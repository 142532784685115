<template>
  <v-card class="pt-0" tile flat :height="cardHeight">
    <v-card-text class="pt-0 pb-2 font-weight-normal" >
        <!-- <span v-if="showChoices">
           <v-select dense class="mt-0 pt-0 pb-0 font-weight-normal body-2"
              v-model="currentChoice"
              :items="choices"
              return-object
              @change="choiceChanged"
              append-outer-icon="mdi-filter"
              @click:append-outer="showFilter=true"
            ></v-select>
        </span> -->
        <span v-if="currentChoice">
          <span>{{currentChoice.text}}</span>
          <span style="float: right;">
            <v-btn @click="showFilter=true" icon><v-icon color="secondary">mdi-filter</v-icon></v-btn>
          </span>
        </span>
        <span v-else>{{title}}</span>
      </v-card-text>
        <v-divider class="mx-3"></v-divider>
        <chart-loader class="mt-9 pt-9" v-if="reportLoading" :reportLoading="reportLoading" />
        <v-card v-else-if="chart_data" class="pt-0" tile :height="overflowCardHeight" flat style="flex-grow: 1 !important; overflow:auto !important;">
          <e-horizontal-bar-stacked v-if="stacked" class="pt-3"
            :series="chart_data"
            :chart_axes="chart_axes"
            :y_axis_data="chart_labels"
            :metric_type="metric.value"
            :target_group="null"
            :changed="changed"
            :entity_type="entity_type"
            :show_labels="show_labels"
            :download="download"
            :chart_size="chart_size"
            :chartHeight="chartHeight"
            :id="id"
            :color="color"
          />
          <e-horizontal-bar-chart v-else class="pt-3"
            :series="chart_data"
            :group="group"
            :chart_axes="chart_axes"
            :y_axis_data="chart_labels"
            :metric_type="metric.value"
            :target_group="null"
            :changed="changed"
            :resetChart="resetChart"
            :entity_type="entity_type"
            :show_labels="show_labels"
            :download="download"
            :chart_size="chart_size"
            :chartHeight="chartHeight"
            :id="id"
            :color="color"
            @updateQuery="updateQuery"
          />
        </v-card>

    <v-dialog v-model="showFilter" width="30%">
      <v-card>
        <v-card-title class="pl-4">Chart Settings</v-card-title>
          <v-subheader class="pb-0">Group Spending By</v-subheader>
          <v-card-actions class="pt-0 mt-0">
            <v-select dense class="mt-0 pt-0 pb-0 font-weight-normal body-2"
              v-model="currentChoice"
              :items="choices"
              return-object
              @change="choiceChanged"
            ></v-select>
          </v-card-actions>
        <cluster-filter v-if="currentChoice && currentChoice.value == 'buyer_clusters'"
                        :disabled="false" 
                        :entityType="'publisher'" 
                        :entityIDType="'publisher_id'" 
                        :entities="[]" 
                        @closeClusterFilter="closeFilter"
                        @selectedClusters="selectedItems" />
        <gov-filter v-else-if="currentChoice && currentChoice.value == 'government'"
                        :disabled="false" 
                        :entityType="'publisher'" 
                        :entityIDType="'publisher_id'" 
                        :entities="[]" 
                        @closeGovFilter="closeFilter"
                        @selectedGovs="selectedItems" />
        <v-card-actions v-else>
          <v-spacer></v-spacer>

          <v-btn text @click="showFilter=false">
            Cancel
          </v-btn>
          <v-btn color="primary" text @click="showFilter=false">
            Apply
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { prepEntityBreakdown } from "./PrepDonut.js";
import { prepHorizontalSeries, prepHorizontalStack } from "./PrepLineChart.js"
import { entitySearch } from "./APIManager.js";
import { silentURLUpdate } from "./Utils.js";
import ChartLoader from "../components/ChartLoader";
import EHorizontalBarChart from "../components/EHorizontalBarChart";
import EHorizontalBarStacked from "../components/EHorizontalBarStacked";
import ClusterFilter from "../components/ClusterFilter.vue";
import GovFilter from "../components/GovFilter.vue";

export default {
  components: {
    EHorizontalBarChart,
    ChartLoader,
    EHorizontalBarStacked,
    ClusterFilter,
    GovFilter

  },
  props: {
    data: {},
    dataLoaded: Number(),
    dataLoading: Boolean,
    title: {
      type: String,
      default: "Market Share"
    },
    showChoices: {
      type: Boolean,
      default: false
    },
    choices: [],
    description: String,
    query: Object,
    chart_size: {
      type: String(),
      default: "small"
    },
    group: Object(),
    metric: Object(),
    stacked: {
      type: Boolean,
      default: false
    },
    tempType: {
      type: String,
      default: "government"
    },

    chartHeight:{
      type: String,
      default: "200px"
    },
    cardHeight:{
      type: String,
      default: null
    },
    overflowCardHeight:{
      type: String,
      default: "200px"
    },
    resetChart: Number,
    widgetID: String,
    widgetSelection: String
  },

  data() {
    return {
      showFilter: false,
      chart_settings: [],
      subtitle: "Market share by entity",
      show_labels: false,
      entity_type: "government",//String(),
      metric_name: "total_amount",//String(),
      category_aggs: [],
      keyword_aggs: [],
      publisher_aggs: [],
      supplier_aggs: [],
      suppliers_by_govt_aggs: [],
      buyer_cluster_aggs: [],
      buyer_cluster_by_supplier_cluster_aggs:[],
      buyer_cluster_by_govt_aggs: [],
      supplier_cluster_by_govt_aggs: [],
      supplier_cluster_by_publisher_industry_aggs:[],
      suppliers_by_buyer_cluster_aggs: [],
      publishers_by_buyer_cluster_aggs: [],
      publishers_by_supplier_cluster_aggs: [],
      buyer_cluster_by_publisher_aggs: [],
      supplier_cluster_aggs: [],
      buyer_cluster_aggs_count:[],
      supplier_cluster_aggs_count:[],
      search_terms_by_buyer_cluster_aggs: [],
      search_terms_by_govt_aggs: [],
      publishers_by_govt_aggs: [],
      suppliers_by_major_category_aggs: [],
      publishers_by_major_category_aggs: [],
      search_terms_by_parent_category_aggs:[],
      supplier_cluster_by_parent_category_aggs:[],
      search_terms_by_publisher_industry_aggs:[],
      suppliers_by_publisher_industry_aggs:[],
      publishers_by_industry_aggs:[],
      government_aggs:[],
      keyword_aggs_count:[],
      category_aggs_count: [],
      major_category_aggs_count: [],
      publisher_aggs_count: [],
      supplier_aggs_count: [],
      government_aggs_count:[],
      entity_aggs: [],
      metric_options: [{text: "Amount", value:"total_amount"}, {text: "Volume", value:"doc_count"}],
      //metric: {text: "Amount", value:"total_amount"},
      group_options: [{text: "Government", value:"government"}, 
                      {text: "Buyer", value:"publisher"},
                      {text: "Major Category", value:"major_category"},
                      {text: "Category", value:"category"},
                      {text: "Supplier", value:"supplier"},
                      {text: "Sector", value:"publisher_industry"}],
      //group: {text: "Major Category", value:"major_category"},
      num_groups: 10,
      top_entities: [10, 15],
      total_records: Number,
      total_amount: Number,
      reportLoading: Boolean,
      plain_text_query:String,
      legendData: [],
      item_details: false,
      aggregated_data: [],
      //publisher_line_chart_data: [],
      chart_data: {},
      chart_labels: [],
      //chart_axes: { x: "Est Total", y: "Categories" },
      //chart_axes: { x: "Transactions", y: "Year" },
      group_by_amount: true,
      target_entity: String,
      changed: Number,
      download: Number,
      dataview: {headers:[], data:[]},
      embed: Number,
      embed_object: Object,
      embed_obj_changed: Number,
      pub_industry_aggs: [],
      pub_industry_aggs_count: [],
      color: [],
      currentChoice: null
    };
  },

  watch: {
    $route(to, from) {
      this.reportLoading = true;
    },

    dataLoading: {
      handler(val) {
        if (val == true){
        this.reportLoading = true;
        }
        else{
          this.reportLoading = false;
        }
      },
      deep: true
    },


    dataLoaded: {
      handler(newValue, oldValue) {
        
        this.reportLoading = true;
        if (this.showChoices && this.currentChoice){
          this.tempType = this.currentChoice.value;
        }

        this.loadData();
        ////////////////console.log("DATA: ", this.data);
        this.setCurrentChoice();
      }
    },

  },

  created(){
    
  },

  mounted() {
    this.reportLoading = true;
    
    /* this.$nextTick(()=>{
        this.setCurrentChoice();
      }); */
    
  },

  computed:{
    id(){
      return (Math.random() + Date.now()) * Math.random();
    },
  },

  methods: {

    selectedItems(items){

      ////////////////console.log("ITEMS: ", items," length", String(items.length));

      if (items.length > 0){

        silentURLUpdate([{"field_name":this.widgetID, "value":items}]);
        silentURLUpdate([{"field_name":this.widgetSelection, "value":this.currentChoice.value}]);
        ////////////////console.log("ROUTE QUERY in WIDGET: ", this.$route.query);
        this.$emit('selectedItems', items);

      }

    },

    closeFilter(val){
      ////////////////console.log("close: ", val);
      this.showFilter = !val;
    },

    setCurrentChoice(){
      if (this.showChoices){
        var c = this.choices.find(item => item.value === this.tempType);
        this.currentChoice = c;
        ////////////////console.log("this.currentChoice; ", this.currentChoice);
        ////////////////console.log("this.choices[0]; ", this.choices[0]);
      }
    },

    updateQuery(query){
      this.$emit("updateQuery", query);
    },

    isEmpty(obj) {
        return Object.keys(obj).length === 0;
    },

    chart_object(obj){
      this.embed_object["title"] = this.title;
      this.embed_object["description"] = this.description;
      this.embed_object["chart_type"] = "pie";
      this.embed_object["payload"] = obj
      this.embed_object["id"] = (Math.random() + Date.now()) * Math.random();
      this.embed_obj_changed = (Math.random() + Date.now()) * Math.random();
    },

    embed_code(status){
      this.embed =
          (Math.random() + Date.now()) * Math.random();

    },

    updateSettings(){
      this.chart_settings[0] = {field_name: "pie_group_value", value: this.group.value};
      this.chart_settings[1] = {field_name: "pie_metric_value", value: this.metric.value};

      silentURLUpdate(this.chart_settings);

    },

    loadSettings(){
      var query = this.$route.query;
      if (query.hasOwnProperty("pie_group_value") && query.comparison_group_value == "search_terms"){
        delete this.group.text;
        delete this.group.value;
        this.group = {text: "Keyword", value:"search_terms"};

      }
      else if (query.hasOwnProperty("pie_group_value")){
        let index = this.group_options.findIndex(x => x.value === query.pie_group_value);
        delete this.group.text;
        delete this.group.value;
        this.group = this.group_options[index];
      }
      if (query.hasOwnProperty("pie_metric_value")){
        let index = this.metric_options.findIndex(x => x.value === query.pie_metric_value);
        delete this.metric.text;
        delete this.metric.value;
        this.metric = this.metric_options[index];
      }
    },

    showTenders(args){
      this.$emit("showTenders", args);
    },
    
    choiceChanged(val){
      ////////////////console.log ("VAL: ", val);
      ////////////////console.log ("this.currentChoice.value: ", this.currentChoice.value);
      this.tempType = this.currentChoice.value;
      silentURLUpdate([{"field_name":this.widgetSelection, "value":this.currentChoice.value}]);
      this.set_entity_aggs();
      this.chartConfig();
      //this.reportLoading = false;
      this.setChanged();
    },

    set_entity_aggs(){
      //////////////////console.log ("SET ENTITY AGGS");
      this.entity_aggs = [];

      if (this.group.value == "major_category"){
        if (this.metric.value == "doc_count")
          this.entity_aggs = this.major_category_aggs_count;
        else{
          this.entity_aggs = this.major_category_aggs;
          /* for (var i in this.category_aggs){
            this.entity_aggs[i] = this.category_aggs[i].category.buckets[0];
          } */
        }
          
      }
      else if (this.group.value == "category"){
        if (this.metric.value == "doc_count")
          this.entity_aggs = this.category_aggs_count;
        else{
          this.entity_aggs = this.category_aggs;
          /* for (var i in this.category_aggs){
            this.entity_aggs[i] = this.category_aggs[i].category.buckets[0];
          } */
        }
          
      }else if (this.group.value == "publisher"){
        if (this.metric.value == "doc_count")
          this.entity_aggs = this.publisher_aggs_count;
        else{
          if (this.stacked && this.tempType == "government"){
            this.entity_aggs = this.publishers_by_govt_aggs;
          }else if(this.stacked && this.tempType=="sector"){
            this.entity_aggs = this.publishers_by_industry_aggs;
          }
          else if(this.stacked && this.tempType=="category"){
            this.entity_aggs = this.publishers_by_major_category_aggs;
          }
          else if(this.stacked && this.tempType=="buyer_clusters"){
            this.entity_aggs = this.publishers_by_buyer_cluster_aggs;
          }
          else{
            this.entity_aggs = this.publisher_aggs;
          }
        }
      }
      else if (this.group.value == "publisher_industry"){
        if (this.metric.value == "doc_count")
          this.entity_aggs = this.pub_industry_aggs_count;
        else{
          this.entity_aggs = this.pub_industry_aggs;
          /* for (var i in this.publisher_aggs){
            this.entity_aggs[i] = this.publisher_aggs[i].publisher.buckets[0];
          } */
          //////////////////console.log("AGGS: ", this.entity_aggs);
        }
      }
      else if (this.group.value == "government"){
        if (this.metric.value == "doc_count")
          this.entity_aggs = this.government_aggs_count;
        else
          this.entity_aggs = this.government_aggs;
      }else if (this.group.value == "search_terms"){
          if (this.stacked && this.tempType=="government"){
            this.entity_aggs = this.search_terms_by_govt_aggs;
          }
          else if(this.stacked && this.tempType=="sector"){
            this.entity_aggs = this.search_terms_by_publisher_industry_aggs;
          }
          else if(this.stacked && this.tempType=="category"){
            this.entity_aggs = this.search_terms_by_parent_category_aggs;
          }
          else if(this.stacked && this.tempType=="buyer_clusters"){
            this.entity_aggs = this.search_terms_by_buyer_cluster_aggs;
          }
          else{
            this.entity_aggs = this.keyword_aggs;
          }
      }
      else if (this.group.value == "buyer_clusters"){
          if (this.stacked && this.tempType=="government"){
            this.entity_aggs = this.buyer_cluster_by_govt_aggs;
          } 
          else if(this.stacked && this.tempType=="category"){
            this.entity_aggs = this.buyer_cluster_by_parent_category_aggs;
          }
          else if(this.stacked && this.tempType=="supplier"){
            this.entity_aggs = this.suppliers_by_buyer_cluster_aggs;
          }
          else{
            this.entity_aggs = this.buyer_cluster_aggs;
          }
      }
      else if (this.group.value == "supplier_clusters"){
          if (this.stacked && this.tempType=="government"){
            this.entity_aggs = this.supplier_cluster_by_govt_aggs;
          } 
          else if(this.stacked && this.tempType=="category"){
            this.entity_aggs = this.supplier_cluster_by_parent_category_aggs;
          }
          else if(this.stacked && this.tempType=="sector"){
            this.entity_aggs = this.supplier_cluster_by_publisher_industry_aggs;
          }
          else if(this.stacked && this.tempType=="buyer_clusters"){
            this.entity_aggs = this.buyer_cluster_by_supplier_cluster_aggs;
          }
          else{
            this.entity_aggs = this.supplier_cluster_aggs;
          }
      }
      else {
        if (this.metric.value == "doc_count")
          this.entity_aggs = this.supplier_aggs_count;
        else{
          if (this.stacked && this.tempType=="government"){
            this.entity_aggs = this.suppliers_by_govt_aggs;
          }
          else if(this.stacked && this.tempType=="sector"){
            this.entity_aggs = this.suppliers_by_publisher_industry_aggs;
          }
          else if(this.stacked && this.tempType=="category"){
            this.entity_aggs = this.suppliers_by_major_category_aggs;
          }
          else if(this.stacked && this.tempType=="buyer_clusters"){
            this.entity_aggs = this.suppliers_by_buyer_cluster_aggs;
          }
          else{
            this.entity_aggs = this.supplier_aggs;
          }
        }
      }
    },

    setChanged(){
      //////////////////console.log("CHANGED");
      this.changed =
          (Math.random() + Date.now()) * Math.random();
    },

    downloadImage(status){
      this.download =
          (Math.random() + Date.now()) * Math.random();

    },

    loadDataView(data){
      this.dataview = data;
    },

    async loadData() {

      this.supplier_aggs = this.data.aggregations.supplier.buckets;
      this.suppliers_by_govt_aggs = this.data.aggregations.suppliers_by_govt.buckets;
      this.publishers_by_govt_aggs = this.data.aggregations.publishers_by_govt.buckets;
      //////////////////console.log("SUPPLIEzrS ", this.supplier_aggs);
      this.category_aggs = this.data.aggregations.category.buckets;
      this.pub_industry_aggs = this.data.aggregations.suppliers_by_publisher_industry.buckets;
      //this.major_category_aggs = this.data.aggregations.major_category.buckets;
      this.publisher_aggs = this.data.aggregations.publisher.buckets;
      this.government_aggs = this.data.aggregations.government.buckets;

      this.suppliers_by_major_category_aggs = this.data.aggregations.major_category.buckets;
      this.publishers_by_major_category_aggs = this.data.aggregations.publishers_by_major_category.buckets;
      
      this.suppliers_by_publisher_industry_aggs = this.data.aggregations.suppliers_by_publisher_industry.buckets;
      this.publishers_by_industry_aggs = this.data.aggregations.publishers_by_industry.buckets;

      if (this.data.aggregations.search_terms){
        this.keyword_aggs = this.data.aggregations.search_terms.buckets;
        this.search_terms_by_govt_aggs = this.data.aggregations.search_terms_by_govt.buckets;
        this.search_terms_by_parent_category_aggs = this.data.aggregations.search_terms_by_parent_category.buckets;
        this.search_terms_by_publisher_industry_aggs = this.data.aggregations.search_terms_by_publisher_industry.buckets;
        if (this.data.aggregations.buyer_clusters){
          this.search_terms_by_buyer_cluster_aggs = this.data.aggregations.search_terms_by_buyer_cluster.buckets;
        }
        this.group_options.push({text: "Keyword", value:"search_terms"})
      }

      if (this.data.aggregations.buyer_clusters){
          this.buyer_cluster_aggs = this.data.aggregations.buyer_clusters.buckets;
          
          this.suppliers_by_buyer_cluster_aggs = this.data.aggregations.suppliers_by_buyer_cluster.buckets;
          this.publishers_by_buyer_cluster_aggs = this.data.aggregations.publishers_by_buyer_cluster.buckets;
          
          this.group_options.push({text: "Buyer Cluster", value:"buyer_clusters"})
      }

      if (this.data.aggregations.supplier_clusters){
          this.supplier_cluster_aggs = this.data.aggregations.supplier_clusters.buckets;
          
          this.supplier_cluster_by_parent_category_aggs = this.data.aggregations.supplier_clusters_by_parent_category.buckets;
          this.supplier_cluster_by_govt_aggs = this.data.aggregations.supplier_clusters_by_govt.buckets;
          this.supplier_cluster_by_publisher_industry_aggs = this.data.aggregations.supplier_clusters_by_publisher_industry.buckets;
          //this.publishers_by_supplier_cluster_aggs = this.data.aggregations.publishers_by_supplier_cluster.buckets;

          this.buyer_cluster_by_supplier_cluster_aggs = this.data.aggregations.buyer_cluster_by_supplier_cluster.buckets;
          
          this.group_options.push({text: "Supplier Cluster", value:"supplier_clusters"})
      }
      
      this.total_amount = this.data.aggregations.total_amount.value;
      this.plain_text_query = this.data.plain_text_query;
      this.total_records = this.data.hits.value;

      //count
      this.supplier_aggs_count = this.data.aggregations.supplier_by_count.buckets;
      this.pub_industry_aggs_aggs_count = this.data.aggregations.publisher_industry_by_count.buckets;
      this.category_aggs_count = this.data.aggregations.category_by_count.buckets;
      this.major_category_aggs_count = this.data.aggregations.major_category_by_count.buckets;
      this.publisher_aggs_count = this.data.aggregations.publisher_by_count.buckets;
      this.government_aggs_count = this.data.aggregations.government_by_count.buckets;

      this.set_entity_aggs();
      this.chartConfig();
      
      this.reportLoading = false;
      this.setChanged();
      

    },


    get_targets(bucket_data, type) {
      let targets = [];
      let target_count = 0;

      //for each year get the targets
      for (var y = 0; y < bucket_data.length; y++) {
        let data_for_year = bucket_data[y][type].buckets;

        for (var t = 0; t < data_for_year.length; t++) {
          targets[target_count] = data_for_year[t].key;
          //////////////////console.log("entity: " + data_for_year[t].key);
          target_count++;
        }
      }
      return targets;
    },

    chartConfig() {
      //this.chart_data = new Object();
      var preppedData = {};

      let colors = [
        "#12253a",
        "#ff1463",
        "#d73e32",
        "#2196f3",
        "#004700",
        "#2d9437",
        "#004d9f",
        "#007cd6",
        "#d73e32",
        "#de004b",
        "#ff457c",
        "#4a575d",
        "#7b898f",
        "#bb5900",
        "#bb5900",
        "#ffa72d",
        "#4caf50",
        "#2196f3",
        "#fb8c00",
        "#ff1463",
        "#b71c1c",
        "#ff937b",
        "#95e8ff",
        "#a2ff9e",
        "#fffb82",
        "#FFE70A",
        "#SFS603",
        "#7F7305",
        "#BFAB07",
        "#FF6905",
        "#5405FF"
      ];

      //!!!assign color to entity. sort alphabetically first so colours are consistent across any chart on the dash!!!!!
      if (!this.stacked && this.entity_aggs.length > 0){
        //////////////////console.log("BEFORE SORT: ", this.entity_aggs);
        var sortedEntities = this.entity_aggs.sort(function(a, b) {
                    var nameA = a.key.toUpperCase(); // ignore upper and lowercase
                    var nameB = b.key.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                      return -1; //nameA comes first
                    }
                    if (nameA > nameB) {
                      return 1; // nameB comes first
                    }
                    return 0;  // names must be equal
                  });
        this.entity_aggs = sortedEntities;
        //////////////////console.log("ORDERED ALPHABETICALLY: ", sortedEntities);
        for (var i in this.entity_aggs){
          //////////////////console.log("COLOR AT ", i, " IS ", colors[i])
          this.entity_aggs[i]["color"] = colors[i];
          //////////////////console.log("ITEM AT ", i, " IS ", this.entity_aggs[i])
        }

      }
      if (this.stacked){

          var target_names = [];
          var target_values = [];
          this.chart_labels = [];
          this.chart_data = {};

          for (var i in this.entity_aggs){
            this.chart_labels.unshift(this.entity_aggs[i].key);
          }

          //////////////////console.log (this.entity_aggs);
          //////////////////console.log (this.group.value);

          for (var i in this.entity_aggs){
            //////////////////console.log ("ENTITY AGGS: ", this.entity_aggs[i]);
            for (var t in this.entity_aggs[i][this.group.value].buckets){
              let entity = {name: String(), value: Number()};
              entity.name = this.entity_aggs[i][this.group.value].buckets[t].key;
              entity.value = this.entity_aggs[i][this.group.value].buckets[t].total_amount.value
              target_names.unshift(entity.name);
              target_values.unshift(entity);
            }
          }
          

          // remove duplicates
          let uniqueTargets = target_names.filter((element, index) => {
              return target_names.indexOf(element) === index;
          });

          //sort and assign colours
          uniqueTargets.sort((a, b) => (a < b) ? 1 : -1)
          let target_obj_list = []
          for (var i in uniqueTargets){
            target_obj_list[i] = { key: uniqueTargets[i], color: colors[i] };
          };

          //////////////////console.log(ass)

          /* ////////////////console.log (this.entity_aggs);
          ////////////////console.log (this.metric.value);
          ////////////////console.log (this.group.value);
          ////////////////console.log (target_obj_list); */
          
          preppedData = prepHorizontalStack(
            this.entity_aggs,
            this.metric.value,
            this.group.value,
            colors,
            this.chart_labels,
            target_obj_list
          );

          this.chart_data = preppedData;

      }
      else{
        if (this.metric.value == "total_amount"){
          this.entity_aggs.sort((a, b) => (a.total_amount.value < b.total_amount.value) ? 1 : -1)
        }
        else if (this.metric.value == "doc_count"){
          this.entity_aggs.sort((a, b) => (a.doc_count < b.doc_count) ? 1 : -1)
        }
        else if (this.metric.value == "avg_value"){
          this.entity_aggs.sort((a, b) => (a.avg_value.value < b.avg_value.value) ? 1 : -1)
        }

        //////////////////console.log("PREPPING DATA... ");
        //////////////////console.log("ID... ", this.id);

        preppedData = prepHorizontalSeries(
          this.entity_aggs,
          this.metric.value,
          colors
        );

        //////////////////console.log("PREPPED: ", preppedData);

        this.chart_data = preppedData["series"];
        this.chart_labels = preppedData["yAxisData"];
      }
    
      //this.reportLoading = false;
      //this.setChanged();

    }
  }
};
</script>

<style scoped>

</style>
