<template>
  <v-div
    class="pa-0 ma-0 fill-height"
    style="background-color:#f5f5f5"
    fluid
    align="start"
  >
    <v-content>
      <v-container class="fill-height pl-1 pr-0 mx-1 py-2 mt-0" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" class="pt-8">
            <div
              style="max-width:1200px; display: block; margin:auto !important;"
            >
              <p
                style="text-align:center;"
                class="display-1 font-weight-medium"
              >
                Your Alerts.
              </p>
              <p style="text-align:center;" class="body-1 font-weight-normal">
                New tender or contract matches that we find for your saved
                searches will be listed here. You will also be sent an email
                notification for any new matches that haven't already appeared in a search you have run.
              </p>
              <v-row align="center" justify="center">
                <v-col cols="12" style="max-width:800px;" class="pa-0 ma-0">
                  <inbox-filters />
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-if="tenders.length > 0"
            cols="12"
            md="12"
            class="pt-0 mb-0 pb-0 pr-0"
          >
            <results-table :tenders="tenders" :hit_info="hit_info" :json_query="sort" @updateSort="updateSort" />
            <v-overlay v-model="overlay">
              <v-progress-circular
                indeterminate
                size="64"
              ></v-progress-circular>
            </v-overlay>
          </v-col>
          <v-col v-else cols="12" md="12" class="pt-0 mb-0 pb-0 pr-0">
            <v-card class="mx-auto pt-4" max-width="700">
              <v-card-text>
                <p style="text-align:center; " class="display-1 text--primary">
                  No records found.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </v-div>
</template>

<script>
import axios from "axios";
import ResultsTable from "../components/ResultsTable.vue";
import InboxFilters from "../components/InboxFilters";

import { bus } from "../main.js";
import { fetchNotifications } from "../components/APIManager";

export default {
  components: {
    InboxFilters,
    ResultsTable
  },
  data() {
    return {
      drawer: true,
      mini: true,
      search: { publisher_id: Number },
      current_entity: {},
      global_search: false,
      view_index: null,

      selected: "snapshot",
      current_view: "snapshot",
      watching: false,
      tenders: [],
      overlay: false,
      hit_info: {},
      sort: {sort_field: "created", sort_order: "desc"},
      //results_drawer: null
    };
  },

  created() {
    this.loadInbox();

    bus.$on("mini", showHide => {
      this.mini = !this.mini;
    });
  },

  mounted() {},

  methods: {

    updateSort(sortQuery){

      ////////console.log("ARGS: ", sortQuery);
      this.page_num = 1;
      this.overlay = true;

      this.sort.sort_field = sortQuery.sort_field.value;
      this.sort.sort_order = sortQuery.sort_order.value;
      
      //this.args.push(sortQuery)
      ////////console.log("ARGS: ", params);
      this.loadInbox();
    },

    loadInbox() {
      this.tenders = [];
      this.overlay = true;
      var user = this.$auth.user; //JSON.parse(localStorage.getItem("user"));
      var keyword = this.$route.query.keyword;
      var disposition = this.$route.query.disposition;

      var params = {};

      ////////console.log(user);

      params["user_id"] = user.sub;

      if (keyword){
        params["keyword"] = keyword;
      }

      if (disposition){
        params["disposition"] = disposition;
      }

      params["sort_field"] = this.sort.sort_field;
      params["sort_order"] = this.sort.sort_order;

      fetchNotifications(params).then(response => {
        ////////console.log(response);
        this.tenders = response.data;
        ////////console.log(this.tenders);
        this.hit_info["total_pages"] = 1;

        this.overlay = false;
        
      });
      
    },

    hideFilter() {
      let show = false;
      bus.$emit("hideFilters", show);
    },

    isEmpty(obj) {
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
      }
      return true;
    }
  },

  watch: {
    $route(to, from) {
      ////////console.log("IN TENDER RESULTS: ");
      this.loadInbox();
    }
  }
};
</script>
