<template>
  <div>
    
    <v-card width="100%" v-if="!$auth.loading && $auth.isAuthenticated" flat>
      
      <v-card-title color="#12253a">
        <v-avatar size="36px" v-if="$auth.user.picture" class="pl-4 pr-7">
          <img small :src="$auth.user.picture"
              alt="User's profile picture"/>
        </v-avatar>
        <v-avatar size="36px" v-else color="primary" class="pl-4 pr-7">
          <v-icon dark>
            mdi-account-circle
          </v-icon>
        </v-avatar>
        
        <!-- <v-icon color="#12253a" medium left>mdi-account</v-icon> -->
        <span class="title font-weight-bold subtitle-1"
          >{{ $auth.user.name }}</span
        >
        <div class="flex-grow-1"></div>
        <!-- <v-btn icon color="secondary" @click="logout()"><v-icon>mdi-logout</v-icon></v-btn> -->
      </v-card-title>
      <v-divider></v-divider> 
      <div class="mt-2 ml-1">
            <v-btn
              @click="logout()"
              v-bind="attrs"
              v-on="on"
              text
              small
              class="text-capitalize body-2"
              color="secondary"
              >Logout <v-icon medium right>mdi-logout</v-icon></v-btn
            >
      </div>
      <div class="mt-2 ml-1">
            <v-btn
              @click="shareLink=true, generateLink()"
              v-bind="attrs"
              v-on="on"
              :disabled="isClusterRoute || isReportRoute"
              text
              small
              class="text-capitalize body-2"
              color="secondary"
              >Share Page<v-icon medium right outline>mdi-share</v-icon></v-btn
            >
            
      </div>

        <v-card-actions v-if="loading==true">
          <v-progress-linear
            indeterminate
            size="36"
            color="primary"
          ></v-progress-linear>
        </v-card-actions>
        <v-card-actions v-else>
          <v-combobox
            class="px-3 py-2 caption"
            v-model="selectedSupplier"
            cache-items
            :items="suppliers"
            item-text="name"
            :search-input.sync="search"
            item-value="id"
            label="Set your home organisation"
            x-small
            clearable
            outlined
            dense
            append-outer-icon="mdi-content-save"
            :dark="dark"
            @click:append-outer="saveHomeOrg()"
          >
          </v-combobox>
          
          <!-- <v-autocomplete
            class="px-3 py-2 caption"
            v-model="selectedSupplier"
            :items="suppliers"
            item-text="name"
            item-value="id"
            label="Set your home organisation"
            return-object
            dense
            :search-input.sync="search"
            outlined
          ></v-autocomplete> -->
      </v-card-actions>
      
    </v-card>
    
    <v-dialog v-model="shareLink" max-width="500">
      <v-snackbar
              v-model="copied"
              top
              color="green"
              dense
              small
              max-height="10"
              max-width="50"
              timeout="1000"
            >
              Copied to clipboard!
              <!-- <v-btn dark text @click="copied = false">
                Close
              </v-btn> -->
      </v-snackbar>
        <v-card mx-auto flat min-height="180">
          <v-card-title><span>Share Page</span>
            <div class="flex-grow-1"></div> <v-btn icon @click="shareableLink = '', shareLink=false"><v-icon style="font-weight:thin;" medium>mdi-close</v-icon></v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-subtitle class="pt-2">Copy and share the shortened URL below with other users</v-card-subtitle>
          <span v-if="shareableLink != ''">
            <v-card flat class="mx-4 py-1" outlined>
              <span class="subtitle-2 ml-4">
                <a :href="shareableLink" style="color:white">{{shareableLink}}</a></span>
           <span>
                  <v-btn class="ml-1" icon @click="copy(shareableLink), shareLink=false"><v-icon color="primary" medium>mdi-content-copy</v-icon></v-btn>
              </span>
            </v-card>
          </span>
          <span v-else>
            <div class="text-center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
          </span>
        </v-card>
      </v-dialog>
  </div>

</template>

<script>
import { bus } from "../main.js";
import axios from "axios";
import {
  getCurrentUserProfile,
  setUserOrg,
  searchSuppliers
} from "../components/APIManager.js";
//import { logout, loggedIn } from "./LoginManager";

export default {
  components: {},
  props: { drawer: Boolean, menu: Boolean, authenticated: Boolean },
  data() {
    return {
      selectedSupplier: {name: null, id: null},
      suppliers: [],
      searchTimeout: null,
      shareLink: false,
      shareableLink: "",
      copied: false,
      pwd_dialog: false,
      search_request: false,
      source: "",
      search: "",
      search_terms: {},
      //drawer: true,
      //watched: false,
      view_index: null,
      //creds: {email: "", password:""},
      //username,
      email: "",
      password: "",
      error: false,
      //pw:""
      current_user: null,
      selected: "snapshot",
      current_view: "snapshot",
      token: "",
      isLoggedIn: false,
      currentURL: "",
      supportBody:"",
      loading: false
      //results_drawer: null
    };
  },

    watch: {
      search(val) {
        if (val && val !== this.select) {
          this.suppliers = this.lookup(val);
        }
      },
  },

  computed: {

  },

  mounted() {

    this.$nextTick(() => {
       this.loadUser();
    });

  },

  async created() {
    
  },

  computed: {
    isClusterRoute() {
      // Check if the current route is 'cluster'
      return this.$route.path.includes('cluster');
    },
    isReportRoute() {
      // Check if the current route is 'report'
      return this.$route.path.includes('report');
    }
  },

  methods: {

    lookup (searchText){
      searchSuppliers(searchText).then(results => {
        this.suppliers = results;
        
        //this.loading = false;
        ////////////console.log("loadPublishers data: ", results);
      });
    },

    saveHomeOrg (){
      this.loading = true;
      setUserOrg(this.selectedSupplier.id).then(results => {
        this.current_user = results.data;
         
          if (this.current_user.org_id){
            this.selectedSupplier.name = this.current_user.org_data.name;
            this.selectedSupplier.id = this.current_user.org_data.id;
            this.suppliers[0] = this.selectedSupplier;
            this.loading = false;
          }
          else{
            this.loading = false;
          }
          
        //this.loading = false;
        ////////////console.log("loadPublishers data: ", results);
      });
    },

    loadUser() {
      this.loading = true;

      getCurrentUserProfile()
        .then(results => {
          // Set current_user if the promise resolves successfully
          this.current_user = results.data;
         
          if (this.current_user.org_id){
            this.selectedSupplier.name = this.current_user.org_data.name;
            this.selectedSupplier.id = this.current_user.org_data.id;
            this.suppliers[0] = this.selectedSupplier;
            this.loading = false;
          }
          else{
            this.loading = false;
          }
          
        });
    },

    async copy(txt){
        try {
            await navigator.clipboard.writeText(txt);
            this.copied = true;
        } catch (e) {

        }
    },
    contactUs (){
      window.open('https://tendertrace.com/contact-us/');
    },
    goToRegistration() {
      this.$router.push(this.$route.query.push || "/register");
    },

    async generateLink(){
      this.currentURL = window.location.href;

      const options = {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          Authorization: 'pk_vU6EIAOfRouzmn3x'
        },
        body: JSON.stringify({
          allowDuplicates: false,
          domain: 'go.tendertrace.com',
          originalURL: this.currentURL
        })
      };

      fetch('https://api.short.io/links/public', options)
        .then(response => response.json())
        .then(response => {
          this.shareableLink = response.shortURL})
        .catch(err);

      //this.supportBody = await this.shortURL(this.currentURL) + '%0D' + '%0DPlease enter your message below this line.%0D';
    },

    async sendSupportMsg(){
      this.currentURL = window.location.href;

      const options = {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          Authorization: 'pk_vU6EIAOfRouzmn3x'
        },
        body: JSON.stringify({
          allowDuplicates: false,
          domain: 'go.tendertrace.com',
          originalURL: this.currentURL
        })
      };

      fetch('https://api.short.io/links/public', options)
        .then(response => response.json())
        .then(response => {
          window.location.href = 'mailto:' + 'support@tendertrace.com?subject=Tendertrace Support Query&body=Source page: '+response.shortURL+ '%0D' + '%0DPlease enter your message below this line.%0D';
          return response.shortURL})
        .catch(err);

      //this.supportBody = await this.shortURL(this.currentURL) + '%0D' + '%0DPlease enter your message below this line.%0D';
    },


    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    },


  }
};
</script>
