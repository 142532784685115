<template>
  <v-card class="chart_container pa-2" tile flat min-height="400">
    <chart-header :title="title"
      :subtitle="subtitle"
      :widget_name="'expiring-soon'"
      :total_result="total_records"
      :total_amount="total_amount"
      :search_query="plain_text_query"
      :chart_settings="chart_settings"
      :data="dataview"
      :embed_object="embed_object"
      :embed_obj_changed="embed_obj_changed"
      @embed="embed_code"  
      @downloadImage="downloadImage"/>
    <chart-loader :reportLoading="reportLoading" />
    <v-row>
        <div class="d-inline pl-4 mr-5">
          <p class="mb-0 pb-0 subtitle-2" style="font-weight:bold;">Entity Type</p>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              class="text-capitalize body-1"
                color="#E0E0E0"
                outlined
                v-bind="attrs"
                v-on="on"
                width="170"
                style="justify-content:left !important;"
              >
                <span style="color:#12253a;">{{group.text}}</span>
                <v-icon color="#12253a" right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, value) in group_options"
                :key="value"
                @click="group = item, updateSettings(), set_entity_aggs(), setChanged()"
              >
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="d-inline">
          <p class="mb-0 pb-0 subtitle-2" style="font-weight:bold;"># Entities / Categories</p>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              class="text-capitalize body-1"
                color="#E0E0E0"
                outlined
                v-bind="attrs"
                v-on="on"
                width="130"
                style="justify-content:left !important;"
              >
                <span style="color:#12253a;">{{num_groups}}</span>
                <v-icon color="#12253a" right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="item in top_entities"
                :key="item"
                @click="num_groups = item, updateSettings(), refresh()"
              >
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        
    </v-row>
    <v-row>
        <v-col cols="12">
    <div class="px-3 pb-3 pt-0">
      <heatmap v-if="expiry_activity.length > 0"
        :activity="expiry_activity"
        :tooltipText="'Number of Notices Expiring'"
        :changed="changed"
        :byQuarter="true"
        :quarters="quarters"
        :download="download"
        :target_group="group.value"
        :embed="embed"
        @chart_object="chart_object"
        @dataview="loadDataView"
        @showTenders="showTenders"
        :id="id"
      />
    </div>
    </v-col>
      </v-row>
  </v-card>
</template>

<script>
//import { bus } from "@/main.js";
import Heatmap from "@/components/Heatmap.vue";
import ChartLoader from "../components/ChartLoader";
import ChartHeader from "../components/ChartHeader";
import { histogramSearch } from "./APIManager.js";
import { silentURLUpdate } from "./Utils.js";

export default {
  components: {
    Heatmap,
    ChartLoader,
    ChartHeader
    //Donut
  },
  props: {
    title: {
      type: String,
      default: "Expiring Contracts"
    },
    description: String
  },
  data() {
    return {
      chart_settings: [],
      subtitle: "Number of notices expiring over the next 8 quarters",
      top_entities: [10, 20],
      tenders: [],
      buyer_cluster_aggs: [],
      supplier_cluster_aggs: [],
      date_aggs: [],
      keyword_aggs: [],
      keyword_aggs_count: [],
      expiry_activity: [],
      publisher_activity: [],
      supplier_activity: [],
      category_activity: [],
      major_category_activity: [],
      government_activity: [],
      changed: 1,
      num_groups: 10,
      reportLoading: Boolean,
      quarters: [],
      group_options: [{text: "Buyer", value:"publisher"},
                      {text: "Supplier", value:"supplier"},
                      {text: "Major Category", value:"major_category"},
                      {text: "Category", value:"category"},
                      {text: "Government", value:"government"}],
      group: {text: "Buyer", value:"publisher"},

      total_records: Number,
      total_amount: Number,
      plain_text_query:String,
      download: 0,
      dataview: {headers:[], data:[]},
      embed: Number,
      embed_object: Object,
      embed_obj_changed: Number
    };
  },
  watch: {

    $route(to, from) {
      this.reportLoading = true;
      this.loadData(this.$route.query);
    },

  },

  computed: {

    id(){
      return (Math.random() + Date.now()) * Math.random();
    },
  },

  created(){

  },

  async mounted() {
    this.reportLoading = true;
    this.loadSettings();
    this.loadData(this.$route.query);
  },

  methods: {

    chart_object(obj){
      this.embed_object["title"] = this.title;
      this.embed_object["description"] = this.description;
      this.embed_object["chart_type"] = "heatmap";
      this.embed_object["payload"] = obj
      this.embed_object["id"] = (Math.random() + Date.now()) * Math.random();
      this.embed_obj_changed = (Math.random() + Date.now()) * Math.random();
    },

    embed_code(status){
      this.embed =
           (Math.random() + Date.now()) * Math.random();

    },

    updateSettings(){
      this.chart_settings[0] = {field_name: "expiry_group_value", value: this.group.value};
      this.chart_settings[1] = {field_name: "expiry_num_groups", value: this.num_groups};

      silentURLUpdate(this.chart_settings);
    },

    loadSettings(){
      var query = this.$route.query;

      if (query.hasOwnProperty("expiry_group_value") && query.expiry_group_value == "search_terms"){
        delete this.group.text;
        delete this.group.value;
        this.group = {text: "Keyword", value:"search_terms"};
      }
      else if (query.hasOwnProperty("expiry_group_value") && query.expiry_group_value == "supplier_clusters"){
        delete this.group.text;
        delete this.group.value;
        this.group = {text: "Supplier Cluster", value:"supplier_clusters"};

      }
      else if (query.hasOwnProperty("expiry_group_value") && query.expiry_group_value == "buyer_clusters"){
        delete this.group.text;
        delete this.group.value;
        this.group = {text: "Buyer Cluster", value:"buyer_clusters"};

      }
      else if (query.hasOwnProperty("expiry_group_value")){
        let index = this.group_options.findIndex(x => x.value === query.expiry_group_value);
        delete this.group.text;
        delete this.group.value;
        this.group = this.group_options[index];
      }
      if (query.hasOwnProperty("expiry_num_groups")){
        this.num_groups = query.expiry_num_groups;
      }
    },

    showTenders(args){
      this.$emit("showTenders", args);
    },

    async refresh(){
      this.reportLoading = true;
      await this.loadData(this.$route.query);

      var query = this.$route.query;

      if (query.hasOwnProperty("expiry_group_value") && query.expiry_group_value == "search_terms"){
        let index = this.group_options.findIndex(x => x.value === "search_terms");
        //delete this.tree_group.text;
        //delete this.tree_group.value;
        if (index > -1){
          this.group_options.splice(index, 1);

        }
      }

      if (query.hasOwnProperty("expiry_group_value") && query.expiry_group_value == "buyer_clusters"){
        let index = this.group_options.findIndex(x => x.value === "buyer_clusters");
        if (index > -1){
          this.group_options.splice(index, 1);
        }
      }

      if (query.hasOwnProperty("expiry_group_value") && query.expiry_group_value == "supplier_clusters"){
        let index = this.group_options.findIndex(x => x.value === "supplier_clusters");
        if (index > -1){
          this.group_options.splice(index, 1);
        }
      }
    },

    downloadImage(status){
      this.download = this.download + 1;
         // (Math.random() + this.chart_data.length) * Math.random();

    },

    loadDataView(data){
      this.dataview = data;
    },

    set_entity_aggs(){
      this.expiry_activity = [];

      if (this.group.value == "major_category"){
          this.expiry_activity = this.major_category_activity;
      }
      else if (this.group.value == "category"){
          this.expiry_activity = this.category_activity;
      }else if (this.group.value == "publisher"){
          ////////////console.log(this.publisher_activity);
          this.expiry_activity = this.publisher_activity;
      }else if (this.group.value == "government"){
          this.expiry_activity = this.government_activity;
      }else if (this.group.value == "search_terms"){
          this.expiry_activity = this.keyword_aggs;
      }
      else if (this.group.value == "buyer_clusters"){
          this.expiry_activity = this.buyer_cluster_aggs;
      }
      else if (this.group.value == "supplier_clusters"){
          this.expiry_activity = this.supplier_cluster_aggs;
      }
      else {
        this.expiry_activity = this.supplier_activity;
      }
    },


    setChanged(){
      this.changed = this.changed + 1;
          //(Math.random() + this.changed) * Math.random();
    },

    async loadData(qry) {

      var query = JSON.parse(JSON.stringify(qry));
      query["agg_size"] = this.num_groups;

      histogramSearch(query, 1)
        .then(data => {
          /* this.expiry_activity =
            data.aggregations.expiry_by_month.buckets; */

          this.total_records = data.hits.value;
          this.total_amount = data.aggregations.total_amount.value;
          this.plain_text_query = data.plain_text_query;
          
          this.publisher_activity = data.aggregations.expiry_by_quarter_publisher.buckets;
          this.supplier_activity = data.aggregations.expiry_by_quarter_supplier.buckets;
          this.category_activity = data.aggregations.expiry_by_quarter_category.buckets;
          this.major_category_activity = data.aggregations.expiry_by_quarter_major_category.buckets;
          this.government_activity = data.aggregations.expiry_by_quarter_government.buckets;

          if (data.aggregations.expiry_by_quarter_search_terms){
            this.keyword_aggs = data.aggregations.expiry_by_quarter_search_terms.buckets;
            if (!this.group_options.find(o => o.value === 'search_terms')){
              this.group_options.push({text: "Keyword", value:"search_terms"})
            }
            //this.group_options.push({text: "Keyword", value:"search_terms"})
          }

          if (data.aggregations.expiry_by_quarter_buyer_cluster){
            this.buyer_cluster_aggs = data.aggregations.expiry_by_quarter_buyer_cluster.buckets;
            if (!this.group_options.find(o => o.value === 'buyer_clusters')){
              this.group_options.push({text: "Buyer Cluster", value:"buyer_clusters"})
            }
            
          }

          if (data.aggregations.expiry_by_quarter_supplier_cluster){
            this.supplier_cluster_aggs = data.aggregations.expiry_by_quarter_supplier_cluster.buckets;
            if (!this.group_options.find(o => o.value === 'supplier_clusters')){
              this.group_options.push({text: "Supplier Cluster", value:"supplier_clusters"})
            }
            
          }

          this.quarters = data.aggregations.expiry_quarter_count.buckets;

          //this.$emit("aggs-loaded", this.gov_aggs);
          this.set_entity_aggs();
          this.setChanged();
          this.reportLoading = false;
          ////////////console.log("EXPIRING: ", this.expiry_activity);

        })
        .catch(error => {
          ////////////console.log(error);
          //this.errored = true;
          //this.overlay = false;
        });
    },
  }
};
</script>
<style scoped>

.chart_container {
    max-width: 100% !important;
    /* overflow: auto !important; */
    border: none !important;
    height: 100% !important;
    background-color: #fff !important;
}



</style>