<template>
    <div>
      <!-- Timeline container -->
      <div ref="timeline"></div>
    </div>
  </template>
  
  <script>
  import { Timeline, DataSet } from "vis-timeline/standalone"; // Properly import vis-timeline
  import "vis-timeline/styles/vis-timeline-graph2d.min.css"; // Import the CSS
  
  export default {
    name: "TimelineVis",
    props: {
      item: {
        type: Object,
        required: true
      },
    },
    data() {
      return {
        timeline: null,
      };
    },
    mounted() {
      this.initializeTimeline();
    },
    methods: {

      getStyle(event){
        if (event === "published"){
            return "background-color: #ff1463; color: white; padding: 2px; border-radius: 4px; box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); font-size: 11px;"
        }
        else if (event === "contracted"){
            return "background-color: rgb(255, 231, 10) ;color: #12253a; padding: 2px; border-radius: 4px; box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); font-size: 11px;"
        }
        else if (event === "expired"){
            return "background-color: #12253a ;color: white; padding: 2px; border-radius: 4px; box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); font-size: 11px;"
        }
        
      },

      formatDate(date) {
        if (!date) return '';
        const d = new Date(date);
        return d.toISOString().split("T")[0];  // Format as YYYY-MM-DD for vis.js
      },
      calculateDaysBetween(date1, date2) {
        const d1 = new Date(date1);
        const d2 = new Date(date2);
        const diffTime = Math.abs(d2 - d1);
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
      },
      initializeTimeline() {
        const container = this.$refs.timeline;
  
        // Prepare the timeline data by checking for valid dates
        const items = new DataSet([]); // Use DataSet from vis-timeline
  
        if (this.item.published_date) {
          items.add({
            id: 1,
            content: `<b>Published</b><br>${this.formatDate(this.item.published_date)}`,
            start: this.formatDate(this.item.published_date),
            style: this.getStyle("published")
          });
        }
  
        if (this.item.contract_start) {
          items.add({
            id: 2,
            content: `<b>Term Start</b><br>${this.formatDate(this.item.contract_start)}`,
            start: this.formatDate(this.item.contract_start),
            style: this.getStyle("contracted")
          });
        }
  
        // Check if contract_end or closing_date is available for expiry
        const expiryDate = this.item.contract_end || this.item.closing_date;
        if (expiryDate) {
          items.add({
            id: 3,
            content: `<b>Expiry</b><br>${this.formatDate(expiryDate)}`,
            start: this.formatDate(expiryDate),
            style: this.getStyle("expired")
          });
        }
  
        // Customize timeline options
        const options = {
            stack: false,
            //horizontalScroll: true,
            zoomable: true,
            //zoomKey: 'ctrlKey',
            moveable: true,
            showCurrentTime: true,
            //zoomMin: 1000 * 60 * 60 * 24 * 365 * 3, // 3 year
            //zoomMax: 1000 * 60 * 60 * 24 * 365 * 10, // 5 years
            width: '100%',
            height: '120px',
            margin: {
                item: 20,
                axis:20
            },
            format: {
                minorLabels: {
                month: 'MMM',
                year: 'YYYY',
                },
                majorLabels: {
                year: 'YYYY',
                },
            },
        };
  
        // Initialize the timeline with the container, items, and options
        this.timeline = new Timeline(container, items, options); // Initialize with Timeline from vis-timeline
      }
    },
  };
  </script>
  
  <style>
  /* Optional styles to customize the look of timeline items */
  .vis-time-axis{
    font-size: 10px;
}


  </style>
  