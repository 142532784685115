<template>
  <div class="px-0 py-2 ma-0" style="background-color:#f5f5f5" fluid>
    <v-content>
      <v-container class="fill-height px-0 mx-0 pt-0 mt-0" fluid>
        <v-row v-if="showTenders === true" class="pb-0 mx-0">
          <v-col cols="12" class="px-1 py-1 mb-2">
            <v-tabs color="rgb(18, 37, 58)" v-model="active_view" show-arrows>
              <v-tabs-slider color="rgb(255, 20, 99)"></v-tabs-slider>
              <v-tab
                v-for="item in views"
                :key="item.value"
                @click="toggle_view(item.value, item.index)"
              >
                <v-icon left>{{ item.icon }}</v-icon>
                {{ item.label }}
              </v-tab>
            </v-tabs>
          </v-col>

          <!-- here -->
          <v-col cols="12" class="px-1 pt-0 mt-0">
            <v-card class="pa-0 mx-0" flat tile>
              <tender-results
                :current_view="current_view"
                :tenders="tenders"
                :search_terms="search_terms"
                :search_changed="search_changed"
                :search_request="search_request"
                :global_search="global_search"
                :search_history="search_history"
              />
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-snackbar
        v-model="snackbar"
        :bottom="y === 'bottom'"
        :color="snackbar_colour"
        :left="x === 'left'"
        :multi-line="mode === 'multi-line'"
        :right="x === 'right'"
        :timeout="msg_timeout"
        :top="y === 'top'"
        :vertical="mode === 'vertical'"
      >
        {{ response_msg }}
        <v-btn dark text @click="snackbar = false">
          Close
        </v-btn>
      </v-snackbar>
    </v-content>
    <router-view></router-view>
  </div>
</template>

<script>
//import TenderFilters from "../components/TenderFilters.vue";
import TenderResults from "../components/TenderResults.vue";

import { bus } from "../main.js";

export default {
  components: {
    //TenderFilters,
    TenderResults
  },
  props: {
    drawer: Boolean,
    login_menu: Boolean,
    authenticated: Boolean
  },
  data() {
    return {
      active_view: Number,
      showTenders: true,
      showEntities: false,
      search_request: false,
      search_terms: {},
      entity_search: {},
      global_search: false,
      //view_index: null,
      selected_view: null,
      views: [
        //{ label: "Summary", value: "snapshot", icon: "mdi-view-headline" },
        {
          label: "Data",
          value: "table",
          icon: "mdi-format-list-bulleted",
          index: 0
        },
        {
          label: "Leaderboard",
          value: "leaderboard",
          icon: "mdi-sort-variant",
          index: 1
        },
        { label: "Network",
          value: "network",
          icon: "mdi-graphql",
          index: 2 
        },
        {
          label: "Insights",
          value: "report",
          icon: "mdi-chart-box-outline",
          index: 3
        }/* ,
        {
          label: "Summary",
          value: "overview",
          icon: "mdi-radar",
          index: 4
        } */
        /* ,
        { label: "Suppliers",
          value: "suppliers",
          icon: "mdi-account-multiple",
          index: 4 
        },
        { label: "Buyers",
          value: "buyers",
          icon: "mdi-domain",
          index: 5 
        } *//* ,
        {
          label: "Spend Tracker",
          value: "procurement_analysis",
          icon: "mdi-radar"
        } */

      ],
      views_mobile: [
        //{ label: "Summary", value: "snapshot" },
        { label: "Data", value: "table" },
        { label: "Network", value: "network" },
        { label: "Analysis", value: "report" }
      ],
      selected: "table",
      current_view: "table",
      current_entity: {},
      search_changed: Number,
      search_history: [],
      response_msg: "Invalid username or password. Please try again.",
      msg_timeout: 7000,
      snackbar: false,
      snackbar_colour: "#4EB888",
      x: null,
      y: "top",
      mini: true
      //results_drawer: null
    };
  },

  watch: {
    $route(to, from) {

      var query = JSON.parse(JSON.stringify(this.$route.query));

      if (query.hasOwnProperty("viewIndex")){
        this.active_view = parseInt(query.viewIndex);
        this.current_view = this.views[this.active_view].value;
      }
      else{
        this.active_view = 0;
        this.current_view = this.views[this.active_view].value;
      }

      this.process_route();
    }
  },

  created() {

    var query = JSON.parse(JSON.stringify(this.$route.query));

    if (query.hasOwnProperty("viewIndex")){
      this.active_view = parseInt(query.viewIndex);
      this.current_view = this.views[this.active_view].value;
    }
    else{
      this.active_view = 0;
      this.current_view = this.views[this.active_view].value;
    }
    
    this.process_route();


    bus.$on("get-last-search", get_search => {
      ////////console.log("BEFORE POP: ", JSON.stringify(this.search_history));
      this.showTenders = true;
      this.showEntities = false;
      this.search_request = true;
      this.search_terms = this.search_history.pop();
      this.search_changed = (Math.random() + Date.now()) * Math.random();
      ////////console.log("AFTER POP: ", JSON.stringify(this.search_history));
    });

    bus.$on("update_search_terms", new_search => {
      this.search_history.push(this.search_terms);
      this.search_terms = new_search;
    });

    bus.$on("typeFilter", typeFilter => {
      var query = JSON.parse(JSON.stringify(this.$route.query));

      ////////console.log("BEFORE POP: ", JSON.stringify(this.search_history));

      query["disposition"] = typeFilter.disposition;

      if (typeFilter.disposition == "Request") {
        ////////console.log("DATE: " + this.getDate());
        query["expiry_date_from"] = this.getDate();
      }

      this.$router.replace({ query: query }).catch(err => {});
      //this.$router.push({ path: "/search", query: JSON.parse(query) });
    });

    bus.$on("updateSort", sortQuery => {
      var query = JSON.parse(JSON.stringify(this.$route.query));

      ////////console.log("BEFORE POP: ", JSON.stringify(this.search_history));

      query["sort_field"] = sortQuery.sort_field;
      query["sort_order"] = sortQuery.sort_order;

      this.$router.replace({ query: query }).catch(err => {});
      //this.$router.push({ path: "/search", query: JSON.parse(query) });
    });


    bus.$on("search-complete", search_complete => {
      ////////console.log(`SEARCH COMPLETE ${search_complete}`);
      this.showTenders = true;
      this.showEntities = false;
      this.search_request = search_complete;
      this.global_search = false;
    });

    bus.$on("retrieve-search", query => {
      ////////console.log(`RETRIEVE SEARCH EVENT: ${query}`);
      this.showTenders = true;
      this.showEntities = false;
      this.search_request = true;
      this.search_history.push(this.search_terms);
      this.search_terms = query;
      this.search_changed = (Math.random() + Date.now()) * Math.random();
      ////////console.log(`IN SEARCH.VUE `, query);
    });

    bus.$on("global-search", query => {
      ////////console.log(`IN SEARCH.VUE ${search_params.tender}`);
      this.showTenders = true;
      this.showEntities = false;
      this.search_request = true;
      this.search_history.push(this.search_terms);
      this.search_terms = query;
      this.global_search = true;
      this.search_changed = (Math.random() + Date.now()) * Math.random();
      ////////console.log(`IN SEARCH.VUE ${query}`);
    });

    bus.$on("plaintext-search", query => {
      ////////console.log(`IN SEARCH.VUE ${search_params.tender}`);
      this.showTenders = true;
      this.showEntities = false;
      this.search_request = true;
      this.search_history.push(this.search_terms);
      this.search_terms = query;
      this.search_changed = (Math.random() + Date.now()) * Math.random();
      ////////console.log(`IN SEARCH.VUE ${query}`);
    });

    bus.$on("endUserMsg", data => {
      ////////console.log("END USER MESSAGE! :)" + data);
      this.response_msg = data.message;
      this.snackbar_colour = data.color;
      this.msg_timeout = data.timeout;
      this.snackbar = true;
    });

    bus.$on("mini", showHide => {
      this.mini = !this.mini;
    });
  },

  mounted() {
    //this.selected_view = { label: "Overview", value: "snapshot" };
    this.showTenders = true;
    this.showEntities = false;

    bus.$on("entity-selected", entity => {
      this.showTenders = false;
      this.showEntities = true;
      this.buildQuery(entity.id);
      this.current_entity = entity;
    });
  },

  methods: {
    getDate() {
      var d = new Date(),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },

    process_route() {
      this.search_request = true;

      this.search_history.push(this.search_terms);

      ////////console.log("$route.query: ", JSON.stringify(this.$route.query));
      this.search_terms = this.$route.query;
      this.search_changed = (Math.random() + Date.now()) * Math.random();
    },

    toggle_view(value, view_index) {
      //this.current_grouping = this.grouping_dropdown.item.value;

      if (value == "network" || value == "report") {
        let hide = true;
        bus.$emit("hide-side-navs", hide);
      }

      this.current_view = value;
      this.$router.push({ query: Object.assign({}, this.$route.query, { viewIndex: view_index }) });

      ////////console.log("SELECTED VIEW: ", selected_view);
      //c,onsole.log("Value: ", this.current_view);
    },

    buildQuery(id) {
      this.search = {};
      this.search.publisher_id = [id];
      //this.search.size = 5;
      ////////console.log("Pub Profile QUERY ", this.search)
    }
  }
};
</script>
<style>
  .sticky {
    position: fixed;
    top: 100 !important;
    width: 100%
  }

  .fixed-tabs-bar .v-tabs__bar {
    position: -webkit-sticky;
    position: sticky;
    top: 56;
    z-index: 2;
  }
</style>
