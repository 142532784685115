<template>
  <div class="pa-0 ma-0" style="background-color:#f5f5f5" fluid>
    <v-content>
      <v-container class="px-0 pt-3 mt-0 mr-2" fluid>
        <v-row class="ma-0 pt-0" fluid>
          <v-col cols="12" md="12" class="pt-0 mb-0 pb-0">
            <entity-profile v-if="current_entity.name"
              :entity="current_entity"
              :current_search="search"
              :entity_id="entity_id"
            />
          </v-col>
        </v-row>
      </v-container>
      <router-view></router-view>
    </v-content>
  </div>
</template>

<script>
import EntityProfile from "../components/entities/EntityProfile";
import { bus } from "../main.js";
import { fetchProfile } from "../components/APIManager";

export default {
  components: {
    EntityProfile
  },

  data() {
    return {
      search: { publisher_id: Number },
      current_entity: {},
      global_search: false,
      view_index: null,

      selected: "snapshot",
      current_view: "snapshot",
      watching: false,
      entity_id: String()

      //results_drawer: null
    };
  },

  created() {
    this.loadEntity();
  },

  mounted() {},

  methods: {
    async loadEntity() {
      this.entity_id = this.$route.params.id;
      this.buildQuery(this.$route.params.id);
      this.current_entity = await this.fetchEntity(this.$route.params.id);
      //////////console.log("BUYER ENTITY: " + JSON.stringify(this.current_entity));
    },

    async fetchEntity(id) {
      let results = {};

      results = await fetchProfile(id);

      return results;
    },

    buildQuery(id) {
      this.search = {};
      this.search["publisher_id"] = [id];
      //this.search.size = 5;
      //////////console.log("Pub Profile QUERY ", this.search)
    },

    isEmpty(obj) {
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
      }
      return true;
    }
  },

  watch: {
    $route(to, from) {
      this.loadEntity();
    },

    publisher() {
      this.loadEntity();
    }
  }
};
</script>
