<template>
  <div class="pa-0 ma-0" style="background-color:#f5f5f5" fluid>
    <v-content>
      <v-container class="px-0 pt-3 mt-0 mr-2" fluid>
        <v-row class="ma-0 pt-0" fluid>
          <v-col cols="12" md="12" class="pt-0 mb-0 pb-0">
            <category-profile
              :category="current_category"
              :current_search="search"
              :category_id="category_id"
            />
          </v-col>
        </v-row>
      </v-container>
      <router-view></router-view>
    </v-content>
  </div>
</template>

<script>
import CategoryProfile from "../components/entities/CategoryProfile";
//import { bus } from "../main.js";
import { fetchCategory } from "../components/APIManager";

export default {
  components: {
    CategoryProfile
  },

  data() {
    return {
      search: { supplier_id: Number },
      current_category: {},
      related_entities: {},
      global_search: false,
      view_index: null,

      selected: "snapshot",
      current_view: "snapshot",
      watching: false,
      category_id: String()

      //results_drawer: null
    };
  },

  async created() {
    this.loadCategory();

    ////////console.log("BUYER ENTITY: " + JSON.stringify(this.current_entity));
  },

  mounted() {},

  methods: {
    async loadCategory() {
      this.category_id = this.$route.params.id;
      this.buildQuery(this.$route.params.id);
      var results = await this.fetchEntity(this.$route.params.id);
      //this.related_entities = results.related_entities;
      this.current_category = results;
    },

    async fetchEntity(id) {
      let results = {};

      results = await fetchCategory(id);
      
      return results;
    },

    buildQuery(id) {
      this.search = {};
      this.search["category_id"] = [id];
      //this.search.size = 5;
      ////////console.log("Pub Profile QUERY ", this.search)
    },

    isEmpty(obj) {
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
      }
      return true;
    }
  },

  watch: {
    $route(to, from) {
      this.loadCategory();
    },

    category() {
      this.loadCategory();
    }
  }
};
</script>
