<template>
  <v-card width="100%" class="mr-5" flat>
    <v-card-title color="#12253a">
      <v-icon color="#12253a" medium left>mdi-history</v-icon>
      <span class="title font-weight-bold subtitle-1">Recent Searches</span>
      <span>
        <v-btn icon @click="loading = true, getRecentSearches()">
          <v-icon color="secondary">mdi-refresh</v-icon>
        </v-btn>
      </span>
      <span>
        <v-btn
          icon
          href="https://docs.tendertrace.com/guide/user-documentation/saving-your-work/search-history"
          target="_blank"
        >
          <v-icon class="material-icons" color="secondary">mdi-help-circle-outline</v-icon>
        </v-btn>
      </span>
    </v-card-title>

    <v-divider></v-divider>

    <v-card flat class="px-2 py-2 mx-2 my-2">
      <v-text-field
        v-model="search"
        dense
        solo-inverted
        hide-details
        flat
        label="Search recent searches..."
        append-inner-icon="mdi-magnify"
        class="mt-3"
      ></v-text-field>
    </v-card>

    <v-list v-if="loading">
      <v-skeleton-loader v-bind="attrs" type="list-item-two-line"></v-skeleton-loader>
      <v-skeleton-loader v-bind="attrs" type="list-item-two-line"></v-skeleton-loader>
    </v-list>
    <v-list v-else>
      <v-list-item-group v-if="filteredRecentSearches.length > 0" v-model="selectedItem" color="rgb(18, 37, 58)">
        <v-list-item v-for="item in filteredRecentSearches" :key="item.id">
          <v-tooltip left dark color="#000000" style="opacity: 0.7;">
            <template v-slot:activator="{ on }">
              <v-list-item-subtitle
                v-on="on"
                @click="retrieveSearch(item.search_query)"
                class="wrap-text hvr"
                style="color: #12253a !important;"
              >
                {{ item.plain_text_query }}
              </v-list-item-subtitle>
            </template>
            <span class="tooltip">{{ item.plain_text_query }}</span>
          </v-tooltip>
        </v-list-item>
      </v-list-item-group>
      <v-list-item v-else class="ma-0 pa-0">
        <v-card flat class="ma-0 pa-0">
          <v-card-text>
            <p>
              Tendertrace automatically saves all queries you make so that you can quickly rerun a previous search even if it's not one you are tracking. Your most recent 20 queries will be displayed here.
            </p>
            <p>
              To access your search history from any page in Tendertrace, click on the
              <v-icon>mdi-history</v-icon> button located on the left hand side of the application menu bar.
            </p>
            <p>
              <router-link :to="{ name: 'search',
                                    query: {
                                      disposition: ['Contract Notice','Grant'],
                                      expiry_from: new Date().getFullYear(),
                                      expiry_to: new Date().getFullYear(),
                                      sort_order: 'asc'
                                    }
                                  }"
              >
                Click here to start exploring.
              </router-link>
            </p>
          </v-card-text>
        </v-card>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { bus } from "../main.js";
import axios from "axios";
import { getInstance } from "../auth/index.js";

export default {
  components: {},
  props: { drawer: Boolean },
  data() {
    return {
      loading: true,
      menu: false,
      recent_searches: [],
      selectedItem: Number,
      search: ""
    };
  },

  mounted() {
    this.getRecentSearches();

    bus.$on("refresh_lists", refresh_lists => {
      this.getRecentSearches();
    });

    bus.$on("search-complete", search_complete => {
      this.getRecentSearches();
    });
  },

  computed: {
    filteredRecentSearches() {
      if (this.recent_searches.length > 0){
        return this.recent_searches.filter(search =>
          search.plain_text_query.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      else{
        return this.recent_searches;
      }
    }
  },

  methods: {
    retrieveSearch(query) {
      this.$router.push({ path: "/search", query: JSON.parse(query) });
      bus.$emit(bus.$emit("hide-side-navs", true));
    },

    cancel() {
      this.menu = false;
      bus.$emit("recent-history-close", this.menu);
    },

    async getRecentSearches() {
      const instance = getInstance();
      const accessToken = await instance.getTokenSilently();

      this.loading = true;
      axios
        .get(process.env.VUE_APP_API_SEARCHES, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        .then(response => {
          this.recent_searches = response.data.search_history;
          this.loading = false;
          return this.recent_searches;
        })
        .catch(error => {
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style scoped>
.wrap-text {
  -webkit-line-clamp: unset !important;
}

.tooltip {
  display: inline-block;
  max-width: 360px;
}

.hvr:hover {
  text-decoration: underline !important;
}
</style>
