<template>
  <div>
    <v-card v-if="items"
    class="mx-auto pl-2"
    max-width="500"
  >
    <v-card-title class="pl-3">
          Add / Remove Prospects
        </v-card-title>
    <v-card-subtitle class="pl-3">
          Manage which of your prospect lists you want the selected notice(s) assigned to. Your current selections are <span style="color:rgb(255, 20, 99);">highlighted</span> below.
        </v-card-subtitle>
    <v-container class="py-0">
      <v-row
        align="center"
        justify="start"
      >
        <v-col
          v-for="(selection, i) in selections"
          :key="selection.name"
          class="shrink"
        >
          <v-chip
            :disabled="loading"
            color="primary"
            outlined
            close
            @click:close="selected.splice(i, 1)"
          >
            {{ selection.name }}
          </v-chip>
        </v-col>

        <v-col
          v-if="!allSelected"
          cols="12"
        >
          <v-text-field
            ref="search"
            v-model="search"
            full-width
            hide-details
            label="Search for a list to assign this notice to"
            single-line
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>

    <v-list class="pl-0">
      <template v-for="item in categories">
        <v-list-item
          v-if="!selected.includes(item)"
          :key="item.name"
          :disabled="loading"
          @click="selected.push(item)"
        >
          <v-list-item-title v-text="item.name"></v-list-item-title>
        </v-list-item>
      </template>
    </v-list>

    <v-divider></v-divider>
    <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="$emit('hide-list-manager', true)">
            Cancel
          </v-btn>

          <v-btn
            color="secondary"
            text
            @click="$emit('bookmarked', {selectedLists: selected, bookmarkedItemID: targetItem.id}), $emit('hide-list-manager', true)"
          >
            Save
          </v-btn>
        </v-card-actions>
  </v-card>
  <v-card v-else flat class="ma-0 pa-0">
      <v-card-title>No prospect lists found</v-card-title>
      <v-card-text>
        <p>
          You can create prospect lists and then add any target tenders and
          contracts to those lists. This gives you quick access to those
          lists whenever you need them.
        </p>
        <p>
          To start building lists, run a search for contracts of interest
          and then click the
          <v-icon color="secondary">mdi-bookmark-outline</v-icon> button to add
          that notice to a list.
        </p>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="$emit('hide-list-manager', true)">
            OK
          </v-btn>
        </v-card-actions>
       </v-card-text
      ></v-card
    >
  </div>
</template>
<script>

export default {
  components: {
  },

  props: {
    //results_drawer: Boolean
    targetItem: {},
    items: [],
    selected: [],
    changed: Number
  },
  data() {
    return {
      loading: false,
      search: ''
      
    }
  },
  
  mounted: function() {

    ////console.log("in list manager: ", this.targetItem);

  },

  computed: {
      allSelected () {
        return this.selected.length === this.items.length
      },
      categories () {
        const search = this.search.toLowerCase()

        if (!search) return this.items

        return this.items.filter(item => {
          const text = item.name.toLowerCase()

          return text.indexOf(search) > -1
        })
      },
      selections () {
        const selections = []

        for (const selection of this.selected) {
          selections.push(selection)
        }

        return selections
      },
    },

    watch: {
      selected () {
        this.search = ''
      }

    },

    methods: {

      reset(){
          this.selected = [];
      },

      presetSelected() {

        for (var i in this.items){
            let list = this.items[i].search_query.tender_id;
            
            if (list.includes(parseInt(this.targetItem.id))){
                this.selected.push(this.items[i]);
            }

        }
      }
    }
}
</script>