<template>
  <v-card class="chat-card" tile>
    <v-card-title id="queryWindow">
      <v-icon color="secondary" left small>mdi-creation</v-icon><span class="title font-weight-bold">Tracey</span>
        </v-card-title>
        <v-card-subtitle>Tell Tracey what you would like to search for.</v-card-subtitle>
        <v-divider></v-divider>
        <v-card-actions class="chat-window">
          <v-textarea 
            label="'E.g. show me the 5 largest contracts this year'"
                    clearable
                    clear-icon="mdi-close-circle"
                    rows="3"
                    auto-grow
                    v-model="userQuery" 
                    filled
                    class="text-body-2"
                    @keydown.enter="sendMessage"></v-textarea>
                    
        </v-card-actions>
          <v-card-text class="pt-1">
            <v-row v-if="loadingHistory==true">
              <v-col cols="12">
                <v-progress-linear
                  indeterminate
                  size="36"
                  color="primary"
                ></v-progress-linear>
              </v-col>
            </v-row>
            <v-row v-else v-for="(message, index) in messages" :key="index">
              <v-col class="py-0 my-0 mx-auto">
                <div class="message-container">
                  <div class="message-header">
                    <span class="timestamp">{{ message.timestamp }}</span>
                      <div class="flex-grow-1"></div>
                      <span>
                        <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                          <template v-slot:activator="{ on }">
                            <v-btn v-on="on" color="primary" icon @click="selectedToDelete=message, delete_query=true">
                              <v-icon>mdi-delete-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Delete this query</span>
                        </v-tooltip>
                      </span>
                      <span>
                        <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                          <template v-slot:activator="{ on }">
                            <v-btn v-on="on" color="primary" icon @click="editMessage(message)">
                              <v-icon>mdi-pencil-circle-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Edit this query</span>
                        </v-tooltip>
                      </span>
                      <span >
                        <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                          <template v-slot:activator="{ on }">
                            <v-btn v-on="on" color="primary" icon @click="playQuery(message)">
                              <v-icon>mdi-play-circle-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Run this query</span>
                        </v-tooltip>

                      </span>
                    </div>
                    <div class="message-limit">QUERY</div>
                    <div class="message-filter-content">{{ message.text }}</div>
                    <div v-if="message.status" class="message-limit">RESULT</div>
                    <div class="message-filter-content">
                      <span v-if="message.status == 1000"><v-icon color="rgb(255, 231, 10)">mdi-dots-horizontal-circle-outline</v-icon><span class="pl-1">Pending</span></span>
                      <span v-else-if="message.status == 200" ><v-icon color="green">mdi-check-circle-outline</v-icon><span class="pl-1">{{message.rows}} rows returned</span></span>
                      <span v-else ><v-icon color="#de004b">mdi-alert-circle-outline</v-icon><span class="pl-1">Unsuccessful. Try changing you query.</span></span>
                    </div>
              </div>
              </v-col>
            </v-row>
          
        </v-card-text>

      <v-dialog v-model="delete_query" max-width="500" absolute>
        <v-card>
          <p class="title mx-auto pa-3">
            <span>Delete Query: </span> <span v-if="selectedToDelete"> {{selectedToDelete["title"]}}</span>?
          </p>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="delete_query = false">
              Cancel
            </v-btn>

            <v-btn
              color="#4EB888"
              text
              @click="(delete_query = false), deleteQuery(selectedToDelete.id)"
            >
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { fetchAIQueries, deleteAIQuery } from "./APIManager"

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    responseStatus: Object,
    responseStatusChanged: Number
  },
  data() {
    return {
      selectedToDelete: null,
      delete_query: false,
      isLoading: false, 
      loadingHistory: false, 
      isError: false,
      showData: false,
      internalDialog: this.value,
      viewType: null,
      userQuery: "",
      responseSQL: "",
      messages: [],
      
    };
  },

  mounted(){
    this.fetchData();
  },

  watch: {
    value(newVal) {
      //this.internalDialog = newVal;
    },
    internalDialog(newVal) {
      //this.$emit("input", newVal);
    },
    responseStatusChanged(newVal) {
      this.messages[0].status = this.responseStatus.text;
      this.messages[0].rows = this.responseStatus.rows;
      this.messages[0].id = this.responseStatus.id;
      this.messages[0].title = this.responseStatus.name;
    },
  },
  methods: {

    editMessage(message) {

      this.$nextTick(() =>
          document.getElementById('queryWindow').scrollIntoView({behavior: 'smooth'})
      );

      this.userQuery = message.text;
      
    },

    formattedTimestamp(timestamp) {
      var optionsTime = { hour: 'numeric', minute: 'numeric', hour12: true };
      var optionsDate = { year: 'numeric', month: 'short', day: 'numeric' };
      return new Date(timestamp).toLocaleTimeString(undefined, optionsTime) + ', ' +
        new Date(timestamp).toLocaleDateString(undefined, optionsDate);
    },

    getToday() {
      var optionsTime = { hour: 'numeric', minute: 'numeric', hour12: true };
      var optionsDate = { year: 'numeric', month: 'short', day: 'numeric' };
      return new Date().toLocaleTimeString(undefined, optionsTime) + ', ' +
        new Date().toLocaleDateString(undefined, optionsDate);
    },

    getDate() {
      const dte = new Date();
      return dte.toISOString();
    },

    formatDateToCustomString(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const milliseconds = String(date.getMilliseconds()).padStart(3, '0');
        
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
    },

    playQuery(message){
      //this.userQuery = message.text;
      //if (this.userQuery.trim() === "") return;

      this.messages = this.messages.filter(item => item.id !== message.id);
      this.messages.unshift({ text: message.text, title: message.title, id: message.id, status: message.status, rows: message.rows, sql: message.sql, timestamp:  this.getToday()});

      this.$emit("fetchData", {input: message.text, message_id: message.id, timestamp: this.formatDateToCustomString(new Date())})
      this.userQuery = "";
    },

    sendMessage() {
      if (this.userQuery.trim() === "") return;

      this.messages.unshift({ text: this.userQuery, status: 1000, rows:0, timestamp:  this.getToday()});
      //this.$router.push({ path: "/search", query: {input: this.userQuery} });
      //this.fetchData({input: this.userQuery})
      this.$emit("fetchData", {input: this.userQuery, timestamp: this.formatDateToCustomString(new Date())})
      this.userQuery = "";
      
      // After sending a message, simulate a reply from the other party

      this.internalDialog = false;
    },

    deleteQuery (id){

      //console.log(id, " ", this.selectedToDelete);
      let target_id = id;

      this.loadingHistory = true;

      this.messages = this.messages.filter(item => item.id !== id);

      deleteAIQuery(target_id);

      this.loadingHistory = false;
    },

    fetchData() {
      this.loadingHistory = true;

      fetchAIQueries()
        .then(response => {
          //console.log(response);
          var data = response.data;

          for (var i in data){
            this.messages.unshift({ text: data[i].user_query, title:data[i].ai_response_title, id: data[i].id, status: data[i].ai_response_status, rows: data[i].num_rows_returned, sql: data[i].ai_sql_query_response, timestamp:  this.formattedTimestamp(data[i].created)});
          }
          /* this.userQuery = response.data.user_query;
          this.responseSQL = response.data.ai_sql_query_response;
          this.responseStatus.text=response.data.ai_response_status;
          this.responseStatus.rows="-"; */
        })
        .catch(() => {
          this.isError = true;
          this.responseStatus.text= 500;
          this.responseStatus.rows= 0;
        })
        .finally(() => {
          this.loadingHistory = false;
        });
    },

  },
};
</script>

<style scoped>

.chat-card {
  width: 25%;
  height: 100vh; /* Full height of the viewport */
  padding-bottom: 20px;
  position: fixed; /* Make it float over other content */
  overflow-y: auto; /* Scrollable if content is too tall */
  /*border: 10px solid red;*/
}

.chat-window {
  /*position: fixed;*/
  /*top: 0;*/
  width: 100%;
}

.sent {
  background-color: #ff1463;
}
.received {
  background-color: #12253a;
}

.message-container {
  border: 1px solid #d1d1d1;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  position: relative;
}

.message-header {
  font-weight: bold;
  color: #888;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.message-filter {
  font-weight: bold;
  color: #000;
  margin-bottom: 5px;
}

.message-filter-content {
  color: #555;
  margin-bottom: 3px;
  border: 1px solid #d1d1d1;
  padding: 5px; 
  border-radius: 5px;
}

.message-limit {
  color: #555;
  font-weight: bold;
  margin-top: 5px;
}
</style>

