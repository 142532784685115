<template>
  <div class="pl-0">
    <v-card>
      <v-card-title color="#12253a">
        <v-icon color="#12253a" medium left>{{ menuIcon }}</v-icon>
        <span class="font-weight-bold subtitle-1">{{ menuName }}</span>
        <span>
          <v-btn icon @click="loading = true, loadClusters()">
            <v-icon color="secondary">mdi-refresh</v-icon>
          </v-btn>
        </span>
        <span>
          <v-btn
            icon
            href="https://docs.tendertrace.com/guide/user-documentation/clusters"
            target="_blank"
          >
            <v-icon class="material-icons" color="secondary">mdi-help-circle-outline</v-icon>
          </v-btn>
        </span>
      </v-card-title>

      <v-divider></v-divider>
      <v-card v-if="loading" flat>
        <v-list>
          <v-skeleton-loader v-bind="attrs" type="list-item-two-line"></v-skeleton-loader>
          <v-skeleton-loader v-bind="attrs" type="list-item-two-line"></v-skeleton-loader>
        </v-list>
      </v-card>
      <v-card v-else flat class="px-2 py-2 mx-2 my-2">
        <v-text-field
          v-model="newClusterName"
          dense
          solo-inverted
          hide-details
          flat
          append-outer-icon="mdi-plus-circle-outline"
          label="New cluster name..."
          @click:append-outer="postCluster()"
          @keyup.enter="postCluster()"
        ></v-text-field>

        <v-text-field
          append-icon="mdi-magnify"
          v-model="search"
          dense
          solo-inverted
          hide-details
          flat
          label="Search clusters..."
          class="mt-3"
        ></v-text-field>

        <v-list-item-group>
          <v-list-item v-for="cluster in filteredClusters" :key="cluster.id">
            <v-list-item-content @click="fetchClusterData(cluster.id)">
              <v-list-item-title>{{ cluster.name }}</v-list-item-title>
              <v-list-item-subtitle>
                {{ cluster.search_query[entityIDType].length }} entities
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-icon>
              <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                    color="secondary"
                    light
                    style="text-decoration: none !important;"
                    @click="fetchClusterData(cluster.id)"
                  >
                    <v-icon>mdi-format-list-bulleted</v-icon>
                  </v-btn>
                </template>
                <span>View all {{ cluster.name }} notices</span>
              </v-tooltip>
              <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                <template v-slot:activator="{ on }">
                  <v-btn
                    right
                    color="secondary"
                    icon
                    target="_blank"
                    :to="'/cluster/' + cluster.id"
                    v-on="on"
                  >
                    <v-icon medium color="secondary">mdi-open-in-new</v-icon>
                  </v-btn>
                </template>
                <span>View / Edit {{ cluster.name }} Cluster Profile</span>
              </v-tooltip>
              <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                <template v-slot:activator="{ on }">
                  <v-btn right icon @click="selectedItem = cluster, delete_cluster = true" v-on="on">
                    <v-icon medium color="primary">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete {{ cluster.name }} Cluster</span>
              </v-tooltip>
            </v-list-item-icon>
          </v-list-item>
        </v-list-item-group>
      </v-card>
    </v-card>
    <v-dialog v-model="delete_cluster" max-width="500" absolute>
      <v-card>
        <p class="title mx-auto pa-3">
          <span>Delete Cluster: </span>
          <span v-if="selectedItem"> {{ selectedItem["name"] }}</span>?
        </p>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="delete_cluster = false">
            Cancel
          </v-btn>

          <v-btn color="#4EB888" text @click="(delete_cluster = false), deleteCluster(selectedItem.id)">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="edit_cluster" max-width="1200" absolute>
      <v-card>
        <p class="title mx-auto pa-3">
          <span>Edit Cluster: </span>
          <span v-if="selectedItem"> {{ selectedItem["name"] }}</span>
        </p>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getClusters, updateClusters, addCluster, deleteCluster } from "./APIManager.js";
import { bus } from "../main.js";

export default {
  components: {
    //ClusterEntities
  },
  props: {
    current_buyer_flag: Boolean,
    disabled: Boolean,
    entityType: String,
    entityIDType: String,
    entities: [],
    menuName: String,
    menuIcon: String
  },
  data: () => ({
    selectedItem: null,
    delete_cluster: false,
    edit_cluster: false,
    newClusterName: "",
    search: "",
    clusters: [],
    loading: true,
    clusterEntities: [],
    selectedClusters: [],
    fav: true,
    menu: false,
    message: false,
    hints: true,
    active: true,
    years: [2016, 2025],
    year_min: 2016,
    year_max: 2025,
    keyword_chips: [],
    keyword_items: [],
    selected_types: [],
    include_publisher: true,
    expiryRangeMenu: false,
    expiryRange: []
  }),

  mounted() {
    this.loadClusters();
  },

  computed: {
    filteredClusters() {
      return this.clusters.filter(cluster =>
        cluster.name.toLowerCase().includes(this.search.toLowerCase())
      );
    }
  },

  watch: {
    delete_cluster(value) {
      if (!value) {
        this.loadClusters();
      }
    }
  },

  methods: {
    deleteCluster,

    fetchClusterData(cluster_id) {
      if (this.entityType === "supplier") {
        this.$router.push({
          name: "search",
          query: {
            supplier_cluster: [cluster_id],
            sort_order: "asc",
            viewIndex: 0
          }
        });
      } else {
        this.$router.push({
          name: "search",
          query: {
            buyer_cluster: [cluster_id],
            sort_order: "asc",
            viewIndex: 0
          }
        });
      }

      bus.$emit(bus.$emit("hide-side-navs", true));
    },

    async postCluster() {
      this.loading = true;

      addCluster(encodeURIComponent(this.newClusterName), this.entityType).then(results => {
        this.clusters = results.data;
        for (var i in this.clusters) {
          this.clusters[i].search_query = JSON.parse(this.clusters[i].search_query);
        }
        this.newClusterName = "";
        this.loading = false;
      });
    },

    async postClusters() {
      this.loading = true;
      var targetClusters = [];

      if (!this.selectedClusters) {
        for (var i in this.clusters) {
          targetClusters.push(this.clusters[i].id);
        }
      } else {
        targetClusters = this.selectedClusters;
      }

      updateClusters(targetClusters, this.entityType, this.entities).then(results => {
        this.clusters = results.data;
        for (var i in this.clusters) {
          this.clusters[i].search_query = JSON.parse(this.clusters[i].search_query);
        }
        this.loading = false;
      });
    },

    async loadClusters() {
      this.loading = true;

      getClusters(this.entityType).then(results => {
        this.clusters = results.data;
        for (var i in this.clusters) {
          this.clusters[i].search_query = JSON.parse(this.clusters[i].search_query);
        }

        this.loading = false;
      });
    },

    exists(cluster_entities) {
      var exists = cluster_entities.some(item => this.entities.includes(item));
      return exists;
    },

    partialExists(cluster_entities) {
      let totalEntities = this.entities.length;
      let numMatches = 0;
      let partialMatch = false;

      for (let i = 0; i < this.entities.length; i++) {
        if (cluster_entities.indexOf(this.entities[i]) === -1) {
          partialMatch = true;
          break;
        } else {
          numMatches++;
        }
      }

      if (numMatches === totalEntities) {
        partialMatch = false; // is a full match so indeterminate should be false
      }

      return partialMatch;
    },

    year() {
      var d = new Date();
      return d.getFullYear();
    },

    remove_kw(item) {
      this.keyword_chips.splice(this.keyword_chips.indexOf(item), 1);
      this.keyword_chips = [...this.keyword_chips];
    },

    clear_expiryRange() {
      this.expiryRange = [];
    },

    update_search() {
      let additional_filters = {
        keywords: this.keyword_chips,
        expiry_from: this.expiryRange[0],
        expiry_to: this.expiryRange[1],
        type: this.selected_types,
        include_publisher: this.include_publisher
      };

      bus.$emit("update-filter", additional_filters);
    }
  }
};
</script>

<style scoped>
.v-application--is-ltr .v-list-group--sub-group .v-list-group__header {
  padding-left: 0px !important;
}

.wrap-text {
  -webkit-line-clamp: unset !important;
}

.tooltip {
  display: inline-block;
  max-width: 360px;
}

.border {
  border: 1px solid rgb(255, 20, 99);
}

.hvr:hover {
  text-decoration: underline !important;
}
</style>
