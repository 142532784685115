var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-0 ma-0 fill-height",staticStyle:{"background-color":"#f5f5f5"},attrs:{"fluid":""}},[_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300","lazy":""},model:{value:(_vm.overlay),callback:function ($$v) {_vm.overlay=$$v},expression:"overlay"}},[_c('v-card',{attrs:{"color":"secondary","dark":""}},[_c('v-card-text',[_vm._v(" Building list... "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1),(!_vm.overlay)?_c('v-content',[_c('v-row',{staticClass:"px-0 py-0",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"color":"#f5f5f5","flat":"","tile":"","max-width":"1400"}},[_c('p',{staticClass:"pt-6 display-1 font-weight-medium",staticStyle:{"text-align":"center"}},[_vm._v(" Custom Reports ")]),_c('p',{staticClass:"body-1 font-weight-normal",staticStyle:{"text-align":"center"}},[_vm._v(" This is the list of custom reports that you have on Tendertrace. Only you can see this list. ")]),_c('v-row',{staticClass:"px-0 py-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-5",staticStyle:{"background-color":"white"}},[_c('v-text-field',{staticStyle:{"font-size":"16px !important"},attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","outlined":"","clearable":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1 py-2",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.boards},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"editor-link",staticStyle:{"font-size":"14px !important","font-weight":"500 !important"},attrs:{"to":'/report/'+item.id}},[_vm._v(_vm._s(_vm.stripHTML(item.title)))])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.stripHTML(item.description))+" ")]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"14px !important"}},[_vm._v(_vm._s(_vm.getMonthYear(item.created)))])]}},{key:"item.public",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"input-value":item.public,"color":"primary","value":"","disabled":""}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"target":"_blank","to":'/report/'+item.id}},[_c('v-icon',{staticClass:"mr-4",attrs:{"medium":""}},[_vm._v("mdi-open-in-new")])],1),_c('v-icon',{attrs:{"medium":""},on:{"click":function($event){_vm.selectedItem = item, _vm.deleteReport = true}}},[_vm._v("mdi-delete")])]}}],null,false,2917319395)})],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500","absolute":""},model:{value:(_vm.deleteReport),callback:function ($$v) {_vm.deleteReport=$$v},expression:"deleteReport"}},[_c('v-card',[_c('p',{staticClass:"title mx-auto pa-3"},[_c('span',[_vm._v("Delete report: ")]),_vm._v(" "),(_vm.selectedItem)?_c('span',[_vm._v(" "+_vm._s(_vm.selectedItem.title))]):_vm._e(),_vm._v("? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.deleteReport = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"#4EB888","text":""},on:{"click":function($event){(_vm.deleteReport = false), _vm.removeBoard(_vm.selectedItem.id)}}},[_vm._v(" Delete ")])],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }