<template>
  <div :id="id" :style="reportLineMini"></div>
</template>

<script>
import * as echarts from "echarts";
import {downloadFile} from "./Utils";
//import $ from 'jquery';
//import jsondata from "../temp/life-expectancy-table.json";

export default {

 props:{
     series: Array(),
     legend_data: Array(),
     x_axis_data: [],
     changed: Number,
     showLegend: Boolean(),
     download: Number,
     metric_type: String,
     target_group: String,
     interval: String,
     story: Boolean,
     embed: Number,
     chart_options: {},
     id: Number,
      chart_size: {
        type: String(),
        default: "large"
      },
    chartHeight:{
      type: String,
      default: "200px"
    }
 },
  data() {
    return {
      options: {},
      myChart: null,
      canvasCSS: String(),
      chartWidth: Number(),
      legend_padding: Number()
    };
  },

  watch: {
    changed() {
      //this.myChart.dispose();
      this.loadChart();
    },

    embed() {
      ////////////console.log("CALL SHARE CHART\n", this.myChart);
      
      this.saveChart();
    },

    download() {
      this.saveImage();
    }
  },

  mounted() {
      this.loadChart();
  },

  methods: {

    saveChart(){

      var imgUrl = this.myChart.getDataURL();

      let data = {
        imgData: imgUrl, //this.myChart.getDataURL(),
        options: this.options
      };

      this.$emit("chart_object", data);

      ////////////console.log("LINE CHART PAYLOAD: ", data);

    },

    saveImage(){

      var img = new Image();
      
      img.src = this.myChart.getDataURL({
          pixelRatio: 2,
          backgroundColor: '#fff'
      });

      downloadFile(img.src);

    },

    loadChart() {

        if (this.myChart != null && this.myChart != "" && this.myChart != undefined) {
            this.myChart.dispose();
        }

        var chartDom = document.getElementById(this.id);
        this.myChart = echarts.init(chartDom);

        if (this.story){
          
          //set end labels
          for (let i in this.chart_options.series){
            this.chart_options.series[i].endLabel.formatter = '{a}';
          }

          this.chart_options.tooltip.formatter = function (params){
              function convert (num){
                if (num > 999 && num < 1000000) {
                  num = (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
                } else if (num >= 1000000 && num < 1000000000) {
                  num = (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
                } else if (num >= 1000000000) {
                  num = (num / 1000000000).toFixed(1) + "B"; // convert to M for number from > 1 million
                }
                return num;
              }

              function getColor(number){
                if (number.includes("-")) return "red";
                else if (number.includes("+")) return "green";
                else return "black";
              }

              var infocard = [];

              for (var i in params){
                let val = params[i].data.value;

                if (params[i].data.type == "total_amount"){
                  val = "$" + convert(val);
                }

                //let iconStyle = '"background-color:'+params[i].data.color + 'flex-shrink: 0; width: 14px;height: 14px;border-radius: 50%;margin-right: 12px;display: inline; align-items: center;justify-content: center;fill: #fff;border: 2px solid #fff;box-shadow: 0 2px 4px rgb(0 0 0 / 25%);"';
                //iconStyle = iconStyle + 'background-color:'+params[i].data.color;
                var colorSpan = color => '<span style="display:inline-block;margin-right:5px;border-radius:50%;width:14px;height:14px;border: 2px solid #fff;box-shadow: 0 2px 4px rgb(0 0 0 / 25%);background-color:' + color + '"></span>';
                infocard[i] = [
                  '<tr><td style="text-align: left;">',
                    //'<div style="color: #12253a; font-size:12; font-family:Roboto; font-weight:normal;">',
                      ''+colorSpan(params[i].color)+'',
                      '<span style="color: #12253a; font-size:12; font-family:Roboto; font-weight:normal; padding-right:10px;">' + params[i].seriesName + '</span>',
                  '</td>',
                  '<td style="text-align: left;">',
                      '<span style="color: #12253a; font-size:12; font-family:Roboto; font-weight:bold; padding-right:10px;">' + val + '</span>',
                  '</td>',
                  '<td style="text-align: left;">',
                      '<span style="color:' + getColor(params[i].data.percent) + ';font-size:12; font-family:Roboto; font-weight:bold;">' + params[i].data.percent + '</span>',
                  '</td></tr>',
                      //'<span style="color: #12253a; font-size:12; font-family:Roboto; font-weight:bold; float: right;"> (' + params[i].data.percent + ')</span></div>',
                ].join('');
                
              };

              infocard.unshift('<table align="left"><thead><tr><th style="text-align: left;padding-right:10px;">' + params[i].name + '</th><th style="text-align: left;padding-right:10px;">Total</th><th style="text-align: left;">Change</th></tr></thead>');
              infocard.push('</table>');

              return infocard.join('');
          };
          
          this.chart_options.yAxis.axisLabel.formatter = (s) => {
            function convert (num){
                if (num > 999 && num < 1000000) {
                    num = (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
                } else if (num >= 1000000 && num < 1000000000) {
                    num = (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
                } else if (num >= 1000000000) {
                    num = (num / 1000000000).toFixed(1) + "B"; // convert to M for number from > 1 million
                }

                return num;
            }

            if (this.chart_options.metric_type == "total_amount"){
              return '{valueStyle|' + '$'+ convert(s) + '}';
            }else{
              return '{valueStyle|' + s + '}';
            }
            
        };

          this.myChart.setOption(this.chart_options);
        }
        else{
          var option;


          option = {
              animationDuration:1000,
              color : [
                  "#12253a",
                  "#ff1463",
                  "#d73e32",
                  "#2196f3",
                  "#004700",
                  "#2d9437",
                  "#004d9f",
                  "#007cd6",
                  "#d73e32",
                  "#de004b",
                  "#ff457c",
                  "#4a575d",
                  "#7b898f",
                  "#bb5900",
                  "#bb5900",
                  "#ffa72d",
                  "#4caf50",
                  "#2196f3",
                  "#fb8c00",
                  "#ff1463",
                  "#b71c1c",
                  "#ff937b",
                  "#95e8ff",
                  "#a2ff9e",
                  "#fffb82",
                  "#FFE70A",
                  "#SFS603",
                  "#7F7305",
                  "#BFAB07",
                  "#FF6905",
                  "#5405FF"
                ],
              tooltip: {
                trigger: 'axis',
                formatter: function (params){

                      function convert (num){
                        if (num > 999 && num < 1000000) {
                          num = (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
                        } else if (num >= 1000000 && num < 1000000000) {
                          num = (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
                        } else if (num >= 1000000000) {
                          num = (num / 1000000000).toFixed(1) + "B"; // convert to M for number from > 1 million
                        }
                        return num;
                      }

                      function getColor(number){
                        if (number.includes("-")) return "red";
                        else if (number.includes("+")) return "green";
                        else return "black";
                      }

                      var infocard = [];

                      for (var i in params){
                        let val = params[i].data.value;

                        if (params[i].data.type == "total_amount"){
                          val = "$" + convert(val);
                        }

                        //let iconStyle = '"background-color:'+params[i].data.color + 'flex-shrink: 0; width: 14px;height: 14px;border-radius: 50%;margin-right: 12px;display: inline; align-items: center;justify-content: center;fill: #fff;border: 2px solid #fff;box-shadow: 0 2px 4px rgb(0 0 0 / 25%);"';
                        //iconStyle = iconStyle + 'background-color:'+params[i].data.color;
                        var colorSpan = color => '<span style="display:inline-block;margin-right:5px;border-radius:50%;width:14px;height:14px;border: 2px solid #fff;box-shadow: 0 2px 4px rgb(0 0 0 / 25%);background-color:' + color + '"></span>';
                        infocard[i] = [
                          '<tr><td style="text-align: left;">',
                            //'<div style="color: #12253a; font-size:12; font-family:Roboto; font-weight:normal;">',
                              ''+colorSpan(params[i].color)+'',
                              '<span style="color: #12253a; font-size:11px; font-family:Roboto; font-weight:normal; padding-right:10px;">' + params[i].seriesName + '</span>',
                          '</td>',
                          '<td style="text-align: left;">',
                              '<span style="color: #12253a; font-size:11px; font-family:Roboto; font-weight:bold; padding-right:10px;">' + val + '</span>',
                          '</td>',
                          '<td style="text-align: left;">',
                              '<span style="color:' + getColor(params[i].data.percent) + ';font-size:11px; font-family:Roboto; font-weight:bold;">' + params[i].data.percent + '</span>',
                          '</td></tr>',
                              //'<span style="color: #12253a; font-size:12; font-family:Roboto; font-weight:bold; float: right;"> (' + params[i].data.percent + ')</span></div>',
                        ].join('');
                        
                      };

                      //add header
                      infocard.unshift('<table align="left"><thead><tr><th style="text-align: left;padding-right:10px; font-size:11px; font-family:Roboto;">' + params[i].name + '</th><th style="text-align: left;padding-right:10px; font-size:11px; font-family:Roboto;">Total</th><th style="text-align: left; font-size:11px; font-family:Roboto;">Change</th></tr></thead>');
                      infocard.push('</table>');

                      return infocard.join('');
                  },
              },
              legend: {
                show: this.showLegend,
                icon: "circle",
                orient: 'horizontal',
                textStyle: {
                  fontSize: 9
                },
                padding: [5, 5, 10, 5],
                type: "scroll",
                left: 10,
                //top:'center',
                //bottom: 0
              },
              grid: {
                  left: "16px",
                  bottom: "0px",
                  top: "40px",
                  containLabel: true
              },
              xAxis: {
                  type: 'category',
                  show: true,
                  axisLabel: {
                    fontSize: 10,
                    showMinLabel: true,
                    showMaxLabel: true
                  },
                  
                  boundaryGap: true,
                  data: this.x_axis_data,
                  axisTick:{
                    show: false,
                    interval: "auto"
                  }
              },
              yAxis: {
                  type: 'value',
                  scale: true,
                  axisTick:{
                    show: false,
                    interval: "auto"
                  },
                  splitNumber: 2,
                  splitLine: {
                    show: false
                  },
                  show: true,
                  axisLabel: {
                      showMinLabel: false,
                      showMaxLabel: true,
                      formatter : (s) => {
                          function convert (num){
                              if (num > 999 && num < 1000000) {
                                  num = (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
                              } else if (num >= 1000000 && num < 1000000000) {
                                  num = (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
                              } else if (num >= 1000000000) {
                                  num = (num / 1000000000).toFixed(1) + "B"; // convert to M for number from > 1 million
                              }

                              return num;
                          }

                          if (this.metric_type == "total_amount"){
                            return '{valueStyle|' + '$'+ convert(s) + '}';
                          }else{
                            return '{valueStyle|' + s + '}';
                          }
                          
                      },
                      rich: {
                          valueStyle: {
                              // Make yearly text more standing out
                              color: '#000',
                              fontWeight: 'normal',
                              fontSize: 10
                          }
                      }
                  },
              },
              series: this.series.data
          };

          this.myChart.setOption(option);

          this.options = option;
          this.options.metric_type = this.metric_type;

          //this.saveChart();

          this.myChart.on('click', param => {

            ////////////console.log("line params: ", param);

            let args = [];

            if (this.interval == "year"){
              args[0] = {field: "contract_year_from", value: param.name}
              args[1] = {field: "contract_year_to", value: param.name}
              //args[2] = {field: this.target_group, value: param.seriesName}
            }
            else{

              var year = param.name;
              year = year.split('-');
              year = year[1];
              
              var qtr = param.name;
              qtr = qtr.split('-');
              qtr = qtr[0].split("Q")[1];
              qtr = this.getMonthsInQuarter(qtr);

              ////////////console.log("QTR: ", qtr);

              args[0] = {field: "contract_year_from", value: year}
              args[1] = {field: "contract_year_to", value: year}
              args[2] = {field: "contract_month", value: qtr}
              //args[3] = {field: this.target_group, value: param.seriesName}
            }

            this.$emit("showTenders", args);
          
          });
        }

    },

    getMonthsInQuarter(qtr){
      switch (qtr) {
        case "1":
          return ["January", "February", "March"];
        case "2":
          return ["April", "May", "June"];
        case "3":
          return ["July", "August", "September"];
        case "4":
          return ["October", "November", "December"];
        
      }
    }
  },

  computed: {
    reportLineMini() {
      return 'margin-top: 0px !important;padding-top: 0px !important;padding-left: 0px !important;padding-bottom: 0px !important;width: 100% !important;height: '+this.chartHeight+' !important;'

    }
  }
}

</script>
<style>
/* .reportLineMini {
  margin-top: 0px !important;
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-bottom: 0px !important;
  width: 100% !important;
  height: 100px !important;
} */
</style>