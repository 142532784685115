<template>
  <div class="pa-0 ma-0" style="background-color:#f5f5f5" fluid>
    <v-content>
      <v-container class="px-0 pt-3 mt-0 mr-2" fluid>
        <v-row class="ma-0 pt-0" fluid>
          <v-col cols="12" md="12" class="pt-0 mb-0 pb-0">
        <v-row class="mx-auto px-0 py-0" style="background-color:#f5f5f5">
         <v-col cols="12" class="px-0 pb-0 pt-2">

            <v-card tile>
            <v-card-title style="color:#12253a;">
                
                <span
                class="d-inline-block text-truncate"
                style="max-width:600px; min-width:150px;"
                >
                <v-card-subtitle color="secondary" class="pa-0 font-weight-light">{{clusterType}}</v-card-subtitle>
                {{ entity.name }}
                
                </span>
                <div class="flex-grow-1 desktop-nav"></div>
                <span>
                <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                    <template v-slot:activator="{ on }">
                    <v-btn
                        v-on="on"
                        large
                        icon
                        color="#4EB888"
                        light
                        style="text-decoration: none !important;"
                        :to="{ name: 'search', query: { [clusterParam]: entity.id } }"
                    >
                        <v-icon>mdi-format-list-bulleted</v-icon>
                    </v-btn>
                    </template>
                    <span>View all {{ entity.name }} notices</span>
                </v-tooltip>
                </span>
                </v-card-title
            >
            <v-row v-if="no_members" class="ma-0 pa-0">
                <v-divider></v-divider>
                <v-col cols="12">
                  <v-card flat class="pl-0 pt-0">
                      <v-card-text v-if="entity_type=='supplier_id'" class="pl-0 pt-0">This cluster is empty. 
                        Head over to the <router-link :to="{ name: 'suppliers' }">suppliers</router-link> directory to add entities to it.
                      </v-card-text>
                      <v-card-text v-else-if="entity_type=='publisher_id'" class="pl-0 pt-0">This cluster is empty. 
                        Head over to the <router-link :to="{ name: 'entities' }">buyers</router-link> directory to add entities to it.
                      </v-card-text>
                    </v-card>

                </v-col>
            </v-row>
            <v-row v-else-if="isDataLoaded" class="ma-0 pa-0">
                <v-divider></v-divider>
                <v-col cols="12">
                  <search-analysis :query="search"></search-analysis>
                </v-col>
            </v-row>
            
            </v-card>
         </v-col>
        </v-row>
        <v-tabs class="pt-4 pb-1" color="rgb(18, 37, 58)" show-arrows v-if="no_members">
          <v-tab>
              Entities
          </v-tab>
          <v-tab>
              Recent Notices
          </v-tab>
          <v-tab-item></v-tab-item>
          <v-tab-item></v-tab-item>
        </v-tabs>
        <v-tabs v-else class="pt-4 pb-1" color="rgb(18, 37, 58)" show-arrows>
          <v-tabs-slider color="rgb(255, 20, 99)"></v-tabs-slider>
          <v-tab>
              {{ entity_title }}
          </v-tab>
          <v-tab>
              {{ counterparty_title }}
          </v-tab>
          <v-tab>
              Recent Notices
          </v-tab>
          <v-tab-item>
              <v-row style="background-color:#f5f5f5">
              <v-col cols="12" class="pt-3">
                  <cluster-members-v-2 v-if="isDataLoaded" 
                          @reloadCluster="loadCluster" 
                          :target_entity="target_entity" 
                          :key="loaded" 
                          :query="query" 
                          :entity_type="entity_type"
                          :cluster_type="clusterParam"
                          :cluster_id="cluster_id"></cluster-members-v-2>
              </v-col>
              </v-row>
          </v-tab-item>
          <v-tab-item>
              <v-row style="background-color:#f5f5f5">
              <v-col cols="12" class="pt-3">
                  <cluster-members-v-2 v-if="isDataLoaded" 
                          @reloadCluster="loadCluster" 
                          :target_entity="target_counterparty" 
                          :key="loaded" 
                          :query="query" 
                          :entity_type="counterparty_type"
                          :cluster_type="clusterParam"
                          :cluster_id="cluster_id">
                  </cluster-members-v-2>
              </v-col>
              </v-row>
          </v-tab-item>
          <v-tab-item>
              <v-row style="background-color:#f5f5f5">
              <v-col cols="12" class="pt-3">
                  <v-skeleton-loader
                  v-if="overlay"
                  type="table-tbody"
                  ></v-skeleton-loader>
                  <results-table
                      v-else
                      class="pb-3 pt-0 pl-0 pr-0"
                      :hit_info="hit_info"
                      :tenders="tenders"
                      :hide_pagination="true"
                      />
                  <v-overlay absolute="true" :value="overlay">
                  <v-progress-circular
                      indeterminate
                      size="64"
                  ></v-progress-circular>
                  </v-overlay>
              </v-col>
              </v-row>
          </v-tab-item>
        </v-tabs>
      </v-col>
     </v-row>
    </v-container>
   </v-content>
 </div>
</template>

<script>
import {
  executeSearch,
  getCluster
} from "../components/APIManager.js";
import SearchAnalysis from "../components/SearchAnalysis.vue";
import { growth_format, numFormatter, formattedCurrency, govAvatarColor, getMonthYear } from "../components/Utils.js";
import ResultsTable from "../components/ResultsTable.vue";
import ClusterMembersV2 from "../components/ClusterMembersV2.vue";

export default {
  components: {
    ClusterMembersV2,
    ResultsTable,
    SearchAnalysis
  },

  data() {
    return {
      entity_title: "Members",
      counterparty_title: "Entities",
      counterparty_type: String,
      target_counterparty: String,
      entity: "",
      current_search: Object,
      entity_id: String,
      cluster_id: Number,
      supplier_id: Number,
      entity_ids: Array,
      entity_type: String,
      target_entity: String,
      query: String,
      expiring_activity: [],
      expiry_aggs: [],
      hit_info: {},
      tenders: [""],
      overlay: false,
      tabs: null,
      absolute: true,
      entity_panel: -9,
      entity_panels: [],
      panel_clicked: false,
      //current_index: 0,
      index: Number, //use the exp panel index as key so that we can cache the data
      profiles: [],
      mounted: false,
      total_amt_metric: "total_amount",
      search: Object,
      category_aggs: [],
      date_aggs: [],
      supplier_aggs: [],
      contracting_activity: [],
      publish_activity:[],
      annual_spend: [],
      avg_contract: Number,
      loading_stats: false,
      industry: [],
      clusterParam: String,
      clusterType: "",
      loaded: Number,
      no_members: false
    };
  },

  computed: {
    isDataLoaded() {
        const isLoaded = Object.keys(this.entity).map(key => this.entity[key].length !== 0)
        return this.entity && isLoaded.length !== 0
    }
  },

  created() {
    
  },

  mounted() {

    this.$nextTick(()=>{
        this.loadCluster();
        
    });
    
  },

  watch: {
    $route(to, from) {

    },

  },

  methods: {

    growth_format,
    numFormatter,
    formattedCurrency,
    govAvatarColor,

    loadCluster() {
        ////////////console.log("QUERY FROM SUPP PROFILE: ", query);
        this.overlay = true;

        //var qry = JSON.parse(JSON.stringify(query));

        getCluster(this.$route.params.clusterId).then(results => {
            
            this.entity = results.data;
            this.query = results.data.search_query;
            
            if (this.entity.entity_type == "publisher"){
                this.clusterParam = "buyer_cluster";
                this.clusterType = "Buyer Cluster";
                this.target_entity = "entity";
                this.counterparty_type = "supplier_id";
                this.target_counterparty = "supplier";
                this.entity_title = "Buyers"
                this.counterparty_title = "Suppliers"
            }
            else{
                this.clusterParam = "supplier_cluster";
                this.clusterType = "Supplier Cluster"
                this.target_entity = "supplier";
                this.counterparty_type = "publisher_id";
                this.target_counterparty = "entity";
                this.entity_title = "Suppliers"
                this.counterparty_title = "Public Entities"
            }
            this.search = { [this.clusterParam]: [this.$route.params.clusterId], size: 1};
            ////////console.log("----SEARCH----\n: ", this.search);
            this.entity_type = results.data.entity_type+'_id';

            ////console.log("QUERY: ", this.query);
            ////console.log("ENTITY TYPE: ", JSON.parse(this.query)["supplier_id"]);

            var q = JSON.parse(this.query);

            if(q[this.entity_type].length <= 0)
            {
              this.no_members = true;
            }
            else{
              this.cluster_id = results.data.id;
              this.clusterName = results.data.name;
              this.loaded = Math.random() * Math.random();
              ////////console.log("----CLUSTER DATA----\n: ", results.data);
              this.loadRecentAwards();
              this.overlay = false;
            }

            
        });
    },

    reset_aggs(){
      this.expiry_aggs = [];
      this.expiring_activity = [];
      this.date_aggs = [];
      this.contracting_activity = [];
      this.publish_activity = [];
      this.annual_spend = [];
      this.category_aggs = [];
      this.supplier_aggs = [];
    },


    async loadRecentAwards() {
      let results = {};

      //let labels = [];
      results = await executeSearch(
        JSON.stringify({
          [this.clusterParam]: this.$route.params.clusterId,
          //disposition: "Contract Notice",
          sort_field: "expiry_date",
          sort_order: "desc",
          size: 10
        })
      );

      ////////////console.log("TABLE RESULTS: ", results)

      this.tenders = results.data;
      this.hit_info = results.hits;
      this.overlay = false;
    },

    

    disableIcon(url) {
      if (!url) {
        return true;
      } else {
        return false;
      }
    },

    toggle() {
      this.watching = !this.watching;
      this.is_saved();
    },

    is_saved() {
      ////////////console.log("NOTIFY STATUS: " + this.current_search.notify)
      return this.watching;
    }
  }
};
</script>

<style>
.chart {
  border-color: #424242 !important;
}

.headingUnderline {
  border-color: aqua !important;
}
</style>
