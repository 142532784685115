<template>
  <span>
    <v-dialog
          v-model="reportLoading"
          hide-overlay
          persistent
          width="300"
          lazy          
        >
          <v-card
            color="secondary"
            dark
          >
            <v-card-text>
              Building the network...
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
    </v-dialog>
      <v-row align="center" justify="center">
        <v-col>
          <div class="pb-4">
            <span class="pl-0">
              <v-icon color="rgb(255, 231, 10)" small>mdi-checkbox-blank-circle</v-icon><span class="pl-1">Buyer</span>
            </span>
            <span class="pl-3">
              <v-icon color="rgb(18, 37, 58)" small>mdi-checkbox-blank-circle</v-icon><span class="pl-1">Supplier</span>
            </span>
          </div>
          <v-card flat tile :id="id" class="mynetwork" height="400px"></v-card>
        </v-col>
      </v-row>
  </span>
</template>

<script>
import $ from "jquery";
import moment from "moment";
import { Network } from "vis-network/peer";
import { DataSet } from "vis-data/peer";
import { bus } from "../main.js";
import { executeSearch, graphSearch } from "./APIManager";

export default {
  props: {
    story: Boolean,
    embed: Number,
    chart_options: {},
    id: Number
    //graph: String,
    //agg_size: Number,
    //query: {}
  },

  components: {
  },

  data() {
    return {
      has_keywords: false,
      default_group: 1,
      current_page: 1,
      selectedTender: {},
      drawer: false,
      //topSelection: 10,
      fullscreen: false,
      toggle: null,
      nodes: null,
      edges: null,
      network: null,
      option: {},
      overlay: false,
      groups: [10, 30, 20],

      selectedNode: String,
      currQuery: {},
      tenders: [],
      num_hits: Number,
      total_value: Number,
      hit_info: {},
      num_groups: 10,
      errored: false,
      reportLoading: Boolean,
      //agg_size: 10,
      graph: String,
      category_graph: String,
      keyword_graph: String,
      entity_graph: String,
      query: {},
      widget_name: 'network',
      current_graph: "category",
      imgURL: String,
      graph_data: Object
    };
  },
  watch: {
    // whenever wave_sets prop changes, this function will run
    fullscreen: {
      handler: async function() {
        if (this.fullscreen) {
          //this.overlay = !this.overlay;
          this.network = await this.draw();
        }
      }
    },

    $route(to, from) {
      ////////////console.log("IN TENDER RESULTS: ");
      this.refresh();

    }
  },
  created() {
    bus.$on("nodeSelected", node => {
      this.current_page = 1;
      this.updateQuery(node, this.$route.query);
      this.loadTenders().then(results => {
        this.tenders = results.data;
        this.num_hits = results.hits.value;
        this.total_value = results.aggregations.total_amount.value;
        this.num_pages = results.hits.total_pages;
      });
    });
  },

  async mounted() {
    this.reportLoading = true;
    if (this.story){
      this.graph = this.chart_options;
      this.draw();
    }
    else{
      this.getGraphData(this.$route.query);
    }
    
  },

  computed: {
    entityName() {
      ////////////console.log("entityName CURR QUERY: ", this.currQuery);
      let name = "";
      if (this.currQuery["supplier_id"] > 0) {
        name = this.tenders[0].supplier;
      } else if (this.currQuery["publisher_id"] > 0) {
        name = this.tenders[0].publisher;
      }
      else if (this.currQuery["major_category_id"] > 0) {
        name = this.tenders[0].parent_category;
      }
      else if (this.currQuery["tender"]) {
        name = this.currQuery["tender"]
      }
      return name;
    }

    /* num_pages: function() {
      return this.hits.total_pages;
    } */
  },

  methods: {

    shareChart(){

      var can = document.getElementsByTagName("canvas");
      var imgUrl = can[0].toDataURL("image/png");

      let data = {
        imgData: imgUrl, //this.myChart.getDataURL(),
        options: this.graph_data
      };

      ////////////console.log("DATAURL: ", data);

      //this.$emit("chart_object", data);

    },

    async copy(type) {
        /* Get the text field */

        ////////////console.log(this.buttons);

        var copyText = String;

        var qry = JSON.parse(JSON.stringify(this.$route.query));
        for (var i in this.chart_settings){
          qry[this.chart_settings[i]["field_name"]] = this.chart_settings[i]["value"]
        }

        //let routeData = this.$router.resolve({name: this.widget_name, query: this.$route.query});
        let routeData = this.$router.resolve({name: this.widget_name, query: qry});

        if (type == 'object'){
          var can = document.getElementsByTagName("canvas");
          var src = can[0].toDataURL("image/png");
          ////////////console.log(src);
          copyText = src;
        }

        if (type == 'iframe'){
            copyText = '<iframe src='+ '"'+window.location.origin +routeData.href+'"' +'frameborder="0" style="height: 600px; width: 1230px"></iframe>';
        }else if (type == 'link'){
            copyText = window.location.origin + routeData.href;
        }

        try {
            await navigator.clipboard.writeText(copyText);
        } catch (e) {
            //console.error(e);
        }

        /* Alert the copied text */
        this.snackbar = true;
    },

    expand_nodes(num_nodes){
      ////////////console.log("num_nodes: ", num_nodes);
      this.num_groups = num_nodes;
      this.refresh();
    },

    change_graph(graph_focus){
      this.reportLoading = true;
      this.current_graph = graph_focus;

      if (graph_focus == "category"){
        this.graph = this.category_graph;
        this.draw();
      }
      else if (graph_focus == "keyword"){
        this.graph = this.keyword_graph;
        this.draw();
      } 
      else{
        this.graph = this.entity_graph;
        this.draw();
      }
    },

    openWidget(){
        let routeData = this.$router.resolve({name: this.widget_name, query: this.$route.query});
        window.open(routeData.href, '_blank');
    },

    async refresh(){
      this.graph = String;
      this.reportLoading = true;
      this.getGraphData(this.$route.query);
    },

    async getPage(number) {
      this.current_page = number;
      this.loadTenders().then(results => {
        this.tenders = results.data;
      });
    },

    formattedCurrency: function(amount) {
      ////////////console.log("AMOUNT: " + amount);
      if (!isNaN(amount)) {
        return (
          "$" +
          Math.round(amount)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        );
      } //else {
      //return null;
      //}
    },
    formatDate(date_value) {
      if (!date_value) {
        return null;
      } else {
        return moment(date_value).format("MMMM YYYY");
      }
    },
    getTender(id) {
      //this.$router.push({ name: "tender", params: { id: id } });
      let routeData = this.$router.resolve({
        name: "tender",
        params: { id: id }
      });
      window.open(routeData.href, "_blank");
    },

    updateQuery(node, query) {
      this.currQuery = JSON.parse(JSON.stringify(query)); //NEED THIS TO PASS A COPY INSTEAD OF A REFERENCE
      
      if (node.keyword){
        this.currQuery["tender"] = node.keyword;
        ////////////console.log("updateQuery CURR QUERY: ", this.currQuery);
        //this.currQuery["tender"] = 
      }
      else{
        this.currQuery[node.type] = node.id;
      }
      
      this.currQuery["label"] = node.label;
      //this.tenders.push(this.currQuery);
    },

    async loadTenders() {
      this.overlay = true;
      var results;
      results = await executeSearch(this.currQuery, this.current_page);
      this.overlay = false;
      return results;
    },

    async getGraphData(qry) {

      var query = {};

      var query = JSON.parse(JSON.stringify(qry));

      query["agg_size"] = this.num_groups;

      graphSearch(query, 1)
        .then(data => {
          this.graph_data = data;
          this.category_graph = data.graph.category_graph;
          this.keyword_graph = data.graph.keyword_graph;
          this.entity_graph = data.graph.entity_graph;

          this.drawGraph();
        })
        .catch(error => {
          ////////////console.log(error);
          this.errored = true;
        });
    },

    drawGraph(){
      if (this.current_graph == "category"){
        this.graph = this.category_graph;
      }
      else if (this.current_graph == "keyword"){
        this.graph = this.keyword_graph;
      } 
      else{
        this.graph = this.entity_graph;
      }

      if (this.keyword_graph){
        this.has_keywords = true;
      }

      this.network = this.draw();
    },

    expand() {
      var el = document.getElementById(this.id);

      if (el.webkitRequestFullScreen) {
        el.webkitRequestFullScreen();
      } else {
        el.mozRequestFullScreen();
      }
    },

    /* change_depth(depth) {
      let size = depth;
      bus.$emit("extend-agg", size);
    }, */

    async draw() {

      this.nodes = this.graph.nodes;

      this.edges = this.graph.edges;

      // Instantiate our network object.
      var container = document.getElementById(this.id);

      var nodesDataset = new DataSet(this.nodes); 
      var edgesDataset = new DataSet(this.edges); 

      var data = { nodes: nodesDataset, edges: edgesDataset };

      var options = {
        //highlightNearest: true,
        groups: {
          "category":{color:{background:'rgb(255, 20, 99)'}, borderWidth:3, 'color': 'rgb(255, 20, 99)', 'font': '24px roboto'},
          "supplier":{color:{background:'rgb(18, 37, 58)'}, borderWidth:3, 'color': 'rgb(18, 37, 58)', 'font': '13px roboto'},
          "publisher":{color:{background:'rgb(255, 231, 10)'}, borderWidth:3, 'color':'rgb(255, 231, 10)', 'font': '13px roboto'},
          "keyword" :{color:{background:'rgb(255,127,80)'}, borderWidth:3, 'color': 'rgb(255,127,80)', 'font': '24px roboto' }
        },


        layout: {improvedLayout: false},
        interaction: {
          //multiselect: true,
          navigationButtons: false
        },
        nodes: {
          shape: "dot", 
          scaling: {
            min: 10,
            max: 50,
          }
        },
        edges: {
          chosen: true,
          color: {
            color: "#cbdae1",
            highlight: "#12253a" 
          }
        },
        physics: {
          enabled: true,
          forceAtlas2Based: {
            gravitationalConstant: -40,
            centralGravity: 0.005,
            springLength: 230,
            springConstant: 0.18,
          },
          maxVelocity: 146,
          solver: "forceAtlas2Based",
          timestep: 0.35,
          stabilization: { iterations: 5 }
        },
      };
      let network = new Network(container, data, options);

      network.on("selectNode", function(properties) {
        ////////////console.log(JSON.stringify(properties));
        if (properties.nodes[0].includes("supp")) {
          let id = properties.nodes[0].replace("supp", "");
          var sNode = { id: id, type: "supplier_id", keyword: null };
          bus.$emit("nodeSelected", sNode);
        } else if (properties.nodes[0].includes("pub")) {
          let id = properties.nodes[0].replace("pub", "");
          var pNode = { id: id, type: "publisher_id", keyword: null };
          bus.$emit("nodeSelected", pNode);
        }
        else if (properties.nodes[0].includes("cat")) {
          let id = properties.nodes[0].replace("cat", "");
          var cNode = { id: id, type: "major_category_id", keyword: null };
          bus.$emit("nodeSelected", cNode);
        }
        else{ //must be the keyword
          ////////////console.log("PROPERTIRE: ", properties.nodes[0]);
          let keyword = properties.nodes[0];
          var kNode = { id: keyword, type: "keyword", keyword: keyword };
          bus.$emit("nodeSelected", kNode);
        }
      });

      var allNodes = nodesDataset.get({ returnType: "Object" });
      var highlightActive = false;

      network.on("click", function(params){
        if (params.nodes.length > 0) {
          highlightActive = true;
          var i, j;
          var selectedNode = params.nodes[0];
          var degrees = 2;

          // mark all nodes as hard to read.
          for (var nodeId in allNodes) {
            allNodes[nodeId].color = "rgba(200,200,200,0.5)";
            if (allNodes[nodeId].hiddenLabel === undefined) {
              allNodes[nodeId].hiddenLabel = allNodes[nodeId].label;
              allNodes[nodeId].label = undefined;
            }
          }
          var connectedNodes = network.getConnectedNodes(selectedNode);
          var allConnectedNodes = [];

          // get the second degree nodes
          for (i = 1; i < degrees; i++) {
            for (j = 0; j < connectedNodes.length; j++) {
              allConnectedNodes = allConnectedNodes.concat(
                network.getConnectedNodes(connectedNodes[j])
              );
            }
          }

          // all second degree nodes get a different color and their label back
          for (i = 0; i < allConnectedNodes.length; i++) {
            allNodes[allConnectedNodes[i]].color = "rgba(150,150,150,0.75)";
            if (allNodes[allConnectedNodes[i]].hiddenLabel !== undefined) {
              allNodes[allConnectedNodes[i]].label =
                allNodes[allConnectedNodes[i]].hiddenLabel;
              allNodes[allConnectedNodes[i]].hiddenLabel = undefined;
            }
          }

          // all first degree nodes get their own color and their label back
          for (i = 0; i < connectedNodes.length; i++) {
            allNodes[connectedNodes[i]].color = undefined;
            if (allNodes[connectedNodes[i]].hiddenLabel !== undefined) {
              allNodes[connectedNodes[i]].label =
                allNodes[connectedNodes[i]].hiddenLabel;
              allNodes[connectedNodes[i]].hiddenLabel = undefined;
            }
          }

          // the main node gets its own color and its label back.
          allNodes[selectedNode].color = undefined;
          if (allNodes[selectedNode].hiddenLabel !== undefined) {
            allNodes[selectedNode].label = allNodes[selectedNode].hiddenLabel;
            allNodes[selectedNode].hiddenLabel = undefined;
          }
        } else if (highlightActive === true) {
          // reset all nodes
          for (var nodeId in allNodes) {
            allNodes[nodeId].color = undefined;
            if (allNodes[nodeId].hiddenLabel !== undefined) {
              allNodes[nodeId].label = allNodes[nodeId].hiddenLabel;
              allNodes[nodeId].hiddenLabel = undefined;
            }
          }
          highlightActive = false;
        }

        // transform the object into an array
        var updateArray = [];
        for (nodeId in allNodes) {
          if (allNodes.hasOwnProperty(nodeId)) {
            updateArray.push(allNodes[nodeId]);
          }
        }
        nodesDataset.update(updateArray);
      });

      network.on("afterDrawing", function (ctx) {
        ////////////console.log(ctx[0]);
        //var dataURL = ctx.canvas[0].toDataURL();
        //this.imgURL = dataURL;
        //document.getElementById('canvasImg').src = dataURL;
      });

      this.reportLoading = false;

      return network;

    }
  }
};
</script>
<style scoped>

.mynetwork {
  overflow: visible;
}

div.vis-tooltip {
  color: #fff !important;
  background-color: #000000 !important;
  border-radius: 0px !important;
  border: none !important;
  font-family: verdana !important;
  font-size: 12px !important;
}

.wrap-text {
  -webkit-line-clamp: unset !important;
}

.tooltip {
  display: inline-block;
  max-width: 360px;
}


</style>
