<template>
  <div :id="id" :style="singleBar"></div>
</template>

<script>
import * as echarts from "echarts";
import {downloadFile} from "./Utils";
import $ from 'jquery';
//import jsondata from "../temp/life-expectancy-table.json";

export default {

 props:{
     series: Object,
     group:Object,
     legend_data: Array,
     y_axis_data: [],
     changed: Number,
     download: Number,
     metric_type: String,
     target_group: String,
     interval: String,
     story: Boolean,
     embed: Number,
     color: [],
     chart_options: {},
     id: Number,
     chart_size: {
        type: String,
        default: "large"
      },
     chartHeight:{
      type: String,
      default: "200px"
    },
    resetChart: Number
 },
  data() {
    return {
      options: {},
      myChart: null,
      canvasCSS: String(),
      chartWidth: Number(),
      legend_padding: Number(),
      entities: []
    };
  },

  watch: {
    changed() {
      //this.myChart.dispose();
      ////////////console.log("CHANGED DATA: ", this.series)
      this.loadChart();
    },

    embed() {
      ////////////console.log("CALL SHARE CHART\n", this.myChart);
      
      this.saveChart();
    },

    download() {
      this.saveImage();
    },

    resetChart(){
      this.clearSelection();
    }
  },

  mounted() {
      //nextTick will fix chart width at 100px issue
      this.$nextTick(()=>{
        this.loadChart();
      });
  },

  methods: {

    setStyle () {
      if (this.chart_size == "large"){
        this.chartHeight= 60;
        this.chartWidth=180;
        this.legend_padding = -30;
        this.canvasCSS = "reportPieLarge";
        
      }
      else{
        this.chartHeight= 40;
        this.chartWidth=120;
        this.legend_padding = -30;
        this.canvasCSS = "reportPieSmall";;
      }
            
    },

    saveChart(){

      var imgUrl = this.myChart.getDataURL();

      let data = {
        imgData: imgUrl, //this.myChart.getDataURL(),
        options: this.options
      };

      this.$emit("chart_object", data);

      ////////////console.log("LINE CHART PAYLOAD: ", data);

    },

    saveImage(){

      var img = new Image();
      
      img.src = this.myChart.getDataURL({
          pixelRatio: 2,
          backgroundColor: '#fff'
      });

      downloadFile(img.src);

    },

    loadChart() {

        if (this.myChart != null && this.myChart != "" && this.myChart != undefined) {
            this.myChart.dispose();
        }

        var chartDom = document.getElementById(this.id);
        this.myChart = echarts.init(chartDom);

        var option;


        option = {
            animationDuration:1000,
            tooltip: {
              trigger: 'item',
              textStyle:{
                fontSize: 10
              },
              axisPointer: {
                // Use axis to trigger tooltip
                type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
              }
            },
            //colorBy: "data",
            //color: ["red","blue", "green"],
            legend: {
              show: false,
              icon: "circle",
              orient: 'horizontal',
              //type: "scroll",
              //right: 0,
              //top:'center',
              bottom: 0
            },
            grid: {
                show: false,
                left: "2%",
                //bottom: "10%",
                top: "5px",
                //right: 240,
                containLabel: true
            },
            xAxis: {
                type: 'value',
                splitLine: {
                  show: false
                },
                axisTick:{
                  show: false
                },
                axisLine: {
                  show: false
                },
                axisLabel: {
                  show: false,
                  fontSize: 12,
                  
                  formatter : (s) => {
                        function convert (num){
                            if (num > 999 && num < 1000000) {
                                num = (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
                            } else if (num >= 1000000 && num < 1000000000) {
                                num = (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
                            } else if (num >= 1000000000) {
                                num = (num / 1000000000).toFixed(1) + "B"; // convert to M for number from > 1 million
                            }

                            return num;
                        }

                        if (this.metric_type == "total_amount" || this.metric_type == "avg_value"){
                          return '{valueStyle|' + '$'+ convert(s) + '}';
                        }else{
                          return '{valueStyle|' + s + '}';
                        }
                        
                    },
                      rich: {
                          valueStyle: {
                              // Make yearly text more standing out
                              color: '#000',
                              fontWeight: 'normal',
                              fontSize: 10
                          }
                      }
                },
                //boundaryGap: true,
                
            },
            yAxis: {
                type: 'category',
                data: this.y_axis_data,
                splitLine: {
                  show: false
                },
                scale: true,
                axisTick:{
                  show: false
                },
                axisLine: {
                  show: false
                },
                
                axisLabel: {
                  show: true,
                  showMinLabel: true,
                  showMaxLabel: true,
                  fontSize: 9,
                  overflow: 'truncate',
                  //width: 100
                  formatter: (label) => {
                    ////////////console.log(params);
                    var name = this.truncateString(label, 20);
                    return name;
                    //return '{b|'+num + '}';
                  },
                  
                },
            },
            
            series: [this.series]
          };

          this.myChart.setOption(option);

          this.options = option;
          this.options.metric_type = this.metric_type;

          //raise click event when bar is clicked
          /* this.myChart.on('click', param => {
            this.updateQuery(param.name);
          }); */

    },

    clearSelection(){
      //this.myChart.clear();
      this.myChart.setOption(this.options, true)
    },

    updateQuery(term){

      //if exists then remove from entities
      let idx = this.entities.indexOf(term)
      if (idx < 0){
        this.entities.unshift(term);
      }
      else{
        this.entities.splice(idx, 1); // remove 1 element at idx
      }
      
      var query = JSON.parse(JSON.stringify(this.$route.query));
      if (this.entities.length == 0){
        this.$emit("updateQuery", query);
      }
      else if (this.group.value == "search_terms" && this.entities.length > 0){
        delete query.tender;
        query["tender"] = this.entities;
        query["metric_type"] = this.metric_type;
        this.$emit("updateQuery", query);
      }
      else if (this.group.value == "supplier" && this.entities.length > 0){
        delete query.supplier;
        query["supplier"] = this.entities;
        query["metric_type"] = this.metric_type;
        this.$emit("updateQuery", query);
      }
      else if (this.group.value == "publisher" && this.entities.length > 0){
        delete query.publisher;
        query["publisher"] = this.entities;
        query["metric_type"] = this.metric_type;
        this.$emit("updateQuery", query);
      }

      //////////console.log("SELECTED ENTITIES: ", this.entities);

      //this.$emit("updateQuery", query);
      
    },
    
    truncateString(str, num) {
      if (str.length > num) {
        return str.slice(0, num) + "...";
      } else {
        return str;
      }
    },
  },
  computed: {
    singleBar() {
      return 'margin-top: 0px !important;padding-top: 0px !important;padding-left: 0px !important;padding-bottom: 0px !important;width: 100% !important;height: '+this.chartHeight+' !important;'

    }
  },
}

</script>
<style>
/* .reportLine {
  margin-top: 0px !important;
  padding-top: 0px !important;
  padding-left: 0px !important;
  width: 100% !important;
  height: 360px !important;

} */

</style>