<template>
  <v-app id="inspire">
    <v-navigation-drawer
      disable-resize-watcher
      dark
      color="secondary"
      v-model="drawer"
      app
      clipped
      stateless
      mobile-breakpoint="400"
      width="400"
      height="100%"
    >
      <tender-filters v-on:showNav="drawer = !drawer" />
    </v-navigation-drawer>

    <v-app-bar
      v-if="app_bar_active"
      app
      clipped-left
      clipped-right
      fixed
      
      dark
      color="secondary"
      height="56"
      flat
      style="border-bottom : 2px solid rgb(255, 20, 99) !important;"
    >
      <div class="logo">
        <router-link to="/" style="color: white; text-decoration: none">
          <v-img
            src="@/assets/img/logo-white.png"
            max-height="22"
            max-width="22"
            contain
            class="desktop px-0 mx-0"
          ></v-img>
          <v-img
            src="@/assets/img/tendle_logo-thumbnail-white.svg"
            max-height="20"
            max-width="20"
            contain
            style="margin-right: 10px margin-left:0px"
            class="mobile"
          ></v-img>
        </router-link>
      </div>
 
        <span v-if="app_bar_active && $route.name != 'home'">
          <tender-search-app-bar />
        </span>

        <span v-if="app_bar_active && $route.name != 'home'">
              <v-btn
              class="pl-2"
                small
                text
                color="#e0e0e0"
                @click="drawer = !drawer"
              >
                <span class="text-capitalize body-2 desktop-nav ">Advanced Search</span>
                <v-icon small right>mdi-tune</v-icon>
              </v-btn>

        </span>
        <!-- <span v-if="app_bar_active">
              <v-btn
              class="ml-2"
                small
                icon
                color="#e0e0e0"
                @click="queryDialog = !queryDialog"
              >
                <v-icon small>mdi-message-text</v-icon>
              </v-btn>

        </span> -->

        <div class="flex-grow-1"></div>
        <span>
          <v-badge
            :color="badge_color"
            :value="badge_visible"
            :content="num_matches"
            offset-x="15"
            
            left
            overlap
          >
            <v-btn
              v-if="app_bar_active"
              class="text-capitalize body-2 desktop-nav "
              small
              dark
              text
              depressed
              tile
              @click="$router.push({ name: 'inbox' })"
              >Alerts
            </v-btn>
  
          </v-badge>

        </span>
        <!-- <span>
          <v-btn
            v-if="app_bar_active"
            class="text-capitalize body-2 desktop-nav "
            small
            dark
            text
            depressed
            tile
            @click="$router.push(
              { name: 'tracey'})"
          ><v-icon left>mdi-creation</v-icon>
            Tracey
          </v-btn>
        </span> -->
        <!-- <span>
          <v-btn
            v-if="app_bar_active"
            class="text-capitalize body-2 desktop-nav "
            small
            dark
            text
            depressed
            tile
            @click="$router.push(
              { name: 'search', query:{
                expiry_from: new Date().getFullYear(),
                expiry_to: new Date().getFullYear(),
                sort_order: 'asc',
                showFilter: true
              } })"
          >
            Search
          </v-btn>
        </span> -->
        <span>
          <v-menu
            v-if="app_bar_active"
            v-model="showEntityMenu"
            offset-y
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="text-capitalize body-2 desktop-nav"
                small
                dark
                text
                depressed
                tile
                v-bind="attrs"
                v-on="on"
              >
                Directories
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-card dark color="secondary" class="body-2" min-width="200" max-width="300">
              <v-list dense color="secondary">

                  <v-list-item style="text-decoration:none !important; color:white !important;"
                    v-for="(item, i) in directory_items"
                    :key="i"
                    @click.native="showEntityMenu = false"
                    :to="{ name: item.route }"
                  >
                    <v-list-item-icon>
                      <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{item.text}}
                        
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

              </v-list>
              
            </v-card>
          </v-menu>
        </span>
        <span>
          <v-btn
            v-if="app_bar_active"
            class="text-capitalize body-2 desktop-nav "
            small
            dark
            text
            depressed
            tile
            @click="$router.push({ name: 'storyboards' })"
            >Reports
          </v-btn>
        </span>
        <span>
          <v-tooltip bottom dark color="#000000" style="opacity: 0.7;">
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                  :disabled="disabled()"
                  
                >
                <router-link to="/">
                  <v-icon color="#FFE70A">mdi-home</v-icon>
                </router-link>
                </v-btn>
              </template>
              <span>Home</span>
            </v-tooltip>
        </span>
        <div class="desktop-nav">
          <v-tabs
            color="#4EB888"
            v-model="drawer_tabs"
            background-color="secondary"
            show-arrows
            hide-slider
            fixed-tabs
          >
            <v-tooltip bottom dark color="#000000" style="opacity: 0.7;">
              <template v-slot:activator="{ on }">
                <v-tab
                  v-on="on"
                  :disabled="disabled()"
                  @click="showHide(0)"
                  class="v-app-bar-icon"
                >
                  <v-icon color="#FFE70A">mdi-history</v-icon>
                </v-tab>
              </template>
              <span>Search History</span>
            </v-tooltip>

            <v-tooltip bottom dark color="#000000" style="opacity: 0.7;">
              <template v-slot:activator="{ on }">
                <v-tab
                  v-on="on"
                  :disabled="disabled()"
                  @click="showHide(1)"
                  class="v-app-bar-icon"
                >
                  <v-icon>mdi-bookmark-outline</v-icon>
                </v-tab>
              </template>
              <span>Prospect Lists</span>
            </v-tooltip>

            <v-tooltip bottom dark color="#000000" style="opacity: 0.7;">
              <template v-slot:activator="{ on }">
                <v-tab
                  v-on="on"
                  :disabled="disabled()"
                  @click="showHide(2)"
                  class="v-app-bar-icon"
                >
                  <v-icon>mdi-view-headline</v-icon>
                </v-tab>
              </template>
              <span>Search Profiles</span>
            </v-tooltip>

            <v-tooltip bottom dark color="#000000" style="opacity: 0.7;">
              <template v-slot:activator="{ on }">
                <v-tab
                  v-on="on"
                  :disabled="disabled()"
                  @click="showHide(3)"
                  class="v-app-bar-icon"
                >
                  <v-icon>mdi-account-multiple</v-icon>
                </v-tab>
              </template>
              <span>Supplier Clusters</span>
            </v-tooltip>

            <v-tooltip bottom dark color="#000000" style="opacity: 0.7;">
              <template v-slot:activator="{ on }">
                <v-tab
                  v-on="on"
                  :disabled="disabled()"
                  @click="showHide(4)"
                  class="v-app-bar-icon"
                >
                  <v-icon>mdi-domain</v-icon>
                </v-tab>
              </template>
              <span>Buyer Clusters</span>
            </v-tooltip>

            <v-tooltip bottom dark color="#000000" style="opacity: 0.7;">
              <template v-slot:activator="{ on }">
                <v-tab
                  v-on="on"
                  :disabled="disabled()"
                  @click="showHide(5)"
                  class="v-app-bar-icon"
                >
                  <v-avatar size="30px" v-if="$auth.user.picture">
                    <img small :src="$auth.user.picture"
                        alt="User's profile picture"/>
                  </v-avatar>
                  <v-avatar size="30px" v-else color="primary">
                    <v-icon dark>
                      mdi-account-circle
                    </v-icon>
                  </v-avatar>
                </v-tab>
              </template>
              <span>My Profile</span>
            </v-tooltip>

            <v-tooltip bottom dark color="#000000" style="opacity: 0.7;">
              <template v-slot:activator="{ on }">
                <v-tab
                  v-on="on"
                  :disabled="disabled()"
                  @click="showHide(5)"
                  class="v-app-bar-icon"
                >
                  <v-icon color="rgb(255, 20, 99)">mdi-help-circle</v-icon>
                </v-tab>
              </template>
              <span>Help</span>
            </v-tooltip>
          </v-tabs>
        </div>
  
    </v-app-bar>

    <div class="mobile-nav">
      <v-bottom-navigation fixed horizontal :input-value="app_bar_active">
        <v-tabs
          color="#4EB888"
          v-model="drawer_tabs"
          background-color="FFFFFF"
          show-arrows
          hide-slider
        >
          <v-tooltip bottom dark color="#000000" style="opacity: 0.7;">
            <template v-slot:activator="{ on }">
              <v-tab
                color="#FFE70A"
                v-on="on"
                :disabled="disabled()"
                @click="showHide(0)"
                class="v-app-bar-icon"
              >
                <v-icon>mdi-history</v-icon>
              </v-tab>
            </template>
            <span>Search History</span>
          </v-tooltip>
          <v-tooltip bottom dark color="#000000" style="opacity: 0.7;">
            <template v-slot:activator="{ on }">
              <v-tab
                v-on="on"
                :disabled="disabled()"
                @click="showHide(1)"
                class="v-app-bar-icon"
              >
                <v-icon>mdi-bookmark-outline</v-icon>
              </v-tab>
            </template>
            <span>Prospect Lists</span>
          </v-tooltip>

          <v-tooltip bottom dark color="#000000" style="opacity: 0.7;">
            <template v-slot:activator="{ on }">
              <v-tab
                v-on="on"
                :disabled="disabled()"
                @click="showHide(2)"
                class="v-app-bar-icon"
              >
                <v-icon>mdi-view-headline</v-icon>
              </v-tab>
            </template>
            <span>Your Saved Search Profiles</span>
          </v-tooltip>

          <v-tooltip bottom dark color="#000000" style="opacity: 0.7;">
            <template v-slot:activator="{ on }">
              <v-tab
                v-on="on"
                :disabled="disabled()"
                @click="showHide(3)"
                class="v-app-bar-icon"
              >
                <v-icon>mdi-account-multiple</v-icon>
              </v-tab>
            </template>
            <span>Supplier Clusters</span>
          </v-tooltip>

          <v-tooltip bottom dark color="#000000" style="opacity: 0.7;">
            <template v-slot:activator="{ on }">
              <v-tab
                v-on="on"
                :disabled="disabled()"
                @click="showHide(4)"
                class="v-app-bar-icon"
              >
                <v-icon>mdi-domain</v-icon>
              </v-tab>
            </template>
            <span>Buyer Clusters</span>
          </v-tooltip>

          <v-tooltip bottom dark color="#000000" style="opacity: 0.7;">
            <template v-slot:activator="{ on }">
              <v-tab
                v-on="on"
                :disabled="disabled()"
                @click="showHide(5)"
                class="v-app-bar-icon"
              >
                <v-avatar size="30px" v-if="$auth.user.picture">
                  <img small :src="$auth.user.picture"
                      alt="User's profile picture"/>
                </v-avatar>
                <v-avatar size="30px" v-else color="primary">
                  <v-icon dark>
                    mdi-account-circle
                  </v-icon>
                </v-avatar>
              </v-tab>
            </template>
            <span>My Profile</span>
          </v-tooltip>

          

          <v-tooltip bottom dark color="#000000" style="opacity: 0.7;">
            <template v-slot:activator="{ on }">
              <v-tab
                v-on="on"
                :disabled="disabled()"
                @click="showHide(5)"
                class="v-app-bar-icon"
              >
                <v-icon>mdi-help-circle</v-icon>
              </v-tab>
            </template>
            <span>Help</span>
          </v-tooltip>
        </v-tabs>
      </v-bottom-navigation>
    </div>

    <v-navigation-drawer
      disable-resize-watcher
      v-model="right_drawer"
      right
      app
      clipped
      width="300px"
      stateless
    >
      <v-card width="100%" height="100%">
        <v-tabs-items v-model="drawer_tabs">
          <v-tab-item :disabled="disabled()">
            <search-history />
          </v-tab-item>
          <v-tab-item :disabled="disabled()">
            <my-lists />
          </v-tab-item>
          <v-tab-item :disabled="disabled()">
            <favourites />
          </v-tab-item>
          <v-tab-item :disabled="disabled()">
            <cluster-menu :disabled="false" :menuIcon="'mdi-account-multiple'" :menuName="'Supplier Clusters'" :entityType="'supplier'" :entityIDType="'supplier_id'" :entities="[]" />
          </v-tab-item>
          <v-tab-item :disabled="disabled()">
            <cluster-menu :disabled="false" :menuIcon="'mdi-domain'"  :menuName="'Buyer Clusters'" :entityType="'publisher'" :entityIDType="'publisher_id'" :entities="[]" />
          </v-tab-item>

          <v-tab-item :disabled="disabled()">
            <login :menu="login_menu" :authenticated="authenticated" />
          </v-tab-item>
          
          <v-tab-item :disabled="disabled()">
            <v-card flat>
              <v-card-title color="#12253a" class="title font-weight-bold subtitle-1"
                >Help</v-card-title
              >
              <v-divider></v-divider>
              
              <!-- <v-card-text class="font-weight-bold pt-0">
                <a
                  href="https://tendertrace.com/privacy-policy/"
                  target="_blank"
                  >Privacy Policy</a
                ></v-card-text
              > -->

              <div class="mt-2 ml-1">
                  <v-btn
                    href="https://docs.tendertrace.com/guide"
                    target="_blank"
                    text
                    small
                    class="text-capitalize body-2"
                    
                    >User Guide <v-icon medium right outline>mdi-file-document</v-icon></v-btn
                  >
            </div>

                <div class="mt-2 ml-1">
                    <v-btn
                      @click="sendSupportMsg()"
                      v-bind="attrs"
                      v-on="on"
                      text
                      small
                      class="text-capitalize body-2"
                      
                      >Contact Support <v-icon medium right outline>mdi-headset</v-icon></v-btn
                    >
              </div>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-navigation-drawer>

    <router-view
      :drawer="drawer"
      :right_drawer="right_drawer"
      :drawer_data="drawer_data"
      :login_menu="login_menu"
      :authenticated="authenticated"
      :drawer_tabs="drawer_tabs"
    />

    <v-dialog v-model="dialog" width="300">
      <v-card>
        <v-card-title class="headline" primary-title>
          {{ dialog_title }}
        </v-card-title>

        <v-card-text>
          {{ dialog_msg }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            Got It
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
  </v-app>
</template>

<script>
//import { METHODS } from "http";
import TenderFilters from "./components/TenderFilters.vue";
import SearchHistory from "./components/SearchHistory.vue";
import Favourites from "./components/Favourites.vue";
import Login from "./components/Login.vue";
import MyLists from "./components/MyLists.vue";
import TenderSearchAppBar from "./components/TenderSearchAppBar.vue";
import ClusterMenu from "./components/ClusterMenu.vue";
//import ChatQueryWindow from "./components/ChatQueryWindow.vue";

import { bus } from "./main.js";
import { getInstance } from "./auth/index.js";
import axios from "axios";
import { userLog } from "./components/APIManager.js";

export default {
  components: {
    SearchHistory,
    Favourites,
    Login,
    MyLists,
    TenderSearchAppBar,
    TenderFilters,
    ClusterMenu
  },
  props: {
    source: String
  },
  data() {
    return {
      directory_items: [
        { text: 'Buyers', icon: 'mdi-domain', route:'entities'},
        { text: 'Suppliers', icon: 'mdi-account-multiple', route:'suppliers' },
        { text: 'Categories', icon: 'mdi-folder', route:'categories' },
        { text: 'Panels', icon: 'mdi-apps', route:'panels' }
      ],
      queryDialog: false,
      app_bar_active: false,
      tool_bar_active: true,
      current_tab: Number,
      drawer_tabs: null,
      drawer_data: String,
      right_drawer: false,
      drawer: false,
      recent_history_menu: true,
      favourites_menu: true,
      my_lists_menu: true,
      login_menu: true,
      logged_in_state_colour: "",
      authenticated: true,
      disable_nav: false,
      dialog_msg: "",
      dialog: false,
      dialog_title: "",
      search_terms: "",
      nav_open: true,
      closeEntityMenu: false,
      showEntityMenu: false,
      overlay: true,
      category_list: [],
      num_matches: 0
      //show_login_menu: false
    };
  },
  watch: {


    $route(to, from) {

      // Capture the full path of the route the user is navigating to
      const fullPath = window.location.origin + to.fullPath;

      userLog(fullPath);

      if (this.$route.path.includes("/widgets/")){
        this.app_bar_active = false;
      }

      if (this.$route.path.includes("/inbox")){
        this.num_matches = 0;
      }

      if (this.$route.query["showFilter"] && !this.$route.query["viewIndex"]) {
        this.drawer = true;
      } else {
        this.drawer = false;
      }
    }
  },
  computed: {
    badge_color(){

      if (this.num_matches > 0){
        return "primary";
      }
      else{
        return "secondary";
      }

    },

    badge_visible(){

      if (this.num_matches > 0){
        return true;
      }
      else{
        return false;
      }

    },

    appTitle() {
      return this.$store.state.appTitle;
    },

    loginStateColour() {
      return this.logged_in_state_colour;
    },

    displayBar() {
      if (this.app_bar_active) {
        //////////console.log("LOGGED IN")
        return { display: "none" };
      } else {
        return { display: "block" };
      }
    }
  },


  created() {
    if (this.$route.path.includes("/widgets/")){
        this.app_bar_active = false;
      }

  },

  mounted() {

    const instance = getInstance();

    instance.$watch("loading", async loading => {
      if (!loading && instance.isAuthenticated) {
        const token = await instance.getTokenSilently();
        this.tool_bar_active = false;
        if (this.$route.path.includes("/widgets/")){
          this.app_bar_active = false;
        }
        else{ 
          this.app_bar_active = true;
        }
        this.logged_in_state_colour = "#4EB888";
        this.disable_nav = false;
        //this.drawer = true;
        this.authenticated = true;
        this.overlay = false;
        this.getSavedSearches(token);
      }
      else {
        this.tool_bar_active = true;
        this.app_bar_active = false;
        this.logged_in_state_colour = "#FFFFFF";
        this.disable_nav = true;
        this.authenticated = false;
        this.right_drawer = false;
      }

    });

    if (this.$route.path.includes("/widgets/")){
        this.app_bar_active = false;
    }

    bus.$on("hide-side-navs", hide => {
      //////////console.log("HIDE SIDE NAVS");
      this.right_drawer = false;
      this.drawer = false;
    });

    bus.$on("show_dialog", dialog => {
      //////////console.log(`CALLED DIALOG :)` + dialog.msg  + " STATE: " + dialog.state);
      this.dialog_msg = dialog.msg;
      this.dialog_title = dialog.title;
      this.dialog = dialog.state;
    });

    bus.$on("hideFilters", show => {
      //////////console.log(`CALLED DIALOG :)` + dialog.msg  + " STATE: " + dialog.state);
      this.drawer = show;
    });

  },

  methods: {

    async sendSupportMsg(){
      this.currentURL = window.location.href;

      const options = {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          Authorization: 'pk_vU6EIAOfRouzmn3x'
        },
        body: JSON.stringify({
          allowDuplicates: false,
          domain: 'go.tendertrace.com',
          originalURL: this.currentURL
        })
      };

      fetch('https://api.short.io/links/public', options)
        .then(response => response.json())
        .then(response => {
          window.location.href = 'mailto:' + 'support@tendertrace.com?subject=Tendertrace Support Query&body=Source page: '+response.shortURL+ '%0D' + '%0DPlease enter your message below this line.%0D';
          return response.shortURL})
        .catch(err);

      //this.supportBody = await this.shortURL(this.currentURL) + '%0D' + '%0DPlease enter your message below this line.%0D';
    },

    async getSavedSearches(accessToken) {
      //const instance = getInstance();
      //const accessToken = await instance.getTokenSilently();

      axios
        .get(process.env.VUE_APP_API_SEARCHES, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        .then(response => {
          //////////console.log("SAVED SEARCHES" + JSON.stringify(response.data.data));
          this.num_matches = response.data.total_new_matches;
          //return this.saved_searches;
        })
        .catch(error => {
          //////////console.log(error);
          this.errored = true;
        })
        .finally(() => (this.loadingSearches = false));
    },

    showHide(tabID) {
      if (!this.right_drawer) {
        //this.drawer = false;
        this.right_drawer = true;
      } else if (this.current_tab === tabID) {
        this.right_drawer = false;
        //this.drawer = true;
      } else {
        //this.drawer = false;
        this.right_drawer = true;
      }
      this.current_tab = tabID;
    },

    globalSearch(search_terms) {
      var query = search_terms;
      bus.$emit("global-search", query);
    },

    plaintextSearch(search_terms) {
      var query = search_terms;
      bus.$emit("plaintext-search", query);
    },

    getStateColor() {
      if (this.drawer === null) {
        return "#4EB888";
      } else if (this.drawer === true) {
        return "#4EB888";
      } else {
        return "#FFFFFF";
      }
    },

    getRightDrawerStateColor() {
      if (this.right_drawer) {
        return "#4EB888";
      } else {
        return "#FFFFFF";
      }
    },

    refresh() {
      bus.$emit("refresh_lists");
    },

    getLoginStateColor() {
      if (this.$auth.isAuthenticated) {
        this.logged_in_state_colour = "#4EB888";
        return this.logged_in_state_colour;
      } else {
        this.logged_in_state_colour = "#FFFFFF";
        return this.logged_in_state_colour;
      }
    },

    disabled() {
      if (this.$auth.isAuthenticated) {
        this.disable_nav = false;
        return this.disable_nav;
      } else {
        this.disable_nav = true;
        return this.disable_nav;
      }
    }
  }
};
</script>

<style>

/* hide mobile version by default */
.logo .mobile {
  display: none;
}

/* .mobile-nav{
    display:none;
  }
 */
/* when screen is less than 600px wide
     show mobile version and hide desktop */
@media (max-width: 600px) {
  .logo .mobile {
    display: block;
  }
  .logo .desktop {
    display: none;
  }

  .tab-menu {
    max-width: 200px;
  }

  /* .mobile-nav {
      display: block;
    }

    .desktop-nav {
      display: none;
    } */
}


</style>
