<template>
  <div class="pl-2">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          v-bind="attrs"
          v-on="on"
          class="text-capitalize body-2"
          color="secondary"

        >
          Graph Settings
          <v-icon large right>mdi-menu-down</v-icon>
        </v-btn>
      </template>

      <v-card max-width="450">
        <v-list three-line>
          <v-list-item>
            <v-list-item-content class="pb-0 mb-0">
              <v-list-item-subtitle v-html="tip_text"></v-list-item-subtitle>
              <v-select
                dense
                v-model="num_nodes"
                class="pt-5"
                :items="expansion_items"
                label="Nodes per group"
                @change='$emit("expand_nodes", num_nodes), (menu = false)'
              ></v-select>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-radio-group v-model="radios" @change='$emit("change_graph", radios), (menu = false)'>
              <template v-slot:label>
                <div>Focus of the network</div>
              </template>
              <v-radio value="category">
                <template v-slot:label>
                  <div>Entity relationships via <strong>service categories</strong></div>
                </template>
              </v-radio>
              <v-radio value="keyword" v-if="has_keywords">
                <template v-slot:label>
                  <div>Entity relationships via <strong>keywords searched</strong></div>
                </template>
              </v-radio>
              <v-radio value="direct">
                <template v-slot:label>
                  <div>Direct <strong>entity</strong> relationships</div>
                </template>
              </v-radio>
            </v-radio-group>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-radio-group v-model="concentration" @change='$emit("showConcentration", concentration), (menu = false)'>
              <template v-slot:label>
                <div>Supplier concentration</div>
              </template>
              <v-radio value="none">
                <template v-slot:label>
                  <div>None</div>
                </template>
              </v-radio>
              <v-radio value="category" v-if="radios==='category'">
                <template v-slot:label>
                  <div>Usage across <strong>multiple service categories</strong></div>
                </template>
              </v-radio>
              <v-radio value="spend">
                <template v-slot:label>
                  <div>High proportion of <strong>spend</strong></div>
                </template>
              </v-radio>
            </v-radio-group>
          </v-list-item>
        </v-list>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="menu = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import moment from "moment";
import { bus } from "../main.js";

export default {
  props: {
    has_keywords: Boolean
  },
  data: () => ({
    tip_text: '<b>TIP &mdash;</b> <span>Expanding the nodes per group can reveal more relationships between the existing nodes',
    expansion_items: [5,10,15,20,30,50],
    radios: 'category',
    concentration: "none",
    num_nodes: 10,
    closeOnClick: true,
    type_options: [{ value: "Contract Notice" }, { value: "Request" }, { value: "Grant" }, { value: "Grant Opportunity" }],
    fav: true,
    menu: false,
    message: false,
    hints: true,

    years: [2016, 2025],
    year_min: 2016,
    year_max: 2025,
    keyword_chips: [],
    keyword_items: [],
    selected_types: [],
    include_publisher: true,
    expiryRangeMenu: false,
    expiryRange: []
  }),

  computed: {
    expiryRangeText() {
      return this.expiryRange.join(" - ");
    }
  },

  watch:{
    has_keywords(newVal, oldVal) {
    }
  },

  methods: {
    year() {
      var d = new Date();
      return d.getFullYear();
    },

    remove_kw(item) {
      this.keyword_chips.splice(this.keyword_chips.indexOf(item), 1);
      this.keyword_chips = [...this.keyword_chips];
    },

    clear_expiryRange() {
      this.expiryRange = [];
    },

    update_search() {
      let additional_filters = {
        keywords: this.keyword_chips,
        expiry_from: this.expiryRange[0],
        expiry_to: this.expiryRange[1],
        type: this.selected_types,
        include_publisher: this.include_publisher
      };

      bus.$emit("update-filter", additional_filters);
    }
  }
};
</script>
