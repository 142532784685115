<template>
    <v-div
        class="pa-0 ma-0 fill-height"
        style="background-color:#f5f5f5;"
        fluid
    >
    <v-content>
            <v-card class="mx-auto" flat tile>
                <story :changed="changed" :data="selectedStory" />
            </v-card>
    </v-content>
    </v-div>
</template>
<script>
import Story from "../components/Story.vue";
import json from '../components/data/imgdata.json';

export default {
    components: {
        Story
    },
    data() {
        return {
            model: 0,
            name: "Cyber Security Report",
            createdDate: "Updated 3d ago",
            description: "This board explores government spending on cybersecurity technology with the top 10 suppliers for financial year FY21. ",
            show: false,
            storyDialog: false,
            selectedStory: {},
            stories: json,
            changed: Number,
        }
    },

    mounted() {
        ////////console.log(json);
        this.graphChanged(this.stories[4]);
    },

    methods:{

        currentStoryIndex(id){

            var index = this.stories.findIndex((story, index) => {
            if (story.id === id) {
                return true
            }
            })

            return index;

        },
    
        showHideStory(story) {
            this.graphChanged(story);
            this.storyDialog = true;
            
        },

        graphChanged(story){
            this.selectedStory = null;
            this.selectedStory = story;
            this.changed = (Math.random() + this.stories.length) * Math.random();
            ////////console.log("MODEL: ", this.model);
            ////////console.log("STORY: ", this.selectedStory);
        },

         shortDescription(description){
            let text = description;
            let arr = text.split(" ");
            let limit = arr.length;
            let newText = []
            ////////console.log(arr);
            if (limit > 20){
                limit = 20;

                for (let i=0; i < limit; i++){
                    newText[i]= arr[i];
                }
                text = newText.join(" ");
                text = text + "..."
            }

            return text;
        }
    },

    computed:{

    },

    watch: {
        model() {
            this.graphChanged(this.stories[this.model])
        },
    },
}
</script>
<style scoped>
.truncate {
    font-family:sans-serif;
    font-size: 1em;
    font-weight: bold;
    line-height: 1.2em;
    transition: var(--transition);
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden ;
    text-overflow: ellipsis;
}

.txt {
    
    font-weight: normal;
    line-height: 1.2;
    letter-spacing: -0.02em;
    color: var(--dark-grey);
}

.truncate-title {
    line-height: 2em;
    transition: var(--transition);
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden ;
    text-overflow: ellipsis;

}
</style>