<template>
  <div>
    <v-card style="padding-bottom: 20px" tile>
      <v-skeleton-loader
        v-if="isLoading"
        type="table-tbody"
      ></v-skeleton-loader>
      <v-simple-table v-else style="width:100%;">
        <thead>
          <tr>
            <th class="text-left subtitle-2 font-weight-bold py-3 pl-5 pr-1">
              Contact
            </th>

            <th
              class="text-left subtitle-2 font-weight-bold py-3 p2-2 pl-5 ml-1"
            >
              Email
            </th>
            <th
              class="text-left subtitle-2 font-weight-bold py-3 p2-2 pl-5 ml-1"
            >
              Phone
            </th>
            <th
              class="text-left subtitle-2 font-weight-bold py-3 p2-2 pl-1 ml-1"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="entity in entities" :key="entity.id">
            <td class="py-2 pl-5 pr-1 ml-1">
              <div class="subtitle-2 d-inline-block text-truncate">
                <router-link
                  :to="{
                    name: 'search',
                    query: { contact: entityName(entity.name) }
                  }"
                  >{{ entityName(entity.name) }}</router-link
                >

                <!-- <span>{{ entityName(entity.name) }}</span> -->
              </div>
            </td>
            <td class="py-2 pl-5 pr-1 ml-1">
              <div class="subtitle-2 d-inline-block text-truncate">
                <span>{{ entityEmail(entity.email) }}</span>
              </div>
            </td>
            <td class="py-2 pl-5 pr-1 ml-1">
              <div class="subtitle-2 d-inline-block text-truncate">
                <span>{{ entityPhone(entity.phone) }}</span>
              </div>
            </td>
            <td class="py-2 px-1">
              <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    icon
                    color="#4EB888"
                    light
                    :href="'mailto:' + entity.email"
                    target="_blank"
                    :disabled="disableIcon(entity.email)"
                    v-on="on"
                  >
                    <v-icon>mdi-mail</v-icon>
                  </v-btn>
                </template>
                <span>Send Email</span>
              </v-tooltip>
            </td>
            <!-- <td class="py-2 px-1">
              <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    color="#4EB888"
                    light
                    icon
                    @click="getLists(entity.id)"
                    v-on="on"
                  >
                    <v-icon left>mdi-playlist-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add to List</span>
              </v-tooltip>
            </td> -->
          </tr>
        </tbody>
      </v-simple-table>

      <div class="text-center">
        <v-pagination
          v-if="entities.length > 1"
          v-model="page_num"
          color="#4EB888"
          :length="num_pages"
          total-visible="5"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
        ></v-pagination>
      </div>
    </v-card>
  </div>
</template>

<script>
import { bus } from "@/main.js";
import { fetchContacts } from "../APIManager";

export default {
  components: {
    //EntityProfileSearch
  },

  props: {
    publisher_id: String
  },
  data() {
    return {
      item: 0,
      entities: [],
      entities_temp: [],
      page_num: 1,
      num_pages: Number,
      isLoading: true
      //current_selection:
    };
  },

  methods: {
    formattedCurrency: function(amount) {
      ////////////console.log("AMOUNT: " + amount);
      if (!isNaN(amount)) {
        return (
          "$" +
          Math.round(amount)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        );
      }
    },

    /*     entity_selected(entity_id) {
      //var ent = JSON.stringify(entity);
      this.$router.push({ name: "supplier", params: { id: entity_id } });
      //bus.$emit("entity-selected", entity);
    }, */

    loadContacts() {
      ////////////console.log("loading suppliers: ");
      this.isLoading = true;
      fetchContacts(this.publisher_id, this.page_num).then(results => {
        this.entities = results.data;
        this.num_pages = results.stats.total_pages;
        this.isLoading = false;

        ////////////console.log("loadContacts data: ", results);
      });
    },

    entityName(name) {
      if (!name) return null;
      else {
        return name.replace("Contact Name: ", "");
        //return name;
      }
    },

    entityDetails(name, mail, phone) {
      details =
        this.entityName(name) +
        " " +
        this.entityEmail(mail) +
        " " +
        this.entityPhone(phone);
      return details;
    },

    entityEmail(mail) {
      if (!mail) return null;
      else {
        return mail.replace("Email: ", "");
      }
      /* mail = mail.replace("Email: ", "");
      return mail; */
    },

    entityPhone(phone) {
      if (!phone) return null;
      else {
        return phone.replace("Phone: ", "");
      }
      /* phone = phone.replace("Phone: ", "");
      return phone; */
    },

    disableIcon(url) {
      if (!url) {
        return true;
      } else {
        return false;
      }
    }
  },
  created() {
    //this.loadPublishers(this.page_num);
  },

  mounted() {
    //this.page_num = this.$route.query.page;
    this.loadContacts();
    //this.isLoadng = false;

    bus.$on("filter-entity-list", entity => {
      if (this.entities.length > 1) {
        this.entities_temp = this.entities;
      }
      this.entities = [entity];
    });

    bus.$on("search-cleared", value => {
      this.entities = this.entities_temp;
    });
  },
  computed: {},
  watch: {
    page_num: {
      handler() {
        ////////////console.log("IN TENDER RESULTS: ");
        this.loadContacts();
      },
      deep: true
    },

    publisher_id: {
      handler() {
        ////////////console.log("IN TENDER RESULTS: ");
        this.loadContacts();
      }
    }
  }
};
</script>
<style>
.wrap-text {
  word-wrap: normal !important;
}

.chip-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}
.nav-list {
  background-color: "#303030";
  border-bottom: 2pt white;
}
.list-item {
  text-decoration: none !important;
  color: "#FFFFFF" !important;
}

.bottom-border {
  border-bottom: 2pt white;
}
</style>
