<template>
  <v-card class="mx-auto" flat min-height="300">
    <v-card-title v-if="profile_type=='publisher'" class="py-1">
      <span
        class="subtitle-2 font-weight-bold"
        style="color:rgba(0, 0, 0, 0.6);"
        >Overall {{ entity_name }} awards vs awards from {{ profile_name }}</span>
    </v-card-title>
    <v-card-title v-else-if="profile_type=='supplier'" class="py-1">
      <span
        class="subtitle-2 font-weight-bold"
        style="color:rgba(0, 0, 0, 0.6);"
        >Overall {{ entity_name }} awards vs awards to {{ profile_name }}</span>
    </v-card-title>
    <v-divider class="py-1"></v-divider>
    <chart-loader v-if="reportLoading" :reportLoading="reportLoading" />
      <profile-member-performance-chart
          v-else
          :entity_id="entity_id"
          :entity_name="entity_name"
          :profile_name="profile_name"
          :data="data"
        />
  </v-card>
</template>

<script>
//import { bus } from "@/main.js";
import ProfileMemberPerformanceChart from "@/components/ProfileMemberPerformanceChart.vue";
import { getProfileMemberPerformance } from "./APIManager.js";
import ChartLoader from "../components/ChartLoader";
//import Donut from "@/components/Donut.vue";

export default {
  components: {
    ProfileMemberPerformanceChart,
    ChartLoader
    //Donut
  },
  props: {
    entity_id: Number,
    profile_id: String,
    profile_type: String,
    profile_name: String,
    entity_type: String,
    entity_name: String
    //results_drawer: Boolean
  },
  data() {
    return {
      tenders: [],
      date_aggs: [],
      publisher_activity: [],
      changed: 1,
      num_groups: 10,
      reportLoading: Boolean,
      data: []
    };
  },

  watch: {

    entity_id(){
      this.loadData(this.data);
    }

  },

  created(){

  },

  async mounted() {
    this.loadData();
  },

  methods: {

    setChanged(){
      this.changed = this.changed + 1;
          //(Math.random() + this.changed) * Math.random();
    },

    async loadData(){
        this.reportLoading = true;
        getProfileMemberPerformance(this.profile_id, this.profile_type, this.entity_type, this.entity_id)
            .then(data => {
                // Extract data for chart
                this.data = data;
                this.reportLoading = false;
            })
    },
  }
};
</script>
