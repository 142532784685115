import { render, staticRenderFns } from "./Cluster.vue?vue&type=template&id=f00e569a&"
import script from "./Cluster.vue?vue&type=script&lang=js&"
export * from "./Cluster.vue?vue&type=script&lang=js&"
import style0 from "./Cluster.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VContent } from 'vuetify/lib/components/VContent';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCard,VCardSubtitle,VCardText,VCardTitle,VCol,VContainer,VContent,VDivider,VIcon,VOverlay,VProgressCircular,VRow,VSkeletonLoader,VTab,VTabItem,VTabs,VTabsSlider,VTooltip})
