<template>
  <div>
    <v-card style="padding-bottom: 20px" tile>
      <v-card-actions class="pl-5 py-3">
        <span class="pr-2">
        <v-btn style="text-decoration:none !important;" :disabled="selected.length <= 0" @click="showSelected=true"
              small
              outlined 
              color="primary">View Selected</v-btn>
        </span>
        <span>
          <cluster-select @clearSelections="selected = [], selectAll=false, selectedEntities = []" :disabled="notSelected" :entityType="'publisher'" :entityIDType="'publisher_id'" :entities="selected" />
        </span>
              
      </v-card-actions>
      

      <v-simple-table style="width:100%;">
        <thead>
          <tr>
            <th class="pl-5">
              <v-checkbox v-model="selectAll" @click="user_clicked_select_all(), select_all()"></v-checkbox>
            </th>
            <th class="text-left subtitle-2 font-weight-bold py-3 pl-5 pr-1">
              Gov
            </th>
            <th
              class="text-left subtitle-2 font-weight-bold py-3 pl-1 pr-2"
            >
            Entity
            </th>
            <th
              class="text-center subtitle-2 font-weight-bold py-3 p2-2 pl-1 ml-1"
            >
              <div>Awarded</div> 
              <div class="caption" style="font-weight:normal !important;">All Time</div>
            </th>
             <th
              class="text-center subtitle-2 font-weight-bold py-3 p2-2 pl-1 ml-1"
            >
              <div>Contracts</div> 
              <div class="caption" style="font-weight:normal !important;">All Time</div>
            </th>
            <th
              class="text-center subtitle-2 font-weight-bold py-3 p2-2 pl-1 ml-1"
            >
              <div>Awarded</div> 
              <div class="caption" style="font-weight:normal !important;">Last 12 Months</div>
            </th>
            <th
              class="text-center subtitle-2 font-weight-bold py-3 p2-2 pl-1 ml-1"
            >
             <div>Avg Contract</div> 
              <div class="caption" style="font-weight:normal !important;">Last 12 Months</div>
            </th>
            <th
              class="text-center subtitle-2 font-weight-bold py-3 p2-2 pl-1 ml-1"
            >
              <div>New Contracts</div> 
              <div class="caption" style="font-weight:normal !important;">Last 12 Months</div>
            </th>
            <th
              class="text-center subtitle-2 font-weight-bold py-3 p2-2 pl-1 ml-1"
            >
              <div>Expiring Contracts</div> 
              <div class="caption" style="font-weight:normal !important;">Next 12 Months</div>
            </th>
          </tr>
        </thead>
        <tbody v-if="isLoading">
          <tr>
            <td colspan="9">
              <v-progress-linear v-show="isLoading" slot="progress" color="primary" indeterminate width="100%"></v-progress-linear>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-for="entity in entities" :key="entity.id">
            <td class="py-0 pl-5 pr-1 ml-1">
              <v-checkbox
                @click="select(), updateSelectedEntities()"
                v-model="selected"
                :value="entity.id"
              ></v-checkbox>
            </td>
            <td class="py-2 pl-5 pr-0 mr-1">
              <v-avatar
                :color="govAvatarColor(entity.gov_type)"
                size="36"
                class="mr-2"
              >
                <span class="white--text caption">{{ entity.gov_type }}</span>
              </v-avatar>
            </td>
            <td class="py-2 px-1 ml-1" style="max-width:300px; min-width:120px;">
              <div
                class="subtitle-2 d-inline-block text-truncate"
                style="max-width:350px; min-width:120px;"
              >
                <router-link :to="{ name: 'entity', params: { id: entity.id } }"
                  >{{entity.name}}</router-link
                >
              </div>
              <div
                  class="caption pt-0 mt-0"
                >
                <!-- <span v-if="!entity.clusters"> -->
                  <span v-for="e in industries(entity)" :key="e">
                    <v-chip v-if="entity.industry"
                      class="my-1 ml-0 mr-2"
                      color="secondary"
                      label
                      text-color="secondary"
                      small
                      outlined
                    >
                    <v-icon small left>
                      mdi-label
                    </v-icon>
                    {{e}}
                    </v-chip>
                  </span>
                <!-- </span> -->
                  <span v-for="clstr in entity.clusters" :key="clstr.id">
                    <router-link
                            :to="{ name: 'cluster', params: { clusterId: clstr.id } }"
                          >
                      <v-chip v-if="clstr"
                        class="my-1 ml-0 mr-2"
                        color="primary"
                        label
                        text-color="primary"
                        small
                        outlined
                      >
                      <v-icon small left>
                        mdi-hexagon-multiple
                      </v-icon>
                      {{clstr.name}}
                      </v-chip>
                    </router-link>
                  </span>
                </div>
            </td>

            <td class="py-2 px-1 text-center">
              <router-link
                  :to="{ name: 'search', query: { publisher_id: entity.id, supplier_id: supplier_id } }"
                >
              {{ formattedCurrency(entity.award_total) }}
              </router-link>
            </td>
            <td class="py-2 px-1 text-center">
              <router-link
                  :to="{ name: 'search', query: { publisher_id: entity.id, supplier_id: supplier_id } }"
                >
                {{ numFormatter(entity.num_notices) }}
              </router-link>
            </td>
            <td class="py-2 px-1 text-center">
              <span>
              {{ formattedCurrency(entity.growth.current_year_total) }}
              </span>
              <span>
                    <v-icon class="py-0 pr-0 mr-0" :color="growth_format(entity.growth.spend_change_percent).color">
                      {{growth_format(entity.growth.spend_change_percent).icontype}}
                    </v-icon>
                    {{ growth_format(entity.growth.spend_change_percent).change }}%
                  </span>
            </td>
            <td class="py-2 px-1 text-center">
              <span>
                {{ formattedCurrency(entity.growth.curr_yr_avg_contract) }}
              </span>
              <span>
                    <v-icon class="py-0 pr-0 mr-0" :color="growth_format(entity.growth.avg_change_percent).color">
                      {{growth_format(entity.growth.avg_change_percent).icontype}}
                    </v-icon>
                    {{ growth_format(entity.growth.avg_change_percent).change }}%
                  </span>
            </td>
          
            <td class="py-2 px-1 text-center">
              <span>
                {{ numFormatter(entity.growth.current_year_vol) }}
              </span> 
              <span>
                    <v-icon class="py-0 mr-0 pr-0" :color="growth_format(entity.growth.vol_change_percent).color">
                      {{growth_format(entity.growth.vol_change_percent).icontype}}
                    </v-icon>
                    {{ growth_format(entity.growth.vol_change_percent).change }}%
                  </span>
            </td>

            <td class="py-2 px-1 text-center">
              <span>
                {{ formattedCurrency(entity.expiring_soon.next_year_total) }}
              </span> 
              <span>
                <v-icon class="py-0 mr-0 pr-0" :color="growth_format(entity.expiring_soon.spend_change_percent).color">
                  {{growth_format(entity.expiring_soon.spend_change_percent).icontype}}
                </v-icon>
                {{ growth_format(entity.expiring_soon.spend_change_percent).change }}%
              </span>
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <div class="text-center">
        <v-pagination
          v-if="entities.length > 1"
          v-model="page_num"
          color="#4EB888"
          :length="num_pages"
          total-visible="0"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          @input="
            $router.replace({ name: $route.name, query: {...$route.query, page: page_num } })"
        ></v-pagination>
      </div>
    </v-card>

    <v-dialog v-model="showSelected" width="50%">
      <v-card>
        <v-list
        
        flat
      >
      <span><v-subheader>Currently selected entities</v-subheader></span><span> 
            <v-btn class="caption ml-2" x-small text color="primary" 
                  :disabled="selected.length <= 0" 
                  @click="(selectAll = false), user_clicked_select_all(), showSelected=false">
              Clear All
            </v-btn></span>
      <v-btn 
        absolute
        top
        right
        icon
        @click="showSelected = false"
        medium
      >
        <v-icon medium>
          mdi-close
        </v-icon>
      </v-btn>

      <v-card flat class="px-2 py-2 mx-2 my-2" min-height="300" max-height="420" style="flex-grow: 1 !important; overflow:auto !important;">

          <v-row
            align="center"
            justify="start"
          >
            <v-col
              v-for="entity in selectedEntities"
              :key="entity.id"
              class="py-1"
            >
              <v-chip
                color="primary"
                small
                label
                clearable
                dense
                close
                dark
                @click:close="deselectEntity(entity.id)"
              >
                {{ entity.name }}
              </v-chip>
            </v-col>
          </v-row>
      </v-card>
      
    </v-list>
    </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { bus } from "@/main.js";
import { fetchPublishers } from "../APIManager";
import { growth_format, formattedCurrency, numFormatter } from "../Utils.js";
import ClusterSelect from "../ClusterSelect.vue";

export default {
  components: {
    ClusterSelect
  },

  props: {
    supplier_id: Number,
  },
  data() {
    return {
      showSelected: false,
      saveButtonActive: false,
      clusterbox: false,
      selectAll: false,
      selected: [],
      selectedCount: 0,
      item: 0,
      entities: [],
      entities_temp: [],
      page_num: 1,
      num_pages: 5,
      isLoading: true,
      userClickedSelectAll: false,
      entitiesViewedCount: 0,
      selectedEntities: []
      //current_selection:
    };
  },

  mounted() {
    this.buildQuery();

    bus.$on("search-cleared", value => {
      this.entities = this.entities_temp;
    });
  },

  methods: {

    growth_format,
    numFormatter,

    select_all(){
      if (this.selectAll) {
          for (let entity in this.entities) {
            //////////////console.log (this.entities);
            //check the entity id is not already in the selected entities
            let index = this.selected.findIndex(x => x === this.entities[entity].id);
            //////////////console.log ("INDEX= ", index, " || ENTITY ID: ", this.entities[entity].id);
            if (index < 0){
              this.selected.push(this.entities[entity].id);
              this.updateSelectedEntities();
            }
            
          }
      }
    },

    user_clicked_select_all(){
      this.userClickedSelectAll = this.selectAll;
      if (!this.selectAll){ //if the user has physically unclicked selectall
        this.selected = [];
        this.selectedEntities = [];
      }
    },
    
    select() {
      ////////////console.log ("selected :", this.selected);
        this.selectAll = false;
    },

    updateSelectedEntities(){

      for (let s in this.selected) { 
        //check the entity id is not already in the selected entities
        let entity = this.entities.find(x => x.id === this.selected[s]);
        ////////////console.log ("updateSelectedEntities :", entity);

        if (entity){
          let index = this.selectedEntities.findIndex(x => x.id === entity.id);
          if (index < 0) {
            this.selectedEntities.push(entity);
          }

        }
      }
    },

    deselectEntity(id){

      let index = this.selected.findIndex(x => x === id);
      //////////////console.log (index,  this.entities[entity].id);
      if (index >= 0) { // only splice array when item is found
        this.selected.splice(index, 1); // 2nd parameter means remove one item only
      }

      let entityIndex = this.selectedEntities.findIndex(x => x.id === id);

      if (entityIndex >= 0) { // only splice array when item is found
        this.selectedEntities.splice(entityIndex, 1); // 2nd parameter means remove one item only
      }

      this.selectAll = false;

    },

    industries(entity){
      var ind = entity["industry"];
      var industries = []
      if (ind && ind.includes(","))
        industries = ind.split(',');
      else
        industries[0] = ind;

      return industries;
    },

    buildQuery(){

      if (this.supplier_id){
        var query = {page: this.page_num, supplier_id: this.supplier_id}
        this.loadPublishers(this.page_num, query);
      }
      else if (this.$route.query["page"]){
        ////////////////console.log("HAS PAGE: ", this.$route.query["page"])
        this.page_num = this.$route.query["page"]
        this.loadPublishers(this.page_num, this.$route.query);
      }
      else{
        this.page_num = 1;
        this.loadPublishers(this.page_num, this.$route.query);
      }
      
      //this.isLoadng = false;
    },

    formattedCurrency,

    entity_selected(entity_id) {
      //var ent = JSON.stringify(entity);
      this.$router.push({ name: "entity", params: { id: entity_id } });
      //bus.$emit("entity-selected", entity);
    },

    loadPublishers(page_num, query) {

      ////////////////console.log("QUERY FROM SUPP PROFILE: ", query);
      this.isLoading = true;

      //var qry = JSON.parse(JSON.stringify(query));

      fetchPublishers(page_num, query).then(results => {
        this.entities = results.data;
        
        this.num_pages = results.stats.total_pages;
        this.page_num = results.stats.page_no;
        this.select_all();
        this.isLoading = false;

        ////////////////console.log("loadPublishers data: ", results);
      });
    },

    disableIcon(url) {
      if (!url) {
        return true;
      } else {
        return false;
      }
    },

    govAvatarColor(gov) {
      var color = "grey";
      switch (gov) {
        case "NSW":
          color = "#41a7d9";
          break;
        case "VIC":
          color = "#0058AC";
          break;
        case "WA":
          color = "#FFD200";
          break;
        case "QLD":
          color = "#A6003D";
          break;
        case "AUS":
          color = "#D8993B";
          break;
        case "FED":
          color = "#D8993B";
          break;
        case "DEF":
          color = "#d8673b";
          break;
        case "SA":
          color = "#ff4081";
          break;
        case "TAS":
          color = "#4eb888";
          break;
        case "ACT":
          color = "#41a7d9";
          break;
        case "NT":
          color = "#FF3647";
          break;
      }

      return color;
    }
  },
  created() {
    //this.loadPublishers(this.page_num);
  },

  computed: {
    notSelected(){
      if (this.selected.length > 0) return false;
      else return true;
    }
  },
  watch: {
     $route(to, from) {
      this.buildQuery();
    },
  }
};
</script>
<style>
.wrap-text {
  word-wrap: normal !important;
}

.chip-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}
.nav-list {
  background-color: "#303030";
  border-bottom: 2pt white;
}
.list-item {
  text-decoration: none !important;
  color: "#FFFFFF" !important;
}

.bottom-border {
  border-bottom: 2pt white;
}
</style>
