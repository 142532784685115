<template>
  <v-card tile class="fill-height">
    <v-card tile flat class="pl-6">
      
      <v-card-actions class="py-3">
        <span class="pr-2">
        <v-btn style="text-decoration:none !important;" :disabled="selected.length <= 0" @click="showSelected=true"
              small
              outlined 
              color="primary">View Selected</v-btn>
        </span>
        <span class="pr-2">
        <v-btn style="text-decoration:none !important;" :disabled="selected.length <= 0" @click="deleteDialog=true"
              small
              outlined 
              color="primary">Delete from Cluster</v-btn>
        </span>
              
      </v-card-actions>
    </v-card>
    <v-skeleton-loader
            v-if="loading"
            type="table-tbody"
      ></v-skeleton-loader>
    <v-data-table id="top_entities" v-else
        v-model="selected"
        show-select
        :items="dataview.data"
        :items-per-page="50"
        item-key="id"
        :headers="dataview.headers"
        class="elevation-1 px-2"
        @item-selected="select"
        @toggle-select-all="select_all"
    >
    <template #item.publisher="{ item }">
      <router-link class="font-weight-normal caption" :to="{ name: group.alt_value, params: { id: item[group.value].id } }">
        {{ item[group.value].name  }}
      </router-link>
    </template>
    <template #item.category="{ item }">
      <router-link class="font-weight-normal caption" :to="{ name: group.alt_value, params: { id: item[group.value].id } }">
        {{ item[group.value].name  }}
      </router-link>
    </template>
    <template #item.supplier="{ item }">
      <router-link class="font-weight-normal caption" :to="{ name: group.alt_value, params: { id: item[group.value].id } }">
        {{ item[group.value].name  }}
      </router-link>
    </template>
    <template #item.keyword="{ item }">
      <span class="font-weight-normal caption">
        {{ item[group.value] }}
      </span>
    </template>
    <template #item.doc_count="{ item }">
      <router-link class="font-weight-normal caption" v-if="group.value=='keyword'" :to="{ name: 'search', query: {...$route.query, 'tender': item[group.value], viewIndex:0 } }">
        {{ item.doc_count  }}
      </router-link>
      <span class="font-weight-normal caption" v-else-if="group.value=='supplier_cluster'" >
        {{ item.doc_count  }}
      </span>
      <span class="font-weight-normal caption" v-else-if="group.value=='buyer_cluster'" >
        {{ item.doc_count  }}
      </span>
      <router-link class="font-weight-normal caption" v-else :to="{ name: 'search', query: {...$route.query, [group.value+'_id']: item[group.value].id, [cluster_type]: cluster_id, viewIndex:0 } }">
        {{ item.doc_count  }}
      </router-link>
    </template>
    <template #item.value="{ item }">
        <span class="font-weight-normal caption">${{ item.value  }}M</span>
    </template>
    
    <template #item.avg_contract="{ item }">
        <span class="font-weight-normal caption">${{ item.avg_contract  }}M</span>
    </template>
    <template #item.per_awards="{ item }">
      <v-progress-linear
        :value="item.per_awards"
        color="primary"
        height="20"
      >
        <template v-slot:default="{ value }">
          <strong class="font-weight-normal caption">{{ Math.ceil(value) }}%</strong>
        </template>
      </v-progress-linear>
    </template>
    <template #item.per_award_value="{ item }">
      <v-progress-linear
        :value="item.per_award_value"
        color="rgb(255, 231, 10)"
        height="20"
      >
        <template v-slot:default="{ value }">
          <strong class="font-weight-normal caption">{{ Math.ceil(value) }}%</strong>
        </template>
      </v-progress-linear>
    </template>
    <template #item.current_year_total="{ item }">
        <span class="font-weight-normal caption">${{ item.current_year_total  }}M</span>
    </template>
    <template #item.spend_change_percent="{ item }">
        <div class="font-weight-normal caption" style="color:#12253a; min-width:100px !important; text-align: left !important;">
          <v-icon :color="growth_format(item.spend_change_percent).color">
            {{ growth_format(item.spend_change_percent).icontype }}
          </v-icon>
          {{ growth_format(item.spend_change_percent).change }}%
        </div>
    </template>
    <template #item.curr_yr_avg_contract="{ item }">
        <span class="font-weight-normal caption">${{ item.curr_yr_avg_contract  }}M</span>
    </template>
    <template #item.avg_change_percent="{ item }">
        <div class="font-weight-normal caption" style="color:#12253a; min-width:100px !important; text-align: left !important;">
          <v-icon :color="growth_format(item.avg_change_percent).color">
            {{ growth_format(item.avg_change_percent).icontype }}
          </v-icon>
          {{ growth_format(item.avg_change_percent).change }}%
        </div>
    </template>
    </v-data-table>

    <v-dialog v-model="sharebox" hide-overlay max-width="1200" absolute style="overflow-x:'hidden' !important;">
        <v-card tile>
        <v-card-title>Add leaderboard to a custom report
            <div class="flex-grow-1"></div> <v-btn icon @click="sharebox=!sharebox"><v-icon medium>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card tile flat class="mx-auto pa-5" max-width="1150px">
            <v-row justify="center">
            <v-col
              cols="6"
              class="pb-0 d-flex child-flex"
            >
              <v-combobox
                v-model="selected_board"
                :items="storyboards"
                :loading="isLoading"
                item-value="id"
                :search-input.sync="search"
                item-text="plainTextTitle"
                label="Select a report"
                outlined
                dense
                class="pb-0"
                return-object
              >
              </v-combobox>
            </v-col>
              <v-col
              cols="6"
              class="pb-0"
            >

              <v-btn color="primary" :disabled="!saveButtonActive" @click="saveChart(), updateBoards(), sharebox=!sharebox">Save leaderboard to report</v-btn>
              </v-col>

              <v-col cols="12" class="pt-0 d-flex child-flex">
                <story :changed="embed_obj_changed" :key="embed_obj_changed" :data="embed_object" />
              </v-col>
            </v-row>
        </v-card>
        </v-card>
    </v-dialog>

    <v-dialog v-model="showSelected" width="50%">
      <v-card>
        <v-list
        
        flat
      >
      <span><v-subheader>Currently selected entities</v-subheader></span><span> 
            <v-btn class="caption ml-2" x-small text color="primary" 
                  :disabled="selected.length <= 0" 
                  @click="(selectAll = false), user_clicked_select_all(), showSelected=false">
              Clear All
            </v-btn></span>
      <v-btn 
        absolute
        top
        right
        icon
        @click="showSelected = false"
        medium
      >
        <v-icon medium>
          mdi-close
        </v-icon>
      </v-btn>

      <v-card flat class="px-2 py-2 mx-2 my-2" min-height="300" max-height="420" style="flex-grow: 1 !important; overflow:auto !important;">

          <v-row
            align="center"
            justify="start"
          >
            <v-col
              v-for="entity in selectedEntities"
              :key="entity.id"
              class="py-1"
            >
              <v-chip
                color="primary"
                small
                label
                clearable
                dense
                close
                dark
                @click:close="deselectEntity(entity.id)"
              >
                {{ entity.name }}
              </v-chip>
            </v-col>
          </v-row>
      </v-card>
      
    </v-list>
    </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" width="50%">
      <v-card>
        <v-list
        
        flat
      >
      <span><v-subheader>The currently selected entities will be deleted from the cluster</v-subheader></span><span> 
            <v-btn class="caption ml-2" x-small text color="primary" 
                  :disabled="selected.length <= 0" 
                  @click="(selectAll = false), user_clicked_select_all(), deleteDialog=false">
              Clear All
            </v-btn></span>
      <v-btn 
        absolute
        top
        right
        icon
        @click="deleteDialog = false"
        medium
      >
        <v-icon medium>
          mdi-close
        </v-icon>
      </v-btn>

      <v-card flat class="px-2 py-2 mx-2 my-2" min-height="300" max-height="420" style="flex-grow: 1 !important; overflow:auto !important;">

          <v-row
            align="center"
            justify="start"
          >
            <v-col
              v-for="entity in selectedEntities"
              :key="entity.id"
              class="py-1"
            >
              <v-chip
                color="primary"
                small
                label
                clearable
                dense
                close
                dark
                @click:close="deselectEntity(entity.id)"
              >
                {{ entity.name }}
              </v-chip>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-btn 
              :disabled="selected.length <= 0"
              absolute
              bottom
              right
              @click="removeEntities(cluster_id, selected)"
              small
              color="primary"
            >
            Delete
              <v-icon small left>
                mdi-delete
              </v-icon>
            </v-btn>
          </v-card-actions>
      </v-card>
      
    </v-list>
    </v-card>
    </v-dialog>
    
  </v-card>
</template>

<script>
import { bus } from "../main.js";
import moment from "moment";
//import tableToJSON from "tabletojson";
import { growth_format, exportCSVFile } from "./Utils.js";
import { entitySearch } from "./APIManager.js";
import { fetchStoryboards, addStory, addStoryboard, deleteClusterEntities} from "./APIManager"
import Story from "./Story.vue";

export default {
  props: {
    story: {
      type: Boolean,
      default: false
    },
    chart_options: Object,
    changed: Number,
    cluster_id: Number,
    cluster_type: String,
    supplier_id: Number,
    entity_ids: Array,
    entity_type: String,
    query: String,
    target_entity: String
  },
  components: {
    Story
  },

  data() {
    return {
      sharebox: false,
      widget_name: "leaderboard",
      entity_aggs: [],
      defaultButton: 0,
      currentIndex:0,
      publisher_aggs: [],
      keyword_aggs: [],
      supplier_aggs: [],
      category_aggs: [],
      supplier_cluster_aggs: [],
      buyer_cluster_aggs: [],
      govt_aggs:[],
      avg_contract_value: Number,
      total_amount: Number,
      updated: 0,
      loading: Boolean,
      //row_number: [50],
      num_entities: 1000,
      total_records: Number,
      num_publishers: Number,
      num_suppliers: Number, 
      num_categories: Number,
      num_keywords: Number,
      num_supplier_clusters: Number,
      num_buyer_clusters: Number,
      num_govts: Number,
      other_categories: Number,
      other_suppliers: Number,
      other_publishers: Number,
      group_options: [{text: "Buyer", value:"publisher", alt_value:"entity"},
                      {text: "Category", value:"category", alt_value:"category"},
                      {text: "Supplier", value:"supplier", alt_value:"supplier"},
                      {text: "Keyword", value:"keyword", alt_value:"keyword"},
                      {text: "Government", value:"government", alt_value:"government"},
                      {text: "Supplier Cluster", value:"supplier_cluster", alt_value:"supplier_cluster"},
                      {text: "Buyer Cluster", value:"buyer_cluster", alt_value:"buyer_cluster"}],
      group: {text: "Buyer", value:"publisher", alt_value:"entity"},
      dataview: {headers: [], data: []},
      storydata: {},
      embed_object: {},
      storyboards: [],
      selected_board: null,
      resetChart:Boolean,
      new_board:null,
      search: null,
      embed_obj_changed: Number,
      deleteDialog: false,
      showSelected: false,
      saveButtonActive: false,
      clusterbox: false,
      selectAll: false,
      selected: [],
      selectedCount: 0,
      item: 0,
      entities: [],
      entities_temp: [],
      page_num: 1,
      num_pages: 5,
      isLoading: true,
      userClickedSelectAll: false,
      entitiesViewedCount: 0,
      selectedEntities: []
    };
  },

  watch: {
    cluster_id:{
      handler(newValue, oldValue) {
        this.loading = true;
        this.selected = [];
        this.selectedEntities = [];
        if (this.entity_type == "publisher_id"){
          this.loadData(0);
            //this.loadPublishers(this.page_num, this.query);
          }
        else{
          this.loadData(2);
          //this.loadSuppliers(this.page_num, this.query);
        }
      },

    },
  },

  created() {
    ////////////////console.log("SEARCH TERMS: ", JSON.stringify(this.search_terms));
    //this.updated = (Math.random() + 10) * Math.random();
  },

  async mounted() {
    
    this.loading = true;
    this.selected = [];
    this.selectedEntities = [];
    if (this.entity_type == "publisher_id"){
      this.loadData(0);
      }
    else{
      this.loadData(2);
    }
    
  },

  methods: {
    deleteClusterEntities,
    growth_format,


    async updateBoards(){
        this.isLoading = true;
        this.search = null;
        this.saveButtonActive = false;

        var data = await fetchStoryboards();
        this.storyboards = data.storyboards;

        for (var i in this.storyboards){
          this.storyboards[i]["plainTextTitle"] = this.stripHTML(this.storyboards[i]["title"]);
        }

        this.isLoading = false;

    },

    stripHTML(text){
        let strippedText = text.replace(/(<([^>]+)>)/gi, "");
        return strippedText;
    },

    set_chart_object(){
      this.storydata["defaultButton"] = this.currentIndex;
      this.embed_object["title"] = this.title;
      this.embed_object["description"] = this.description;
      this.embed_object["chart_type"] = "leaderboard";
      this.embed_object["payload"] = {imgData: {}, options: this.storydata}
      this.embed_object["id"] = (Math.random() + Date.now()) * Math.random();
      this.embed_obj_changed = (Math.random() + Date.now()) * Math.random();
    },

    async saveChart(){

      let data = {};
        data["title"] = this.embed_object.title;
        data["description"] = this.embed_object.description;
        data["chart_type"] = this.embed_object.chart_type;
        data["payload"] =  this.embed_object.payload;

      if (!this.selected_board || (this.search != this.selected_board["plainTextTitle"])){
          //this.selected_board = this.addItem(this.search);
          await addStoryboard({title: this.search, description:'This report needs an overview', story: data})
        }
        else{
          await addStory(this.selected_board.id, data)
          ////////////////console.log("EXISTING REPORT: ", this.selected_board);
        }

      ////////////////console.log("LEADER BOARD PAYLOAD: ", data);

    },

    getPercent(value, total) {
      return (value/total * 100).toFixed(1)
    },

    buildTable(){
      
      //this.dataview.headers[0] = {text:"Rank", value:"rank"};
      this.dataview.headers[0] = {text: this.group.text, value: this.group.value, sortable: false};
      this.dataview.headers[1] = {text:"# Notices", value: 'doc_count', align: 'center'};
      this.dataview.headers[2] = {text:"Value", value: 'value', align: 'center'};
      this.dataview.headers[3] = {text:"Avg", value: 'avg_contract', align: 'center'};
      this.dataview.headers[4] = {text:"% Notices", value: 'per_awards', align: 'center'};
      this.dataview.headers[5] = {text:"% Value", value: 'per_award_value', align: 'center'};
      this.dataview.headers[6] = {text:"12M Val", value: 'current_year_total', align: 'center'};
      this.dataview.headers[7] = {text:"12M Chg", value: 'spend_change_percent', align: 'left'};
      this.dataview.headers[8] = {text:"12M Avg", value: 'curr_yr_avg_contract', align: 'center'};
      this.dataview.headers[9] = {text:"12M Avg Chg", value: 'avg_change_percent', align: 'left'};

      for (var item in this.entity_aggs){
        let grp_value = this.group.value;
        if (grp_value == "keyword" || grp_value == "government" || grp_value == "supplier_cluster" || grp_value == "buyer_cluster"){
          this.dataview.data[item] = {};
          //this.dataview.data[item]["rank"] = parseInt(item)+1;
          this.dataview.data[item][grp_value] =  this.entity_aggs[item].key;
          this.dataview.data[item]["doc_count"] = this.entity_aggs[item].doc_count;
          this.dataview.data[item]["value"] = (this.entity_aggs[item].total_amount.value / 1000000).toFixed(2);
          this.dataview.data[item]["avg_contract"] = (parseFloat((this.entity_aggs[item].total_amount.value / this.entity_aggs[item].doc_count)) / 1000000).toFixed(2);
          this.dataview.data[item]["per_awards"] = this.getPercent(this.entity_aggs[item].doc_count, this.total_records);
          if (this.total_amount < 1){
            this.dataview.data[item]["per_award_value"] = 'N/A';
          }
          else{
            this.dataview.data[item]["per_award_value"] = this.getPercent(this.entity_aggs[item].total_amount.value, this.total_amount);
          }
          this.dataview.data[item]["current_year_total"] = (this.entity_aggs[item].growth.current_year_total / 1000000).toFixed(2);
          this.dataview.data[item]["spend_change_percent"] = (this.entity_aggs[item].growth.spend_change_percent);
          this.dataview.data[item]["curr_yr_avg_contract"] = (this.entity_aggs[item].growth.curr_yr_avg_contract / 1000000).toFixed(2);
          this.dataview.data[item]["avg_change_percent"] = (this.entity_aggs[item].growth.avg_change_percent);
        }
        else{
          let grp_id = this.entity_aggs[item].key;

          this.dataview.data[item] = {};
          //this.dataview.data[item]["rank"] = parseInt(item)+1;
          this.dataview.data[item]["id"] = grp_id;
          this.dataview.data[item]["name"] = this.entity_aggs[item][grp_value].buckets[0].key;
          this.dataview.data[item][grp_value] = {id: grp_id, name: this.entity_aggs[item][grp_value].buckets[0].key};
          this.dataview.data[item]["doc_count"] = this.entity_aggs[item][grp_value].buckets[0].doc_count;
          this.dataview.data[item]["value"] = (this.entity_aggs[item][grp_value].buckets[0].total_amount.value / 1000000).toFixed(2);
          this.dataview.data[item]["avg_contract"] = (parseFloat((this.entity_aggs[item][grp_value].buckets[0].total_amount.value / this.entity_aggs[item][grp_value].buckets[0].doc_count)) / 1000000).toFixed(2);
          this.dataview.data[item]["per_awards"] = this.getPercent(this.entity_aggs[item][grp_value].buckets[0].doc_count, this.total_records);
          if (this.total_amount < 1){
            this.dataview.data[item]["per_award_value"] = 'N/A';
          }
          else{
            this.dataview.data[item]["per_award_value"] = this.getPercent(this.entity_aggs[item][grp_value].buckets[0].total_amount.value, this.total_amount);
          }
          this.dataview.data[item]["current_year_total"] = (this.entity_aggs[item][grp_value].buckets[0].growth.current_year_total / 1000000).toFixed(2);
          this.dataview.data[item]["spend_change_percent"] = (this.entity_aggs[item][grp_value].buckets[0].growth.spend_change_percent);
          this.dataview.data[item]["curr_yr_avg_contract"] = (this.entity_aggs[item][grp_value].buckets[0].growth.curr_yr_avg_contract / 1000000).toFixed(2);
          this.dataview.data[item]["avg_change_percent"] = (this.entity_aggs[item][grp_value].buckets[0].growth.avg_change_percent);

          ////console.log(this.dataview.data[item]);
        }
        
      }

    },

    openWidget(){
        let routeData = this.$router.resolve({name: this.widget_name, query: this.$route.query});
        window.open(routeData.href, '_blank');
    },

    sort (col) {
      var table, rows, switching, i, x, y, shouldSwitch;
      table = document.getElementById("top_entities").getElementsByTagName("TABLE")[0];
      ////////////////console.log(table);
      switching = true;
      /*Make a loop that will continue until
      no switching has been done:*/
      while (switching) {
        //start by saying: no switching is done:
        switching = false;
        rows = table.rows;
        /*Loop through all table rows (except the
        first, which contains table headers):*/
        for (i = 1; i < (rows.length - 1); i++) {

          //start by saying there should be no switching:
          shouldSwitch = false;
          /*Get the two elements you want to compare,
          one from current row and one from the next:*/
          x = rows[i].getElementsByTagName("TD")[col];
          y = rows[i + 1].getElementsByTagName("TD")[col];
          //check if the two rows should switch place:
          if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
            //if so, mark as a switch and break the loop:
            shouldSwitch = true;
            break;
          }
        }
        if (shouldSwitch) {
          /*If a switch has been marked, make the switch
          and mark that a switch has been done:*/
          rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
          switching = true;
        }
      }
      
    },

    exportTable(){

      //need this to convert table to string representation
      var tmp = document.createElement("div");

      let table = document.querySelector('#top_entities').cloneNode( true );

      tmp.appendChild(table);

      const HtmlTableToJson = require('html-table-to-json');

      var jsonTable = HtmlTableToJson.parse(tmp.innerHTML);

      var headers = {entity:'Buyer/Supplier/Category/Keyword/Supplier Cluster/Buyer Cluster',
                      notices:'# Notices', 
                      value:'$ Value', 
                      avg_contract:'Avg Contract', 
                      per_awards: '% Awards',
                      per_value:'% Award Value',
                      yoy_total:'$ YoY Total',
                      yoy_change:'$ YoY Change',
                      yoy_avg:'$ YoY Avg',
                      yoy_avg_change:'$ YoY Avg Change',
                    };

      try {
        var dte = new Date();

        var fileTitle = "tendertrace_leaderboard" + "_" + dte.toISOString();
        exportCSVFile(headers, jsonTable.results[0], fileTitle);

      } catch (err) {
      }

    },

    loadGroup(index){
      ////////////console.log("loading group: ", index)
      ////////////console.log("this.group_options: ", this.group_options)
      this.loading = true;
      this.entity_aggs = [];
      this.dataview.headers = [];
      this.dataview.data = [];
    
      if (this.group_options[index].value == "category"){
        this.group = new Object;
        this.group = this.group_options[index];
        var new_index = 0;

        for (var i in this.category_aggs){
          if (this.category_aggs[i].category.buckets.length == 0){
            ////////////////console.log(this.entity_aggs[i]);
            continue;
          }
          else{
            this.entity_aggs[new_index] = this.category_aggs[i];
            new_index++;
          }
        }

      }else if (this.group_options[index].value == "publisher"){
        this.group = new Object;
        this.group = this.group_options[index];
        this.entity_aggs = this.publisher_aggs;
      }
      else if (this.group_options[index].value == "government"){
        this.group = new Object;
        this.group = this.group_options[index];
        var new_index = 0;

        for (var i in this.govt_aggs){
          this.entity_aggs[new_index] = this.govt_aggs[i];
          new_index++;
        }
      }
      else if (this.group_options[index].value == "keyword"){
        this.group = new Object;
        this.group = this.group_options[index];
        var new_index = 0;

        for (var i in this.keyword_aggs){
          if (this.keyword_aggs.length == 0){
            ////////////////console.log(this.entity_aggs[i]);
            continue;
          }
          else{
            this.entity_aggs[new_index] = this.keyword_aggs[i];
            new_index++;
          }
        }
      }
      else if (this.group_options[index].value == "buyer_cluster"){
        this.group = new Object;
        this.group = this.group_options[index];
        var new_index = 0;

        for (var i in this.buyer_cluster_aggs){
          if (this.buyer_cluster_aggs.length == 0){
            ////////////////console.log(this.entity_aggs[i]);
            continue;
          }
          else{
            this.entity_aggs[new_index] = this.buyer_cluster_aggs[i];
            new_index++;
          }
        }
      }
      else if (this.group_options[index].value == "supplier_cluster"){
        this.group = new Object;
        this.group = this.group_options[index];
        var new_index = 0;

        for (var i in this.supplier_cluster_aggs){
          if (this.supplier_cluster_aggs.length == 0){
            ////////////////console.log(this.entity_aggs[i]);
            continue;
          }
          else{
            this.entity_aggs[new_index] = this.supplier_cluster_aggs[i];
            new_index++;
          }
        }
      }
      else{
        this.group = new Object;
        this.group = this.group_options[index];
        this.entity_aggs = this.supplier_aggs;
      }

      ////////////console.log("finished loading group...")
      
      this.buildTable();
      ////////////console.log("finished building table...")
      this.loading = false;
    },


    select_all(lst){
      if (lst.value == true) {
        this.selected = lst.items;
        this.updateSelectedEntities();
      }
      else{
        this.selected = [];
        this.selectedEntities = [];
      }
    },

    user_clicked_select_all(){
      this.userClickedSelectAll = this.selectAll;
      if (!this.selectAll){ //if the user has physically unclicked selectall
        this.selected = [];
        this.selectedEntities = [];
      }
    },
    
    select(val) {
      //console.log(val);
      if (val.value == true) {
        this.selected.push(val.item);
        this.updateSelectedEntities();
      }
      else{
        this.deselectEntity(val.item.id)
      }
    },

    updateSelectedEntities(value){
      ////console.log(value)
      for (let s in this.selected) { 
        //check the entity id is not already in the selected entities
        this.selectedEntities.push(this.selected[s]);
      }
    },

    deselectEntity(id){

      let index = this.selected.findIndex(x => x.id === id);
      //////////////console.log (index,  this.entities[entity].id);
      if (index >= 0) { // only splice array when item is found
        this.selected.splice(index, 1); // 2nd parameter means remove one item only
      }

      let entityIndex = this.selectedEntities.findIndex(x => x.id === id);

      if (entityIndex >= 0) { // only splice array when item is found
        this.selectedEntities.splice(entityIndex, 1); // 2nd parameter means remove one item only
      }

      this.selectAll = false;

    },

    removeEntities(cluster_id, selected){

      this.isLoading = true;
      this.deleteDialog = false;
      let ents = []
      for (var i in selected){
        ents.push(selected[i].id)
      }
      this.selected = [];
      this.selectedEntities = [];
      ////console.log("TO DELETE: ", ents, " clusrer: ", cluster_id);

      this.deleteClusterEntities(cluster_id, ents).then(results => {
        this.$emit('reloadCluster', true)
      });
    },

    entity_selected(entity_id) {
      //var ent = JSON.stringify(entity);
      this.$router.push({ name: "entity", params: { id: entity_id } });
      //bus.$emit("entity-selected", entity);
    },

    async loadData(index) {
      this.currentIndex = index;
      var query = JSON.parse(this.query);

      this.loading = true;

      query["agg_size"] = this.num_entities;
      query["target_aggs"] = this.group_options[index].value;

      entitySearch(query, 1)
        .then(data => {
          //////////////console.log("LEADERBOARD DATA: ", data);
          this.storydata = data;
          this.total_records = data.hits.value;
          this.num_publishers =
            data.aggregations.publisher_id.buckets.length +
            data.aggregations.publisher_id.sum_other_doc_count;
          this.num_govts = data.aggregations.government.buckets.length + data.aggregations.government.sum_other_doc_count;
          this.govt_aggs = data.aggregations.government.buckets;
          this.publisher_aggs = data.aggregations.publisher_id.buckets;
          this.other_publishers = data.aggregations.publisher_id.sum_other_doc_count;
          this.supplier_aggs = data.aggregations.supplier_id.buckets;

          if (data.aggregations.search_terms){
            this.keyword_aggs = data.aggregations.search_terms.buckets;
            this.num_keywords = this.keyword_aggs.length;
          }
          else{
            this.keyword_aggs = [];
            this.num_keywords = Number;
          }

          if (data.aggregations.supplier_clusters){
            this.supplier_cluster_aggs = data.aggregations.supplier_clusters.buckets;
            this.num_supplier_clusters = this.supplier_cluster_aggs.length;
          }
          else{
            this.supplier_cluster_aggs = [];
            this.num_supplier_clusters = Number;
          }

          if (data.aggregations.buyer_clusters){
            this.buyer_cluster_aggs = data.aggregations.buyer_clusters.buckets;
            this.num_buyer_clusters = this.buyer_cluster_aggs.length;
          }
          else{
            this.buyer_cluster_aggs = [];
            this.num_buyer_clusters = Number;
          }

          this.other_suppliers = data.aggregations.supplier_id.sum_other_doc_count;
          this.num_suppliers =
            data.aggregations.supplier_id.buckets.length +
            data.aggregations.supplier_id.sum_other_doc_count;
          this.category_aggs = data.aggregations.category_id.buckets;
          this.other_categories = data.aggregations.category_id.sum_other_doc_count;
          this.num_categories =
            data.aggregations.category_id.buckets.length +
            data.aggregations.category_id.sum_other_doc_count;
          this.total_amount = data.aggregations.total_amount.value;
          //this.plain_text_query = data.plain_text_query;
          ////////////////console.log(JSON.stringify(this.gov_aggs));
          this.loadGroup(index);
          this.loading = false;
        })
        .catch(error => {
          ////////////////console.log(error);
          //this.errored = true;
          //this.overlay = false;
        });
    },


    check_gov_name(gov) {
      if (gov === "AUS") {
        return "AUS";
      } 
      else if (gov === "FED") {
        return "FED";
      }
      else if (gov === "DEF") {
        return "DEF";
      }else {
        return gov;
      }
    },

    numFormatter(num) {
      if (num > 999 && num < 1000000) {
        return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
      } else if (num > 1000000 && num < 1000000000) {
        return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
      } else if (num > 1000000000) {
        return (num / 1000000000).toFixed(1) + "B"; // convert to M for number from > 1 million
      } else if (num < 1000) {
        return num; // if value < 1000, nothing to do
      }
    },

    formattedCurrency: function(amount) {
      ////////////////console.log("AMOUNT: " + amount);
      if (!isNaN(amount)) {
        return "$" + this.numFormatter(amount);
        //return "$" + Math.round(amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } //else {
      //return null;
      //}
    },

    formattedCurrencyFull: function(amount) {
      ////////////////console.log("AMOUNT: " + amount);
      if (!isNaN(amount)) {
        return (
          "$" +
          Math.round(amount)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        );
      } //else {
      //return null;
      //}
    },
    showHideDetails(item) {
      //this.item_details = !this.item_details;
      this.item_details.show = true;
      this.item_details.record = item;
      bus.$emit("show-hide-details", this.item_details);
    },
    formatDate(date_value) {
      return moment(date_value).format("MMMM YYYY");
    }
  },
  computed: {}
};
</script>

<style>
.v-data-table-header th {
  white-space: nowrap;
}
</style>
