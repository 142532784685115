<script>
import { Bar, Line } from "vue-chartjs";
import { formattedCurrencyFull, formattedCurrency, govColor, getPercent } from "./Utils.js";
import { bus } from "../main.js";

export default {
  props: {
    chart_headers: [],
    chart_data: {},
    chart_labels: Array(),
    chart_axes: {},
    metric_name: String(),
    target_entity: String(),
    entity_type: String(),
    index: Number,
    changed: Number,
    legend_position: String(),
    legend_display: Boolean(),
    aspect_ratio: Boolean(),
    supplier_id: Number,
    currentAggType: String()
  },
  extends: Bar,
  Line,
  data() {
    return {
      htmlLegend: null,
      //yMetric: String(),
      options: {
        onClick: this.graphClickEvent,
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }
        },
        scales: {
          yAxes: [
            {
              stacked: true,
              id: "value",
              ticks: {
                beginAtZero: false,
                //fontColor: "#FFFFFF",
                callback: (value, index, values) => {
                  //var label = data.datasets[tooltipItem.datasetIndex].label;
                  // Format the y-axis value.
                  var value =
                    "$" +
                    //value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    this.numFormatter(value);

                  return value;
                },
                
              },
              gridLines: {
                color: "#f4f4f4",
                display: false
              }
            },
            {
              id: "count",
              display: true,
              position: "right",
              ticks: {
                beginAtZero: true,
                //fontColor: "#FFFFFF",
                callback: (value, index, values) => {
                  //var label = data.datasets[tooltipItem.datasetIndex].label;
                  // Format the y-axis value.
                  var value =
                    //value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    this.numFormatter(value);

                  return value;
                },
                //fontColor: "rgba(255, 20, 99, 0.8)",
              },
              gridLines: {
                color: "#424242",
                display: false
              }
            },
            {
              id: "avg_value",
              display: false,
              ticks: {
                beginAtZero: false,
                //fontColor: "#FFFFFF",
                callback: (value, index, values) => {
                  return (
                    "$" + parseFloat(value/ 1000000).toFixed(2) + "M"
                  );
                }
              },
              gridLines: {
                color: "#424242",
                display: false
              }
            }
          ],
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: false
              },
              ticks: {
                //min: 10000,
                //fontColor: "#FFFFFF"
              }
            }
          ]
        },
        
        legend: {
          maxWidth: 10,
          display: true,
          position: "bottom",
          align: "center",
          labels: {
            // This more specific font property overrides the global property
            //fontColor: "#FFFFFF",
            usePointStyle: true,
            fontSize: 11,
            boxWidth: 6,
            font: {
              weight: "bold",
              family: "verdana",
              size: "12"
            }
          }
        },
        //legend: false,

        responsive: true,
        maintainAspectRatio: false,
        hover: {
          mode: "nearest",
          intersect: true
        }
      }
    };
  },

  watch: {
    changed() {
      this.loadChart();
      //this.$emit("htmlLegend", this.$data._chart.generateLegend());
    }

  },

  created() {
    /* if (this.chart_data.data.length > 0) {
      this.loadChart();
    } */
  },

  mounted() {
    this.$nextTick(()=>{
        if (this.chart_data.data.length > 0) {
          this.loadChart();
        }
    });
    
  },

  methods: {
    numFormatter(num) {
      if (num > 999 && num < 1000000) {
        return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
      } else if (num >= 1000000 && num < 1000000000) {
        return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
      } else if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1) + "B"; // convert to M for number from > 1 million
      } else if (num < 900) {
        return num; // if value < 1000, nothing to do
      }
    },

    graphClickEvent(event, array) {

      if (array[0] && array[0]._model["label"]) {
        var query = JSON.parse(JSON.stringify(this.$route.query));
        if (this.$route.name == "entity") {
          query["publisher_id"] = this.$route.params.id;
        }
        if (this.$route.name == "supplier") {
          query["supplier_id"] = this.$route.params.id;
        }
        if (this.$route.name == "category") {
          query["category_id"] = this.$route.params.id;
        }
        if (this.$route.name == "parent_category") {
          query["parent_category_id"] = this.$route.params.id;
        }

        if (this.entity_type == "major_category"){
          query["type"] = "parent_category";
          query["parent_category"] = array[0]._model.datasetLabel;
        } 
        else{
          query["type"] = this.entity_type;
          query[this.entity_type] = array[0]._model.datasetLabel;
        }

        if (this.currentAggType == "contracted") {
          query["contract_year_from"] = array[0]._model.label;
          query["contract_year_to"] = array[0]._model.label;
        } else {
          query["expiry_from"] = array[0]._model.label;
          query["expiry_to"] = array[0]._model.label;
        }

        bus.$emit("barClicked", query);
      }
    },

    loadChart() {

      ////////////console.log("CHART DATA: ", this.chart_data);

      var datacollection = {};
      var datasets = [];

      for (var i in this.chart_data.data) {
        datacollection.labels = this.chart_data.data[i].labels;
        ////////////console.log(this.chart_data.data[i]);
        datasets[i] = {
          label: this.chart_data.data[i].label,
          yAxisID: "value",
          fill: false,
          //barThickness: 20,
          borderColor: this.chart_data.data[i].borderColor,
          backgroundColor: this.chart_data.data[i].borderColor,
          pointBackgroundColor: this.chart_data.data[i].borderColor,
          borderWidth: 1,
          pointBorderColor: this.chart_data.data[i].borderColor,
          data: this.chart_data.data[i].data,
          order: 3
        };
      }

      datasets[datasets.length] = {
        yAxisID: "value",
        label: "Award Value (all participants)",
        data: this.chart_data.annual_spend,
        type: "line",
        fill: false,
        lineTension: 0,
        borderWidth: 2,
        borderColor: "rgb(255, 231, 10)",
        backgroundColor: "rgb(255, 231, 10)",
        //pointStyle: 'line',
        pointRadius: 0,
        pointBackgroundColor: "rgb(255, 231, 10)",
        order: 1
      };

      datasets[datasets.length] = {
        yAxisID: "count", 
        label: "# Transactions (all participants)",
        data: this.chart_data.activity,
        type: "line",
        fill: false,
        lineTension: 0,
        borderWidth: 2,
        borderColor: "rgba(0, 0, 0, 0.5)",//"rgba(255, 20, 99, 0.5)",
        borderDash: [4],
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        //pointStyle: 'cross',
        pointRadius: 0,
        pointBackgroundColor: "rgba(0, 0, 0, 0.5)",
        order: 2
      };

      datasets[datasets.length] = {
        yAxisID: "count", 
        label: "# Transactions (top 10)",
        data: this.chart_data.topXactivity,
        type: "line",
        fill: false,
        lineTension: 0,
        borderWidth: 2,
        borderColor: "rgb(255,127,80)",//"rgba(255, 20, 99, 0.5)",
        borderDash: [4],
        backgroundColor: "rgb(255,127,80)",
        //pointStyle: 'cross',
        pointRadius: 0,
        pointBackgroundColor: "rgb(255,127,80)",
        order: 2
      };

      ////////////console.log("DATASETS: ", datasets);

      datacollection.datasets = datasets;

      
      this.options.tooltips = {
        intersect: false,
        mode: 'nearest',
        position:'nearest',
        displayColors: true,
        backgroundColor: 'rgb(255,255,255)', 
        borderColor: '#CDCDCD',
        padding: 20,
        xPadding: 10,
        yPadding: 10,
        caretSize: 0,
        borderWidth: 1,
        cornerRadius: 2,
        bodySpacing: 10,
        bodyFontFamily: 'verdana',
        multiKeyBackground: 'rgb(255,255,255)',
        callbacks: {
          label: (tooltipItem, data) => {
            var label = data.datasets[tooltipItem.datasetIndex].label;
            label = label + " in " + tooltipItem.xLabel;

            if (tooltipItem.datasetIndex === datasets.length - 1 || tooltipItem.datasetIndex === datasets.length - 2) {
              var value = tooltipItem.yLabel
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return `${label}: ${value}`;
            }
            else{
              // Format the y-axis value.
              var value =
                "$" +
                tooltipItem.yLabel
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }

            return `${label}: ${value}`;

            //return value;
          },

          title: function() {
            return null;
          },

          labelTextColor: function(context) {
            return "grey";
          }
        }

      };
    
      if (this.chart_labels) {
        datacollection.labels = this.chart_labels;
      }

      this.$refs.canvas.parentNode.style.height = "305px";

      this.renderChart(datacollection, this.options);

      //this.$emit("htmlLegend", this.$data._chart.generateLegend());
    }
  }
};
</script>

<style scoped>
.chart-legend {
  list-style-type: none;
  font-size: 9pt;
  font-family: Roboto;
  /*width: fit-content;
  margin: 0.5rem auto;*/
  margin-left: 0px;
  padding-left: 0px;
}
.chart-legend li {
  padding: 0.25rem;
}
.chart-legend .legend-item {
  height: 11;
  display: flex;
}
.chart-legend .legend-item .label {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 0px;
  padding-left: 0px;
}
.chart-legend .legend-item span {
  cursor: pointer;
  margin-right: 0.75rem;
  border-radius: 1.25rem;
  width: 1.25rem;
  height: 1.25rem;
}
</style>
