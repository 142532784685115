<template>
  <container fluid class="fill-height pt-0 mt-0">
        <v-card tile class="mb-1 mt-0 pt-0 pb-0 mr-0">
          <v-row dense>
            <v-col cols="9" md="9" class="pl-3">
              <v-card-text
                v-if="overlay"
                class="ml-0 pl-0 py-0"
                style="color:#12253a;"
              >
                <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
              </v-card-text>
              <v-card-text
                v-else-if="search_name && search_name != 'unnamed search'"
                class="font-weight-normal subtitle-1"
                style="color:#12253a;"
              >
                <span>
                <div class="font-weight-normal caption" style="color:#12253a;">
                  <span>Search Profile</span>
                   <span class="pt-0 mt-0 pl-1">
                  <v-icon small color="secondary" @click="qry_dialog=!qry_dialog">mdi-help-circle</v-icon>
                  </span>
                </div>
                
                <span class="font-weight-medium title" style="color:#12253a;">
                  {{ search_name }}
                </span>
               
                </span>
              </v-card-text>
              <v-card-text
                v-else-if="list_name"
                class="font-weight-normal subtitle-1"
                style="color:#12253a;"
              >
                <span>
                <div class="font-weight-normal caption" style="color:#12253a;">
                  <span>Prospect List</span>
                   <span class="pt-0 mt-0 pl-1">
                  <v-icon small color="secondary" @click="qry_dialog=!qry_dialog">mdi-help-circle</v-icon>
                  </span>
                </div>
                
                <span class="font-weight-medium title" style="color:#12253a;">
                  {{ list_name }}
                </span>
               
                </span>
              </v-card-text>
              <v-card-text
                v-else-if="search_query"
                class="font-weight-normal subtitle-1"
                style="color:#12253a;"
              >
                <span>Found</span>
                <span class="font-weight-normal" style="color:rgb(255, 20, 99);">
                  {{ total_result }}
                </span>
                notice<span v-if="total_result > 1">s spanning </span
                ><span class="font-weight-normal" style="color:rgb(255, 20, 99);">{{num_publishers}}</span> buyer<span v-if="num_publishers > 1">s</span>
                <span v-if="num_suppliers"> and <span class="font-weight-normal" style="color:rgb(255, 20, 99);">
                  {{num_suppliers}}</span> supplier<span v-if="num_suppliers > 1">s</span>
                  </span>
                <span v-if="total_amount > 0">
                  with a total awarded value of
                  <span class="font-weight-normal" style="color:rgb(255, 20, 99);">
                    {{ total_value }}</span
                  >
                </span>
                where
                <span>{{ search_query }}</span>
                <!-- <span v-if="smart_tags()">
                  <span class="font-weight-normal" style="color:rgb(255, 20, 99);">
                    (SMART TAGS ARE ON)</span
                  >
                </span> -->
              </v-card-text>
              <v-card-text v-else class="font-weight-light"
                ><span>{{ search_bar_text }}</span>
              </v-card-text>
            </v-col>
            <v-col cols="3" md="3" class="pr-5">
              <v-card-text class="text-right pr-0" style="float: right;">
                <span>
                  <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                    <template v-slot:activator="{ on }">
                      <span v-if="is_saved()">
                        <span>
                          <v-btn
                            
                            small
                            color="#12253a"
                            class="mr-2"
                            outlined
                            @click="toggle()"
                            light
                            v-on="on"
                          >
                          Unsave Search
                            <v-icon right>mdi-playlist-remove</v-icon>
                          </v-btn>
                        </span>
                        <span>
                          <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                :color="emailStatus()"
                                class="mr-3"
                                
                                @click="sendEmail()"
                                light
                                v-on="on"
                              >
                                <v-icon>{{alertIcon()}}</v-icon>
                              </v-btn>
                            </template>
                            <span>{{alert_message}}</span>
                          </v-tooltip>
                        </span>
                      </span>
                      <span v-else>
                      <v-btn
                        
                        small
                        color="#12253a"
                        class="mr-4"
                        outlined
                        @click="profileSave = true"
                        light
                        v-on="on"
                      >
                        Save Search
                        <v-icon right>mdi-playlist-plus</v-icon>
                      </v-btn>
                      </span>
                    </template>
                    <span>Save or remove this search profile</span>
                  </v-tooltip>
                </span>
              </v-card-text>
            </v-col>
          </v-row>
            </v-card>
          
    <div class="pb-5 mt-2" style="background-color:#f5f5f5">
      <span v-if="current_view === 'table'">
        <v-card tile class="mb-1 mt-0 pt-0 pb-0 mr-0">
          <v-skeleton-loader
            v-if="overlay"
            type="table-tbody"
          ></v-skeleton-loader>
          <results-table
            v-else
            :hit_info="hit_info"
            :type_filter="true"
            :page_num="page_num"
            :tenders="tenders"
            :results_start="results_start"
            :json_query="json_query"
            @updateSort="updateSort"
            @typeFilter="typeFilter"
            @change-page="changePage"
          />
        </v-card>
      </span>
      <span v-else-if="current_view === 'network'">
        <network-view />
      </span>
      <span v-else-if="current_view === 'leaderboard'">
        <results-leaderboard
        />
      </span>
      <span v-else-if="current_view === 'report'">
        <report />
      </span>
      <!-- <span v-else-if="current_view === 'overview'">
        <results-dashboard />
      </span> -->
    </div>
    <v-dialog v-model="profileSave" max-width="500" absolute>
      <v-card>
        <v-card-title>Save Search Profile</v-card-title>
        <v-card-subtitle class="pt-2">
          Saving this search profile will ensure that you are notified of any new contracts or tenders that match your search filters.
          Your list of saved profiles will be available on under the <v-icon small>mdi-view-headline</v-icon> menu in the toolbar.
        </v-card-subtitle>
        <v-card-text>
          <v-text-field
            label="Enter a name for this search profile"
            v-model="profileName"
            single-line
            :rules="[profileNameRules]"  
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="profileSave = false">Cancel</v-btn>

          <v-btn
            color="secondary"
            text
            :disabled="!isProfileNameValid"  
            @click="(profileSave = false), toggle()" 
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="qry_dialog" hide-overlay width="700" absolute>
        <v-card tile>
        <v-card-title>What query are these results based on?
            <div class="flex-grow-1"></div> <v-btn icon @click="qry_dialog=!qry_dialog"><v-icon style="font-weight:thin;" medium>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text
            class="font-weight-normal subtitle-1"
            style="color:#12253a;"
          >
            <span>Found</span>
            <span class="font-weight-normal" style="color:rgb(255, 20, 99);">
              {{ total_result }}
            </span>
            notice<span v-if="total_result > 1">s spanning </span
            ><span class="font-weight-normal" style="color:rgb(255, 20, 99);">{{num_publishers}}</span> buyer<span v-if="num_publishers > 1">s</span>
            <span v-if="num_suppliers"> and <span class="font-weight-normal" style="color:rgb(255, 20, 99);">
              {{num_suppliers}}</span> supplier<span v-if="num_suppliers > 1">s</span>
              </span>
            <span v-if="total_amount > 0">
              with a total awarded value of
              <span class="font-weight-normal" style="color:rgb(255, 20, 99);">
                {{ total_value }}</span
              >
            </span>
            where
            <span>{{ search_query }}</span>

            <span v-if="smart_tags()">
                  <span class="font-weight-normal" style="color:rgb(255, 20, 99);">
                    (SMART TAGS ARE ON)</span
                  >
                </span>
          </v-card-text>
        </v-card>
    </v-dialog>
  </container>
</template>

<script>
import ResultsTable from "../components/ResultsTable.vue";
import {
  histogramSearch,
  graphSearch,
  executeSearch,
  entitySearch,
  groupBySearch
} from "../components/APIManager.js";
import ResultsLeaderboard from "../components/ResultsLeaderboard.vue";
//import ResultsDashboard from "../components/ResultsDashboard.vue";
import Report from "../components/Report.vue";
import NetworkView from "../components/NetworkView.vue";
import { getInstance } from "../auth/index.js";

import { bus } from "../main.js";
import axios from "axios";

export default {
  components: {
    NetworkView,
    ResultsTable,
    ResultsLeaderboard,
    Report,
    //ResultsDashboard
  },
  props: {
    current_view: String,
    search_terms: {},
    search_request: Boolean,
    global_search: Boolean,
    search_changed: Number,
    search_history: []
  },
  data() {
    return {
      list_name: null,
      email_alerts: false,
      qry_dialog: false,
      profileName: '',
      profileNameRules: [
        v => v.length >= 5 || 'Profile name must be at least 5 characters'
      ],
      profileSave: false,
      overlay: false,
      hit_info: {},
      tenders: [""],
      aggregates: [""],
      graph: String,
      publisher_aggs: [""],
      publisher_id_aggs: [],
      supplier_aggs: [""],
      category_aggs: [""],
      cumulative_value: [""],
      cumulative_activity: [""],
      contracting_activity: [""],
      expiring_activity: [""],
      activity_trend: [""],
      govt_aggs: [""],
      date_aggs: [],
      expiry_aggs: [],
      num_contracts: Number,
      published_aggs: [],
      loading: true,
      errored: false,
      results_drawer: "",
      selected_record: "",
      selected_grouping: "",
      saved: false,
      search: "",
      search_bar_text: "No filters set",
      results_start: 0,
      results_size: 50,
      search_query: "",
      search_name:"",
      json_query: {},
      search_saved: false,
      std_search: process.env.VUE_APP_API_STANDARD_SEARCH,
      adv_search: process.env.VUE_APP_API_ADV_SEARCH,
      current_search: { notify: null },
      //default_search: {"contract_end_from":"2020-01-01","contract_end_to": "2020-12-31"},
      default_search: {
        expiry_from: 2020,
        expiry_to: 2021
      },
      saved_searches: [],
      supplier_by_pub_aggs: [],
      scroll_id: "",
      page_num: 1,
      publisher_activity: {},
      expiry_activity: {},
      expiry_aggregate: {},
      quarters: {},
      num_publishers: 0,
      num_suppliers: 0,
      num_categories: 0,
      agg_size: 10,
      curr_agg_size: null,
      total_amount: Number,
      histogramLoading: false,
      contracting_spend: [],
      contracted_per_month: [],
      grouped_results: [],
      grouping: "category",
      filters: false,
      suppliers_by_gov: [],
      publishers_by_gov: [],
      categories_by_gov: [],
      sort: {sort_field: "expiry_date", sort_order: "desc"},
      disposition: ["Contract Notice", "Request", "Grant", "Grant Opportunity"],

    }
  },
  methods: {

    changePage(number){

      //////////////console.log("CLICKED PAGE: " + number);
      this.page_num = number;
      this.loadPage();

    },

    showNav() {
      this.filters = !this.filters;
      bus.$emit("hideFilters", this.filters);
    },

    isEmpty(obj) {
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
      }
      return true;
    },

    async toggle() {
      this.search_saved = await this.save_search();
      this.is_saved();
      this.profileName='';
    },

    smart_tags(){

      var query = this.urlAsObject();
      if (query["supplier_smart_tags"]){
        return true;
      }
      else return false;

    },
    

    getLastSearch() {
      bus.$emit("get-last-search", true);
    },

    is_saved() {
      //////////////console.log("NOTIFY STATUS: " + this.current_search.notify)
      if (this.current_search && this.current_search.notify === true) {
        return true;
      }
    },

    formattedCurrency: function(amount) {
      return "$" + amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    getStatusColour(item) {
      if (item.current) {
        return "#b3bc4b";
      } else {
        return "#E0E0E0";
      }
    },

    emailStatus(){
      if (this.email_alerts) {
        return "primary";
      } else {
        return "#E0E0E0";
      }
    },
    alertIcon(){
      if (this.email_alerts) {
        return "mdi-bell";
      } else {
        return "mdi-bell-off";
      }
    },

    async save_search() {
      const instance = getInstance();
      const accessToken = await instance.getTokenSilently();
      var query = this.urlAsObject();
      
      var params = JSON.stringify(query);

      params = {query: JSON.parse(params)};

      params.query["search_id"] = this.current_search.id;
      params.query["search_name"] = this.profileName;
      
      params.query["email_alerts"] = true;

      var endpoint = process.env.VUE_APP_API_SEARCH_NOTIFY; 
      var headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/x-www-form-urlencoded"
      };
      axios
        .post(endpoint, null, { headers, params })
        .then(response => {
          this.current_search = response.data.current_search;
          this.search_name = response.data.current_search.name
          this.current_search = response.data.current_search;
          this.email_alerts = response.data.current_search.email_alerts;

          return true;
        })
        .catch(error => {
          //////////////console.log(error);
          this.errored = true;
          return false;
        });
    },

    async sendEmail() {
      const instance = getInstance();
      const accessToken = await instance.getTokenSilently();

      var query = this.urlAsObject();
      
      var params = JSON.stringify(query);

      params = {query: JSON.parse(params)};
      params.query["search_id"] = this.current_search.id;
      params.query["search_name"] = this.profileName;
      
      params.query["email_alerts"] = !this.email_alerts;

      var endpoint = process.env.VUE_APP_API_SEARCH_NOTIFY; 

      var headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/x-www-form-urlencoded"
      };
      axios
        .put(endpoint, null, { headers, params })
        .then(response => {
          //this.saved_searches = response.data.data;
          this.current_search = response.data.current_search;
          this.email_alerts = response.data.current_search.email_alerts;
          return true;
        })
        .catch(error => {
          //////////////console.log(error);
          this.errored = true;
          return false;
        });
    },

    urlAsObject(){
      var querystring = window.location.search.slice(1);
      // parse query string
      const params = new URLSearchParams(querystring);

      //////////////console.log(params.toString());

      const obj = {};

      // iterate over all keys
      for (const key of params.keys()) {
          if (params.getAll(key).length > 1) {
              obj[key] = params.getAll(key);
          } else {
              obj[key] = params.get(key);
          }
      }

      return obj;
    },

    async loadPage() {

      await Promise.all(
        [this.getData()]
      );

      var query = this.urlAsObject();
      this.list_name = query["list_name"];
    },

    updateSort(sortQuery){

      //////////////console.log("ARGS: ", sortQuery);
      this.page_num = 1;
      this.overlay = true;

      this.sort.sort_field = sortQuery.sort_field.value;
      this.sort.sort_order = sortQuery.sort_order.value;
      
      //this.args.push(sortQuery)
      //////////////console.log("ARGS: ", params);
      this.getData();
    },

    typeFilter(types){

      //////////////console.log("ARGS: ", sortQuery);
      this.page_num = 1;
      this.overlay = true;

      this.disposition = types.disposition;

      this.getData();
    },

    removeParam(parameter) {
        let url = new URL(window.location.href);
        url.searchParams.delete(parameter);

        window.history.pushState({}, '', url);
    },

    async getData() {
      var query = {};

      if (this.isEmpty(this.search_terms)) {
        query = this.default_search;
        query["agg_size"] = this.agg_size;
        query["sort_field"] = this.sort.sort_field;
        query["sort_order"] = this.sort.sort_order;
        query["disposition"] = this.disposition;
        bus.$emit("update_search_terms", query);
        bus.$emit("load-filters", query);
      } else {
        query = this.search_terms;
        if (query["newSearch"]){
          this.page_num = 1;
          delete query.newSearch;
          this.removeParam("newSearch");
        }
        if (!query.size){
          query.size = this.results_size;
        }
        
        query["agg_size"] = this.agg_size;
        query["sort_field"] = this.sort.sort_field;
        query["sort_order"] = this.sort.sort_order;
        //query["disposition"] = this.disposition;
      }

      //query["agg_type"] = group_by;
      this.overlay = true;

      executeSearch(query, this.page_num)
        .then(data => {
          this.hit_info = data.hits;
          //this.scroll_id = response.data.hits.sscroll_id;
          this.tenders = data.data;
          this.total_amount = data.aggregations.total_amount.value;
          this.num_publishers = data.aggregations.publisher_count.value;
          this.num_suppliers = data.aggregations.supplier_count.value;
          this.search_query = data.plain_text_query;
          this.search_name = data.current_search.name
          this.json_query = data.json_query;
          this.curr_agg_size = data.json_query.agg_size;
          this.current_search = data.current_search;
          this.email_alerts = data.current_search.email_alerts;

          ////////////console.log("SEARCH QUERY: ", this.search_query);

          this.overlay = false;
        })
        .catch(error => {
          //////////////console.log(error);
          this.errored = true;
          this.overlay = false;
        });
    }
  },

  watch: {
    search_changed: {
      handler() {
        //////////////console.log("IN TENDER RESULTS: ");
        this.loadPage();
        //this.getData("");
        var search_complete = false;
        bus.$emit("search-complete", search_complete);
        //this.getData("expiry");
      },
      deep: true
    }
  },
  mounted() {
    this.loadPage();
    //////////////console.log("this.publishers_by_gov ---", this.publishers_by_gov[0]);
  },

  created() {
    bus.$on("show-hide-details", item_details => {
      //////////////console.log(`Oh, that's nice. It's gotten ${item_details} clicks! :)`);
      this.results_drawer = item_details.show;
      this.selected_record = item_details.record;
    });

    bus.$on("hideProfile", show => {
      //////////////console.log(`Oh, that's nice. It's gotten ${item_details} clicks! :)`);
      this.results_drawer = show;
    });

    bus.$on("fetch-page", resultset => {
      //////////////console.log(`Oh, that's nice. It's gotten ${item_details} clicks! :)`);
      //this.results_start = resultset.start;
      this.results_size = resultset.size;
      //////////////console.log("FETCH PAGE DATA .. "+ JSON.stringify(this.last_search));
      this.getData();
    });

  },

  computed: {

    isProfileNameValid() {
      return this.profileName.length >= 5; 
    },

    alert_message(){
      if (this.email_alerts){
        return "Stop alerting me about new matches"
      }
      else{
        return "Alert me about new matches"
      }
    },

    total_contracts() {
      var contracts = 0;
      for (var i = 0; i < this.date_aggs.length; i++) {
        contracts += this.date_aggs[i].doc_count;
      }
      return contracts;
    },

    searchHistoryIsEmpty() {
      if (
        this.search_history.length == 0 ||
        (this.search_history.length == 1 &&
          this.isEmpty(this.search_history[0]))
      ) {
        return true;
      } else {
        return false;
      }
    },

    total_result() {
      return this.hit_info.value;
    },

    total_value() {
      if (!isNaN(this.total_amount)) {
        return (
          "$" +
          Math.round(this.total_amount)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        );
      } else {
        return this.total_amount;
      }
    },

    total_suppliers() {
      return this.supplier_aggs.length;
    },

    total_entities() {
      return this.publisher_aggs.length;
    }
  }
};
</script>

<style scoped>
::v-deep .v-input__control {
  max-height: 25px !important;
}

table {
  border-collapse: collapse;
}

th {
  border-bottom: 1px solid #535353;
}

/* .tr:hover {
  transition: opacity 0.4s ease-in-out;
} */

.row-hover tr:hover {
  background-color: #535353;

  -webkit-transition: background-color 0.4s; /* For Safari 3.1 to 6.0 */
  transition: background-color 0.4s;
}
</style>
