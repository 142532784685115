<template>
  <v-card class="pt-0" tile flat id="spendingdonutmini">
    <v-card-text class="pt-0 pb-0 font-weight-normal" >{{title}}</v-card-text>
        <v-divider class="mx-3"></v-divider>
        <chart-loader class="mt-9 pt-9" v-if="reportLoading" :reportLoading="reportLoading" />
        <pie-echarts-mini class="pt-6" v-else-if="chart_data && chart_data.labels"
          :chart_data="chart_data"
          :metric_name="metric.value"
          :target_entity="target_entity"
          :changed="changed"
          :entity_type="entity_type"
          :show_labels="show_labels"
          :download="download"
          :chart_size="chart_size"
          :target_group="group.value"
          :id="id"
        />
  </v-card>
</template>

<script>
import { prepEntityBreakdown } from "./PrepDonut.js";
import { entitySearch } from "./APIManager.js";
import { silentURLUpdate, formattedCurrencyFull, formattedCurrency, govColor, getPercent } from "./Utils.js";
import ChartHeader from "../components/ChartHeader";
import ChartLoader from "../components/ChartLoader";
import PieEchartsMini from "../components/PieEChartsMini";
//import Donut from "@/components/Donut.vue";

export default {
  components: {
    PieEchartsMini,
    //ChartHeader,
    ChartLoader
  },
  props: {
    title: {
      type: String,
      default: "Market Share"
    },
    description: String,
    query: Object,
    chart_size: {
      type: String(),
      default: "small"
    },
    group: Object()
  },

  data() {
    return {
      chart_settings: [],
      subtitle: "Market share by entity",
      show_labels: false,
      entity_type: "government",//String(),
      metric_name: "total_amount",//String(),
      category_aggs: [],
      major_category_aggs: [],
      keyword_aggs: [],
      publisher_aggs: [],
      supplier_aggs: [],
      government_aggs:[],
      keyword_aggs_count:[],
      category_aggs_count: [],
      major_category_aggs_count: [],
      publisher_aggs_count: [],
      supplier_aggs_count: [],
      government_aggs_count:[],
      entity_aggs: [],
      metric_options: [{text: "Amount", value:"total_amount"}, {text: "Volume", value:"doc_count"}],
      metric: {text: "Amount", value:"total_amount"},
      group_options: [{text: "Government", value:"government"}, 
                      {text: "Buyer", value:"publisher"},
                      {text: "Major Category", value:"major_category"},
                      {text: "Category", value:"category"},
                      {text: "Supplier", value:"supplier"},
                      {text: "Sector", value:"publisher_industry"}],
      //group: {text: "Major Category", value:"major_category"},
      num_groups: 10,
      top_entities: [10, 15],
      total_records: Number,
      total_amount: Number,
      reportLoading: Boolean,
      plain_text_query:String,
      legendData: [],
      item_details: false,
      aggregated_data: [],
      //publisher_line_chart_data: [],
      chart_data:  {},
      //chart_axes: { x: "Est Total", y: "Categories" },
      //chart_axes: { x: "Transactions", y: "Year" },
      group_by_amount: true,
      target_entity: String,
      changed: Number,
      download: Number,
      dataview: {headers:[], data:[]},
      embed: Number,
      embed_object: Object,
      embed_obj_changed: Number,
      pub_industry_aggs: [],
      pub_industry_aggs_count: [],
    };
  },

  watch: {
    $route(to, from) {
      this.refresh();
    },

    query: {
      handler(newValue, oldValue) {
        this.reportLoading = true;
        this.loadData(this.query);
      },
      deep: true
    },

  },

  created(){
    
  },

  async mounted() {
    this.reportLoading = true;
    this.loadSettings();

    if (this.query){
        this.loadData(this.query);
      }
      else{
        this.loadData(this.$route.query);
      }
    //this.loadData(this.$route.query);
  },

  computed:{
    id(){
      return (Math.random() + Date.now()) * Math.random();
    },
  },

  methods: {

    chart_object(obj){
      this.embed_object["title"] = this.title;
      this.embed_object["description"] = this.description;
      this.embed_object["chart_type"] = "pie";
      this.embed_object["payload"] = obj
      this.embed_object["id"] = (Math.random() + Date.now()) * Math.random();
      this.embed_obj_changed = (Math.random() + Date.now()) * Math.random();
    },

    embed_code(status){
      this.embed =
          (Math.random() + Date.now()) * Math.random();

    },

    updateSettings(){
      this.chart_settings[0] = {field_name: "pie_group_value", value: this.group.value};
      this.chart_settings[1] = {field_name: "pie_metric_value", value: this.metric.value};

      silentURLUpdate(this.chart_settings);

    },

    loadSettings(){
      var query = this.$route.query;
      if (query.hasOwnProperty("pie_group_value") && query.comparison_group_value == "search_terms"){
        delete this.group.text;
        delete this.group.value;
        this.group = {text: "Keyword", value:"search_terms"};

      }
      else if (query.hasOwnProperty("pie_group_value")){
        let index = this.group_options.findIndex(x => x.value === query.pie_group_value);
        delete this.group.text;
        delete this.group.value;
        this.group = this.group_options[index];
      }
      if (query.hasOwnProperty("pie_metric_value")){
        let index = this.metric_options.findIndex(x => x.value === query.pie_metric_value);
        delete this.metric.text;
        delete this.metric.value;
        this.metric = this.metric_options[index];
      }
    },

    showTenders(args){
      this.$emit("showTenders", args);
    },
    
    async refresh(){
      this.reportLoading = true;
      await this.loadData(this.$route.query);

      var query = this.$route.query;

      if (query.hasOwnProperty("pie_group_value") && query.pie_group_value == "search_terms"){
        let index = this.group_options.findIndex(x => x.value === "search_terms");
        if (index > -1){
          this.group_options.splice(index, 1);

        }
      }
    },

    set_entity_aggs(){
      this.entity_aggs = [];

      if (this.group.value == "major_category"){
        if (this.metric.value == "doc_count")
          this.entity_aggs = this.major_category_aggs_count;
        else{
          this.entity_aggs = this.major_category_aggs;
          /* for (var i in this.category_aggs){
            this.entity_aggs[i] = this.category_aggs[i].category.buckets[0];
          } */
        }
          
      }
      else if (this.group.value == "category"){
        if (this.metric.value == "doc_count")
          this.entity_aggs = this.category_aggs_count;
        else{
          this.entity_aggs = this.category_aggs;
          /* for (var i in this.category_aggs){
            this.entity_aggs[i] = this.category_aggs[i].category.buckets[0];
          } */
        }
          
      }else if (this.group.value == "publisher"){
        if (this.metric.value == "doc_count")
          this.entity_aggs = this.publisher_aggs_count;
        else{
          this.entity_aggs = this.publisher_aggs;
          /* for (var i in this.publisher_aggs){
            this.entity_aggs[i] = this.publisher_aggs[i].publisher.buckets[0];
          } */
          ////////////console.log("AGGS: ", this.entity_aggs);
        }
      }
      else if (this.group.value == "publisher_industry"){
        if (this.metric.value == "doc_count")
          this.entity_aggs = this.pub_industry_aggs_count;
        else{
          this.entity_aggs = this.pub_industry_aggs;
          /* for (var i in this.publisher_aggs){
            this.entity_aggs[i] = this.publisher_aggs[i].publisher.buckets[0];
          } */
          ////////////console.log("AGGS: ", this.entity_aggs);
        }
      }
      else if (this.group.value == "government"){
        if (this.metric.value == "doc_count")
          this.entity_aggs = this.government_aggs_count;
        else
          this.entity_aggs = this.government_aggs;
      }else if (this.group.value == "search_terms"){
          this.entity_aggs = this.keyword_aggs;
      }
      else {
        if (this.metric.value == "doc_count")
          this.entity_aggs = this.supplier_aggs_count;
        else{
          this.entity_aggs = this.supplier_aggs;
          /* for (var i in this.supplier_aggs){
            this.entity_aggs[i] = this.supplier_aggs[i].supplier.buckets[0];
          } */
        }
      }
    },

    setChanged(){
      this.changed =
          (Math.random() + Date.now()) * Math.random();
    },

    downloadImage(status){
      this.download =
          (Math.random() + Date.now()) * Math.random();

    },

    loadDataView(data){
      this.dataview = data;
    },

    async loadData(qry) {

      var query = JSON.parse(JSON.stringify(qry));
      query["agg_size"] = 10;//this.num_groups;

      entitySearch(query, 1)
        .then(data => {
          this.supplier_aggs = data.aggregations.supplier.buckets;
          ////////////console.log("SUPPLIEzrS ", this.supplier_aggs);
          this.category_aggs = data.aggregations.category.buckets;
          this.pub_industry_aggs = data.aggregations.suppliers_by_publisher_industry.buckets;
          this.major_category_aggs = data.aggregations.major_category.buckets;
          this.publisher_aggs = data.aggregations.publisher.buckets;
          this.government_aggs = data.aggregations.government.buckets;

          if (data.aggregations.search_terms){
            this.keyword_aggs = data.aggregations.search_terms.buckets;
            this.group_options.push({text: "Keyword", value:"search_terms"})
          }
          
          this.total_amount = data.aggregations.total_amount.value;
          this.plain_text_query = data.plain_text_query;
          this.total_records = data.hits.value;

          //count
          this.supplier_aggs_count = data.aggregations.supplier_by_count.buckets;
          this.pub_industry_aggs_aggs_count = data.aggregations.publisher_industry_by_count.buckets;
          this.category_aggs_count = data.aggregations.category_by_count.buckets;
          this.major_category_aggs_count = data.aggregations.major_category_by_count.buckets;
          this.publisher_aggs_count = data.aggregations.publisher_by_count.buckets;
          this.government_aggs_count = data.aggregations.government_by_count.buckets;

          this.set_entity_aggs();
          this.chartConfig();

          
        })
        .catch(error => {
          ////////////console.log(error);
          //this.errored = true;
          //this.overlay = false;
        });
    },

    chartConfig() {
      //this.chart_data = new Object();

      this.chart_data = prepEntityBreakdown(
        this.entity_aggs,
        this.metric.value,
        "",
        this.group.value
      );

      this.reportLoading = false;
      this.setChanged();

    }
  }
};
</script>

<style>
/* .pie-chart-container {
  max-height: 360px !important;
  max-width: 600px !important;
} */
</style>
