<template>
    <v-card tile>
        <v-card-title style="color:#12253a;" class="font-weight-medium subtitle-1 pb-1">
        
        <span
            class="d-inline-block text-truncate"
            style="max-width:800px; min-width:150px;"
        >
            <v-card-subtitle class="pa-0 font-weight-normal">Buyer</v-card-subtitle>
            <span>
                <router-link
                    :to="{
                            name: 'entity',
                            params: { id: entity.id }
                        }"
                >
                    {{ entity.name }}
                </router-link>
            </span>
            <br />
            <span v-for="e in industry" :key="e">
                <v-chip v-if="entity['industry']"
                    class="my-0 ml-0 mr-2"
                      color="secondary"
                      label
                      text-color="secondary"
                      small
                      outlined
              >
                <v-icon small left>
                  mdi-label
                </v-icon>
                {{e}}
              </v-chip>
            </span>
            <span v-for="clstr in entity.clusters" :key="clstr.id">
                <router-link
                            :to="{ name: 'cluster', params: { clusterId: clstr.id } }"
                          >
                    <v-chip v-if="clstr"
                      class="my-0 ml-0 mr-2"
                      color="primary"
                      label
                      text-color="primary"
                      small
                      outlined
                    >
                    <v-icon small left>
                      mdi-hexagon-multiple
                    </v-icon>
                    {{clstr.name}}
                    </v-chip>
                </router-link>
                  </span>
        </span>
        <div class="flex-grow-1 desktop-nav"></div> 
            <span>
                <v-avatar
                :color="govAvatarColor(entity.gov_type)"
                size="36"
                class="mr-3 pl-0 ml-0"
                >
                <span class="white--text caption">{{ entity.gov_type }}</span>
                </v-avatar>
            </span>
            <span>
                <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                <template v-slot:activator="{ on }">
                    <v-btn
                    v-on="on"
                    large
                    icon
                    color="#4EB888"
                    light
                    style="text-decoration: none !important;"
                    :to="{
                            name: 'entity',
                            params: { id: entity.id }
                        }"
                    >
                    <v-icon>mdi-open-in-new</v-icon>
                    </v-btn>
                </template>
                <span>Open entity profile</span>
                </v-tooltip>
            </span>
            <span>
                <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                <template v-slot:activator="{ on }">
                    <v-btn
                    v-on="on"
                    large
                    icon
                    color="#4EB888"
                    light
                    style="text-decoration: none !important;"
                    :to="{ name: 'search', query: { publisher_id: entity.id } }"
                    >
                    <v-icon>mdi-format-list-bulleted</v-icon>
                    </v-btn>
                </template>
                <span>View all {{ entity.name }} notices</span>
                </v-tooltip>
            </span>
            
            <!-- <span>
                <span>
                <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                <template v-slot:activator="{ on }">
                    <v-btn
                    v-on="on"
                    large
                    icon
                    color="secondary"
                    light
                    style="text-decoration: none !important;"
                    :to="{ name: 'search', query: { publisher_id: entity.id,  viewIndex: 2} }"
                    >
                    <v-icon>mdi-graphql</v-icon>
                    </v-btn>
                </template>
                <span>View this buyer's network</span>
                </v-tooltip>
            </span>
                <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                <template v-slot:activator="{ on }">
                    <v-btn
                    v-on="on"
                    large
                    icon
                    color="#4EB888"
                    light
                    :href="entity.website"
                    target="_blank"
                    style="text-decoration: none !important;"
                    :disabled="disableIcon(entity.website)"
                    >
                    <v-icon>mdi-web</v-icon>
                    </v-btn>
                </template>
                <span>Go to Website</span>
                </v-tooltip>
            </span> -->
        </v-card-title>
        <v-row v-if="loading_stats == true" class="ma-0 pa-0">
        <v-col cols="12" class="ma-0 pa-0">
            <v-card tile style="color:rgb(78, 184, 136);">
            <v-skeleton-loader
                class="py-2"
                type="table-thead"
            ></v-skeleton-loader>
            </v-card>
        </v-col>
        </v-row>

        <v-row v-else-if="loading_stats == false" class="ma-0 pa-0">
        <v-divider></v-divider>
        <v-col cols="12" md="4" class="ma-0 pa-0">
            <v-card flat>
            <v-card-text class="pt-2 pb-0 font-weight-normal" >{{all_time_header}}</v-card-text>
            <v-divider class="mx-3"></v-divider>
            <v-row class="ma-0 pa-0">
                
                <v-col cols="4" class="ma-0 pa-0">
                <v-card tile flat class="py-0 my-0">
                    <v-card-title
                    class="font-weight-normal body-2"
                    style="color:#12253a;"
                    >Value</v-card-title
                    >
                    <v-card-subtitle>
                    <div class="font-weight-medium title" style="color:#12253a;">
                        {{ formattedCurrency(entity.award_total) }}
                    </div>
                    <div class="font-weight-normal body-2">
                        <router-link v-if="is_major_category"
                            :to="{ name: 'search', query: { publisher_id: entity.id, major_category_id: category_id } }"
                            >
                            {{ entity.num_notices
                            }}<span v-if="entity.num_notices == 10000">+</span>
                            notices
                        </router-link>
                        <router-link v-else
                            :to="{ name: 'search', query: { publisher_id: entity.id, category_type_id: category_id } }"
                            >
                            {{ entity.num_notices
                            }}<span v-if="entity.num_notices == 10000">+</span>
                            notices
                        </router-link>
                    </div>
                    </v-card-subtitle>
                </v-card>
                </v-col>
            
                <v-col cols="4"  class="ma-0 pa-0">
                <v-card tile flat class="py-0 my-0">
                    <v-card-title
                    class="font-weight-normal body-2"
                    style="color:#12253a;"
                    >Avg Per Year</v-card-title
                    >
                    <v-card-subtitle>
                    <div v-if="entity.award_total>0" class="font-weight-medium title" style="color:#12253a;">
                        {{ formattedCurrency(entity.award_total / entity.awards_by_year.length) }}
                    </div>
                    <div v-else class="font-weight-medium title" style="color:#12253a;">
                        $0
                    </div>
                    <div class="font-weight-normal body-2" style="color:#12253a;">
                        Over {{entity.awards_by_year.length}} years
                    </div>
                    </v-card-subtitle>
                </v-card>
                </v-col>

                <v-col cols="4"  class="ma-0 pa-0">
                <v-card tile flat class="py-0 my-0">
                    <v-card-title
                    class="font-weight-normal body-2"
                    style="color:#12253a;"
                    >Avg Contract</v-card-title
                    >
                    <v-card-subtitle>
                    <div class="font-weight-medium title" style="color:#12253a;">
                        {{ formattedCurrency(entity.award_total / entity.num_notices) }}
                    </div>
                    <div class="font-weight-normal body-2" style="color:#12253a;">
                        All contracts
                    </div>
                    </v-card-subtitle>
                </v-card>
                </v-col>
            </v-row>
            </v-card>
        </v-col>
        <v-col cols="12" md="4" class="ma-0 pa-0">
            <v-card flat>
            <v-card-text class="pt-2 pb-0 font-weight-normal">{{awards_last_year_header}}</v-card-text>
            <v-divider class="mx-3"></v-divider>
            <v-row class="ma-0 pa-0">
            <v-col cols="4"  class="ma-0 pa-0">
                <v-card tile flat class="py-0 my-0">
                <v-card-title
                    class="font-weight-normal body-2"
                    style="color:#12253a;"
                    >Value</v-card-title 
                >
                <v-card-subtitle>
                    <div class="font-weight-medium title" style="color:#12253a;">
                    {{ formattedCurrency(entity.growth.current_year_total) }}
                    </div>
                    <div class="font-weight-normal body-2" style="color:#12253a;">
                    <v-icon :color="growth_format(entity.growth.spend_change_percent).color">
                        {{growth_format(entity.growth.spend_change_percent).icontype}}
                    </v-icon>
                    {{ growth_format(entity.growth.spend_change_percent).change }}%
                    </div>
                </v-card-subtitle>
                </v-card>
            </v-col>
            <v-col cols="4"  class="ma-0 pa-0">
                <v-card tile flat class="py-0 my-0">
                <v-card-title
                    class="font-weight-normal body-2"
                    style="color:#12253a;"
                    >Avg Contract</v-card-title 
                >
                <v-card-subtitle>
                    <div class="font-weight-medium title" style="color:#12253a;">
                    {{ formattedCurrency(entity.growth.curr_yr_avg_contract) }}
                    </div>
                    <div class="font-weight-normal body-2" style="color:#12253a;">
                    <v-icon :color="growth_format(entity.growth.avg_change_percent).color">
                        {{growth_format(entity.growth.avg_change_percent).icontype}}
                    </v-icon>
                    {{ growth_format(entity.growth.avg_change_percent).change }}%
                    </div>
                </v-card-subtitle>
                </v-card>
            </v-col> 
            <v-col cols="4"  class="ma-0 pa-0">
                <v-card tile flat class="py-0 my-0">
                <v-card-title
                    class="font-weight-normal body-2"
                    style="color:#12253a;"
                    >Contracts</v-card-title 
                >
                <v-card-subtitle>
                    <div class="font-weight-medium title" style="color:#12253a;">
                    <router-link v-if="is_major_category"
                        :to="{
                            name: 'search',
                            query: {
                            publisher_id: entity.id,
                            major_category_id: category_id,
                            disposition: ['Contract Notice','Grant'],
                            contract_start_from: new Date().toISOString().split('T')[0],
                            contract_start_to: new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate()).toISOString().split('T')[0],
                            sort_order: 'asc'
                            }
                        }"
                        >
                        {{ numFormatter(entity.growth.current_year_vol) }}
                    </router-link>
                    <router-link v-else
                        :to="{
                            name: 'search',
                            query: {
                            publisher_id: entity.id,
                            category_id: category_id,
                            disposition: ['Contract Notice','Grant'],
                            contract_start_from: new Date().toISOString().split('T')[0],
                            contract_start_to: new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate()).toISOString().split('T')[0],
                            sort_order: 'asc'
                            }
                        }"
                        >
                        {{ numFormatter(entity.growth.current_year_vol) }}
                    </router-link>
                    </div>
                    <div class="font-weight-normal body-2" style="color:#12253a;">
                    
                    <v-icon :color="growth_format(entity.growth.vol_change_percent).color">
                        {{ growth_format(entity.growth.vol_change_percent).icontype }}
                    </v-icon>
                    {{ growth_format(entity.growth.vol_change_percent).change }}%
                    </div>
                </v-card-subtitle>
                </v-card>
            </v-col>
            </v-row>
            </v-card>
        </v-col>
        <v-col cols="12" md="4" class="ma-0 pa-0">
            <v-card flat>
            <v-card-text class="pt-2 pb-0 font-weight-normal">{{expiry_next_year_header}}</v-card-text>
            <v-divider class="mx-3"></v-divider>
            <v-row class="ma-0 pa-0">
            <v-col cols="4"  class="ma-0 pa-0">
                <v-card tile flat class="py-0 my-0">
                <v-card-title
                    class="font-weight-normal body-2"
                    style="color:#12253a;"
                    >Value</v-card-title 
                >
                <v-card-subtitle>
                    <div class="font-weight-medium title" style="color:#12253a;">
                    {{ formattedCurrency(entity.expiring_soon.next_year_total) }}
                    </div>
                    <div class="font-weight-normal body-2" style="color:#12253a;">
                    <v-icon :color="growth_format(entity.expiring_soon.spend_change_percent).color">
                        {{growth_format(entity.expiring_soon.spend_change_percent).icontype}}
                    </v-icon>
                    {{ growth_format(entity.expiring_soon.spend_change_percent).change }}%
                    </div>
                </v-card-subtitle>
                </v-card>
            </v-col>
            <v-col cols="4"  class="ma-0 pa-0">
                <v-card tile flat class="py-0 my-0">
                <v-card-title
                    class="font-weight-normal body-2"
                    style="color:#12253a;"
                    >Avg Contract</v-card-title 
                >
                <v-card-subtitle>
                    <div class="font-weight-medium title" style="color:#12253a;">
                    {{ formattedCurrency(entity.expiring_soon.next_yr_avg_contract) }}
                    </div>
                    <div class="font-weight-normal body-2" style="color:#12253a;">
                    <v-icon :color="growth_format(entity.expiring_soon.avg_change_percent).color">
                        {{growth_format(entity.expiring_soon.avg_change_percent).icontype}}
                    </v-icon>
                    {{ growth_format(entity.expiring_soon.avg_change_percent).change }}%
                    </div>
                </v-card-subtitle>
                </v-card>
            </v-col> 
            <v-col cols="4"  class="ma-0 pa-0">
                <v-card tile flat class="py-0 my-0">
                <v-card-title
                    class="font-weight-normal body-2"
                    style="color:#12253a;"
                    >Contracts</v-card-title 
                >
                <v-card-subtitle>
                    <div class="font-weight-medium title" style="color:#12253a;">
                    <router-link v-if="is_major_category"
                        :to="{
                            name: 'search',
                            query: {
                            publisher_id: entity.id,
                            major_category_id: category_id,
                            disposition: ['Contract Notice','Grant'],
                            contract_end_from: new Date().toISOString().split('T')[0],
                            contract_end_to: new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate()).toISOString().split('T')[0],
                            sort_order: 'asc'
                            }
                        }"
                        >
                        {{ numFormatter(entity.expiring_soon.next_year_vol) }}
                    </router-link>
                    <router-link v-else
                        :to="{
                            name: 'search',
                            query: {
                            publisher_id: entity.id,
                            category_id: category_id,
                            disposition: ['Contract Notice','Grant'],
                            contract_end_from: new Date().toISOString().split('T')[0],
                            contract_end_to: new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate()).toISOString().split('T')[0],
                            sort_order: 'asc'
                            }
                        }"
                        >
                        {{ numFormatter(entity.expiring_soon.next_year_vol) }}
                    </router-link>
                    </div>
                    <div class="font-weight-normal body-2" style="color:#12253a;">
                    <v-icon :color="growth_format(entity.expiring_soon.vol_change_percent).color">
                        {{ growth_format(entity.expiring_soon.vol_change_percent).icontype }}
                    </v-icon>
                    {{ growth_format(entity.expiring_soon.vol_change_percent).change }}%
                    </div>
                </v-card-subtitle>
                </v-card>
            </v-col>
            </v-row>
            </v-card>
        </v-col>
        </v-row>
    </v-card>
</template>
<script>
import { growth_format, numFormatter, formattedCurrency, govAvatarColor } from "../Utils.js";
import json from "../data/categories.json"
export default {
    props: {
        entity: {},
        category_id: Number,
        supplier_id: Number,
        publisher_id: Number,
        loading_stats: Boolean,
        all_time_header: String,
        awards_last_year_header: String,
        expiry_next_year_header: String
  },
  data(){
      return{
          major_categories: json.data,
          is_major_category: Boolean,
          category_type_id: "category_id",
          industry: []
      }

  },

  mounted(){
      var ind = this.entity["industry"];
  
      if (ind && ind.includes(","))
        this.industry = ind.split(',');
      else
        this.industry[0] = ind;

      this.is_major_category = this.major_categories.find(x => x.id === parseInt(this.category_id));

      if (this.is_major_category){
          this.category_type_id= "major_category_id"
      }
  },

  methods: {
    growth_format,
    numFormatter,
    formattedCurrency,
    govAvatarColor,

    disableIcon(url) {
      if (!url) {
        return true;
      } else {
        return false;
      }
    },
  }
}
</script>