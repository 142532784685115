<template>
<v-combobox
    v-model="selectedClusters"
    x-small-chips
    :items="clusters"
    item-text="name"
    clearable
    dense
    label="Cluster"
    multiple
    x-small
    @change="selectedChanged()"
    :menu-props="{ maxWidth: '350', maxHeight: '300' }"
    ref="clusters"
>
    <template v-slot:selection="{ attrs, item, select, selected }">
    <v-chip
        color="rgb(255, 20, 99)"
        x-small
        v-bind="attrs"
        :input-value="selected"
        close
        @click="select"
        @click:close="remove_cluster(item)"
        ><span class="chip-label">{{ item.name }}</span></v-chip
    >
    </template>
</v-combobox>
</template>

<script>
import { getClusters, updateClusters, addCluster } from "../components/APIManager.js"
import { bus } from "../main.js";

export default {
  props: {
    current_buyer_flag: Boolean,
    disabled: Boolean,
    entityType: String,
    entityIDType: String,
    entities:[],
    injectedClusters: []

  },
  data: () => ({
    newClusterName: "",
    clusters: [],
    loading:true,
    clusterEntities: [],
    selectedClusters:[],
    fav: true,
    menu: false,
    message: false,
    hints: true,
    active: true,
    years: [2016, 2025],
    year_min: 2016,
    year_max: 2025,
    keyword_chips: [],
    keyword_items: [],
    selected_types: [],
    include_publisher: true,
    expiryRangeMenu: false,
    expiryRange: []
  }),

  mounted(){
    this.loadClusters();

  },

  watch: {

    injectedClusters: {
      handler() {
        //////////console.log("injectedClusters: ", this.injectedClusters);
       //this.selectedClusters = this.injectedClusters;
        this.selectedClusters = [];
        for (var i in this.injectedClusters){
          //////////console.log("injectedClusters: ", this.injectedClusters);
          let c = this.clusters.find(x => x.id === Number(this.injectedClusters[i]));
          this.selectedClusters.push(c);
        }

        this.selectedChanged();
    
        
      },
      deep: true
    }

  },

  computed: {

  },

  methods: {

    selectedChanged(){
        this.$emit("updateCluster", this.selectedClusters);
    },

    remove_cluster(item) {
      this.selectedClusters.splice(this.selectedClusters.indexOf(item), 1);
      this.selectedClusters = [...this.selectedClusters];
    },

    async postCluster(){
        this.loading = true;
        
        addCluster(this.newClusterName, this.entityType).then(results => {
            this.clusters = results.data;
            for (var i in this.clusters){
                this.clusters[i].search_query = JSON.parse(this.clusters[i].search_query)
            }
            this.loading = false;
        });
    },

    async postClusters(){

        this.loading = true;
        var targetClusters = [];

        if (!this.selectedClusters){
            for (var i in this.clusters){
                targetClusters.push(this.clusters[i].id);
            }
        }
        else{
            targetClusters = this.selectedClusters;
        }

        updateClusters(targetClusters, this.entityType, this.entities).then(results => {
            
            this.clusters = results.data;
            for (var i in this.clusters){
                this.clusters[i].search_query = JSON.parse(this.clusters[i].search_query)
            }
            this.loading = false;
        });

    },

    async loadClusters(){

      this.loading = true;

      getClusters(this.entityType).then(results => {
        this.clusters = results.data;
        ////////////console.log("CLUSTER QUERY: ", JSON.parse(this.clusters[0].search_query));
        for (var i in this.clusters){
            this.clusters[i].search_query = JSON.parse(this.clusters[i].search_query)
        }
        
   
        for (var i in this.injectedClusters){
          let c = this.clusters.find(x => x.id === Number(this.injectedClusters[i]));
          this.selectedClusters.push(c)
        }

        this.$emit("updateCluster", this.selectedClusters);

        this.loading = false;
        ////////////console.log("loadPublishers data: ", results);
      });
    },

    exists(cluster_entities){
        
        var exists = cluster_entities.some(item => this.entities.includes(item));
        return exists;

    },
    
    partialExists(cluster_entities){
        let totalEntities = this.entities.length;
        let numMatches = 0;
        let partialMatch = false;

        for (let i = 0; i < this.entities.length; i++){
            if (cluster_entities.indexOf(this.entities[i]) === -1) {
                partialMatch = true;
                break;
            }
            else{
                numMatches++;
            }
        }

        if (numMatches == totalEntities){
            partialMatch = false; //is a fullmatch so indeterminate should be false
        }
      
        return partialMatch;
    },

    year() {
      var d = new Date();
      return d.getFullYear();
    },

    remove_kw(item) {
      this.keyword_chips.splice(this.keyword_chips.indexOf(item), 1);
      this.keyword_chips = [...this.keyword_chips];
    },

    clear_expiryRange() {
      this.expiryRange = [];
    },

    update_search() {
      let additional_filters = {
        keywords: this.keyword_chips,
        expiry_from: this.expiryRange[0],
        expiry_to: this.expiryRange[1],
        type: this.selected_types,
        include_publisher: this.include_publisher
      };

      bus.$emit("update-filter", additional_filters);
    }
  }
};
</script>
