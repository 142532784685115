<template>
  <span>
    <v-row class="pa-0 ma-0">
      <v-col v-if="tenders.length > 0 || overlay" cols="12" class="pa-0 ma-0">
        <v-card v-if="overlay" tile height="406px">
          <v-skeleton-loader
            type="list-item-three-line, divider, list-item-three-line, list-item-three-line"
          ></v-skeleton-loader>
        </v-card>
        <v-card v-else tile height="406px">
          <v-card-text
            class="font-weight-normal subtitle-1 py-3"
            style="color:#12253a;"
          >
            <span class="font-weight-normal" style="color:rgb(255, 20, 99);">
              {{ num_hits }}
            </span>
            notice<span v-if="num_hits > 1">s</span> <span>awarded</span>
            <span v-if="chartGroup === 'supplier'">
              to
              <router-link
                target="_blank"
                text
                style="text-decoration: none; !important;"
                :to="{
                  name: 'supplier',
                  params: { id: tenders[0].supplier_id }
                }"
              >
                {{ tenders[0].supplier }}</router-link
              >
            </span>
            <span v-else-if="chartGroup === 'publisher'">
              by
              <router-link
                target="_blank"
                text
                style="text-decoration: none; !important;"
                :to="{
                  name: 'entity',
                  params: { id: tenders[0].publisher_id }
                }"
              >
                {{ tenders[0].publisher }}</router-link
              >
            </span>
            <span v-else-if="chartGroup === 'parent_category'">
              for
              <router-link
                target="_blank"
                text
                style="text-decoration: none; !important;"
                :to="{
                  name: 'category',
                  params: { id: tenders[0].parent_category_id }
                }"
              >
                {{ tenders[0].parent_category }}</router-link
              >
            </span>
            <span v-else-if="chartGroup === 'category'">
              for
              <router-link
                target="_blank"
                text
                style="text-decoration: none; !important;"
                :to="{
                  name: 'category',
                  params: { id: tenders[0].category_id }
                }"
              >
                {{ tenders[0].category }}</router-link
              >
            </span>
            <span v-if="total_value > 0">
              with a total awarded value of
              <span class="font-weight-normal" style="color:rgb(255, 20, 99);">
                {{ formattedCurrency(total_value) }}</span
              >
            </span>
            <span v-if="year_filter"> in</span>
            <span
              v-if="year_filter"
              class="font-weight-normal"
              style="color:rgb(255, 20, 99);"
            >
              {{ year_filter }}
            </span>
          </v-card-text>

          <v-divider></v-divider>
          <v-row>
            <v-col cols="12" class="pl-3 pt-3">
              <v-list
                three-line
                class="py-0 overflow-y-auto"
                max-height="260px"
              >
                <v-list-item-group
                  v-model="selectedTender"
                  color="#FFFFFF"
                  class="py-0"
                >
                  <v-list-item
                    v-for="item in tenders"
                    :key="item.id"
                    class="py-0"
                  >
                    <v-list-item-content>
                      <v-tooltip
                        left
                        dark
                        color="#000000"
                        style="opacity: 0.7;"
                      >
                        <template v-slot:activator="{ on }">
                          <v-list-item-title
                            v-on="on"
                            class="wrap-text"
                            style="color: #12253a; !important;"
                            @click="getTender(item.id)"
                            >{{ item.title }}</v-list-item-title
                          >
                          <v-list-item-subtitle v-if="item.expiry_date"
                            ><v-icon small class="pr-1 pt-0">mdi-clock</v-icon
                            >{{
                              formatDate(item.expiry_date)
                            }}</v-list-item-subtitle
                          >
                          <v-list-item-subtitle v-if="item.value"
                            ><b>{{
                              formattedCurrency(item.value)
                            }}</b></v-list-item-subtitle
                          >
                        </template>
                        <span class="tooltip"
                          ><b>Buyer: </b> {{ item.publisher }}</span
                        >
                        <br />
                        <span class="tooltip"
                          ><b>Supplier: </b> {{ item.supplier }}</span
                        >
                        <br />

                        <span class="tooltip">{{ item.description }}</span>
                      </v-tooltip>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
          </v-row>

          <div v-if="num_pages > 1" class="text-center" style="max-width:600px">
            <v-pagination
              v-model="current_page"
              :length="num_pages"
              color="#4EB888"
              total-visible="5"
              prev-icon="mdi-menu-left"
              next-icon="mdi-menu-right"
              @input="getPage(current_page)"
            ></v-pagination>
          </div>
          <!-- <v-overlay :value="overlay" absolute>
            <v-card-text class="caption">
              <p class="text-md-center">
                Fetching Data...
              </p>
              <p class="text-md-center">
                <v-progress-circular
                  indeterminate
                  size="32"
                ></v-progress-circular>
              </p>
            </v-card-text>
          </v-overlay> -->
        </v-card>
      </v-col>
      <v-col v-else cols="12" class="pa-0 ma-0">
        <v-card tile height="406px">
          <v-card-text
            class="font-weight-normal subtitle-1 py-3"
            style="color:#12253a;"
            >Notices</v-card-text
          >
          <v-divider></v-divider>
          <v-row>
            <v-col cols="12" class="pl-4 pt-4">
              <p class="text-center py-0 px-0">
                <v-icon v-for="i in 3" :key="i" class="pl-0 ml-0" x-large
                  >mdi-menu-left</v-icon
                >
              </p>
            </v-col>
            <v-col cols="12" class="pl-4 py-0">
              <v-card-subtitle class="subtitle-1 py-0 my-0"
                >Click on a section of a graph to see the contracts for that
                segment.</v-card-subtitle
              >
              <p class="text-center py-3 px-0">
                <v-icon v-for="i in 3" :key="i" class="pl-0 ml-0" x-large
                  >mdi-menu-right</v-icon
                >
              </p>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </span>
</template>

<script>
import moment from "moment";
import { DataSet, Network } from "vis-network";
import { bus } from "../main.js";
import { executeSearch } from "./APIManager";

export default {
  props: {
    graph: String,
    agg_size: Number,
    chartGroup: String
  },

  data() {
    return {
      current_page: 1,
      selectedTender: {},
      drawer: false,
      //topSelection: this.query.agg_size,
      fullscreen: false,
      toggle: null,
      nodes: null,
      edges: null,
      network: null,
      option: {},
      overlay: false,
      absolute: true,
      selectedNode: String,
      currQuery: {},
      tenders: [],
      num_hits: Number,
      total_value: Number,
      hit_info: {},
      year_filter: Number
      //query: {}
    };
  },
  watch: {
    $route(to, from) {
      ////////////console.log("IN TENDER RESULTS: ");
      this.tenders = [];
    }
    /*     num_hits: {
      handler() {
        ////////////console.log("IN TENDER RESULTS: ");
        this.overlay = false;
      }
      //deep: true
    } */
  },
  created() {
    bus.$on("barClicked", qry => {
      //this.overlay = true;
      if (qry["type"] == this.chartGroup) {
        ////////////console.log("TYPE: " + qry["type"] + " GROUP: " + this.chartGroup);
        this.year_filter = qry["contract_year_from"];
        this.current_page = 1;
        this.updateQuery(qry);
        this.loadTenders().then(results => {
          ////////////console.log(results);
          this.tenders = results.data;
          this.num_hits = results.hits.value;
          this.total_value = results.aggregations.total_amount.value;
          this.num_pages = results.hits.total_pages;
          //this.overlay = false;
        });
      }
    });

    bus.$on("pieClicked", qry => {
      //this.overlay = true;
      if (qry["type"] == this.chartGroup) {
        ////////////console.log("TYPE: " + qry["type"] + " GROUP: " + this.chartGroup);
        this.year_filter = null;
        this.current_page = 1;
        this.updateQuery(qry);
        this.loadTenders().then(results => {
          ////////////console.log(results);
          this.tenders = results.data;
          this.num_hits = results.hits.value;
          this.total_value = results.aggregations.total_amount.value;
          this.num_pages = results.hits.total_pages;
        });
        //this.overlay = false;
      }
    });
  },

  computed: {
    entityName() {
      if (this.currQuery["supplier_id"]) {
        return this.tenders[0].supplier;
      } else {
        return this.tenders[0].publisher;
      }
    }

    /* num_pages: function() {
      return this.hits.total_pages;
    } */
  },

  methods: {
    async getPage(number) {
      //this.overlay = true;
      this.current_page = number;
      this.loadTenders().then(results => {
        this.tenders = results.data;
        //this.overlay = false;
      });
    },

    formattedCurrency: function(amount) {
      ////////////console.log("AMOUNT: " + amount);
      if (!isNaN(amount)) {
        return (
          "$" +
          Math.round(amount)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        );
      } //else {
      //return null;
      //}
    },
    formatDate(date_value) {
      if (!date_value) {
        return null;
      } else {
        return moment(date_value).format("MMMM YYYY");
      }
    },
    getTender(id) {
      //this.$router.push({ name: "tender", params: { id: id } });
      let routeData = this.$router.resolve({
        name: "tender",
        params: { id: id }
      });
      window.open(routeData.href, "_blank");
    },

    updateQuery(query) {
      //this.currQuery = JSON.parse(JSON.stringify(query)); //NEED THIS TO PASS A COPY INSTEAD OF A REFERENCE
      this.currQuery = query;
    },

    async loadTenders() {
      this.overlay = true;
      var results;
      results = await executeSearch(this.currQuery, this.current_page);
      this.overlay = false;
      return results;
    }
  }
};
</script>
<style>
#mynetwork {
  height: 980px;
  overflow: visible;
}

#mynetwork .vis-network:focus {
  outline: none;
}

div.vis-network div.vis-navigation div.vis-button.vis-up,
div.vis-network div.vis-navigation div.vis-button.vis-down,
div.vis-network div.vis-navigation div.vis-button.vis-left,
div.vis-network div.vis-navigation div.vis-button.vis-right,
div.vis-network div.vis-navigation div.vis-button.vis-zoomIn,
div.vis-network div.vis-navigation div.vis-button.vis-zoomOut,
div.vis-network div.vis-navigation div.vis-button.vis-zoomExtends {
  background-image: none !important;
}

div.vis-network div.vis-navigation div.vis-button:hover {
  box-shadow: none !important;
}

div.vis-tooltip {
  color: #fff !important;
  background-color: #000000 !important;
  border-radius: 0px !important;
  border: none !important;
  font-family: verdana !important;
  font-size: 12px !important;
}

.vis-button:after {
  font-size: 2em !important;
  color: gray !important;
}

.vis-button:hover:after {
  font-size: 2em !important;
  color: lightgray !important;
}

.vis-button.vis-up:after {
  content: "▲" !important;
}

.vis-button.vis-down:after {
  content: "▼" !important;
}

.vis-button.vis-left:after {
  content: "◀";
}

.vis-button.vis-right:after {
  content: "▶" !important;
}

.vis-button.vis-zoomIn:after {
  content: "+" !important;
  font-weight: bold !important;
}

.vis-button.vis-zoomOut:after {
  content: "−" !important;
  font-weight: bold !important;
}

.wrap-text {
  -webkit-line-clamp: unset !important;
}

.tooltip {
  display: inline-block;
  max-width: 360px;
}

/* .vis-button.vis-zoomExtends:after {
  content: "⤧" !important;
} */
</style>
