<template>
  <div :id="id" :style="stackedBar"></div>
</template>

<script>
import * as echarts from "echarts";
import {downloadFile} from "./Utils";
import $ from 'jquery';
//import jsondata from "../temp/life-expectancy-table.json";

export default {

 props:{
     series: Object,
     legend_data: Array,
     y_axis_data: [],
     changed: Number,
     download: Number,
     metric_type: String,
     target_group: String,
     interval: String,
     story: Boolean,
     embed: Number,
     color: [],
     chart_options: {},
     id: Number,
      chart_size: {
        type: String,
        default: "large"
      },
    chartHeight:{
      type: String,
      default: "200px"
    }
 },
  data() {
    return {
      options: {},
      myChart: null,
      canvasCSS: String(),
      chartWidth: Number(),
      legend_padding: Number()
    };
  },

  watch: {
    changed() {
      //this.myChart.dispose();
      ////////////console.log("CHANGED DATA: ", this.series)
      this.loadChart();
    },

    embed() {
      ////////////console.log("CALL SHARE CHART\n", this.myChart);
      
      this.saveChart();
    },

    download() {
      this.saveImage();
    }
  },

  mounted() {
      //nextTick will fix chart width at 100px issue
      this.$nextTick(()=>{
        ////////////console.log("SERIES: ", this.series);
        this.loadChart();
      });
  },

  methods: {

    setStyle () {
      if (this.chart_size == "large"){
        this.chartHeight= 60;
        this.chartWidth=180;
        this.legend_padding = -30;
        this.canvasCSS = "reportPieLarge";
        
      }
      else{
        this.chartHeight= 40;
        this.chartWidth=120;
        this.legend_padding = -30;
        this.canvasCSS = "reportPieSmall";;
      }
            
    },

    saveChart(){

      var imgUrl = this.myChart.getDataURL();

      let data = {
        imgData: imgUrl, //this.myChart.getDataURL(),
        options: this.options
      };

      this.$emit("chart_object", data);

      ////////////console.log("LINE CHART PAYLOAD: ", data);

    },

    saveImage(){

      var img = new Image();
      
      img.src = this.myChart.getDataURL({
          pixelRatio: 2,
          backgroundColor: '#fff'
      });

      downloadFile(img.src);

    },

    loadChart() {

        if (this.myChart != null && this.myChart != "" && this.myChart != undefined) {
            this.myChart.dispose();
        }

        var chartDom = document.getElementById(this.id);
        this.myChart = echarts.init(chartDom);

        var option;


        option = {
          color : [
                  "#12253a",
                  "#ff1463",
                  "#d73e32",
                  "#2196f3",
                  "#004700",
                  "#2d9437",
                  "#004d9f",
                  "#007cd6",
                  "#d73e32",
                  "#de004b",
                  "#ff457c",
                  "#4a575d",
                  "#7b898f",
                  "#bb5900",
                  "#bb5900",
                  "#ffa72d",
                  "#4caf50",
                  "#2196f3",
                  "#fb8c00",
                  "#ff1463",
                  "#b71c1c",
                  "#ff937b",
                  "#95e8ff",
                  "#a2ff9e",
                  "#fffb82",
                  "#FFE70A",
                  "#SFS603",
                  "#7F7305",
                  "#BFAB07",
                  "#FF6905",
                  "#5405FF"
                ],
          tooltip: {
            trigger: 'item',
            textStyle:{
              fontSize: 10
            },
            axisPointer: {
              // Use axis to trigger tooltip
              type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
            }
          },
          label: {
                show: false, 
                //position: "right", 
                //fontSize:9,
                formatter : (s) => {
                    function convert (num){
                        if (num > 999 && num < 1000000) {
                            num = (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
                        } else if (num >= 1000000 && num < 1000000000) {
                            num = (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
                        } else if (num >= 1000000000) {
                            num = (num / 1000000000).toFixed(1) + "B"; // convert to M for number from > 1 million
                        }

                        return num;
                    }
                    ////////////console.log(s);

                    return '{valueStyle|' + '$'+ convert(s.value) + '}';
                    
                },
                  rich: {
                      valueStyle: {
                          // Make yearly text more standing out
                          //color: '#000',
                          fontWeight: 'bold',
                          fontSize: 10
                      }
                  }
            },
          legend: {
                show: true,
                icon: "circle",
                orient: 'horizontal',
                textStyle: {
                  fontSize: 9
                },
                padding: [5, 5, 10, 5],
                type: "scroll",
                left: 10,
                itemStyle: {
                  color: 'inherit'
                }
                //top:'center',
                //bottom: 0
              },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'value',
            splitLine: {
                    show: false
            },
            axisTick:{
              show: false
            },
            axisLine: {
              show: false
            },
            axisLabel: {
              showMinLabel: false,
              showMaxLabel: true,
              formatter : (s) => {
                            function convert (num){
                                if (num > 999 && num < 1000000) {
                                    num = (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
                                } else if (num >= 1000000 && num < 1000000000) {
                                    num = (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
                                } else if (num >= 1000000000) {
                                    num = (num / 1000000000).toFixed(1) + "B"; // convert to M for number from > 1 million
                                }

                                return num;
                            }


                            return '{valueStyle|' + '$'+ convert(s) + '}';

                            
                        },
              rich: {
                  valueStyle: {
                      // Make yearly text more standing out
                      color: '#000',
                      fontWeight: 'normal',
                      fontSize: 10
                  }
              }
            }
          },
          yAxis: {
            type: 'category',
            //overflow: "break",
            data: this.y_axis_data,
            splitLine: {
                    show: false
                  },
            axisTick:{
              show: false
            },
            axisLine: {
              show: false
            },
            axisLabel: {
                  show: true,
                  showMinLabel: true,
                  showMaxLabel: true,
                  fontSize: 10,
                  //overflow: 'truncate',
                  //width: 400
                  formatter: (label) => {
                    ////////////console.log(params);
                    var name = this.truncateString(label, 20);
                    return name;
                    //return '{b|'+num + '}';
                  },
                  
                },
          },
          series: this.series
        };

          this.myChart.setOption(option);

          this.options = option;
          //this.options.metric_type = this.metric_type;

          //this.saveChart();

          

    },

    truncateString(str, num) {
      if (str.length > num) {
        return str.slice(0, num) + "...";
      } else {
        return str;
      }
    },
  },
  computed: {
    stackedBar() {
      return 'margin-top: 0px !important;padding-top: 0px !important;padding-left: 0px !important;padding-bottom: 0px !important;width: 100% !important;height: '+this.chartHeight+' !important;'

    }
  },
}

</script>
<style>
/* .reportLine {
  margin-top: 0px !important;
  padding-top: 0px !important;
  padding-left: 0px !important;
  width: 100% !important;
  height: 200px !important;
} */

</style>