<template>
  <v-card min-height="300" tile>
    <v-card-title class="py-1">
      <span
        class="font-weight-normal subtitle-1 py-2"
        style="color:#12253a;"
      >
        {{ title }}
      </span>
      <div class="flex-grow-1"></div>
      <span class="text-capitalize body-2 desktop-nav"  style="color:#12253a;">
        View By
      </span>
      <span>
        <v-btn-toggle class="ml-2" v-model="defaultButton">
          <v-btn
            class="text-capitalize body-2 desktop-nav mt-0 mb-1"
            small
            color="#12253a"
            @click="barChartConfig(0)"
            outlined
            >Start Date
          </v-btn>
          <v-btn
            class="text-capitalize body-2 desktop-nav mt-0 mb-1"
            small
            color="#12253a"
            @click="barChartConfig(1)"
            outlined
            >Expiry Date
          </v-btn>
        </v-btn-toggle>
      </span>
    </v-card-title>

    <v-divider></v-divider>

    <!-- <div v-if="loading" class="text-center">
      <v-progress-circular
        :size="70"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div> -->

    <div class="pa-3"></div>
    <v-container fluid>
      <v-row>
        <v-col v-if="date_aggs.length == 0"  cols="12" md="12">
          <div class="text-center" style="height:305px;">
            <v-progress-circular
              :size="70"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
        </v-col>
        <v-col v-else cols="12" md="12">
          <line-chart
            :aspect_ratio="false"
            :legend_position="'left'"
            :legend_display="showLegend"
            :chart_data="chart_data"
            :chart_axes="chart_axes"
            :chart_labels="chart_labels"
            :metric_name="metric_name"
            :target_entity="target_entity"
            :entity_type="entity_type"
            :changed="changed"
            :supplier_id="supplier_id"
            :currentAggType="currentAggType"
            v-on:htmlLegend="updateLegend"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import {
  prepMultiLineData,
  getXLabels,
  prepEntityData
} from "../components/PrepLineChart.js";
import { executeSearch } from "../components/APIManager.js";
import { bus } from "../main.js";
import LineChart from "../components/LineChart.vue";

export default {
  components: {
    LineChart
    //Donut
  },
  props: {
    title: String(),
    tenders: [],
    date_aggs: [],
    expiry_aggs: [],
    published_aggs: [],
    contracting_activity: [],
    expiring_activity: [],
    entity_aggs: [],
    entity: String(),
    entity_id: Number,
    supplier_id: Number,
    entity_type: String(),
    entity_panel: Number,
    index: Number,
    search: {},
    metric_name: String,
    units: String,
    annual_spend: []
    //results_drawer: Boolean
  },
  data() {
    return {
      aggGroup: ["contracted", "expiry"],
      outlined: true,
      defaultButton: 0,
      toggleButton: "rgb(255, 20, 99)",
      toggleText: "View By Start Date",
      toggleOn: false,
      legend: null,
      showLegend: true,
      item_details: false,
      aggregated_data: [],
      //publisher_line_chart_data: [],
      line_chart_headers: [],
      chart_data: { data: [], activity: [] },
      chart_labels: [],
      chart_axes: { x: "Transactions", y: "Year" },
      metric_type: "count",
      group_by_amount: true,
      target_entity: String,
      ready: false,
      changed: Number,
      currentAggType: "contracted"
      //loading: true
    };
  },
  watch: {
    date_aggs: {
      handler() {
        ////////////console.log("IN TENDER RESULTS: ");
        this.barChartConfig(0);
        this.defaultButton = 0;
      },
      deep: true
    }
  },
  mounted() {
    this.barChartConfig(0);

    /* this.$on("htmlLegend", htmlLegend => {
      if (htmlLegend.entityType == this.entity_type) {
        this.legend = htmlLegend.html;
      }
      this.legend = htmlLegend.html;
    }); */
  },

  methods: {
    excludeFromSearch(itemName) {
      var exclude_field = "exclude_" + this.entity_type;
      //var exclude_query= {exclude_field: }

      this.$router.replace({
        name: "search",
        query: {
          ...this.$route.query,
          [exclude_field]: Array(itemName) //using square brackets to stop literal naming of key
        }
      });
    },

    updateLegend(htmlLegend) {
      //onsole.log(htmlLegend);
      this.legend = JSON.parse(htmlLegend);
    },

    get_targets(bucket_data, type) {
      let targets = [];
      let target_count = 0;

      //for each year get the targets
      for (var y = 0; y < bucket_data.length; y++) {
        let data_for_year = bucket_data[y][type].buckets;

        ////////////console.log("year: " + bucket_data[y].key_as_string);
        ////////////console.log("buckets: " + JSON.stringify(data_for_year));

        ////////////console.log("array length: " + data_for_year.length);

        for (var t = 0; t < data_for_year.length; t++) {
          targets[target_count] = data_for_year[t].key;
          ////////////console.log("entity: " + data_for_year[t].key);
          target_count++;
        }
      }
      return targets;
    },

    barChartConfig(aggType) {
      //this.loading = true;
      let data = [];

      let activity = [];
      
      let annual_spend = [];

      let target_aggs = [];

      var topXactivity = [];

      if (aggType == 0) {
        target_aggs = this.date_aggs;
        this.currentAggType = this.aggGroup[0];
        for (var c in this.annual_spend) {
          activity[c] = this.annual_spend[c].doc_count;
          annual_spend[c] = this.annual_spend[c].total_amount.value;
        }
      } else if (aggType == 1) {
        target_aggs = this.expiry_aggs;
        this.currentAggType = this.aggGroup[1];
        for (var c in this.expiring_activity) {
          activity[c] = this.expiring_activity[c].doc_count;
          annual_spend[c] = this.expiring_activity[c].total_amount.value;
        }
      }

      //let contracted_aggs = this.date_aggs;

      let targets = this.entity_aggs;

      let colors = [
        "#12253a",
        "#ff1463",
        "#d73e32",
        "#2196f3",
        "#004700",
        "#006000",
        "#00791e",
        "#2d9437",
        "#004d9f",
        "#0064ba",
        "#007cd6",
        "#d73e32",
        "#de004b",
        "#ff457c",
        "#4a575d",
        "#7b898f",
        "#bb5900",
        "#bb5900",
        "#ffa72d",
        "#4caf50",
        "#2196f3",
        "#fb8c00",
        "#ff1463",
        "#b71c1c",
        "#ff937b",
        "#95e8ff",
        "#a2ff9e",
        "#fffb82",
        "#FFE70A",
        "#SFS603",
        "#7F7305",
        "#BFAB07",
        "#FF6905",
        "#5405FF"
      ];

      for (var t in targets) {
        let color = colors.shift();
        ////////////console.log("colour: ", color);
        let target_data = prepMultiLineData(
          target_aggs,
          this.entity_type,
          this.metric_name,
          targets[t].key,
          targets[t].key,
          color
        );

        data[t] = target_data;
      }

      ////////////console.log("DATA: ", data);

      //get the actvity of each top entity for each year an sum it
      if (data[0] && data[0]["activity_data"]){

        for (var y in data[0].activity_data) {
          let year_data = 0;
          for (var ent in data) {
            year_data += parseInt(data[ent].activity_data[y]); //sum the data for the current year for each entity
          }
          topXactivity[y] = year_data;
        }


      }
      
      ////////////console.log("DATA: ", data);
      ////////////console.log("VOLUME: ", activity);

      this.chart_labels = getXLabels(target_aggs);
      this.chart_data["data"] = data;
      this.chart_data["activity"] = activity;
      this.chart_data["topXactivity"] = topXactivity;
      this.chart_data["annual_spend"] = annual_spend;

      this.changed = (Math.random() + 10) * Math.random();

      //this.loading = false;
    }
  }
};
</script>

<style>
.chart-container {
  height: 800px !important;
  width: 800px !important;
}

.chart-legend {
  list-style-type: none;
  font-size: 9pt;
  font-family: "Roboto";
  /*width: fit-content;
  margin: 0.5rem auto;*/
  margin-left: 0px;
  padding-left: 0px;
}
.chart-legend li {
  padding: 0.25rem;
}
.chart-legend .legend-item {
  height: 11;
  display: flex;
}
.chart-legend .legend-item .label {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 0px;
  padding-left: 0px;
}
.chart-legend .legend-item span {
  cursor: pointer;
  margin-right: 0.75rem;
  margin-top: 0.2rem;
  border-radius: 0.75rem;
  width: 0.3rem;
  height: 0.75rem;
}

.chart-legend .legend-item span:hover {
  text-decoration: underline;
}
</style>
