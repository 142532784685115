<template>
  <div
        class="pa-0 ma-0 fill-height"
        style="background-color:#F4F4F4;"
        fluid
    >
   <v-main>
        <v-card class="ma-0 pa-0" tile flat color="secondary">
            <v-row justify="center" class="px-0 pt-3 pb-0">
                <v-col cols="12">
                  <v-card dark color="secondary" tile flat max-width="1080px" class="mx-auto pa-9">
                      <v-card-title class="pl-0 ml-0 pb-9">
                          <span class="pl-0">
                              <a href="https://tendertrace.com" target="_blank">
                              <v-img
                              src="@/assets/img/logo-white.png"
                              max-height="100"
                              contain
                              class="pl-0 ml-0"
                              >
                              </v-img>
                              </a>
                          </span>
                      <!-- <span class="pl-0 headline" style="font-weight:bold; color:white;">Insights</span> -->
                      </v-card-title>
                      <h2 class="display-3">Page not found.</h2>
                      <p class="title mt-3 font-weight-light">Oh! It looks the page you're looking for does not exist. Or you may not have access to it.</p>
                      <div>
                        Go <router-link class="editor-link" to="/home">home</router-link> or learn more at <a class="editor-link" href="https://tendertrace.com" target="_blank">tendertrace.com</a>.
                      </div>
                  </v-card>
                </v-col>
            </v-row>
        </v-card>
        
   </v-main>
   
  </div>
</template>

<script>
export default {
  name: "notfound",

  data() {
    return {
      response_msg: "Invalid username or password. Please try again.",
      msg_timeout: 7000,
      snackbar: false,
      snackbar_colour: "#4EB888",
      x: null,
      y: "top"
    };
  },
}

  
</script>

<style scoped>
.editor-link {
    border-color: rgb(255, 190, 10);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    color: inherit !important;
}
</style>
