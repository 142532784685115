<template>
  <v-card v-if="item" tile>
    <v-card-text>
      <v-row>
        <v-col :cols="6">
            <v-card-text class="pt-0 pb-0 font-weight-normal">Amendment Value Analysis</v-card-text>
              <v-divider class="mx-3"></v-divider>
                <v-row class="ma-0 pa-0">
                  <v-col v-if="item.entity_amendment_rollup" :cols="6" class="ma-0 pa-0">
                    <v-card tile flat class="py-0 my-0">
                      <v-card-subtitle
                            class="font-weight-normal caption text-center pb-0"
                        style="color:#12253a;"
                        >Avg First Amendment Value</v-card-subtitle
                        >
                        <v-card-subtitle class="py-0 text-center">
                          <v-progress-circular
                            v-if="item.entity_amendment_rollup"
                            :value="item.entity_amendment_rollup.benchmark_first_amendment_percentage"
                            color="secondary"
                            :size="100"
                            :width="15"
                          >
                            <strong class="font-weight-bold caption">{{ item.entity_amendment_rollup.benchmark_first_amendment_percentage }}%</strong>
                          </v-progress-circular> 
                        </v-card-subtitle>
                        <v-card-subtitle
                            class="font-weight-normal caption text-center py-0"
                        style="color:#12253a;"
                        >{{ formattedCurrency(item.entity_amendment_rollup.benchmark_first_amendment_actual_value) }}
                        </v-card-subtitle
                        >
                    </v-card>
                  </v-col>
                  <v-col v-if="item.govt_amendment_benchmarks" :cols="6" class="ma-0 pa-0">
                    <v-card tile flat class="py-0 my-0">
                      <v-card-subtitle
                            class="font-weight-normal caption text-center pb-0"
                        style="color:#12253a;"
                        >Avg Across Market</v-card-subtitle
                        >
                        <v-card-subtitle class="py-0 text-center">
                          <v-progress-circular
                            v-if="item.govt_amendment_benchmarks"
                            :value="item.govt_amendment_benchmarks.benchmark_first_amendment_percentage"
                            color="primary"
                            :size="100"
                            :width="15"
                          >
                            <strong class="font-weight-bold caption">{{ item.govt_amendment_benchmarks.benchmark_first_amendment_percentage }}%</strong>
                          </v-progress-circular> 
                        </v-card-subtitle>
                        <v-card-subtitle
                            class="font-weight-normal caption text-center py-0"
                        style="color:#12253a;"
                        >{{ formattedCurrency(item.govt_amendment_benchmarks.benchmark_first_amendment_actual_value) }}
                        </v-card-subtitle
                        >
                    </v-card>
                  </v-col>
                  
                </v-row>
          </v-col>

            <v-col :cols="6">
              <v-card-text class="pt-0 pb-0 font-weight-normal">Timeframe Analysis</v-card-text>
                <v-divider class="mx-3"></v-divider>
                    <v-row class="ma-0 pa-0">
                      <v-col v-if="item.entity_amendment_rollup" :cols="6" class="ma-0 pa-0">
                        <v-card tile flat class="py-0 my-0">
                            <v-card-subtitle
                            class="font-weight-normal caption text-center pb-0"
                            style="color:#12253a;">
                            Avg Time To First Amendment</v-card-subtitle
                            >
                            <v-card-subtitle class="py-0 text-center">
                              <v-progress-circular
                                v-if="item.entity_amendment_rollup"
                                :value="item.entity_amendment_rollup.benchmark_percent_complete"
                                color="secondary"
                                :size="100"
                                :width="15"
                              >
                                <strong class="font-weight-bold caption">{{ item.entity_amendment_rollup.benchmark_percent_complete }}%</strong>
                              </v-progress-circular>  
                            </v-card-subtitle>
                            <v-card-subtitle
                                class="font-weight-normal caption text-center py-0"
                                style="color:#12253a;"
                                >{{ item.entity_amendment_rollup.benchmark_duration_to_first_amendment }} days
                                </v-card-subtitle
                            >
                        </v-card>
                      </v-col>
                      <v-col v-if="item.govt_amendment_benchmarks" :cols="numAnalyisCols(item)" class="ma-0 pa-0">
                        <v-card tile flat class="py-0 my-0">
                          <v-card-subtitle
                            class="font-weight-normal caption text-center pb-0"
                            style="color:#12253a;"
                            >Average Across Market</v-card-subtitle
                            >
                            <v-card-subtitle class="py-0 text-center">
                              <v-progress-circular
                                v-if="item.govt_amendment_benchmarks"
                                :value="item.govt_amendment_benchmarks.benchmark_percent_complete"
                                color="primary"
                                :size="100"
                                :width="15"
                              >
                                <strong class="font-weight-bold caption">{{ item.govt_amendment_benchmarks.benchmark_percent_complete }}%</strong>
                              </v-progress-circular>  
                            </v-card-subtitle>
                            <v-card-subtitle
                                class="font-weight-normal caption text-center py-0"
                                style="color:#12253a;"
                                >{{ item.govt_amendment_benchmarks.benchmark_avg_contract_duration }} days</v-card-subtitle
                            >
                        </v-card>
                      </v-col>
                    </v-row>
            </v-col>
        </v-row> 
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    item: {},
    entity_name: String
  },
  data() {
    return {
    
    };
  },

  methods: {
    getColor(event) {
      // Customize this method to return different colors for different event types
      switch (event) {
        case 'Commenced':
          return 'primary';
        case 'Published':
          return 'secondary';
        case 'Amended':
          return 'highlight';
        case 'Expiry':
          return 'primary';
        default:
          return 'grey';
      }
    },

    formattedCurrency: function(amount) {
      ////////console.log("AMOUNT: " + amount);
      if (!isNaN(amount)) {
        return (
          "$" +
          Math.round(amount)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        );
      } //else {
      //return null;
      //}
    },

    numCols(item) {
      if (item.benchmarks) {
        return 12;
      } else {
        return 6;
      }
    },

    numAnalyisCols(item) {
      if (item.benchmarks) {
        return 3;
      } else {
        return 6;
      }
    },
    
    disableIcon(url) {
      if (!url) {
        return true;
      } else {
        return false;
      }
    },
    formatDate(date) {
      // Format date string to a more readable format
      return new Date(date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    },
  },
};
</script>