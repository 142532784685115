<template>
  <div class="pa-0 ma-0 fill-height" style="background-color:#f5f5f5" fluid>
    <v-content class="py-1">
      <v-container fluid>
        <v-row class="py-4" style="background-color:#12253a;">
          <v-col cols="12">
            <!-- <span style="height:20%;"></span> -->
            <div
              style="max-width:1200px; display: block; margin:auto !important; padding-top:50px;"
            >
              
              <v-row align="center" justify="center">
                <v-col cols="11" style="max-width:800px;" class="pr-0 mr-0">
                  <tender-search />
                </v-col>
                <v-col cols="1">
                  <v-btn
                    class="text-capitalize body-2 ml-0 desktop-nav"
                    color="white"
                    small
                    text
                    :to="{
                      name: 'search',
                      query: {
                        expiry_from: new Date().getFullYear(),
                        expiry_to: new Date().getFullYear(),
                        sort_order: 'asc',
                        showFilter: true
                      }
                    }"
                  ><span class="text-capitalize body-2 desktop-nav">Advanced</span>
                    
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <div class="pt-5 pb-2"><p class="title pb-0 pl-1"><span class="pt-2">My Search Profiles </span><span class="pt-0 mt-0">
          <v-dialog v-model="explainer">
          <v-card tile flat class="pa-4">
                <p class="title" style="color:#12253a;">
                  What are Search Profiles?
                </p>
                <p style="color:#12253a;">
                  Search profiles are based on searches that have been executed and then saved. This means you can quickly
                  rerun to those searches when you log back in. Tendertrace will
                  automatically monitor search profiles and notify you of any new notices that match a profile. All new matches will be available in your
                  "Alerts" page.
                </p>
                <p>
                  You will find up to date analysis of the search profiles you create on your home page.
                </p>
                <p style="color:#12253a;">
                  Saved searches also save the search tab you are on. So for example, if you were on the Leaderboard tab when you saved the search profile, clicking on the saved profile will run the search and also take you to the tab you were on when you saved the profile initially.
                </p>
                <p style="color:#12253a;">
                  To start saving searches: 
                  <ul>
                    <li>Run a search for contracts or tenders of interest.</li> 
                    <li>In the filter description section of the screen, click the button labeled "Save Search" to have Tendetrace start monitoring that search on
                  your behalf.</li>
                  </ul>
                </p>
                <p>

                  <v-btn color="primary" small>
                  <router-link :to="{ name: 'search',
                                        query: {
                                          disposition: ['Contract Notice','Grant'],
                                          expiry_from: new Date().getFullYear(),
                                          expiry_to: new Date().getFullYear(),
                                          sort_order: 'asc'
                                        }
                                      }"
                      ><span style="color:white !important;">Create profile</span></router-link
                    >
                  </v-btn>
                </p>
                </v-card>
        </v-dialog>
          <v-icon small color="secondary" @click="explainer = true">mdi-information-outline</v-icon>
          
          
          </span></p></div>
          
        <v-row
          class="py-0"
          style="height: 100%;"
        >
          <v-col cols="12" sm="6" class="pt-0 pb-0">
            <v-combobox
                  v-model="selected_profiles"
                  :items="saved_searches"
                  :loading="loadingSearches"
                  :search-input.sync="search"
                  item-text="name"
                  label="Select one or more profiles to compare"
                  outlined
                  chips
                  dense
                  multiple
                  class="pb-0"
                  return-object
                  
                >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    color="rgb(255, 20, 99)"
                    class="accent white--text"
                    small
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    @click="select"
                    @click:close="remove_profile(item)"
                    ><span class="chip-label">{{ item.name }}</span></v-chip
                  >
              </template>
          </v-combobox>
          </v-col>
        </v-row>
        <v-row v-if="selected_profiles.length == 0">
          <v-col>
              <v-card tile flat class="pa-4">
                <p class="title" style="color:#12253a;">
                  What are Search Profiles?
                </p>
                <p style="color:#12253a;">
                  Search profiles are based on searches that have been executed and then saved. This means you can quickly
                  rerun to those searches when you log back in. Tendertrace will
                  automatically monitor search profiles and notify you of any new notices that match a profile. All new matches will be available in your
                  "Alerts" page.
                </p>
                <p>
                  You will find up to date analysis of the search profiles you create on your home page.
                </p>
                <p style="color:#12253a;">
                  Saved searches also save the search tab you are on. So for example, if you were on the Leaderboard tab when you saved the search profile, clicking on the saved profile will run the search and also take you to the tab you were on when you saved the profile initially.
                </p>
                <p style="color:#12253a;">
                  To start saving searches: 
                  <ul>
                    <li>Run a search for contracts or tenders of interest.</li> 
                    <li>In the filter description section of the screen, click the button labeled "Save Search" to have Tendetrace start monitoring that search on
                  your behalf.</li>
                  </ul>
                </p>
                <p>

                  <v-btn color="primary" small>
                  <router-link :to="{ name: 'search',
                                        query: {
                                          disposition: ['Contract Notice','Grant'],
                                          expiry_from: new Date().getFullYear(),
                                          expiry_to: new Date().getFullYear(),
                                          sort_order: 'asc'
                                        }
                                      }"
                      ><span style="color:white !important;">Create profile</span></router-link
                    >
                  </v-btn>
                </p>
                </v-card>
            </v-col>
        </v-row>
          <v-row v-else v-for="selected_profile in selected_profiles" :key="selected_profile.search_id" class="pb-3">
            <v-col v-if="selected_profile['search_query']" cols="12" class="pt-0 pb-0">
              <v-card width="100%" flat outlined>
                <v-card-title class="pt-3 pb-4 pl-6" style="font-weight:medium !important; color: #12253a !important;">
                      <v-badge
                        color="primary"
                        :value="selected_profile.new_matches > 0"
                        :content="selected_profile.new_matches + ' new notices'"
                      >
                      <router-link
                            :to="{
                                name: 'search',
                                query: selected_profile.search_query
                              }"
                            >
                        <span>{{selected_profile.name}}</span>
                      </router-link>
                      </v-badge>
                    <v-spacer></v-spacer>
                    <span>
                      <v-tooltip left max-width="250">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon>
                              <v-icon v-bind="attrs" v-on="on" medium color="primary">mdi-information-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>What data is this search profile based on?</span
                            >
                            <br />
                            <v-divider class="mt-2 mb-2" style="border-color:white;"></v-divider>
                            <span>This search profile is an aggregate view of the results from the search <i>{{selected_profile.plain_text_query}}</i></span>
                            <div></div>
                        </v-tooltip>
                    </span>
                    <span>
                      <v-tooltip left max-width="250">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn @click="openProfile(0, selected_profile)" icon>
                              <v-icon v-bind="attrs" v-on="on" medium color="secondary">mdi-format-list-bulleted</v-icon>
                            </v-btn>
                          </template>
                          <span>Go to the data for this profile</span>
                        </v-tooltip>
                    </span>
                    <span>
                      <v-tooltip left max-width="250">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn @click="openProfile(1, selected_profile)" icon>
                              <v-icon v-bind="attrs" v-on="on" medium color="secondary">mdi-sort-variant</v-icon>
                            </v-btn>
                          </template>
                          <span>Go to the leaderboard for this profile</span>
                        </v-tooltip>
                    </span>
                    <span>
                      <v-tooltip left max-width="250">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn @click="openProfile(3, selected_profile)" icon>
                              <v-icon v-bind="attrs" v-on="on" medium color="secondary">mdi-chart-box-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Go to the analysis for this profile</span>
                        </v-tooltip>
                    </span>
                </v-card-title>
                <v-card-text class="px-5 pt-0">
                  <v-row class="pa-0">
                    <v-col cols="12" sm="12">
                      <search-analysis :query="selected_profile.search_query" />
                    </v-col>
                  </v-row>
                  <v-row class="pa-0">
                    <v-col class="pl-0" cols="12" sm="3">
                      <spend-comparison-mini
                        :title="''" :description="''" :query="selected_profile.search_query"
                      />
                    </v-col>
                    <v-col cols="12" sm="3">
                      <spending-donut-mini
                        :title="'Top Sectors'" :description="''" :query="selected_profile.search_query" :group="{text: 'Industry', value: 'publisher_industry'}"
                      />
                    </v-col>
                    <v-col cols="12" sm="3">
                      <spending-donut-mini
                        :title="'Top Categories'" :description="''" :query="selected_profile.search_query" :group="{text: 'Major Category', value: 'major_category'}"
                      />
                    </v-col>
                    <v-col cols="12" sm="3">
                      <spending-donut-mini
                        :title="'Top Buyers'" :description="''" :query="selected_profile.search_query" :group="{text: 'Buyer', value: 'publisher'}"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            
          </v-row>
      </v-container>
    </v-content>
  </div>
</template>

<script>
import TenderSearch from "../components/TenderSearch";
import { getStats, entitySearch } from "../components/APIManager";
import SearchAnalysis from "../components/SearchAnalysis.vue";
import SpendingDonutMini from "../components/SpendingDonutMini.vue";
import SpendComparisonMini from "../components/SpendComparisonMini.vue";
import { getInstance } from "../auth/index.js";
import axios from "axios";

//import AdvancedFilter from "../components/AdvancedFilter.vue";
//import Network from "../components/Network";

export default {
  components: {
    SearchAnalysis,
    SpendingDonutMini,
    SpendComparisonMini,
    TenderSearch,
  },

  data() {
    return {
      explainer: false,
      search: String(),
      stats: {},
      selected_profiles: [],
      saved_searches: [],
      loadingSearches: Boolean(),
      errored: Boolean(),
      cards: [
        {
          title: "Open tenders",
          src: "requests.png",
          flex: 3,
          id: 0
        },
        {
          title: "Competitor contracts",
          src: "competitorcontracts.png",
          flex: 3,
          id: 1
        },
        {
          title: "Partnership opportunities",
          src: "networkmin.png",
          flex: 3,
          id: 2
        }
      ],
      changed: Number
    };
  },

  computed: {
    imgSourced() {
      return "@/assets/img/requests.png";
    }
  },

  mounted(){
    this.loadingSearches = true;
    this.getSavedSearches();

  },

  methods: {

    remove_profile(item) {
      this.selected_profiles.splice(
        this.selected_profiles.indexOf(item),1);
      this.selected_profiles = [...this.selected_profiles];
    },

    openProfile(viewIndex, profile) {
      var qry = profile.search_query;

      qry["viewIndex"] = viewIndex;

      this.$router.push({ path: "/search", query: qry });
      //bus.$emit("load-filters", JSON.parse(query));
    },

    refresh(){
      this.setChanged();
    },

    setChanged(){
      this.changed =
          (Math.random() + this.chart_data.data.length) * Math.random();
      //this.generate_json();
    },

    async getSavedSearches() {
      
      const instance = getInstance();
      const accessToken = await instance.getTokenSilently();

      axios
        .get(process.env.VUE_APP_API_SEARCHES, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        .then(response => {
          ////////console.log("SAVED SEARCHES" + JSON.stringify(response.data.saved_searches));
          this.saved_searches = response.data.saved_searches;
          this.selected_profiles.push(this.saved_searches[0]);
          //this.selected_profiles.push(this.saved_searches[1]);
          this.loadingSearches = false;
          //return this.saved_searches;
        })
        .catch(error => {
          ////////console.log(error);
          this.errored = true;
        })
        .finally(() => (this.loadingSearches = false));
    },

    numFormatter(num) {
      if (num > 999 && num < 1000000) {
        return (num / 1000).toFixed(0) + "K"; // convert to K for number from > 1000 < 1 million
      } else if (num > 1000000 && num < 1000000000) {
        return (num / 1000000).toFixed(0) + "M"; // convert to M for number from > 1 million
      } else if (num > 1000000000) {
        return (num / 1000000000).toFixed(0) + "B"; // convert to M for number from > 1 million
      } else if (num < 900) {
        return num; // if value < 1000, nothing to do
      }
    },

    future() {
      var d = new Date();
      var year = d.getFullYear();
      var month = d.getMonth();
      var day = d.getDate();
      var future = new Date(year + 10, month, day);
      return future.toISOString().substr(0, 10);
    },

    today() {
      var dte = new Date();
      return dte.toISOString().substr(0, 10);
    },

    imgSource() {
      var src;
      switch (id) {
        case 0:
          src = "@/assets/img/requests.png";
          ////////console.log(src);
          break;
        case 1:
          src = "@/assets/img/competitorcontracts.png";
          break;
        case 2:
          src = "@/assets/img/networkmin.png";
          break;
      }

      return src;
    }
  },

  async created() {
    this.stats = await getStats();
    ////////console.log(JSON.stringify(this.stats));
  }
};
</script>

<style scoped>
.chip-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.searchBackground::after {
  background-image: url("../assets/img/background.ef1dd5e2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  content: "";
  opacity: 0.09;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}

.bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url("../assets/img/networkbanner.png") no-repeat center center;
  background-size: inherit;
  transform: scale(1.1);
  opacity: 0.09;
}

#linkhover:hover {
  text-decoration: underline !important;
}
</style>
