<template>
    <v-row>
      <v-col cols="12">
        <v-overlay :value="reportLoading" absolute :opacity="opacity">
          <v-progress-circular
            indeterminate
            size="36"
            color="primary"
          ></v-progress-circular>
        </v-overlay>
        <!-- <v-dialog
          v-model="reportLoading"
          hide-overlay
          persistent
          width="300"
          lazy
          absolute          
        >
          <v-card
            color="secondary"
            dark
          >
            <v-card-text>
              Building report...
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog> -->
      </v-col>
    </v-row>
</template>

<script>
export default {
  components: {
    
  },
  props: {
    reportLoading: Boolean
  },
  data() {
    return {
      loader: Boolean,
      opacity: 0
    };
  },

  watch: {
    reportLoading() {
      ////////////console.log("ROUTE CHANGEDS: ");
      //this.loadData(this.$route.query);

      this.loader = this.reportLoading;
    },
  }
}
</script>

