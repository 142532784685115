<template>
    <div class="fill-height pa-0 ma-0" style="background-color:#f5f5f5" fluid>
        <v-content>
            <v-row justify="center" class="px-0 py-3">
                <v-card color="#f5f5f5" flat width="95%" class="mx-auto">
                    <p
                        style="text-align:center;"
                        class="pt-6 display-1 font-weight-medium"
                    >
                        Panels and Standing Offers
                    </p>
                    <p style="text-align:center;" class="body-1 font-weight-normal">
                        Find and explore panels and standing offers of interest. Only the first 100 results are displayed, so use the keyword search to narrow down your list. Search using keywords for
                        title, description, category, supplier or lead agency names. 
                    </p>

                    <v-row class="px-0 py-0">
                        <v-col cols="12">
                            <v-card flat color="#f5f5f5" tile min-height="40" class="ma-0 pa-0">
                                <v-card-text class="ma-0 pa-0">
                                <v-combobox
                                    v-model="keywords"
                                    small-chips
                                    :rounded="false"
                                    :solo="true"
                                    clearable
                                    outlined
                                    dense
                                    label="Enter tab separated keywords e.g. Antenna, Deloitte, Defence"
                                    multiple
                                    small
                                    ref="keywords"
                                    append-outer-icon="mdi-magnify"
                                    :dark="false"
                                    @click:append-outer="loadData()"
                                    @click:clear="clearSearch()"
                                >
                                    <template v-slot:selection="{ item, select, selected }">
                                    <v-chip
                                        color="rgb(255, 20, 99)"
                                        small
                                        label
                                        :input-value="selected"
                                        close
                                        @click="select"
                                        @click:close="remove_kw(item)"
                                        >{{ item }}</v-chip
                                    >
                                    </template>
                                </v-combobox>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    
                    <v-data-table
                        :headers="headers"
                        :items="filteredItems"
                        :footer-props="{
                            'items-per-page-options': [100]
                        }"
                        :items-per-page="100"
                        :loading="loading"
                        class="elevation-1 py-2"
                        >
                            <template v-slot:item.gov_type="{ item }">
                              <v-avatar
                                  :color="govAvatarColor(item.gov_type)"
                                  size="36"
                                  class="mr-2"
                                >
                                  <span class="white--text caption">{{ item.gov_type }}</span>
                                </v-avatar>
                            </template>
                            <template v-slot:item.son_id="{ item }">
                                <router-link class="editor-link" style="font-size: 14px !important; font-weight: 500 !important;" :to="'/panel?son_id='+item.son_id+'&government='+item.gov_type">{{stripHTML(item.son_id)}}</router-link>
                            </template>
                            <template v-slot:item.son_start="{ item }">
                            {{ formatDate(item.son_start) }}
                            </template>
                            <template v-slot:item.son_end="{ item }">
                            {{ formatDate(item.son_end) }}
                            </template>
                            <template v-slot:item.total_spend="{ item }">
                            {{ formattedCurrencyFull(item.total_spend) }}
                            </template>
                            <template v-slot:item.son_url="{ item }">
                                <span class="text-center">
                                    <v-btn v-if="item.son_url"
                                        small
                                        icon
                                        color="#4EB888"
                                        light
                                        :disabled="isLink(item.son_url)"
                                        :href="item.son_url"
                                        target="_blank"
                                        style="text-decoration: none !important;"
                                    >
                                        <v-icon>mdi-web</v-icon>
                                    </v-btn>
                                </span>
                            </template>
                    </v-data-table>
                </v-card>
            </v-row>
        </v-content>
    </div>
</template>

<script>
import { getPanels } from "../components/APIManager";
import { formattedCurrencyFull } from "../components/Utils";

export default {
  data() {
    return {
      data: [],
      keywords: [],
      loading: false,
      search: '',
      gov_type: "",
      page: 1
    };
  },

  mounted() {
    var query = this.$route.query;

    if (query.hasOwnProperty("terms")) {
      this.keywords = JSON.parse(query.terms);
    }
    if (query.hasOwnProperty("gov_type")) {
      this.gov_type = JSON.parse(query.gov_type);
    }
    this.loadData();
  },

  computed:{

    headers(){ 
        return [
            { text: "Gov", value: "gov_type" },
            { text: "Reference", value: "son_id" },
            { text: "Title", value: "title" },
            { text: "Agency", value: "agency" },
            { text: "Category", value: "primary_category_name" },
            { text: "Start Date", value: "son_start" },
            { text: "End Date", value: "son_end" },
            //{ text: "Multi-Agency", value: "multi_agency" },
            //{ text: "Suppliers", value: "total_suppliers" },
            { text: "Active Suppliers", value: "active_suppliers" },
            { text: "Active Buyers", value: "active_publishers" },
            { text: "Awarded", value: "total_spend" },
            //{ text: "Avg Contract", value: "avg_contract_value" },
            { text: "Actions", value: "son_url" }
        ]
    },

    filteredItems() {
      if (this.search) {
        const searchQuery = this.search.toLowerCase()
        return this.data.filter(item => {
          return (
            item.title.toLowerCase().includes(searchQuery) ||
            item.agency.toLowerCase().includes(searchQuery) ||
            item.primary_category_name.toLowerCase().includes(searchQuery) ||
            item.secondary_category_name.toLowerCase().includes(searchQuery)
          )
        })
      } else {
        return this.data
      }
    },

  },

  methods: {
    formattedCurrencyFull,

    isLink(link){
      if (link == null){
        return true;
      }
      else{
        return false;
      }
    },

    govAvatarColor(gov) {
      var color = "grey";
      switch (gov) {
        case "NSW":
          color = "#41a7d9";
          break;
        case "VIC":
          color = "#0058AC";
          break;
        case "WA":
          color = "#FFD200";
          break;
        case "QLD":
          color = "#A6003D";
          break;
        case "AUS":
          color = "#D8993B";
          break;
        case "FED":
          color = "#D8993B";
          break;
        case "DEF":
          color = "#d8673b";
          break;
        case "SA":
          color = "#ff4081";
          break;
        case "TAS":
          color = "#4eb888";
          break;
        case "ACT":
          color = "#41a7d9";
          break;
        case "NT":
          color = "#FF3647";
          break;
      }

      return color;
    },

    stripHTML(text){
        if (!text){
            return "";
        }
        else{
            let strippedText = text.replace(/(<([^>]+)>)/gi, "");
            return strippedText;
        }
        
    },

    formatDate(date) {
      if (date) {
        return new Date(date).toLocaleDateString();
      }
      return 'N/A';
    },

    clearSearch(){
        this.keywords = [];
        var qry = JSON.parse(JSON.stringify(this.$route.query));
        delete qry["terms"];
        delete qry["gov_type"];
        //delete qry["page"];
        
        this.$router.push({ name: "panels", query: qry });
        this.loadData();
    },

    remove_kw(item) {
      this.keywords.splice(this.keywords.indexOf(item), 1);
      this.keywords = [...this.keywords];
      if (this.keywords.length == 0){
        this.clearSearch()
      }
    },

    async loadData(){
        this.loading = true;
        this.data = [];
        getPanels(this.gov_type, this.page, this.keywords)
        .then(response => {
            // Extract data for chart
            this.data = response.data;
            this.loading = false;
        })
        .catch(error => {
            this.data=[];
        });  
    },

  },
};
</script>