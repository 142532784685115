<template>
  <div>
      <v-card flat tile height="100%" width="100%" class="elevation-1">
        <v-card tile flat class="mx-auto">
          <v-row dense v-if="!story" class="pb-0">
            <v-col cols="9" class="pl-3 pb-0">
                <v-card-text
                    v-if="isLoading"
                    class="ml-0 pl-0 py-0"
                    style="color:#12253a;"
                  >
                    <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                  </v-card-text>
                <v-card-text
                  v-else-if="queryName"
                  class="font-weight-normal subtitle-1 pl-2"
                  style="color:#12253a;"
                >
                  <span>
                  <div class="font-weight-normal caption" style="color:#12253a;">
                    <span>Query Results</span>
                    <span class="pt-0 mt-0 pl-1">
                    <v-icon small color="secondary" @click="qry_dialog=!qry_dialog">mdi-help-circle</v-icon>
                    </span>
                  </div>
                  
                  <span class="font-weight-medium title" style="color:#12253a;">
                    {{ queryName }}
                  </span>

                  </span>
                </v-card-text>
              </v-col>
              <v-col cols="3" md="3" class="pr-5">
              <v-card-text class="text-right pr-0" style="float: right;">
                <span class="pl-2">
                  <v-btn tile style="justify-content:center !important;" icon href="https://docs.tendertrace.com/guide/user-documentation/analysing-search-results/leaderboard" target="_blank">
                      <v-icon class="material-icons" color="secondary">mdi-help-circle-outline</v-icon>
                  </v-btn>
                </span>
              <span class="pl-2">
                <v-btn
                  icon
                  tile
                  color="secondary"
                  @click="exportToCSV"
                  style="justify-content:center !important;"
                  ><v-icon>mdi-download</v-icon>
                  </v-btn>
              </span>
              <span class="pl-2">
                <v-tooltip bottom dark color="#000000" style="opacity: 0.7;">
                    <template v-slot:activator="{ on }"> 
                        <v-btn
                        icon
                        tile
                        v-on="on"
                        color="secondary"
                        @click="openWidget()"
                        style="justify-content:center !important;"
                        ><v-icon>mdi-open-in-new</v-icon>
                    </v-btn>
                    </template>
                    <span>Open in separate window</span>
                  </v-tooltip>
              </span>
              <span class="pl-2">
                <v-tooltip bottom dark color="#000000" style="opacity: 0.7;">
                    <template v-slot:activator="{ on }"> 
                        <v-btn
                            icon
                            tile
                            v-on="on"
                            class="text-capitalize"
                            color="secondary"
                            @click="newStory"
                            style="justify-content:center !important;"
                            ><v-icon>mdi-playlist-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>Add these results to a custom report</span>
                </v-tooltip>
              </span>
                </v-card-text>
            </v-col>
          </v-row>
          <v-row v-if="headers.length > 0 && !story" class="py-0">
            <v-col cols="6" class="py-0">
              <v-card-text class="py-0">
                <v-select class="py-0" dense v-model="selectedHeaders" 
                        :items="headers" label="Select Columns" 
                        multiple 
                        outlined
                        :menu-props="{ offsetY: true }" 
                        return-object>
                  <template v-slot:selection="{ item, index }">
                    <v-chip small v-if="index < 4">
                      <span>{{ item.text }}</span>
                    </v-chip>
                    <span v-if="index === 4" class="grey--text caption">(+{{ selectedHeaders.length - 4 }} others)</span>
                  </template>
                </v-select>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
          <div>
            <v-data-table
              dense
              :loading="isLoading"
              loading-text="Loading... Please wait"
              class="pt-3"
              multi-sort
              :headers="visibleHeaders"
              :items="rows"
            >
            <template #item.supplier_name="{ item }" v-if="columnExists('supplier_name')">
              <router-link :to="{ name: 'supplier', params: { id: item.supplier_id } }" target="_blank">
                {{item.supplier_name}}
              </router-link>
            </template>
            <template #item.buyer_name="{ item }" v-if="columnExists('buyer_name')">
              <router-link :to="{ name: 'entity', params: { id: item.buyer_id } }" target="_blank">
                {{item.buyer_name}}
              </router-link>
            </template>
            <template #item.contract_title="{ item }" v-if="columnExists('contract_title')">
              <router-link :to="{ name: 'tender', params: { id: item.contract_id } }" target="_blank">
                {{item.contract_title}}
              </router-link>
            </template>
              <template #item.contract_id="{ item }" v-if="columnExists('contract_id')">
                <router-link :to="{ name: 'tender', params: { id: item.contract_id } }" target="_blank">
                  {{item.contract_id}}
                </router-link>
              </template>
              <template #item.supplier_id="{ item }" v-if="columnExists('supplier_id')" target="_blank">
                <router-link :to="{ name: 'supplier', params: { id: item.supplier_id } }">
                  {{item.supplier_id}}
                </router-link>
              </template>
              <template #item.buyer_id="{ item }" v-if="columnExists('buyer_id')" target="_blank">
                <router-link :to="{ name: 'entity', params: { id: item.buyer_id } }">
                  {{item.buyer_id}}
                </router-link>
              </template> formattedCurrencyFull
              <template #item.source_url="{ item }" v-if="columnExists('source_url')">
                <a :href=item.source_url target="_blank"><v-icon>mdi-web</v-icon></a>
              </template>
            </v-data-table>
            <v-card class="mt-1" flat tile>
              <v-row v-if="apiData.columns.length >= 2">
              <div v-if="selectedChartType !== 'pie'" class="d-inline pl-4 ml-4 mr-5">
                <p class="mb-0 pb-0 subtitle-2" style="font-weight:bold;">X-axis</p>
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="text-lowercase subtitle-2"
                          color="#E0E0E0"
                          small
                          outlined
                          v-bind="attrs"
                          v-on="on"
                          width="220"
                          style="justify-content:left !important;"
                        >
                        <span style="color:#12253a;">{{ selectedXColumn }}</span>
                          <v-icon color="#12253a" right>mdi-menu-down</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item-group>
                            <v-list-item
                                v-for="column in apiData.columns"
                                :key="column"
                                @click="selectedXColumn=column, initChart()"
                            >
                                <v-list-item-title>{{ column }}</v-list-item-title>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-menu>
              </div>
              <div class="d-inline pl-8 mr-5">
                <p class="mb-0 pb-0 subtitle-2" style="font-weight:bold;">{{ selectedChartType === 'pie' ? 'Values' : 'Y-axis' }}</p>
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="text-lowercase subtitle-2"
                          color="#E0E0E0"
                          outlined
                          small
                          v-bind="attrs"
                          v-on="on"
                          width="220"
                          style="justify-content:left !important;"
                        >

                        <span style="color:#12253a;">{{ selectedYColumn }}</span>
                          <v-icon color="#12253a" right>mdi-menu-down</v-icon>
                        </v-btn>
                      </template>
                        <v-list>
                              <v-list-item-group>
                                  <v-list-item
                                      v-for="column in apiData.columns"
                                      :key="column"
                                      @click="selectedYColumn=column, initChart()"
                                  >
                                      <v-list-item-title>{{ column }}</v-list-item-title>
                                  </v-list-item>
                              </v-list-item-group>
                          </v-list>
                      </v-menu>
                  </div>
                  <div class="d-inline pl-4 mr-5">
                    <p class="mb-0 pb-0 subtitle-2" style="font-weight:bold;">Chart Type</p>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                class="text-lowercase subtitle-2"
                                color="#E0E0E0"
                                outlined
                                small
                                v-bind="attrs"
                                v-on="on"
                                width="220"
                                style="justify-content:left !important;"
                            >
                                <span style="color:#12253a;">{{ selectedChartType }}</span>
                                <v-icon color="#12253a" right>mdi-menu-down</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item-group>
                                <v-list-item
                                    v-for="chartType in ['scatter', 'pie', 'verticalBar']"
                                    :key="chartType"
                                    @click="selectedChartType=chartType, initChart()"
                                >
                                    <v-list-item-title>{{ chartType }}</v-list-item-title>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-menu>
                </div>
                </v-row>
              <div ref="chart" style="height: 300px; width: 100%;"></div>
          </v-card>
        </div>
      </v-card>
      <v-dialog v-model="qry_dialog" hide-overlay width="700" absolute>
        <v-card tile>
        <v-card-title>What query are these results based on?
            <div class="flex-grow-1"></div> <v-btn icon @click="qry_dialog=!qry_dialog"><v-icon style="font-weight:thin;" medium>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-tabs>
          <v-tab>Plain Text</v-tab>
          <v-tab>SQL</v-tab>
          <v-tab-item>
          <v-card flat>
            <v-card-text
              class="font-weight-normal subtitle-1"
              style="color:#12253a;"
            >       
              <span>{{ resultsText }}</span>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text
              class="font-weight-normal subtitle-1"
              style="color:#12253a;"
            >       
              <span><code>{{ resultsSQL }}</code></span>
            </v-card-text>
          </v-card>
        </v-tab-item>
        </v-tabs>
        
        </v-card>
    </v-dialog>
    <v-dialog v-model="sharebox" hide-overlay max-width="1200" absolute style="overflow-x:'hidden' !important;">
        <v-card tile>
        <v-card-title>Add query results to a custom report
            <div class="flex-grow-1"></div> <v-btn icon @click="sharebox=!sharebox"><v-icon medium>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card tile flat class="mx-auto pa-5" max-width="1150px">
            <v-row justify="center">
            <v-col
              cols="6"
              class="pb-0 d-flex child-flex"
            >
              <v-combobox
                v-model="selected_board"
                :items="storyboards"
                :loading="storiesLoading"
                item-value="id"
                :search-input.sync="search"
                item-text="plainTextTitle"
                label="Select a report"
                outlined
                dense
                class="pb-0"
                return-object
              >
              </v-combobox>
            </v-col>
              <v-col
              cols="6"
              class="pb-0"
            >

              <v-btn color="primary" :disabled="!saveButtonActive" @click="saveChart(), updateBoards(), sharebox=!sharebox">Save to report</v-btn>
              </v-col>

              <v-col cols="12" class="pt-0 d-flex child-flex">
                <tracey-story :changed="changeCounter" :key="changeCounter" :data="embed_object" />
              </v-col>
            </v-row>
        </v-card>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { formattedCurrencyFull, downloadFile } from "./Utils";
import { executePlainTextSearch, fetchStoryboards, addStory, addStoryboard } from "./APIManager.js"
import * as echarts from "echarts";
import TraceyStory from "./TraceyStory.vue"

export default {
  props: {
    story: {
      type: Boolean,
      default: false
    },
    changed: Number,
    chart_options: Object,
    query: {
      type: Object
    }
  },

  components: {
    TraceyStory
  },
  
  data() {
    return {
      selectedHeaders: [],
      selectedChartType: 'verticalBar', // default chart type
      qry_dialog: false,
      storydata: {},
      embed_object: {},
      widget_name: "tracey-report",
      storyboards: [],
      selected_board: null,
      storiesLoading: false,
      saveButtonActive: false,
      embed_obj_changed: Number,
      new_board: null,
      sharebox: false,
      queryName: "You have not run a search yet.",
      resultsText: "",
      resultsSQL: "",
      isLoading: false, 
      isError: false,
      showData: false,
      internalDialog: this.value,
      viewType: null,
      userQuery: "",
      messages: [],
      apiData: { columns: [],rows: [] },
      data: [],
      queries: [],
      loading: false,
      gov_type: "",
      page: 1,
      responseStatus: {text: null, rows: null},
      chart: null,
      search: null,
      selectedXColumn: "",
      selectedYColumn: "",
      embed: Number,
      changeCounter: 0
    };
  },

  mounted() {
    this.$nextTick(() => {
      if (this.story){
        this.loadStory();
      }
    })
  },


  watch: {

    changed(newData, oldData) {
      // Handle the updated data here if necessary
      if (this.story){
        this.loadStory();
      }else{
        this.fetchData(this.query);
        this.changeCounter = (this.changeCounter+ 1) * Math.random();
      }
    },


    search (val, prev) {
        if (this.selected_board || this.search != null){
          this.saveButtonActive = true;
        }
        else{
          this.saveButtonActive = false;
        }
      }


  },

  computed:{
    headers() {
      return this.apiData.columns.map(col => ({ text: col, value: col, cellClass:"font-weight-normal caption", isVisible: true }));
    },
    rows() {
      return this.apiData.rows;
    },
    visibleHeaders() {
      /* return this.headers.filter(header => {
        // Check conditions and exclude header if a corresponding name column exists
        if (
          (header.value === 'supplier_id' && this.columnExists('supplier_name')) ||
          (header.value === 'buyer_id' && this.columnExists('buyer_name')) ||
          (header.value === 'contract_id' && this.columnExists('contract_title'))
        ) {
          return false;
        }
        return true;
      }); */
      return this.headers.filter(s => this.selectedHeaders.includes(s));
    },

  },


  methods: {
    formattedCurrencyFull,

    newStory(){
      this.set_chart_object();
      this.updateBoards();
      this.sharebox=!this.sharebox;
    },


    async updateBoards(){
        this.storiesLoading = true;
        this.search = null;
        this.saveButtonActive = false;

        var data = await fetchStoryboards();
        this.storyboards = data.storyboards;

        for (var i in this.storyboards){
          this.storyboards[i]["plainTextTitle"] = this.stripHTML(this.storyboards[i]["title"]);
        }

        this.storiesLoading = false;

        //console.log("FINISHED PREPPING DATA: ", this.storyboards, " ", this.storydata)

    },

    stripHTML(text){
        let strippedText = text.replace(/(<([^>]+)>)/gi, "");
        return strippedText;
    },

    set_chart_object(){
      this.storydata.selectedHeaders = this.selectedHeaders;
      this.embed_object["title"] = this.queryName;
      this.embed_object["description"] = this.resultsText;
      this.embed_object["chart_type"] = "tracey-report";
      this.embed_object["payload"] = {imgData: {}, options: this.storydata}
      this.embed_object["id"] = (Math.random() + Date.now()) * Math.random();
      this.embed_obj_changed = (Math.random() + Date.now()) * Math.random();
      this.changeCounter = (this.changeCounter+ 1) * Math.random();
    },

    openWidget(){
        /* let routeData = this.$router.resolve({name: this.widget_name, query: this.$route.query});
        window.open(routeData.href, '_blank'); */
    },

    async saveChart(){
      let data = {};
        data["title"] = this.embed_object.title;
        data["description"] = this.embed_object.description;
        data["chart_type"] = this.embed_object.chart_type;
        data["payload"] =  this.embed_object.payload;

      if (!this.selected_board || (this.search != this.selected_board["plainTextTitle"])){
          //this.selected_board = this.addItem(this.search);
          await addStoryboard({title: this.search, description:'This report needs an overview', story: data})
        }
        else{
          await addStory(this.selected_board.id, data)
          ////////////////console.log("EXISTING REPORT: ", this.selected_board);
        }

      ////////////////console.log("LEADER BOARD PAYLOAD: ", data);

    },

    saveChartImage(){

      var img = new Image();

      img.src = this.chart.getDataURL({
          pixelRatio: 2,
          backgroundColor: '#fff'
      });

      downloadFile(img.src);

    },

    exportToCSV() {
      let csvContent = "data:text/csv;charset=utf-8,";
      
      // Add headers to CSV
      csvContent += this.headers.map(header => header.text).join(",") + "\n";
      
      // Add data rows to CSV
      this.rows.forEach(row => {
        csvContent += Object.values(row).join(",") + "\n";
      });

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "export.csv");
      document.body.appendChild(link); 
      
      link.click(); 
    },

    columnExists(columnName) {
      return this.headers.some((header) => header.value === columnName);
    },

    initChart() {
      var chartType = this.selectedChartType;
      this.chart = echarts.init(this.$refs.chart);
      
      // Fetch the categories (X-axis) based on selected column.
      var categories = this.apiData.rows.map(row => row[this.selectedXColumn]);

      // Fetch the series data based on selected column.
      var seriesData = this.apiData.rows.map(row => row[this.selectedYColumn]);

      // Fetch the series data (Y-axis) based on selected column.
      var series = [];
    
      switch (chartType) {
          case 'scatter':
              series = [{
                  name: this.selectedYColumn,
                  type: 'scatter',
                  data: seriesData
              }];
              break;
          
          case 'pie':
              series = [{
                  name: this.selectedYColumn,
                  type: 'pie',
                  radius: [50, 120],
                  //center: ['30%', '30%'],
                  roseType: "radius",
                  tooltip: {
                    show: true,
                    trigger: "item"
                  },
      
                  labelLine: {
                    lineStyle: {
                      color: "#12253a"
                    },
                    smooth: 0.2,
                    length: 5,
                    length2: 5
                  },
                  data: categories.map((category, index) => ({
                      name: category,
                      value: seriesData[index]
                  }))
              }];
              break;

          case 'verticalBar':
          default:
              series = [{
                  name: this.selectedYColumn,
                  type: 'bar',
                  data: seriesData
              }];
              break;
      }

      var options = {
          color: [
            "#12253a",
            "#ff1463",
            "#d73e32",
            "#2196f3",
            "#004700",
            "#006000",
            "#00791e",
            "#2d9437",
            "#004d9f",
            "#0064ba",
            "#007cd6",
            "#d73e32",
            "#de004b",
            "#ff457c",
            "#4a575d",
            "#7b898f",
            "#bb5900",
            "#bb5900",
            "#ffa72d",
            "#4caf50",
            "#2196f3",
            "#fb8c00",
            "#ff1463"
          ],
          tooltip: { trigger: 'axis' },
          xAxis: { 
              data: chartType === 'pie' ? null : categories, 
              type: chartType === 'pie' ? 'value' : 'category',
              show: chartType === 'pie' ? false : true,
              axisLabel: {
              show: true,
              showMinLabel: true,
              showMaxLabel: true,
              fontSize: 9,
              overflow: 'truncate',
              //width: 100
              formatter: (label) => {
                ////////////console.log(params);
                var name = this.truncateString(label, 20);
                return name;
                //return '{b|'+num + '}';
              },
              
            }, 
          },
          yAxis: { 
            type: chartType === 'horizontalBar' ? 'category' : 'value',
            data: chartType === 'horizontalBar' ? categories : null,
            show: chartType === 'pie' ? false : true,
          },
          series: series
      };

      this.storydata.xAxis = this.selectedXColumn;
      this.storydata.yAxis = this.selectedYColumn;
      
      this.chart.setOption(options);
    },

    truncateString(str, num) {
      if (str.length > num) {
        return str.slice(0, num) + "...";
      } else {
        return str;
      }
    },

    loadStory() {
      //console.log ("IN LOADING STORY");
      let data = this.chart_options;
      this.isLoading = true;
      this.apiData.rows = data.rows;
      this.apiData.columns = data.columns;
      this.resultsText = data.explanation;
      this.resultsSQL = data.sql;
      this.queryName = data.name;
      this.selectedXColumn = data.xAxis;
      this.selectedYColumn = data.yAxis;
      this.selectedChartType = data.chartType;
      this.isError = false;
      this.initChart();
      //this.selectedHeaders = data.selectedHeaders;
      this.selectedHeaders = this.headers;
      this.isLoading = false;
    },

    fetchData(input) {
      this.isLoading = true;
      this.responseStatus.text=1000;
      this.responseStatus.rows=0;
      this.$emit("response", this.responseStatus);
      executePlainTextSearch(input)
        .then(response => {
          //console.log(response);
          this.storydata = response.data;
          this.responseStatus.text= response.status;
          this.responseStatus.rows= response.data.rows.length;
          this.apiData.rows = response.data.rows;
          this.apiData.columns = response.data.columns;
          this.resultsText = response.data.explanation;
          this.resultsSQL = response.data.sql;
          this.queryName = response.data.name;
          this.selectedXColumn = response.data.xAxis;
          this.selectedYColumn = response.data.yAxis;
          this.selectedChartType = response.data.chartType;
          this.isError = false;
          this.selectedHeaders = this.headers;
          this.initChart();
          this.$emit("response", {text: this.responseStatus.text, rows: this.responseStatus.rows, name: this.queryName, id: response.data.id });
        })
        .catch(() => {
          this.isError = true;
          this.responseStatus.text= 500;
          this.responseStatus.rows= 0;
          this.$emit("response", {text: this.responseStatus.text, rows: this.responseStatus.rows, name: this.queryName, id: response.data.id });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    formatDate(date) {
      if (date) {
        return new Date(date).toLocaleDateString();
      }
      return 'N/A';
    },
  }

};
</script>
<style scoped>
/* ... existing styles ... */
.overflow-auto {
    overflow-y: auto; 
    height: 100vh;
}
</style>