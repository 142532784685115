import Vue from "vue";
import Router from "vue-router";
import Search from "@/views/Search.vue";
import Home from "@/views/Home.vue";
import Tender from "@/views/Tender.vue";
import Buyer from "@/views/Buyer.vue";
import Supplier from "@/views/Supplier.vue";
import Category from "@/views/Category.vue";
import Entities from "@/views/Entities.vue";
import Suppliers from "@/views/Suppliers.vue";
import Categories from "@/views/Categories.vue";
import Inbox from "@/views/Inbox.vue";
import StoryBoard from "@/views/StoryBoard.vue";
import OpenBoard from "@/views/OpenBoard.vue";
import SearchProfile from "@/views/SearchProfile.vue";
import Storyboards from "@/views/Storyboards.vue";
import Cluster from "@/views/Cluster.vue";
import Industry from "@/views/Industry.vue";
import Panel from "@/views/Panel.vue";
import Panels from "@/views/Panels.vue";
import Tracey from "@/views/Tracey.vue";
import SharedChart from "@/views/SharedChart.vue";
import NotFound from "@/views/NotFound.vue";
//import BuyerSearch from "@/views/BuyerSearch.vue";
//import SignIn from "@/views/SignIn.vue";
//import Register from "@/views/Register.vue";
//import Confirm from "@/views/Confirm.vue";
//import ChangePass from "@/views/ChangePass.vue";
import SpendingScatter from "@/components/SpendingScatter";
import QuarterlyTrends from "@/components/QuarterlyTrends.vue";
import SpendingTree from "@/components/SpendingTree";
import SpendComparison from "@/components/SpendComparison";
import SpendingPie from "@/components/SpendingPie";
import NetworkView from "@/components/NetworkView";
import ResultsExpiryCycle from "@/components/ResultsExpiryCycle";
import ResultsLeaderboard from "@/components/ResultsLeaderboard";
import TraceyResults from "@/components/TraceyResults";
import Report from "@/components/Report";
import ResultsDashboard from "@/components/ResultsDashboard";
//import ElineChart from "@/components/ELineChart";
import { loggedIn } from "./components/LoginManager";
import { logout } from "./components/LoginManager";
import { authGuard } from "./auth/authGuard";
//import {bus} from "./main.js"
//import Home from "@/views/Home.vue";

Vue.use(Router);

function requiresAuth(to, from, next) {
  if (!loggedIn()) {
    logout();
    next("/sign-in");

    //next("/sign-in");
  } else {
    next();
  }
}

const router = new Router({
  mode: "history",

  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: { title: 'Home' },
      beforeEnter: authGuard
      //navigation guard to ensure user is signed in
    },
    /* {
      path: "/buyer",
      name: "buyer-search",
      component: BuyerSearch,
      beforeEnter: requiresAuth
      //navigation guard to ensure user is signed in
    }, */
    {
      path: "/entities",
      name: "entities",
      component: Entities,
      meta: { title: 'Buyers directory' },
      beforeEnter: authGuard
      //navigation guard to ensure user is signed in
    },
    {
      path: "/categories",
      name: "categories",
      component: Categories,
      meta: { title: 'Categories directory' },
      beforeEnter: authGuard
      //navigation guard to ensure user is signed in
    },
    {
      path: "/suppliers",
      name: "suppliers",
      component: Suppliers,
      meta: { title: 'Suppliers directory' },
      beforeEnter: authGuard
      //navigation guard to ensure user is signed in
    },
    {
      path: "/inbox",
      name: "inbox",
      component: Inbox,
      meta: { title: 'Alerts' },
      beforeEnter: authGuard
      //navigation guard to ensure user is signed in
    },
    {
      path: "/entity/:id",
      name: "entity",
      component: Buyer,
      meta: { title: 'Public entity profile' },
      beforeEnter: authGuard
      //navigation guard to ensure user is signed in
    },
    {
      path: "/supplier/:id",
      name: "supplier",
      component: Supplier,
      meta: { title: 'Supplier profile' },
      beforeEnter: authGuard
      //navigation guard to ensure user is signed in
    },
    {
      path: "/category/:id",
      name: "category",
      component: Category,
      meta: { title: 'Category profile' },
      beforeEnter: authGuard
      //navigation guard to ensure user is signed in
    },
    {
      path: "/search",
      name: "search",
      component: Search,
      meta: { title: 'Search' },
      beforeEnter: authGuard
      //navigation guard to ensure user is signed in
    },
    {
      path: "/report/:storyboardId",
      name: "storyboard",
      component: StoryBoard,
      props: true,
      meta: { title: 'Report' },
      beforeEnter: authGuard
    },
    {
      path: "/reports",
      name: "storyboards",
      component: Storyboards,
      meta: { title: 'Reports directory' },
      beforeEnter: authGuard
    },
    {
      path: "/search-profile",
      name: "search-profile",
      component: SearchProfile,
      meta: { title: 'Search profile' },
      beforeEnter: authGuard
    },
    {
      path: "/cluster/:clusterId",
      name: "cluster",
      component: Cluster,
      props: true,
      meta: { title: 'Cluster profile' },
      beforeEnter: authGuard
    },
    {
      path: "/industries",
      name: "industry",
      component: Industry,
      beforeEnter: authGuard
    },
    {
      path: "/panel",
      name: "panel",
      component: Panel,
      meta: { title: 'Panel profile' },
      //props: true,
      beforeEnter: authGuard
    },
    {
      path: "/panels",
      name: "panels",
      component: Panels,
      meta: { title: 'Panels directory' },
      beforeEnter: authGuard
    },
    /* {
      path: "/tracey",
      name: "tracey",
      component: Tracey,
      meta: { title: 'Tracey' },
      beforeEnter: authGuard
    },
    */
    {
      path: "/widgets/tracey-report",
      name: "tracey-report",
      component: TraceyResults,
      meta: { title: 'Tracey Report' },
      beforeEnter: authGuard
    }, 
    {
      path: "/open/:openboardId",
      name: "openboard",
      component: OpenBoard
    },
    {
      path: "/not_found",
      name: "notfound",
      component: NotFound
    },
    {
      path: "/tender/:id",
      name: "tender",
      component: Tender,
      meta: { title: 'Notice' },
      beforeEnter: authGuard
    },
    
    {
      path: "/widgets/spending-tree",
      name: "spending-tree",
      component: SpendingTree,
      meta: { title: 'Government spending breakdown' },
      beforeEnter: authGuard
    },
    {
      path: "/widgets/quarterly-trends",
      name: "quarterly-trends",
      component: QuarterlyTrends,
      meta: { title: 'Quarterly trends by FY' },
      beforeEnter: authGuard
    },
    {
      path: "/widgets/spending-scatter",
      name: "spending-scatter",
      component: SpendingScatter,
      beforeEnter: authGuard
    },
    {
      path: "/widgets/cumulative-comparison",
      name: "cumulative-comparison",
      component: SpendComparison,
      meta: { title: 'Participant awards over time' },
      beforeEnter: authGuard
    },
    {
      path: "/widgets/leaderboard",
      name: "leaderboard",
      component: ResultsLeaderboard,
      meta: { title: 'Leaderboard' },
      beforeEnter: authGuard
    },
    
    {
      path: "/widgets/overall-spend",
      name: "overall-spend",
      component: SpendingPie,
      meta: { title: 'Market Share' },
      beforeEnter: authGuard
    },
    {
      path: "/widgets/report",
      name: "report",
      component: Report,
      meta: { title: 'Report' },
      beforeEnter: authGuard
    },
    {
      path: "/widgets/expiring-soon",
      name: "expiring-soon",
      component: ResultsExpiryCycle,
      meta: { title: 'Expiring soon' },
      beforeEnter: authGuard
    },
    {
      path: "/widgets/network",
      name: "network",
      component: NetworkView,
      meta: { title: 'Network' },
      beforeEnter: authGuard
    },
    ,
    {
      path: "/widgets/dashboard",
      name: "dashboard",
      component: ResultsDashboard,
      meta: { title: 'Dashboard'},
      beforeEnter: authGuard
    }

  ],
  /* scrollBehavior: function (to) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
  }, */
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Explore Australian government tenders and contracts.';
  next();
})

export default router;
