function buildTree (tree_data, metric_name, entity_type) {

    var results = [];

    for (var i in tree_data){
      let metric = [];

      if (metric_name == "total_amount") {
        metric[0] = tree_data[i].total_amount.value;
        metric[1] = tree_data[i].doc_count;
      }
      else {
        metric[0] = tree_data[i].doc_count;
        metric[1] = tree_data[i].total_amount.value;
      }

      results[i] = { name: tree_data[i].key, 
                     value: metric,
                     itemStyle: { color: get_state_color(tree_data[i].key)},
                     children: []
                    };
      
      var child_data = tree_data[i][entity_type].buckets;
      
      for (var j in child_data){

        let metric = [];

        if (metric_name == "total_amount") {
          metric[0] = child_data[j].total_amount.value;
          metric[1] = (child_data[j].total_amount.value / tree_data[i].total_amount.value) * 100;
          metric[2] = child_data[j].doc_count;
          metric[3] = (child_data[j].doc_count / tree_data[i].doc_count) * 100;
          metric[4] = "amount";
        }
        else {
          metric[0] = child_data[j].doc_count;
          metric[1] = (child_data[j].doc_count / tree_data[i].doc_count) * 100;
          metric[2] = child_data[j].total_amount.value;
          metric[3] = (child_data[j].total_amount.value / tree_data[i].total_amount.value) * 100;
          metric[4] = "count";
        }

        results[i].children[j] = { name: child_data[j].key, 
                                   value: metric,
                                   children: []
                                };
        
      }
    }

    ////////////console.log ("----TREE----");
    ////////////console.log (results);

    return results;

}

export { buildTree };

function get_state_color (name){
  if (name == "NSW") {
    return "#41a7d9";
  } else if (name == "VIC") {
    return "#0058AC";
  } else if (name == "WA") {
    return "#FFD200";
  } else if (name == "QLD") {
    return "#A6003D";
  } else if (name == "AUS") {
    return "#D8993B";
  } 
    else if (name == "FED") {
    return "#D8993B";
  }
    else if (name == "DEF") {
    return "#d8673b";
  }
    else if (name == "SA") {
    return "#ff4081";
  } else if (name == "TAS") {
     return "#4eb888";
  } else if (name == "ACT") {
    return "#41a7d9";
  } else if (name == "NT") {
    return "#FF3647";
  }
}
