<template>
  <v-text-field
    v-model="search_terms"
    outlined
    flat
    hide-details
    label="Try searching: deloitte or software or covid or taxation office"
    prepend-inner-icon="mdi-magnify"
    class="mx-auto font-regular"
    dense
    clearable
    @keyup.enter="keywordSearch(search_terms)"
    background-color="#FFFFFF"
  ></v-text-field>
</template>

<script>
import { bus } from "../main.js";

export default {
  data() {
    return {
      search_terms: "",
      disable_nav: false
      //show_login_menu: false
    };
  },
  methods: {
    keywordSearch(search_terms) {
      var query = { tender: [search_terms] };
      this.$router.push({ path: "/search", query: query });
      //bus.$emit("global-search", query);
    },

    disabled() {
      if (localStorage.getItem("isLoggedIn") == "1") {
        this.disable_nav = false;
        return this.disable_nav;
      } else {
        this.disable_nav = true;
        return this.disable_nav;
      }
    }
  }
};
</script>
<style>
/*  .v-text-field .v-input__control .v-input__slot  {
    min-height: auto !important;
    
  } */
</style>
