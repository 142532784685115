<template>
  <div>
    <v-dialog
      v-model="isLoading"
      hide-overlay
      persistent
      width="300"
      lazy
    >
      <v-card color="secondary" dark>
        <v-card-text>
          Building timeline...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Display chart only if data is not empty and is not loading -->
    <div
      ref="chartContainer"
      style="width: 100%; height: 1000px;"
      
    >
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { getDataLineage } from "./APIManager.js";

export default {

  props:{
    changed: Number,
    current_recid: String
  },
  components:{
    //LineageCard
  },

  data() {
    return {
      isLoading: false,
      hierarchy: null
    };
  },
  mounted() {
    this.fetchHierarchy(); // Fetch hierarchy when the component is mounted
  },
  computed: {
    formattedHierarchy() {
      return this.formatHierarchy(this.hierarchy);
    }
  },
  methods: {

    formattedCurrency: function(amount) {
      ////////////console.log("AMOUNT: " + amount);
      if (!isNaN(amount)) {
        return (
          "$" +
          Math.round(amount)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        );
      } //else {
      //return null;
      //}
    },

    isEmpty(obj) {
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
      }
      return true;
    },

    async fetchHierarchy() {
      this.isLoading = true;

      try {
        const result = await getDataLineage(this.$route.params.id);
        ////console.log("Fetched hierarchy:", result.heirarchy);
        this.hierarchy = result.heirarchy;
        this.$nextTick(() => { // Wait for the DOM to update
          this.renderChart(); // Render the chart after data is fetched
        });
      } catch (error) {
        ////console.error('Error fetching hierarchy:', error);
        this.hierarchy = {}; // Handle as appropriate
      } finally {
        this.isLoading = false;
      }
    },

    formatHierarchy(node) {
      if (!node || typeof node !== 'object') {
        return {};
      }

      // Sort children by contract_start
      const sortedChildren = (node.children && Array.isArray(node.children))
        ? node.children.sort((a, b) => {
            // Determine the sorting date for each node, prioritizing amendment_published_date
            const dateA = a.amendment_published_date 
              ? new Date(a.amendment_published_date).getTime() 
              : new Date(a.contract_start).getTime();
              
            const dateB = b.amendment_published_date 
              ? new Date(b.amendment_published_date).getTime() 
              : new Date(b.contract_start).getTime();
            
            // Sort in ascending order
            return dateA - dateB;
          }).map(child => this.formatHierarchy(child))
        : [];

      return {
        name: node.title || 'Untitled',
        id: node.recid,
        title: node.title || 'No title',
        description: node.description || 'No description',
        publisher: node.publisher || 'No publisher',
        contract_start: node.amendment_published_date ? new Date(node.amendment_published_date).toLocaleDateString() : (node.contract_start ? new Date(node.contract_start).toLocaleDateString() : 'No start date'),
        published_date: node.published_date ? new Date(node.published_date).toLocaleDateString() : 'No published date',
        url: node.url,
        value: node.value,
        amendment_value: node.amendment_value ? node.amendment_value : '',
        disposition: node.disposition,
        amendment_reason: node.amendment_reason ? node.amendment_reason : '',
        is_current_record: node.recid == this.current_recid ? true : false,
        timeline: node.timeline,
        children: sortedChildren,
        tooltipColor: node.recid == this.current_recid ? 'rgb(255, 231, 10)' : 'white',
        //initialTreeDepth: node.disposition == "Contract Notice" ? 2 : 1,
        label: {
            borderWidth: 80,
            borderRadius: 3,
            backgroundColor: node.recid == this.current_recid ? 'rgb(255, 231, 10)' : 'white',
            width: 250,
            shadowColor: '#595953',
            shadowBlur: 10,
            shadowOffsetX: 5,
            shadowOffsetY: 5,
            padding: 5
        }
      };
    },

    getNodeColor(){
        return 'pink';
    },

    renderChart() {
      if (this.$refs.chartContainer) {
        const chart = echarts.init(this.$refs.chartContainer);

        // Create a container for the custom component
        const tooltipContainer = document.createElement('div');
        tooltipContainer.id = 'tooltip-container';

        const options = {
          tooltip: {
            trigger: 'item',
            enterable: true,  // Allows the user to interact with the tooltip
            triggerOn: 'mousemove',  // Trigger on mouse movement
            position: function (point, params, dom, rect, size) {
                // 'point' is the position of the mouse
                // 'size' contains information about the tooltip size and view size (viewport)
                const [x, y] = point;
                const { contentSize, viewSize } = size;

                // Calculate new x and y positions to ensure tooltip doesn't overflow
                let posX = x;
                let posY = y;

                // Adjust X position if tooltip overflows horizontally
                if (x + contentSize[0] > viewSize[0]) {
                    posX = x - contentSize[0]; // Shift to the left
                }
                if (posX < 0) {
                    posX = 0; // Ensure it doesn't move out of view on the left side
                }

                // Adjust Y position if tooltip overflows vertically
                if (y + contentSize[1] > viewSize[1]) {
                    posY = y - contentSize[1]; // Shift up
                }
                if (posY < 0) {
                    posY = 0; // Ensure it doesn't move out of view on the top
                }

                return [posX, posY];
            },
            formatter: (params) => {
              ////console.log(params);

              function formattedCurrency (amount) {
                if (!isNaN(amount)) {
                  return (
                    "$" +
                    Math.round(amount)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  );
                } 
              }
              const item = params.data.timeline && params.data.timeline.length ? params.data.timeline[0] : {};

              // Create HTML structure for the tooltip
              const tooltipHtml = `
                <div class="tooltip-container">
                    <div style="padding:10px; opacity: 0.9;">
                    <div style="font-weight: 400; font-size: 16px; color: #333;">
                        <span><a href="/tender/${item.id}" target="_blank">${item.reference} <i class="mdi mdi-open-in-new" style="font-size: 20px; margin-right: 5px;"></i></a></span>
                    </div>
                    <div class="mycaption">Published ${item.date ? new Date(item.date).toLocaleDateString() : ''}</div>
                    <div style="font-weight: bold; color: #12253a;">${params.data.title}</div>
                    <div style="font-weight: bold; color: #12253a;">
                        ${item.event === 'Contract Amended' ? formattedCurrency(item.value) : formattedCurrency(params.data.value)}
                    </div>
                    <div class="mycaption">
                        ${item.notes ? `<div>${item.notes}</div>` : ''}
                    </div>
                    
                    ${item.event === 'Contract Amended' && item.benchmarks ? 
                        `<div class="mycaption"> 
                        This analysis compares the relative value of <b>this amendment</b> with the relative value of initial contract amendments in the market along with the relative duration between the start of the contract and the date of this amendment.
                        Understanding these comparisons can provide an indication of the tendency of an entity to make amendments to a contract earlier than the norm, or for a higher relative value than the norm.
                        </div>` 
                    : ''}
                    
                    ${item.url ? 
                        `<div style="margin-top: 10px;">
                        <a href="${item.url}" target="_blank" style="display: flex; align-items: center; text-decoration: none;">
                            <i class="mdi mdi-web" style="font-size: 20px; margin-right: 5px;"></i>
                            Go to Source
                        </a>
                        </div>` 
                    : ''}
                    <div>
                        ${item.value_change ? 
                        `<div style="margin-top: 10px;">
                            <div style="margin-bottom: 10px;">Amendment Value Analysis</div>
                            <div style="display: flex; flex-wrap: wrap;">
                            <div style="margin-right: 10px; text-align: center;">
                                <div class="mycaption">Value</div>
                                <div style="position: relative; display: inline-block;">
                                    <div class="progress-circle" style="--value: ${item.value_percentage_change};"></div>
                                    <strong>${item.value_percentage_change}%</strong>
                                </div>
                                <div>${formattedCurrency(item.value_change)}</div>
                            </div>
                            ${item.benchmarks ? 
                                `<div style="margin-right: 10px;text-align: center;">
                                <div class="mycaption">Govt Average</div>
                                <div style="position: relative; display: inline-block;">
                                    <div class="benchmark-progress-circle" style="--value: ${item.benchmarks.benchmark_first_amendment_percentage};"></div>
                                    <strong>${item.benchmarks.benchmark_first_amendment_percentage}%</strong>
                                </div>
                                <div>${formattedCurrency(item.benchmarks.benchmark_first_amendment_actual_value)}</div>
                                </div>
                                ${item.benchmarks.publisher && item.benchmarks.publisher.benchmark_first_amendment_percentage ? 
                                    `<div style="margin-right: 10px;text-align: center;">
                                    <div class="mycaption">Buyer Average</div>
                                    <div style="position: relative; display: inline-block;">
                                    <div class="benchmark-progress-circle" style="--value: ${item.benchmarks.publisher.benchmark_first_amendment_percentage};"></div>
                                    <strong>${item.benchmarks.publisher.benchmark_first_amendment_percentage}%</strong>
                                    </div>
                                    <div>${formattedCurrency(item.benchmarks.publisher.benchmark_first_amendment_actual_value)}</div>
                                    </div>` 
                                : ''}
                                ${item.benchmarks.category && item.benchmarks.category.benchmark_first_amendment_percentage ? 
                                    `<div style="text-align: center;">
                                    <div class="mycaption">Category Average</div>
                                    <div style="position: relative; display: inline-block;">
                                    <div class="benchmark-progress-circle" style="--value: ${item.benchmarks.category.benchmark_first_amendment_percentage};"></div>
                                    <strong>${item.benchmarks.category.benchmark_first_amendment_percentage}%</strong>
                                    </div>
                                    <div>${formattedCurrency(item.benchmarks.category.benchmark_first_amendment_actual_value)}</div>
                                    </div>` 
                                : ''}
                            ` 
                            : ''}
                            </div>
                        </div>` 
                        : ''}
                    </div>
                    <div>
                        ${item.percent_complete ? 
                        `<div style="margin-top: 10px;">
                            <div style="margin-bottom: 10px;">Timeframe Analysis</div>
                            <div style="display: flex; flex-wrap: wrap;">
                            <div style="margin-right: 10px; text-align: center;">
                                <div class="mycaption">Elapsed Time</div>
                                <div style="position: relative; display: inline-block;">
                                    <div class="progress-circle" style="--value: ${item.percent_complete};"></div>
                                    <strong>${item.percent_complete}%</strong>
                                </div>
                                <div>${item.days_since_commencement} days</div>
                            </div>
                            ${item.benchmarks ? 
                                `<div style="margin-right: 10px;text-align: center;">
                                <div class="mycaption">Govt Average</div>
                                <div style="position: relative; display: inline-block;">
                                    <div class="benchmark-progress-circle" style="--value: ${item.benchmarks.benchmark_percent_complete};"></div>
                                    <strong>${item.benchmarks.benchmark_percent_complete}%</strong>
                                </div>
                                <div>${item.benchmarks.benchmark_duration_to_first_amendment} days</div>
                                </div>
                                ${item.benchmarks.publisher && item.benchmarks.publisher.benchmark_percent_complete ? 
                                    `<div style="margin-right: 10px;text-align: center;">
                                        <div class="mycaption">Buyer Average</div>
                                        <div style="position: relative; display: inline-block;">
                                        <div class="benchmark-progress-circle" style="--value: ${item.benchmarks.publisher.benchmark_percent_complete};"></div>
                                        <strong>${item.benchmarks.publisher.benchmark_percent_complete}%</strong>
                                        </div>
                                        <div>${item.benchmarks.publisher.benchmark_duration_to_first_amendment} days</div>
                                    </div>` 
                                : ''}
                                ${item.benchmarks.category && item.benchmarks.category.benchmark_percent_complete ? 
                                    `<div style="text-align: center;">
                                        <div class="mycaption">Category Average</div>
                                        <div style="position: relative; display: inline-block;">
                                        <div class="benchmark-progress-circle" style="--value: ${item.benchmarks.category.benchmark_percent_complete};"></div>
                                        <strong>${item.benchmarks.category.benchmark_percent_complete}%</strong>
                                        </div>
                                        <div>${item.benchmarks.category.benchmark_duration_to_first_amendment} days</div>
                                    </div>` 
                                : ''}
                            ` 
                            : ''}
                            </div>
                        </div>` 
                        : ''}
                    </div>
                    </div>
                </div>
                `;
              return tooltipHtml;


            }
          },
          series: [
            {
              type: 'tree',
              expandAndCollapse: true,
              initialTreeDepth: 2,
              data: [this.formattedHierarchy], // Use the formattedHierarchy data directly
              leaves: {
                label: {
                  padding: [5, 5, 5, 5],
                  overflow: "truncate"
                }
              },
              label: {
                overflow: "truncate",
                
                formatter: function (params) {
                    ////console.log(params)
                    function formattedCurrency (amount) {
                            if (!isNaN(amount)) {
                                return (
                                    "$" +
                                    Math.round(amount)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                );
                            }
                            else{
                                return ''
                            }
                        }

                    if (params.data.amendment_reason){
                        return [
                            [`{dispo|${params.data.disposition}}`],
                            [`{header|${params.data.id} (${formattedCurrency(params.data.amendment_value)})}`],
                            [`{text|${params.data.amendment_reason}}`],
                            [`{text|${params.data.title}}`],
                            [`{date|${params.data.contract_start}}`]
                        ].join('\n'); 
                    }
                    /* else if (params.data.published_date){
                        return [
                        [`{header|${params.data.id}}`],
                        [`{text|${params.data.title}}`],
                        [`{date|${params.data.published_date}}`]
                        ].join('\n');
                    } */
                    else{
                        return [
                            [`{dispo|${params.data.disposition}}`],  
                            [`{header|${params.data.id} (${formattedCurrency(params.data.value)})}`],
                            [`{text|${params.data.title}}`],
                            [`{date|${params.data.contract_start}}`]
                        ].join('\n');
                    }
                    
                    },
                    rich: {
                        dispo: {
                            align: 'left',
                            fontSize: 9,
                            color: '#12253a'
                        },
                        header: {
                            align: 'left',
                            fontSize: 12,
                            fontWeight: 'bold',
                            color: '#12253a'
                        },
                        text: {
                            align: 'left',
                            fontSize: 10,
                            color: '#12253a'
                        },
                        date: {
                            align: 'left',
                            fontSize: 10,
                            color: '#12253a'
                        }
                    }
              },
              roam: true
            }
          ]
        };

        chart.setOption(options);

        // Ensure that the chart is responsive and updated on resize
        window.addEventListener('resize', () => {
          chart.resize();
        });
      } else {

        true;
        
      }
    },
  },
  watch: {
    changed(val) {
      this.fetchHierarchy(); // Fetch hierarchy whenever the `changed` prop changes
    }
    
  }
};
</script>

<style>
.mycaption {
  font-size: 12px;         /* Smaller text size */
  font-weight: 400;        /* Normal weight */
  color: rgba(0, 0, 0, 0.54); /* Muted black with 54% opacity */
  line-height: 1.5;        /* Slightly increased line-height for readability */
  font-family: "Roboto", "Helvetica", "Arial", sans-serif; /* Default Vuetify font stack */
}
  .progress-circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: conic-gradient(
    #12253a calc(var(--value) * 1%),
    #ddd calc(var(--value) * 1%)
  );
  position: relative;
  text-align: center;
  line-height: 100px;
}

.benchmark-progress-circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: conic-gradient(
    #ff1463 calc(var(--value) * 1%),
    #ddd calc(var(--value) * 1%)
  );
  position: relative;
  text-align: center;
  line-height: 100px;
}
  .progress-circle::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    height: 80%;
    border-radius: 50%;
    background: #fff;
    transform: translate(-50%, -50%);
  }

  .benchmark-progress-circle::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    height: 80%;
    border-radius: 50%;
    background: #fff;
    transform: translate(-50%, -50%);
  }

  .tooltip-container {
  max-width: 500px;       /* Set the maximum width for the tooltip */
  padding: 0px;          /* Add some padding for better readability */
  /*background-color: #fff; /* Background color for the tooltip */
  border-radius: 5px;     /* Rounded corners */
  word-wrap: break-word;  /* Ensures long words break and wrap */
  overflow: hidden;       /* Ensure content does not overflow */
  text-overflow: ellipsis; /* If necessary, adds ellipsis for overflowing text */
  white-space: normal;    /* Allows text to wrap within the max-width */
}

.tooltip-container a {
  color: #007bff;
  text-decoration: none;
  font-size: 14px;
}

.tooltip-container a:hover {
  text-decoration: underline;
}

.tooltip-container:hover {
  opacity: 1; /* Keep tooltip visible on hover */
  cursor: pointer;
}
</style>

