<template>
    <v-card v-if="loaded == true" tile class="pb-2">
        <v-card-title style="color:#12253a;" class="font-weight-medium subtitle-1"
        ><span>
            <v-card-subtitle class="pa-0 font-weight-normal">Supplier</v-card-subtitle>
            <span>
                <router-link
                    :to="{
                            name: 'supplier',
                            params: { id: entity.id }
                        }"
                >
                    {{ entity.name }}
                </router-link>
            </span>
            <span v-if="entity.published_name && entity.published_name != entity.name"></span>
        </span>
        <div class="flex-grow-1 desktop-nav"></div>
        <span>
            <v-tooltip top dark color="#000000" style="opacity: 0.7;">
            <template v-slot:activator="{ on }">
                <v-btn
                v-on="on"
                large
                icon
                color="#4EB888"
                light
                style="text-decoration: none !important;"
                :to="{
                        name: 'supplier',
                        params: { id: entity.id }
                    }"
                >
                <v-icon>mdi-open-in-new</v-icon>
                </v-btn>
            </template>
            <span>Open supplier profile</span>
            </v-tooltip>
        </span>
        <span>
            <v-tooltip top dark color="#000000" style="opacity: 0.7;">
            <template v-slot:activator="{ on }">
                <v-btn
                v-on="on"
                large
                icon
                color="#4EB888"
                light
                style="text-decoration: none !important;"
                :to="{ name: 'search', query: { supplier_id: entity.id } }"
                >
                <v-icon>mdi-format-list-bulleted</v-icon>
                </v-btn>
            </template>
            <span>View all {{ entity.name }} awards</span>
            </v-tooltip>
        </span>
        
        </v-card-title
        >
        <v-card-subtitle class="pb-1">
            <div
              v-if="entity.company_number"
              style="color:#12253a;"
              class="font-weight-strong"
            >
              <b>ABN</b> {{ entity.company_number }}
            </div>
            <span v-if="entity.metadata">
              <span v-for="e in entity.metadata.industry" :key="e">
                <router-link
                            :to="{ name: 'industry', query: { supplier_industry: e } }"
                          >
                    <v-chip
                        class="my-1 ml-0 mr-2"
                        color="secondary"
                        label
                        text-color="secondary"
                        small
                        outlined
                  >
                    <v-icon small left>
                      mdi-label
                    </v-icon>
                    {{e}}
                  </v-chip>
                </router-link>
                </span>
            </span>
            <span v-for="clstr in entity.clusters" :key="clstr.id">
              <router-link
                            :to="{ name: 'cluster', params: { clusterId: clstr.id } }"
                          >
                    <v-chip v-if="clstr"
                      class="my-1 ml-0 mr-2"
                      color="primary"
                      label
                      text-color="primary"
                      small
                      outlined
                    >     
                    <v-icon small left>
                      mdi-hexagon-multiple
                    </v-icon>
                    {{clstr.name}}
                    </v-chip>
              </router-link>
            </span>
            <div
              v-if="entity.metadata && entity.metadata.competitors && entity.metadata.competitors.length > 0"
              class="font-weight-normal subtitle-2 pt-3 pb-0"
            >
              <a @click="similar_dialog = true"
                >Similar companies or competitors<v-icon color="#41a7d9" class="pl-0 ml-0"
                  >mdi-menu-right</v-icon
                ></a
              >
            </div>
          </v-card-subtitle>
        <v-row class="ma-0 pa-0">
        <v-divider></v-divider>
        <v-col cols="12" md="4" class="ma-0 pa-0">
            <v-card flat>
            <v-card-text class="pt-2 pb-0 font-weight-normal" >{{all_time_header}}</v-card-text>
            <v-divider class="mx-3"></v-divider>
            <v-row class="ma-0 pa-0">
                
                <v-col cols="4" class="ma-0 pa-0">
                <v-card tile flat class="py-0 my-0">
                    <v-card-title
                    class="font-weight-normal body-2"
                    style="color:#12253a;"
                    >Value</v-card-title
                    >
                    <v-card-subtitle>
                    <div class="font-weight-medium title" style="color:#12253a;">
                        {{ formattedCurrency(entity.award_total) }}
                    </div>
                    <div class="font-weight-normal body-2">
                        <router-link
                        :to="{ name: 'search', query: { supplier_id: entity.id, publisher_id: publisher_id } }"
                        >
                        {{ entity.num_notices
                        }}<span v-if="entity.num_notices == 10000">+</span>
                        awards</router-link
                        >
                    </div>
                    </v-card-subtitle>
                </v-card>
                </v-col>
            
                <v-col cols="4"  class="ma-0 pa-0">
                <v-card tile flat class="py-0 my-0">
                    <v-card-title
                    class="font-weight-normal body-2"
                    style="color:#12253a;"
                    >Avg Per Year</v-card-title
                    >
                    <v-card-subtitle>
                    <div class="font-weight-medium title" style="color:#12253a;">
                        {{ formattedCurrency(entity.award_total / entity.awards_by_year.length) }}
                    </div>
                    <div class="font-weight-normal body-2" style="color:#12253a;">
                        Over {{entity.awards_by_year.length}} years
                    </div>
                    </v-card-subtitle>
                </v-card>
                </v-col>

                <v-col cols="4"  class="ma-0 pa-0">
                <v-card tile flat class="py-0 my-0">
                    <v-card-title
                    class="font-weight-normal body-2"
                    style="color:#12253a;"
                    >Avg Contract</v-card-title
                    >
                    <v-card-subtitle>
                    <div class="font-weight-medium title" style="color:#12253a;">
                        {{ formattedCurrency(entity.award_total / entity.num_notices) }}
                    </div>
                    <div class="font-weight-normal body-2" style="color:#12253a;">
                        All contracts
                    </div>
                    </v-card-subtitle>
                </v-card>
                </v-col>
            </v-row>
            </v-card>
        </v-col>
        <v-col cols="12" md="4" class="ma-0 pa-0">
            <v-card flat>
            <v-card-text class="pt-2 pb-0 font-weight-normal">{{awards_last_year_header}}</v-card-text>
            <v-divider class="mx-3"></v-divider>
            <v-row class="ma-0 pa-0">
            <v-col cols="4"  class="ma-0 pa-0">
                <v-card tile flat class="py-0 my-0">
                <v-card-title
                    class="font-weight-normal body-2"
                    style="color:#12253a;"
                    >Value</v-card-title 
                >
                <v-card-subtitle>
                    <div class="font-weight-medium title" style="color:#12253a;">
                    {{ formattedCurrency(entity.growth.current_year_total) }}
                    </div>
                    <div class="font-weight-normal body-2" style="color:#12253a;">
                    <v-icon :color="growth_format(entity.growth.spend_change_percent).color">
                        {{growth_format(entity.growth.spend_change_percent).icontype}}
                    </v-icon>
                    {{ growth_format(entity.growth.spend_change_percent).change }}%
                    </div>
                </v-card-subtitle>
                </v-card>
            </v-col>
            <v-col cols="4"  class="ma-0 pa-0">
                <v-card tile flat class="py-0 my-0">
                <v-card-title
                    class="font-weight-normal body-2"
                    style="color:#12253a;"
                    >Avg Contract</v-card-title 
                >
                <v-card-subtitle>
                    <div class="font-weight-medium title" style="color:#12253a;">
                    {{ formattedCurrency(entity.growth.curr_yr_avg_contract) }}
                    </div>
                    <div class="font-weight-normal body-2" style="color:#12253a;">
                    <v-icon :color="growth_format(entity.growth.avg_change_percent).color">
                        {{growth_format(entity.growth.avg_change_percent).icontype}}
                    </v-icon>
                    {{ growth_format(entity.growth.avg_change_percent).change }}%
                    </div>
                </v-card-subtitle>
                </v-card>
            </v-col> 
            <v-col cols="4"  class="ma-0 pa-0">
                <v-card tile flat class="py-0 my-0">
                <v-card-title
                    class="font-weight-normal body-2"
                    style="color:#12253a;"
                    >Contracts</v-card-title 
                >
                <v-card-subtitle>
                    <div class="font-weight-medium title" style="color:#12253a;">
                    <router-link
                        :to="{
                            name: 'search',
                            query: {
                            supplier_id: entity.id,
                            publisher_id: publisher_id,
                            disposition: ['Contract Notice','Grant'],
                            contract_start_from: new Date().toISOString().split('T')[0],
                            contract_start_to: new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate()).toISOString().split('T')[0],
                            sort_order: 'asc'
                            }
                        }"
                        >
                        {{ numFormatter(entity.growth.current_year_vol) }}
                    </router-link>
                    </div>
                    <div class="font-weight-normal body-2" style="color:#12253a;">
                    <v-icon :color="growth_format(entity.growth.vol_change_percent).color">
                        {{ growth_format(entity.growth.vol_change_percent).icontype }}
                    </v-icon>
                    {{ growth_format(entity.growth.vol_change_percent).change }}%
                    </div>
                </v-card-subtitle>
                </v-card>
            </v-col>
            </v-row>
            </v-card>
        </v-col>
        <v-col cols="12" md="4" class="ma-0 pa-0">
            <v-card flat>
            <v-card-text class="pt-2 pb-0 font-weight-normal">{{expiry_next_year_header}}</v-card-text>
            <v-divider class="mx-3"></v-divider>
            <v-row class="ma-0 pa-0">
            <v-col cols="4"  class="ma-0 pa-0">
                <v-card tile flat class="py-0 my-0">
                <v-card-title
                    class="font-weight-normal body-2"
                    style="color:#12253a;"
                    >Value</v-card-title 
                >
                <v-card-subtitle>
                    <div class="font-weight-medium title" style="color:#12253a;">
                    {{ formattedCurrency(entity.expiring_soon.next_year_total) }}
                    </div>
                    <div class="font-weight-normal body-2" style="color:#12253a;">
                    <v-icon :color="growth_format(entity.expiring_soon.spend_change_percent).color">
                        {{growth_format(entity.expiring_soon.spend_change_percent).icontype}}
                    </v-icon>
                    {{ growth_format(entity.expiring_soon.spend_change_percent).change }}%
                    </div>
                </v-card-subtitle>
                </v-card>
            </v-col>
            <v-col cols="4"  class="ma-0 pa-0">
                <v-card tile flat class="py-0 my-0">
                <v-card-title
                    class="font-weight-normal body-2"
                    style="color:#12253a;"
                    >Avg Contract</v-card-title 
                >
                <v-card-subtitle>
                    <div class="font-weight-medium title" style="color:#12253a;">
                    {{ formattedCurrency(entity.expiring_soon.next_yr_avg_contract) }}
                    </div>
                    <div class="font-weight-normal body-2" style="color:#12253a;">
                    <v-icon :color="growth_format(entity.expiring_soon.avg_change_percent).color">
                        {{growth_format(entity.expiring_soon.avg_change_percent).icontype}}
                    </v-icon>
                    {{ growth_format(entity.expiring_soon.avg_change_percent).change }}%
                    </div>
                </v-card-subtitle>
                </v-card>
            </v-col> 
            <v-col cols="4"  class="ma-0 pa-0">
                <v-card tile flat class="py-0 my-0">
                <v-card-title
                    class="font-weight-normal body-2"
                    style="color:#12253a;"
                    >Contracts</v-card-title 
                >
                <v-card-subtitle>
                    <div class="font-weight-medium title" style="color:#12253a;">
                    <router-link
                        :to="{
                            name: 'search',
                            query: {
                            supplier_id: entity.id,
                            publisher_id: publisher_id,
                            disposition: ['Contract Notice','Grant'],
                            contract_end_from: new Date().toISOString().split('T')[0],
                            contract_end_to: new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate()).toISOString().split('T')[0],
                            sort_order: 'asc'
                            }
                        }"
                        >
                        {{ numFormatter(entity.expiring_soon.next_year_vol) }}
                    </router-link>
                    
                    </div>
                    <div class="font-weight-normal body-2" style="color:#12253a;">
                    <v-icon :color="growth_format(entity.expiring_soon.vol_change_percent).color">
                        {{ growth_format(entity.expiring_soon.vol_change_percent).icontype }}
                    </v-icon>
                    {{ growth_format(entity.expiring_soon.vol_change_percent).change }}%
                    </div>
                </v-card-subtitle>
                </v-card>
            </v-col>
            </v-row>
            </v-card>
        </v-col>
        </v-row>
        <v-dialog
      v-model="similar_dialog"
      max-width="500px"
      max-height="400px"
      hide-overlay
    >
      <v-card>
        <v-card-title class="subtitle-1 grey lighten-2">
          Similar companies to {{ entity.name }}
        </v-card-title>

        <v-card-text class="py-0">
          <v-list three-line>
            <v-list-item
              v-for="n in entity.metadata.competitors"
              :key="n.name"
              class="py-0"
            >
              <v-list-item-content>
                <v-list-item-title class="my-0 py-0"
                  >{{ n.name }}
                  <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                  <template v-slot:activator="{ on }">
                    <router-link target="_blank" :to="{ name: 'search', query: { supplier: n.name, viewIndex:0 } }" v-on="on">
                      <v-btn right icon>
                        <v-icon medium color="secondary">mdi-magnify</v-icon>
                      </v-btn>
                    </router-link>
                  </template>
                  <span>Search this term</span>
                </v-tooltip>
                  </v-list-item-title
                >
                <v-list-item-subtitle v-if="n.num_notices > 0" class="my-0 py-0">
                  <b>{{ n.num_notices }}</b> awards worth
                  <b>{{
                    formattedCurrency(n.award_total)
                  }}</b></v-list-item-subtitle
                >
                <v-list-item-subtitle v-else class="my-0 py-0">
                  <b>0 direct awards found</b></v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="danger" text @click="similar_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-card>
</template>
<script>
import { growth_format, numFormatter, formattedCurrency, govAvatarColor } from "../Utils.js";
export default {
    props: {
    entity: {},
    category_id: Number,
    supplier_id: Number,
    publisher_id: Number,
    loading_stats: Boolean,
    loaded: Boolean,
    all_time_header: String,
    awards_last_year_header: String,
    expiry_next_year_header: String

  },

  data() {
    return {
      similar_dialog: false
    };
  },

  mounted(){
  },

  methods: {
    growth_format,
    numFormatter,
    formattedCurrency,
    govAvatarColor,

    disableIcon(url) {
      if (!url) {
        return true;
      } else {
        return false;
      }
    },
  }
}
</script>