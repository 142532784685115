<template>
  <div>
    <v-row class="mx-auto px-0 py-0" style="background-color:#f5f5f5"
      ><v-col cols="12" class="px-0 pb-0 pt-2">
        <v-card tile>
          <v-card-title style="color:#12253a;"
            >
            <span>
              <v-card-subtitle color="secondary" class="pa-0 font-weight-light">Category</v-card-subtitle>
              <v-list-item v-if="category['parent'] && category.parent.name != null" two-line class="pl-0 pt-1">
                  <v-list-item-content class="pt-0">
                    <v-list-item-title class="pt-0"
                      >
                      <span class="body-2 font-weight-normal"
                        >{{ category.parent.code }} :
                        <router-link :to="{ name: 'category', params: { id: category.parent.id }}" class="body-2 font-weight-normal">{{ category.parent.name }}</router-link></span
                      ></v-list-item-title
                    >
                    <v-list-item
                      class="category-indent"
                      style="min-height:30px !important;"
                    >
                      <span class="subtitle"
                        >{{ category.code }} :
                        {{ category.name }}</span
                      >
                    </v-list-item>
                  </v-list-item-content>
                </v-list-item>
                <span v-else>{{ category.code }} : {{ category.name }}</span> 
            </span>
            <div class="flex-grow-1 desktop-nav"></div>
            <span>
              <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-if="is_major_category"
                    v-on="on"
                    large
                    icon
                    color="#4EB888"
                    light
                    style="text-decoration: none !important;"
                    :to="{ name: 'search', query: { major_category_id: category.id } }"
                  >
                    <v-icon>mdi-format-list-bulleted</v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    v-on="on"
                    large
                    icon
                    color="#4EB888"
                    light
                    style="text-decoration: none !important;"
                    :to="{ name: 'search', query: { category_id: category.id } }"
                  >
                    <v-icon>mdi-format-list-bulleted</v-icon>
                  </v-btn>
                </template>
                <span>View all {{ category.name }} awards</span>
              </v-tooltip>
            </span>
            </v-card-title
          >
          <v-row v-if="loading_stats == true" class="ma-0 pa-0">
            <v-col cols="12" class="ma-0 pa-0">
              <v-card tile style="color:rgb(78, 184, 136);">
                <v-skeleton-loader
                  class="py-2"
                  type="table-thead"
                ></v-skeleton-loader>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-else class="ma-0 pa-0">
            <v-divider></v-divider>
            <v-col cols="4" class="ma-0 pa-0">
              <v-card flat>
                <v-card-text class="pt-2 pb-0 font-weight-normal" >Awards - All Time</v-card-text>
                <v-divider class="mx-3"></v-divider>
                <v-row class="ma-0 pa-0">
                  
                  <v-col cols="4" class="ma-0 pa-0">
                    <v-card tile flat class="py-0 my-0">
                      <v-card-title
                        class="font-weight-normal body-2"
                        style="color:#12253a;"
                        >Value</v-card-title
                      >
                      <v-card-subtitle>
                        <div class="font-weight-medium headline" style="color:#12253a;">
                          {{ formattedCurrency(category.award_total) }}
                        </div>
                        <div class="font-weight-normal body-2">
                          <router-link
                            v-if="is_major_category"
                            :to="{ name: 'search', query: { major_category_id: category.id } }"
                          >
                            {{ category.num_notices
                            }}<span v-if="category.num_notices == 10000">+</span>
                            awards</router-link
                          >
                          <router-link
                            v-else
                            :to="{ name: 'search', query: { category_id: category.id } }"
                          >
                            {{ category.num_notices
                            }}<span v-if="category.num_notices == 10000">+</span>
                            awards</router-link
                          >
                        </div>
                      </v-card-subtitle>
                    </v-card>
                  </v-col>
                
                  <v-col cols="4"  class="ma-0 pa-0">
                    <v-card tile flat class="py-0 my-0">
                      <v-card-title
                        class="font-weight-normal body-2"
                        style="color:#12253a;"
                        >Avg Per Year</v-card-title
                      >
                      <v-card-subtitle>
                        <div class="font-weight-medium headline" style="color:#12253a;">
                          {{ formattedCurrency(category.award_total / date_aggs.length) }}
                        </div>
                        <div class="font-weight-normal body-2" style="color:#12253a;">
                          Over {{date_aggs.length}} years
                        </div>
                      </v-card-subtitle>
                    </v-card>
                  </v-col>

                  <v-col cols="4"  class="ma-0 pa-0">
                    <v-card tile flat class="py-0 my-0">
                      <v-card-title
                        class="font-weight-normal body-2"
                        style="color:#12253a;"
                        >Avg Contract</v-card-title
                      >
                      <v-card-subtitle>
                        <div class="font-weight-medium headline" style="color:#12253a;">
                          {{ formattedCurrency(avg_contract) }}
                        </div>
                        <div class="font-weight-normal body-2" style="color:#12253a;">
                          All contracts
                        </div>
                      </v-card-subtitle>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="4" class="ma-0 pa-0">
              <v-card flat>
                <v-card-text class="pt-2 pb-0 font-weight-normal">Awards - Last 12 Months</v-card-text>
                <v-divider class="mx-3"></v-divider>
                <v-row class="ma-0 pa-0">
                <v-col cols="4"  class="ma-0 pa-0">
                  <v-card tile flat class="py-0 my-0">
                    <v-card-title
                      class="font-weight-normal body-2"
                      style="color:#12253a;"
                      >Value</v-card-title 
                    >
                    <v-card-subtitle>
                      <div class="font-weight-medium headline" style="color:#12253a;">
                        {{ formattedCurrency(category.growth.current_year_total) }}
                      </div>
                      <div class="font-weight-normal body-2" style="color:#12253a;">
                        <v-icon :color="growth_format(category.growth.spend_change_percent).color">
                          {{growth_format(category.growth.spend_change_percent).icontype}}
                        </v-icon>
                        {{ growth_format(category.growth.spend_change_percent).change }}%
                      </div>
                    </v-card-subtitle>
                  </v-card>
                </v-col>
                <v-col cols="4"  class="ma-0 pa-0">
                  <v-card tile flat class="py-0 my-0">
                    <v-card-title
                      class="font-weight-normal body-2"
                        style="color:#12253a;"
                      >Avg Contract</v-card-title 
                    >
                    <v-card-subtitle>
                      <div class="font-weight-medium headline" style="color:#12253a;">
                        {{ formattedCurrency(category.growth.curr_yr_avg_contract) }}
                      </div>
                      <div class="font-weight-normal body-2" style="color:#12253a;">
                        <v-icon :color="growth_format(category.growth.avg_change_percent).color">
                          {{growth_format(category.growth.avg_change_percent).icontype}}
                        </v-icon>
                        {{ growth_format(category.growth.avg_change_percent).change }}%
                      </div>
                    </v-card-subtitle>
                  </v-card>
                </v-col> 
                <v-col cols="4"  class="ma-0 pa-0">
                  <v-card tile flat class="py-0 my-0">
                    <v-card-title
                      class="font-weight-normal body-2"
                        style="color:#12253a;"
                      >Contracts</v-card-title 
                    >
                    <v-card-subtitle>
                      <div class="font-weight-medium headline" style="color:#12253a;">
                        <router-link v-if="is_major_category"
                           :to="{
                              name: 'search',
                              query: {
                                major_category_id: category.id,
                                disposition: ['Contract Notice','Grant'],
                                contract_start_from: new Date().toISOString().split('T')[0],
                                contract_start_to: new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate()).toISOString().split('T')[0],
                                sort_order: 'asc'
                              }
                            }"
                          >
                            {{ numFormatter(category.growth.current_year_vol) }}
                        </router-link>
                        <router-link v-else
                           :to="{
                              name: 'search',
                              query: {
                                major_category_id: category.id,
                                disposition: ['Contract Notice','Grant'],
                                contract_start_from: new Date().toISOString().split('T')[0],
                                contract_start_to: new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate()).toISOString().split('T')[0],
                                sort_order: 'asc'
                              }
                            }"
                          >
                            {{ numFormatter(category.growth.current_year_vol) }}
                        </router-link>
                      </div>
                      <div class="font-weight-normal body-2" style="color:#12253a;">
                        <v-icon :color="growth_format(category.growth.vol_change_percent).color">
                          {{ growth_format(category.growth.vol_change_percent).icontype }}
                        </v-icon>
                        {{ growth_format(category.growth.vol_change_percent).change }}%
                      </div>
                    </v-card-subtitle>
                  </v-card>
                </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="4" class="ma-0 pa-0">
              <v-card flat>
                <v-card-text class="pt-2 pb-0 font-weight-normal">Expiring - Next 12 Months</v-card-text>
                <v-divider class="mx-3"></v-divider>
                <v-row class="ma-0 pa-0">
                <v-col cols="4"  class="ma-0 pa-0">
                  <v-card tile flat class="py-0 my-0">
                    <v-card-title
                      class="font-weight-normal body-2"
                        style="color:#12253a;"
                      >Value</v-card-title 
                    >
                    <v-card-subtitle>
                      <div class="font-weight-medium headline" style="color:#12253a;">
                        {{ formattedCurrency(category.expiring_soon.next_year_total) }}
                      </div>
                      <div class="font-weight-normal body-2" style="color:#12253a;">
                        <v-icon :color="growth_format(category.expiring_soon.spend_change_percent).color">
                          {{growth_format(category.expiring_soon.spend_change_percent).icontype}}
                        </v-icon>
                        {{ growth_format(category.expiring_soon.spend_change_percent).change }}%
                      </div>
                    </v-card-subtitle>
                  </v-card>
                </v-col>
                <v-col cols="4"  class="ma-0 pa-0">
                  <v-card tile flat class="py-0 my-0">
                    <v-card-title
                      class="font-weight-normal body-2"
                      style="color:#12253a;"
                      >Avg Contract</v-card-title 
                    >
                    <v-card-subtitle>
                      <div class="font-weight-medium headline" style="color:#12253a;">
                        {{ formattedCurrency(category.expiring_soon.next_yr_avg_contract) }}
                      </div>
                      <div class="font-weight-normal body-2" style="color:#12253a;">
                        <v-icon :color="growth_format(category.expiring_soon.avg_change_percent).color">
                          {{growth_format(category.expiring_soon.avg_change_percent).icontype}}
                        </v-icon>
                        {{ growth_format(category.expiring_soon.avg_change_percent).change }}%
                      </div>
                    </v-card-subtitle>
                  </v-card>
                </v-col> 
                <v-col cols="4"  class="ma-0 pa-0">
                  <v-card tile flat class="py-0 my-0">
                    <v-card-title
                      class="font-weight-normal body-2"
                        style="color:#12253a;"
                      >Contracts</v-card-title 
                    >
                    <v-card-subtitle>
                      <div class="font-weight-medium headline" style="color:#12253a;">
                        <router-link v-if="is_major_category"
                           :to="{
                              name: 'search',
                              query: {
                                major_category_id: category.id,
                                disposition: ['Contract Notice','Grant'],
                                contract_end_from: new Date().toISOString().split('T')[0],
                                contract_end_to: new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate()).toISOString().split('T')[0],
                                sort_order: 'asc'
                              }
                            }"
                          >
                            {{ numFormatter(category.expiring_soon.next_year_vol) }}
                        </router-link>
                        <router-link v-else
                           :to="{
                              name: 'search',
                              query: {
                                major_category_id: category.id,
                                disposition: ['Contract Notice','Grant'],
                                contract_end_from: new Date().toISOString().split('T')[0],
                                contract_end_to: new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate()).toISOString().split('T')[0],
                                sort_order: 'asc'
                              }
                            }"
                          >
                            {{ numFormatter(category.expiring_soon.next_year_vol) }}
                        </router-link>
                      </div>
                      <div class="font-weight-normal body-2" style="color:#12253a;">
                        <v-icon :color="growth_format(category.expiring_soon.vol_change_percent).color">
                          {{ growth_format(category.expiring_soon.vol_change_percent).icontype }}
                        </v-icon>
                        {{ growth_format(category.expiring_soon.vol_change_percent).change }}%
                      </div>
                    </v-card-subtitle>
                  </v-card>
                </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-tabs class="pt-4 pb-1" color="rgb(18, 37, 58)" show-arrows>
      <v-tabs-slider color="rgb(255, 20, 99)"></v-tabs-slider>
      <v-tab>
        Analysis
      </v-tab>
      <v-tab>
        Recent Awards
      </v-tab>
      <v-tab>
        Suppliers
      </v-tab>
      <v-tab>
        Related Categories
      </v-tab>
      <v-tab-item>
        <v-row class="py-3">
          <v-col
            cols="12"
            sm="7"
            class="pl-md-3 pr-md-1 py-md-1 px-xs-0 py-xs-0"
          >
            <v-card tile flat>
              <spending-timeline
                :date_aggs="date_aggs"
                :entity_aggs="publisher_aggs"
                :expiry_aggs="expiry_aggs"
                :contracting_activity="contracting_activity"
                :expiring_activity="expiring_activity"
                :annual_spend="annual_spend"
                :entity_type="'publisher'"
                :metric_name="'total_amount'"
                :units="'Amount'"
                :title="'Awards Over Time - Top 10 Buyers'"
                :category_id="category.id"
              />
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="3"
            class="pl-md-1 pr-md-1 py-md-1 px-xs-0 py-xs-0"
          >
            <v-card tile flat>
              <graph-data-view :chartGroup="'publisher'" />
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="2"
            class="pl-md-1 pr-md-3 py-md-1 px-xs-0 py-xs-0"
          >
            <v-card tile flat>
              <spending-donut
                :entity_type="'publisher'"
                :metric_name="'total_amount'"
                :search="search_terms"
                :entity_data="publisher_aggs"
                :title="'Total By Buyer'"
              />
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="7"
            class="pl-md-3 pr-md-1 py-md-1 px-xs-0 py-xs-0"
          >
            <v-card tile flat>
              <spending-timeline
                :date_aggs="date_aggs"
                :expiry_aggs="expiry_aggs"
                :contracting_activity="contracting_activity"
                :expiring_activity="expiring_activity"
                :entity_aggs="supplier_aggs"
                :annual_spend="annual_spend"
                :entity_type="'supplier'"
                :metric_name="'total_amount'"
                :units="'Amount'"
                :title="'Awards Over Time - Top 10 Suppliers'"
                :scategory_id="category.id"
              />
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="3"
            class="pl-md-1 pr-md-1 py-md-1 px-xs-0 py-xs-0"
          >
            <v-card tile flat>
              <graph-data-view :chartGroup="'supplier'" />
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="2"
            class="pl-md-1 pr-md-3 py-md-1 px-xs-0 py-xs-0"
          >
            <v-card tile flat>
              <spending-donut
                :entity_type="'supplier'"
                :metric_name="'total_amount'"
                :search="search_terms"
                :entity_data="supplier_aggs"
                :title="'Total By Supplier'"
              />
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-row style="background-color:#f5f5f5">
          <v-col cols="12" class="pt-3">
            <results-table
              class="pb-3 pt-0 pl-0 pr-0"
              :hit_info="hit_info"
              :tenders="tenders"
              :hide_pagination="true"
            />
            <v-overlay absolute="true" :value="overlay">
              <v-progress-circular
                indeterminate
                size="64"
              ></v-progress-circular>
            </v-overlay>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-row style="background-color:#f5f5f5">
          <v-col cols="12" class="pt-3">
            <supplier-table
              :category_id="$route.params.id"
              :cat_is_child="category['parent']"
            />
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-row style="background-color:#f5f5f5">
          <v-col cols="12" class="pt-3">
            <category-table
              :category_id="$route.params.id"
            />
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import { bus } from "@/main.js";
import {
  entitySearch,
  histogramSearch,
  executeSearch
} from "../APIManager.js";
import { growth_format, numFormatter, formattedCurrency } from "../Utils.js";
import SupplierTable from "../entities/SupplierTable";
import CategoryTable from "../entities/CategoryTable";
import SpendingTimeline from "../SpendingTimeline.vue";
import ResultsTable from "../ResultsTable.vue";
import SpendingDonut from "../SpendingDonut.vue";
//import EntityHeader from "../entities/EntityHeader.vue";
//import MiniTable from "../entities/MiniTable.vue";
import GraphDataView from "../GraphDataView.vue";
import json from "../data/categories.json"

export default {
  components: {
    SpendingDonut,
    ResultsTable,
    SpendingTimeline,
    GraphDataView,
    SupplierTable,
    CategoryTable
  },
  props: {
    category: {},
    current_search: {},
    category_id: String()
    //results_drawer: Boolean
  },
  data() {
    return {
      is_major_category: Boolean,
      major_categories: json.data,
      hit_info: {},
      tenders: [""],
      related_dialog: false,
      overlay: false,
      tabs: null,
      absolute: true,
      category_panel: -9,
      category_panels: [],
      panel_clicked: false,
      //current_index: 0,
      index: Number, //use the exp panel index as key so that we can cache the data
      profiles: [],
      mounted: false,
      entity_type: "supplier",
      total_amt_metric: "total_amount",
      search: {},
      category_aggs: [],
      date_aggs: [],
      publisher_aggs: [],
      contracting_activity: [],
      expiring_activity: [],
      expiry_aggs: [],
      annual_spend: [],
      avg_contract: Number,
      loading_stats: false
    };
  },

  computed: {},

  created() {
    //this.search = this.current_search;
  },

  mounted() {
    this.overlay = true;
    this.loadProfile();

    this.is_major_category = this.major_categories.find(x => x.id === parseInt(this.category_id));

  },

  watch: {
    $route(to, from) {
      //this.reset_aggs();
      //this.loadProfile();
    },
  },

  methods: {

    growth_format,
    numFormatter,
    formattedCurrency,

    reset_aggs(){
      this.expiry_aggs = [];
      this.expiring_activity = [];
      this.date_aggs = [];
      this.contracting_activity = [];
      this.annual_spend = [];
      this.category_aggs = [];
      this.supplier_aggs = [];
    },

    loadProfile() {
      
      this.search = { category_id: this.$route.params.id, size: 1 };
      this.loadTenderAggs();

      this.loadRecentAwards();
    },

    async loadRecentAwards() {
      let results = {};

      //let labels = [];
      results = await executeSearch(
        JSON.stringify({
          category_id: this.$route.params.id,
          disposition: ['Contract Notice','Grant'],
          sort_field: "contract_start",
          size: 10
        })
      );

      ////////////console.log("TABLE RESULTS: ", results)

      this.tenders = results.data;
      this.hit_info = results.hits;
      this.overlay = false;
    },

    async loadTenderAggs() {
      this.loading_stats = true;

      try {
          const [histogramData, entityData] = await Promise.all([
              histogramSearch(this.search),
              entitySearch(this.search)
          ]);

          this.expiry_aggs = histogramData.aggregations.expiring.buckets;
          this.expiring_activity = histogramData.aggregations.expiring_activity.buckets;
          this.date_aggs = histogramData.aggregations.contracted.buckets;
          this.contracting_activity = histogramData.aggregations.contracting_activity.buckets;
          this.publish_activity = histogramData.aggregations.publish_activity.buckets;
          this.annual_spend = histogramData.aggregations.contracting_spend.buckets;
          this.avg_contract = histogramData.aggregations.avg_contract_value.value;

          this.publisher_aggs = entityData.aggregations.publisher.buckets;
          this.supplier_aggs = entityData.aggregations.supplier.buckets;
      } catch (error) {
          //console.error('Error fetching data:', error);
      } finally {
          this.loading_stats = false;
      }
    },

    /* async loadTenderAggs() {

      this.loading_stats = true;

      Promise.all(
        histogramSearch(this.search).then(data => {
          this.expiry_aggs = data.aggregations.expiring.buckets;
          this.expiring_activity = data.aggregations.expiring_activity.buckets;
          this.date_aggs = data.aggregations.contracted.buckets;
          this.contracting_activity =
            data.aggregations.contracting_activity.buckets;
          this.annual_spend = data.aggregations.contracting_spend.buckets;
          this.avg_contract = data.aggregations.avg_contract_value.value;

          this.loading_stats = false;

        }),
        entitySearch(this.search).then(data => {
          this.supplier_aggs = data.aggregations.supplier.buckets;
          this.publisher_aggs = data.aggregations.publisher.buckets;
        })
      );
    }, */


    disableIcon(url) {
      if (!url) {
        return true;
      } else {
        return false;
      }
    },

    toggle() {
      this.watching = !this.watching;
      this.is_saved();
    },

    is_saved() {
      ////////////console.log("NOTIFY STATUS: " + this.current_search.notify)
      return this.watching;
    },

    govAvatarColor(gov) {
      var color = "grey";
      switch (gov) {
        case "NSW":
          color = "#41a7d9";
          break;
        case "VIC":
          color = "#0058AC";
          break;
        case "WA":
          color = "#FFD200";
          break;
        case "QLD":
          color = "#A6003D";
          break;
        case "AUS":
          color = "#D8993B";
          break;
        case "FED":
          color = "#D8993B";
          break;
        case "DEF":
          color = "#d8673b";
          break;
        case "SA":
          color = "#ff4081";
          break;
        case "TAS":
          color = "#4eb888";
          break;
        case "ACT":
          color = "#41a7d9";
          break;
        case "NT":
          color = "#FF3647";
          break;
      }

      return color;
    }
  }
};
</script>

<style>
.category-indent {
  border-left: 3px solid #979797;
  margin-left: 10px;
}

.chart {
  border-color: #424242 !important;
}

.headingUnderline {
  border-color: aqua !important;
}
</style>
