/* eslint-disable prettier/prettier */

import * as echarts from "echarts";

function get_target_stats(bucket_data, target){
    
    for (var i = 0; i < bucket_data.length; i++){
        if (bucket_data[i].key === target){
            //////console.log("BUCKET DATA: ", bucket_data[i]);
            return bucket_data[i]
        }
    }
    return false;
}


//type = "government", "supplier", "publisher", or "category"
//target = the target govt, publisher, supplier or category. e.g. "NSW Police"
function prepEntityData(data, type, metric_name, target, line_label, colour) {
    
    var results = {labels: [], data: [], label: line_label, borderColor: colour, pointBackgroundColor: colour, pointBorderColor: colour };

    var results_counter = 0;

    for (var i = 0; i < data.length; i++) {

        var data_for_interval = get_target_stats(data[i][type].buckets, target);

        if (!data_for_interval){continue;}
        else {
            results.labels[results_counter] = data[i].key_as_string;

            //////////////console.log("TARGET: ", target, "\nYEAR: ",results.labels[results_counter], "\nDATA FOR YEAR", data_for_interval);

            if (metric_name === "doc_count" && data_for_interval.key === target){
                results.data[results_counter] = data_for_interval.doc_count;
            }
            else if (metric_name === "total_amount" && data_for_interval.key === target){
                results.data[results_counter] = Math.round(data_for_interval.total_amount.value);
            }

            results_counter++;
        } //assign the year where the enity had activity
        
    }
    
    return results;
}

export {prepEntityData}

export function prepEchartsSeries(data, type, metric_name, target, line_label, colour) {
    
    var series = {data: [], 
                    activity_data:[], 
                    name: line_label, 
                    type: "bar",
                    //stack: 'Total',
                    areaStyle: {},
                    barCategoryGap: "40%",
                    stack: 'x',
                    //lineStyle: {color: colour},
                    endLabel: {show: false, formatter: function(params){return target}},
                    labelLayout: {
                        moveOverlap: 'shiftY'
                    },
                    emphasis: {
                        focus: 'series'
                    }, };

    var results_counter = 0;
    var prev_value=0;

    for (var i = 0; i < data.length; i++) {

        var data_for_interval = get_target_stats(data[i][type].buckets, target);

        if (!data_for_interval){

            let val = {};
            
            let per = getPercentageChange(prev_value, 0);

            val["percent"] = per.direction + per.value;

            /* if (per.value != "n/a"){
                val["percent"] = per.direction + per.value;
            }else{
                val["percent"] = per.direction + per.value;
            } */

            series.data[results_counter] = {"value": 0, "netchange":0, "type":metric_name, "percent": val.percent};

            prev_value = 0;
            results_counter++;
        }
        else {
            //////////////console.log("TARGET: ", target, "\nYEAR: ",results.labels[results_counter], "\nDATA FOR YEAR", data_for_interval);

            if (metric_name === "doc_count" && data_for_interval.key === target){
                let val = {};
                val["value"] = data_for_interval.doc_count;
                val["netchange"] = data_for_interval.doc_count;
                val["type"] = metric_name;

                let per = getPercentageChange(prev_value, val.value);
                val["percent"] = per.direction + per.value;
       
                series.data[results_counter] = val;
                prev_value = series.data[results_counter].value;
            }
            else if (metric_name === "total_amount" && data_for_interval.key === target){
                let val = {};
                //////////////console.log("RAW VALUE: ", Math.round(data_for_interval.total_amount.value) + prev_value);
                val["value"] = Math.round(data_for_interval.total_amount.value);
                val["netchange"] = Math.round(data_for_interval.total_amount.value);
                val["type"] = metric_name

                let per = getPercentageChange(prev_value, val.value);

                val["percent"] = per.direction + per.value;

                series.data[results_counter] = val;
                //////////////console.log("PREV VALUE: ", prev_value, " NEW VALUE: ", val.value, " CHANGE: ", val.percent);
                prev_value = series.data[results_counter].value;

                //////////////console.log("data: ", val);
            }

            results_counter++;
        } //assign the year where the enity had activity
        
    }
    //////////////console.log("ELine SERIES : ", series);
    return series;
}

export function prepActiveContracts(data, type, metric_name, target, line_label, colour) {
    
    var series = {data: [], 
                    activity_data:[], 
                    name: line_label, 
                    type: "line",
                    //stack: 'Total',
                    /* areaStyle: {
                        opacity: 0.8,
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                          {
                            offset: 0,
                            color: colour
                          },
                          {
                            offset: 1,
                            color: colour
                          }
                        ])
                      }, */
                      areaStyle:{},
                    barCategoryGap: "40%",
                    stack: 'x',
                    //lineStyle: {color: colour},
                    endLabel: { show: false,
                        formatter: function(params){
                           /*  var colorSpan = color => '<span style="display:inline-block;margin-right:5px;border-radius:50%;width:14px;height:14px;border: 2px solid #fff;box-shadow: 0 2px 4px rgb(0 0 0 / 25%);background-color:' + color + '"></span>';
                            var result = '<div>' + colorSpan(params.color) + target + '</div>' */
                            let trunc = "";
                            if (params.seriesName.length > 20){
                                trunc = params.seriesName.substring(0, 20) + '...';
                            }
                            else{
                                trunc = params.seriesName;
                            }

                            return trunc;
                        },
                        fontSize: 10
                    },
                    labelLayout: {
                        moveOverlap: 'shiftY'
                    },
                    emphasis: {
                        focus: 'series'
                    }, };

    var results_counter = 0;
    var prev_value=0;

    for (var i = 0; i < data.length; i++) {

        var data_for_interval = get_target_stats(data[i][type].buckets, target);

        if (!data_for_interval){

            let val = {};
            
            let per = getPercentageChange(prev_value, 0);

            val["percent"] = per.direction + per.value;

            /* if (per.value != "n/a"){
                val["percent"] = per.direction + per.value;
            }else{
                val["percent"] = per.direction + per.value;
            } */

            series.data[results_counter] = {"value": 0, "netchange":0, "type":metric_name, "percent": val.percent};

            prev_value = 0;
            results_counter++;
        }
        else {
            //////////////console.log("TARGET: ", target, "\nYEAR: ",results.labels[results_counter], "\nDATA FOR YEAR", data_for_interval);

            if (metric_name === "doc_count" && data_for_interval.key === target){
                let val = {};
                val["value"] = data_for_interval.doc_count;
                val["netchange"] = data_for_interval.doc_count;
                val["type"] = metric_name;

                let per = getPercentageChange(prev_value, val.value);
                val["percent"] = per.direction + per.value;
       
                series.data[results_counter] = val;
                prev_value = series.data[results_counter].value;
            }
            else if (metric_name === "total_amount" && data_for_interval.key === target){
                let val = {};
                //////////////console.log("RAW VALUE: ", Math.round(data_for_interval.total_amount.value) + prev_value);
                val["value"] = Math.round(data_for_interval.total_amount.value);
                val["netchange"] = Math.round(data_for_interval.total_amount.value);
                val["type"] = metric_name

                let per = getPercentageChange(prev_value, val.value);

                val["percent"] = per.direction + per.value;

                series.data[results_counter] = val;
                //////////////console.log("PREV VALUE: ", prev_value, " NEW VALUE: ", val.value, " CHANGE: ", val.percent);
                prev_value = series.data[results_counter].value;

                //////////////console.log("data: ", val);
            }

            results_counter++;
        } //assign the year where the enity had activity
        
    }
    //////////////console.log("ELine SERIES : ", series);
    return series;
}


export function prepHorizontalSeries(data, metric_name, colors) {

    var colorList = [];
    
    var yAxisData = [];
    var series = {data: [], 
                    activity_data:[], 
                    //name: "Test", 
                    type: "bar",
                    /* select: {
                        disabled: false,
                        itemStyle: {
                            color:"#FF6905",
                          }
                    }, */
                    //selectedMode: "multiple",
       
                    barCategoryGap: "10%",
                    //barMaxWidth:30,
                    //barWidth: 30,
                    //barMinHeight: 60,
                    barMaxHeight: 20,
    
                    label: {
                        show: true, 
                        position: "right", 
                        //fontSize:9,
                        formatter : (s) => {
                            function convert (num){
                                if (num > 999 && num < 1000000) {
                                    num = (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
                                } else if (num >= 1000000 && num < 1000000000) {
                                    num = (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
                                } else if (num >= 1000000000) {
                                    num = (num / 1000000000).toFixed(1) + "B"; // convert to M for number from > 1 million
                                }
    
                                return num;
                            }
                            //////////////console.log(s);
                            if (metric_name == "total_amount" || metric_name == "avg_value"){
                              return '{valueStyle|' + '$'+ convert(s.value) + '}';
                            }else{
                              return '{valueStyle|' + s.value + '}';
                            }
                            
                        },
                          rich: {
                              valueStyle: {
                                  // Make yearly text more standing out
                                  //color: '#000',
                                  fontWeight: 'bold',
                                  fontSize: 10
                              }
                          }
                    },
                    labelLayout: {
                        moveOverlap: 'shiftX'
                    },
                    emphasis: {
                        focus: 'self',
                        blurScope: 'coordinateSystem'
                    }, };

    
    for (var i in data){
        //////////////console.log("DATA: ", data[i]);
        yAxisData.unshift(data[i].key);
        //colorList.unshift(colors[i])
        if (metric_name == "total_amount"){
            let d = {
                value: data[i].total_amount.value,
                itemStyle: {
                  color: data[i].color
                }
              };

            series.data.unshift(d);
        }
        else if (metric_name == "avg_value"){
            let d = {
                value: data[i].avg_value.value,
                itemStyle: {
                  color: data[i].color
                }
              };

            series.data.unshift(d);
        }
        else if (metric_name == "doc_count"){
            let d = {
                value: data[i].doc_count,
                itemStyle: {
                    color: data[i].color
                }
                };

            series.data.unshift(d);
        }

    }

    //////////////console.log("HBAR SERIES : ", series, " YAXIS: ", yAxisData);
    return {series: series, yAxisData: yAxisData};
}

function getTargetDataEntries(target, data, target_type){
    var series_data = [];
    
    for (var d in data)
    {
        var found = data[d][target_type].buckets.find(x => x.key === target.key)
        if (found){
            var v = {
                value: found.total_amount.value,
                /* itemStyle: {
                    color: target.color
                } */
            };
            series_data.unshift(v)
        }
        else{
            series_data.unshift(0)
        }
    }

    return series_data;
}

export function prepHorizontalStack(data, metric_name, target_type, colors, labels, target_list) {

    //////////////console.log(data, metric_name, target_type, colors, labels, target_list);
    
    var series = [];

    //get all values for each target
    for (var t in target_list){
        var series_obj = {
            data: getTargetDataEntries(target_list[t], data, target_type), 
            name: target_list[t].key, 
            type: "bar",
            stack: "total",
            barCategoryGap: "10%",
            //barMinWidth: 30,
            //barMaxWidth:30,
            //barWidth: 30,
            //barMinHeight: 60,
            //colorBy: "data",

            label: {
                show: false, 
                position: "right",
                
                //fontSize:9,
                formatter : (s) => {
                    function convert (num){
                        if (num > 999 && num < 1000000) {
                            num = (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
                        } else if (num >= 1000000 && num < 1000000000) {
                            num = (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
                        } else if (num >= 1000000000) {
                            num = (num / 1000000000).toFixed(1) + "B"; // convert to M for number from > 1 million
                        }

                        return num;
                    }
                    //////////////console.log(s);
                    if (metric_name == "total_amount" || metric_name == "avg_value"){
                      return '{valueStyle|' + '$'+ convert(s.value) + '}';
                    }else{
                      return '{valueStyle|' + s.value + '}';
                    }
                    
                },
                  rich: {
                      valueStyle: {
                          // Make yearly text more standing out
                          //color: '#000',
                          fontWeight: 'bold',
                          fontSize: 10
                      }
                  }
            },
            labelLayout: {
                moveOverlap: 'shiftX'
            },
            emphasis: {
                focus: 'series'
            }
        };

        series.unshift(series_obj);

    }

    //////////////console.log("HBAR SERIES : ", series, " YAXIS: ", yAxisData);
    return series;
}

export function prepEchartsSeriesMini(data, type, metric_name, target, line_label, colour) {
    
    //////////////console.log("DATA: ", data, "TYPE: ", type);
    var series = {data: [], 
                    activity_data:[], 
                    name: line_label, 
                    type: "line",
                    symbol: "none",
                    //stack: 'Total',
                    //areaStyle: {},
                    barCategoryGap: "40%",
                    //stack: 'x',
                    //lineStyle: {color: colour},
                    endLabel: {show: false, formatter: function(params){return target}},
                    labelLayout: {
                        moveOverlap: 'shiftY'
                    },
                    emphasis: {
                        focus: 'series'
                    }, };

    var results_counter = 0;
    var prev_value=0;

    for (var i = 0; i < data.length; i++) {

        var data_for_interval = get_target_stats(data[i][type].buckets, target);

        if (!data_for_interval){

            let val = {};
            
            let per = getPercentageChange(prev_value, 0);

            val["percent"] = per.direction + per.value;

            /* if (per.value != "n/a"){
                val["percent"] = per.direction + per.value;
            }else{
                val["percent"] = per.direction + per.value;
            } */

            series.data[results_counter] = {"value": 0, 
                                            "netchange":0, 
                                            "type":metric_name, 
                                            "percent": val.percent,
                                            "itemStyle": {
                                                color: colour
                                              }
                                        };

            prev_value = 0;
            results_counter++;
        }
        else {
            //////////////console.log("TARGET: ", target, "\nYEAR: ",results.labels[results_counter], "\nDATA FOR YEAR", data_for_interval);

            if (metric_name === "doc_count" && data_for_interval.key === target){
                let val = {};
                val["value"] = data_for_interval.doc_count;
                val["netchange"] = data_for_interval.doc_count;
                val["type"] = metric_name;

                let per = getPercentageChange(prev_value, val.value);
                val["percent"] = per.direction + per.value;
                val["itemStyle"] = {
                    color: colour
                  };
       
                series.data[results_counter] = val;
                prev_value = series.data[results_counter].value;
            }
            else if (metric_name === "total_amount" && data_for_interval.key === target){
                let val = {};
                //////////////console.log("RAW VALUE: ", Math.round(data_for_interval.total_amount.value) + prev_value);
                val["value"] = Math.round(data_for_interval.total_amount.value);
                val["netchange"] = Math.round(data_for_interval.total_amount.value);
                val["type"] = metric_name

                let per = getPercentageChange(prev_value, val.value);

                val["percent"] = per.direction + per.value;
                val["itemStyle"] = {
                    color: colour
                  };

                series.data[results_counter] = val;
                //////////////console.log("PREV VALUE: ", prev_value, " NEW VALUE: ", val.value, " CHANGE: ", val.percent);
                prev_value = series.data[results_counter].value;

                //////////////console.log("data: ", val);
            }

            results_counter++;
        } //assign the year where the enity had activity
        
    }
    //////////////console.log("ELine SERIES : ", series);
    return series;
}

export function prepEchartsSeriesMiniNoGroup(data, metric_name, colour) {
    //////////////console.log("DATA", data[0]);
    var series = {data: [], 
                    activity_data:[], 
                    name: 'Amount', 
                    type: "bar",
                    //stack: 'Total',
                    itemStyle: {color: "rgb(18, 37, 58)"},
                    barCategoryGap: "40%",
                    //stack: 'x',
                    lineStyle: {color: colour},
                    /* markPoint: {
                        data: [{
                          type: "max"
                        }],
                        symbol: "circle"
                      }, */
                      /* label: {
                        show: true,
                        position: "top"
                      }, */
                    /* markLine: {
                        data: [{ type: 'average', name: 'Avg' }]
                    }, */

                    labelLayout: {
                        moveOverlap: 'shiftY'
                    },
                    emphasis: {
                        focus: 'series'
                    }, };

    var results_counter = 0;
    var prev_value=0;

    for (var i in data) {

        if (metric_name === "doc_count"){
            let val = {};
            val["value"] = data[i].doc_count;
            val["netchange"] = data[i].doc_count;
            val["type"] = metric_name;

            let per = getPercentageChange(prev_value, val.value);
            val["percent"] = per.direction + per.value;
    
            series.data[results_counter] = val;
            prev_value = series.data[results_counter].value;
        }
        else if (metric_name === "total_amount"){
            let val = {};
            //////////////console.log("RAW VALUE: ", Math.round(data_for_interval.total_amount.value) + prev_value);
            val["value"] = Math.round(data[i].total_amount.value);
            val["netchange"] = Math.round(data[i].total_amount.value);
            val["type"] = metric_name

            let per = getPercentageChange(prev_value, val.value);

            val["percent"] = per.direction + per.value;

            let per_chg = ""+val["percent"]+"";
            let color = "#4CAF50";

            if (per_chg.includes("-")) color = "#F44336";

            val["label"] = {show: true, position: "top", fontSize:9, 
                formatter: per_chg, color: color};

            /* val["label"] = {show: true, position: "top", fontSize:9, 
                        formatter: function(params){
                            return params.seriesName;
                        }
                } */

            series.data[results_counter] = val;
            //////////////console.log("PREV VALUE: ", prev_value, " NEW VALUE: ", val.value, " CHANGE: ", val.percent);
            prev_value = series.data[results_counter].value;

            //////////////console.log("data: ", val);
        }

        results_counter++;

        
    }
    //////////////console.log("ELine SERIES : ", series);
    return series;
}

export function prepCumulativeSpendMini (data, type, metric_name, target, line_label, colour) {
    
    var series = {data: [], 
                    activity_data:[], 
                    name: line_label, 
                    type: "line",
                    stack: 'Total',
                    areaStyle: {},
                    //stack: 'x',
                    //lineStyle: {color: colour},
                    endLabel: { show: false,
                        formatter: function(params){
                           /*  var colorSpan = color => '<span style="display:inline-block;margin-right:5px;border-radius:50%;width:14px;height:14px;border: 2px solid #fff;box-shadow: 0 2px 4px rgb(0 0 0 / 25%);background-color:' + color + '"></span>';
                            var result = '<div>' + colorSpan(params.color) + target + '</div>' */
                            return params.seriesName;
                        }
                    },
                    labelLayout: {
                        moveOverlap: 'shiftY'
                    },
                    emphasis: {
                        focus: 'series'
                    }, };

    var results_counter = 0;
    var prev_value=0;

    for (var i = 0; i < data.length; i++) {

        var data_for_interval = get_target_stats(data[i][type].buckets, target);

        if (!data_for_interval){
            series.data[results_counter] = {"value": (0 + prev_value), "netchange":0, "type":metric_name, "percent": "n/a"};
            results_counter++;
        }
        else {
            //////////////console.log("TARGET: ", target, "\nYEAR: ",results.labels[results_counter], "\nDATA FOR YEAR", data_for_interval);

            if (metric_name === "doc_count" && data_for_interval.key === target){
                let val = {};
                val["value"] = data_for_interval.doc_count + prev_value;
                val["netchange"] = data_for_interval.doc_count;
                val["type"] = metric_name
                let per = getPercentageChange(prev_value, val.value);
                val["percent"] = per.direction + per.value;
       
                series.data[results_counter] = val;
                prev_value = series.data[results_counter].value;
            }
            else if (metric_name === "total_amount" && data_for_interval.key === target){
                let val = {};
                //////////////console.log("RAW VALUE: ", Math.round(data_for_interval.total_amount.value) + prev_value);
                val["value"] = Math.round(data_for_interval.total_amount.value) + prev_value;
                val["netchange"] = Math.round(data_for_interval.total_amount.value);
                val["type"] = metric_name

                let per = getPercentageChange(prev_value, val.value);

                if (per.value != ""){
                    val["percent"] = "+$" + convert(val.netchange) + " ("+per.direction + per.value +")";
                }else{
                    val["percent"] = "("+per.direction + per.value +")";
                }

                series.data[results_counter] = val;
                //////////////console.log("DATA Record: ", val);
                prev_value = series.data[results_counter].value;

                //////////////console.log("data: ", val);
            }

            results_counter++;
        } //assign the year where the enity had activity
        
    }
    //////////////console.log("ELine SERIES : ", series);
    return series;
}

export function prepCumulativeSpend (data, type, metric_name, target, line_label, colour) {
    
    var series = {data: [], 
                    activity_data:[], 
                    name: line_label, 
                    type: "line",
                    stack: 'Total',
                    areaStyle: {},
                    //stack: 'x',
                    //lineStyle: {color: colour},
                    endLabel: { show: false,
                        formatter: function(params){
                           /*  var colorSpan = color => '<span style="display:inline-block;margin-right:5px;border-radius:50%;width:14px;height:14px;border: 2px solid #fff;box-shadow: 0 2px 4px rgb(0 0 0 / 25%);background-color:' + color + '"></span>';
                            var result = '<div>' + colorSpan(params.color) + target + '</div>' */
                            let trunc = "";
                            if (params.seriesName.length > 20){
                                trunc = params.seriesName.substring(0, 20) + '...';
                            }
                            else{
                                trunc = params.seriesName;
                            }

                            return trunc;
                        },
                        fontSize: 10
                    },
                    labelLayout: {
                        moveOverlap: 'shiftY'
                    },
                    emphasis: {
                        focus: 'series'
                    }, };

    var results_counter = 0;
    var prev_value=0;

    for (var i = 0; i < data.length; i++) {

        var data_for_interval = get_target_stats(data[i][type].buckets, target);

        if (!data_for_interval){
            series.data[results_counter] = {"value": (0 + prev_value), "netchange":0, "type":metric_name, "percent": "n/a"};
            results_counter++;
        }
        else {
            //////////////console.log("TARGET: ", target, "\nYEAR: ",results.labels[results_counter], "\nDATA FOR YEAR", data_for_interval);

            if (metric_name === "doc_count" && data_for_interval.key === target){
                let val = {};
                val["value"] = data_for_interval.doc_count + prev_value;
                val["netchange"] = data_for_interval.doc_count;
                val["type"] = metric_name
                let per = getPercentageChange(prev_value, val.value);
                val["percent"] = per.direction + per.value;
       
                series.data[results_counter] = val;
                prev_value = series.data[results_counter].value;
            }
            else if (metric_name === "total_amount" && data_for_interval.key === target){
                let val = {};
                //////////////console.log("RAW VALUE: ", Math.round(data_for_interval.total_amount.value) + prev_value);
                val["value"] = Math.round(data_for_interval.total_amount.value) + prev_value;
                val["netchange"] = Math.round(data_for_interval.total_amount.value);
                val["type"] = metric_name

                let per = getPercentageChange(prev_value, val.value);

                if (per.value != ""){
                    val["percent"] = "+$" + convert(val.netchange) + " ("+per.direction + per.value +")";
                }else{
                    val["percent"] = "("+per.direction + per.value +")";
                }

                series.data[results_counter] = val;
                //////////////console.log("DATA Record: ", val);
                prev_value = series.data[results_counter].value;

                //////////////console.log("data: ", val);
            }

            results_counter++;
        } //assign the year where the enity had activity
        
    }
    //////////////console.log("ELine SERIES : ", series);
    return series;
}

/**
 * Calculates in percent, the change between 2 numbers.
 * e.g from 1000 to 500 = 50%
 */
 function getPercentageChange(oldNumber, newNumber){
    var change = newNumber - oldNumber;
    var per = {};

    /* if (change == 0) {
        per["value"] = "n/a";
        per["direction"] = "";
        return per;
    } */

    if (change == 0 || oldNumber == 0){
        per["value"] = "";
        per["direction"] = "";
        return per;
    }

    //per["value"] = (change / oldNumber) * 100;

    if (change > 0){
        per["value"] = (change / oldNumber) * 100;
        per["direction"] = "+";
    }
    else if (change < 0){
        per["value"] = (change / oldNumber) * 100;
        per["direction"] = "-";
    }

    /* if (per["value"] > 0){
        per["direction"] = "+";
    }
    else if (per["value"] < 0){
        per["direction"] = "-";
    } */
    
   
    //////////////console.log("PER VALUE: ", Math.abs(per.value.toFixed(1)) + "%");

    per.value = Math.abs(per.value.toFixed(1)) + "%";

    return per;

}


function prepMultiLineData(data, type, metric_name, target, line_label, colour) {

    //////////////console.log("TARGET " + target + " LINE LABEL " + line_label);
    
    var results = {data: [], avg_spend:[], activity_data: [],  label: line_label, borderColor: colour, pointBackgroundColor: colour, pointBorderColor: colour };

    var results_counter = 0;

    for (var i = 0; i < data.length; i++) {

        var data_for_interval = get_target_stats(data[i][type].buckets, target);

        if (!data_for_interval){
            results.data[results_counter] = 0;
            results.avg_spend[results_counter] = 0;
            results.activity_data[results_counter] = 0;
            results_counter++;
        }
        else {
            //////////////console.log("TARGET: ", target, "\nYEAR: ",results.labels[results_counter], "\nDATA FOR YEAR", data_for_interval);

            if (metric_name === "doc_count" && data_for_interval.key === target){
                results.data[results_counter] = data_for_interval.doc_count;
            }
            else if (metric_name === "total_amount" && data_for_interval.key === target){
                results.data[results_counter] = Math.round(data_for_interval.total_amount.value);
                results.avg_spend[results_counter] = Math.round(data_for_interval.total_amount.value / data_for_interval.doc_count);
                results.activity_data[results_counter] = data_for_interval.doc_count;
            }

            results_counter++;
        } //assign the year where the enity had activity
        
    }
    //////////////console.log("Line RESULTS : ", results);
    return results;
}

export {prepMultiLineData}

function getXLabels(data) {
    
    var labels = [];

    for (var y in data){
        let label = "";

        if (data[y].key_as_string){
            label = data[y].key_as_string;
        }
        else if (data[y].key){
            label = data[y].key;
        }
        if (label.includes("-")){
            let qstart = getQuarterStart(label.charAt(0));

            label = qstart + label.substring(1,6);
            //label = "Q"+label;
        }
        labels[y] = label;
    }

    return labels;
}

export {getXLabels}

function getQuarterStart(qtr){
    switch (qtr) {
      case "1":
        return "Jan";
      case "2":
        return "Apr";
      case "3":
        return "Jul";
      case "4":
        return "Oct";
      
    }
}

function convert (num){
    if (num > 999 && num < 1000000) {
      num = (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num >= 1000000 && num < 1000000000) {
      num = (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num >= 1000000000) {
      num = (num / 1000000000).toFixed(1) + "B"; // convert to M for number from > 1 million
    }
    return num;
  }



