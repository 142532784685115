<template>
  <div class="pa-0 ma-0 fill-height" style="background-color:#f5f5f5" fluid>
    <v-content>
      <v-container class="fill-height pl-1 pr-0 mx-1 py-2 mt-0" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" class="pt-8">
            <div
              style="max-width:1200px; display: block; margin:auto !important;"
            >
              <p
                style="text-align:center;"
                class="display-1 font-weight-medium"
              >
                Supplier Directory
              </p>
              <p style="text-align:center;" class="body-1 font-weight-normal">
                Supplier profiles offer insights into a specific entity,
                including award trends over time along with the buyers and categories that the supplier provides services to.
              </p>
              <v-row align="center" justify="center">
                <v-col cols="3" class="pa-0 ma-0">
                  <v-combobox
                      v-model="selectedCategory"
                      x-small-chips
                      :items="parent_category_options"
                      item-text="name"
                      item-value="id"
                      :rounded="false"
                      :solo="true"
                      clearable
                      dense
                      outlined
                      label="Service category"
                      x-small
                      :menu-props="{ maxWidth: '400', maxHeight: '300' }"
                      class="pr-5"
                      @change="filterByCategory()"
                      @click:clear="clearSearch()"
                    >
                  </v-combobox>
                </v-col>
                <v-col cols="9" style="max-width:600px;" class="pa-0 ma-0">
                  <supplier-search :rounded="false" :color="'#f5f5f5'" :dark="false" :solo="true" @clearFilter="reset_filters" />
                </v-col>
                
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" class="pt-0 mb-0 pb-0 pr-0">
            <supplier-table v-if="selectedCategory"
              :category_id="selectedCategory.id"
              :cat_is_child="false"
             />
             <supplier-table v-else
             />
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </div>
</template>

<script>
import SupplierSearch from "../components/SupplierSearch";
import SupplierTable from "../components/entities/SupplierTable";
//import EntityProfile from "../components/entities/EntityProfile";

import { bus } from "../main.js";
import json from '../components/data/categories.json'

export default {
  components: {
    SupplierSearch,
    SupplierTable
  },
  props: {
    publisher: {}
  },
  data() {
    return {
      selectedCategory: null,
      drawer: true,
      mini: true,
      search: { publisher_id: Number },
      current_entity: {},
      global_search: false,
      view_index: null,
      parent_category_options: json.data,

      selected: "snapshot",
      current_view: "snapshot",
      watching: false,
      filters: {}
      //results_drawer: null
    };
  },

  created() {

  },

  mounted() {
    var query = this.$route.query;
    var cat = Object;

    if (query.hasOwnProperty("category_id")) {
      for (var i in this.parent_category_options){
        if (this.parent_category_options[i].id == query["category_id"]){
          this.selectedCategory = this.parent_category_options[i];
        }
      }
    }
  },

  methods: {
    clearSearch(){
      var qry = JSON.parse(JSON.stringify(this.$route.query));
      delete qry["category_id"];
      delete qry["page"];
      var page_num = 1;
      this.$router.push({ name: 'suppliers', query: qry});
    },

    process_route() {
      this.filters = this.$route.query;
    },

    filterByCategory(){
      var qry = JSON.parse(JSON.stringify(this.$route.query));
      delete qry["category_id"];
      delete qry["page"];
      qry["category_id"] = this.selectedCategory.id;
      var page_num = 1;
      this.$router.push({ name: 'suppliers', query: qry});
    },

    hideFilter() {
      let show = false;
      bus.$emit("hideFilters", show);
    },

    isEmpty(obj) {
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
      }
      return true;
    },

    reset_filters(){
      var qry = JSON.parse(JSON.stringify(this.$route.query));
      delete qry["term"];
      delete qry["page"];
      this.$router.push({ name: "suppliers", query: qry });
    }
  },

  watch: {

  }
};
</script>
<style scoped>
.v-input {
    font-size: 14px !important;
}
</style>
