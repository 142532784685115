<template>
  <div>
    <v-card style="padding-bottom: 20px" tile>
      <v-card-actions class="pl-5 py-3">
        <v-btn style="text-decoration:none !important;" :disabled="notSelected"
              :to="{ name: 'search', 
                    query: { category_id: selected,
                             supplier_id: supplier_id,
                             viewIndex: 3,
                             pie_group_value: 'category',
                             tree_group_value: 'category',
                             scatter_group_value: 'category',
                             comparison_group_value: 'category',
                             expiry_group_value: 'category' } }"
              small
              outlined 
              color="primary">Compare Selected</v-btn></v-card-actions>
      <!-- <v-skeleton-loader
        v-if="isLoading"
        type="table-tbody"
      ></v-skeleton-loader> -->
      <v-simple-table style="width:100%;">
        <thead>
          <tr>
            <th class="pl-5">
              <v-icon>mdi-checkbox-blank</v-icon>
            </th>
            <th class="text-left subtitle-2 font-weight-bold py-3 pl-5 pr-1">
              Category
            </th>

            <th
              class="text-center subtitle-2 font-weight-bold py-3 p2-2 pl-1 ml-1"
            >
              <div>Awarded</div> 
              <div class="caption" style="font-weight:normal !important;">All Time</div>
            </th>
            <th
              class="text-center subtitle-2 font-weight-bold py-3 p2-2 pl-1 ml-1"
            >
              <div>Contracts</div> 
              <div class="caption" style="font-weight:normal !important;">All Time</div>
            </th>
            <th
              class="text-center subtitle-2 font-weight-bold py-3 p2-2 pl-1 ml-1"
            >
              <div>Awarded</div> 
              <div class="caption" style="font-weight:normal !important;">Last 12 Months</div>
            </th>
            <th
              class="text-center subtitle-2 font-weight-bold py-3 p2-2 pl-1 ml-1"
            >
             <div>Avg Contract</div> 
              <div class="caption" style="font-weight:normal !important;">Last 12 Months</div>
            </th>
            
            <th
              class="text-center subtitle-2 font-weight-bold py-3 p2-2 pl-1 ml-1"
            >
              <div>New Contracts</div> 
              <div class="caption" style="font-weight:normal !important;">Last 12 Months</div>
            </th>
            <th
              class="text-center subtitle-2 font-weight-bold py-3 p2-2 pl-1 ml-1"
            >
              <div>Expiring Contracts</div> 
              <div class="caption" style="font-weight:normal !important;">Next 12 Months</div>
            </th>
          </tr>
        </thead>
        <tbody v-if="isLoading">
          <tr>
            <td colspan="8">
              <v-progress-linear v-show="isLoading" slot="progress" color="primary" indeterminate width="100%"></v-progress-linear>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-for="entity in entities" :key="entity.id">
            <td class="py-0 pl-5 pr-1 ml-1">
              <v-checkbox
                v-model="selected"
                :value="entity.id"
              ></v-checkbox>
            </td>
            <td class="py-2 pl-5 pr-1 ml-1">
              <div
                class="subtitle-2 d-inline-block text-truncate"
                style="max-width:600px; min-width:150px;"
              >
                <router-link :to="{ name: 'category', params: { id: entity.id } }"
                  >{{entity.name}}</router-link
                >
              </div>
              <div
                class="caption text-truncate"
                style="max-width:500px; min-width:150px;"
              >
              <router-link v-if="entity.parent" :to="{ name: 'category', params: { id: entity.parent.id } }"
                  >{{entity.parent.name}}</router-link
                >
              </div>
            </td>

            <td class="py-2 px-1 text-center">
              <router-link v-if="entity.parent"
                  :to="{ name: 'search', query: { category_id: entity.id, supplier_id: supplier_id } }"
                >
              {{ formattedCurrency(entity.award_total) }}
              </router-link>
              <router-link v-else
                  :to="{ name: 'search', query: { major_category_id: entity.id, supplier_id: supplier_id } }"
                >
              {{ formattedCurrency(entity.award_total) }}
              </router-link>
            </td>
            <td class="py-2 px-1 text-center">
              <router-link v-if="entity.parent"
                  :to="{ name: 'search', query: { category_id: entity.id, supplier_id: supplier_id } }"
                >
              {{ numFormatter(entity.num_notices) }}
              </router-link>
              <router-link v-else
                  :to="{ name: 'search', query: { major_category_id: entity.id, supplier_id: supplier_id } }"
                >
              {{ numFormatter(entity.num_notices) }}
              </router-link>
            </td>
            <td class="py-2 px-1 text-center">
              <span>
              {{ formattedCurrency(entity.growth.current_year_total) }}
              </span>
              <span>
                    <v-icon class="py-0 pr-0 mr-0" :color="growth_format(entity.growth.spend_change_percent).color">
                      {{growth_format(entity.growth.spend_change_percent).icontype}}
                    </v-icon>
                    {{ growth_format(entity.growth.spend_change_percent).change }}%
                  </span>
            </td>
            <td class="py-2 px-1 text-center">
              <span>
                {{ formattedCurrency(entity.growth.curr_yr_avg_contract) }}
              </span>
              <span>
                    <v-icon class="py-0 pr-0 mr-0" :color="growth_format(entity.growth.avg_change_percent).color">
                      {{growth_format(entity.growth.avg_change_percent).icontype}}
                    </v-icon>
                    {{ growth_format(entity.growth.avg_change_percent).change }}%
                  </span>
            </td>
        

            <td class="py-2 px-1 text-center">
              <span>
                {{ numFormatter(entity.growth.current_year_vol) }}
              </span> 
              <span>
                    <v-icon class="py-0 mr-0 pr-0" :color="growth_format(entity.growth.vol_change_percent).color">
                      {{growth_format(entity.growth.vol_change_percent).icontype}}
                    </v-icon>
                    {{ growth_format(entity.growth.vol_change_percent).change }}%
                  </span>
            </td>

            <td class="py-2 px-1 text-center">
              <span>
                {{ formattedCurrency(entity.expiring_soon.next_year_total) }}
              </span> 
              <span>
                <v-icon class="py-0 mr-0 pr-0" :color="growth_format(entity.expiring_soon.spend_change_percent).color">
                  {{growth_format(entity.expiring_soon.spend_change_percent).icontype}}
                </v-icon>
                {{ growth_format(entity.expiring_soon.spend_change_percent).change }}%
              </span>
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <div class="text-center">
        <v-pagination
          v-if="entities.length > 1"
          v-model="page_num"
          color="#4EB888"
          :length="num_pages"
          total-visible="5"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          @input="
            $router.replace({ name: $route.name, query: {...$route.query, page: page_num } }),
              buildQuery()
          "
        ></v-pagination>
      </div>
    </v-card>
  </div>
</template>

<script>
//import { bus } from "@/main.js";
//import SupplierSearch from "../SupplierSearch";
import { fetchCategories } from "../APIManager";
import { growth_format, formattedCurrency, numFormatter } from "../Utils.js";

export default {
  components: {
    //EntityProfileSearch
  },

  props: {
    supplier_id: Number,
    category_id: Number
  },
  data() {
    return {
      selectAll: false,
      selected: [],
      selectedCount: 0,
      item: 0,
      entities: [],
      entities_temp: [],
      page_num: 1,
      num_pages: 5,
      isLoading: true
      //current_selection:
    };
  },

  mounted() {
    this.buildQuery();
  },

  methods: {

    growth_format,
    numFormatter,
    formattedCurrency,

    buildQuery(){

      if (this.supplier_id){
        var query = {page: this.page_num, supplier_id: this.supplier_id}
        this.loadCategories(this.page_num, query);
      }
      else if (this.category_id){
        var query = {page: this.page_num, category_id: this.category_id}
        this.loadCategories(this.page_num, query);
      }
      else if (this.$route.query["page"]){
        ////////////console.log("HAS PAGE: ", this.$route.query["page"])
        this.page_num = this.$route.query["page"]
        this.loadCategories(this.page_num, this.$route.query);
      }
      else{
        this.page_num = 1;
        this.loadCategories(this.page_num, this.$route.query);
      }
      
      //this.isLoadng = false;
    },

    entity_selected(entity_id) {
      //var ent = JSON.stringify(entity);
      this.$router.push({ name: "category", params: { id: entity_id } });
      //bus.$emit("entity-selected", entity);
    },

    loadCategories(page_num, query) {
      ////////////console.log("loading suppliers: ");
      this.isLoading = true;

      //var qry = JSON.parse(JSON.stringify(query));

      fetchCategories(page_num, query).then(results => {
        this.entities = results.data;
        this.num_pages = results.stats.total_pages;
        this.page_num = results.stats.page_no;
        this.isLoading = false;

        ////////////console.log("fetchCategories data: ", results);
      });
    },

    disableIcon(url) {
      if (!url) {
        return true;
      } else {
        return false;
      }
    }
  },
  created() {
    //this.loadPublishers(this.page_num);
  },

  computed: {
    notSelected(){
      if (this.selected.length > 0) return false;
      else return true;
    }
  },
  watch: {

    $route(to, from) {
      this.buildQuery();
    },
  }
};
</script>
<style>
.wrap-text {
  word-wrap: normal !important;
}

.chip-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}
.nav-list {
  background-color: "#303030";
  border-bottom: 2pt white;
}
.list-item {
  text-decoration: none !important;
  color: "#FFFFFF" !important;
}

.bottom-border {
  border-bottom: 2pt white;
}
</style>
