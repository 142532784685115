<template>
  <v-card class="chart_container pa-2" tile flat min-height="500px">
    <chart-header :title="'Distribution of Contracts / Tenders'"
      :subtitle="subtitle"
      :widget_name="'spending-scatter'" 
      :total_result="total_records"
      :total_amount="total_amount"
      :search_query="plain_text_query"
      :chart_settings="chart_settings"
      :disable_show_data="true"
      :embed_obj_changed="embed_obj_changed"
      :embed_object="embed_object"
      @embed="embed_code" 
      @downloadImage="downloadImage"
      :data="dataview"/>
    <chart-loader :reportLoading="scatterLoading" />

    <v-row>
        <div class="d-inline pl-4 mr-5">
          <p class="mb-0 pb-0 subtitle-2" style="font-weight:bold;">Entity Type</p>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                class="text-capitalize body-1"
                  color="#E0E0E0"
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  width="210"
                  style="justify-content:left !important;"
                >
                  <span style="color:#12253a;">{{group.text}}</span>
                  <v-icon color="#12253a" right>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, value) in group_options"
                  :key="value"
                  @click="group = item, grouping = item.value, updateSettings(), refresh()"
                >
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
        </div>
        <div class="d-inline mr-5">
          <p class="mb-0 pb-0 subtitle-2" style="font-weight:bold;">Date Orientation</p>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                class="text-capitalize body-1"
                  color="#E0E0E0"
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  width="160"
                  style="justify-content:left !important;"
                >
                  <span style="color:#12253a;">{{date_option.text}}</span>
                  <v-icon color="#12253a" right>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, value) in date_options"
                  :key="value"
                  @click="date_option = item, change_date_orientation(), updateSettings()"
                >
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
        </div>
        <div class="d-inline">
          <p class="mb-0 pb-0 subtitle-2" style="font-weight:bold;"># Entities / Categories</p>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              class="text-capitalize body-1"
                color="#E0E0E0"
                outlined
                v-bind="attrs"
                v-on="on"
                width="130"
                style="justify-content:left !important;"
              >
                <span style="color:#12253a;">{{num_groups}}</span>
                <v-icon color="#12253a" right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="item in top_entities"
                :key="item"
                @click="num_groups = item, updateSettings(), refresh()"
              >
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
    </v-row>
    
    <v-row>
      <v-col cols="10" class="pt-2 mt-0">
        <scatter v-if="groups.length > 0"
          :legend_settings="legend_settings"
          :series="groups"
          :changed="changed"
          :download="download"
          :embed="embed"
          @chart_object="chart_object"
          :id="id"
          @dataview="loadDataView"
        />
      </v-col>
      <v-col cols="2" class="pt-4 pr-7">
        <chart-legend
          :colors="true" 
          :legendData="legend_data"
          :groups="groups"
          :total_results="total_records"
          :total_amount="total_amount"
          :metric="'total_amount'" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import html2canvas from 'html2canvas';
import { buildTree } from "./PrepTree.js";
import { groupBySearch } from "./APIManager.js";
import { silentURLUpdate, formattedCurrencyFull, formattedCurrency } from "./Utils.js";

import { govColor } from "../components/Utils";
import Scatter from "../components/Scatter";
import ChartHeader from "../components/ChartHeader";
import ChartLoader from "../components/ChartLoader";
import ChartLegend from "./ChartLegend.vue";
import {downloadFile} from "../components/Utils";
import { formatDate, getMonthYear } from "../components/Utils";
//import Donut from "@/components/Donut.vue";

export default {
  components: {
    Scatter,
    ChartHeader,
    ChartLoader,
    ChartLegend
    //Donut
  },

  props: {
    title: {
      type: String,
      default: "Contract Distribution"
    },
    description: String
  },

  data() {
    return {
      chart_settings: [],
      subtitle: "Indvidual contracts or tenders over time by award amount",
      scatterLoading: Boolean,
      num_groups: 5,
      top_entities: [5, 10, 15],
      item_details: false,
      aggregated_data: [],
      //publisher_line_chart_data: [],
      //chart_axes: { x: "Est Total", y: "Categories" },
      //chart_axes: { x: "Transactions", y: "Year" },
      group_by_amount: true,
      target_entity: String,
      changed: Number,
      years: [],
      groups: [],
      grouped_tenders: [],
      legend_data: [],
      group_options: [{text: "Major Category", value:"parent_category"}, 
                      {text: "Category", value:"category"}, 
                      {text: "Buyer", value:"publisher"}, 
                      {text: "Supplier", value:"supplier"}, 
                      {text: "Government", value:"government"},
                      {text: "Notice Type", value:"disposition"},
                      {text: "Sector", value:"publisher_industry"},
                      {text: "Procurement Method", value:"procurement_method"}],
      group: {text: "Supplier", value:"supplier"},
      date_options: [{text: "Contract Start", value:"contract_start"}, {text: "Expiry Date", value:"expiry_date"}],
      date_option: {text: "Contract Start", value:"contract_start"},
      grouping: "supplier",
      group_sort_options: [{text: "Amount", value:"group_total"}, {text: "Volume", value:"group_count"}],
      group_sort_by: {text: "Amount", value:"group_total"},
      total_records: Number,
      total_amount: Number,
      plain_text_query:String,
      download: Number,
      embed: Number,
      embed_object: Object,
      embed_obj_changed: Number,
      dataview: {headers:[], data:[]}
      //legend_settings: []
    };
  },
  watch: {

    $route(to, from) {
      this.refresh();
    },
  },

  async mounted() {
    this.scatterLoading = true;
    this.loadSettings();
    this.loadData(this.$route.query);
  },

  computed: {

    id(){
      return (Math.random() + Date.now()) * Math.random();
    },

    legend_settings() {
      let mylegend = {};

      mylegend["colors"] = true;
      mylegend["legendData"] = this.legend_data;
      mylegend["groups"] = this.groups;
      mylegend["total_results"] = this.total_records;
      mylegend["total_amount"] = this.total_amount;
      mylegend["metric"] = 'total_amount';

      return mylegend;
    }

  },


  methods: {

    formattedCurrency,
    getMonthYear,
    formatDate,

    loadDataView(data){
      this.dataview = data;
    },

    chart_object(obj){
      this.embed_object["title"] = this.title;
      this.embed_object["description"] = this.description;
      this.embed_object["chart_type"] = "scatter";
      this.embed_object["payload"] = obj
      this.embed_object["id"] = (Math.random() + Date.now()) * Math.random();
      this.embed_obj_changed = (Math.random() + Date.now()) * Math.random();
    },

    embed_code(status){
      this.embed =
          (Math.random() + Date.now()) * Math.random();

    },

    updateSettings(){
      this.chart_settings[0] = {field_name: "scatter_date_orientation", value: this.date_option.value};
      this.chart_settings[1] = {field_name: "scatter_group_value", value: this.group.value};
      this.chart_settings[2] = {field_name: "scatter_num_groups", value: this.num_groups};

      silentURLUpdate(this.chart_settings);
    },

    loadSettings(){
      var query = this.$route.query;
      if (query.hasOwnProperty("scatter_group_value")){
        let index = this.group_options.findIndex(x => x.value === query.scatter_group_value);
        delete this.group.text;
        delete this.group.value;
        this.group = this.group_options[index];
      }
      if (query.hasOwnProperty("scatter_date_orientation")){
        let index = this.date_options.findIndex(x => x.value === query.scatter_date_orientation);
        delete this.date_option.text;
        delete this.date_option.value;
        this.date_option = this.date_options[index];
      }
      if (query.hasOwnProperty("scatter_num_groups")){
        this.num_groups = query.scatter_num_groups;
      }
    },

    downloadImage(status){
      ////////////console.log("DOWNLOAD CLICKED");
      this.download =
          (Math.random() + this.grouped_tenders[0].data.length) * Math.random();

    },


    getTitle (){
      if (!this.total_records || this.scatterLoading == true){
        return null;
      }
      else{
        return this.total_records + " contracts valued at " + this.formatCurrency(this.total_amount);
      }
    },

    setChanged(){
      this.changed =
          (Math.random() + this.grouped_tenders[0].data.length) * Math.random();
    },


    async refresh(){
      this.scatterLoading = true;
      this.legend_data = [];
      this.groups = [];
      await this.loadData(this.$route.query);
    },

    async change_date_orientation(){
      this.scatterLoading = true;
      this.legend_data = [];
      this.groups = [];
      this.chartConfig();
      this.setChanged();
    },

    async loadData(query) {
      ////////////console.log("GROUP: ", this.group);

      query["agg_size"] = this.num_groups;

      groupBySearch(query, 1, this.group.value, "group_total")
        .then(data => {
          this.grouped_tenders = data.group_by;
          this.total_records = data.hits.value;
          this.total_amount = data.aggregations.total_amount.value;
          this.plain_text_query = data.plain_text_query;
          /* //////////console.log(
            "GROUPED RESULTS " + JSON.stringify(this.grouped_tenders)
          ); */
          this.chartConfig();
          
          //this.legend_ready = true;
          this.scatterLoading = false;
          this.setChanged();

          //this.setGroups();
        })
        .catch(error => {
          ////////////console.log(error);
          this.errored = true;
          //this.overlay = false;
        });
    },

    chartConfig() {

      //build y-axis list and data
      for (var i in this.grouped_tenders) {
        let group = {};
        group["name"] = this.grouped_tenders[i].group;
        group["type"] = "scatter";
        group["emphasis"] = {focus: "series"};
        group["itemStyle"] = {color: this.grouped_tenders[i].color};
        group["group_total"] = this.grouped_tenders[i].group_total;
        group["group_count"] = this.grouped_tenders[i].group_count;

        group["data"] = [];

        this.legend_data.push({key: group["name"], 
            color: this.grouped_tenders[i].color, 
            doc_count: group["group_count"], 
            total_amount: {value: group["group_total"]}});

        for (var d in this.grouped_tenders[i].data) {
          var record = this.grouped_tenders[i].data[d];

          record[this.date_option.value] = formatDate(record[this.date_option.value]);

          group.data.push({value: [record[this.date_option.value],
              this.getRandomYPosition(500)], symbolSize: this.sizeFunction(record.value),
              detail: { 
                  title: record.title,
                  description: record.description,
                  identifer: record.recid,
                  disposition: record.disposition,
                  buyer: record.publisher,
                  government: record.government,
                  value: formattedCurrencyFull(record.value),
                  supplier: record.supplier,
                  contract_start: record.contract_start,
                  expiry: formatDate(record.expiry_date),
                  major_category: record.parent_category,
                  category: record.category,
                  id: record.id
                }
              });
        }

        this.groups.push(group);
        this.$emit("distribution-loaded", this.groups);
        this.scatterLoading = false;
      }

      ////////////console.log("GROUPS: ", this.groups);
    },

    sizeFunction (x) {
        var y = Math.sqrt(x / 100000000) + 0.1;
        return y * 80;
    },

    getRandomYPosition(max) {
      return Math.floor(Math.random() * max);
    }

  }
};
</script>

<style>
.chart_container {
    max-width: 100% !important;
    /* overflow: auto !important; */
    border: none !important;
    height: 100% !important;
    background-color: #fff !important;
}
</style>
