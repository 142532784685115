var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"chart_container pa-2",attrs:{"tile":"","flat":"","min-height":"500px"}},[_c('chart-header',{attrs:{"title":'Distribution of Contracts / Tenders',"subtitle":_vm.subtitle,"widget_name":'spending-scatter',"total_result":_vm.total_records,"total_amount":_vm.total_amount,"search_query":_vm.plain_text_query,"chart_settings":_vm.chart_settings,"disable_show_data":true,"embed_obj_changed":_vm.embed_obj_changed,"embed_object":_vm.embed_object,"data":_vm.dataview},on:{"embed":_vm.embed_code,"downloadImage":_vm.downloadImage}}),_c('chart-loader',{attrs:{"reportLoading":_vm.scatterLoading}}),_c('v-row',[_c('div',{staticClass:"d-inline pl-4 mr-5"},[_c('p',{staticClass:"mb-0 pb-0 subtitle-2",staticStyle:{"font-weight":"bold"}},[_vm._v("Entity Type")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize body-1",staticStyle:{"justify-content":"left !important"},attrs:{"color":"#E0E0E0","outlined":"","width":"210"}},'v-btn',attrs,false),on),[_c('span',{staticStyle:{"color":"#12253a"}},[_vm._v(_vm._s(_vm.group.text))]),_c('v-icon',{attrs:{"color":"#12253a","right":""}},[_vm._v("mdi-menu-down")])],1)]}}])},[_c('v-list',_vm._l((_vm.group_options),function(item,value){return _c('v-list-item',{key:value,on:{"click":function($event){_vm.group = item, _vm.grouping = item.value, _vm.updateSettings(), _vm.refresh()}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)}),1)],1)],1),_c('div',{staticClass:"d-inline mr-5"},[_c('p',{staticClass:"mb-0 pb-0 subtitle-2",staticStyle:{"font-weight":"bold"}},[_vm._v("Date Orientation")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize body-1",staticStyle:{"justify-content":"left !important"},attrs:{"color":"#E0E0E0","outlined":"","width":"160"}},'v-btn',attrs,false),on),[_c('span',{staticStyle:{"color":"#12253a"}},[_vm._v(_vm._s(_vm.date_option.text))]),_c('v-icon',{attrs:{"color":"#12253a","right":""}},[_vm._v("mdi-menu-down")])],1)]}}])},[_c('v-list',_vm._l((_vm.date_options),function(item,value){return _c('v-list-item',{key:value,on:{"click":function($event){_vm.date_option = item, _vm.change_date_orientation(), _vm.updateSettings()}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)}),1)],1)],1),_c('div',{staticClass:"d-inline"},[_c('p',{staticClass:"mb-0 pb-0 subtitle-2",staticStyle:{"font-weight":"bold"}},[_vm._v("# Entities / Categories")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize body-1",staticStyle:{"justify-content":"left !important"},attrs:{"color":"#E0E0E0","outlined":"","width":"130"}},'v-btn',attrs,false),on),[_c('span',{staticStyle:{"color":"#12253a"}},[_vm._v(_vm._s(_vm.num_groups))]),_c('v-icon',{attrs:{"color":"#12253a","right":""}},[_vm._v("mdi-menu-down")])],1)]}}])},[_c('v-list',_vm._l((_vm.top_entities),function(item){return _c('v-list-item',{key:item,on:{"click":function($event){_vm.num_groups = item, _vm.updateSettings(), _vm.refresh()}}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1)],1)]),_c('v-row',[_c('v-col',{staticClass:"pt-2 mt-0",attrs:{"cols":"10"}},[(_vm.groups.length > 0)?_c('scatter',{attrs:{"legend_settings":_vm.legend_settings,"series":_vm.groups,"changed":_vm.changed,"download":_vm.download,"embed":_vm.embed,"id":_vm.id},on:{"chart_object":_vm.chart_object,"dataview":_vm.loadDataView}}):_vm._e()],1),_c('v-col',{staticClass:"pt-4 pr-7",attrs:{"cols":"2"}},[_c('chart-legend',{attrs:{"colors":true,"legendData":_vm.legend_data,"groups":_vm.groups,"total_results":_vm.total_records,"total_amount":_vm.total_amount,"metric":'total_amount'}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }