<template>
<v-card tile>
    <v-card-title><span>Data View</span>
    <span>
    </span>
    <span class="pl-2">
        <v-btn
          icon
          tile
          color="secondary"
          @click="exportTable()"
          style="justify-content:center !important;"
          ><v-icon>mdi-download</v-icon>
          </v-btn>
      </span>
        <div class="flex-grow-1"></div> <v-btn icon @click="closeView()"><v-icon medium>mdi-close</v-icon></v-btn>
    </v-card-title>
    <v-data-table
        id="dataview"
        :items-per-page="100"
        :items="data"
        :headers="headers"
        class="elevation-1 px-2"
  ></v-data-table>
</v-card>
    
</template>
<script>

import { exportCSVFile } from "./Utils.js";
export default {
    components: {
        
    },
    props: {
        data: [],
        headers:[]
    },

    data() {
        return {
            jsonTable: {}
        }
    },

    mounted() {
        ////////////console.log("DATAVIEW: ", this.data);
        ////////////console.log("HEADERS: ", this.headers);
    },
    
    methods: {
        closeView(){
            this.$emit('close-view', true)
        },

        exportTable(){

            //need this to convert table to string representation
            var tmp = document.createElement("div");

            let table = document.querySelector('#dataview').cloneNode( true );

            tmp.appendChild(table);

            const HtmlTableToJson = require('html-table-to-json');

            var jsonTable = HtmlTableToJson.parse(tmp.innerHTML);
            var headings = [];

            for (var i in this.headers){
                headings[i] = this.headers[i].text;
            }

            /* var headers = {rank: 'Rank', 
                            entity:'Buyer/Supplier/Category',
                            notices:'# Notices', 
                            value:'$ Value', 
                            avg_contract:'Avg Contract', 
                            per_awards: '% Awards',
                            per_value:'% Award Value'
                            }; */

            try {
                var dte = new Date();


                var fileTitle = "tendertrace_dataview" + "_" + dte.toISOString();
                exportCSVFile(headings, jsonTable.results[0], fileTitle);

            } 
            catch (err) {
            }

        },
    }
}
</script>