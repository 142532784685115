<template>
    <!-- <v-card tile flat min-width="200" class="overflow-y-auto" max-height="400px"> -->
        <v-card tile flat min-width="200" class="overflow-y-auto pr-5 pl-0" max-height="450px">
        <div class="legend-container" v-if="legendData.length > 0">
            <div class="c-legend">
                <div class="legend-container">
                    <ul class="legend--list">
                        <li v-for="i in legendData" :key="i.key" class="legend--list-item">
                            <div class="legend--list-item-icon" :style="'background-color:'+ i.color +';'"></div>
                            <div class="legend--list-item-name">
                                <div>{{i.key}}</div>
                                <div v-if="metric == 'total_amount'" class="legend--list-item-value">{{ formattedCurrency(i.total_amount.value) }} - {{ getPercent(i.total_amount.value, total_amount) }}</div>
                                <div v-else class="legend--list-item-value">{{ i.doc_count }} - {{ getPercent(i.doc_count, total_results) }}</div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </v-card>
</template>

<script>
import { formattedCurrency, govColor, getPercent } from './Utils';

export default {

    props: {
        legendData: [],
        colors: Boolean,
        groups: [],
        total_results: Number,
        total_amount: Number,
        metric: String
    },

    data() {
        return {
        //entity_count: 10,
        selected: [],
        }
    },

    watch: {
    },

    created() {
        //this.setLegend();
        /* if (this.legendData.length > 0 ){
            this.setLegend();
        } */
    },

    methods:{
        formattedCurrency,
        govColor,
        getPercent,

        updateSelection(){
            this.$emit("legendChanged", this.selected);
        },

        setLegend(){

            this.selected = this.groups;
        }
        
    }
}

</script>

<style scoped>
.legend-container {
    flex: 1 1;
    /* max-height: 500px; */
    overflow-y: hidden;
    overflow-x: hidden;
}

.c-legend {
    display: block;
}

ol, ul {
    list-style: none;
}

li {
  float: left;
}

.c-legend .legend--list-item:first-child {
    margin-top: 0;
}

.c-legend .legend--list-item {
    /* display: flex; */
    display: flex;
    width: 100%;
    max-width: 220px;
    margin-top: 0px;
    margin-bottom: 18px;
    transition: opacity .16s linear;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
}

.c-legend .legend--list-item .legend--list-item-name {
    width: 100%;
    text-transform: capitalize;
    font-size: 14px;
}

.c-legend .legend--list-item .legend--list-item-icon {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    fill: #fff;
    border: 0px solid #fff;
    /* box-shadow: 0 2px 4px rgb(0 0 0 / 25%); */
}

.c-legend .legend--list-item .legend--list-item-value {
    font-weight: 400;
    color: #515153;
    margin-top: 6px;
    font-size: 14px;
    line-height: 14px;
}
</style>
