<script>
import { Doughnut } from "vue-chartjs";
import { bus } from "../main.js";

export default {
  props: {
    chart_headers: [],
    chart_data: {},
    chart_axes: {},
    metric_type: String(),
    target_entity: String(),
    changed: Number,
    metric_name: String(),
    entity_type: String()
  },
  extends: Doughnut,
  data() {
    return {
      labels: [],
      colours: [
        "#004700",
        "#006000",
        "#00791e",
        "#2d9437",
        "#004d9f",
        "#0064ba",
        "#007cd6",
        "#d73e32",
        "#de004b",
        "#ff457c",
        "#4a575d",
        "#7b898f",
        "#bb5900",
        "#bb5900",
        "#ffa72d",
        "#4caf50",
        "#2196f3",
        "#fb8c00",
        "#ff1463",
        "#b71c1c",
        "#ff937b",
        "#95e8ff",
        "#a2ff9e",
        "#fffb82",
        "#FFE70A",
        "#SFS603",
        "#7F7305",
        "#BFAB07",
        "#FF6905",
        "#5405FF"
      ],

      options: {
        onClick: this.graphClickEvent,
        legend: {
          display: false,
          position: "right",
          align: "left",
          labels: {
            boxWidth: 20,
            generateLabels: function(chart) {
              var data = chart.data;
              if (data.labels.length && data.datasets.length) {
                return data.labels.map(function(label, i) {
                  var meta = chart.getDatasetMeta(0);
                  var ds = data.datasets[0];
                  return {
                    // And finally :
                    //text: ds.data[i] + "% of the time " + label,
                    text: label.slice(0, 35) + "...",
                    fillStyle: "#64c197",

                    index: i
                  };
                });
              }
              return [];
            }
          }
        },
        layout: {
          padding: {
            left: 20,
            right: 20,
            top: 10,
            bottom: 50
          }
        },
        cutoutPercentage: 70,
        responsive: true,
        maintainAspectRatio: false
      }
    };
  },

  watch: {
    changed() {
      this.loadChart();
    }
  },

  created() {
    if (!this.isEmpty(this.chart_data)) {
      this.loadChart();
    }
  },

  mounted() {
    if (!this.isEmpty(this.chart_data)) {
      this.loadChart();
    }
  },

  methods: {
    graphClickEvent(point, event) {
      const item = event[0];

      var query = JSON.parse(JSON.stringify(this.$route.query));
      if (this.$route.name == "entity") {
        query["publisher_id"] = this.$route.params.id;
      }
      if (this.$route.name == "supplier") {
        query["supplier_id"] = this.$route.params.id;
      }
      if (this.$route.name == "category") {
          query["category_id"] = this.$route.params.id;
        }
      if (this.$route.name == "parent_category") {
        query["parent_category_id"] = this.$route.params.id;
      }

      if (this.entity_type == "major_category"){
          query["type"] = "parent_category";
          query["parent_category"] = this.labels[item._index];
        } 
      else{
        query["type"] = this.entity_type;
        query[this.entity_type] = this.labels[item._index];
      }

      bus.$emit("pieClicked", query);
    },

    loadChart() {
      var data = {};
      var datasets = [];
      //var labels = [];

      ////////////console.log("DONUT DATA IS: ", this.chart_data);

      this.labels = this.chart_data.labels;
      datasets = this.chart_data.datasets;

      //datasets[0].backgroundColor = this.chart_data.backgroundColor;
      //datasets[0].borderColor = this.chart_data.backgroundColor;

      data.labels = this.labels;
      data.datasets = datasets;

      if (this.metric_name === "total_amount") {
        ////////////console.log("METRIC_NAME: ", this.metric_name);
        this.options.tooltips = {
          labelTextColor: function(context) {
            return "grey";
          },
          displayColors: true,
          backgroundColor: 'rgb(255,255,255)', 
          borderColor: '#CDCDCD',
          padding: 20,
          xPadding: 10,
          yPadding: 10,
          caretSize: 0,
          borderWidth: 1,
          cornerRadius: 2,
          bodySpacing: 10,
          bodyFontFamily: 'verdana',
          multiKeyBackground: 'rgb(255,255,255)',
          callbacks: {
            label(tooltipItem, data) {
              var indice = tooltipItem.index;
              //var value = '$' + tooltipItem.index.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              //////
              let sum = 0;
              let dataArr = data.datasets[0].data;

              dataArr.map(data => {
                sum += data;
              });

              return [
                data.labels[indice],
                "$" +
                  data.datasets[0].data[indice]
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                ((data.datasets[0].data[indice] * 100) / sum).toFixed(2) + "%"
              ];
            },

            title: function() {
              return null;
            },

            labelTextColor: function(context) {
              return "grey";
            }
          }
        };
      }

      ////////////console.log("DONUT DATA FOR RENDERING: ", data);
      this.$refs.canvas.parentNode.style.height = "305px";
      this.renderChart(data, this.options);
    },

    isEmpty(obj) {
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
      }
      return true;
    }
  }
};
</script>

<style>
.small {
  max-width: 300px;
}
</style>
