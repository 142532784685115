<template>
  <div class="fill-height pa-0 ma-0" style="background-color:#f5f5f5" fluid>
        <v-content>
            <v-row class="mx-auto pa-1">
              <v-col cols="3" sm="2" md="3" lg="3" class="pl-1">
                <chat-query-window :responseStatus="responseStatus" @fetchData="runQuery" :responseStatusChanged="responseStatusChanged" />
              </v-col>
              <v-col cols="9" sm="7" md="8" lg="9" class="pr-1">
                <tracey-results :query="userQuery" :changed="changed" @response="setResponse" />
              </v-col>
            </v-row>
        </v-content>
    </div>
</template>

<script>
import ChatQueryWindow from "../components/ChatQueryWindow.vue";
import TraceyResults from "../components/TraceyResults.vue";

export default {
  components: {
    ChatQueryWindow,
    TraceyResults
  },

  data() {
    return {
      qry_dialog: false,
      queryName: "You have not run a search yet. Use the Tracey window to ask Tendertrace for the data you seek.",
      resultsText: "",
      resultsSQL: "",
      isLoading: false, 
      isError: false,
      showData: false,
      internalDialog: this.value,
      viewType: null,
      userQuery: {},
      messages: [],
      apiData: { columns: [],rows: [] },

      data: [],
      queries: [],
      loading: false,
      search: null,
      gov_type: "",
      page: 1,
      responseStatus: {text: null, rows: null, name: null, id: null},
      changed: Number,
      responseStatusChanged: Number
      //chart: null,
    };
  },

  methods: {

    setResponse(response){
      this.responseStatus.text = response.text;
      this.responseStatus.rows = response.rows;
      this.responseStatus.name = response.name;
      this.responseStatus.id = response.id;

      this.responseStatusChanged = (Math.random() + response.rows) * Math.random();
    },

    setChanged(){
      this.changed =
          (Math.random() + this.apiData.rows.length) * Math.random();
      //this.generate_json();
    },

    runQuery(input) {
      //console.log("in runnQuery: ", input);
      this.userQuery = input;
      this.setChanged();
    },


    formatDate(date) {
      if (date) {
        return new Date(date).toLocaleDateString();
      }
      return 'N/A';
    },
  }

};
</script>
<style scoped>
/* ... existing styles ... */
.overflow-auto {
    overflow-y: auto; 
    height: 100vh;
}
</style>