<template>
  <div>
    <v-row class="mx-auto px-0 py-0" style="background-color:#f5f5f5"
      ><v-col cols="12" class="px-0 pb-0 pt-2">
        <v-card v-if="date_aggs.length == 0" tile style="color:rgb(78, 184, 136);">
          <v-skeleton-loader
            max-width="400"
            class="py-2"
            type="list-item-avatar, list-item-one-line, divider, list-item-three-line, list-item-three-line"
          ></v-skeleton-loader>
        </v-card>
        <v-card v-else tile>
          <v-card-title style="color:#12253a;"
            ><span>
              <v-card-subtitle class="pa-0 font-weight-light">Supplier</v-card-subtitle>
              <span>{{ entity.name }}</span>
                <!-- <span v-if="entity.published_name && entity.published_name.trim().toLowerCase() != entity.name.trim().toLowerCase()"> 
                  <span class="title font-weight-light"> (Alt. {{entity.published_name}})</span>
                </span> -->
                <div
                  v-if="related_entities.length > 0"
                  class="font-weight-normal subtitle-2"
                >
                  <a @click="related_dialog = true"
                    >Also known by
                    {{ related_entities.length }} other name<span
                      v-if="related_entities.length > 1"
                      >s</span
                    ><v-icon color="#41a7d9" class="pl-0 ml-0"
                      >mdi-menu-right</v-icon
                    ></a
                  >
                </div>
            </span>
            
            <div class="flex-grow-1 desktop-nav"></div>
            <span>
              <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                      <cluster-select :large="false" @clearSelections="selected = [], selectAll=false, selectedEntities = []" :disabled="notSelected" :entityType="'supplier'" :entityIDType="'supplier_id'" :entities="selected" />
                  </span>
                </template>
                <span>Add {{ entity.name }} to a cluster</span>
              </v-tooltip>
            </span>
            <span>
              <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    large
                    icon
                    color="#4EB888"
                    light
                    style="text-decoration: none !important;"
                    :to="{ name: 'search', query: { supplier_id: entity.id } }"
                  >
                    <v-icon>mdi-format-list-bulleted</v-icon>
                  </v-btn>
                </template>
                <span>View all {{ entity.name }} awards</span>
              </v-tooltip>
            </span>
            <span>
              <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    large
                    icon
                    color="secondary"
                    light
                    style="text-decoration: none !important;"
                    target="_blank"
                    :to="{ name: 'report', query: { supplier_id: entity.id, 
                                                    comparison_sum_value: 'active', 
                                                    comparison_group_value: 'publisher',
                                                    scatter_group_value: 'procurement_method',
                                                    scatter_num_groups: 5} }"
                  >
                    <v-icon>mdi-chart-box-outline</v-icon>
                  </v-btn>
                </template>
                <span>View this supplier's insights report</span>
              </v-tooltip>
            </span>
            <span>
              <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    large
                    icon
                    color="secondary"
                    light
                    style="text-decoration: none !important;"
                    target="_blank"
                    :to="{ name: 'network', query: { supplier_id: entity.id} }"
                  >
                    <v-icon>mdi-graphql</v-icon>
                  </v-btn>
                </template>
                <span>View this supplier's network</span>
              </v-tooltip>
            </span>
            <span>
              <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    large
                    icon
                    color="#4EB888"
                    light
                    :href="entity.website"
                    target="_blank"
                    style="text-decoration: none !important;"
                    :disabled="disableIcon(entity.website)"
                  >
                    <v-icon>mdi-web</v-icon>
                  </v-btn>
                </template>
                <span>Go to Website</span>
              </v-tooltip>
            </span>
            <span v-if="entity.metadata && entity.metadata.linkedin">
                  <v-btn
                    large
                    icon
                    color="#4EB888"
                    light
                    :href="'https://'+entity.metadata.linkedin"
                    target="_blank"
                    style="text-decoration: none !important;"
                  >
                    <v-icon>mdi-linkedin</v-icon>
                  </v-btn>
            </span>
            <span v-if="entity.metadata && entity.metadata.twitter">
                  <v-btn
                    large
                    icon
                    color="#4EB888"
                    light
                    :href="'https://'+entity.metadata.twitter"
                    target="_blank"
                    style="text-decoration: none !important;"
                  >
                    <v-icon>mdi-twitter</v-icon>
                  </v-btn>
            </span>
            
            </v-card-title
          >
          <v-card-subtitle>
            <div
              v-if="entity.company_number"
              style="color:#12253a;"
              class="font-weight-strong"
            >
              <b>ABN</b> {{ entity.company_number }}
            </div>

            <div
              v-if="entity.metadata && entity.metadata.numEmployees"
              style="color:#12253a;"
              class="font-weight-strong"
            >
              <b>STAFF</b> {{getSizeText(entity.metadata.numEmployees.max)}}
            </div>

            <div v-if="entity.metadata" class="font-weight-normal subtitle-2 pt-0 pb-0">
              <a @click="info_dialog = true"
                >Description<v-icon color="#41a7d9" class="pl-0 ml-0"
                  >mdi-menu-right</v-icon
                ></a
              >
            </div>
            <span v-if="entity.metadata">
              <span v-for="e in entity.metadata.industry" :key="e">
                <router-link target="_blank"
                            :to="{ name: 'industry', query: { supplier_industry: e } }"
                          >
                    <v-chip
                        class="my-1 ml-0 mr-2"
                        color="secondary"
                        label
                        text-color="secondary"
                        small
                        outlined
                  >
                    <v-icon small left>
                      mdi-label
                    </v-icon>
                    {{e}}
                  </v-chip>
                </router-link>
                </span>
            </span>
            <span v-for="clstr in entity.clusters" :key="clstr.id">
              <router-link target="_blank"
                            :to="{ name: 'cluster', params: { clusterId: clstr.id } }"
                          >
                    <v-chip v-if="clstr"
                      class="my-1 ml-0 mr-2"
                      color="primary"
                      label
                      text-color="primary"
                      small
                      outlined
                    >     
                    <v-icon small left>
                      mdi-hexagon-multiple
                    </v-icon>
                    {{clstr.name}}
                    </v-chip>
              </router-link>
            </span>
            <div
              v-if="entity.metadata && entity.metadata.competitors && entity.metadata.competitors.length > 0"
              class="font-weight-normal subtitle-2 pt-3 pb-0"
            >
              <a @click="similar_dialog = true"
                >Similar companies or competitors<v-icon color="#41a7d9" class="pl-0 ml-0"
                  >mdi-menu-right</v-icon
                ></a
              >
            </div>
            
          </v-card-subtitle>
          <v-row class="ma-0 pa-0">
            <v-divider></v-divider>
            <v-col cols="12" md="6" class="ma-0 pa-0">
              <v-card flat>
                <v-card-text class="pt-2 pb-0 font-weight-normal" >Active Contracts</v-card-text>
                <v-divider class="mx-3"></v-divider>
                <v-row class="ma-0 pa-0">
                  
                  <v-col cols="4" class="ma-0 pa-0">
                    <v-card tile flat class="py-0 my-0">
                      <v-card-title
                        class="font-weight-normal body-2"
                        style="color:#12253a;"
                        >Value</v-card-title
                      >
                      <v-card-subtitle>
                        <div class="font-weight-medium text-h6" style="color:#12253a;">
                          {{ formattedCurrency(entity.active_contracts.stats.sum) }}
                        </div>
                        <div class="font-weight-normal body-2">
                          <router-link
                            :to="{
                                name: 'search',
                                query: {
                                  supplier_id: entity.id,
                                  disposition: ['Contract Notice','Grant'],
                                  expiry_date_from: new Date().toISOString().split('T')[0],
                                  expiry_date_to: new Date(new Date().getFullYear() + 50, new Date().getMonth(), new Date().getDate()).toISOString().split('T')[0],
                                  sort_order: 'asc'
                                }
                              }"
                          >
                            {{ entity.active_contracts.stats.count}}
                            contracts</router-link
                          >
                        </div>
                      </v-card-subtitle>
                    </v-card>
                  </v-col>
                
                  <v-col cols="4"  class="ma-0 pa-0">
                    <v-card tile flat class="py-0 my-0">
                      <v-card-title
                        class="font-weight-normal body-2"
                        style="color:#12253a;"
                        >Highest Value</v-card-title
                      >
                      <v-card-subtitle>
                        <div class="font-weight-medium text-h6" style="color:#12253a;">
                          {{ formattedCurrency(entity.active_contracts.stats.max) }}
                        </div>
                        <div class="font-weight-normal body-2" style="color:#12253a;">
                          Contract
                        </div>
                      </v-card-subtitle>
                    </v-card>
                  </v-col>

                  <v-col cols="4"  class="ma-0 pa-0">
                    <v-card tile flat class="py-0 my-0">
                      <v-card-title
                        class="font-weight-normal body-2"
                        style="color:#12253a;"
                        >Avg Contract</v-card-title
                      >
                      <v-card-subtitle>
                        <div class="font-weight-medium text-h6" style="color:#12253a;">
                          {{ formattedCurrency(entity.active_contracts.stats.avg) }}
                        </div>
                        <div class="font-weight-normal body-2" style="color:#12253a;">
                          Active
                        </div>
                      </v-card-subtitle>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" md="6" class="ma-0 pa-0">
              <v-card flat>
                <v-card-text class="pt-2 pb-0 font-weight-normal" >Awards - All Time</v-card-text>
                <v-divider class="mx-3"></v-divider>
                <v-row class="ma-0 pa-0">
                  
                  <v-col cols="4" class="ma-0 pa-0">
                    <v-card tile flat class="py-0 my-0">
                      <v-card-title
                        class="font-weight-normal body-2"
                        style="color:#12253a;"
                        >Value</v-card-title
                      >
                      <v-card-subtitle>
                        <div class="font-weight-medium text-h6" style="color:#12253a;">
                          {{ formattedCurrency(entity.award_total) }}
                        </div>
                        <div class="font-weight-normal body-2">
                          <router-link
                            :to="{ name: 'search', query: { supplier_id: entity.id } }"
                          >
                            {{ entity.num_notices
                            }}<span v-if="entity.num_notices == 10000">+</span>
                            awards</router-link
                          >
                        </div>
                      </v-card-subtitle>
                    </v-card>
                  </v-col>
                
                  <v-col cols="4"  class="ma-0 pa-0">
                    <v-card tile flat class="py-0 my-0">
                      <v-card-title
                        class="font-weight-normal body-2"
                        style="color:#12253a;"
                        >Avg Per Year</v-card-title
                      >
                      <v-card-subtitle>
                        <div class="font-weight-medium text-h6" style="color:#12253a;">
                          {{ formattedCurrency(entity.award_total / date_aggs.length) }}
                        </div>
                        <div class="font-weight-normal body-2" style="color:#12253a;">
                          {{date_aggs.length}} years
                        </div>
                      </v-card-subtitle>
                    </v-card>
                  </v-col>

                  <v-col cols="4"  class="ma-0 pa-0">
                    <v-card tile flat class="py-0 my-0">
                      <v-card-title
                        class="font-weight-normal body-2"
                        style="color:#12253a;"
                        >Avg Contract</v-card-title
                      >
                      <v-card-subtitle>
                        <div class="font-weight-medium text-h6" style="color:#12253a;">
                          {{ formattedCurrency(avg_contract) }}
                        </div>
                        <div class="font-weight-normal body-2" style="color:#12253a;">
                          All contracts
                        </div>
                      </v-card-subtitle>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" md="6" class="ma-0 pa-0">
              <v-card flat>
                <v-card-text class="pt-2 pb-0 font-weight-normal">Awards - Last 12 Months</v-card-text>
                <v-divider class="mx-3"></v-divider>
                <v-row class="ma-0 pa-0">
                <v-col cols="4"  class="ma-0 pa-0">
                  <v-card tile flat class="py-0 my-0">
                    <v-card-title
                      class="font-weight-normal body-2"
                      style="color:#12253a;"
                      >Value</v-card-title 
                    >
                    <v-card-subtitle>
                      <div class="font-weight-medium text-h6" style="color:#12253a;">
                        {{ formattedCurrency(entity.growth.current_year_total) }}
                      </div>
                      <div class="font-weight-normal body-2" style="color:#12253a;">
                        <v-icon :color="growth_format(entity.growth.spend_change_percent).color">
                          {{growth_format(entity.growth.spend_change_percent).icontype}}
                        </v-icon>
                        {{ growth_format(entity.growth.spend_change_percent).change }}%
                      </div>
                    </v-card-subtitle>
                  </v-card>
                </v-col>
                <v-col cols="4"  class="ma-0 pa-0">
                  <v-card tile flat class="py-0 my-0">
                    <v-card-title
                      class="font-weight-normal body-2"
                        style="color:#12253a;"
                      >Avg Contract</v-card-title 
                    >
                    <v-card-subtitle>
                      <div class="font-weight-medium text-h6" style="color:#12253a;">
                        {{ formattedCurrency(entity.growth.curr_yr_avg_contract) }}
                      </div>
                      <div class="font-weight-normal body-2" style="color:#12253a;">
                        <v-icon :color="growth_format(entity.growth.avg_change_percent).color">
                          {{growth_format(entity.growth.avg_change_percent).icontype}}
                        </v-icon>
                        {{ growth_format(entity.growth.avg_change_percent).change }}%
                      </div>
                    </v-card-subtitle>
                  </v-card>
                </v-col> 
                <v-col cols="4"  class="ma-0 pa-0">
                  <v-card tile flat class="py-0 my-0">
                    <v-card-title
                      class="font-weight-normal body-2"
                        style="color:#12253a;"
                      >Contracts</v-card-title 
                    >
                    <v-card-subtitle>
                      <div class="font-weight-medium text-h6" style="color:#12253a;">
                        <router-link
                           :to="{
                              name: 'search',
                              query: {
                                supplier_id: entity.id,
                                disposition: ['Contract Notice','Grant'],
                                contract_start_from: new Date().toISOString().split('T')[0],
                                contract_start_to: new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate()).toISOString().split('T')[0],
                                sort_order: 'asc'
                              }
                            }"
                          >
                            {{ numFormatter(entity.growth.current_year_vol) }}
                        </router-link>
                      </div>
                      <div class="font-weight-normal body-2" style="color:#12253a;">
                        <v-icon :color="growth_format(entity.growth.vol_change_percent).color">
                          {{ growth_format(entity.growth.vol_change_percent).icontype }}
                        </v-icon>
                        {{ growth_format(entity.growth.vol_change_percent).change }}%
                      </div>
                    </v-card-subtitle>
                  </v-card>
                </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" md="6" class="ma-0 pa-0">
              <v-card flat>
                <v-card-text class="pt-2 pb-0 font-weight-normal">Expiring - Next 12 Months</v-card-text>
                <v-divider class="mx-3"></v-divider>
                <v-row class="ma-0 pa-0">
                <v-col cols="4"  class="ma-0 pa-0">
                  <v-card tile flat class="py-0 my-0">
                    <v-card-title
                      class="font-weight-normal body-2"
                        style="color:#12253a;"
                      >Value</v-card-title 
                    >
                    <v-card-subtitle>
                      <div class="font-weight-medium text-h6" style="color:#12253a;">
                        {{ formattedCurrency(entity.expiring_soon.next_year_total) }}
                      </div>
                      <div class="font-weight-normal body-2" style="color:#12253a;">
                        <v-icon :color="growth_format(entity.expiring_soon.spend_change_percent).color">
                          {{growth_format(entity.expiring_soon.spend_change_percent).icontype}}
                        </v-icon>
                        {{ growth_format(entity.expiring_soon.spend_change_percent).change }}%
                      </div>
                    </v-card-subtitle>
                  </v-card>
                </v-col>
                <v-col cols="4"  class="ma-0 pa-0">
                  <v-card tile flat class="py-0 my-0">
                    <v-card-title
                      class="font-weight-normal body-2"
                      style="color:#12253a;"
                      >Avg Contract</v-card-title 
                    >
                    <v-card-subtitle>
                      <div class="font-weight-medium text-h6" style="color:#12253a;">
                        {{ formattedCurrency(entity.expiring_soon.next_yr_avg_contract) }}
                      </div>
                      <div class="font-weight-normal body-2" style="color:#12253a;">
                        <v-icon :color="growth_format(entity.expiring_soon.avg_change_percent).color">
                          {{growth_format(entity.expiring_soon.avg_change_percent).icontype}}
                        </v-icon>
                        {{ growth_format(entity.expiring_soon.avg_change_percent).change }}%
                      </div>
                    </v-card-subtitle>
                  </v-card>
                </v-col> 
                <v-col cols="4"  class="ma-0 pa-0">
                  <v-card tile flat class="py-0 my-0">
                    <v-card-title
                      class="font-weight-normal body-2"
                        style="color:#12253a;"
                      >Contracts</v-card-title 
                    >
                    <v-card-subtitle>
                      <div class="font-weight-medium text-h6" style="color:#12253a;">
                        <router-link
                           :to="{
                              name: 'search',
                              query: {
                                supplier_id: entity.id,
                                disposition: ['Contract Notice','Grant'],
                                contract_end_from: new Date().toISOString().split('T')[0],
                                contract_end_to: new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate()).toISOString().split('T')[0],
                                sort_order: 'asc'
                              }
                            }"
                          >
                            {{ numFormatter(entity.expiring_soon.next_year_vol) }}
                        </router-link>
                        
                      </div>
                      <div class="font-weight-normal body-2" style="color:#12253a;">
                        <v-icon :color="growth_format(entity.expiring_soon.vol_change_percent).color">
                          {{ growth_format(entity.expiring_soon.vol_change_percent).icontype }}
                        </v-icon>
                        {{ growth_format(entity.expiring_soon.vol_change_percent).change }}%
                      </div>
                    </v-card-subtitle>
                  </v-card>
                </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          
          
        </v-card>
      </v-col>
    </v-row>
    <v-tabs class="pt-4 pb-1" color="rgb(18, 37, 58)" show-arrows>
      <v-tabs-slider color="rgb(255, 20, 99)"></v-tabs-slider>
      <v-tab>
        Analysis
      </v-tab>
      <v-tab>
        Recent Awards
      </v-tab>
      <v-tab @click="selectedFilter = 'all_entities', updateBuyerList({'supplier_id': [entity.id]}), getBuyerStats(entity.id)">
        Buyers
      </v-tab>
      <v-tab @click="selectedFilter = 'common_entities', updateBuyerList({'supplier_id': [entity.id],
                                                          'result_filter': 
                                                            { 'filter_name': 'common_entities', 
                                                              'filter_target_type': 'supplier', 
                                                              'filter_target_id': [entity.id]}}), getBuyerStats(entity.id)">
        Account Overlaps
      </v-tab>
      <v-tab>
        Categories
      </v-tab>
      <v-tab v-if="entity && entity.entity_amendment_rollup">
        Amendments
      </v-tab>
        <v-tab-item>
          <v-row class="py-3">
            <v-col
              cols="12"
              sm="7"
              class="pl-md-3 pr-md-1 py-md-1 px-xs-0 py-xs-0"
            >
              <v-card tile flat>
                <spending-timeline
                  :date_aggs="date_aggs"
                  :entity_aggs="publisher_aggs"
                  :expiry_aggs="expiry_aggs"
                  :contracting_activity="contracting_activity"
                  :expiring_activity="expiring_activity"
                  :annual_spend="annual_spend"
                  :entity_type="'publisher'"
                  :metric_name="'total_amount'"
                  :units="'Amount'"
                  :title="'Awards Over Time - Top Buyers'"
                  :supplier_id="entity.id"
                />
              </v-card>
            </v-col>
            <v-col
              cols="12"
              sm="3"
              class="pl-md-1 pr-md-1 py-md-1 px-xs-0 py-xs-0"
            >
              <v-card tile flat>
                <graph-data-view :chartGroup="'publisher'" />
              </v-card>
            </v-col>
            <v-col
              cols="12"
              sm="2"
              class="pl-md-1 pr-md-3 py-md-1 px-xs-0 py-xs-0"
            >
              <v-card tile flat>
                <spending-donut
                  :entity_type="'publisher'"
                  :metric_name="'total_amount'"
                  :search="search_terms"
                  :entity_data="publisher_aggs"
                  :title="'Total By Buyer'"
                />
              </v-card>
            </v-col>
            <v-col
              cols="12"
              sm="7"
              class="pl-md-3 pr-md-1 py-md-1 px-xs-0 py-xs-0"
            >
              <v-card tile flat>
                <spending-timeline
                  :date_aggs="date_aggs"
                  :expiry_aggs="expiry_aggs"
                  :contracting_activity="contracting_activity"
                  :expiring_activity="expiring_activity"
                  :entity_aggs="category_aggs"
                  :annual_spend="annual_spend"
                  :entity_type="'major_category'"
                  :metric_name="'total_amount'"
                  :units="'Amount'"
                  :title="'Awards Over Time - Top Categories'"
                  :supplier_id="entity.id"
                />
              </v-card>
            </v-col>
            <v-col
              cols="12"
              sm="3"
              class="pl-md-1 pr-md-1 py-md-1 px-xs-0 py-xs-0"
            >
              <v-card tile flat>
                <graph-data-view :chartGroup="'parent_category'" />
              </v-card>
            </v-col>
            <v-col
              cols="12"
              sm="2"
              class="pl-md-1 pr-md-3 py-md-1 px-xs-0 py-xs-0"
            >
              <v-card tile flat>
                <spending-donut
                  :entity_type="'major_category'"
                  :metric_name="'total_amount'"
                  :search="search_terms"
                  :entity_data="category_aggs"
                  :title="'Total By Category'"
                />
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <v-row style="background-color:#f5f5f5">
            <v-col cols="12" class="pt-3">
              <results-table
                class="pb-3 pt-0 pl-0 pr-0"
                :hit_info="hit_info"
                :tenders="tenders"
                :hide_pagination="true"
              />
              <v-overlay absolute="true" :value="overlay">
                <v-progress-circular
                  indeterminate
                  size="64"
                ></v-progress-circular>
              </v-overlay>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
            <v-row style="background-color:#f5f5f5">
              <v-col cols="12" md="12" class="mt-1 pt-3 pb-1">
                <v-card v-if="buyer_stats_loaded" flat tile width="100%">
                  <v-card flat tile width="100%">
                    <v-row class="ma-0 pa-0">
                      <v-col cols="2"  class="ma-0 pa-0">
                        <v-card tile flat class="py-0 my-0">
                          <v-card-title
                            class="font-weight-normal body-2"
                              style="color:#12253a;"
                            >Total</v-card-title 
                          >
                          <v-card-subtitle>
                            <div class="font-weight-medium text-h6" style="color:#12253a;">
                              <a 
                                :class="filterColour ('all_entities')"
                                @click="selectedFilter = 'all_entities', updateBuyerList({'supplier_id': [entity.id]})">
                                {{ buyer_stats.total_buyers }}</a>
                            </div>
                            <div class="font-weight-normal body-2" style="color:#12253a;">
                              Buyers
                            </div>
                          </v-card-subtitle>
                        </v-card>
                      </v-col>
                      <v-col cols="2"  class="ma-0 pa-0">
                        <v-card tile flat class="py-0 my-0">
                          <v-card-title
                            class="font-weight-normal body-2"
                              style="color:#12253a;"
                            >Current</v-card-title 
                          >
                          <v-card-subtitle>
                            <div class="font-weight-medium text-h6" style="color:#12253a;">
                              <a 
                                :class="filterColour ('current_entities')"
                                @click="selectedFilter = 'current_entities', updateBuyerList({'supplier_id': [entity.id], 
                                                            expiry_from: new Date().toISOString().split('T')[0],
                                                            expiry_to: new Date(new Date().getFullYear() + 100, new Date().getMonth(), new Date().getDate()).toISOString().split('T')[0]})">
                                                            {{ buyer_stats.current_buyers }}                           
                              </a>
                              
                            </div>
                            <div class="font-weight-normal body-2" style="color:#12253a;">
                              Buyers
                            </div>
                          </v-card-subtitle>
                        </v-card>
                      </v-col>
                      <v-col cols="2"  class="ma-0 pa-0">
                        <v-card tile flat class="py-0 my-0">
                          <v-card-title
                            class="font-weight-normal body-2"
                            style="color:#12253a;"
                            >New - 12 Months</v-card-title 
                          >
                          <v-card-subtitle>
                            <div class="font-weight-medium text-h6" style="color:#12253a;">
                              <a v-if="buyer_stats.current_year > 0"
                                :class="filterColour ('new_entities_ytd')"
                                @click="selectedFilter = 'new_entities_ytd', updateBuyerList({'supplier_id': [entity.id],
                                                            'result_filter': 
                                                              { 'filter_name': 'new_entities_ytd', 
                                                                'filter_target_type': 'supplier', 
                                                                'filter_target_id': [entity.id]}})">
                                {{ buyer_stats.current_year }}                          
                              </a>
                              <span v-else>0</span>
                            </div>
                            <div class="font-weight-normal body-2" style="color:#12253a;">
                              <v-icon :color="growth_format(buyer_stats.per_change).color">
                                {{growth_format(buyer_stats.per_change).icontype}}
                              </v-icon>
                              {{ growth_format(buyer_stats.per_change).change }}%
                            </div>
                          </v-card-subtitle>
                        </v-card>
                      </v-col>
                      <v-col cols="2"  class="ma-0 pa-0">
                        <v-card tile flat class="py-0 my-0">
                          <v-card-title
                            class="font-weight-normal body-2"
                              style="color:#12253a;"
                            ><span>Repeat Buyers</span>
                            <v-tooltip left max-width="250">
                              <template v-slot:activator="{ on }">
                                <v-btn x-small v-on="on" icon target="_blank" href="https://docs.tendertrace.com/guide/user-documentation/transparency-integrity-and-risk">
                                  <v-icon  small color="primary">mdi-information-outline</v-icon>
                                </v-btn>
                              </template>
                              <span>Buyers that have contracted more than once with this supplier.</span
                                >
                                <br />
                                Click the <v-icon small color="white">mdi-information-outline</v-icon> icon for more information.
                            </v-tooltip>
                            </v-card-title 
                          >
                          <v-card-subtitle>
                            <div class="font-weight-medium text-h6" style="color:#12253a;">
                              <a v-if="buyer_stats.num_buyers_with_multiple_deals > 0"
                                :class="filterColour ('repeat_entities')"
                                @click="selectedFilter = 'repeat_entities', updateBuyerList({'supplier_id': [entity.id],
                                                            'result_filter': 
                                                              { 'filter_name': 'repeat_entities', 
                                                                'filter_target_type': 'supplier', 
                                                                'filter_target_id': [entity.id]}})">
                                {{ growth_format(Math.round(buyer_stats.num_buyers_with_multiple_deals / buyer_stats.total_buyers * 100)).change }}%                          
                              </a>
                              <span v-else>0</span>
                            </div>
                            <div class="font-weight-normal body-2" style="color:#12253a;">
                              {{ buyer_stats.num_buyers_with_multiple_deals }}
                            </div>
                          </v-card-subtitle>
                        </v-card>
                      </v-col>
                      <v-col cols="2"  class="ma-0 pa-0">
                        <v-card tile flat class="py-0 my-0">
                          <v-card-title
                            class="font-weight-normal body-2"
                              style="color:#12253a;"
                            ><span>Exclusive Buyers</span>
                            <v-tooltip left max-width="250">
                              <template v-slot:activator="{ on }">
                                <v-btn x-small v-on="on" icon target="_blank" href="https://docs.tendertrace.com/guide/user-documentation/transparency-integrity-and-risk">
                                  <v-icon  small color="primary">mdi-information-outline</v-icon>
                                </v-btn>
                              </template>
                              <span>Buyers that have only contracted with this entity.</span
                                >
                                <br />
                                Click the <v-icon small color="white">mdi-information-outline</v-icon> icon for more information.
                            </v-tooltip>
                            </v-card-title 
                          >
                          <v-card-subtitle>
                            <div class="font-weight-medium text-h6" style="color:#12253a;">
                              <a v-if="buyer_stats.sole_source_count > 0"  
                              
                                :class="filterColour ('exclusive_entities')"
                                @click="selectedFilter = 'exclusive_entities', updateBuyerList({'supplier_id': [entity.id],
                                                            'result_filter': 
                                                              { 'filter_name': 'exclusive_entities', 
                                                                'filter_target_type': 'supplier', 
                                                                'filter_target_id': [entity.id]}})">
                              {{ growth_format(Math.round(buyer_stats.sole_source_count / buyer_stats.total_buyers * 100)).change }}%</a>
                              
                              <span v-else>{{buyer_stats.sole_source_count}}</span>
                              
                            </div>
                            <div class="font-weight-normal body-2" style="color:#12253a;">
                              {{ buyer_stats.sole_source_count }}
                            </div>
                          </v-card-subtitle>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-card>
              </v-col>
              <v-col cols="12" class="pt-0">
                  <profile-members 
                      :startIndex="0" 
                      :profile_id="entity.id"
                      :profile_name="entity.name"
                      :profile_type="'supplier'" 
                      :entity_type="'publisher'" 
                      :key="buyer_list_change"
                      :query="buyer_list_query.query"></profile-members>
              </v-col>
            </v-row>
        </v-tab-item>
        <v-tab-item>
            <v-row style="background-color:#f5f5f5">
              <v-col cols="12" md="12" class="mt-1 pt-3 pb-1">
                <v-card v-if="buyer_stats_loaded" flat tile width="100%">
                  <v-card flat tile width="100%">
                    <v-row class="ma-0 pa-0">
                      <v-col cols="2"  class="ma-0 pa-0">
                        <v-card tile flat class="py-0 my-0">
                          <v-card-title
                            class="font-weight-normal body-2"
                              style="color:#12253a;"
                            ><span>Common Accounts</span>
                            <v-tooltip left max-width="250">
                              <template v-slot:activator="{ on }">
                                <v-btn x-small v-on="on" icon target="_blank" href="https://docs.tendertrace.com/guide/user-documentation/transparency-integrity-and-risk">
                                  <v-icon  small color="primary">mdi-information-outline</v-icon>
                                </v-btn>
                              </template>
                              <span>Past or present customers of {{ entity.name }} that have also had contracts with my company.</span
                                >
                                <br />
                                Click the <v-icon small color="white">mdi-information-outline</v-icon> icon for more information.
                            </v-tooltip>
                            </v-card-title 
                          >
                          <v-card-subtitle>
                            <div class="font-weight-medium text-h6" style="color:#12253a;">
                              <a v-if="buyer_stats.common_entities > 0"  
                              
                                :class="filterColour ('common_entities')"
                                @click="selectedFilter = 'common_entities', updateBuyerList({'supplier_id': [entity.id],
                                                            'result_filter': 
                                                              { 'filter_name': 'common_entities', 
                                                                'filter_target_type': 'supplier', 
                                                                'filter_target_id': [entity.id]}})">
                              {{ growth_format(Math.round(buyer_stats.common_entities / buyer_stats.total_buyers * 100)).change }}%</a>
                              
                              <span v-else>{{buyer_stats.common_entities}}</span>
                              
                            </div>
                            <div class="font-weight-normal body-2" style="color:#12253a;">
                              {{ buyer_stats.common_entities }}
                            </div>
                          </v-card-subtitle>
                        </v-card>
                      </v-col>
                      <v-col cols="3"  class="ma-0 pa-0">
                        <v-card tile flat class="py-0 my-0">
                          <v-card-title
                            class="font-weight-normal body-2"
                              style="color:#12253a;"
                            ><span>Accounts in my prospect lists</span>
                            <v-tooltip left max-width="250">
                              <template v-slot:activator="{ on }">
                                <v-btn x-small v-on="on" icon target="_blank" href="https://docs.tendertrace.com/guide/user-documentation/transparency-integrity-and-risk">
                                  <v-icon  small color="primary">mdi-information-outline</v-icon>
                                </v-btn>
                              </template>
                              <span>Past or present customers of {{ entity.name }} that are in my prospect lists</span
                                >
                                <br />
                                Click the <v-icon small color="white">mdi-information-outline</v-icon> icon for more information.
                            </v-tooltip>
                            </v-card-title 
                          >
                          <v-card-subtitle>
                            <div class="font-weight-medium text-h6" style="color:#12253a;">
                              <a v-if="buyer_stats.prospect_list_accounts > 0"  
                              
                                :class="filterColour ('prospect_list_accounts')"
                                @click="selectedFilter = 'prospect_list_accounts', updateBuyerList({'supplier_id': [entity.id],
                                                            'result_filter': 
                                                              { 'filter_name': 'prospect_list_accounts', 
                                                                'filter_target_type': 'supplier', 
                                                                'filter_target_id': [entity.id]}})">
                              {{ growth_format(Math.round(buyer_stats.prospect_list_accounts / buyer_stats.total_buyers * 100)).change }}%</a>
                              
                              <span v-else>{{buyer_stats.prospect_list_accounts}}</span>
                              
                            </div>
                            <div class="font-weight-normal body-2" style="color:#12253a;">
                              {{ buyer_stats.prospect_list_accounts }}
                            </div>
                          </v-card-subtitle>
                        </v-card>
                      </v-col>
                      <v-col cols="3"  class="ma-0 pa-0">
                        <v-card tile flat class="py-0 my-0">
                          <v-card-title
                            class="font-weight-normal body-2"
                              style="color:#12253a;"
                            ><span>Accounts in my buyer clusters</span>
                            <v-tooltip left max-width="250">
                              <template v-slot:activator="{ on }">
                                <v-btn x-small v-on="on" icon target="_blank" href="https://docs.tendertrace.com/guide/user-documentation/transparency-integrity-and-risk">
                                  <v-icon  small color="primary">mdi-information-outline</v-icon>
                                </v-btn>
                              </template>
                              <span>Past or present customers of {{ entity.name }} that are in my buyer clusters</span
                                >
                                <br />
                                Click the <v-icon small color="white">mdi-information-outline</v-icon> icon for more information.
                            </v-tooltip>
                            </v-card-title 
                          >
                          <v-card-subtitle>
                            <div class="font-weight-medium text-h6" style="color:#12253a;">
                              <a v-if="buyer_stats.cluster_buyers > 0"  
                              
                                :class="filterColour ('cluster_buyers')"
                                @click="selectedFilter = 'cluster_buyers', updateBuyerList({'supplier_id': [entity.id],
                                                            'result_filter': 
                                                              { 'filter_name': 'cluster_buyers', 
                                                                'filter_target_type': 'supplier', 
                                                                'filter_target_id': [entity.id]}})">
                              {{ growth_format(Math.round(buyer_stats.cluster_buyers / buyer_stats.total_buyers * 100)).change }}%</a>
                              
                              <span v-else>{{buyer_stats.cluster_buyers}}</span>
                              
                            </div>
                            <div class="font-weight-normal body-2" style="color:#12253a;">
                              {{ buyer_stats.cluster_buyers }}
                            </div>
                          </v-card-subtitle>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-card>
              </v-col>
              <v-col cols="12" class="pt-0">
                  <profile-members 
                      :startIndex="0" 
                      :profile_id="entity.id"
                      :profile_name="entity.name"
                      :profile_type="'supplier'" 
                      :entity_type="'publisher'" 
                      :key="buyer_list_change"
                      :query="buyer_list_query.query"></profile-members>
              </v-col>
            </v-row>
        </v-tab-item>
        <v-tab-item>
            <v-row style="background-color:#f5f5f5">
            <v-col cols="12" class="pt-3">
                <profile-members 
                    :startIndex="1" 
                    :profile_id="entity.id" 
                    :profile_type="'supplier'" 
                    :profile_name="entity.name"
                    :entity_type="'category'"
                    :query="{'supplier_id': entity.id}"
                    ></profile-members>
            </v-col>
            </v-row>
        </v-tab-item>
        <v-tab-item v-if="entity && entity.entity_amendment_rollup">
            <v-row style="background-color:#f5f5f5">
            <v-col cols="12" class="pt-3">
                <v-card flat tile>
                  <v-card-text class="caption">
                    This analysis applies to Federal government contracts only. State and territory governments do not clearly articulate contract amendments. 
                    The analysis compares the relative value of the <b>first amendment</b> of the entity's contracts with the relative value of initial contract amendments in the market along with the relative duration between the start of the contract and the date of the first amendment.
                    Understanding these comparisons can provide an indication of the tendency of the entity to enter into the first amendment of a contract earlier than the norm, or for a higher relative value than the norm.
                  </v-card-text>
                </v-card>
                <amendment-analysis
                    :item="entity"
                    :entity_name="entity.name" 
                    ></amendment-analysis>
            </v-col>
            </v-row>
        </v-tab-item>
    </v-tabs>
    <v-dialog
      v-model="similar_dialog"
      max-width="500px"
      max-height="400px"
      hide-overlay
    >
      <v-card>
        <v-card-title class="subtitle-1 grey lighten-2">
          Similar companies to {{ entity.name }}
        </v-card-title>

        <v-card-text entity.metadata class="py-0">
          <v-list two-line>
            <v-list-item
              v-for="n in entity.metadata.competitors"
              :key="n.name"
              class="py-0"
            >
              <v-list-item-content>
                <v-list-item-title class="my-0 py-0"
                  >{{ n.name }}
                  <!-- <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                  <template v-slot:activator="{ on }">
                    <router-link target="_blank" :to="{ name: 'search', query: { supplier: n.name, viewIndex:0 } }" v-on="on">
                      <v-btn right icon>
                        <v-icon medium color="secondary">mdi-magnify</v-icon>
                      </v-btn>
                    </router-link>
                  </template>
                  <span>Search this term</span>
                </v-tooltip> -->
                  </v-list-item-title
                >
                  <v-list-item-subtitle v-if="n.num_notices > 0" class="my-0 py-0 font-weight-normal subtitle-2">
                    <router-link target="_blank" :to="{ name: 'search', query: { supplier: n.name, viewIndex:0 } }" v-on="on">
                      Find related contracts
                      <v-icon color="#41a7d9" class="pl-0 ml-0"
                          >mdi-menu-right</v-icon
                        ></router-link>
                  </v-list-item-subtitle>
                <!-- <v-list-item-subtitle v-if="n.num_notices > 0" class="my-0 py-0">
                  <b>{{ n.num_notices }}</b> awards worth
                  <b>{{
                    formattedCurrency(n.award_total)
                  }}</b></v-list-item-subtitle
                >
                <v-list-item-subtitle v-else class="my-0 py-0">
                  <b>0 direct awards found</b></v-list-item-subtitle
                > -->
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="danger" text @click="similar_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="info_dialog"
      max-width="500px"
      max-height="400px"
      hide-overlay
    >
      <v-card>
        <v-card-title class="subtitle-1 grey lighten-2">
          About {{ entity.name }}
        </v-card-title>

        <v-card-text class="py-2">
          {{ entity.metadata.description }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="danger" text @click="info_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="related_dialog"
      max-width="500px"
      max-height="400px"
      hide-overlay
    >
      <v-card>
        <v-card-title class="subtitle-1 grey lighten-2">
          Other names for {{ entity.name }}
        </v-card-title>

        <v-card-text class="py-0">
          <v-list two-line>
            <v-list-item
              v-for="n in related_entities"
              :key="n"
              class="py-0"
            >
              <v-list-item-content>
                <v-list-item-title class="my-0 py-0"
                  >{{ n }}
                  </v-list-item-title
                >
                <v-list-item-subtitle class="my-0 py-0 font-weight-normal subtitle-2">
                    <router-link target="_blank" :to="{ name: 'search', query: { supplier: n, viewIndex:0 } }" v-on="on">
                      Find related contracts
                      <v-icon color="#41a7d9" class="pl-0 ml-0"
                          >mdi-menu-right</v-icon
                        ></router-link>
                  </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="danger" text @click="related_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { bus } from "@/main.js";
import {
  entitySearch,
  histogramSearch,
  fetchNews,
  executeSearch,
  buyerStats
} from "../APIManager.js";
//import { Doughnut } from "vue-chartjs";
import { growth_format, numFormatter, formattedCurrency } from "../Utils.js";
//import EntityTable from "../entities/EntityTable";
import SpendingTimeline from "../SpendingTimeline.vue";
//import CategoryTable from "../entities/CategoryTable";
import ProfileMembers from "../ProfileMembers.vue";
import ResultsTable from "../ResultsTable.vue";
import SpendingDonut from "../SpendingDonut.vue";
import GraphDataView from "../GraphDataView.vue";
import ClusterSelect from "../ClusterSelect.vue";
import AmendmentAnalysis from "../AmendmentAnalysis.vue";

export default {
  components: {
    SpendingDonut,
    ResultsTable,
    SpendingTimeline,
    GraphDataView,
    ProfileMembers,
    ClusterSelect,
    AmendmentAnalysis
    //EntityTable,
    //CategoryTable
  },
  props: {
    entity: {},
    current_search: {},
    entity_id: String()
    //results_drawer: Boolean
  },
  data() {
    return {
      active_tab: 0,
      selectedFilter: 'all_entities',
      hit_info: {},
      related: [],
      tenders: [""],
      related_dialog: false,
      similar_dialog: false,
      info_dialog: false,
      alt_names_dialog: false,
      overlay: false,
      tabs: null,
      absolute: true,
      entity_panel: -9,
      entity_panels: [],
      panel_clicked: false,
      //current_index: 0,
      index: Number, //use the exp panel index as key so that we can cache the data
      profiles: [],
      mounted: false,
      entity_type: "supplier",
      total_amt_metric: "total_amount",
      search: {},
      category_aggs: [],
      date_aggs: [],
      publisher_aggs: [],
      contracting_activity: [],
      expiring_activity: [],
      expiry_aggs: [],
      gov_aggs: [],
      annual_spend: [],
      avg_contract: Number,
      following: false,
      industry: [],
      assessment_loaded: false,
      buyer_stats_loaded: false,
      assessment: {},
      buyer_stats: {},
      buyer_list_query: {"query": ""},
      supplier_list_change: Number
    };
  },

  computed: {
    selected(){
      return [this.entity.id];
    }, 

    related_entities(){
      var related = [];
      if (this.entity.metadata && this.entity.metadata.otherNames){
        related = this.buildAltNames(this.entity.metadata.otherNames);
        related = this.removeIfPresent(related, this.entity.name)
      }

      if (this.entity.published_name){
        this.addIfNotPresent(related, this.entity.published_name)
      }
      return related;
    }
  },

  created() {
    
    //this.active_tab = this.$route.query.active_tab || 0;
  },

  mounted() {
    this.overlay = true;
    this.loadProfile();
    
  },

  watch: {
    $route(to, from) {
      //this.active_tab = this.$route.query.active_tab || 0;
    },

  },

  methods: {

    growth_format,
    numFormatter,
    formattedCurrency,

    filterColour(name) {
      return this.selectedFilter == name ? 'selected-link' : 'not-selected-link';
    },

    updateBuyerList(query){
      this.buyer_list_query.query = JSON.parse(JSON.stringify(query));
      this.buyer_list_change = Math.random() + Math.random() + Math.random();
    },

    buildAltNames(arr){
      var updatedArr = this.filterASCIIStrings(arr);
      return updatedArr;
    },

    filterASCIIStrings(arr) {
      return arr.filter(str => {
          return /^[\x00-\x7F]*$/.test(str);
      });
    },

    addIfNotPresent(arr, str) {
      if (!arr.includes(str)) {
          arr.push(str);
      }
      return arr;
    },

    removeIfPresent(arr, str) {
      if (arr.includes(str)) {
          arr.pop(str);
      }
      return arr;
    },

    getSizeText(maxSize){

      if (maxSize <= 1000){
        return "< 1000"
      }
      else if (maxSize <= 5000){
        return "< 5000"
      }
      else{
        return "5000+"
      }

    },

    searchSimilar(name) {
      var qry = {supplier: name, viewIndex:1};

      this.$router.push({ path: "/search", query: qry });
      //bus.$emit("load-filters", JSON.parse(query));
    },

    reset_aggs(){
      this.expiry_aggs = [];
      this.expiring_activity = [];
      this.date_aggs = [];
      this.contracting_activity = [];
      this.annual_spend = [];
      this.category_aggs = [];
      this.supplier_aggs = [];
    },

    loadProfile() {
      
      this.search = { supplier_id: this.$route.params.id, size: 1 };
      this.loadTenderAggs();

      this.loadRecentAwards();
    },

    async loadRecentAwards() {
      let results = {};

      //let labels = [];
      results = await executeSearch(
        JSON.stringify({
          supplier_id: this.$route.params.id,
          disposition: ['Contract Notice','Grant'],
          sort_field: "contract_start",
          size: 10
        })
      );

      ////////////////console.log("TABLE RESULTS: ", results)

      this.tenders = results.data;
      this.hit_info = results.hits;
      this.overlay = false;
    },

    async loadTenderAggs() {
      //var results;
      //results = await executeSearch(this.search);
      //return results;

      this.loading_stats = true;

      try {
          const [histogramData, entityData] = await Promise.all([
              histogramSearch(this.search),
              entitySearch(this.search)
          ]);

          this.expiry_aggs = histogramData.aggregations.expiring.buckets;
          this.expiring_activity = histogramData.aggregations.expiring_activity.buckets;
          this.date_aggs = histogramData.aggregations.contracted.buckets;
          this.contracting_activity = histogramData.aggregations.contracting_activity.buckets;
          this.annual_spend = histogramData.aggregations.contracting_spend.buckets;
          this.avg_contract = histogramData.aggregations.avg_contract_value.value;

          this.category_aggs = entityData.aggregations.major_category.buckets;
          this.publisher_aggs = entityData.aggregations.publisher.buckets;
          this.gov_aggs = data.aggregations.government.buckets;
      } catch (error) {
          //console.error('Error fetching data:', error);
      } finally {
          this.loading_stats = false;
      }
    },

    async getBuyerStats(id) {
      if (this.buyer_stats_loaded == false){
        try {
          const data = await buyerStats(id);
          this.buyer_stats = data.data.buyer_stats;
          this.buyer_stats_loaded = true;
        } 
        catch (error) {
        }
      }
      else{
        return true;
      }
    },

    disableIcon(url) {
      if (!url) {
        return true;
      } else {
        return false;
      }
    },

    toggle() {
      this.watching = !this.watching;
      this.is_saved();
    },

    is_saved() {
      ////////////////console.log("NOTIFY STATUS: " + this.current_search.notify)
      return this.watching;
    },

    govAvatarColor(gov) {
      var color = "grey";
      switch (gov) {
        case "NSW":
          color = "#41a7d9";
          break;
        case "VIC":
          color = "#0058AC";
          break;
        case "WA":
          color = "#FFD200";
          break;
        case "QLD":
          color = "#A6003D";
          break;
        case "AUS":
          color = "#D8993B";
          break;
        case "FED":
          color = "#D8993B";
          break;
        case "DEF":
          color = "#d8673b";
          break;
        case "SA":
          color = "#ff4081";
          break;
        case "TAS":
          color = "#4eb888";
          break;
        case "ACT":
          color = "#41a7d9";
          break;
        case "NT":
          color = "#FF3647";
          break;
      }

      return color;
    }
  }
};
</script>

<style>

.selected-link {
    color: #ff4081 !important;
}

.not-selected-link {
    color: #12253a !important;
}

.chart {
  border-color: #424242 !important;
}

.headingUnderline {
  border-color: aqua !important;
}
</style>
