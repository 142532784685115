<template>
  <v-card class="chart_container pa-2" tile flat>
    <chart-header :title="title"
      :subtitle="subtitle"
      :widget_name="'overall-spend'"
      :total_result="total_records"
      :total_amount="total_amount"
      :search_query="plain_text_query"
      :data="dataview"
      :chart_settings="chart_settings"
      :embed_object="embed_object"
      :embed_obj_changed="embed_obj_changed"
      @embed="embed_code" 
      @downloadImage="downloadImage"/>
    <chart-loader :reportLoading="reportLoading" />
    <v-row>
        <div class="d-inline pl-4 mr-5">
          <p class="mb-0 pb-0 subtitle-2" style="font-weight:bold;">Metric</p>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="text-capitalize body-1"
                color="#E0E0E0"
                outlined
                v-bind="attrs"
                v-on="on"
                width="130"
                style="justify-content:left !important;"
              >
                <span style="color:#12253a;">{{metric.text}}</span>
                <v-icon color="#12253a" right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, value) in metric_options"
                :key="value"
                @click="metric = item, set_entity_aggs(), chartConfig(), updateSettings()"
              >
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="d-inline mr-5">
          <p class="mb-0 pb-0 subtitle-2" style="font-weight:bold;">Grouping</p>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              class="text-capitalize body-1"
                color="#E0E0E0"
                outlined
                v-bind="attrs"
                v-on="on"
                width="210"
                style="justify-content:left !important;"
              >
                <span style="color:#12253a;">{{group.text}}</span>
                <v-icon color="#12253a" right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, value) in group_options"
                :key="value"
                @click="group = item, set_entity_aggs(), chartConfig(), updateSettings()"
              >
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
    </v-row>
    <v-row>
      <v-col cols="12">
        <pie-echarts v-if="chart_data['labels']"
          :chart_data="chart_data"
          :metric_name="metric.value"
          :target_entity="target_entity"
          :changed="changed"
          :entity_type="entity_type"
          :show_labels="show_labels"
          :download="download"
          :chart_size="chart_size"
          :embed="embed"
          @chart_object="chart_object"
          @showTenders="showTenders"
          @dataview="loadDataView"
          :target_group="group.value"
          :id="id"
        />
      </v-col>
    </v-row>
    
  </v-card>
</template>

<script>
import { prepEntityBreakdown } from "./PrepDonut.js";
import { entitySearch } from "./APIManager.js";
import { silentURLUpdate, formattedCurrencyFull, formattedCurrency, govColor, getPercent } from "./Utils.js";
import ChartHeader from "../components/ChartHeader";
import ChartLoader from "../components/ChartLoader";
import PieEcharts from "../components/PieECharts";
//import Donut from "@/components/Donut.vue";

export default {
  components: {
    PieEcharts,
    ChartHeader,
    ChartLoader
  },
  props: {
    title: {
      type: String,
      default: "Market Share"
    },
    description: String,
    query: Object,
    chart_size: {
      type: String(),
      default: "large"
    }
  },

  data() {
    return {
      chart_settings: [],
      subtitle: "Market share by entity",
      show_labels: true,
      entity_type: "government",//String(),
      metric_name: "total_amount",//String(),
      category_aggs: [],
      major_category_aggs: [],
      keyword_aggs: [],
      buyer_cluster_aggs: [],
      supplier_cluster_aggs: [],
      buyer_cluster_aggs_count:[],
      supplier_cluster_aggs_count:[],
      publisher_aggs: [],
      supplier_aggs: [],
      government_aggs:[],
      panel_aggs:[],
      nonpanel_aggs:[],
      disposition_aggs:[],
      procurement_method_aggs:[],
      keyword_aggs_count:[],
      
      category_aggs_count: [],
      major_category_aggs_count: [],
      publisher_aggs_count: [],
      supplier_aggs_count: [],
      government_aggs_count:[],
      disposition_aggs_count:[],
      procurement_method_aggs_count:[],
      entity_aggs: [],
      metric_options: [{text: "Amount", value:"total_amount"}, {text: "Volume", value:"doc_count"}],
      metric: {text: "Amount", value:"total_amount"},
      group_options: [{text: "Government", value:"government"}, 
                      {text: "Buyer", value:"publisher"},
                      {text: "Major Category", value:"major_category"},
                      {text: "Category", value:"category"},
                      {text: "Supplier", value:"supplier"},
                      {text: "Sector", value:"publisher_industry"},
                      {text: "Panel/Non-Panel", value:"panel"},
                      {text: "Notice Type", value:"disposition"},
                      {text: "Procurement Method", value:"procurement_method"}],
      group: {text: "Government", value:"government"},
      num_groups: 10,
      top_entities: [10, 15],
      total_records: Number,
      total_amount: Number,
      reportLoading: Boolean,
      plain_text_query:String,
      legendData: [],
      item_details: false,
      aggregated_data: [],
      //publisher_line_chart_data: [],
      chart_data:  {},
      //chart_axes: { x: "Est Total", y: "Categories" },
      //chart_axes: { x: "Transactions", y: "Year" },
      group_by_amount: true,
      target_entity: String,
      changed: Number,
      download: Number,
      dataview: {headers:[], data:[]},
      embed: Number,
      embed_object: Object,
      embed_obj_changed: Number,
      pub_industry_aggs: [],
      pub_industry_aggs_count: [],
    };
  },

  watch: {
    $route(to, from) {
      this.refresh();
    },

  },

  created(){
    
  },

  async mounted() {
    this.reportLoading = true;
    this.loadSettings();

    if (this.query){
        this.loadData(this.query);
      }
      else{
        this.loadData(this.$route.query);
      }
    //this.loadData(this.$route.query);
  },

  computed:{
    id(){
      return (Math.random() + Date.now()) * Math.random();
    },
  },

  methods: {

    chart_object(obj){
      this.embed_object["title"] = this.title;
      this.embed_object["description"] = this.description;
      this.embed_object["chart_type"] = "pie";
      this.embed_object["payload"] = obj
      this.embed_object["id"] = (Math.random() + Date.now()) * Math.random();
      this.embed_obj_changed = (Math.random() + Date.now()) * Math.random();
    },

    embed_code(status){
      this.embed =
          (Math.random() + Date.now()) * Math.random();

    },

    updateSettings(){
      this.chart_settings[0] = {field_name: "pie_group_value", value: this.group.value};
      this.chart_settings[1] = {field_name: "pie_metric_value", value: this.metric.value};

      silentURLUpdate(this.chart_settings);

    },

    loadSettings(){
      var query = this.$route.query;
      if (query.hasOwnProperty("pie_group_value") && query.pie_group_value == "search_terms"){
        delete this.group.text;
        delete this.group.value;
        this.group = {text: "Keyword", value:"search_terms"};

      }
      else if (query.hasOwnProperty("pie_group_value") && query.pie_group_value == "buyer_clusters"){
        delete this.group.text;
        delete this.group.value;
        this.group = {text: "Buyer Clusters", value:"buyer_clusters"};

      }
      else if (query.hasOwnProperty("pie_group_value") && query.pie_group_value == "supplier_clusters"){
        delete this.group.text;
        delete this.group.value;
        this.group = {text: "Supplier Clusters", value:"supplier_clusters"};

      }
      else if (query.hasOwnProperty("pie_group_value")){
        let index = this.group_options.findIndex(x => x.value === query.pie_group_value);
        delete this.group.text;
        delete this.group.value;
        this.group = this.group_options[index];
      }
      if (query.hasOwnProperty("pie_metric_value")){
        let index = this.metric_options.findIndex(x => x.value === query.pie_metric_value);
        delete this.metric.text;
        delete this.metric.value;
        this.metric = this.metric_options[index];
      }
    },

    showTenders(args){
      this.$emit("showTenders", args);
    },
    
    async refresh(){
      this.reportLoading = true;
      await this.loadData(this.$route.query);

      var query = this.$route.query;

      if (query.hasOwnProperty("pie_group_value") && query.pie_group_value == "search_terms"){
        let index = this.group_options.findIndex(x => x.value === "search_terms");
        if (index > -1){
          this.group_options.splice(index, 1);

        }
      }
    },

    set_entity_aggs(){
      this.entity_aggs = [];

      if (this.group.value == "major_category"){
        if (this.metric.value == "doc_count")
          this.entity_aggs = this.major_category_aggs_count;
        else{
          this.entity_aggs = this.major_category_aggs;
          /* for (var i in this.category_aggs){
            this.entity_aggs[i] = this.category_aggs[i].category.buckets[0];
          } */
        }
          
      }
      else if (this.group.value == "category"){
        if (this.metric.value == "doc_count")
          this.entity_aggs = this.category_aggs_count;
        else{
          this.entity_aggs = this.category_aggs;
          /* for (var i in this.category_aggs){
            this.entity_aggs[i] = this.category_aggs[i].category.buckets[0];
          } */
        }
          
      }else if (this.group.value == "publisher"){
        if (this.metric.value == "doc_count")
          this.entity_aggs = this.publisher_aggs_count;
        else{
          this.entity_aggs = this.publisher_aggs;
          /* for (var i in this.publisher_aggs){
            this.entity_aggs[i] = this.publisher_aggs[i].publisher.buckets[0];
          } */
          ////////////console.log("AGGS: ", this.entity_aggs);
        }
      }
      else if (this.group.value == "publisher_industry"){
        if (this.metric.value == "doc_count")
          this.entity_aggs = this.pub_industry_aggs_count;
        else{
          this.entity_aggs = this.pub_industry_aggs;
          /* for (var i in this.publisher_aggs){
            this.entity_aggs[i] = this.publisher_aggs[i].publisher.buckets[0];
          } */
          ////////////console.log("AGGS: ", this.entity_aggs);
        }
      }else if (this.group.value == "government"){
        if (this.metric.value == "doc_count")
          this.entity_aggs = this.government_aggs_count;
        else
          this.entity_aggs = this.government_aggs;
      }else if (this.group.value == "disposition"){
        if (this.metric.value == "doc_count")
          this.entity_aggs = this.disposition_aggs_count;
        else
          this.entity_aggs = this.disposition_aggs;
      }
      else if (this.group.value == "procurement_method"){
        if (this.metric.value == "doc_count")
          this.entity_aggs = this.procurement_method_aggs_count;
        else
          this.entity_aggs = this.procurement_method_aggs;
      }
      else if (this.group.value == "search_terms"){
          this.entity_aggs = this.keyword_aggs;
      }
      else if (this.group.value == "buyer_clusters"){
          this.entity_aggs = this.buyer_cluster_aggs;
      }
      else if (this.group.value == "supplier_clusters"){
          this.entity_aggs = this.supplier_cluster_aggs;
      }
      else if (this.group.value == "panel"){
          this.entity_aggs = this.panel_aggs;
      }
      else {
        if (this.metric.value == "doc_count")
          this.entity_aggs = this.supplier_aggs_count;
        else{
          this.entity_aggs = this.supplier_aggs;
          /* for (var i in this.supplier_aggs){
            this.entity_aggs[i] = this.supplier_aggs[i].supplier.buckets[0];
          } */
        }
      }
    },

    setChanged(){
      this.changed =
          (Math.random() + Date.now()) * Math.random();
    },

    downloadImage(status){
      this.download =
          (Math.random() + Date.now()) * Math.random();

    },

    loadDataView(data){
      this.dataview = data;
    },

    async loadData(qry) {

      var query = JSON.parse(JSON.stringify(qry));
      query["agg_size"] = 10;//this.num_groups;

      entitySearch(query, 1)
        .then(data => {
          this.supplier_aggs = data.aggregations.supplier.buckets;
          this.disposition_aggs = data.aggregations.disposition.buckets;
          this.procurement_method_aggs = data.aggregations.procurement_method.buckets;
          ////////////console.log("SUPPLIEzrS ", this.supplier_aggs);
          this.category_aggs = data.aggregations.category.buckets;
          this.major_category_aggs = data.aggregations.major_category.buckets;
          this.publisher_aggs = data.aggregations.publisher.buckets;
          this.government_aggs = data.aggregations.government.buckets;
          this.pub_industry_aggs = data.aggregations.suppliers_by_publisher_industry.buckets;
          this.panel_aggs[0] = data.aggregations.panel;
          this.panel_aggs[0].key = "Panel"
          this.panel_aggs[1] = data.aggregations.non_panel;
          this.panel_aggs[1].key = "Non-Panel"

          if (data.aggregations.search_terms){
            this.keyword_aggs = data.aggregations.search_terms.buckets;
            this.group_options.push({text: "Keyword", value:"search_terms"})
          }

          if (data.aggregations.buyer_clusters){
            this.buyer_cluster_aggs = data.aggregations.buyer_clusters.buckets;
            this.group_options.push({text: "Buyer Cluster", value:"buyer_clusters"})
          }

          if (data.aggregations.supplier_clusters){
            this.supplier_cluster_aggs = data.aggregations.supplier_clusters.buckets;
            this.group_options.push({text: "Supplier Cluster", value:"supplier_clusters"})
          }
          
          this.total_amount = data.aggregations.total_amount.value;
          this.plain_text_query = data.plain_text_query;
          this.total_records = data.hits.value;

          //count
          this.supplier_aggs_count = data.aggregations.supplier_by_count.buckets;
          this.pub_industry_aggs_count = data.aggregations.publisher_industry_by_count.buckets;
          this.category_aggs_count = data.aggregations.category_by_count.buckets;
          this.major_category_aggs_count = data.aggregations.major_category_by_count.buckets;
          this.publisher_aggs_count = data.aggregations.publisher_by_count.buckets;
          this.government_aggs_count = data.aggregations.government_by_count.buckets;
          this.disposition_aggs_count = data.aggregations.disposition_by_count.buckets;
          this.procurement_method_aggs_count = data.aggregations.procurement_method_by_count.buckets;

          this.set_entity_aggs();
          this.chartConfig();

          
        })
        .catch(error => {
          ////////////console.log(error);
          //this.errored = true;
          //this.overlay = false;
        });
    },

    chartConfig() {
      //this.chart_data = new Object();

      this.chart_data = prepEntityBreakdown(
        this.entity_aggs,
        this.metric.value,
        "",
        this.group.value
      );

      this.reportLoading = false;
      this.setChanged();

    }
  }
};
</script>

<style>
/* .pie-chart-container {
  max-height: 360px !important;
  max-width: 600px !important;
} */
</style>
