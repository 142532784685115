<template>
   <v-card class="pt-0" tile flat :height="cardHeight">
     <v-card-text class="pt-0 pb-0 font-weight-normal" >{{title}}</v-card-text>
        <v-divider class="mx-3"></v-divider>
    <chart-loader class="mt-9 pt-9" v-if="reportLoading" :reportLoading="reportLoading" />
    <e-line-chart-mini class="pt-3" v-else-if="chart_data.data.length > 0"
      :series="chart_data"
      :chart_axes="chart_axes"
      :x_axis_data="chart_labels"
      :showLegend="legend"
      :changed="changed"
      :download="download"
      :metric_type="metric.value"
      :target_group="null"
      :embed="embed"
      :chartHeight="chartHeight"
      @chart_object="chart_object"
      @showTenders="showTenders"
      :interval="interval.value"
      :id="id"
    />
  </v-card>
</template>

<script>
import {
  prepEchartsSeriesMiniNoGroup,
  prepEchartsSeriesMini,
  prepCumulativeSpendMini,
  getXLabels
} from "./PrepLineChart.js";
import { histogramSearch, entitySearch } from "./APIManager.js";
import { bus } from "../main.js";
import { silentURLUpdate, formattedCurrencyFull, formattedCurrency, govColor, getPercent } from "./Utils.js";
//import LineChart from "../components/LineChart.vue";
import ELineChartMini from "./ELineChartMini.vue";
import ChartHeader from "../components/ChartHeader";
import ChartLoader from "../components/ChartLoader";
//import ChartLegend from "./ChartLegend.vue";

export default {
  components: {
    //LineChart,
    ELineChartMini,
    //ChartHeader,
    ChartLoader
    //ChartLegend
    //Donut
  },
  props: {
    legend: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "Size of Wallet"
    },
    chartHeight:{
      type: String,
      default: "200px"
    },
    cardHeight:{
      type: String,
      default: null
    },
    interval_text: {
      type: String,
      default: 'Quarter'
    }
    ,interval_value: {
      type: String,
      default: 'quarter'
    },
    description: String,
    query: Object,
    sum_text: {
      type: String,
      default: 'Actual'
    }
    ,sum_value: {
      type: String,
      default: 'actual'
    },
    group_text: {
      type: String,
      default: null
    }
    ,group_value: {
      type: String,
      default: null
    },
    num_entities: {
      type: Number,
      default: 10
    }
    
  },
  data() {
    return {
      chart_settings: [],
      aggGroup: ["contracted", "expiry"],
      outlined: true,
      defaultButton: 0,
      toggleButton: "rgb(255, 20, 99)",
      toggleText: "View By Start Date",
      toggleOn: false,
      subtitle: "Cumulative or actual award totals over time",
      showLegend: true,
      item_details: false,
      aggregated_data: [],
      //publisher_line_chart_data: [],
      series: {},
      line_chart_headers: [],
      chart_data: { data: [], activity: [] },
      chart_labels: [],
      chart_axes: { x: "Transactions", y: "Year" },
      group_by_amount: true,
      target_entity: String,
      ready: false,
      changed: Number,
      currentAggType: "contracted",
      metric_name: "doc_count",
      sum_options: [{text: "Cumulative", value:"cumulative"}, {text: "Actual", value:"actual"}],
      //sum: {text: "Actual", value:"actual}",
      metric_options: [{text: "Amount", value:"total_amount"}, {text: "Volume", value:"doc_count"}],
      metric: {text: "Amount", value:"total_amount"},
      group_options: [{text: "Buyer", value:"publisher"},
                      {text: "Major Category", value:"major_category"},
                      {text: "Category", value:"category"},
                      {text: "Supplier", value:"supplier"},
                      {text: "Government", value:"government"}],
      //group: {text: "Government", value:"government"},
      interval_options: [{text: "Quarter", value:"quarter"}, {text: "Year", value:"year"}],
      //interval: {text: "Quarter", value:"quarter"},
      top_entities: [5, 10],
      num_groups: 5,
      total_records: Number,
      total_amount: Number,
      reportLoading: Boolean,
      plain_text_query:String,
      download: Number,
      legendData: [],
      date_aggs: [],
      expiry_aggs: [],
      published_aggs: [],
      contracting_activity: [],
      expiring_activity: [],
      entity_aggs: [],
      supplier_aggs:[],
      category_aggs: [],
      major_category_aggs: [],
      publisher_aggs: [],
      government_aggs: [],
      quarterly_aggs:[],
      fy_aggs:[],
      keyword_aggs: [],
      supplier_cluster_aggs: [],
      keyword_aggs_count: [],
      quarterly_aggs_count:[],
      quarterly_grouped_aggs:[],
      quarterly_grouped_aggs_count: [],
      supplier_aggs_count:[],
      category_aggs_count: [],
      major_category_aggs_count: [],
      publisher_aggs_count: [],
      government_aggs_count: [],
      publisher_activity: [],
      date_aggs_count: [],
      entity_type: "supplier",
      embed: Number,
      embed_object: Object,
      embed_obj_changed: Number
      //loading: true
    };
  },
  watch: {
    /* $route(to, from) {
      this.refresh();
    }, */

    query: {
      handler(newValue, oldValue) {
        ////////////console.log(newValue)
        this.reportLoading = true;
        this.loadData(this.query);
      },
      deep: true
    },

    group_value(){
      this.set_entity_aggs();
      this.chartConfig();
      this.setChanged();
    }
  },

  computed: {
    id(){
      return (Math.random() + Date.now()) * Math.random();
    },

    interval(){
      return {text: this.interval_text, value: this.interval_value}
    },

    sum(){
      return {text: this.sum_text, value: this.sum_value}
    },

    group(){
      return {text: this.group_text, value: this.group_value}
    }
  },

  async mounted() {
    this.num_groups = this.num_entities;
    this.reportLoading = true;
    //this.loadSettings();
    if (this.query){
        this.loadData(this.query);
    }
    else{
      this.loadData(this.$route.query);
    }
  },

  methods: {

    formattedCurrency,

    chart_object(obj){
      this.embed_object["title"] = this.title;
      this.embed_object["description"] = this.description;
      this.embed_object["chart_type"] = "line";
      this.embed_object["payload"] = obj
      this.embed_object["id"] = (Math.random() + Date.now()) * Math.random();
      this.embed_obj_changed = (Math.random() + Date.now()) * Math.random();
    },

    embed_code(status){
      this.embed =
           (Math.random() + Date.now()) * Math.random();

    },

    generate_json(){

      let payload = {};
      payload["series"]=this.chart_data
      payload["chart_axes"]=this.chart_axes
      payload["x_axis_data"]=this.chart_labels
      payload["changed"]=this.changed
      payload["download"]=this.download
      payload["metric_type"]=this.metric.value
      payload["target_group"]=this.group.value
      payload["interval"]=this.interval.value

      ////////////console.log(JSON.stringify(payload));

    },

    updateSettings(){
      this.chart_settings[0] = {field_name: "comparison_metric_value", value: this.metric.value};
      this.chart_settings[1] = {field_name: "comparison_sum_value", value: this.sum.value};
      this.chart_settings[2] = {field_name: "comparison_group_value", value: this.group.value};
      this.chart_settings[3] = {field_name: "comparison_interval_value", value: this.interval.value};
      this.chart_settings[4] = {field_name: "comparison_num_groups", value: this.num_groups};

      ////////////console.log ("INTERVAL: ", JSON.stringify(this.interval));

      silentURLUpdate(this.chart_settings);
      
    },

    loadSettings(){
      var query = this.$route.query;
      if (query.hasOwnProperty("comparison_group_value") && query.comparison_group_value == "search_terms"){
        delete this.group.text;
        delete this.group.value;
        this.group = {text: "Keyword", value:"search_terms"};

      }
      else if (query.hasOwnProperty("comparison_group_value")){
        let index = this.group_options.findIndex(x => x.value === query.comparison_group_value);
        delete this.group.text;
        delete this.group.value;
        this.group = this.group_options[index];
      }
      
      if (query.hasOwnProperty("comparison_metric_value")){
        let index = this.metric_options.findIndex(x => x.value === query.comparison_metric_value);
        delete this.metric.text;
        delete this.metric.value;
        this.metric = this.metric_options[index];
      }

      if (query.hasOwnProperty("comparison_interval_value")){
        let index = this.interval_options.findIndex(x => x.value === query.comparison_interval_value);
        delete this.interval.text;
        delete this.interval.value;
        this.interval = this.interval_options[index];
      }
      if (query.hasOwnProperty("comparison_sum_value")){
        let index = this.sum_options.findIndex(x => x.value === query.comparison_sum_value);
        delete this.sum.text;
        delete this.sum.value;
        this.sum = this.sum_options[index];
      }
      if (query.hasOwnProperty("comparison_num_groups")){
        this.num_groups = query.comparison_num_groups;
      }
    },

    showTenders(args){
      this.$emit("showTenders", args);
    },

    set_entity_aggs(){
      this.entity_aggs = [];

      if (this.group.value == "major_category"){
        if (this.metric.value == "doc_count")
          this.entity_aggs = this.major_category_aggs_count;
        else
          this.entity_aggs = this.major_category_aggs;
      }
      else if (this.group.value == "category"){
        if (this.metric.value == "doc_count")
          this.entity_aggs = this.category_aggs_count;
        else
          this.entity_aggs = this.category_aggs;
      }else if (this.group.value == "publisher"){
        if (this.metric.value == "doc_count")
          this.entity_aggs = this.publisher_aggs_count;
        else
          this.entity_aggs = this.publisher_aggs;
      }else if (this.group.value == "government"){
        if (this.metric.value == "doc_count")
          this.entity_aggs = this.government_aggs_count;
        else
          this.entity_aggs = this.government_aggs;
      }else if (this.group.value == "search_terms"){
          this.entity_aggs = this.keyword_aggs;
      }else if (this.group.value == "supplier_clusters"){
          this.entity_aggs = this.supplier_cluster_aggs;
      }
      else {
        if (this.metric.value == "doc_count")
          this.entity_aggs = this.supplier_aggs_count;
        else 
          this.entity_aggs = this.supplier_aggs;
      }
    },

    downloadImage(status){
      this.download =
          (Math.random() + this.chart_data.length) * Math.random();

    },

    async refresh(){
      this.reportLoading = true;
      this.chart_data.data = [];
      await this.loadData(this.$route.query);

      /* var query = this.$route.query;

      if (query.hasOwnProperty("comparison_group_value") && query.comparison_group_value == "search_terms"){
        let index = this.group_options.findIndex(x => x.value === "search_terms");
        this.group_options.splice(index, 1);

      } */
    },

    setChanged(){
      this.changed =
          (Math.random() + this.chart_data.data.length) * Math.random();
      //this.generate_json();
    },

    async loadData(qry) {

      var query = JSON.parse(JSON.stringify(qry));
      query["agg_size"] = this.num_groups;
      delete query["yoy_analysis"];
      query["yoy_analysis"] = false; // faster query

      entitySearch(query, 1)
        .then(data => {
          this.supplier_aggs = data.aggregations.supplier.buckets;
          ////////////console.log("entity search ", data);
          this.category_aggs = data.aggregations.category.buckets;
          this.major_category_aggs = data.aggregations.major_category.buckets;
          this.publisher_aggs = data.aggregations.publisher.buckets;
          this.government_aggs = data.aggregations.government.buckets;

          if (data.aggregations.search_terms){
            this.keyword_aggs = data.aggregations.search_terms.buckets;

            //if it doesnt already exist in the dropdown
            if (!this.group_options.find(o => o.value === 'search_terms')){
              this.group_options.push({text: "Keyword", value:"search_terms"})
            }

            //this.group_options.push({text: "Keyword", value:"search_terms"})
            
          }

          if (data.aggregations.supplier_clusters){
            this.supplier_cluster_aggs = data.aggregations.supplier_clusters.buckets;
            
            this.group_options.push({text: "Supplier Cluster", value:"supplier_clusters"})
        }

          //count
          this.supplier_aggs_count = data.aggregations.supplier_by_count.buckets;
          this.category_aggs_count = data.aggregations.category_by_count.buckets;
          this.major_category_aggs_count = data.aggregations.major_category_by_count.buckets;
          this.publisher_aggs_count = data.aggregations.publisher_by_count.buckets;
          this.government_aggs_count = data.aggregations.government_by_count.buckets;
          //this.set_entity_aggs();
        })
        .catch(error => {
          ////////////console.log(error);
          //this.errored = true;
          //this.overlay = false;
        })
        .finally(() => {

          histogramSearch(query, 1)
            .then(data => {
              ////////////console.log("histogramSearch ", data);
              this.total_records = data.hits.value;
              this.total_amount = data.aggregations.total_amount.value;
              this.plain_text_query = data.plain_text_query;
              this.expiring_activity = data.aggregations.expiring_activity.buckets;
              this.date_aggs = data.aggregations.contracted.buckets;
              //this.date_aggs = data.aggregations.contracting_spend_by_qtr.buckets;
              
              this.quarterly_grouped_aggs = data.aggregations.contracted_by_quarter.buckets;
              this.quarterly_grouped_aggs_count = data.aggregations.contract_count_by_quarter.buckets;
              this.quarterly_aggs = data.aggregations.contracting_spend_by_qtr.buckets;
              this.quarterly_aggs_count = data.aggregations.contract_count_by_quarter.buckets;

              this.fy_aggs = data.aggregations.contracting_spend_by_fy.buckets;

              this.date_aggs_count = data.aggregations.contracting_activity_annual.buckets;
              this.expiry_aggs = data.aggregations.expiring.buckets;
              this.published_aggs = data.aggregations.published.buckets;
              //this.entity_aggs = data.aggregations.supplier.buckets;
              this.publisher_activity =
                data.aggregations.transactions_by_month.buckets;
              this.contracting_activity =
                data.aggregations.contracting_activity.buckets;

              this.chartConfig();
              this.reportLoading = false;
              this.setChanged();
              //this.setGroups();
              //this.setColors();
            })
            .catch(error => {
              ////////////console.log(error);
              //this.errored = true;
              //this.overlay = false;
            }).finally(() => {
              this.set_entity_aggs();
              this.chartConfig();
              this.reportLoading = false;
              this.setChanged();
            })
        });
    },

    get_targets(bucket_data, type) {
      let targets = [];
      let target_count = 0;

      //for each year get the targets
      for (var y = 0; y < bucket_data.length; y++) {
        let data_for_year = bucket_data[y][type].buckets;

        for (var t = 0; t < data_for_year.length; t++) {
          targets[target_count] = data_for_year[t].key;
          ////////////console.log("entity: " + data_for_year[t].key);
          target_count++;
        }
      }
      return targets;
    },

    chartConfig() {

      let data = [];

      let target_aggs = [];
      
      if (this.metric.value == "total_amount") {
        if (this.interval.value == "quarter"){
          if (!this.group["value"]){
            target_aggs = this.quarterly_aggs;
          }
          else{
            target_aggs = this.quarterly_grouped_aggs;
          }

        }else{
          target_aggs = this.fy_aggs;
          target_aggs.sort((a, b) => (a.key > b.key) ? 1 : -1)
        }
      }

      let colors = [
        "#12253a",
        "#ff1463",
        "#d73e32",
        "#2196f3",
        "#004700",
        "#006000",
        "#00791e",
        "#2d9437",
        "#004d9f",
        "#0064ba",
        "#007cd6",
        "#d73e32",
        "#de004b",
        "#ff457c",
        "#4a575d",
        "#7b898f",
        "#bb5900",
        "#bb5900",
        "#ffa72d",
        "#4caf50",
        "#2196f3",
        "#fb8c00",
        "#ff1463",
        "#b71c1c",
        "#ff937b",
        "#95e8ff",
        "#a2ff9e",
        "#fffb82",
        "#FFE70A",
        "#SFS603",
        "#7F7305",
        "#BFAB07",
        "#FF6905",
        "#5405FF"
      ];

      let targets = this.entity_aggs;
      //!!!assign color to entity. sort alphabetically first so colours are consistent across any chart on the dash!!!!!
      if (targets.length > 0){
        targets.sort((a, b) => (a.key < b.key) ? 1 : -1)
        for (var i in targets){
          targets[i]["color"] = colors[i];
        }

      }
      targets.sort((a, b) => (a.total_amount.value < b.total_amount.value) ? 1 : -1)

      

      if (!this.group["value"]){
        //target_aggs["all_entities"]["buckets"] = target_aggs.buckets;
        let color = colors.shift();
        let target_data = prepEchartsSeriesMiniNoGroup(
          target_aggs,
          this.metric.value,
          color
        );

        data[0] = target_data;

      }
      else if (this.sum.value == "actual"){
        
        for (var t in targets) {
          let color = colors.shift();
          ////////////console.log("GROUP: ", this.group);
          let target_data = prepEchartsSeriesMini(
            target_aggs,
            this.group.value,
            this.metric.value,
            targets[t].key,
            targets[t].key,
            targets[t].color
          );

          data[t] = target_data;
        }
      }
      else {
        for (var t in targets) {
          let color = colors.shift();
          ////////////console.log("colour: ", color);
          let target_data = prepCumulativeSpendMini(
            target_aggs,
            this.group.value,
            this.metric.value,
            targets[t].key,
            targets[t].key,
            targets[t].color
          );

          data[t] = target_data;
        }
      }

      this.chart_labels = getXLabels(target_aggs);
      this.chart_data["data"] = data;

      this.setChanged();

      //this.loading = false;
    }
  }
};
</script>

<style>
.chart_container {
    max-width: 100% !important;
    /* overflow: auto !important; */
    border: none !important;
    height: 100% !important;
    background-color: #fff !important;
}

.chart-legend {
  list-style-type: none;
  font-size: 9pt;
  font-family: Roboto;
  /*width: fit-content;
  margin: 0.5rem auto;*/
  margin-left: 0px;
  padding-left: 0px;
}
.chart-legend li {
  padding: 0.25rem;
}
.chart-legend .legend-item {
  height: 11;
  display: flex;
}
.chart-legend .legend-item .label {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 0px;
  padding-left: 0px;
}
.chart-legend .legend-item span {
  cursor: pointer;
  margin-right: 0.75rem;
  margin-top: 0.2rem;
  border-radius: 0.75rem;
  width: 0.3rem;
  height: 0.75rem;
}

.chart-legend .legend-item span:hover {
  text-decoration: underline;
}
</style>
