<template>
  <v-card class="mx-auto" flat min-height="300">
    <v-card-title class="py-1">
      <span
        class="subtitle-2 font-weight-bold"
        style="color:rgba(0, 0, 0, 0.6);"
        >Overall Awards vs Panel Awards</span>
    </v-card-title>
    <v-divider class="py-1"></v-divider>
    <chart-loader v-if="reportLoading" :reportLoading="reportLoading" />
      <son-entity-performance-chart
          v-else
          :entity_id="entity_id"
          :son_id="son_id"
          :data="data"
        />
  </v-card>
</template>

<script>
//import { bus } from "@/main.js";
import SonEntityPerformanceChart from "@/components/SonEntityPerformanceChart.vue";
import { getSonEntityPerformance } from "./APIManager.js";
import ChartLoader from "../components/ChartLoader";
//import Donut from "@/components/Donut.vue";

export default {
  components: {
    SonEntityPerformanceChart,
    ChartLoader
    //Donut
  },
  props: {
    entity_id: Number,
    son_id: String(),
    entity_type: String()
    //results_drawer: Boolean
  },
  data() {
    return {
      tenders: [],
      date_aggs: [],
      publisher_activity: [],
      changed: 1,
      num_groups: 10,
      reportLoading: Boolean,
      data: []
    };
  },

  watch: {

    entity_id(){
      this.loadData(this.data);
    }

  },

  created(){

  },

  async mounted() {
    this.loadData();
  },

  methods: {

    setChanged(){
      this.changed = this.changed + 1;
          //(Math.random() + this.changed) * Math.random();
    },

    async loadData(){
        this.reportLoading = true;
          getSonEntityPerformance(this.son_id, this.entity_type, this.entity_id)
            .then(data => {
                // Extract data for chart
                this.data = data;
                this.reportLoading = false;
            })
    },
  }
};
</script>
