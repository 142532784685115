<template>
  <div class="pa-0 ma-0 fill-height" style="background-color:#f5f5f5" fluid>
    <v-content class="py-1">
      <v-container fluid>
        <v-row class="py-6" style="background-color:#12253a;">
          <v-col cols="12">
            <div
              style="max-width:1200px; display: block; margin:auto !important; padding-top:50px;"
            >
              <v-row align="center" justify="center">
                <v-col cols="11" style="max-width:800px;" class="pr-0 mr-0">
                  <tender-search />
                </v-col>
                <v-col cols="1">
                  <v-btn
                    class="text-capitalize body-2 ml-0 desktop-nav"
                    color="white"
                    small
                    text
                    :to="{
                      name: 'search',
                      query: {
                        expiry_from: new Date().getFullYear(),
                        expiry_to: new Date().getFullYear(),
                        sort_order: 'asc',
                        showFilter: true
                      }
                    }"
                  >
                    <span class="text-capitalize body-2 desktop-nav">Advanced</span>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>

        <v-dialog v-model="explainer">
          <v-card tile flat class="pa-4">
            <p class="title" style="color:#12253a;">
              What are Search Profiles?
            </p>
            <p style="color:#12253a;">
              Search profiles are based on searches that have been executed and then saved. This means you can quickly
              rerun those searches when you log back in. Tendertrace will
              automatically monitor search profiles and notify you of any new notices that match a profile. All new matches will be available in your
              "Alerts" page.
            </p>
            <p>
              You will find up to date analysis of the search profiles you create on your home page.
            </p>
            <p style="color:#12253a;">
              Saved searches also save the search tab you are on. So for example, if you were on the Leaderboard tab when you saved the search profile, clicking on the saved profile will run the search and also take you to the tab you were on when you saved the profile initially.
            </p>
            <p style="color:#12253a;">
              To start saving searches: 
              <ul>
                <li>Run a search for contracts or tenders of interest.</li> 
                <li>In the filter description section of the screen, click the button labeled "Save Search" to have Tendetrace start monitoring that search on
              your behalf.</li>
              </ul>
            </p>
            <p>
              <v-btn color="primary" small>
                <router-link
                  :to="{
                    name: 'search',
                    query: {
                      disposition: ['Contract Notice', 'Grant'],
                      expiry_from: new Date().getFullYear(),
                      expiry_to: new Date().getFullYear(),
                      sort_order: 'asc'
                    }
                  }"
                >
                  <span style="color:white !important;">Create profile</span>
                </router-link>
              </v-btn>
            </p>
          </v-card>
        </v-dialog>

        <v-row justify="center" class="pt-5 px-0 pb-0">
          <v-col cols="12" md="3" sm="6">
            <v-card class="mx-auto" width="420px">
              <v-card-title class="font-weight-medium py-1 px-4 subtitle-1 d-flex justify-space-between">
                <span>Search Profiles</span>
                <span>
                  <v-btn
                    icon
                    href="https://docs.tendertrace.com/guide/user-documentation/saving-your-work/search-profiles"
                    target="_blank"
                  >
                    <v-icon class="material-icons" color="secondary">mdi-help-circle-outline</v-icon>
                  </v-btn>
                </span>
              </v-card-title>

              <v-img height="200" src="@/assets/img/Search-Profiles.jpg"></v-img>

              <v-card flat class="px-2 py-2 mx-2 my-2">
                <v-text-field
                  append-icon="mdi-magnify"
                  v-model="searchProfiles"
                  dense
                  solo-inverted
                  hide-details
                  flat
                  append-inner-icon="mdi-magnify"
                  label="Search profiles..."
                ></v-text-field>
              </v-card>

              <v-list nav dense height="400px" style="flex-grow: 1 !important; overflow:auto !important;">
                <v-list-item v-if="loadingSearches">
                  <chart-loader :reportLoading="loadingSearches" />
                </v-list-item>
                <v-list-item v-else v-for="profile in filteredSearchProfiles" :key="profile.id">
                  <v-list-item-icon>
                    <v-icon small left>mdi-view-headline</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-tooltip bottom dark>
                      <template v-slot:activator="{ on }">
                        <v-list-item-title class="pl-0" v-on="on">
                          <router-link
                            class="list-link"
                            :to="{
                              name: 'search',
                              query: profile.search_query
                            }"
                          >
                            <span>{{ profile.name }}</span>
                          </router-link>
                        </v-list-item-title>
                      </template>
                      <span class="tooltip">{{ profile.plain_text_query }}</span>
                    </v-tooltip>
                    <v-list-item-title class="pl-0">
                      <span>
                        <v-tooltip top max-width="250">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn small @click="openProfile(0, profile)" icon>
                              <v-icon small v-bind="attrs" v-on="on" medium color="secondary">mdi-format-list-bulleted</v-icon>
                            </v-btn>
                          </template>
                          <span>Go to the data for this profile</span>
                        </v-tooltip>
                      </span>
                      <span>
                        <v-tooltip top max-width="250">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn small @click="openProfile(2, profile)" icon>
                              <v-icon small v-bind="attrs" v-on="on" medium color="secondary">mdi-graphql</v-icon>
                            </v-btn>
                          </template>
                          <span>Go to the network analysis for this profile</span>
                        </v-tooltip>
                      </span>
                      <span>
                        <v-tooltip top max-width="250">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn small @click="openProfile(1, profile)" icon>
                              <v-icon small v-bind="attrs" v-on="on" medium color="secondary">mdi-sort-variant</v-icon>
                            </v-btn>
                          </template>
                          <span>Go to the leaderboard for this profile</span>
                        </v-tooltip>
                      </span>
                      <span>
                        <v-tooltip top max-width="250">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn small @click="openProfile(3, profile)" icon>
                              <v-icon small v-bind="attrs" v-on="on" medium color="secondary">mdi-chart-box-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Go to the insights report for this profile</span>
                        </v-tooltip>
                      </span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>

          <v-col cols="12" md="3" sm="6">
            <v-card class="mx-auto" width="420px">
              <v-card-title class="font-weight-medium py-1 px-4 subtitle-1 d-flex justify-space-between">
                <span>Recent Searches</span>
                <span>
                  <v-btn
                    icon
                    href="https://docs.tendertrace.com/guide/user-documentation/saving-your-work/search-history"
                    target="_blank"
                  >
                    <v-icon class="material-icons" color="secondary">mdi-help-circle-outline</v-icon>
                  </v-btn>
                </span>
              </v-card-title>

              <v-img height="200" src="@/assets/img/Recent-Searches.jpg"></v-img>

              <v-card flat class="px-2 py-2 mx-2 my-2">
                <v-text-field
                  append-icon="mdi-magnify"
                  v-model="searchRecent"
                  dense
                  solo-inverted
                  hide-details
                  flat
                  append-inner-icon="mdi-magnify"
                  label="Search recent searches..."
                ></v-text-field>
              </v-card>

              <v-list nav dense height="400px" style="flex-grow: 1 !important; overflow:auto !important;">
                <v-list-item v-if="loadingHistory">
                  <chart-loader :reportLoading="loadingHistory" />
                </v-list-item>
                <v-list-item v-else v-for="item in filteredRecentSearches" :key="item.id">
                  <v-list-item-icon>
                    <v-icon small left>mdi-history</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-tooltip bottom dark>
                      <template v-slot:activator="{ on }">
                        <v-list-item-title class="pl-0" v-on="on">
                          <router-link
                            class="list-link"
                            :to="{
                              name: 'search',
                              query: JSON.parse(item.search_query)
                            }"
                          >
                            <span>{{ item.plain_text_query }}</span>
                          </router-link>
                        </v-list-item-title>
                      </template>
                      <span class="tooltip">{{ item.plain_text_query }}</span>
                    </v-tooltip>
                    <v-list-item-title class="pl-0">
                      <span>
                        <v-tooltip top max-width="250">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn small @click="openItem(0, item)" icon>
                              <v-icon small v-bind="attrs" v-on="on" medium color="secondary">mdi-format-list-bulleted</v-icon>
                            </v-btn>
                          </template>
                          <span>Go to the data for this search</span>
                        </v-tooltip>
                      </span>
                      <span>
                        <v-tooltip top max-width="250">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn small @click="openItem(2, item)" icon>
                              <v-icon small v-bind="attrs" v-on="on" medium color="secondary">mdi-graphql</v-icon>
                            </v-btn>
                          </template>
                          <span>Go to the network analysis for this search</span>
                        </v-tooltip>
                      </span>
                      <span>
                        <v-tooltip top max-width="250">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn small @click="openItem(1, item)" icon>
                              <v-icon small v-bind="attrs" v-on="on" medium color="secondary">mdi-sort-variant</v-icon>
                            </v-btn>
                          </template>
                          <span>Go to the leaderboard for this search</span>
                        </v-tooltip>
                      </span>
                      <span>
                        <v-tooltip top max-width="250">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn small @click="openItem(3, item)" icon>
                              <v-icon small v-bind="attrs" v-on="on" medium color="secondary">mdi-chart-box-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Go to the analysis report for this search</span>
                        </v-tooltip>
                      </span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>

          <v-col cols="12" md="3" sm="6">
            <v-card class="mx-auto" width="420px">
              <v-card-title class="font-weight-medium py-1 px-4 subtitle-1 d-flex justify-space-between">
                <span>Prospect Lists</span>
                <span>
                  <v-btn
                    icon
                    href="https://docs.tendertrace.com/guide/user-documentation/saving-your-work/prospect-lists"
                    target="_blank"
                  >
                    <v-icon class="material-icons" color="secondary">mdi-help-circle-outline</v-icon>
                  </v-btn>
                </span>
              </v-card-title>

              <v-img height="200" src="@/assets/img/bookmarks.jpg"></v-img>

              <v-card flat class="px-2 py-2 mx-2 my-2">
                <v-text-field
                  append-icon="mdi-magnify"
                  v-model="searchProspect"
                  dense
                  solo-inverted
                  hide-details
                  flat
                  append-inner-icon="mdi-magnify"
                  label="Search prospect lists..."
                ></v-text-field>
              </v-card>

              <v-list nav dense height="400px" style="flex-grow: 1 !important; overflow:auto !important;">
                <v-list-item v-if="loadingBookmarks">
                  <chart-loader class="pt-6" :reportLoading="loadingBookmarks" />
                </v-list-item>
                <v-list-item v-else v-for="list in filteredProspectLists" :key="list.id">
                  <v-list-item-icon>
                    <v-icon small left>mdi-bookmark</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title class="pl-0">
                      <router-link
                        class="list-link"
                        :to="{
                          name: 'search',
                          query: getQuery(list)
                        }"
                      >
                        <span>{{ list.name }}</span>
                      </router-link>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>

          <v-col cols="12" md="3" sm="6">
            <v-card class="mx-auto" width="420px">
              <v-card-title class="font-weight-medium py-1 px-4 subtitle-1 d-flex justify-space-between">
                <span>Custom Reports</span>
                <span>
                  <v-btn
                    icon
                    href="https://docs.tendertrace.com/guide/user-documentation/custom-reports"
                    target="_blank"
                  >
                    <v-icon class="material-icons" color="secondary">mdi-help-circle-outline</v-icon>
                  </v-btn>
                </span>
              </v-card-title>

              <v-img height="200" src="@/assets/img/Custom-Reports.jpg"></v-img>

              <v-card flat class="px-2 py-2 mx-2 my-2">
                <v-text-field
                  append-icon="mdi-magnify"
                  v-model="searchReports"
                  dense
                  solo-inverted
                  hide-details
                  flat
                  append-inner-icon="mdi-magnify"
                  label="Search custom reports..."
                ></v-text-field>
              </v-card>

              <v-list nav dense height="400px" style="flex-grow: 1 !important; overflow:auto !important;">
                <v-list-item v-if="loadingReports">
                  <chart-loader :reportLoading="loadingReports" />
                </v-list-item>
                <v-list-item v-else v-for="report in filteredCustomReports" :key="report.id">
                  <v-list-item-icon>
                    <v-icon small left>mdi-chart-bubble</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-tooltip bottom dark>
                      <template v-slot:activator="{ on }">
                        <v-list-item-title class="pl-0" v-on="on">
                          <router-link class="list-link" :to="'/report/' + report.id">
                            <span>{{ stripHTML(report.title) }}</span>
                          </router-link>
                        </v-list-item-title>
                      </template>
                      <span class="tooltip">
                        <div>{{ stripHTML(report.description) }}</div>
                        <div>{{ stripHTML(report.created) }}</div>
                      </span>
                    </v-tooltip>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </div>
</template>

<script>
import TenderSearch from "../components/TenderSearch";
import { getStats, entitySearch } from "../components/APIManager";
import { fetchStoryboards } from "../components/APIManager.js";
import { getInstance } from "../auth/index.js";
import axios from "axios";
import ChartLoader from "../components/ChartLoader";

export default {
  components: {
    TenderSearch,
    ChartLoader
  },

  data() {
    return {
      explainer: false,
      searchProfiles: "",
      searchRecent: "",
      searchProspect: "",
      searchReports: "",
      stats: {},
      selected_profiles: [],
      saved_searches: [],
      reports: [],
      my_lists: [],
      recent_searches: [],
      loadingReports: Boolean(),
      loadingHistory: Boolean(),
      loadingBookmarks: Boolean(),
      loadingSearches: Boolean(),
      errored: Boolean(),
      cards: [
        {
          title: "Open tenders",
          src: "requests.png",
          flex: 3,
          id: 0
        },
        {
          title: "Competitor contracts",
          src: "competitorcontracts.png",
          flex: 3,
          id: 1
        },
        {
          title: "Partnership opportunities",
          src: "networkmin.png",
          flex: 3,
          id: 2
        }
      ],
      changed: Number
    };
  },

  computed: {
    filteredSearchProfiles() {
      if (this.saved_searches){
        return this.saved_searches.filter(profile =>
          profile.name.toLowerCase().includes(this.searchProfiles.toLowerCase())
        );
      }
      else{
        return this.saved_searches;
      }      
    },
    filteredRecentSearches() {
      if (this.recent_searches){
        return this.recent_searches.filter(search =>
          search.plain_text_query.toLowerCase().includes(this.searchRecent.toLowerCase())
        );
      }
      else{
        return this.recent_searches;
      }
    },
    filteredProspectLists() {
      if (this.my_lists){
        return this.my_lists.filter(list =>
          list.name.toLowerCase().includes(this.searchProspect.toLowerCase())
        );
      }
      else{
        return this.my_lists;
      }
    },
    filteredCustomReports() {
      if (this.reports){
        return this.reports.filter(report =>
          this.stripHTML(report.title).toLowerCase().includes(this.searchReports.toLowerCase())
        );
      }
      else{
        return this.reports;
      }
    },
    imgSourced() {
      return "@/assets/img/requests.png";
    }
  },

  mounted() {
    this.getSavedSearches();
    this.getLists();
    this.getRecentSearches();
    this.getReports();
  },

  methods: {
    remove_profile(item) {
      this.selected_profiles.splice(this.selected_profiles.indexOf(item), 1);
      this.selected_profiles = [...this.selected_profiles];
    },

    openProfile(viewIndex, profile) {
      var qry = profile.search_query;
      qry["viewIndex"] = viewIndex;

      this.$router.push({ path: "/search", query: qry });
    },

    openItem(viewIndex, profile) {
      var qry = JSON.parse(profile.search_query);
      qry["viewIndex"] = viewIndex;

      this.$router.push({ path: "/search", query: qry });
    },

    refresh() {
      this.setChanged();
    },

    stripHTML(text) {
      if (!text) {
        return "";
      } else {
        let strippedText = text.replace(/(<([^>]+)>)/gi, "");
        return strippedText;
      }
    },

    setChanged() {
      this.changed = (Math.random() + this.chart_data.data.length) * Math.random();
    },

    getQuery(list) {
      var query = {};
      query = JSON.parse(list.search_query);
      query["list_id"] = list.id;
      return query;
    },

    async getReports() {
      this.loadingReports = true;
      var data = await fetchStoryboards();
      this.reports = data.storyboards;
      this.loadingReports = false;
    },

    async getSavedSearches() {
      this.loadingSearches = true;

      const instance = getInstance();
      const accessToken = await instance.getTokenSilently();

      axios
        .get(process.env.VUE_APP_API_SEARCHES, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        .then(response => {
          this.saved_searches = response.data.saved_searches;
          this.loadingSearches = false;
        })
        .catch(error => {
          this.errored = true;
        })
        .finally(() => (this.loadingSearches = false));
    },

    async getLists() {
      this.loadingBookmarks = true;
      const instance = getInstance();
      const accessToken = await instance.getTokenSilently();
      axios
        .get(process.env.VUE_APP_API_LISTS, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        .then(response => {
          this.my_lists = response.data.data;
          this.loadingBookmarks = false;
        })
        .catch(error => {
          this.errored = true;
        })
        .finally(() => (this.loadingBookmarks = false));
    },

    async getRecentSearches() {
      const instance = getInstance();
      const accessToken = await instance.getTokenSilently();

      this.loadingHistory = true;
      axios
        .get(process.env.VUE_APP_API_SEARCHES, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        .then(response => {
          this.recent_searches = response.data.search_history;
          this.loadingHistory = false;
        })
        .catch(error => {
          this.errored = true;
        })
        .finally(() => (this.loadingHistory = false));
    },

    numFormatter(num) {
      if (num > 999 && num < 1000000) {
        return (num / 1000).toFixed(0) + "K"; // convert to K for number from > 1000 < 1 million
      } else if (num > 1000000 && num < 1000000000) {
        return (num / 1000000).toFixed(0) + "M"; // convert to M for number from > 1 million
      } else if (num > 1000000000) {
        return (num / 1000000000).toFixed(0) + "B"; // convert to M for number from > 1 million
      } else if (num < 900) {
        return num; // if value < 1000, nothing to do
      }
    },

    future() {
      var d = new Date();
      var year = d.getFullYear();
      var month = d.getMonth();
      var day = d.getDate();
      var future = new Date(year + 10, month, day);
      return future.toISOString().substr(0, 10);
    },

    today() {
      var dte = new Date();
      return dte.toISOString().substr(0, 10);
    },

    imgSource() {
      var src;
      switch (id) {
        case 0:
          src = "@/assets/img/requests.png";
          break;
        case 1:
          src = "@/assets/img/competitorcontracts.png";
          break;
        case 2:
          src = "@/assets/img/networkmin.png";
          break;
      }
      return src;
    }
  },

  async created() {
    this.stats = await getStats();
  }
};
</script>

<style scoped>
.chip-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.list-link {
  border-color: rgb(255, 190, 10);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  color: inherit !important;
}

.searchBackground::after {
  background-image: url("../assets/img/background.ef1dd5e2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  content: "";
  opacity: 0.09;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}

.bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url("../assets/img/networkbanner.png") no-repeat center center;
  background-size: inherit;
  transform: scale(1.1);
  opacity: 0.09;
}

#linkhover:hover {
  text-decoration: underline !important;
}
</style>
