<template>
  <div>
    <v-row class="px-0 py-0"
      ><v-col cols="12" class="px-0 pb-0 pt-0">
        <v-card flat v-if="overlay || !search_profile" tile style="color:rgb(78, 184, 136);">
          <div class="text-center">
            <v-progress-circular
              :size="36"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
        </v-card>
        <v-card flat v-else-if="date_aggs.length == 0" tile style="color:rgb(78, 184, 136);">
          <div class="text-center">
            <v-card-subtitle style="color:#9E9E9E;">No data available</v-card-subtitle>
          </div>
        </v-card>
        <v-card v-else tile flat>
          <v-row class="ma-0 pa-0">
            <v-divider></v-divider>
            <v-col cols="12" md="6" class="ma-0 pa-0">
              <v-card flat>
                <v-card-text class="pt-0 pb-0 font-weight-normal" >Active Contracts</v-card-text>
                <v-divider class="mx-3"></v-divider>
                <v-row class="ma-0 pa-0">
                  
                  <v-col cols="4" class="ma-0 pa-0">
                    <v-card tile flat class="py-0 my-0">
                      <v-card-title
                        class="font-weight-normal caption"
                        style="color:#12253a;"
                        >Value</v-card-title
                      >
                      <v-card-subtitle>
                        <div class="font-weight-medium title" style="color:#12253a;">
                          {{ formattedCurrency(search_profile.active_contracts.stats.sum) }}
                        </div>
                        <div class="font-weight-normal caption">
                          <router-link
                            :to="{
                              name: 'search',
                              query: getQuery({
                                disposition: ['Contract Notice','Grant'],
                                expiry_date_from: new Date().toISOString().split('T')[0],
                                expiry_date_to: new Date(new Date().getFullYear() + 50, new Date().getMonth(), new Date().getDate()).toISOString().split('T')[0],
                                sort_order: 'asc'
                              })
                            }"
                          >
                            {{ search_profile.active_contracts.stats.count}}
                            contracts</router-link
                          >
                        </div>
                      </v-card-subtitle>
                    </v-card>
                  </v-col>
                
                  <v-col cols="4"  class="ma-0 pa-0">
                    <v-card tile flat class="py-0 my-0">
                      <v-card-title
                        class="font-weight-normal caption"
                        style="color:#12253a;"
                        >Highest Value</v-card-title
                      >
                      <v-card-subtitle>
                        <div class="font-weight-medium text-h6" style="color:#12253a;">
                          {{ formattedCurrency(search_profile.active_contracts.stats.max) }}
                        </div>
                        <div class="font-weight-normal caption" style="color:#12253a;">
                          Contract
                        </div>
                      </v-card-subtitle>
                    </v-card>
                  </v-col>

                  <v-col cols="4"  class="ma-0 pa-0">
                    <v-card tile flat class="py-0 my-0">
                      <v-card-title
                        class="font-weight-normal caption"
                        style="color:#12253a;"
                        >Avg Contract</v-card-title
                      >
                      <v-card-subtitle>
                        <div class="font-weight-medium title" style="color:#12253a;">
                          {{ formattedCurrency(search_profile.active_contracts.stats.avg) }}
                        </div>
                        <div class="font-weight-normal caption" style="color:#12253a;">
                          Active
                        </div>
                      </v-card-subtitle>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" md="6" class="ma-0 pa-0">
              <v-card flat>
                <v-card-text class="pt-0 pb-0 font-weight-normal" >Awards - All Time</v-card-text>
                <v-divider class="mx-3"></v-divider>
                <v-row class="ma-0 pa-0">
                  
                  <v-col cols="4" class="ma-0 pa-0">
                    <v-card tile flat class="py-0 my-0">
                      <v-card-title
                        class="font-weight-normal caption"
                        style="color:#12253a;"
                        >Value</v-card-title
                      >
                      <v-card-subtitle>
                        <div class="font-weight-medium title" style="color:#12253a;">
                          {{ formattedCurrency(search_profile.award_total) }}
                        </div>
                        <div class="font-weight-normal caption">
                          <router-link
                            :to="{ name: 'search', query: getQuery()}"
                          >
                            {{ abbrv(search_profile.num_notices)
                            }}<span v-if="search_profile.num_notices == 10000">+</span>
                            awards</router-link
                          >
                        </div>
                      </v-card-subtitle>
                    </v-card>
                  </v-col>
                
                  <v-col cols="4"  class="ma-0 pa-0">
                    <v-card tile flat class="py-0 my-0">
                      <v-card-title
                        class="font-weight-normal caption"
                        style="color:#12253a;"
                        >Avg Per Year</v-card-title
                      >
                      <v-card-subtitle>
                        <div class="font-weight-medium title" style="color:#12253a;">
                          {{ formattedCurrency(search_profile.award_total / search_profile.num_years_since_first_contract) }}
                        </div>
                        <div class="font-weight-normal caption" style="color:#12253a;">
                          Over {{search_profile.num_years_since_first_contract}} years
                        </div>
                      </v-card-subtitle>
                    </v-card>
                  </v-col>

                  <v-col cols="4"  class="ma-0 pa-0">
                    <v-card tile flat class="py-0 my-0">
                      <v-card-title
                        class="font-weight-normal caption"
                        style="color:#12253a;"
                        >Avg Contract</v-card-title
                      >
                      <v-card-subtitle>
                        <div class="font-weight-medium title" style="color:#12253a;">
                          {{ formattedCurrency(search_profile.award_total / search_profile.num_notices) }}
                        </div>
                        <div class="font-weight-normal caption" style="color:#12253a;">
                          All contracts
                        </div>
                      </v-card-subtitle>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" md="6" class="ma-0 pa-0">
              <v-card flat>
                <v-card-text class="pt-0 pb-0 font-weight-normal">Awards - Last 12 Months</v-card-text>
                <v-divider class="mx-3"></v-divider>
                <v-row class="ma-0 pa-0">
                <v-col cols="4"  class="ma-0 pa-0">
                  <v-card tile flat class="py-0 my-0">
                    <v-card-title
                      class="font-weight-normal caption"
                      style="color:#12253a;"
                      >Value</v-card-title 
                    >
                    <v-card-subtitle>
                      <div class="font-weight-medium title" style="color:#12253a;">
                        {{ formattedCurrency(search_profile.growth.current_year_total) }}
                      </div>
                      <div class="font-weight-normal caption" style="color:#12253a;">
                        <v-icon :color="growth_format(search_profile.growth.spend_change_percent).color">
                          {{growth_format(search_profile.growth.spend_change_percent).icontype}}
                        </v-icon>
                        {{ growth_format(search_profile.growth.spend_change_percent).change }}%
                      </div>
                    </v-card-subtitle>
                  </v-card>
                </v-col>
                <v-col cols="4"  class="ma-0 pa-0">
                  <v-card tile flat class="py-0 my-0">
                    <v-card-title
                      class="font-weight-normal caption"
                        style="color:#12253a;"
                      >Avg Contract</v-card-title 
                    >
                    <v-card-subtitle>
                      <div class="font-weight-medium title" style="color:#12253a;">
                        {{ formattedCurrency(search_profile.growth.curr_yr_avg_contract) }}
                      </div>
                      <div class="font-weight-normal caption" style="color:#12253a;">
                        <v-icon :color="growth_format(search_profile.growth.avg_change_percent).color">
                          {{growth_format(search_profile.growth.avg_change_percent).icontype}}
                        </v-icon>
                        {{ growth_format(search_profile.growth.avg_change_percent).change }}%
                      </div>
                    </v-card-subtitle>
                  </v-card>
                </v-col> 
                <v-col cols="4"  class="ma-0 pa-0">
                  <v-card tile flat class="py-0 my-0">
                    <v-card-title
                      class="font-weight-normal caption"
                        style="color:#12253a;"
                      >Contracts</v-card-title 
                    >
                    <v-card-subtitle>
                      <div class="font-weight-medium title" style="color:#12253a;">
                        <router-link
                           :to="{
                              name: 'search',
                              query: getQuery({
                                disposition: ['Contract Notice','Grant'],
                                contract_start_from: new Date().toISOString().split('T')[0],
                                contract_start_to: new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate()).toISOString().split('T')[0],
                                sort_order: 'asc'
                              })
                            }"
                          >
                            {{ numFormatter(search_profile.growth.current_year_vol) }}
                        </router-link>
                      </div>
                      <div class="font-weight-normal caption" style="color:#12253a;">
                        <v-icon :color="growth_format(search_profile.growth.vol_change_percent).color">
                          {{ growth_format(search_profile.growth.vol_change_percent).icontype }}
                        </v-icon>
                        {{ growth_format(search_profile.growth.vol_change_percent).change }}%
                      </div>
                    </v-card-subtitle>
                  </v-card>
                </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" md="6" class="ma-0 pa-0">
              <v-card flat>
                <v-card-text class="pt-0 pb-0 font-weight-normal">Expiring - Next 12 Months</v-card-text>
                <v-divider class="mx-3"></v-divider>
                <v-row class="ma-0 pa-0">
                <v-col cols="4"  class="ma-0 pa-0">
                  <v-card tile flat class="py-0 my-0">
                    <v-card-title
                      class="font-weight-normal caption"
                        style="color:#12253a;"
                      >Value</v-card-title 
                    >
                    <v-card-subtitle>
                      <div class="font-weight-medium title" style="color:#12253a;">
                        {{ formattedCurrency(search_profile.expiring_soon.next_year_total) }}
                      </div>
                      <div class="font-weight-normal caption" style="color:#12253a;">
                        <v-icon :color="growth_format(search_profile.expiring_soon.spend_change_percent).color">
                          {{growth_format(search_profile.expiring_soon.spend_change_percent).icontype}}
                        </v-icon>
                        {{ growth_format(search_profile.expiring_soon.spend_change_percent).change }}%
                      </div>
                    </v-card-subtitle>
                  </v-card>
                </v-col>
                <v-col cols="4"  class="ma-0 pa-0">
                  <v-card tile flat class="py-0 my-0">
                    <v-card-title
                      class="font-weight-normal caption"
                      style="color:#12253a;"
                      >Avg Contract</v-card-title 
                    >
                    <v-card-subtitle>
                      <div class="font-weight-medium title" style="color:#12253a;">
                        {{ formattedCurrency(search_profile.expiring_soon.next_yr_avg_contract) }}
                      </div>
                      <div class="font-weight-normal caption" style="color:#12253a;">
                        <v-icon :color="growth_format(search_profile.expiring_soon.avg_change_percent).color">
                          {{growth_format(search_profile.expiring_soon.avg_change_percent).icontype}}
                        </v-icon>
                        {{ growth_format(search_profile.expiring_soon.avg_change_percent).change }}%
                      </div>
                    </v-card-subtitle>
                  </v-card>
                </v-col> 
                <v-col cols="4"  class="ma-0 pa-0">
                  <v-card tile flat class="py-0 my-0">
                    <v-card-title
                      class="font-weight-normal caption"
                        style="color:#12253a;"
                      >Contracts</v-card-title 
                    >
                    <v-card-subtitle>
                      <div class="font-weight-medium title" style="color:#12253a;">
                        <router-link
                           :to="{
                              name: 'search',
                              query: getQuery({
                                disposition: ['Contract Notice','Grant'],
                                contract_end_from: new Date().toISOString().split('T')[0],
                                contract_end_to: new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate()).toISOString().split('T')[0],
                                sort_order: 'asc'
                              })
                            }"
                          >
                            {{ numFormatter(search_profile.expiring_soon.next_year_vol) }}
                        </router-link>
                        
                      </div>
                      <div class="font-weight-normal caption" style="color:#12253a;">
                        <v-icon :color="growth_format(search_profile.expiring_soon.vol_change_percent).color">
                          {{ growth_format(search_profile.expiring_soon.vol_change_percent).icontype }}
                        </v-icon>
                        {{ growth_format(search_profile.expiring_soon.vol_change_percent).change }}%
                      </div>
                    </v-card-subtitle>
                  </v-card>
                </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    
  </div>
</template>

<script>
import { bus } from "@/main.js";
import {
  fetchNews,
  executeSearch,
  searchAnalyzer,
  histogramSearch
} from "../components/APIManager";
//import { Doughnut } from "vue-chartjs";
import { growth_format, numFormatter, formattedCurrency } from "../components/Utils.js";

export default {
  components: {

  },
  props: {
    query: Object,
    story: Boolean,
    chart_options: {},
    id: Number,
    embed: Number
    /* related_entities: {},
    search_profile: {},
    current_search: {},
    search_profile_id: String() */
    //results_drawer: Boolean
  },
  data() {
    return {
      search_profile: null,
      hit_info: {},
      tenders: [""],
      related_dialog: false,
      overlay: false,
      tabs: null,
      absolute: true,
      panel_clicked: false,
      //current_index: 0,
      index: Number, //use the exp panel index as key so that we can cache the data
      profiles: [],
      mounted: false,
      total_amt_metric: "total_amount",
      search: {},
      category_aggs: [],
      date_aggs: [],
      publisher_aggs: [],
      contracting_activity: [],
      expiring_activity: [],
      expiry_aggs: [],
      gov_aggs: [],
      annual_spend: [],
      avg_contract: Number,
      following: false,
      data: {}
    };
  },

  computed: {},

  created() {
    
    //this.search = this.current_search;
  },

  mounted() {
    this.overlay = true;
    if (this.story){
      ////////////////console.log(this.chart_options);
      this.expiry_aggs = this.chart_options.expiry_aggs;
      this.expiring_activity = this.chart_options.expiring_activity;
      this.date_aggs = this.chart_options.date_aggs;
      this.contracting_activity = this.chart_options.contracting_activity;
      this.annual_spend = this.chart_options.annual_spend;
      this.avg_contract = this.chart_options.avg_contract;
      this.category_aggs = this.chart_options.category_aggs;
      this.supplier_aggs = this.chart_options.supplier_aggs;
      this.search_profile = this.chart_options.search_profile;

      this.overlay = false;
      
    }
    else{
      this.loadProfile();
    }

  },

  watch: {
    $route(to, from) {
    },

    embed() {
      ////////////////console.log("CALL SHARE CHART\n", this.myChart);
      
      this.saveChart();
    },

    query: {
      handler(newValue, oldValue) {
        if (!this.story){
          this.overlay = true;
          this.loadProfile();
        }
      },
      deep: true
    },

  },

  methods: {

    growth_format,
    numFormatter,
    formattedCurrency,

    saveChart(){

      //var imgUrl = this.myChart.getDataURL();

      let data = {
        imgData: {},
        options: this.data
      };

      ////////////////console.log("ANALYSIS PAYLOAD: ", data);

      this.$emit("chart_object", data);

    },

    abbrv(num_awards){
      if(num_awards >= 10000){
        return "10K"
      }
      else{
        return num_awards;
      }

    },

    reset_aggs(){
      this.expiry_aggs = [];
      this.expiring_activity = [];
      this.date_aggs = [];
      this.contracting_activity = [];
      this.annual_spend = [];
      this.category_aggs = [];
      this.supplier_aggs = [];
      this.search_profile = null
      this.data={};
    },

    async loadProfile() {
      if (this.query){
        this.loadTenderAggs(this.query);
      }
      else{
        this.loadTenderAggs(this.$route.query);
      }
      
    },

    getQuery(qry) {
      if (qry){
        var q = {}
        if (this.query){
          q = JSON.parse(JSON.stringify(this.query));
        }
        else{
          q = JSON.parse(JSON.stringify(this.$route.query));
        }
        for (const [k, v] of Object.entries(qry)){
          q[k] = v;
        }
        q["viewIndex"] = 0;
        return q;
      }
      else{
        if (this.query){
          return this.query;
        }
        else{
          return this.$route.query;
        }
      }
      
      
    },

    async loadRecentAwards() {
      let results = {};

      //let labels = [];
      results = await executeSearch(
        JSON.stringify({
          supplier_id: this.$route.params.id,
          disposition: ['Contract Notice','Grant'],
          sort_field: "contract_start",
          size: 10
        })
      );

      this.tenders = results.data;
      this.hit_info = results.hits;
      this.overlay = false;
    },

    async loadTenderAggs(qry) {

      var query = JSON.parse(JSON.stringify(qry));
      query["agg_size"] = 10;//this.num_gro

      histogramSearch(query).then(data => {
          this.expiry_aggs = data.aggregations.expiring.buckets;
          this.expiring_activity = data.aggregations.expiring_activity.buckets;
          this.date_aggs = data.aggregations.contracted.buckets;
          this.contracting_activity =
            data.aggregations.contracting_activity.buckets;
          this.annual_spend = data.aggregations.contracting_spend.buckets;

          this.avg_contract = data.aggregations.avg_contract_value.value;
          this.overlay = false;

          this.data.expiry_aggs = data.aggregations.expiring.buckets;
          this.data.expiring_activity = data.aggregations.expiring_activity.buckets;
          this.data.date_aggs = data.aggregations.contracted.buckets;
          this.data.contracting_activity =
            data.aggregations.contracting_activity.buckets;
          this.data.annual_spend = data.aggregations.contracting_spend.buckets;
          this.data.avg_contract = data.aggregations.avg_contract_value.value;

        }).catch(error => {
          ////////////////console.log(error);
          this.errored = true;
        });

      searchAnalyzer(query).then(data => {
        this.search_profile = data.search_analysis;
        this.data.search_profile = data.search_analysis;
        this.formatDataTable();
      }).catch(error => {
        ////////////////console.log(error);
        this.errored = true;
      });

    },

    disableIcon(url) {
      if (!url) {
        return true;
      } else {
        return false;
      }
    },

    toggle() {
      this.watching = !this.watching;
      this.is_saved();
    },

    is_saved() {
      ////////////////console.log("NOTIFY STATUS: " + this.current_search.notify)
      return this.watching;
    },

    formatDataTable() {
      let dataview = {
        headers: [
          { text: 'Category', value: 'category' },
          { text: 'Value', value: 'value' },
          { text: 'Contracts', value: 'contracts' },
          { text: 'Avg Contract', value: 'avg_contract' },
          { text: 'Growth %', value: 'growth_percent' }
        ],
        data: []
      };

      // Extracting the Active Contracts Section Data
      dataview.data.push({
        category: 'Active Contracts - Value',
        value: this.formattedCurrency(this.search_profile.active_contracts.stats.sum),
        contracts: this.search_profile.active_contracts.stats.count,
        avg_contract: this.formattedCurrency(this.search_profile.active_contracts.stats.avg),
        growth_percent: 'N/A'
      });

      dataview.data.push({
        category: 'Active Contracts - Highest Value',
        value: this.formattedCurrency(this.search_profile.active_contracts.stats.max),
        contracts: 'N/A',
        avg_contract: 'N/A',
        growth_percent: 'N/A'
      });

      dataview.data.push({
        category: 'Awards - All Time - Value',
        value: this.formattedCurrency(this.search_profile.award_total),
        contracts: this.abbrv(this.search_profile.num_notices),
        avg_contract: this.formattedCurrency(this.search_profile.award_total / this.search_profile.num_notices),
        growth_percent: 'N/A'
      });

      dataview.data.push({
        category: 'Awards - Last 12 Months - Value',
        value: this.formattedCurrency(this.search_profile.growth.current_year_total),
        contracts: this.numFormatter(this.search_profile.growth.current_year_vol),
        avg_contract: this.formattedCurrency(this.search_profile.growth.curr_yr_avg_contract),
        growth_percent: this.growth_format(this.search_profile.growth.spend_change_percent).change + '%'
      });

      dataview.data.push({
        category: 'Expiring - Next 12 Months - Value',
        value: this.formattedCurrency(this.search_profile.expiring_soon.next_year_total),
        contracts: this.numFormatter(this.search_profile.expiring_soon.next_year_vol),
        avg_contract: this.formattedCurrency(this.search_profile.expiring_soon.next_yr_avg_contract),
        growth_percent: this.growth_format(this.search_profile.expiring_soon.spend_change_percent).change + '%'
      });

      this.$emit('dataview', dataview);
    },


    govAvatarColor(gov) {
      var color = "grey";
      switch (gov) {
        case "NSW":
          color = "#41a7d9";
          break;
        case "VIC":
          color = "#0058AC";
          break;
        case "WA":
          color = "#FFD200";
          break;
        case "QLD":
          color = "#A6003D";
          break;
        case "AUS":
          color = "#D8993B";
          break;
        case "FED":
          color = "#D8993B";
          break;
        case "DEF":
          color = "#d8673b";
          break;
        case "SA":
          color = "#ff4081";
          break;
        case "TAS":
          color = "#4eb888";
          break;
        case "ACT":
          color = "#41a7d9";
          break;
        case "NT":
          color = "#FF3647";
          break;
      }

      return color;
    }
  }
};
</script>

<style>
.chart {
  border-color: #424242 !important;
}

.headingUnderline {
  border-color: aqua !important;
}
</style>
