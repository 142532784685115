<template>
  <v-sheet class="px-3 pt-3 pb-12" :tile="true">
    <v-responsive class="mx-auto">
      <v-skeleton-loader
        ref="skeleton"
        :boilerplate="false"
        type="paragraph@4"
        :tile="true"
        class="mx-auto"
      ></v-skeleton-loader>
    </v-responsive>
  </v-sheet>
</template>

<script>
export default {
  // Vuetify components provide
  // a theme variable that is
  // used to determine dark

  inject: ["theme"]
};
</script>
