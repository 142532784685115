<template>
  <v-text-field
    v-model="search_terms"
    outlined
    dense
    flat
    hide-details
    label="Search contracts"
    prepend-inner-icon="mdi-magnify"
    class="ml-5 py-2 mr-1 font-regular"
    clearable
    rounded
    color="rgb(255, 20, 99)"
    @keyup.enter="keywordSearch(search_terms)"
  >
  </v-text-field>
</template>

<script>
import { bus } from "../main.js";
//import TenderFilters from "../components/TenderFilters.vue";

export default {
  components: {
    //TenderFilters
  },

  data() {
    return {
      search_terms: "",
      disable_nav: false,
      showMenu: false
      //show_login_menu: false
    };
  },
  created() {},

  methods: {
    showFilters() {
      ////////////console.log("SHOW FILTERS");
      this.showMenu = !this.showMenu;
      //bus.$emit("hideFilters", false);
    },

    keywordSearch(search_terms) {
                                                         
      var query = { tender: [search_terms] };

      if (this.$route.query.viewIndex){       
        query["viewIndex"] = this.$route.query.viewIndex;
      }
      this.$router.push({ path: "/search", query: query });
      this.search_terms = "";
      //bus.$emit("global-search", query);
    },

    disabled() {
      if (localStorage.getItem("isLoggedIn") == "1") {
        this.disable_nav = false;
        return this.disable_nav;
      } else {
        this.disable_nav = true;
        return this.disable_nav;
      }
    }
  }
};
</script>
<style>
.v-text-field.v-text-field--solo .v-input__control {
  min-height: 35px !important;
  padding: 0;
}
</style>
