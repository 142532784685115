<template>
  <div class="pl-1">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="300"
      offset-y
      :disabled="disabled"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="large"
          small
          v-bind="attrs"
          v-on="on"
          outlined 
          color="primary"
          :disabled="disabled"
          @click="loadClusters()"
        >
          Add to Cluster
          <v-icon large right>mdi-menu-down</v-icon>
        </v-btn>
        <v-btn
          v-else
          icon
          v-bind="attrs"
          v-on="on"
          large
          color="secondary"
          :disabled="disabled"
          @click="loadClusters()"
        >
          <v-icon>mdi-hexagon-multiple</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-list
      subheader
      two-line
      flat
    >
      <v-subheader>Select or create clusters for related entities</v-subheader>

    <v-card v-if="loading" flat>
      <v-overlay :value="loading" absolute :opacity="opacity">
          <v-progress-circular
            indeterminate
            size="36"
            color="primary"
          ></v-progress-circular>
        </v-overlay>
    </v-card>
    <v-card v-else flat class="px-2 py-2 mx-2 my-2" height="320" style="flex-grow: 1 !important; overflow:auto !important;">
        <v-text-field
          v-model="newClusterName"
          dense
          solo-inverted
          hide-details
          flat
          append-outer-icon="mdi-plus-circle-outline"
          label="New cluster name..."
          @click:append-outer="postCluster()"
          @keyup.enter="postCluster()"
        ></v-text-field>

        <v-list-item-group
        multiple
      >
        <v-list-item v-for="cluster in clusters" :key="cluster.id">
            <v-list-item-action>
              <v-checkbox
                v-if="clusters.length > 0"
                v-model="selectedClusters"
                :value="cluster.id"
                :input-value="exists(cluster.search_query[entityIDType])"
                color="primary"
              ></v-checkbox>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{cluster.name}}</v-list-item-title>
              <v-list-item-subtitle>{{cluster.search_query[entityIDType].length}} entities</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      </v-card>
      
    </v-list>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="menu = false">
            Cancel
          </v-btn>
          <v-btn color="primary" text @click="postClusters(), $emit('clearSelections'), (menu = false), selectedClusters = []">
            Apply
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
    
    <v-dialog>
        
    </v-dialog>
  </div>
</template>

<script>
import { getClusters, updateClusters, addCluster } from "../components/APIManager.js"
import { bus } from "../main.js";

export default {
  props: {
    current_buyer_flag: Boolean,
    disabled: Boolean,
    entityType: String,
    entityIDType: String,
    entities:[],
    large:{
      default: true,
      type: Boolean
    }

  },
  data: () => ({
    newClusterName: "",
    clusters: [],
    loading:true,
    clusterEntities: [],
    selectedClusters:[],
    fav: true,
    menu: false,
    message: false,
    hints: true,
    active: true,
    years: [2016, 2025],
    year_min: 2016,
    year_max: 2025,
    keyword_chips: [],
    keyword_items: [],
    selected_types: [],
    include_publisher: true,
    expiryRangeMenu: false,
    expiryRange: []
  }),

  mounted(){
    //this.loadClusters();

  },

  watch: {
    entityIDType (val, prev) {
      this.clusters = [];
      //this.loadClusters();
    }
  },

  computed: {

  },

  methods: {

    async postCluster(){
        this.loading = true;
        
        addCluster(encodeURIComponent(this.newClusterName), this.entityType).then(results => {
            this.clusters = results.data;
            for (var i in this.clusters){
                this.clusters[i].search_query = JSON.parse(this.clusters[i].search_query)
            }
            this.loading = false;
        });
    },

    async postClusters(){

        this.loading = true;
        var targetClusters = [];

        if (!this.selectedClusters){
            for (var i in this.clusters){
                targetClusters.push(this.clusters[i].id);
            }
        }
        else{
            targetClusters = this.selectedClusters;
        }

        updateClusters(targetClusters, this.entityType, this.entities).then(results => {
            
            this.clusters = results.data;
            for (var i in this.clusters){
                this.clusters[i].search_query = JSON.parse(this.clusters[i].search_query)
            }
            this.loading = false;
        });

    },

    async loadClusters(){

      this.clusters = [];

      this.loading = true;

      getClusters(this.entityType).then(results => {
        this.clusters = results.data;
        ////////////////console.log("CLUSTER QUERY: ", JSON.parse(this.clusters[0].search_query));
        for (var i in this.clusters){
            this.clusters[i].search_query = JSON.parse(this.clusters[i].search_query)
        }
        
        this.loading = false;
        ////////////console.log("loadPublishers data: ", results);
      });
    },

    exists(cluster_entities){
        
        var exists = cluster_entities.some(item => this.entities.includes(item));
        return exists;

    },
    
    partialExists(cluster_entities){
        let totalEntities = this.entities.length;
        let numMatches = 0;
        let partialMatch = false;

        for (let i = 0; i < this.entities.length; i++){
            if (cluster_entities.indexOf(this.entities[i]) === -1) {
                partialMatch = true;
                break;
            }
            else{
                numMatches++;
            }
        }

        if (numMatches == totalEntities){
            partialMatch = false; //is a fullmatch so indeterminate should be false
        }
      
        return partialMatch;
    },

    year() {
      var d = new Date();
      return d.getFullYear();
    },

    remove_kw(item) {
      this.keyword_chips.splice(this.keyword_chips.indexOf(item), 1);
      this.keyword_chips = [...this.keyword_chips];
    },

    clear_expiryRange() {
      this.expiryRange = [];
    },

    update_search() {
      let additional_filters = {
        keywords: this.keyword_chips,
        expiry_from: this.expiryRange[0],
        expiry_to: this.expiryRange[1],
        type: this.selected_types,
        include_publisher: this.include_publisher
      };

      bus.$emit("update-filter", additional_filters);
    }
  }
};
</script>
