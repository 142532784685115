var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pl-1"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize body-2",attrs:{"small":"","color":"secondary"}},'v-btn',attrs,false),on),[_vm._v(" Filters "),_c('v-icon',{attrs:{"large":"","right":""}},[_vm._v("mdi-menu-down")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',{staticClass:"pb-0 mb-0"},[_c('v-list-item-subtitle',[_vm._v("Filter the list")]),_c('v-combobox',{ref:"keywords",staticClass:"pb-0",attrs:{"x-small-chips":"","clearable":"","label":"Enter keywords","multiple":"","small":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"small":"","color":"rgb(255, 20, 99)","input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.remove_kw(item)}}},'v-chip',attrs,false),[_vm._v(_vm._s(item))])]}}]),model:{value:(_vm.keyword_chips),callback:function ($$v) {_vm.keyword_chips=$$v},expression:"keyword_chips"}})],1)],1),_c('v-list-item',[_c('v-menu',{ref:"expiryRangeMenu",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"body-2",attrs:{"label":"Select an expiry date range","prepend-icon":"mdi-calendar","readonly":"","clearable":""},on:{"click:clear":function($event){return _vm.clear_contract_end()}},model:{value:(_vm.expiryRangeText),callback:function ($$v) {_vm.expiryRangeText=$$v},expression:"expiryRangeText"}},on))]}}]),model:{value:(_vm.expiryRangeMenu),callback:function ($$v) {_vm.expiryRangeMenu=$$v},expression:"expiryRangeMenu"}},[_c('v-date-picker',{attrs:{"range":"","inp":""},model:{value:(_vm.expiryRange),callback:function ($$v) {_vm.expiryRange=$$v},expression:"expiryRange"}},[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.expiryRangeMenu = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.expiryRangeMenu.save(_vm.expiryRangeText)}}},[_vm._v("OK")])],1)],1)],1),_c('v-list-item',_vm._l((_vm.type_options),function(item){return _c('span',{key:item.value},[_c('v-checkbox',{staticClass:"pr-3",attrs:{"label":item.value,"color":"#4EB888","value":item.value,"hide-details":""},model:{value:(_vm.selected_types),callback:function ($$v) {_vm.selected_types=$$v},expression:"selected_types"}})],1)}),0),(_vm.current_buyer_flag)?_c('v-list-item',[_c('v-switch',{attrs:{"flat":"","label":"Current buyer notices only"},model:{value:(_vm.include_publisher),callback:function ($$v) {_vm.include_publisher=$$v},expression:"include_publisher"}})],1):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"#41a7d9","text":""},on:{"click":function($event){_vm.update_search(), (_vm.menu = false)}}},[_vm._v(" Apply ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }