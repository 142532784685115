
<template>
  <div>
    <div :id="chartId" ref="chartContainer" :style="reportSize"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    chartHeight:{
      type: String,
      default: "200px"
    },
    chartWidth: String(),
    cardWidth: String(),
    cardHeight: String(),
    percentageType: {
      type: String,
      required: true,
      validator: (value) => ['percentage_value', 'percentage_count'].includes(value),
    },
  },
  data() {
    return {
      chart: null,
      chartId: `echarts-${Math.random().toString(36).substr(2, 9)}`
    };
  },
  mounted() {
    this.initChart();
  },

computed: {
    reportSize() {
      return 'margin-top: 0px !important;padding-top: 0px !important;padding-left: 0px !important;padding-bottom: 0px !important;width: 100% !important;height: '+this.chartHeight+' !important;'

    }
  },

  methods: {
    initChart() {
      const chartContainer = this.$refs.chartContainer;
      this.chart = echarts.init(chartContainer);

      const entities = Array.from(new Set(this.data.map((item) => item.entity)));
      const financialYears = Array.from(new Set(this.data.map((item) => item.financial_year))).sort();

      const seriesData = entities.map((entity) => ({
        name: entity,
        type: 'bar',
        stack: this.percentageType,
        data: financialYears.map((year) => {
          const record = this.data.find((item) => item.entity === entity && item.financial_year === year) || {};
          return {
            value: record[this.percentageType] || 0,
            tender_count: record.tender_count,
            total_value: record.total_value,
          };
        }),
      })).sort(
          (a, b) =>
            b.data[b.data.length - 1].value - a.data[a.data.length - 1].value
        );

      const option = {
        color : [
                  "#12253a",
                  "#ff1463",
                  "#d73e32",
                  "#2196f3",
                  "#004700",
                  "#2d9437",
                  "#004d9f",
                  "#007cd6",
                  "#d73e32",
                  "#de004b",
                  "#ff457c",
                  "#4a575d",
                  "#7b898f",
                  "#bb5900",
                  "#bb5900",
                  "#ffa72d",
                  "#4caf50",
                  "#2196f3",
                  "#fb8c00",
                  "#ff1463",
                  "#b71c1c",
                  "#ff937b",
                  "#95e8ff",
                  "#a2ff9e",
                  "#fffb82",
                  "#FFE70A",
                  "#SFS603",
                  "#7F7305",
                  "#BFAB07",
                  "#FF6905",
                  "#5405FF"
                ],
        grid: {
            left: "16px",
            bottom: "0px",
            top: "40px",
            containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          formatter: ((percentageType) => {
            return (params) => {
              function getMetric(item){
                if (percentageType == 'percentage_value'){
                  return '$'+ convert(item.data.total_value);
                }
                else{
                  return item.data.tender_count
                }
              }

              function convert (num){
                  if (num > 999 && num < 1000000) {
                  num = (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
                  } else if (num >= 1000000 && num < 1000000000) {
                  num = (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
                  } else if (num >= 1000000000) {
                  num = (num / 1000000000).toFixed(1) + "B"; // convert to M for number from > 1 million
                  }
                  return num;
              }
              const tooltipItems = params.filter((param) => param.value !== 0);
              if (tooltipItems.length === 0) {
                return '';
              }

              tooltipItems.sort((a, b) => b.data.value - a.data.value);
              
              let tooltip = ``;
              if (this.percentageType == 'percentage_value'){
                tooltip = `<table align="left"><thead><tr><th style="text-align: left;padding-right:10px; font-size:11px; font-family:Roboto;">Entity</th><th style="text-align: left;padding-right:10px; font-size:11px; font-family:Roboto;">% Participation</th><th style="text-align: left;padding-right:10px; font-size:11px; font-family:Roboto;">$ Total</th></tr></thead>`;
              }
              else{
                tooltip = `<table align="left"><thead><tr><th style="text-align: left;padding-right:10px; font-size:11px; font-family:Roboto;">Entity</th><th style="text-align: left;padding-right:10px; font-size:11px; font-family:Roboto;">% Participation</th><th style="text-align: left;padding-right:10px; font-size:11px; font-family:Roboto;"># Notices</th></tr></thead>`;
              }

              tooltipItems.forEach((item) => {
                const truncatedSeriesName = item.seriesName.length > 30 ? item.seriesName.slice(0, 30) + '...' : item.seriesName;
                const totalValue = (this.data.find((record) => record.entity === item.seriesName && record.financial_year === item.axisValue) || {}).total || 0;
                tooltip += `<tr><td><div style="display:inline-block;margin-right:5px;border-radius:50%;width:14px;height:14px;border:2px solid #fff;box-shadow:0 2px 4px rgb(0 0 0 / 25%);background-color:${item.color};"></div> <span style="font-family:Roboto;font-size:10px;display:inline-block;width:150px;text-align:left;">${truncatedSeriesName}</span></td><td style="text-align: center;"><span style="font-family:Roboto;font-size:10px;">${item.data.value}%</td><td style="text-align: center;"><span style="font-family:Roboto;font-size:10px;"> ${getMetric(item)}</td><tr>`;
              });
              tooltip += `</table>`;
              return tooltip;
          }})(this.percentageType),
        },
        legend: {
            show: true,
            icon: "circle",
            orient: 'horizontal',
            textStyle: {
                fontSize: 9
            },
            padding: [0, 5, 10, 5],
            type: "scroll",
            left: 10,
            //top:'center',
            //bottom: 0
            },
        xAxis: {
          type: 'category',
          show: true,
          axisLabel: {
            fontSize: 9,
            showMinLabel: true,
            showMaxLabel: true
          },
          boundaryGap: true,
          axisTick:{
            show: false,
            interval: "auto"
          },
          data: financialYears,
        },
        yAxis: {
          type: 'value',
          scale: true,
          max: 100,
          axisLabel: {
            fontSize: 9,
            showMinLabel: true,
            showMaxLabel: true
          },
          axisTick:{
            show: false,
            interval: "auto"
          },
          
          splitLine: {
            show: false
          },
          show: true,
        },
        series: seriesData,
      };

      this.chart.setOption(option);
    },
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.initChart();
      },
    },
    percentageType() {
      this.initChart();
    },
  },
};
</script>