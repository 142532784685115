<template>
  <div :id="id" class="reportLine"></div>
</template>

<script>
import * as echarts from "echarts";
import {downloadFile} from "./Utils";
import $ from 'jquery';
//import jsondata from "../temp/life-expectancy-table.json";

export default {

 props:{
     series: Array(),
     legend_data: Array(),
     x_axis_data: [],
     changed: Number,
     download: Number,
     metric_type: String,
     target_group: String,
     interval: String,
     story: Boolean,
     embed: Number,
     chart_options: {},
     id: Number,
      chart_size: {
        type: String(),
        default: "large"
      }
 },
  data() {
    return {
      options: {},
      myChart: null,
      canvasCSS: String(),
      chartHeight: Number(),
      chartWidth: Number(),
      legend_padding: Number()
    };
  },

  watch: {
    changed() {
      //this.myChart.dispose();
      this.loadChart();
    },

    embed() {
      ////////////console.log("CALL SHARE CHART\n", this.myChart);
      
      this.saveChart();
    },

    download() {
      this.saveImage();
    }
  },

  mounted() {
      //nextTick will fix chart width at 100px issue
      this.$nextTick(()=>{
        this.loadChart();
      });
  },

  methods: {

    formatDataTable() {
      let dataview = {
        headers: [],
        data: []
      };

      // Populate the headers
      dataview.headers.push({ text: 'Date/Category', value: 'category' }); // The first column header
      this.series.data.forEach((serie, index) => {
        dataview.headers.push({ text: serie.name, value: `series_${index}` });
      });

      // Populate the data rows
      this.x_axis_data.forEach((category, rowIndex) => {
        let row = { category: category }; // Initialize each row with the x_axis_data (category)
        this.series.data.forEach((serie, seriesIndex) => {
          const dataPoint = serie.data[rowIndex];
          row[`series_${seriesIndex}`] = dataPoint && dataPoint.value ? dataPoint.value : 'N/A'; // Add data for each series
        });
        dataview.data.push(row);
      });

      this.$emit('dataview', dataview);
    },

    
    setStyle () {
      if (this.chart_size == "large"){
        this.chartHeight= 60;
        this.chartWidth=180;
        this.legend_padding = -30;
        this.canvasCSS = "reportPieLarge";
        
      }
      else{
        this.chartHeight= 40;
        this.chartWidth=120;
        this.legend_padding = -30;
        this.canvasCSS = "reportPieSmall";;
      }
            
    },

    saveChart(){

      var imgUrl = this.myChart.getDataURL();

      let data = {
        imgData: imgUrl, //this.myChart.getDataURL(),
        options: this.options
      };

      //console.log("ELINE CHART PAYLOAD: ", data);

      this.$emit("chart_object", data);

    },

    saveImage(){

      var img = new Image();
      
      img.src = this.myChart.getDataURL({
          pixelRatio: 2,
          backgroundColor: '#fff'
      });

      downloadFile(img.src);

    },

    loadChart() {

        if (this.myChart != null && this.myChart != "" && this.myChart != undefined) {
            this.myChart.dispose();
        }

        var chartDom = document.getElementById(this.id);
        this.myChart = echarts.init(chartDom);

        if (this.story){
          
          //set end labels
          for (let i in this.chart_options.series){
            this.chart_options.series[i].endLabel.formatter = '{a}';
          }

          this.chart_options.tooltip.formatter = function (params){
              function convert (num){
                if (num > 999 && num < 1000000) {
                  num = (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
                } else if (num >= 1000000 && num < 1000000000) {
                  num = (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
                } else if (num >= 1000000000) {
                  num = (num / 1000000000).toFixed(1) + "B"; // convert to M for number from > 1 million
                }
                return num;
              }

              function getColor(number){
                if (number.includes("-")) return "red";
                else if (number.includes("+")) return "green";
                else return "black";
              }

              var infocard = [];

              for (var i in params){
                let val = params[i].data.value;

                if (params[i].data.type == "total_amount"){
                  val = "$" + convert(val);
                }

                //let iconStyle = '"background-color:'+params[i].data.color + 'flex-shrink: 0; width: 14px;height: 14px;border-radius: 50%;margin-right: 12px;display: inline; align-items: center;justify-content: center;fill: #fff;border: 2px solid #fff;box-shadow: 0 2px 4px rgb(0 0 0 / 25%);"';
                //iconStyle = iconStyle + 'background-color:'+params[i].data.color;
                var colorSpan = color => '<span style="display:inline-block;margin-right:5px;border-radius:50%;width:14px;height:14px;border: 2px solid #fff;box-shadow: 0 2px 4px rgb(0 0 0 / 25%);background-color:' + color + '"></span>';
                infocard[i] = [
                  '<tr><td style="text-align: left;">',
                    //'<div style="color: #12253a; font-size:12; font-family:Roboto; font-weight:normal;">',
                      ''+colorSpan(params[i].color)+'',
                      '<span style="color: #12253a; font-size:12; font-family:Roboto; font-weight:normal; padding-right:10px;">' + params[i].seriesName + '</span>',
                  '</td>',
                  '<td style="text-align: left;">',
                      '<span style="color: #12253a; font-size:12; font-family:Roboto; font-weight:bold; padding-right:10px;">' + val + '</span>',
                  '</td>',
                  '<td style="text-align: left;">',
                      '<span style="color:' + getColor(params[i].data.percent) + ';font-size:12; font-family:Roboto; font-weight:bold;">' + params[i].data.percent + '</span>',
                  '</td></tr>',
                      //'<span style="color: #12253a; font-size:12; font-family:Roboto; font-weight:bold; float: right;"> (' + params[i].data.percent + ')</span></div>',
                ].join('');
                
              };

              infocard.unshift('<table align="left"><thead><tr><th style="text-align: left;padding-right:10px;">' + params[i].name + '</th><th style="text-align: left;padding-right:10px;">Total</th><th style="text-align: left;">Change</th></tr></thead>');
              infocard.push('</table>');

              return infocard.join('');
          };
          
          this.chart_options.yAxis.axisLabel.formatter = (s) => {
            function convert (num){
                if (num > 999 && num < 1000000) {
                    num = (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
                } else if (num >= 1000000 && num < 1000000000) {
                    num = (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
                } else if (num >= 1000000000) {
                    num = (num / 1000000000).toFixed(1) + "B"; // convert to M for number from > 1 million
                }

                return num;
            }

            if (this.chart_options.metric_type == "total_amount"){
              return '{valueStyle|' + '$'+ convert(s) + '}';
            }else{
              return '{valueStyle|' + s + '}';
            }
            
        };

          this.myChart.setOption(this.chart_options);
        }
        else{
          var option;


          option = {
              animationDuration:1000,
              tooltip: {
                trigger: 'axis',
                formatter: function (params){

                      function convert (num){
                        if (num > 999 && num < 1000000) {
                          num = (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
                        } else if (num >= 1000000 && num < 1000000000) {
                          num = (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
                        } else if (num >= 1000000000) {
                          num = (num / 1000000000).toFixed(1) + "B"; // convert to M for number from > 1 million
                        }
                        return num;
                      }

                      function getColor(number){
                        if (number.includes("-")) return "red";
                        else if (number.includes("+")) return "green";
                        else return "black";
                      }

                      var infocard = [];

                      for (var i in params){
                        let val = params[i].data.value;

                        if (params[i].data.type == "total_amount"){
                          val = "$" + convert(val);
                        }

                        //let iconStyle = '"background-color:'+params[i].data.color + 'flex-shrink: 0; width: 14px;height: 14px;border-radius: 50%;margin-right: 12px;display: inline; align-items: center;justify-content: center;fill: #fff;border: 2px solid #fff;box-shadow: 0 2px 4px rgb(0 0 0 / 25%);"';
                        //iconStyle = iconStyle + 'background-color:'+params[i].data.color;
                        var colorSpan = color => '<span style="display:inline-block;margin-right:5px;border-radius:50%;width:14px;height:14px;border: 2px solid #fff;box-shadow: 0 2px 4px rgb(0 0 0 / 25%);background-color:' + color + '"></span>';
                        infocard[i] = [
                          '<tr><td style="text-align: left;">',
                            //'<div style="color: #12253a; font-size:12; font-family:Roboto; font-weight:normal;">',
                              ''+colorSpan(params[i].color)+'',
                              '<span style="color: #12253a; font-size:12; font-family:Roboto; font-weight:normal; padding-right:10px;">' + params[i].seriesName + '</span>',
                          '</td>',
                          '<td style="text-align: left;">',
                              '<span style="color: #12253a; font-size:12; font-family:Roboto; font-weight:bold; padding-right:10px;">' + val + '</span>',
                          '</td>',
                          '<td style="text-align: left;">',
                              '<span style="color:' + getColor(params[i].data.percent) + ';font-size:12; font-family:Roboto; font-weight:bold;">' + params[i].data.percent + '</span>',
                          '</td></tr>',
                              //'<span style="color: #12253a; font-size:12; font-family:Roboto; font-weight:bold; float: right;"> (' + params[i].data.percent + ')</span></div>',
                        ].join('');
                        
                      };

                      //add header
                      infocard.unshift('<table align="left"><thead><tr><th style="text-align: left;padding-right:10px;">' + params[i].name + '</th><th style="text-align: left;padding-right:10px;">Total</th><th style="text-align: left;">Change</th></tr></thead>');
                      infocard.push('</table>');

                      return infocard.join('');
                  },
              },
              legend: {
                show: true,
                icon: "circle",
                orient: 'horizontal',
                //type: "scroll",
                //right: 0,
                //top:'center',
                bottom: 0
              },
              grid: {
                  left: "1.5%",
                  bottom: "85px",
                  top: "20px",
                  //right: 240,
                  containLabel: true
              },
              xAxis: {
                  type: 'category',
                  axisLabel: {
                    fontSize: 12
                  },
                  boundaryGap: true,
                  data: this.x_axis_data
              },
              yAxis: {
                  type: 'value',
                  scale: true,
                  axisLabel: {
                      showMinLabel: false,
                      showMaxLabel: true,
                      formatter : (s) => {
                          function convert (num){
                              if (num > 999 && num < 1000000) {
                                  num = (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
                              } else if (num >= 1000000 && num < 1000000000) {
                                  num = (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
                              } else if (num >= 1000000000) {
                                  num = (num / 1000000000).toFixed(1) + "B"; // convert to M for number from > 1 million
                              }

                              return num;
                          }

                          if (this.metric_type == "total_amount"){
                            return '{valueStyle|' + '$'+ convert(s) + '}';
                          }else{
                            return '{valueStyle|' + s + '}';
                          }
                          
                      },
                      rich: {
                          valueStyle: {
                              // Make yearly text more standing out
                              color: '#000',
                              fontWeight: 'normal',
                              fontSize: 12
                          }
                      }
                  },
              },
              series: this.series.data
          };

          this.myChart.setOption(option);

          this.options = option;
          this.options.metric_type = this.metric_type;

          //this.saveChart();
          this.formatDataTable();

          this.myChart.on('click', param => {

            //////////console.log("line params: ", param);
            //////////console.log("target_group: ", this.target_group);

            let args = [];

            let field = this.target_group;
            if (field == "supplier_clusters"){
              field = "supplier_cluster_name";
            }
            if (field == "buyer_clusters"){
              field = "buyer_cluster_name";
            }

            if (this.interval == "year"){
              args[0] = {field: "contract_year_from", value: param.name}
              args[1] = {field: "contract_year_to", value: param.name}
              args[2] = {field: field, value: param.seriesName}
            }
            else if (this.interval == "fy"){
              args[0] = {field: "fy", value: param.name}
              args[1] = {field: "fy", value: param.name}
              args[2] = {field: field, value: param.seriesName}
            }
            else{

              var year = param.name;
              year = year.split('-');
              year = year[1];
              
              var qtr = param.name;
              /* qtr = qtr.split('-');
              qtr = qtr[0].split("Q")[1];
              qtr = this.getMonthsInQuarter(qtr); */

              year = "20" + year;
              //qtr = qtr.split('-');
              qtr = qtr.substring(0,3);
              //qtr = qtr[0].split("Q")[1];
              qtr = this.getMonthsInQuarter(qtr);

              ////////////console.log("QTR: ", qtr);

              args[0] = {field: "contract_year_from", value: year}
              args[1] = {field: "contract_year_to", value: year}
              args[2] = {field: "contract_month", value: qtr}
              args[3] = {field: field, value: param.seriesName}
            }

            this.$emit("showTenders", args);
          
          });
        }

    },

    getMonthsInQuarter(qtr){
      switch (qtr) {
        case "1":
          return ["January", "February", "March"];
        case "2":
          return ["April", "May", "June"];
        case "3":
          return ["July", "August", "September"];
        case "4":
          return ["October", "November", "December"];
        
      }
    }
  }
}

</script>
<style>
.reportLine {
  margin-top: 0px !important;
  padding-top: 0px !important;
  padding-left: 0px !important;
  width: 100% !important;
  height: 500px !important;
  /* padding: 0px, 0px, 0px, 0px !important; */
}

.storyLine {
  margin-top: 0px !important;
  padding-top: 0px !important;
  padding-left: 0px !important;
  width: 1040px !important;
  height: 500px !important;
  /* padding: 0px, 0px, 0px, 0px !important; */
}
</style>