/* eslint-disable prettier/prettier */
<template>
  <div class="fill-height">
    <span class="desktop-nav">
      <v-dialog v-model="tender_dialog" width="50%">
        <tender-profile :item="item" :selected_avatar="selected_avatar" @hideProfile="tender_dialog=false" />
      </v-dialog>

      <v-simple-table style="width:100%;">
        <thead>
          <tr>
            <th class="text-left subtitle-2 font-weight-bold py-3 pl-6 pr-3">
              Gov
              <span>
              <v-icon :color="sort_color.government.asc" small @click="updateSort('government', 'asc')">
                mdi-menu-up
              </v-icon>
              </span>
              <span>
              <v-icon :color="sort_color.government.desc" small @click="updateSort('government', 'desc')">
                mdi-menu-down
              </v-icon>
              </span>
            </th>
            <th class="text-left subtitle-2 font-weight-bold py-3 pl-1 pr-2">
              Notice
            </th>
            <th
              v-if="type_filter"
              class="text-center subtitle-2 font-weight-bold py-3 pl-0 ml-0 px-1"
              style="color:rgba(0, 0, 0, 0.6)"
            >
              <v-select
                class="subtitle-2 font-weight-bold pb-2"
                v-model="selected_type"
                :items="types"
                dense
                single-line
                style="width:150px;"
                label="Type"
                item-text="type"
                item-value="type"
                return-object
                @change="updateQuery()"
              ></v-select>
            </th>
            <th
              v-else
              class="text-left subtitle-2 font-weight-bold py-3 pl-1 pr-1"
            >
              Type
            </th>
            <th class="text-left subtitle-2 font-weight-bold py-3 pl-1 pr-0">
            </th>
            <th class="text-left subtitle-2 font-weight-bold py-3 pl-1 pr-1">
              Buyer
              <span>
              <v-icon :color="sort_color.publisher.asc" small @click="updateSort('publisher', 'asc')">
                mdi-menu-up
              </v-icon>
              </span>
              <span>
              <v-icon :color="sort_color.publisher.desc" small @click="updateSort('publisher', 'desc')">
                mdi-menu-down
              </v-icon>
              </span>
            </th>
            <th class="text-left subtitle-2 font-weight-bold py-3 pl-1 pr-0">
            </th>
            <th
              class="text-left subtitle-2 font-weight-bold py-3 p2-2 pl-1 ml-1"
            >
              Supplier
              <span>
              <v-icon :color="sort_color.supplier.asc" small @click="updateSort('supplier', 'asc')">
                mdi-menu-up
              </v-icon>
              </span>
              <span>
              <v-icon :color="sort_color.supplier.desc" small @click="updateSort('supplier', 'desc')">
                mdi-menu-down
              </v-icon>
              </span>
            </th>
            
            <th class="text-left subtitle-2 font-weight-bold py-3 px-1">
              Expiry
              <span>
              <v-icon :color="sort_color.expiry_date.asc" small @click="updateSort('expiry_date', 'asc')">
                mdi-menu-up
              </v-icon>
              </span>
              <span>
              <v-icon :color="sort_color.expiry_date.desc" small @click="updateSort('expiry_date', 'desc')">
                mdi-menu-down
              </v-icon>
              </span>
            </th>
            <th class="text-left subtitle-2 font-weight-bold py-3 px-1">
              Amount
              <span>
              <v-icon :color="sort_color.value.asc" small @click="updateSort('value', 'asc')">
                mdi-menu-up
              </v-icon>
              </span>
              <span>
              <v-icon :color="sort_color.value.desc" small @click="updateSort('value', 'desc')">
                mdi-menu-down
              </v-icon>
              </span>
            </th>

            <th
              colspan="3"
              class="text-left subtitle-2 font-weight-bold py-3 px-3"
              style="width:150px;"
            >
              <!-- <span> Actions</span> -->

              <span class="px-1">
                <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" color="primary" icon medium @click="downloadResults()"
                      ><v-icon medium>mdi-download</v-icon></v-btn
                    >
                  </template>
                  <span>Download Page Results</span>
                </v-tooltip>
              </span>
              <span class="px-1">
                <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                  <template v-slot:activator="{ on }">
                    <v-btn :disabled="disableBookmarks" v-on="on" color="primary" icon medium @click="addMultipleBookmarks()"
                      ><v-icon medium>mdi-bookmark-plus</v-icon></v-btn
                    >
                  </template>
                  <span>Add selected notice(s) to prospect lists</span>
                </v-tooltip>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in rows" :key="item.id" style="vertical-align: top;"
              >
            <td class="py-2 pl-5 pr-3 mr-3" style="width:100px;">
              <v-avatar
                :color="govAvatarColor(item.government)"
                size="36"
                class="mr-2"
              >
                <span class="white--text caption">{{ item.government }}</span>
              </v-avatar>
            </td>
            <td class="py-2 px-1 ml-1">
                <div
                  class="subtitle-2 d-inline-block text-truncate"
                  style="max-width:300px; min-width:150px;"
                >
                      <router-link
                        target="_blank"
                        text
                        :to="{ name: 'tender', params: { id: item.id } }"
                        >{{ item.title }}</router-link
                      >
                </div>
                <div
                  class="caption text-truncate pt-0 mt-0"
                  style="max-width:250px; min-width:150px;"
                >
                <span>
                  <a
                    target="_blank"
                    text
                    v-on="on"
                    @click="
                      showHideDetails(item, govAvatarColor(item.government))
                    "
                    >{{ item.recid }}</a
                  >
                </span>
                <span class="pl-2 pt-0">
                  <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon x-small color="secondary" @click="
                          showHideDetails(item, govAvatarColor(item.government))
                        "><v-icon medium>mdi-eye-outline</v-icon></v-btn>
                    </template>
                    <span>Preview the notice</span>
                  </v-tooltip>
                </span>
                </div>

                <div
                  class="caption text-truncate"
                  style="max-width:250px; min-width:150px;"
                >
                  <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                    <template v-slot:activator="{ on }">
                      <router-link
                        text
                        style="text-decoration: none; !important;"
                        v-on="on"
                        :to="{
                          name: 'category',
                          params: {
                            id: item.category_id
                          }
                        }"
                        >{{ item.category }}</router-link
                      >
                    </template>
                    <span>{{ item.category }}</span>
                  </v-tooltip>
                </div>
                <div>
                  <span v-if="item.is_consultancy_services == 'Yes'">
                    <router-link target="_blank"
                                :to="{ name: 'search', query: { isconsultancy: true } }">
                      <v-chip                      
                        label
                        color="secondary"
                        x-small
                        class="mr-2"
                      >
                        {{ 'Consultancy' }}
                      </v-chip>
                    </router-link>
                    </span>
                  <span  v-for="(tag, index) in getMatchedFields(item.matched_fields)" :key="index">
                    <router-link target="_blank"
                                :to="{ name: 'search', query: { tender: [tag] } }">
                      <v-chip                      
                        label
                        color="primary"
                        x-small
                        class="mr-2"
                      >
                        {{ tag }}
                      </v-chip>
                    </router-link>
                    </span>
                </div>
            </td>

            <td class="py-2  pl-2 pr-1">
              <div class="text-left body-2" style="min-width:110px">
                <router-link
                    v-if="item.son_ref"
                    :to="{ path: '/panel', query: { son_id: item.son_ref, government: item.government }}"
                  >
                    <v-chip
                      small
                      outlined
                      :color="dispositionColor(item.disposition)"
                      label
                      text-color="secondary"
                    >
                      <v-icon small left>mdi-label</v-icon>
                      {{ dispositionText(item) }}
                    </v-chip>
                  </router-link>
                  
                  <v-chip
                    v-else
                    small
                    outlined
                    :color="dispositionColor(item.disposition)"
                    label
                    text-color="secondary"
                  >
                    <v-icon small left>mdi-label</v-icon>
                    {{ dispositionText(item) }}
                  </v-chip>
              </div>
            </td>
            <td class="py-2 px-2">
              <div class="excludePublisher">
                <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    color="secondary"
                    light
                    icon
                    @click="excludePublisher(item.publisher)"
                    v-on="on"
                    style="text-decoration: none !important;"
                  >
                    <v-icon>mdi-eye-off</v-icon>
                  </v-btn>
                </template>
                <span><div>Exclude results from this buyer in this search.</div><div>Use the exclude list in advanced filters to undo this.</div><div><b>TIP:</b> Save this search to retain this setting.</div></span>
              </v-tooltip>
              </div>
              
            </td>
            <td 
              class="py-2 pr-2 pl-1 ml-1"
              >
              <div
                class="body-2 text-uppercase"
                style="max-width:200px; min-width:100px; word-wrap: break-word;"
              >
                <router-link
                  text
                  style="text-decoration: none; !important;"
                  :to="{ name: 'entity', params: { id: item.publisher_id } }"
                >
                  {{ item.publisher }}</router-link
                >
              </div>
              <div>
                <span v-for="pc in item.publisher_clusters" :key="pc.cluster_id">
                  <router-link target="_blank"
                              :to="{ name: 'cluster', params: { clusterId: pc.cluster_id } }"
                            >
                      <v-chip
                        label
                        color="primary"
                        x-small
                        class="mr-2"
                        outlined
                      >
                        <v-icon x-small left>
                        mdi-hexagon-multiple
                      </v-icon>
                        {{ pc.cluster_name }}
                  </v-chip>
                </router-link>
              </span>
              </div>
            </td>
            <td class="py-2 px-2">
              <div class="excludeSupplier">
                <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    color="secondary"
                    light
                    icon
                    @click="excludeSupplier(item.supplier)"
                    v-on="on"
                    style="text-decoration: none !important;"
                  >
                    <v-icon>mdi-eye-off</v-icon>
                  </v-btn>
                </template>
                <span><div>Exclude results from this supplier in this search.</div><div>Use the exclude list in advanced filters to undo this.</div><div><b>TIP:</b> Save this search to retain this setting.</div></span>
              </v-tooltip>
              </div>
            </td>
            <td class="py-2 pr-2 pl-1 ml-1">
              <div
                class="body-2 text-uppercase"
                style="max-width:200px; min-width:150px; word-wrap: break-word;"
              >
                <router-link
                  text
                  style="text-decoration: none; !important;"
                  :to="{ name: 'supplier', params: { id: item.supplier_id } }"
                >
                  {{ item.supplier }}</router-link
                >
              </div>
              <div>
                <span v-for="sc in item.supplier_clusters" :key="sc.cluster_id">
                  <router-link target="_blank"
                              :to="{ name: 'cluster', params: { clusterId: sc.cluster_id } }"
                            >
                      <v-chip
                        label
                        color="primary"
                        x-small
                        class="mr-2"
                        outlined
                      >
                      <v-icon x-small left>
                      mdi-hexagon-multiple
                    </v-icon>
                        {{ sc.cluster_name }}
                      </v-chip>
                    </router-link>
                  </span>
                </div>
            </td>
            

            <td class="py-2 px-1">
              <div class="body-2" style="min-width:110px">
                {{ formatDate(item.expiry_date) }}
              </div>
            </td>
            <td class="py-2 px-1">
              <div class="body-2" style="min-width:110px">
                {{ formattedCurrency(item.value) }}
              </div>
            </td>
            <td class="py-2 px-1">
              <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    color="secondary"
                    light
                    icon
                    target="_blank"
                    :to="{ name: 'tender', params: { id: item.id } }"
                    v-on="on"
                    style="text-decoration: none !important;"
                  >
                    <v-icon>mdi-open-in-new</v-icon>
                  </v-btn>
                </template>
                <span><div>Exclude results from this supplier in this search.</div><div>Use the exclude list in advanced filters to undo this.</div><div><b>TIP:</b> Save this search to retain this setting.</div></span>
              </v-tooltip>
            </td>
            <td class="py-2 px-1">
              <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    icon
                    color="#4EB888"
                    light
                    :href="item.url"
                    target="_blank"
                    :disabled="disableIcon(item.url)"
                    v-on="on"
                    style="text-decoration: none !important;"
                  >
                    <v-icon>mdi-web</v-icon>
                  </v-btn>
                </template>
                <span>Open Source Page</span>
              </v-tooltip>
            </td>
            <td v-if="item.bookmarked" class="py-2 px-1">
              <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    color="secondary"
                    light
                    icon
                    @click="addRemoveSingleBookmark(item)"
                    v-on="on"
                  >
                    <v-icon>mdi-bookmark</v-icon>
                  </v-btn>
                </template>
                <span>Add or remove from prospect list</span>
              </v-tooltip>
            </td>
            <td v-else class="py-2 px-1">
              <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    :color="tempBookmarkColour(item.id)"
                    light
                    icon
                    @click="toggleBookmark(item.id)"
                    v-on="on"
                  >
                  <v-icon>mdi-bookmark-outline</v-icon>
                  </v-btn>
                </template>
                <span>Select item to add to prospect list</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </span>
    <v-row class="py-0 my-0 mobile-nav">
      <v-col cols="9">
        <v-select
          v-model="selected_sort"
          :items="sort_options"
          item-text="label"
          item-value="value"
          label="Sort By"
          color="#4eb888"
          dense
          outlined
          return-object
          x-small
          width="100px"
          class="pt-2 pb-0 my-0 subtitle-1 font-weight-normal"
        >
        </v-select>
      </v-col>
    </v-row>
    <v-container fluid class="ml-0 pl-0 pt-0 mt-0 mobile-nav">
      <v-row v-for="item in rows" :key="item.id">
        <v-col cols="12" class="pl-3 pr-0 pt-0 mt-0 pb-3">
          <v-card tile class="pr-3 pt-0 pb-0" min-height="200">
            <v-card-title class="subtitle-2 font-weight-normal pb-0 mb-0">
              <v-avatar
                :color="govAvatarColor(item.government)"
                size="36"
                class="mr-2"
              >
                <span class="caption">{{ item.government }}</span>
              </v-avatar>
              <a
                text
                style="text-decoration: none; color:grey !important;"
                @click="search_publisher(item.publisher)"
              >
                <span
                  class="d-inline-block text-truncate"
                  style="max-width:250px; min-width:150px;"
                >
                  {{ item.publisher }}
                </span></a
              >
              <div class="flex-grow-1 desktop-nav"></div>
              <span class="desktop-nav">
                <a
                  text
                  style="text-decoration: none; color:grey !important;"
                  @click="
                    search_supplier(item.supplier_company_number, item.supplier)
                  "
                >
                  {{ item.supplier }}</a
                >
              </span>
              <v-divider vertical class="my-1 mx-3 desktop-nav"></v-divider>
              <span class="desktop-nav" style="color:grey !important;">{{
                formattedCurrency(item.value)
              }}</span>
            </v-card-title>

            <v-row>
              <v-col cols="12">
                <v-expansion-panels
                  class="pt-0 mt-0"
                  flat
                  tile
                  accordion
                  style="z-index:unset !important"
                >
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      class="pl-0 ml-0"
                      expand-icon="mdi-menu-down"
                    >
                      <v-card-title class="pt-0 pb-0 mt-0 mb-1">
                        {{ item.title }}
                      </v-card-title>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pl-4">
                      <v-card-title class="pl-0 ml-0 py-1 my-1">
                        <span class="subtitle-2 font-weight-bold card-data"
                          >Publisher</span
                        >
                        <span class="body-2 font-weight-normal">{{
                          item.publisher
                        }}</span>
                      </v-card-title>

                      <v-card-title
                        v-if="item.publisher_division"
                        class="pl-0 ml-0 py-1 my-1"
                      >
                        <span class="subtitle-2 font-weight-bold card-data"
                          >Division</span
                        >
                        <span class="body-2 font-weight-normal">{{
                          item.publisher_division
                        }}</span>
                      </v-card-title>
                      <v-card-title
                        v-if="item.value"
                        class="pl-0 ml-0 py-1 my-1"
                      >
                        <span class="subtitle-2 font-weight-bold card-data"
                          >Contract Value</span
                        >
                        <span class="body-2 font-weight-normal">{{
                          formattedCurrency(item.value)
                        }}</span>
                      </v-card-title>
                      <v-card-title
                        v-if="item.supplier"
                        class="pl-0 ml-0 py-1 my-1"
                      >
                        <span class="subtitle-2 font-weight-bold card-data"
                          >Supplier</span
                        >
                        <a
                          class="body-2 font-weight-normal"
                          @click="
                            search_supplier(
                              item.supplier_company_number,
                              item.supplier
                            )
                          "
                          >{{ item.supplier }}</a
                        >
                      </v-card-title>
                      <v-card-title
                        v-if="item.supplier_company_number"
                        class="pl-0 ml-0 py-1 my-1"
                      >
                        <span class="subtitle-2 font-weight-bold card-data"
                          >Supplier ABN</span
                        >
                        <span class="body-2 font-weight-normal">{{
                          item.supplier_company_number
                        }}</span>
                      </v-card-title>
                      <v-card-title
                        v-if="item.published_date"
                        class="pl-0 ml-0 py-1 my-1"
                      >
                        <span class="subtitle-2 font-weight-bold card-data"
                          >Published</span
                        >
                        <span class="body-2 font-weight-normal">{{
                          formatDateLong(item.published_date)
                        }}</span>
                      </v-card-title>
                      <v-card-title
                        v-if="item.contract_start"
                        class="pl-0 ml-0 py-1 my-1"
                      >
                        <span class="subtitle-2 font-weight-bold card-data"
                          >Term Start</span
                        >
                        <span class="body-2 font-weight-normal">{{
                          formatDateLong(item.contract_start)
                        }}</span>
                      </v-card-title>
                      <v-card-title
                        v-if="item.contract_end"
                        class="pl-0 ml-0 py-1 my-1"
                      >
                        <span class="subtitle-2 font-weight-bold card-data"
                          >Expiry</span
                        >
                        <span class="body-2 font-weight-normal">{{
                          formatDateLong(item.contract_end)
                        }}</span>
                      </v-card-title>
                      <v-card-title
                        v-if="item.closing_date"
                        class="pl-0 ml-0 pt-1 pb-0 mt-1 mb-0"
                      >
                        <span class="subtitle-2 font-weight-bold card-data"
                          >Close Date</span
                        >
                        <span class="body-2 font-weight-normal">{{
                          formatDateLong(item.closing_date)
                        }}</span>
                      </v-card-title>
                      <v-card-title class="pl-0 ml-0 py-1 my-1">
                        <span class="subtitle-2 font-weight-bold card-data"
                          >Identifier</span
                        >
                        <span class="body-2 font-weight-normal">{{
                          item.recid
                        }}</span>
                      </v-card-title>
                      <v-card-title class="pl-0 ml-0 py-1 my-1">
                        <span class="subtitle-2 font-weight-bold card-data"
                          >Category</span
                        >
                        <span class="body-2 font-weight-normal"
                          >{{ item.category }} </span> <span class="body-2 font-weight-normal" v-if="item.category_code"> ({{ item.category_code }})</span
                        >
                      </v-card-title>
                      <div
                        v-if="item.description"
                        class="subtitle-2 font-weight-bold card-data pb-2 pt-2"
                      >
                        Description
                      </div>
                      <div
                        v-if="item.description"
                        class="body-2 font-weight-normal pb-2"
                      >
                        {{ item.description }}
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <v-divider class="entity-info mr-3 ml-3 mt-0 mb-1"></v-divider>

                <v-card-actions class="pl-4 pr-3">
                  <v-card-title
                    v-if="item.contract_end"
                    class="pl-0 ml-0 py-0 my-0"
                  >
                    <v-icon color="grey" class="ml-0 mr-1">
                      mdi-timetable
                    </v-icon>
                    <span
                      class="subtitle-2 font-weight-normal"
                      style="color:grey"
                      >{{ formatDate(item.contract_end) }}</span
                    >
                  </v-card-title>
                  <v-card-title
                    v-else-if="item.closing_date"
                    class="pl-0 ml-0 py-0 my-0"
                  >
                    <v-icon color="grey" class="ml-0 mr-1">
                      mdi-timetable
                    </v-icon>
                    <span
                      class="subtitle-2 font-weight-normal"
                      style="color:grey"
                      >{{ formatDate(item.closing_date) }}</span
                    >
                  </v-card-title>

                  <div class="flex-grow-1"></div>
                  <v-btn
                    large
                    icon
                    color="#4EB888"
                    light
                    :href="item.url"
                    target="_blank"
                    :disabled="disableIcon(item.url)"
                  >
                    <v-icon>mdi-web</v-icon>
                  </v-btn>

                  <v-btn
                    large
                    color="#4EB888"
                    light
                    icon
                    @click="showListManager=true"
                  >
                    <v-icon>mdi-bookmark</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="showListManager" max-width="500px">
      <list-manager
        :items="user_lists"
        :selected="list_selections"
        :targetItem="selectedBookmark"
        :change="bookmark_changed"
        @hide-list-manager="showListManager=false"
        @bookmarked="updateBookmarks">

      </list-manager>
    </v-dialog> 
    <v-divider></v-divider>

    <div
      v-if="hit_info.total_pages >= 1 && !hide_pagination"
      class="text-center pt-3"
    >
    <v-row justify="center">
      <v-col cols="2">
      <v-pagination
        v-model="current_page"
        :length="num_pages"
        total-visible="0"
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
        @input="getPage(current_page)"
      ></v-pagination>
      <p class="caption font-italic" v-if="num_pages == 100">
        Only the first 5000 records will be retrieved. Consider narrowing
        your search.
      </p>
    </v-col>
    <v-col cols="1">
      <v-select
      class="pt-2"
          :items="per_page_options"
          label="Records per page"
          v-model="records_per_page"
          @change="onNumRecordsChange"
          dense
        ></v-select>
      </v-col>
      </v-row>
    </div>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { bus } from "../main.js";
import moment from "moment";
import { getUserLists, addToList } from "./APIManager.js";
import { download, getMatchedFields, getKey } from "./Utils.js";
import TenderProfile from "./TenderProfile.vue";
import ListManager from "./ListManager.vue";

export default {
  components: {
    TenderProfile,
    ListManager
  },

  props: {
    //results_drawer: Boolean
    hide_pagination: Boolean,
    tenders: [],
    hit_info: {},
    json_query: {},
    page_num: Number,
    type_filter: Boolean
  },
  data() {
    return {
      per_page_options: [50, 100, 200, 500],
      tempBookmarks:[],
      bookmarkStates: {},
      selectedItem: false,
      showPreview: false,
      selectedBookmark: {},
      showListManager: false,
      types: [
        { type: "All Types" },
        { type: "Awards" },
        { type: "Requests / ATMs" },
        { type: "Grant Awards" },
        { type: "Grant Opportunities" }
      ],
      selected_type: { type: "All Types" },
      //rows: [],
      //states: {"NSW": "#ff4081", "QLD": "#ff4081", "WA":"#ff4081", "AUS": "#ff4081", "VIC": "#ff4081", "TAS":"#ff4081", "NT":"#ff4081", "ACT":"#ff4081"},
      selected_sort: { label: "Overview", value: "snapshot" },
      sort_options: [
        { label: "Amount", value: "value" },
        { label: "Expiry", value: "contract_end" },
        { label: "Supplier", value: "supplier" }
      ],
      item_details: { show: false, record: {} },
      saved: [{ tender_id: "", value: false }],
      supplier_query: {},
      publisher_query: {},
      current_sort_col: "value",
      current_sort_order: "desc",
      //num_pages: 6,
      current_page: this.page_num,
      records_per_page: null,
      selectList: false,
      user_lists: [],
      added_tender: "",
      selectedTender: null,
      list_search: null,
      message: null,
      overlay: false,
      sort_btn_colour: "#FFFFFF",
      tender_dialog: false,
      selected_avatar: "",
      rows: [],
      //item: null,
      sort_color: {value: { asc: null, desc: null},
                   expiry_date: { asc: null, desc: null},
                   supplier: { asc: null, desc: null},
                   publisher: { asc: null, desc: null},
                   government: { asc: null, desc: null}
      },
      bookmark_changed: 0,
      list_selections: []
      
    };
  },
  
  mounted: function() {


    var qry = JSON.parse(JSON.stringify(this.$route.query));
    if (qry.size){
      this.records_per_page = qry.size;
    }
    else{
      this.records_per_page = 50;
    }
    
    this.rows = this.tenders;

    this.getLists();

    this.setTypeFilter();

    if (this.json_query["sort_field"]){
      this.sort_color[this.json_query["sort_field"]][this.json_query["sort_order"]] = 'primary';
      //this.sort_color[sort_field][sort_order] = 'primary';
    }
    else{
      this.sort_color["expiry_date"]["desc"] = 'primary';
    }

    bus.$on("update-propsect-lists", data => {
      ////console.log("update-propsect-lists");
      this.getLists();
    });

  },

  methods: {

    getMatchedFields,
    getKey,


    onNumRecordsChange(value) {
      var qry = JSON.parse(JSON.stringify(this.$route.query));

      qry["size"] = this.records_per_page;

      this.$router.push({ name: "search", query: qry });
      },

    toggleBookmark(id) {

      // Find the index of the id in the tempBookmarks array
      const index = this.tempBookmarks.indexOf(id);

      // If the id is in the array, remove it
      if (index !== -1) {
        this.tempBookmarks.splice(index, 1)
        // Reflect this action in bookmarkStates
        this.$set(this.bookmarkStates, id, false);
      }
      else {
          this.tempBookmarks.push(id);
          this.$set(this.bookmarkStates, id, !this.bookmarkStates[id]);
        }
      //this.$set(this.bookmarkStates, id, !this.bookmarkStates[id]);
    
    },

    hoverItem (item) {
      this.selectedItem = item;
    },
    unHoverItem () {
      this.selectedItem = false;
    },

    addRemoveSingleBookmark(targetItem){
      //////console.log("targetItem: ", targetItem);

      this.list_selections = [];

      for (var i in this.user_lists){
          let list = this.user_lists[i].search_query.tender_id;
          
          if (list.includes(parseInt(targetItem.id))){
              this.list_selections.push(this.user_lists[i]);
          }
      }

      this.selectedBookmark = targetItem;
      this.showListManager=true;

    },

    addMultipleBookmarks(){
      //////console.log("this.tempBookmarks: ", this.tempBookmarks);

      this.list_selections = [];

      for (var i in this.user_lists){
          let list = this.user_lists[i].search_query.tender_id;
          
          for (var i in this.tempBookmarks){
            if (list.includes(parseInt(this.tempBookmarks[i]))){
                this.list_selections.push(this.user_lists[i]);
            }
          }

      }
      
      this.selectedBookmark = {id: this.tempBookmarks};
      //////console.log("this.selectedBookmark: ", this.selectedBookmark);
      this.showListManager=true;
      //this.bookmark_changed = this.bookmark_changed + Math.round(Math.random() * 1000);
    },

    setBookmarks(data){

      this.user_lists = data;

      for (var i in this.user_lists){
        this.user_lists[i].search_query = JSON.parse(this.user_lists[i].search_query);
      }

      var matched = [];

      for (var l in this.user_lists){

        //////////////console.log("list tenders: ", this.user_lists[l].search_query.tender_id)

        for (var i in this.rows){
          //////////////console.log("checking for.. ", parseInt(this.rows[i].id));
          if (this.user_lists[l].search_query.tender_id.includes(parseInt(this.rows[i].id))){
            //////////////console.log("MATCH: ", this.rows[i].id);
            matched.push(this.rows[i].id);
            this.rows[i]["bookmarked"] = true;
          }
          else if (!matched.includes(this.rows[i].id)){
            this.rows[i]["bookmarked"] = false;
          }
        }

      }
      
    },

    updateBookmarks(bookmark_data){
      //////console.log("BOOKMARK DATA: ", bookmark_data);

      var lists = [];
      var bms = bookmark_data.selectedLists;

      for (var i in bms){
        lists = lists.concat(JSON.parse(bms[i].id));
      }

      addToList(bookmark_data.bookmarkedItemID, lists)
          .then(data => {

            //reload the page is the user is looking at their list
            var list_id = this.$route.query["list_id"];
            var list_name = this.$route.query["list_name"]
            var list = {};

            if (list_id){
              for (var i in data){
                if (list_id == data[i].id){
                  list = data[i];
                }
              }
              
              bus.$emit("refresh_lists", true);
              var qry = JSON.parse(list.search_query)
              qry["list_name"] = list_name
              qry["list_is"] = list_id
              this.$router.push({ path: "/search", query: qry });

            }
            else{
              this.setBookmarks(data);
            
              bus.$emit("refresh_lists", true);
              this.tempBookmarks = [];
              //this.bookmarkStates = [];
              
            }

            //////////////console.log("ADDED :", this.added);
          })
          .catch(error => {
            var dialog = {};
            dialog.msg =
              "We encountered an issue saving the selected notice. Please try again.";
            dialog.title = "Attempted save failed.";
            dialog.state = true;
          });
    },

    setTypeFilter(){

      var qry = JSON.parse(JSON.stringify(this.$route.query));

      if (qry["disposition"]  == "Contract Notice") {
        this.selected_type  = { type: "Awards" };
      } else if (qry["disposition"]  == "Request") {
        this.selected_type  = { type: "Requests / ATMs" };
      }else if (qry["disposition"]  == "Grant") {
        this.selected_type  = { type: "Grant Awards" };
      } else if (qry["disposition"]  == "Grant Opportunity") {
        this.selected_type  = { type: "Grant Opportunities" };
      }else {
        this.selected_type  = { type: "All Awards" };;
      }

    },

    excludeSupplier(supplier) {

      var qry = JSON.parse(JSON.stringify(this.$route.query));

      if (qry["exclude_supplier"]) {
        qry["exclude_supplier"].push(supplier);
      } else{
        qry["exclude_supplier"] = [supplier];
      }

      this.$router.push({ name: "search", query: qry });
    },

    excludePublisher(publisher) {

      var qry = JSON.parse(JSON.stringify(this.$route.query));

      if (qry["exclude_publisher"]) {
        qry["exclude_publisher"].push(publisher);
      } else{
        qry["exclude_publisher"] = [publisher];
      }

      this.$router.push({ name: "search", query: qry });
    },

    updateQuery() {

      var qry = JSON.parse(JSON.stringify(this.$route.query));

      if (this.selected_type.type == "Awards") {
        qry["disposition"]  = "Contract Notice" ;
      } else if (this.selected_type.type == "Requests / ATMs") {
        qry["disposition"] = "Request";
      } else if (this.selected_type.type == "Grant Awards") {
        qry["disposition"] = "Grant";
      }else if (this.selected_type.type == "Grant Opportunities") {
        qry["disposition"] = "Grant Opportunity";
      }else {
        qry["disposition"] = ["Contract Notice", "Request", "Grant", "Grant Opportunity"];
      }

      this.$router.push({ name: "search", query: qry });
    },

    updateSort(sort_field, sort_order) {

      let sortQuery = {};

      sortQuery["sort_field"] = {field: "sort_field", value: sort_field};
      sortQuery["sort_order"] = {field: "sort_order", value: sort_order};

      this.$emit("updateSort", sortQuery);

    },

    downloadResults() {
      download(this.rows, this.current_page);
    },

    borderHighlight(gov) {
      var color = "grey";
      switch (gov) {
        case "NSW":
          color = "#41a7d9";
          break;
        case "VIC":
          color = "#0058AC";
          break;
        case "WA":
          color = "#FFD200";
          break;
        case "QLD":
          color = "#A6003D";
          break;
        case "AUS":
          color = "#D8993B";
          break;
        case "FED":
          color = "#D8993B";
          break;
        case "DEF":
          color = "#D8993B";
          break;
        case "SA":
          color = "#ff4081";
          break;
        case "TAS":
          color = "#4eb888";
          break;
        case "ACT":
          color = "#41a7d9";
          break;
        case "NT":
          color = "#FF3647";
          break;
      }

      var style = {
        "border-left-width": 5 + "px",
        "border-left-style": "solid",
        "border-left-color": color + " !important"
      };

      return style;
    },

    dispositionColor(dis) {
      var color = "grey";
      switch (dis) {
        case "Request":
          color = "#4EB888";
          break;
        case "Grant Opportunity":
          color = "#4EB888";
          break;
        case "Contract Notice":
          color = "#FF1463";
          break;
        case "Grant":
          color = "#FF1463";
          break;
      }

      return color;
    },

    dispositionText(item) {
      let disposition = item.disposition;
      if (disposition == "Contract Notice"){
        disposition = "Contract";
      }

      if (disposition == "Grant"){
        disposition = "Grant";
      }

      if (item.son_ref){
        disposition = "Panel Contract";
      }
      return disposition;
    },

    govAvatarColor(gov) {
      var color = "grey";
      switch (gov) {
        case "NSW":
          color = "#41a7d9";
          break;
        case "VIC":
          color = "#0058AC";
          break;
        case "WA":
          color = "#FFD200";
          break;
        case "QLD":
          color = "#A6003D";
          break;
        case "AUS":
          color = "#D8993B";
          break;
        case "FED":
          color = "#D8993B";
          break;
        case "DEF":
          color = "#d8673b";
          break;
        case "SA":
          color = "#ff4081";
          break;
        case "TAS":
          color = "#4eb888";
          break;
        case "ACT":
          color = "#41a7d9";
          break;
        case "NT":
          color = "#FF3647";
          break;
      }

      return color;
    },

    getPage(number) {
      this.$emit("change-page", this.current_page);
      //////////////console.log("CLICKED PAGE: " + this.current_page);
    },
    formattedCurrency: function(amount) {
      //////////////console.log("AMOUNT: " + amount);
      if (!isNaN(amount)) {
        return (
          "$" +
          Math.round(amount)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        );
      } 
    },

    getStatusColour(item) {
      if (item.status === "published") {
        return { color: "#0b875b", text: "white" };
      } else {
        return { color: "#E0E0E0", text: "#1b1b1b" };
      }
    },

    showHideDetails(item, avatar) {
      //this.item_details = !this.item_details;
      this.tender_dialog = true;
      this.item = item;
      this.selected_avatar = avatar;
      //bus.$emit("show-hide-details", this.item, this.this.selected_avatar);
    },

    formatDate(date_value) {
      if (!date_value) {
        return null;
      } else {
        return moment(date_value).format("MMMM YYYY");
      }
    },

    formatDateLong(date_value) {
      if (!date_value) {
        return null;
      } else {
        return moment(date_value).format("DD MMMM YYYY");
      }
    },

    
    disableIcon(url) {
      if (!url) {
        return true;
      } else {
        return false;
      }
    },

    getLists() {
      getUserLists()
        .then(data => {
          this.setBookmarks(data);
        })
        .catch(error => {
          error;
        });
    },

  },
  computed: {

    disableBookmarks(){
      return this.tempBookmarks.length==0 ? true : false;
    },

    tempBookmarkColour() {
      return (id) => {
        return this.bookmarkStates[id] ? 'primary' : '';
      };
    },

    num_pages: function() {
      let max_hits = this.hit_info.total_pages;

      if (max_hits > 100) {
        max_hits = 100;
      } else {
        max_hits = this.hit_info.total_pages;
      }
      return max_hits;
    }
  },

  selectedItems: {
    get() {
      return this.value;
    },
    set(item) {
      // Could either emit (so you can use v-model on the parent)
      // or add to array
      this.chosenItems.push(item);
      this.$emit("input", item);
    }
  }
};
</script>

<style scoped>
::v-deep .v-expansion-panel-content__wrap {
  padding-left: 0px;
}

.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}

.tender {
  border-left: 5px solid #ff4081 !important;
}
.card-data {
  width: 150px !important;
}

table {
  border-collapse: collapse;
}

th {
  border-bottom: 1px solid #535353;
}

tr .excludeSupplier {
  visibility: hidden;
}

tr:hover .excludeSupplier {
  visibility: visible;
}

tr .excludePublisher {
  visibility: hidden;
}

tr:hover .excludePublisher {
  visibility: visible;
}

/* .tr:hover {
  transition: opacity 0.4s ease-in-out;
} */

tbody tr:hover {
  background: #535353;

  -webkit-transition: background-color 0.4s; /* For Safari 3.1 to 6.0 */
  transition: background-color 0.4s;
}

.v-select--dense .v-input__control {
  height: 20px !important;
}

.state-line {
  width: 100%;
  text-align: right;
  border-bottom: 1px solid #4eb888;
  line-height: 0.1em;
  margin: 10px 0px 20px;
}
.state-line-span {
  padding: 0 10px;
}

.state {
  margin: 20px;
  overflow: hidden;
  color: gray;
  text-align: right;
  line-height: 1.6em;
}

.state:before {
  display: block;
  float: left;
  margin-top: 0.8em; /* half the line-height */
  border-top: 2px solid silver;
  width: 95%;
  content: "";
}
</style>
