<template>
  <v-card class="chart_container pa-2" tile flat min-height="500px">
    <chart-header 
      :title="title"
      :subtitle="subtitle"
      :widget_name="'spending-tree'"
      :total_result="total_records"
      :total_amount="total_amount"
      :search_query="plain_text_query"
      :chart_settings="chart_settings"  
      :data="dataview"
      :embed_object="embed_object"
      :embed_obj_changed="embed_obj_changed"
      @embed="embed_code" 
      @downloadImage="downloadImage"/>
    <chart-loader :reportLoading="reportLoading" />
    
   <!--  <v-row justify="center" align="center">
       
    </v-row> -->
    <v-row>
        <div class="d-inline pl-4 mr-5">
          <p class="mb-0 pb-0 subtitle-2" style="font-weight:bold;">Metric</p>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              class="text-capitalize body-1"
                color="#E0E0E0"
                outlined
                v-bind="attrs"
                v-on="on"
                width="130"
                style="justify-content:left !important;"
              >
                <span style="color:#12253a;">{{tree_metric.text}}</span>
                <v-icon color="#12253a" right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, value) in tree_metric_options"
                :key="value"
                @click="tree_metric = item, chartConfig(), setChanged(), updateSettings()"
              >
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="d-inline mr-5">
          <p class="mb-0 pb-0 subtitle-2" style="font-weight:bold;">Entity Type</p>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              class="text-capitalize body-1"
                color="#E0E0E0"
                outlined
                v-bind="attrs"
                v-on="on"
                width="170"
                style="justify-content:left !important;"
              >
                <span style="color:#12253a;">{{tree_group.text}}</span>
                <v-icon color="#12253a" right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, value) in tree_group_options"
                :key="value"
                @click="tree_group = item, chartConfig(), setChanged(), updateSettings()"
              >
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="d-inline">
          <p class="mb-0 pb-0 subtitle-2" style="font-weight:bold;"># Entities / Categories</p>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              class="text-capitalize body-1"
                color="#E0E0E0"
                outlined
                v-bind="attrs"
                v-on="on"
                width="130"
                style="justify-content:left !important;"
              >
                <span style="color:#12253a;">{{num_groups}}</span>
                <v-icon color="#12253a" right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="item in top_entities"
                :key="item"
                @click="num_groups = item, updateSettings(), refresh()"
              >
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
    </v-row>
      
    <v-row id="image">
      <v-col cols="10">
        <tree-echarts v-if="chart_data.length > 0"
          :legend_settings="legend_settings"
          :chart_data="chart_data"
          :changed="changed"
          :download="download"
          :target_group="tree_group.value"
          :embed="embed"
          @chart_object="chart_object"
          @dataview="loadDataView"
          @showTenders="showTenders"
          :id="id"
        />
       
      </v-col>

      <v-col cols="2" class="pt-4">
        <chart-legend 
          :colors="true" 
          :legendData="gov_aggs"
          :groups="groups"
          :total_results="total_records"
          :total_amount="total_amount"
          :metric="tree_metric.value" />
      </v-col>
      <!-- <v-col cols="12" class="pl-4 pt-0">
        <chart-footer />
      </v-col> -->
    </v-row>
  </v-card>

</template>

<script>
//import html2canvas from 'html2canvas';
import { buildTree } from "./PrepTree.js";
import { entitySearch } from "./APIManager.js";
import { silentURLUpdate, formattedCurrencyFull, formattedCurrency, govColor, getPercent } from "./Utils.js";
import TreeEcharts from "../components/TreeEcharts";
import ChartHeader from "../components/ChartHeader";
import ChartLoader from "../components/ChartLoader";
import ChartLegend from "./ChartLegend.vue";
//import {downloadFile} from "../components/Utils";
//import ChartFooter from "./ChartFooter.vue";
//import Donut from "@/components/Donut.vue";

export default {
  components: {
    TreeEcharts,
    ChartHeader,
    ChartLoader,
    ChartLegend
    //ChartFooter
    //Donut
  },
  props: {
    title: {
      type: String,
      default: "Government Spending Breakdown"
    },
    description: String
  },
  data() {
    return {
      //entity_count: 10,
      chart_settings: [],
      groups: [],
      top_entities: [5, 10, 20, 30],
      infobox:false,
      //title: "Government Spending Breakdown",
      subtitle: "Share of total spend based on government jurisdiction",
      defaultMetric: 0,
      defaultGroup: 0,
      item_details: false,
      aggregated_data: [],
      //publisher_line_chart_data: [],
      chart_data: [],
      group_by_amount: true,
      target_entity: String,
      changed: Number,
      entity_type: "publisher",//String(),
      metric_name: "total_amount",//String(),
      suppliers_by_gov: [],
      publishers_by_gov: [],
      categories_by_gov: [],
      major_categories_by_gov: [],
      keywords_by_gov: [],
      gov_aggs:[],
      tree_data: [],
      tree_metric_options: [{text: "Amount", value:"total_amount"}, {text: "Volume", value:"doc_count"}],
      tree_metric: {text: "Amount", value:"total_amount"},
      tree_group_options: [{text: "Buyer", value:"publisher"}, {text: "Major Category", value:"major_category"}, {text: "Category", value:"category"}, {text: "Supplier", value:"supplier"}],
      tree_group: {text: "Buyer", value:"publisher"},
      num_groups: 10,
      total_records: Number,
      total_amount: Number,
      reportLoading: Boolean,
      plain_text_query:String,
      download: Number,
      legendData: [],
      dataview: {headers:[], data:[]},
      embed: Number,
      embed_object: Object,
      embed_obj_changed: Number
    };
  },

  computed: {

    id(){
      return (Math.random() + Date.now()) * Math.random();
    },

    legend_settings() {
      let mylegend = {};

      mylegend["colors"] = true;
      mylegend["legendData"] = this.gov_aggs;
      mylegend["groups"] = this.groups;
      mylegend["total_results"] = this.total_records;
      mylegend["total_amount"] = this.total_amount;
      mylegend["metric"] = this.tree_metric.value;

      return mylegend;
    }

  },

  watch: {

    $route(to, from) {
      this.refresh();
    },

  },

  created(){

  },

  async mounted() {
    this.reportLoading = true;
    this.loadSettings();
    this.loadData(this.$route.query);
  },

  methods: {
    //need to list imported methods here to use it in template
    formattedCurrency,

    chart_object(obj){
      this.embed_object["title"] = this.title;
      this.embed_object["description"] = this.description;
      this.embed_object["chart_type"] = "treemap";
      this.embed_object["payload"] = obj
      this.embed_object["id"] = (Math.random() + Date.now()) * Math.random();
      this.embed_obj_changed = (Math.random() + Date.now()) * Math.random();
    },

    embed_code(status){
      this.embed =
           (Math.random() + Date.now()) * Math.random();

    },

    updateSettings(){
      this.chart_settings[0] = {field_name: "tree_group_value", value: this.tree_group.value};
      this.chart_settings[1] = {field_name: "tree_metric_value", value: this.tree_metric.value};
      this.chart_settings[2] = {field_name: "tree_num_groups", value: this.num_groups};

      silentURLUpdate(this.chart_settings);
    },

    loadSettings(){
      var query = this.$route.query;

      if (query.hasOwnProperty("tree_group_value") && query.tree_group_value == "search_terms"){
        delete this.tree_group.text;
        delete this.tree_group.value;
        //this.tree_group_options.push({text: "Keyword", value:"search_terms"})
        this.tree_group = {text: "Keyword", value:"search_terms"};
      }
      else if (query.hasOwnProperty("tree_group_value")){
        let index = this.tree_group_options.findIndex(x => x.value === query.tree_group_value);
        delete this.tree_group.text;
        delete this.tree_group.value;
        this.tree_group = this.tree_group_options[index];
      }
      if (query.hasOwnProperty("tree_metric_value")){
        let index = this.tree_metric_options.findIndex(x => x.value === query.tree_metric_value);
        delete this.tree_metric.text;
        delete this.tree_metric.value;
        this.tree_metric = this.tree_metric_options[index];
      }
      if (query.hasOwnProperty("tree_num_groups")){
        this.num_groups = query.tree_num_groups;
      }
    },

    showTenders(args){
      this.$emit("showTenders", args);
    },

    downloadImage(status){
      this.download =
          (Math.random() + this.chart_data.length) * Math.random();

    },

    loadDataView(data){
      this.dataview = data;
    },

    getTitle (){
      if (!this.total_records || this.reportLoading == true){
        return null;
      }
      else{
        return this.total_records + " contracts valued at " + this.formatCurrency(this.total_amount);
      }
    },

    formatCurrency (value){
      return formattedCurrencyFull(value);
    },

    selected_metric(value){
      if (this.tree_metric.value == value){
        return "font-weight-bold"
      }
      else{
        return "font-weight-normal"
      }
    },

    async refresh(){
      this.reportLoading = true;
      await this.loadData(this.$route.query);

      var query = this.$route.query;

      if (query.hasOwnProperty("tree_group_value") && query.tree_group_value == "search_terms"){
        let index = this.tree_group_options.findIndex(x => x.value === "search_terms");
        //delete this.tree_group.text;
        //delete this.tree_group.value;
        if (index > -1){
          this.tree_group_options.splice(index, 1);

        }
      }
      
    },

    setGroups(){
      for (var l in this.gov_aggs){
        this.groups[l] = this.gov_aggs[l].key;
      }

    },

    setColors (){
      for (var i = 0; i < this.gov_aggs.length; i++){
        this.gov_aggs[i]["color"] = govColor(this.gov_aggs[i].key);
      }
    },

    itemColor(item){
      return govColor(item);
    },

    setChanged(){
      this.changed =
          (Math.random() + this.chart_data.length) * Math.random();
    },

    async loadData(qry) {

      var query = JSON.parse(JSON.stringify(qry));
      query["agg_size"] = this.num_groups;

      entitySearch(query, 1)
        .then(data => {
          this.total_records = data.hits.value;
          this.publishers_by_gov = data.aggregations.publishers_by_govt.buckets;
          this.suppliers_by_gov = data.aggregations.suppliers_by_govt.buckets;
          this.categories_by_gov = data.aggregations.categories_by_govt.buckets;
          this.major_categories_by_gov = data.aggregations.major_categories_by_govt.buckets;

          if (data.aggregations.search_terms_by_govt){
            this.keywords_by_gov = data.aggregations.search_terms_by_govt.buckets;

            if (!this.tree_group_options.find(o => o.value === 'search_terms')){
              this.tree_group_options.push({text: "Keyword", value:"search_terms"})
            }
            //this.tree_group_options.push({text: "Keyword", value:"search_terms"})
          }

          this.gov_aggs = data.aggregations.government.buckets;
          this.total_amount = data.aggregations.total_amount.value;
          this.plain_text_query = data.plain_text_query;
          ////////////console.log(JSON.stringify(this.gov_aggs));

          this.$emit("aggs-loaded", this.gov_aggs);

          this.chartConfig()
          this.reportLoading = false;
          this.setChanged();
          this.setGroups();
          this.setColors()
          
        })
        .catch(error => {
          ////////////console.log(error);
          //this.errored = true;
          //this.overlay = false;
        });
    },

    chartConfig(){

      let tree_data = [];

      if (this.tree_group.value == "publisher"){
        tree_data = this.publishers_by_gov;
      }
      else if (this.tree_group.value == "supplier"){
        tree_data = this.suppliers_by_gov;
      }
      else if (this.tree_group.value == "category"){
        tree_data = this.categories_by_gov;
      }
      else if (this.tree_group.value == "major_category"){
        tree_data = this.major_categories_by_gov;
      }
      else if (this.tree_group.value == "search_terms"){
        tree_data = this.keywords_by_gov;
      }

      this.chart_data = buildTree(
        tree_data,
        this.tree_metric.value,
        this.tree_group.value
      );

    }
  }
};
</script>

<style>

.chart_container {
    max-width: 100% !important;
    /* overflow: auto !important; */
    border: none !important;
    height: 100% !important;
    background-color: #fff !important;
}



</style>
