var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mr-5",attrs:{"width":"100%","flat":""}},[_c('v-card-title',{attrs:{"color":"#12253a"}},[_c('v-icon',{attrs:{"color":"#12253a","medium":"","left":""}},[_vm._v("mdi-history")]),_c('span',{staticClass:"title font-weight-bold subtitle-1"},[_vm._v("Recent Searches")]),_c('span',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.loading = true, _vm.getRecentSearches()}}},[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-refresh")])],1)],1),_c('span',[_c('v-btn',{attrs:{"icon":"","href":"https://docs.tendertrace.com/guide/user-documentation/saving-your-work/search-history","target":"_blank"}},[_c('v-icon',{staticClass:"material-icons",attrs:{"color":"secondary"}},[_vm._v("mdi-help-circle-outline")])],1)],1)],1),_c('v-divider'),_c('v-card',{staticClass:"px-2 py-2 mx-2 my-2",attrs:{"flat":""}},[_c('v-text-field',{staticClass:"mt-3",attrs:{"dense":"","solo-inverted":"","hide-details":"","flat":"","label":"Search recent searches...","append-inner-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.loading)?_c('v-list',[_c('v-skeleton-loader',_vm._b({attrs:{"type":"list-item-two-line"}},'v-skeleton-loader',_vm.attrs,false)),_c('v-skeleton-loader',_vm._b({attrs:{"type":"list-item-two-line"}},'v-skeleton-loader',_vm.attrs,false))],1):_c('v-list',[(_vm.filteredRecentSearches.length > 0)?_c('v-list-item-group',{attrs:{"color":"rgb(18, 37, 58)"},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},_vm._l((_vm.filteredRecentSearches),function(item){return _c('v-list-item',{key:item.id},[_c('v-tooltip',{staticStyle:{"opacity":"0.7"},attrs:{"left":"","dark":"","color":"#000000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item-subtitle',_vm._g({staticClass:"wrap-text hvr",staticStyle:{"color":"#12253a !important"},on:{"click":function($event){return _vm.retrieveSearch(item.search_query)}}},on),[_vm._v(" "+_vm._s(item.plain_text_query)+" ")])]}}],null,true)},[_c('span',{staticClass:"tooltip"},[_vm._v(_vm._s(item.plain_text_query))])])],1)}),1):_c('v-list-item',{staticClass:"ma-0 pa-0"},[_c('v-card',{staticClass:"ma-0 pa-0",attrs:{"flat":""}},[_c('v-card-text',[_c('p',[_vm._v(" Tendertrace automatically saves all queries you make so that you can quickly rerun a previous search even if it's not one you are tracking. Your most recent 20 queries will be displayed here. ")]),_c('p',[_vm._v(" To access your search history from any page in Tendertrace, click on the "),_c('v-icon',[_vm._v("mdi-history")]),_vm._v(" button located on the left hand side of the application menu bar. ")],1),_c('p',[_c('router-link',{attrs:{"to":{ name: 'search',
                                  query: {
                                    disposition: ['Contract Notice','Grant'],
                                    expiry_from: new Date().getFullYear(),
                                    expiry_to: new Date().getFullYear(),
                                    sort_order: 'asc'
                                  }
                                }}},[_vm._v(" Click here to start exploring. ")])],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }