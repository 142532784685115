<template>
  <span>
    <v-dialog
          v-model="reportLoading"
          hide-overlay
          persistent
          width="300"
          lazy          
        >
          <v-card
            color="secondary"
            dark
          >
            <v-card-text>
              Building the network...
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
    </v-dialog>
    <v-dialog v-model="sharebox" hide-overlay max-width="1200" absolute style="overflow-x:'hidden' !important;">
        <v-card tile>
        <v-card-title>Add network to a custom report
            <div class="flex-grow-1"></div> <v-btn icon @click="sharebox=!sharebox"><v-icon medium>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card tile flat class="mx-auto pa-5" max-width="1080px">
            <v-row justify="center">
            <v-col
              cols="6"
              class="pb-0 d-flex child-flex"
            >
              <v-combobox
                v-model="selected_board"
                :items="storyboards"
                :loading="isLoading"
                item-value="id"
                :search-input.sync="search"
                item-text="plainTextTitle"
                label="Select a report"
                outlined
                dense
                class="pb-0"
                return-object
              >
              </v-combobox>
            </v-col>
              <v-col
              cols="6"
              class="pb-0"
            >

              <v-btn color="primary" :disabled="!saveButtonActive" @click="saveChart(), updateBoards(), sharebox=!sharebox">Save network to report</v-btn>
              </v-col>

              <v-col cols="12" class="pt-0 d-flex child-flex">
                <story :changed="embed_obj_changed" :key="embed_obj_changed" :data="embed_object" />
              </v-col>
            </v-row>
        </v-card>
        </v-card>
    </v-dialog>
    <v-card tile class="mx-auto">
      <v-card-title class="px-3 py-2 ma-0">
        <span>
          <network-settings :has_keywords="has_keywords" @change_graph="change_graph" @expand_nodes="expand_nodes" @showConcentration="showConcentration" />
        </span>
        <span class="body-2">
        <span class="pl-5">
          <v-icon color="rgb(255, 231, 10)" small>mdi-checkbox-blank-circle</v-icon><span class="pl-1">Buyer</span>
        </span>
        <span class="pl-3">
          <v-icon color="rgb(18, 37, 58)" small>mdi-checkbox-blank-circle</v-icon><span class="pl-1">Supplier</span>
        </span>
        <span v-if="current_graph == 'category'" class="pl-3">
          <v-icon color="rgb(255, 20, 99)" small>mdi-checkbox-blank-circle</v-icon><span class="pl-1">Category</span>
        </span>
        <span v-else-if="current_graph == 'keyword'" class="pl-3">
          <v-icon color="rgb(255,127,80)" small>mdi-checkbox-blank-circle</v-icon><span class="pl-1">Keyword</span>
        </span>
        <span v-if="selectedConcentration == 'spend'" class="pl-3">
          <v-icon color="#98E116" small>mdi-checkbox-blank-circle</v-icon><span class="pl-1">High proportion of spend</span>
        </span>
        <span v-else-if="selectedConcentration == 'category'" class="pl-3">
          <v-icon color="#F0B323" small>mdi-checkbox-blank-circle</v-icon><span class="pl-1">Usage across multiple service categories</span>
        </span>
        </span>
        <div class="flex-grow-1"></div>
        <span class="pl-2" style="float: right;">
          <v-tooltip bottom dark color="#000000" style="opacity: 0.7;">
              <template v-slot:activator="{ on }"> 
                  <v-btn
                  icon
                  tile
                  v-on="on"
                  color="secondary"
                  @click="set_chart_object(), updateBoards(), sharebox=!sharebox"
                  style="justify-content:center !important;"
                  ><v-icon>mdi-playlist-plus</v-icon>
              </v-btn>
              </template>
              <span>Add network to a custom report</span>
            </v-tooltip>
        </span>
        <span class="pl-2" style="float: right;">
          <v-tooltip bottom dark color="#000000" style="opacity: 0.7;">
              <template v-slot:activator="{ on }"> 
                  <v-btn
                  icon
                  tile
                  v-on="on"
                  color="secondary"
                  @click="openWidget()"
                  style="justify-content:center !important;"
                  ><v-icon>mdi-open-in-new</v-icon>
              </v-btn>
              </template>
              <span>Open in separate window</span>
            </v-tooltip>
        </span>
        <span class="pl-2" style="float: right;">
          <v-btn icon href="https://docs.tendertrace.com/guide/user-documentation/analysing-search-results/summary-dashboard" target="_blank">
              <v-icon class="material-icons" color="secondary">mdi-help-circle-outline</v-icon>
          </v-btn>
        </span>
      </v-card-title>

      <v-divider></v-divider>
      <v-row class="pt-3">
        <v-col cols="9" style="border-right:1px solid #cbdae1;">
          <div id="mynetwork"></div>
        </v-col>
        <v-col
          v-if="tenders.length > 0 || overlay"
          cols="3"
          class="pl-2 pr-5 pt-2"
        >
          <v-card v-if="overlay" tile height="980px">
            <v-skeleton-loader
              type="list-item-three-line, divider, list-item-three-line, list-item-three-line, list-item-three-line, list-item-three-line"
            ></v-skeleton-loader>
          </v-card>
          <v-card v-else tile height="980px">
            <v-card-text
              class="font-weight-normal subtitle-1 py-1"
              style="color:#12253a;"
            >
              <span class="font-weight-normal" style="color:rgb(255, 20, 99);">
                {{ num_hits }}
              </span>
              notice<span v-if="num_hits > 1">s</span> awarded
              <span v-if="currQuery.supplier_id > 0">
                to
                <router-link
                  target="_blank"
                  text
                  style="text-decoration: none; !important;"
                  class="font-weight-normal"
                  :to="{
                    name: 'supplier',
                    params: { id: currQuery.supplier_id }
                  }"
                >
                  {{ entityName }}</router-link
                >
              </span>
              <span v-else-if="currQuery.publisher_id">
                by
                <router-link
                  target="_blank"
                  text
                  style="text-decoration: none; !important;"
                  class="font-weight-normal"
                  :to="{
                    name: 'entity',
                    params: { id: currQuery.publisher_id }
                  }"
                >
                  {{ entityName }}</router-link
                >
              </span>
              <span v-else-if="currQuery.major_category_id">
                under
                <router-link
                  target="_blank"
                  text
                  style="text-decoration: none; !important;"
                  class="font-weight-normal"
                  :to="{
                    name: 'category',
                    params: { id: currQuery.major_category_id }
                  }"
                >
                  {{ entityName }}</router-link
                >
              </span>
              <span v-else-if="currQuery.tender">
                under 
                <router-link
                  target="_blank"
                  text
                  style="text-decoration: none; !important;"
                  class="font-weight-normal"
                  :to="{
                    name: 'search',
                    query: { tender: currQuery.tender }
                  }"
                >
                  {{ entityName }}</router-link
                >
              </span>
              <span v-if="total_value > 0">
                with a total awarded value of
                <span class="font-weight-normal" style="color:rgb(255, 20, 99);">
                  {{ formattedCurrency(total_value) }}</span
                >
              </span>
            </v-card-text>

            <v-divider></v-divider>
            <v-row>
              <v-col cols="12" class="pl-3 pt-1">
                <v-list
                  three-line
                  class="py-0 overflow-y-auto"
                  max-height="800px"
                >
                  <v-list-item-group
                    v-model="selectedTender"
                    color="#FFFFFF"
                    class="py-0"
                  >
                    <v-list-item
                      v-for="item in tenders"
                      :key="item.id"
                      class="py-0"
                    >
                      <v-list-item-content>
                        <v-tooltip
                          left
                          dark
                          color="#000000"
                          style="opacity: 0.7;"
                        >
                          <template v-slot:activator="{ on }">
                            <v-list-item-title
                              v-on="on"
                              class="wrap-text font-weight-strong"
                              style="color: #12253a !important;"
                              @click="getTender(item.id)"
                              >{{ item.title }}</v-list-item-title
                            >
                            <v-list-item-subtitle v-if="item.expiry_date"
                              ><v-icon small class="pr-1 pt-0">mdi-clock</v-icon
                              >{{
                                formatDate(item.expiry_date)
                              }}</v-list-item-subtitle
                            >
                            <v-list-item-subtitle v-if="item.value"
                              ><b>{{
                                formattedCurrency(item.value)
                              }}</b></v-list-item-subtitle
                            >
                          </template>
                           <span class="tooltip"
                          ><b>Buyer: </b> {{ item.publisher }}</span
                        >
                        <br />
                        <span class="tooltip"
                          ><b>Supplier: </b> {{ item.supplier }}</span
                        >
                        <br />

                        <span class="tooltip">{{ item.description }}</span>
                        </v-tooltip>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-col>
            </v-row>

            <div
              v-if="num_pages > 1"
              class="text-center"
              style="max-width:600px"
            >
              <v-pagination
                v-model="current_page"
                :length="num_pages"
                color="#12253a"
                total-visible="5"
                prev-icon="mdi-menu-left"
                next-icon="mdi-menu-right"
                @input="getPage(current_page)"
              ></v-pagination>
            </div>
          </v-card>
        </v-col>
        <v-col v-else cols="3" class="pl-2 pr-5 pt-2">
          <v-card tile height="100%">
            <v-card-text
              class="font-weight-normal subtitle-1 py-1"
              style="color:#12253a;"
              >Notices</v-card-text
            >
            <v-divider></v-divider>
            <v-row>
              <v-col cols="12" class="pl-4 pt-1">
                <p class="text-center py-0 px-0">
                  <v-icon v-for="i in 3" :key="i" class="pl-0 ml-0" x-large
                    >mdi-menu-left</v-icon
                  >
                </p>
              </v-col>
              <v-col cols="12" class="pl-4 py-0">
                <v-card-subtitle class="subtitle-1 py-0 my-0"
                  >Select a node or an edge to see the contracts that make up
                  that relationship</v-card-subtitle
                >
                <p class="text-center py-3 px-0">
                  <v-icon v-for="i in 3" :key="i" class="pl-0 ml-0" x-large
                    >mdi-menu-left</v-icon
                  >
                </p>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </span>
</template>

<script>
import $ from "jquery";
import moment from "moment";
import { Network } from "vis-network/peer";
import { DataSet } from "vis-data/peer";
import { bus } from "../main.js";
import { executeSearch, graphSearch } from "./APIManager";
import NetworkSettings from "../components/NetworkSettings.vue";
import { fetchStoryboards, addStory, addStoryboard} from "../components/APIManager"
import Story from "../components/Story.vue";

export default {
  props: {
    story: Boolean,
    embed: Number,
    chart_options: {}
    //graph: String,
    //agg_size: Number,
    //query: {}
  },

  components: {
    NetworkSettings,
    Story
  },

  data() {
    return {
      isLoading: false,
      sharebox: false,
      has_keywords: false,
      default_group: 1,
      current_page: 1,
      selectedTender: {},
      drawer: false,
      //topSelection: 10,
      fullscreen: false,
      toggle: null,
      nodes: null,
      edges: null,
      network: null,
      option: {},
      overlay: false,
      groups: [10, 30, 20],

      selectedNode: String,
      currQuery: {},
      tenders: [],
      num_hits: Number,
      total_value: Number,
      hit_info: {},
      num_groups: 10,
      errored: false,
      reportLoading: Boolean,
      //agg_size: 10,
      graph: String,
      category_graph: String,
      keyword_graph: String,
      entity_graph: String,
      query: {},
      widget_name: 'network',
      current_graph: "category",
      imgURL: String,
      graph_data: Object,
      embed_object: {},
      storyboards: [],
      selected_board: null,
      resetChart:Boolean,
      new_board:null,
      search: null,
      saveButtonActive: false,
      embed_obj_changed: Number,
      selectedConcentration: null,
      supplierColors: {},
      thresholdSpend: 0.1, //out of 10
      thresholdCategories: 2
      
    };
  },
  watch: {
    // whenever wave_sets prop changes, this function will run
    fullscreen: {
      handler: async function() {
        if (this.fullscreen) {
          //this.overlay = !this.overlay;
          this.network = this.draw();
        }
      }
    },

    $route(to, from) {
      ////////////console.log("IN TENDER RESULTS: ");
      this.refresh();

    },

    search (val, prev) {
        if (this.selected_board || this.search != null){
          this.saveButtonActive = true;
        }
        else{
          this.saveButtonActive = false;
        }
      }
  },
  created() {
    bus.$on("nodeSelected", node => {
      this.current_page = 1;
      this.updateQuery(node, this.$route.query);
      this.loadTenders().then(results => {
        this.tenders = results.data;
        this.num_hits = results.hits.value;
        this.total_value = results.aggregations.total_amount.value;
        this.num_pages = results.hits.total_pages;
      });
    });
  },

  async mounted() {
    this.reportLoading = true;
    if (this.story){
      this.graph_data = this.chart_options;
      this.category_graph = this.graph_data.graph.category_graph;
      this.keyword_graph = this.graph_data.graph.keyword_graph;
      this.entity_graph = this.graph_data.graph.entity_graph;
      this.drawGraph();
    }
    else{
      this.getGraphData(this.$route.query);
    }
    
  },

  computed: {
    entityName() {
      ////////////console.log("entityName CURR QUERY: ", this.currQuery);
      let name = "";
      if (this.currQuery["supplier_id"] > 0) {
        name = this.tenders[0].supplier;
      } else if (this.currQuery["publisher_id"] > 0) {
        name = this.tenders[0].publisher;
      }
      else if (this.currQuery["major_category_id"] > 0) {
        name = this.tenders[0].parent_category;
      }
      else if (this.currQuery["tender"]) {
        name = this.currQuery["tender"]
      }
      return name;
    }

    /* num_pages: function() {
      return this.hits.total_pages;
    } */
  },

  methods: {

    async updateBoards(){
        this.isLoading = true;
        this.search = null;
        this.saveButtonActive = false;

        var data = await fetchStoryboards();
        this.storyboards = data.storyboards;

        for (var i in this.storyboards){
          this.storyboards[i]["plainTextTitle"] = this.stripHTML(this.storyboards[i]["title"]);
        }
        this.isLoading = false;

    },

    stripHTML(text){
        let strippedText = text.replace(/(<([^>]+)>)/gi, "");
        return strippedText;
    },

    async saveChart(){

      let data = {};
        data["title"] = this.embed_object.title;
        data["description"] = this.embed_object.description;
        data["chart_type"] = this.embed_object.chart_type;
        data["payload"] =  this.embed_object.payload;

      if (!this.selected_board || (this.search != this.selected_board["plainTextTitle"])){
          //this.selected_board = this.addItem(this.search);
          await addStoryboard({title: this.search, description:'This report needs an overview', story: data})
        }
        else{
          await addStory(this.selected_board.id, data)
          ////////////console.log("EXISTING REPORT: ", this.selected_board);
        }

    },

    set_chart_object(){

      var can = document.getElementsByTagName("canvas");
      var imgUrl = can[0].toDataURL("image/png");

      let data = {
        imgData: imgUrl, //this.myChart.getDataURL(),
        options: this.graph
      };

      this.embed_object["title"] = this.title;
      this.embed_object["description"] = this.description;
      this.embed_object["chart_type"] = "network";
      this.embed_object["payload"] = data;
      this.embed_object["id"] = (Math.random() + Date.now()) * Math.random();
      this.embed_obj_changed = (Math.random() + Date.now()) * Math.random();
    },

    async copy(type) {
        /* Get the text field */

        ////////////console.log(this.buttons);

        var copyText = String;

        var qry = JSON.parse(JSON.stringify(this.$route.query));
        for (var i in this.chart_settings){
          qry[this.chart_settings[i]["field_name"]] = this.chart_settings[i]["value"]
        }

        //let routeData = this.$router.resolve({name: this.widget_name, query: this.$route.query});
        let routeData = this.$router.resolve({name: this.widget_name, query: qry});

        if (type == 'object'){
          var can = document.getElementsByTagName("canvas");
          var src = can[0].toDataURL("image/png");
          ////////////console.log(src);
          copyText = src;
        }

        if (type == 'iframe'){
            copyText = '<iframe src='+ '"'+window.location.origin +routeData.href+'"' +'frameborder="0" style="height: 600px; width: 1230px"></iframe>';
        }else if (type == 'link'){
            copyText = window.location.origin + routeData.href;
        }

        try {
            await navigator.clipboard.writeText(copyText);
        } catch (e) {
            //console.error(e);
        }

        /* Alert the copied text */
        this.snackbar = true;
    },

    expand_nodes(num_nodes){
      ////////////console.log("num_nodes: ", num_nodes);
      this.num_groups = num_nodes;
      this.refresh();
    },

    showConcentration(concentration){
      this.reportLoading = true;
      this.selectedConcentration = concentration;
      this.draw();
    },

    change_graph(graph_focus){
      this.reportLoading = true;
      this.current_graph = graph_focus;

      if (graph_focus == "category"){
        this.graph = this.category_graph;
        this.draw();
      }
      else if (graph_focus == "keyword"){
        this.graph = this.keyword_graph;
        this.draw();
      } 
      else{
        this.graph = this.entity_graph;
        this.draw();
      }
    },

    openWidget(){
        let routeData = this.$router.resolve({name: this.widget_name, query: this.$route.query});
        window.open(routeData.href, '_blank');
    },

    async refresh(){
      this.graph = String;
      this.reportLoading = true;
      this.getGraphData(this.$route.query);
    },

    async getPage(number) {
      this.current_page = number;
      this.loadTenders().then(results => {
        this.tenders = results.data;
      });
    },

    formattedCurrency: function(amount) {
      ////////////console.log("AMOUNT: " + amount);
      if (!isNaN(amount)) {
        return (
          "$" +
          Math.round(amount)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        );
      } //else {
      //return null;
      //}
    },
    formatDate(date_value) {
      if (!date_value) {
        return null;
      } else {
        return moment(date_value).format("MMMM YYYY");
      }
    },
    getTender(id) {
      //this.$router.push({ name: "tender", params: { id: id } });
      let routeData = this.$router.resolve({
        name: "tender",
        params: { id: id }
      });
      window.open(routeData.href, "_blank");
    },

    updateQuery(node, query) {
      this.currQuery = JSON.parse(JSON.stringify(query)); //NEED THIS TO PASS A COPY INSTEAD OF A REFERENCE
      
      if (node.keyword){
        this.currQuery["tender"] = node.keyword;
        ////////////console.log("updateQuery CURR QUERY: ", this.currQuery);
        //this.currQuery["tender"] = 
      }
      else{
        this.currQuery[node.type] = node.id;
      }
      
      this.currQuery["label"] = node.label;
      //this.tenders.push(this.currQuery);
    },

    async loadTenders() {
      this.overlay = true;
      var results;
      results = await executeSearch(this.currQuery, this.current_page);
      this.overlay = false;
      return results;
    },

    async getGraphData(qry) {

      var query = {};

      var query = JSON.parse(JSON.stringify(qry));

      query["agg_size"] = this.num_groups;

      graphSearch(query, 1)
        .then(data => {
          this.graph_data = data;
          this.category_graph = data.graph.category_graph;
          this.keyword_graph = data.graph.keyword_graph;
          this.entity_graph = data.graph.entity_graph;

          this.drawGraph();
        })
        .catch(error => {
          ////////////console.log(error);
          this.errored = true;
        });
    },

    drawGraph(){
      if (this.current_graph == "category"){
        this.graph = this.category_graph;
      }
      else if (this.current_graph == "keyword"){
        this.graph = this.keyword_graph;
      } 
      else{
        this.graph = this.entity_graph;
      }

      if (this.keyword_graph){
        this.has_keywords = true;
      }

      this.network = this.draw();
    },

    expand() {
      var el = document.getElementById("mynetwork");

      if (el.webkitRequestFullScreen) {
        el.webkitRequestFullScreen();
      } else {
        el.mozRequestFullScreen();
      }
    },

    /* change_depth(depth) {
      let size = depth;
      bus.$emit("extend-agg", size);
    }, */

    draw() {
      this.nodes = this.graph.nodes;

      this.edges = this.graph.edges;

      // Instantiate our network object.
      var container = document.getElementById("mynetwork");

      var edgesDataset = new DataSet(this.edges); 

      if (this.selectedConcentration == "category"){
        var multipleCategoriesEdgeColor = "#E10600";
        var multipleCategoriesNodeColor = "#F0B323";
        var multipleCategoriesNodeBGColor = "rgb(18, 37, 58)";

        // Create a map to track suppliers with multiple category edges
        var suppliersWithMultipleCategories = {};

        // Loop through edges to identify suppliers with multiple categories
        edgesDataset.forEach((edge) => {
          if (edge.from.includes("cat") && edge.to.includes("supp")) {
            const supplierId = edge.to;
            if (!suppliersWithMultipleCategories[supplierId]) {
              suppliersWithMultipleCategories[supplierId] = {
                count: 0,
                edges: [],
              };
            }
            suppliersWithMultipleCategories[supplierId].count += 1;
            suppliersWithMultipleCategories[supplierId].edges.push(edge.id);
          }
        });

        // Loop through nodes to update the border color of suppliers with multiple categories
        this.nodes.forEach((node) => {
          if (
              node.group === "supplier" &&
              suppliersWithMultipleCategories[node.id] &&
              suppliersWithMultipleCategories[node.id].count >= this.thresholdCategories
            ) 
          {
            // Supplier has multiple category edges
            node.color = {
              border: multipleCategoriesNodeColor, // Update only the border color
              background: multipleCategoriesNodeBGColor, // Retain the existing background color
            };

            // Update the colors of the edges for this supplier
            /* const edgeIds = suppliersWithMultipleCategories[node.id].edges;
            edgeIds.forEach((edgeId) => {
              const edge = edgesDataset.get(edgeId);
              edge.color = multipleCategoriesEdgeColor;
            }); */
          }
          else if (node.group === "supplier"){
            node.color = {
              border: "#12253a", // 79B807 Update only the border color
              background: "#12253a", //#12253a Retain the existing background color
            };
          }
        });
      }
      else if (this.selectedConcentration == "spend"){
        // Calculate the total value of all supplier nodes
        const totalSupplierValue = this.nodes.reduce((total, node) => {
          if (node.group === "supplier") {
            return total + node.value;
          }
          return total;
        }, 0);

        // Calculate the threshold value for "significant" suppliers (30% of the total value)
        const thresholdValue = totalSupplierValue * this.thresholdSpend;

        // Define the colors for significant suppliers
        const significantSpendEdgeColor = "#800080"; // Purple
        const significantSpendNodeColor = "#f0f8fe"; // Green


        this.nodes.forEach((node) => {
          if (node.group === "supplier" && node.value >= thresholdValue) {
            node.color = {
              border: "#98E116", // 79B807 Update only the border color
              background: "#12253a", //#12253a Retain the existing background color
            };
          } 
          else if (node.group === "supplier"){
            node.color = {
              border: "#12253a", // 79B807 Update only the border color
              background: "#12253a", //#12253a Retain the existing background color
            };
          }
        });

        // Set edge colors based on significance
        /* this.edges.forEach((edge) => {
          const fromNode = this.nodes.find((node) => node.id === edge.from);
          if (fromNode.group === "supplier" && fromNode.value >= thresholdValue) {
            edge.color = significantSpendEdgeColor;
          }
        }); */

      }
      else{
        this.nodes.forEach((node) => {
          if (node.group === "supplier") {
            node.color = {
              border: "#12253a", // 79B807 Update only the border color
              background: "#12253a", //#12253a Retain the existing background color
            };
          } 
        });
      }
      //store the node colours for when they are selected and deselected
      this.nodes.forEach((node) => {
        node.originalColor = {
          background: node.color.background,
          border: node.color.border,
        };
      });

      var nodesDataset = new DataSet(this.nodes); 


      var data = { nodes: nodesDataset, edges: edgesDataset };

      var options = {
        //highlightNearest: true,

        groups: {
          "category":{color:{background:'rgb(255, 20, 99)'}, borderWidth:3, 'color': 'rgb(255, 20, 99)', 'font': '24px roboto'},
          "supplier":{color:{background:'rgb(18, 37, 58)'}, borderWidth:3, 'color': 'rgb(18, 37, 58)', 'font': '13px roboto'},
          "publisher":{color:{background:'rgb(255, 231, 10)'}, borderWidth:3, 'color':'rgb(255, 231, 10)', 'font': '13px roboto'},
          "keyword" :{color:{background:'rgb(255,127,80)'}, borderWidth:3, 'color': 'rgb(255,127,80)', 'font': '24px roboto' }
        },

        layout: {improvedLayout: false},
        interaction: {
          //multiselect: true,
          navigationButtons: true
        },
        nodes: {
          shape: "dot",
          scaling: {
            min: 10,
            max: 80,
          }
        },
        edges: {
          chosen: true,
          color: {
            color: "#cbdae1",
            highlight: "#12253a" 
          }
        },
        physics: {
          enabled: true,
          forceAtlas2Based: {
            gravitationalConstant: -40,
            centralGravity: 0.005,
            springLength: 230,
            springConstant: 0.18,
          },
          maxVelocity: 146,
          solver: "forceAtlas2Based",
          timestep: 0.35,
          stabilization: { iterations: 150 }
        },
      };
      let network = new Network(container, data, options);

      var allNodes = nodesDataset.get({ returnType: "Object" });
      var highlightActive = false;

      network.on("selectNode", function(properties) {
        ////////////console.log(JSON.stringify(properties));
        if (properties.nodes[0].includes("supp")) {
          let id = properties.nodes[0].replace("supp", "");
          var sNode = { id: id, type: "supplier_id", keyword: null };
          bus.$emit("nodeSelected", sNode);
        } else if (properties.nodes[0].includes("pub")) {
          let id = properties.nodes[0].replace("pub", "");
          var pNode = { id: id, type: "publisher_id", keyword: null };
          bus.$emit("nodeSelected", pNode);
        }
        else if (properties.nodes[0].includes("cat")) {
          let id = properties.nodes[0].replace("cat", "");
          var cNode = { id: id, type: "major_category_id", keyword: null };
          bus.$emit("nodeSelected", cNode);
        }
        else{ //must be the keyword
          ////////////console.log("PROPERTIRE: ", properties.nodes[0]);
          let keyword = properties.nodes[0];
          var kNode = { id: keyword, type: "keyword", keyword: keyword };
          bus.$emit("nodeSelected", kNode);
        }

        /////////new
        if (properties.nodes.length > 0) {
          highlightActive = true;
          var i, j;
          var selectedNode = properties.nodes[0];
          var degrees = 2;

          // mark all nodes as hard to read.
          for (var nodeId in allNodes) {
            allNodes[nodeId].color = "rgba(200,200,200,0.5)";
            if (allNodes[nodeId].hiddenLabel === undefined) {
              allNodes[nodeId].hiddenLabel = allNodes[nodeId].label;
              allNodes[nodeId].label = undefined;
            }
          }
          var connectedNodes = network.getConnectedNodes(selectedNode);
          var allConnectedNodes = [];

          // get the second degree nodes
          for (i = 1; i < degrees; i++) {
            for (j = 0; j < connectedNodes.length; j++) {
              allConnectedNodes = allConnectedNodes.concat(
                network.getConnectedNodes(connectedNodes[j])
              );
            }
          }

          // all second degree nodes get a different color and their label back
          for (i = 0; i < allConnectedNodes.length; i++) {
            allNodes[allConnectedNodes[i]].color = "rgba(150,150,150,0.75)";
            if (allNodes[allConnectedNodes[i]].hiddenLabel !== undefined) {
              allNodes[allConnectedNodes[i]].label =
                allNodes[allConnectedNodes[i]].hiddenLabel;
              allNodes[allConnectedNodes[i]].hiddenLabel = undefined;
            }
          }

          // all first degree nodes get their own color and their label back
          for (i = 0; i < connectedNodes.length; i++) {
            allNodes[connectedNodes[i]].color = undefined;
            if (allNodes[connectedNodes[i]].hiddenLabel !== undefined) {
              allNodes[connectedNodes[i]].label =
                allNodes[connectedNodes[i]].hiddenLabel;
              allNodes[connectedNodes[i]].hiddenLabel = undefined;
            }
          }

          // the main node gets its own color and its label back.
          allNodes[selectedNode].color = undefined;
          if (allNodes[selectedNode].hiddenLabel !== undefined) {
            allNodes[selectedNode].label = allNodes[selectedNode].hiddenLabel;
            allNodes[selectedNode].hiddenLabel = undefined;
          }
        } else if (highlightActive === true) {
          // reset all nodes
          for (var nodeId in allNodes) {
            allNodes[nodeId].color = undefined;
            if (allNodes[nodeId].hiddenLabel !== undefined) {
              allNodes[nodeId].label = allNodes[nodeId].hiddenLabel;
              allNodes[nodeId].hiddenLabel = undefined;
            }
          }
          highlightActive = false;
        }

        // transform the object into an array
        var updateArray = [];
        for (nodeId in allNodes) {
          if (allNodes.hasOwnProperty(nodeId)) {
            updateArray.push(allNodes[nodeId]);
          }
        }
        nodesDataset.update(updateArray);
      });

      network.on("click", function (params) {
        if (params.nodes.length === 0) {
          for (var nodeId in allNodes) {
            //if (allNodes.hasOwnProperty(nodeId) && allNodes[nodeId].group === "supplier") {
            allNodes[nodeId].color = allNodes[nodeId].originalColor;
            //}
          }
          var updateArray = [];
          for (nodeId in allNodes) {
            if (allNodes.hasOwnProperty(nodeId)) {
              updateArray.push(allNodes[nodeId]);
            }
          }
          nodesDataset.update(updateArray);
        }
      });

      network.on("afterDrawing", function (ctx) {
        ////////////console.log(ctx[0]);
        //var dataURL = ctx.canvas[0].toDataURL();
        //this.imgURL = dataURL;
        //document.getElementById('canvasImg').src = dataURL;
      });

      this.reportLoading = false;

      return network;

      }
  }
};
</script>
<style scoped>

#mynetwork {
  height: 980px;
  overflow: visible;
}

#mynetwork .vis-network:focus {
  outline: none;
}

div.vis-network div.vis-navigation div.vis-button.vis-up,
div.vis-network div.vis-navigation div.vis-button.vis-down,
div.vis-network div.vis-navigation div.vis-button.vis-left,
div.vis-network div.vis-navigation div.vis-button.vis-right,
div.vis-network div.vis-navigation div.vis-button.vis-zoomIn,
div.vis-network div.vis-navigation div.vis-button.vis-zoomOut,
div.vis-network div.vis-navigation div.vis-button.vis-zoomExtends {
  background-image: none !important;
}

div.vis-network div.vis-navigation div.vis-button:hover {
  box-shadow: none !important;
}

div.vis-tooltip {
  color: #fff !important;
  background-color: #000000 !important;
  border-radius: 0px !important;
  border: none !important;
  font-family: verdana !important;
  font-size: 12px !important;
}

.vis-button:after {
  font-size: 2em !important;
  color: gray !important;
}

.vis-button:hover:after {
  font-size: 2em !important;
  color: lightgray !important;
}

.vis-button.vis-up:after {
  content: "▲" !important;
}

.vis-button.vis-down:after {
  content: "▼" !important;
}

.vis-button.vis-left:after {
  content: "◀";
}

.vis-button.vis-right:after {
  content: "▶" !important;
}

.vis-button.vis-zoomIn:after {
  content: "+" !important;
  font-weight: bold !important;
}

.vis-button.vis-zoomOut:after {
  content: "−" !important;
  font-weight: bold !important;
}

.wrap-text {
  -webkit-line-clamp: unset !important;
}

.tooltip {
  display: inline-block;
  max-width: 360px;
}


</style>
