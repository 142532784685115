<template>
<div class="pa-0 ma-0 fill-height" style="background-color:#f5f5f5" fluid>
    <v-dialog
          v-model="overlay"
          hide-overlay
          persistent
          width="300"
          lazy          
        >
          <v-card
            color="secondary"
            dark
          >
            <v-card-text>
              Building list...
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
    </v-dialog>
    <v-content v-if="!overlay">
        <v-row justify="center" class="px-0 py-0">
            <v-col cols="12">
                <v-card color="#f5f5f5" flat tile max-width="1400" class="mx-auto">
                    <p
                        style="text-align:center;"
                        class="pt-6 display-1 font-weight-medium"
                    >
                        Custom Reports
                    </p>
                    <p style="text-align:center;" class="body-1 font-weight-normal">
                        This is the list of custom reports that you have on Tendertrace. Only you can see this list.
                    </p>
                    <v-row class="px-0 py-0">
                        <v-col cols="12">
                            <div class="mb-5" style="background-color: white;">
                                <v-text-field
                                    style="font-size: 16px !important;"
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    label="Search"
                                    single-line
                                    hide-details
                                    outlined
                                    clearable
                                    dense
                                ></v-text-field>
                            </div> 
                            <v-data-table :search="search" :headers="headers" :items="boards" class="elevation-1 py-2">

                                <template v-slot:item.title="{ item }">
                                    <router-link class="editor-link" style="font-size: 14px !important; font-weight: 500 !important;" :to="'/report/'+item.id">{{stripHTML(item.title)}}</router-link>
                                </template>

                                <template v-slot:item.description="{ item }">
                                    {{stripHTML(item.description)}}
                                </template>

                                <template v-slot:item.created="{ item }">
                                    <span  style="font-size: 14px !important;">{{getMonthYear(item.created)}}</span>
                                </template>

                                <template v-slot:item.public="{ item }">
                                    <v-checkbox
                                        :input-value="item.public"
                                        color="primary"
                                        value
                                        disabled
                                    ></v-checkbox>
                                </template>
                            
                                <template v-slot:item.action="{ item }">
                                    <router-link target="_blank" :to="'/report/'+item.id">
                                        <v-icon medium class="mr-4">mdi-open-in-new</v-icon>
                                    </router-link>
                                    <v-icon medium @click="selectedItem = item, deleteReport = true">mdi-delete</v-icon>
                                </template>
                                </v-data-table>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
            <v-dialog v-model="deleteReport" max-width="500" absolute>
                <v-card>
                    <p class="title mx-auto pa-3">
                    <span>Delete report: </span> <span v-if="selectedItem"> {{selectedItem.title}}</span>?
                    </p>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="deleteReport = false">
                        Cancel
                    </v-btn>

                    <v-btn
                        color="#4EB888"
                        text
                        @click="(deleteReport = false), removeBoard(selectedItem.id)"
                    >
                        Delete
                    </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
    </v-content>
</div>
</template>
<script>
import { fetchStoryboards, deleteStoryboard } from "../components/APIManager.js";
import { getMonthYear } from "../components/Utils.js";

export default {
    components: {
    },

    data() {
        return {
            deleteReport: false,
            selectedReport: null,
            search:'',
            headers: [
                /* {
                text: "Images",
                align: "left",
                sortable: false,
                value: "imgData",
                width: "10%"
                }, */
                { text: "Title", value: "title", width: "25%" },
                { text: "Overview", value: "description", width: "45%" },
                { text: "Created", value: "created", width: "10%" },
                /* { text: "Last Updated", value: "last_updated", width: "10%" }, */
                { text: "Published", value: "public", width: "5%" },
                { text: "Actions", value: "action", sortable: false, width: "10%" }
            ],
            removeDialog: false,
            toRemove: "",
            grid: false,
            model: 0,
            title: "My Custom Reports",
            createdDate: "2 days ago",
            //description: "The advice and services provided by the consulting firms are critical to the Australian public sector. Since the start of the Covid pandemic, the top echelon of consulting firms, the Big Four, have won over $2.4B in Commonwealth, States and Territories Government contracts. This is a 100% growth from the amount awarded to all firms in the two years prior to covid. This Q2 FY21/22 insights have been generated on Tendertrace online platform to highlight the traction all firms have had in the public sector market in months leading to the height of the Omicron variant.",
            description: '',
            show: false,
            storyDialog: false,
            selectedStory: {},
            boards: [], //json,
            changed: Number,
            num: 0,
            boardUpdate: Number,
            storyboard: {},
            overlay: true
        }
    },

    async mounted(){
        this.loadBoards();
    },


    methods:{
        getMonthYear,

        async loadBoards(){
            this.overlay = true;
            var data = await fetchStoryboards();
            this.boards = data.storyboards;
            ////////console.log("----BOARDS----\n: ", this.boards);
            this.overlay = false;
        },

        async removeBoard(id){
            //this.overlay = true;
            deleteStoryboard(id)
            .then(response => {
                ////////console.log(response);
                this.loadBoards();
            })
            .catch(error => {
                return error;
            });
            
        },

        stripHTML(text){
            if (!text){
                return "";
            }
            else{
                let strippedText = text.replace(/(<([^>]+)>)/gi, "");
                return strippedText;
            }
            
        },

        currentStoryIndex(id){

            var index = this.stories.findIndex((story, index) => {
            if (story.id === id) {
                return true
            }
            })

            return index;

        },


         shortDescription(description){
            let text = description;
            let arr = text.split(" ");
            let limit = arr.length;
            let newText = []
            ////////console.log(arr);
            if (limit > 20){
                limit = 20;

                for (let i=0; i < limit; i++){
                    newText[i]= arr[i];
                }
                text = newText.join(" ");
                text = text + "..."
            }

            return text;
        }
    },

    computed:{

    },

    watch: {
        
    },
}
</script>
<style scoped>

.truncate {
    font-family:sans-serif;
    font-size: 1em;
    font-weight: bold;
    line-height: 1.2em;
    transition: var(--transition);
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden ;
    text-overflow: ellipsis;
}

.txt {
    
    font-weight: normal;
    line-height: 1.2;
    letter-spacing: -0.02em;
    color: var(--dark-grey);
}

.truncate-title {
    line-height: 2em;
    transition: var(--transition);
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden ;
    text-overflow: ellipsis;

}

#no-background-hover::before {
   background-color: transparent !important;
}
</style>
