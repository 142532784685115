<template>
    <div
        class="pa-0 ma-0 fill-height"
        style="background-color:#F4F4F4;"
        fluid
    >
    <v-dialog
          v-model="overlay"
          hide-overlay
          persistent
          width="300"
          lazy          
        >
          <v-card
            color="secondary"
            dark
          >
            <v-card-text>
              Generating report...
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
    </v-dialog>
    <v-content v-if="!overlay">
        <v-card tile max-width="1200px" class="mx-auto pt-2 pb-0">
            <v-row justify="center" class="px-0 pt-3 pb-5">
                <v-col class="pt-0" cols="12">
                    <v-card class="ma-0 pa-0" tile flat color="secondary">
                        
                        <v-row justify="center" class="px-0 pt-3 pb-0">
                            <v-col cols="12">
                                    <v-card dark color="secondary" tile flat max-width="1200px" class="mx-auto pa-9">
                                            <v-card-title class="pl-0 ml-0 pb-9">
                                                <span class="pl-0">
                                                    <a href="https://tendertrace.com" target="_blank">
                                                    <v-img
                                                    src="@/assets/img/logo-white.png"
                                                    max-height="100"
                                                    contain
                                                    class="pl-0 ml-0"
                                                    >
                                                    </v-img>
                                                    </a>
                                                </span>
                                            </v-card-title>
                                            <h2 class="display-3"><tiptap :editable="false" :sectionType="'heading'" :value="title" @input="saveUpdate" /></h2>
                                            <p class="title mt-3 font-weight-light"><tiptap :editable="false" :value="description" @input="saveUpdate" /></p>
                                            <p class="title mt-3 font-weight-light">{{getMonthYear(created)}}</p>
                                            <p class="mt-3 subtitle-2 font-weight-light"><i>by {{author}}</i></p>
                                    </v-card>
                            </v-col>
                        </v-row>
                        </v-card>
                        <v-card flat max-width="1400" class="mx-auto">
                            <v-row justify="center" class="mt-3 px-0 pb-0 pt-9">
                                <v-col class="d-flex child-flex mt-0 py-0" v-for="story in stories" :key="story.id" cols="12">
                                    <v-card tile flat max-width="1200px" class="mx-auto px-9 pt-0 pb-0">
                                        <story :editable="false" :changed="story.id" :position="story.position" :data="story" @close="storyDialog = false" />
                                        <v-divider v-if="showDivider(story)" class="pt-6 mt-6 pb-0 mb-0"></v-divider>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card>
                </v-col>
            </v-row>
            <v-footer
                dark
                padless
               
            >
                <v-card
                flat
                tile
                class="secondary white--text text-center"
                width="100%"
                >
                <v-card-text class="white--text pt-5">
                    This report was automatically generated using the Tendertrace market intelligence platform.
                </v-card-text>

                <v-card-text class="white--text">
                    &copy; 2022 Tendertrace, and/or its affiliates. All rights reserved. This report, including all supporting materials, is proprietary to Tendertrace, and/or its affiliates and is for the sole internal use of the intended recipients. Because this report may contain information that is confidential, proprietary or otherwise legally protected, it may not be further copied, distributed or publicly displayed without the express written permission of Tendertrace, and/or its affiliates.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-text class="white--text">
                    <span> <a href="https://tendertrace.com" target="_blank">
                                                <v-img
                                                src="@/assets/img/tendertrace_logo_outline_white_final_552x66.png"
                                                max-height="20"
                                                contain
                                                class="pl-0 ml-0"
                                                >
                                                </v-img>
                                                </a></span>
                    <span>&copy; {{ new Date().getFullYear() }}</span>
                </v-card-text>
                </v-card>
            </v-footer>
        </v-card>
    </v-content>
    </div>
</template>
<script>
import Story from "../components/Story.vue";
//import json from '../components/data/imgdata.json';
import Tiptap from "../components/Tiptap.vue";
import { fetchOpenboard } from "../components/APIManager.js";
import { getMonthYear } from "../components/Utils.js";

export default {
    components: {
        Story,
        Tiptap
    },

    props:{

    },

    data() {
        return {
            removeDialog: false,
            toRemove: "",
            grid: false,
            model: 0,
            title: "",
            createdDate: "",
            //description: "The advice and services provided by the consulting firms are critical to the Australian public sector. Since the start of the Covid pandemic, the top echelon of consulting firms, the Big Four, have won over $2.4B in Commonwealth, States and Territories Government contracts. This is a 100% growth from the amount awarded to all firms in the two years prior to covid. This Q2 FY21/22 insights have been generated on Tendertrace online platform to highlight the traction all firms have had in the public sector market in months leading to the height of the Omicron variant.",
            description: '',
            created:'',
            show: false,
            storyDialog: false,
            selectedStory: {},
            stories: {}, //json,
            changed: Number,
            num: 0,
            boardUpdate: Number,
            storyboard: {},
            overlay: true,
            author: ""
        }
    },

    async mounted(){
        this.$nextTick(() => {
          this.loadBoard();
        })
    },


    methods:{

        getMonthYear,

        showDivider(story){

            let index = this.stories.findIndex(item => item.id === story.id);
            if (index+1 == this.stories.length) return false;
            else return true;
        },

        async loadBoard(){
            this.overlay = true;
            var data = await fetchOpenboard(this.$route.params.openboardId);
            this.author = data.author;
            this.stories = data.stories;
            this.storyboard = data.storyboard;
            ////////console.log("----STORIES----\n: ", this.stories);
            this.title = this.storyboard.title;
            this.description = this.storyboard.description;
            this.created = this.storyboard.created;
            this.sortBoard();
            this.overlay = false;
        },

        stripHTML(text){
            if (!text){
                return "";
            }
            else{
                let strippedText = text.replace(/(<([^>]+)>)/gi, "");
                return strippedText;
            }
            
        },

        sortBoard(){
            this.stories.sort(function(a, b) {
                return parseInt(a.position) - parseFloat(b.position);
            });
        },
    
        showHideStory(story) {
            this.graphChanged(story);
            this.storyDialog = true;
            
        },

        graphChanged(story){
            this.selectedStory = null;
            this.selectedStory = story;
            this.changed = (Math.random() + this.stories.length) * Math.random();
            ////////console.log("MODEL: ", this.model);
            ////////console.log("STORY: ", this.selectedStory);
        },

        changeGraph(){
            return (Math.random() + this.stories.length) * Math.random();
        },

         shortDescription(description){
            let text = description;
            let arr = text.split(" ");
            let limit = arr.length;
            let newText = []
            ////////console.log(arr);
            if (limit > 20){
                limit = 20;

                for (let i=0; i < limit; i++){
                    newText[i]= arr[i];
                }
                text = newText.join(" ");
                text = text + "..."
            }

            return text;
        }
    },

    computed:{

    },

    watch: {
        model() {
            this.graphChanged(this.stories[this.model])
        },
    },
}
</script>
<style scoped>

.v-divider {
    border-style: solid !important
}

.truncate {
    font-family:sans-serif;
    font-size: 1em;
    font-weight: bold;
    line-height: 1.2em;
    transition: var(--transition);
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden ;
    text-overflow: ellipsis;
}

.txt {
    
    font-weight: normal;
    line-height: 1.2;
    letter-spacing: -0.02em;
    color: var(--dark-grey);
}

.truncate-title {
    line-height: 2em;
    transition: var(--transition);
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden ;
    text-overflow: ellipsis;

}

#no-background-hover::before {
   background-color: transparent !important;
}
</style>

