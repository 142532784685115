<template>
  <div>
    <v-card class="mx-auto" flat min-height="300">
    <v-card-title class="py-1">
      <span
        class="subtitle-2 font-weight-bold"
        style="color:rgba(0, 0, 0, 0.6);"
        >{{title}}</span>
    </v-card-title>
    <v-divider class="py-1"></v-divider>
    <chart-loader v-if="reportLoading" :reportLoading="reportLoading" />
      <panel-participation-chart v-else :chartHeight="'250px'" :data="chartData" :percentageType="percentageType"></panel-participation-chart>
  </v-card>
    
  </div>
</template>

<script>
import PanelParticipationChart from './PanelParticipationChart.vue';
import { getPanelParticipation } from "./APIManager.js";
import ChartLoader from "../components/ChartLoader";

export default {
  components: {
    PanelParticipationChart,
    ChartLoader
  },
  props:{
    son_id: {
      type: String(),
      default: "SON3675450"
    },
    gov_type: {
      type: String(),
      default: "FED"
    },
    percentageType: {
      type: String(),
      default: "percentage_count"
    },
    title: {
      type: String(),
      default: "Participation by Volume"
    },
    entity_type: {
      type: String(),
      default: "supplier"
    }

  },
  data() {
    return {
      chartData: [],
      reportLoading: Boolean
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.reportLoading = true;
      // Replace the URL with the actual API endpoint URL
      ////console.log("son: ", this.son_id, this.gov_type);
      getPanelParticipation(this.son_id, this.gov_type, this.entity_type)
            .then(data => {
                // Extract data for chart
                this.chartData = data;
                this.reportLoading = false;
            })
            .catch(error => true);
    },
  },
};
</script>
