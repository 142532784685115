<template>
  <span>
    <div
      :id="id"
      class="multidimensional pt-4 pl-4 pr-4"
      
    ></div>
  </span>
</template>

<script>
import { bus } from "../main.js";
import moment from "moment";
import * as echarts from "echarts";
import {downloadFile} from "./Utils";

export default {
  props: {
    heatmap_sets: [],
    heatmap_headers: [],
    heatmap_legend: [],
    activity: [],
    quarters: [],
    quarter_list: [],
    tooltipText: String(),
    type: String(),
    byQuarter: Boolean,
    changed: Number,
    download: Number,
    target_group: String,
    interval: String,
    embed: Number,
    story: Boolean,
    chart_options: {},
    id: Number
    //group_by_amount: Boolean
  },
  data() {
    return {
      autoresize: true,
      myChart: Object,
      heatmapOptions: {}
    };
  },
  watch: {

    quarters: {
      handler: function() {
        this.configureHeatmap();
      },
      //immediate: true,
      deep: true
    },

    byQuarter: {
      handler: function() {
        this.setXAxis();
        this.configureHeatmap();
      }
    },

    embed() {
      ////////////console.log("CALL SHARE CHART\n", this.myChart);
      
      this.saveChart();
    },

    changed: {
      handler: function() {
        this.setXAxis();
        this.configureHeatmap();
      }
    },

    download() {
      ////////////console.log("CHANGED-- ", this.chart_data);
      this.saveImage();
    }
  },
  mounted() {
    /* if (this.activity.length > 0 || this.story) {
      this.configureHeatmap();
    } */

    this.$nextTick(()=>{
        this.configureHeatmap();
    });
    
    
  },

  created() {
    //this.configureHeatmap();
  },

  methods: {

    saveChart(){

      var imgUrl = this.myChart.getDataURL();

      let data = {
        imgData: imgUrl, //this.myChart.getDataURL(),
        options: this.heatmapOptions
      };

      ////////////console.log("HEATMAP PAYLOAD: ", data);

      this.$emit("chart_object", data);

    },

    saveImage(){

      var img = new Image();
      
      img.src = this.myChart.getDataURL({
          pixelRatio: 2,
          backgroundColor: '#fff'
      });

      downloadFile(img.src);
    },

    flatFilter(params) {
      var selectedItemQuery = {};

      if (this.byQuarter) {
        var quarters = [
          { number: 1, months: ["January", "February", "March"] },
          { number: 2, months: ["April", "May", "June"] },
          { number: 3, months: ["July", "August", "September"] },
          { number: 4, months: ["October", "November", "December"] }
        ];

        var year = params.name.split("-")[1];
        var qNumber = params.name.replace("Q", "");
        qNumber = qNumber.split("-")[0];
        var months = [];

        for (var i in quarters) {
          if (quarters[i].number == qNumber) {
            months = quarters[i].months;
          }
        }

        selectedItemQuery["year"] = year;
        selectedItemQuery["months"] = months;
      } else {
        selectedItemQuery["months"] = params.name;
      }

      bus.$emit("flatHeatmapFilter", selectedItemQuery);

    },


    configureHeatmap() {

      if (this.story){
          ////////////console.log(this.chart_options);
          var chartDom = document.getElementById(this.id);
          this.myChart = echarts.init(chartDom);
          this.myChart.setOption(this.chart_options);
      }
      else{

        this.heatmapOptions= {
          
            tooltip: {
              position: "top"
            },
            animation: false,
            grid: {
              left: "0%",
              right: "0%",
              bottom: "0%",
              top: "0%",
              containLabel: true
            },
            xAxis: {
              type: "category",
              data: this.setXAxis(),
              splitArea: {
                show: true
              },
              axisTick: {
                show: false
              },
              axisLabel: {
                color: "rgb(255, 20, 99)",
                fontFamily: "Roboto",
                //fontWeight: "bold",
                fontSize: 12
              }
            },
            yAxis: {
              type: "category",
              data: [],
              splitArea: {
                show: true
              },
              axisTick: {
                show: false
              },
              axisLabel: {
                color: "rgb(18, 37, 58)",
                fontFamily: "Roboto",
                //fontWeight: "bold",
                fontSize: 16
              }
            },
            visualMap: {
              min: 0,
              max: 500,
              calculable: true,
              orient: "horizontal",
              left: "center",
              textStyle: { color: "white" },

              inRange: {
                
                color: [
                  "#78b1f0",
                  "#4d94e3",
                  "#3c7bc1",
                  "#3368a3",
                  "#295585",
                  "#204267",
                  "#1b3858",
                  "#12253a"
                ]
              },
              show: false
            },
            series: [
              {
                name: this.tooltipText,
                type: "heatmap",
                data: [],
                label: {
                  show: true
                },
                /* emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowColor: "rgba(78, 184, 136)"
                  }
                }, */
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: "red" // color at 0% position
                      },
                      {
                        offset: 1,
                        color: "blue" // color at 100% position
                      }
                    ],
                    global: false // false by default
                  }
                }
              }
            ]
          }


        let xData = [];
        let yData = [];
          
        var chartDom = document.getElementById(this.id);

        let existInstance = echarts.getInstanceByDom(chartDom);
        
        if (existInstance) {
            if (true) {
                echarts.dispose(chartDom);
            }
        }
        this.myChart = echarts.init(chartDom);

        let numElements = Number;
        //let xData = [];

        if (this.byQuarter) {
          numElements = xData.length * this.quarters.length;
          //this.heatmapOptions.xAxis.data = this.quarter_list;
          this.heatmapOptions.xAxis.data = this.setXAxis();
          xData = this.heatmapOptions.xAxis.data;
        } else {
          numElements = xData.length * this.activity.length;
          this.heatmapOptions.xAxis.data = this.setXAxis();
          xData = this.heatmapOptions.xAxis.data;
        }

        //let yData = [];

        let counter = 0;
        let currMax = 0;
        let prevMax = 0;

        yData[0] = this.tooltipText;

        ////////////console.log("YDATA: " + yData[y]);

        var quarterly_data = this.activity;

        let seriesData = new Array(numElements);

        for (var y = 0; y < this.activity.length; y++) {
          yData[y] = this.activity[y].key;
          ////////////console.log("YDATA: " + yData[y]);
          var quarterlyData = this.activity[y].quarters.buckets;

          for (var x = 0; x < xData.length; x++) {
            let value = 0;
            ////////////console.log("XDATA: " + xData[x]);
            for (var m = 0; m < quarterlyData.length; m++) {

              ////////////console.log("xData[x]: ", xData[x], " --- quarterlyData[m].key: ", quarterlyData[m].key_as_string);
              ////////////console.log("X-AXIS: ", xData[x]);
              ////////////console.log("quarterlyData[m].key_as_string: ", quarterlyData[m].key_as_string);

              let qstart = this.getQuarterStart(quarterlyData[m].key_as_string.charAt(0));
              let data_for_quarter = qstart + quarterlyData[m].key_as_string.substring(1,6);

              if (xData[x] == data_for_quarter || xData[x] == data_for_quarter || xData[x] == data_for_quarter || xData[x] == data_for_quarter) {
                value = quarterlyData[m].doc_count;

                currMax = quarterlyData[m].doc_count;

                if (prevMax > currMax) {
                  currMax = prevMax;
                }

                prevMax = quarterlyData[m].doc_count;
              }
            }

            seriesData[counter] = [x, y, value];
            counter++;
          }
        }

        this.heatmapOptions.yAxis.axisLabel.fontSize = 13;
        this.heatmapOptions.xAxis.axisLabel.fontSize = 13;
        
        this.heatmapOptions.visualMap.max = currMax * 3;
        this.heatmapOptions.yAxis.data = yData;
        this.heatmapOptions.series[0].data = seriesData;

        this.myChart.setOption(this.heatmapOptions);

        var dataview = {headers: [], data: []}

        for (var i in xData){
          dataview.headers.push({text: xData[i], value: xData[i]});
        }

        dataview.headers.unshift({text: 'Entity', value: 'entity'});

        var seriesIndex = 0;

        for (var j in yData){
          let entity = yData[j];
          let row = {};
          row["entity"] = entity;

          for (var s in xData){
            row[xData[s]] = seriesData[seriesIndex][2];
            seriesIndex++;
          }
          dataview.data.push(row);
        }

        this.$emit('dataview', dataview);

        this.myChart.on('click', param => {
          ////////////console.log("PARAM: ", param);
          ////////////console.log("QTR: ", xData[param.data[0]]);
          ////////////console.log("GROUP: ", yData[param.data[1]]);

          let args = [];

          let field = this.target_group;
          if (field == "supplier_clusters"){
            field = "supplier_cluster_name";
          }
          if (field == "buyer_clusters"){
            field = "buyer_cluster_name";
          }

          var year = xData[param.data[0]];
          year = year.split('-');
          year = "20" + year[1];
          
          var qtr = xData[param.data[0]];
          //qtr = qtr.split('-');
          qtr = qtr.substring(0,3);
          //qtr = qtr[0].split("Q")[1];
          qtr = this.getMonthsInQuarter(qtr);

          ////////////console.log("QTR: ", qtr);

          args[0] = {field: "expiry_from", value: year};
          args[1] = {field: "expiry_to", value: year};
          args[2] = {field: "expiry_month", value: qtr};
          args[3] = {field: field, value: yData[param.data[1]]};

          ////////////console.log("ARGS: ", args);

          this.$emit("showTenders", args);
        });

      }

    },

    getMonthsInQuarter(qtr){
      switch (qtr) {
        case "Jan":
          return ["January", "February", "March"];
        case "Apr":
          return ["April", "May", "June"];
        case "Jul":
          return ["July", "August", "September"];
        case "Oct":
          return ["October", "November", "December"];
        
      }
    },

    getIndex(key, list) {
      for (var i = 0; i < list.length; i++) {
        if (list[i] === key) {
          return i;
        }
      }
    },

    setXAxis() {
      var axis = [];
      if (this.byQuarter) {

        ////////////console.log("QUARTERS: ", this.quarters);

        var now = moment(new Date());
        //get current year and quarter number
        var year = now.year().toString().substr(-2);
        var qtr = now.quarter();

        var curr_quarter = qtr + "-" + year;

        var next_eight_quarters = [];

        for (var i = 0; i < 8; i++) {
          next_eight_quarters[i] = qtr + "-" + year;
          if (qtr == 4) {
            year++;
            qtr = 1;
          } else {
            qtr++;
          }
        }

        var start_index = this.findIndex(
          next_eight_quarters,
          curr_quarter,
          this.quarters
        );

        let count = 0;

        if (start_index < 0){
          start_index = this.quarters.length;
        }

        for (var i = start_index; i < this.quarters.length; i++) {
          
          let qstart = this.getQuarterStart(this.quarters[i].key_as_string.charAt(0));
          let label = qstart + this.quarters[i].key_as_string.substring(1,6);

          axis[count] = label;

          //axis[count] = "Q" + this.quarters[i].key_as_string;
          if (count == 7) {
            break;
          } else {
            count++;
          }
        }
      } else {
        
        axis = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December"
        ];

      }

      return axis;
    },

    getQuarterStart(qtr){
      switch (qtr) {
        case "1":
          return "Jan";
        case "2":
          return "Apr";
        case "3":
          return "Jul";
        case "4":
          return "Oct";
        
      }
    },

    findIndex(qtr_list, str, obj) {
      var index = -1;
      for (var i = 0; i < obj.length; i++) {
        for (var j = 0; j < qtr_list.length; j++) {
          if (obj[i].key_as_string === qtr_list[j]) {
            index = i;
            return index;
          }
        }
      }
      return index;
    },

    formattedCurrency: function(data) {
      data = parseFloat(data);
      return data.toLocaleString("tr-TR", {
        style: "currency",
        currency: "TRY"
      });
    },

    getStatusColour(item) {
      if (item.current) {
        return "#b3bc4b";
      } else {
        return "#E0E0E0";
      }
    },
    
    showHideDetails() {
      //this.item_details = !this.item_details;
      this.item_details = true;
      bus.$emit("show-hide-details", this.item_details);
    }
  },
  computed: {
    byAgency() {
      return this.results.reduce((acc, result) => {
        (acc[result.agency] = acc[result.agency] || []).push(result.title);
        return acc;
      }, {});
    }
  }
};
</script>

<style>
.chart-wrapper {
  width: 100%;
  height: 50%;
}
.flat {
  width: 100% !important;
  height: 60px !important;
  padding-left: 0px !important;
  /* padding: 0px, 0px, 0px, 0px !important; */
}
.multidimensional {
  margin-top: 0px !important;
  padding-top: 0px !important;
  padding-left: 0px !important;
  width: 100% !important;
  height: 400px !important;
  /* padding: 0px, 0px, 0px, 0px !important; */
}
</style>
