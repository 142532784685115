<template>
  <div class="fill-height">
    <v-row class="pt-0 pb-1 px-0">
      <v-col cols="3" class="pr-1">
            <v-card class="mt-0" tile>
                <v-card-title class="pl-5 pt-2 d-flex justify-space-between align-center" style="font-weight:normal !important;">
                      <span
                        style="color:secondary; font-weight:bold;"
                      >
                      <div>
                        Market Overview <span class="caption font-weight-normal font-italic grey--text">Last 4 FYs to date</span>
                      </div>
                      <!-- <div class="subtitle-1">
                        Cumulative growth in contract awards over the past 24 months
                      </div> -->
                      </span>
                      <a href="https://docs.tendertrace.com/guide/user-documentation/analysing-search-results/summary-dashboard" target="_blank">
                          <v-icon class="material-icons" color="secondary" right>mdi-help-circle-outline</v-icon>
                      </a>
                  </v-card-title>
                  <v-row class="pt-2 px-2">
                    <v-col cols="12" md="12" class="pt-0 mt-0">
                      <spend-comparison-mini
                        :title="'Size of Wallet'" 
                        :description="''" 
                        :query="categoryOnlyQuery" 
                        :interval_text="'Fiscal Year'" 
                        :interval_value="'fy'" 
                        :chartHeight="'200px'"
                        :cardHeight="'220px'"
                      />
                    </v-col>
                  </v-row>
  
            </v-card>
      </v-col>
      <v-col cols="9" class="pl-1">
            <v-card class="mt-0" tile>
                <v-card-title class="pl-5 pt-2" style="font-weight:normal !important;">
                      <v-row>
                        <v-col cols="12" md="9">
                          <span style="color:secondary; font-weight:bold;">Top 10</span> <span class="caption font-weight-normal font-italic grey--text">Entities are grouped based on the dropdown value on the right</span>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-card-text class="text-right pr-0 pt-0 pb-0" style="float: right;">
                            <div class="d-inline">
                              <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                  class="text-capitalize body-1"
                                    color="#E0E0E0"
                                    outlined
                                    v-bind="attrs"
                                    v-on="on"
                                    width="170"
                                    small
                                    style="justify-content:left !important;"
                                  >
                                    <span style="color:#12253a;" class="subtitle-2">{{group.text}}</span>
                                    <v-icon color="#12253a" right>mdi-menu-down</v-icon>
                                  </v-btn>
                                </template>
                                <v-list>
                                  <v-list-item
                                    v-for="(item, value) in group_options"
                                    :key="value"
                                    @click="group = item, change_group()"
                                  >
                                    <v-list-item-title style="color:#12253a;" class="subtitle-2">{{ item.text }}</v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </div>
                          </v-card-text>
                        </v-col>
                      </v-row>
                  </v-card-title>
                <v-row class="pt-2 px-2">
                  <v-col cols="12" md="4" class="pt-0 mt-0">
                    <spending-leaders-bar
                      @updateQuery="updateQuery" 
                      :query="$route.query" 
                      :title="'By Contract Value ($)'" 
                      :group="group"
                      :stacked="false"
                      :dataLoaded="dataLoaded"
                      :resetChart="resetByContractValue"
                      :data="dashboardData"
                      :metric="{text: 'Amount', value: contract_value_metric}"
                      :chartHeight="'360px'"
                      :cardHeight="'220px'"
                       />
                  </v-col>
                  <v-col cols="12" md="4" class="pt-0 mt-0">
                    <spending-leaders-bar :query="$route.query"
                      @updateQuery="updateQuery" 
                      :title="'By Number of Contracts (#)'" 
                      :group="group"
                      :stacked="false"
                      :dataLoaded="dataLoaded"
                      :resetChart="resetByNumContracts"
                      :data="dashboardData"
                      :metric="{text: 'Volume', value:contract_count_metric}"
                      :chartHeight="'360px'"
                      :cardHeight="'220px'" />
                  </v-col>
                  <v-col cols="12" md="4" class="pt-0 mt-0">
                    <spending-leaders-bar :query="$route.query" 
                      :title="'By Average Contract ($)'"
                      @updateQuery="updateQuery"
                      :group="group"
                      :stacked="false"
                      :dataLoaded="dataLoaded"
                      :resetChart="resetByAvgValue"
                      :data="dashboardData"
                      :metric="{text: 'Average', value:contract_avg_metric}"
                      :chartHeight="'360px'"
                      :cardHeight="'220px'" />
                  </v-col>
                </v-row>
            </v-card>
      </v-col>
    </v-row>
    <v-row class="pt-0 pb-1 px-0">
      <v-col cols="12" class="pr-1 pt-1">

        <v-card class="mt-0" tile>
          <v-card-title class="pl-5 pt-2 d-flex justify-space-between align-center" style="font-weight:normal !important;">
                <span
                  style="color:secondary; font-weight:bold;"
                >
                <div>
                  Market Breakdown <span class="caption font-weight-normal font-italic grey--text">This is a breakdown across market dimensions</span>
                </div>
                </span>
                <a href="https://docs.tendertrace.com/guide/user-documentation/analysing-search-results/summary-dashboard" target="_blank">
                          <v-icon class="material-icons" color="secondary" right>mdi-help-circle-outline</v-icon>
                      </a>
            </v-card-title>
          <v-row class="pt-2 px-2">
            <v-col cols="12" md="4" class="pt-0 mt-0">
              <spending-leaders-bar
                :title="'Buyer Spending'" 
                :stacked="true"
                :showChoices="true"
                :choices="smallWidgetChoices"
                :tempType="smallWidgetGroup"
                :group="group"
                :widgetID="smallWidgetSettings.id"
                :widgetSelection="smallWidgetSettings.selection"
                :dataLoading="smallWidgetLoading"
                :dataLoaded="smallWidgetDataLoaded"
                :data="smallWidgetData"
                :metric="{text: 'Amount', value:'total_amount'}"
                :chartHeight="'200px'"
                :cardHeight="'250px'"
                @selectedItems="updateSmallWidgetItems" />
              <v-row>
                <v-col cols="12" md="12" class="pt-0 mt-0">
                  <spend-comparison-mini
                    :title="'By Quarter'"
                    :description="''" 
                    :query="trendChartQuery"
                    :num_entities="num_entities"
                    :legend="true"
                    :key="filteredDataLoaded"
                    :interval_text="'Quarter'" 
                    :interval_value="'quarter'"
                    :sum_value="'actual'"
                    :sum_text="'Actual'"
                    :group_value="group.value"
                    :group_text="group.text"   
                    :chartHeight="'200px'"
                    :cardHeight="'250px'"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="4" class="pt-0 mt-0">
              <spending-leaders-bar
                :title="'Agency Sector'" 
                :stacked="true"
                :showChoices="true"
                :choices="largeWidgetChoices"
                :widgetID="largeWidgetSettings.id"
                :widgetSelection="largeWidgetSettings.selection"
                :dataLoading="largeWidgetLoading"
                :dataLoaded="largeWidgetDataLoaded"
                :data="largeWidgetData"
                :tempType="largeWidgetGroup"
                :group="group"
                :metric="{text: 'Amount', value:'total_amount'}"
                :chartHeight="'400px'"
                :overflowCardHeight="'400px'"
                :cardHeight="'500px'"
                @selectedItems="updateLargeWidgetItems" />
            </v-col>
            <v-col cols="12" md="4" class="pt-0 mt-0">
              <spending-leaders-bar
                :title="'Category'" 
                :stacked="true"
                :dataLoading="filterLoading"
                :dataLoaded="filteredDataLoaded"
                :data="filteredData"
                :tempType="'category'"
                :group="group"
                :metric="{text: 'Amount', value:'total_amount'}"
                :chartHeight="'400px'"
                :overflowCardHeight="'400px'"
                :cardHeight="'500px'" />
            </v-col>
            
          </v-row>
          
      </v-card>
        
      </v-col>
    </v-row>
    <!-- <v-row v-if="showTable" class="pt-0 pb-1 px-0">
      <v-col cols="12" class="pr-1 pt-1">
        <results-table
              class="pb-3 pt-0 pl-0 pr-0"
              :hit_info="hit_info"
              :tenders="tenders"
              :hide_pagination="true"
            />
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
import { bus } from "../main.js";
import moment from "moment";
import { executeSearch, histogramSearch, entitySearch, getClusters, updateClusters, addCluster } from "./APIManager.js";
import SpendingLeadersBar from "../components/SpendingLeadersBar";
import ResultsTable from "../components/ResultsTable.vue";
import SpendComparisonMini from "./SpendComparisonMini.vue";

export default {
  props: {
    
  },
  components: {
    SpendingLeadersBar,
    SpendComparisonMini,
    //ResultsTable
  },

  data() {
    return {
      smallWidgetGroup: String,
      largeWidgetGroup: String,
      clusters: [],
      trendChartQuery: {},
      showTable: false,
      hit_info: {},
      tenders: [],
      total_amount: 0,
      total_records: 0,

      group_options: [{text: "Buyer Entity", value:"publisher"},
                      {text: "Supplier Entity", value:"supplier"}],

      group: { text: "Supplier Entity", value:"supplier" },

      reportLoading: Boolean,
      tableLoading:Boolean,
      filterLoading: Boolean,
      direction: 'top',
      fab: false,
      fling: false,
      hover: false,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: 'slide-y-reverse-transition',

      grouped_tenders: [],

      show_entity_amount: null,
      show_supplier_amount: null,
      show_category_amount: null,
      item_details: { show: false, record: {} },
      saved: [{ tender_id: "", value: false }],
      total_contracts: "",
      current_contracts: 0,
      expiring_soon: 0,
      avg_contract_value: 0,
      largest_contract: { supplier: "", procurer: "", contract_value: 0 },
      total_contract_value: 0,
      largest_supplier: { supplier: "", total_amount: 0 },
      largest_procurer: { agency: "", total_amount: 0 },
      most_active_govt: { govt: "", total_contracts: 0 },
      updated: 0,
      contracting_activity: [],
      contracting_spend: [],
      contracted_per_month: [],
      govt_aggs: [],
      tender_filters: [],
      plain_text_query: "",
      change_grp: Number,
      dashboardData: {},
      dataLoaded: Number,
      filteredData: {},
      filteredDataLoaded: Number,
      breakdownData: {},
      contract_value_metric: 'total_amount',
      contract_count_metric: 'doc_count',
      contract_avg_metric: 'avg_value',
      filterClicked: false,
      currentFilterGroup: String,
      resetByContractValue: Number,
      resetByNumContracts: Number,
      resetByAvgValue: Number,
      num_entities: 10,
      num_widget_entities: 30,
      hasBuyerClusters: Boolean,
      smallWidgetChoices: [],
      smallWidgetData: [],
      smallWidgetDataLoaded: Number,
      smallWidgetLoading: Boolean,
      smallWidgetSettings: {id: "smallWidgetID", 
                            selection: "smallWidgetSelection"},
      largeWidgetData: [],
      largeWidgetDataLoaded: Number,
      largeWidgetLoading: Boolean,
      largeWidgetSettings: {id: "largeWidgetID", 
                            selection: "largeWidgetSelection"},
      largeWidgetChoices: [],
      //smallWidgetID: "smallWidget",
      //smallWidgetSelection: "government"
      
      //gov_spend_title: "Government Spending Breakdown",
      //gov_spend_description: "Overall spending and transaction numbers can be further broken down by the top buyers, suppliers or categories grouped by federal, state, and territory governments.",
      //market_share_title: "Market Share",
      //market_share_description: "Overall spending and transaction numbers can be further broken down by the top buyers, suppliers or categories grouped by federal, state, and territory governments."
    };
  },


  watch: {
    $route(to, from) {
      this.refresh();
    },
    

  },
  created() {
    ////////////////////console.log("SEARCH TERMS: ", JSON.stringify(this.search_terms))
  },

  mounted() {
    this.refresh();
  },

  methods: {

    async updateSmallWidgetItems(items){
      //////////////////console.log("selected clusters: ", items);

      this.smallWidgetLoading = true;

      //////////////////console.log("VUE QUERY: ", this.$route.query); 

      //////////////////console.log("urlParams: ", qry);

      await this.getWidgetData(items);

    },

    async updateLargeWidgetItems(items){
      //////////////////console.log("selected clusters for large widget: ", items);

      this.largeWidgetLoading = true;

      //////////////////console.log("VUE QUERY: ", this.$route.query); 

      //////////////////console.log("urlParams: ", qry);

      await this.getLargeWidgetData(items);

    },

    async refresh(){
        this.loadSettings();
        this.trendChartQuery = this.getCurrentQueryParams();
        await this.getDashboardData(this.getCurrentQueryParams());
        await this.getFilteredData(this.getCurrentQueryParams());
        await this.getWidgetData();
        await this.getLargeWidgetData();
    },

    getCurrentQueryParams(){
      let queryString = window.location.search;
      let urlParams = new URLSearchParams(queryString);
      //let qry = Object.fromEntries(urlParams);
      //////////////////console.log ("queryString: ", queryString);
      //////////////////console.log ("urlParams: ", urlParams.entries());
      //////////////////console.log ("js qry: ", qry);
      //////////////////console.log ("$route.params: ", this.$route.params);
      //////////////////console.log("ROUTE QUERY: ", this.$route.query)

      var qry = {};

      // Display the key/value pairs
      for (const [key, value] of urlParams.entries()) {
        if (qry.hasOwnProperty(key)){ //if the value already exists make that value an array
          if (!Array.isArray(qry[key])){ //if not array already then make it one
            qry[key] = [qry[key]];
          }
          qry[key].push(value);
        }
        else{
          qry[key] = value;
        }
        //////////////////console.log(`${key}, ${value}`);
      }
      //////////////////console.log ("js qry: ", qry);
      return qry;
      //return this.$route.query;
    },

    async loadClusters(){

      //this.loading = true;

      getClusters(this.entityType).then(results => {
        this.clusters = results.data;
        ////////////////////console.log("CLUSTER QUERY: ", JSON.parse(this.clusters[0].search_query));
        for (var i in this.clusters){
            this.clusters[i].search_query = JSON.parse(this.clusters[i].search_query)
        }
        
        //this.loading = false;
        ////////////////////console.log("loadPublishers data: ", results);
      });
    },

    loadSettings(){
      var query = this.$route.query;

      this.group_options = [{text: "Buyer Entity", value:"publisher"},
                      {text: "Supplier Entity", value:"supplier"}];

      this.group = { text: "Supplier Entity", value:"supplier" };
      
      if (query.hasOwnProperty("tender") && !this.isEmpty(query.tender)){
        delete this.group.text;
        delete this.group.value;
        this.group = {text: "Keyword", value:"search_terms"};
        if (!this.group_options.find(o => o.value === 'search_terms')){
          this.group_options.push({text: "Keyword", value:"search_terms"})
        }
      }
      else if (query.hasOwnProperty("supplier_clusters") && !this.isEmpty(query.supplier_clusters)){
        delete this.group.text;
        delete this.group.value;
        this.group = {text: "Supplier Cluster", value:"supplier_clusters"};

        if (!this.group_options.find(o => o.value === 'supplier_clusters')){
          this.group_options.push({text: "Supplier Cluster", value:"supplier_clusters"})
        }

      }
      /* else if (query.hasOwnProperty("buyer_clusters")  && !this.isEmpty(query.buyer_clusters)){
        delete this.group.text;
        delete this.group.value;
        this.group = {text: "Buyer Cluster", value:"buyer_clusters"};

        if (!this.group_options.find(o => o.value === 'buyer_clusters')){
          this.group_options.push({text: "Buyer Cluster", value:"buyer_clusters"})
        }

      } */
    },

    isEmpty(obj) {
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
      }
      return true;
    },

    updateQuery(query){

      var newFilterGroup = query.metric_type;

      if (!this.filterClicked){
        this.currentFilterGroup = query.metric_type;
        this.filterClicked = true;
      }else{
        if (newFilterGroup != this.currentFilterGroup){
          //reset the filters for the current filter group
          switch(this.currentFilterGroup){
            case this.contract_value_metric:
              this.resetByContractValue = Math.random() * Math.random();
              break;
            case this.contract_count_metric:
              this.resetByNumContracts = Math.random() * Math.random();
              break;
            case this.contract_avg_metric:
              this.resetByAvgValue = Math.random() * Math.random();
              break;
          }
        }
      }

      ////////////////////console.log("NEW QUERY:  ", query);

      this.currentFilterGroup = newFilterGroup;
      this.filterLoading = true;
      this.trendChartQuery = query;
      this.getFilteredData(query);
      this.filteredLoading = false;
        
    },


    async loadAwards(qry) {
      let results = {};

      //let labels = [];
      results = await executeSearch(qry);

      ////////////////////console.log("TABLE RESULTS: ", results)

      this.tenders = results.data;
      this.hit_info = results.hits;
      this.tableLoading = false;
    },

    async getDashboardData(qry) {
      //this.dashboardData = [];

      var query = JSON.parse(JSON.stringify(qry));
      query["agg_size"] = this.num_entities;
      delete query["yoy_analysis"];
      query["yoy_analysis"] = false; // faster query

      ////////////////////console.log(query);

      entitySearch(query, 1)
        .then(data => {
          //////////////////console.log("DATA: ", data)
          if (data.aggregations.hasOwnProperty("supplier_clusters")){
            if (!this.group_options.find(o => o.value === 'supplier_clusters')){
              this.group_options.push({text: "Supplier Cluster", value:"supplier_clusters"})
              delete this.group.text;
              delete this.group.value;
              this.group = {text: "Supplier Cluster", value:"supplier_clusters"};
            }

          }

          this.dashboardData = data;
          this.dataLoaded = (Math.random() + Date.now()) * Math.random();
        })
        .catch(error => {
        });
    },

    async getFilteredData(qry) {
      //this.filteredData = [];

      var query = JSON.parse(JSON.stringify(qry));
      query["agg_size"] =this.num_entities;
      delete query["yoy_analysis"];
      query["yoy_analysis"] = false; // faster query

      ////////////////////console.log("filter: ", query);

      entitySearch(query, 1)
        .then(data => {
          this.filteredData = data;
          this.filteredDataLoaded = (Math.random() + Date.now()) * Math.random();
        })
        .catch(error => {

        });
    },

    async getWidgetData(items) {
      //this.filteredData = [];

      var query = JSON.parse(JSON.stringify(this.getCurrentQueryParams()));
      query["agg_size"] =this.num_widget_entities;
      delete query["yoy_analysis"];
      query["yoy_analysis"] = false; // faster query

      //////////////////console.log("filter: ", query);

      if (query.hasOwnProperty("smallWidgetID")) {
        //////////////////console.log("property exists");
        var group = query["smallWidgetSelection"];

        //this.hasBuyerClusters = true;

        this.smallWidgetGroup = group;
        this.smallWidgetChoices = [{text:'Buyer Cluster', value:'buyer_clusters'}, {text:'Government', value:'government'}]

        if (group == "buyer_clusters"){
          group = "buyer_cluster";
        }

        delete query[group];

        if (items && items.length > 0){
          query[group] = items;
        }
        else{
          query[group] = query["smallWidgetID"].split(',');
        }

        //////////////////console.log("new query: ", query);
        
        entitySearch(query, 1)
        .then(data => {
          this.smallWidgetData = data;
          this.smallWidgetLoading = false;
          this.smallWidgetDataLoaded = (Math.random() + Date.now()) * Math.random();
        })
        .catch(error => {
          ////////////////////console.log(error);
          //this.errored = true;
          //this.overlay = false;
        });

      }
      else{

        //////////////////console.log("filter: ", query);
        this.smallWidgetGroup = 'government'
        this.smallWidgetChoices = [{text:'Government', value:'government'}, {text:'Buyer Cluster', value:'buyer_clusters'}]

        entitySearch(query, 1)
          .then(data => {
            //////////////////console.log("DATA: ", data);
            this.smallWidgetData = data;
            this.smallWidgetLoading = false;
            this.smallWidgetDataLoaded = (Math.random() + Date.now()) * Math.random();
          })
          .catch(error => {
            ////////////////////console.log(error);
            //this.errored = true;
            //this.overlay = false;
          });

      }
      
      
    },
      
    async getLargeWidgetData(items) {
      //this.filteredData = [];

      var query = JSON.parse(JSON.stringify(this.getCurrentQueryParams()));
      query["agg_size"] =this.num_widget_entities;
      delete query["yoy_analysis"];
      query["yoy_analysis"] = false; // faster query

      //////////////////console.log("filter: ", query);

      if (query.hasOwnProperty("largeWidgetID")) {
        //////////////////console.log("property exists");
        var group = query["largeWidgetSelection"];

        //this.hasBuyerClusters = true;

        this.largeWidgetGroup = group;
        this.largeWidgetChoices = [{text:'Buyer Cluster', value:'buyer_clusters'}, {text:'Agency Sector', value:'sector'}]

        if (group == "buyer_clusters"){
          group = "buyer_cluster";
        }

        delete query[group];

        if (items && items.length > 0){
          query[group] = items;
        }
        else{
          query[group] = query["largeWidgetID"].split(',');
        }

        //////////////////console.log("new query: ", query);
        
        entitySearch(query, 1)
        .then(data => {
          this.largeWidgetData = data;
          this.largeWidgetLoading = false;
          this.largeWidgetDataLoaded = (Math.random() + Date.now()) * Math.random();
        })
        .catch(error => {
          ////////////////////console.log(error);
          //this.errored = true;
          //this.overlay = false;
        });

      }
      else{

        //////////////////console.log("filter: ", query);
        this.largeWidgetGroup = 'sector'
        this.largeWidgetChoices = [{text:'Agency Sector', value:'sector'}, {text:'Buyer Cluster', value:'buyer_clusters'}]

        entitySearch(query, 1)
          .then(data => {
            this.largeWidgetData = data;
            this.largeWidgetLoading = false;
            this.largeWidgetDataLoaded = (Math.random() + Date.now()) * Math.random();
          })
          .catch(error => {
            ////////////////////console.log(error);
            //this.errored = true;
            //this.overlay = false;
          });

      }
    },

    getArray(string) {
      if (Array.isArray(string)) {
        return string;
      } else {
        if (string > "") {
          let arr = new Array();
          arr[0] = string;
          return arr;
        } else {
          return string;
        }
      }
    },

    change_group(){
      this.dataLoaded = (Math.random() + Date.now()) * Math.random();
      this.filteredDataLoaded = (Math.random() + Date.now()) * Math.random();
      this.smallWidgetDataLoaded = (Math.random() + Date.now()) * Math.random();
      this.largeWidgetDataLoaded = (Math.random() + Date.now()) * Math.random();
      /* if (this.hasBuyerClusters){
        this.smallWidgetGroup = 'buyer_clusters';
      }
      else{
        this.smallWidgetGroup = 'government'
      } */

      this.change_grp = (Math.random() + Date.now()) * Math.random();
      
    },

    showTenders(args){
      this.tender_filters = [];
      this.tender_filters = args;
      this.tenders = true;
    },

    hideTenders(args){
      //this.tender_filters = [];
      this.tenders = false;
    },

    openWidget(){
        let routeData = this.$router.resolve({name: this.widget_name, query: this.$route.query});
        window.open(routeData.href, '_blank');
    },

    loadGroups(groups){
      this.grouped_tenders = groups;
      ////////////////////console.log("DISTRO GROUPS: ", this.grouped_tenders);
    },

    loadAggs(aggs){
      this.govt_aggs = aggs;
      ////////////////////console.log("DISTRO GROUPS: ", this.govt_aggs);
    },


    getDistributionPercent(type){

      var topGroups = 0;
      ////////////////////console.log("GROUPED TENDERS: ", this.grouped_tenders)
      var other = this.grouped_tenders[this.grouped_tenders.length - 1][type];

      for (var i = 0; i < this.grouped_tenders.length - 1; i++){
        topGroups += this.grouped_tenders[i][type];
      }

      var pc = topGroups / (topGroups + other) * 100;

      return Math.round(pc, 1);

    },


    numFormatter(num) {
      if (num > 999 && num < 1000000) {
        return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
      } else if (num >= 1000000 && num < 1000000000) {
        return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
      } else if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1) + "B"; // convert to M for number from > 1 million
      } else if (num < 900) {
        return num; // if value < 1000, nothing to do
      }
    },

    formattedCurrency: function(amount) {
      ////////////////////console.log("AMOUNT: " + amount);
      if (!isNaN(amount)) {
        return "$" + this.numFormatter(amount);
        //return "$" + Math.round(amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } //else {
      //return null;
      //}
    },

    formattedCurrencyFull: function(amount) {
      ////////////////////console.log("AMOUNT: " + amount);
      if (!isNaN(amount)) {
        return (
          "$" +
          Math.round(amount)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        );
      } //else {
      //return null;
      //}
    },
    
    formatDate(date_value) {
      return moment(date_value).format("MMMM YYYY");
    },

    getPercentageChange(oldNum, newNum) {
      var change = newNum - oldNum;
      var per = (change / oldNum) * 100;
      return per;
    },

  },
  
  computed: {

    categoryOnlyQuery(){
      var qry = JSON.parse(JSON.stringify(this.$route.query));

      delete qry["tender"];
      delete qry["supplier"];
      delete qry["publisher"];
      delete qry["fy"]
      delete qry["contract_start_from"]
      delete qry["contract_start_to"]
      delete qry["expiry_from"]
      delete qry["expiry_to"]

      qry["fy"] = ["FY19","FY20","FY21","FY22"];

      ////////////////////console.log(qry);
      
      return qry;
    },

    getGroup(){
      if (this.group.value === "publisher"){
        return "buyer";
      }else{
        return this.group.value;
      }
    },

    start_year() {
      return this.contracting_activity[0].key_as_string;
    },

    end_year() {
      return this.contracting_activity[this.contracting_activity.length - 1]
        .key_as_string;
    },

    highest_year() {
      let max_count = this.contracting_activity[0].doc_count;
      let max_year = this.contracting_activity[0].key_as_string;

      for (var i = 1; i < this.contracting_activity.length; i++) {
        if (this.contracting_activity[i].doc_count > max_count) {
          max_count = this.contracting_activity[i].doc_count;
          max_year = this.contracting_activity[i].key_as_string;
        }
      }

      return { year: max_year, num_notices: max_count };
    },

    avg_per_year() {
      let num_years = this.contracting_activity.length;
      let total_contracts = 1;
      let total_spend = 0;

      for (var i in this.contracting_activity) {
        total_contracts += this.contracting_activity[i].doc_count;
        total_spend += this.contracting_spend[i].total_amount.value;
      }

      var avg_contracts_per_year = Math.round(total_contracts / num_years, 0);
      var avg_spend_per_year = Math.round(total_spend / num_years, 0);

      return { count: avg_contracts_per_year, value: avg_spend_per_year };
    },

    avg_monthly_spend() {
      var avg = this.getSTLY()[0].totalValue + this.getSTLY()[1].totalValue;
      avg = avg / 24;
      return avg;
    },

    
  }
};
</script>

<style>
.rtl {
  direction: ltr;
}
.small {
  max-height: 300px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.headings {
  font-size: 48px;
  font-weight: 300;
  /*color: #12253a;*/
  /* border-bottom: 3px solid; */
  /*border-color: #12253a;*/
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.body {
  font-size: 21px;
  font-weight: 300;
  color: rgb(18, 37, 58);
}
.divider {
  border-bottom: 2px solid;
  /* border-color: rgb(78, 184, 136); */
  padding-bottom: 30px;
  /* padding-right: 30px;
  padding-left: 30px; */
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 30px;
}

</style>

