<template>
  <v-card class="pr-3 pt-0 pb-0 pl-5" tile min-height="200">
    <v-card-title class="subtitle-2 font-weight-normal pb-0 mb-0 pl-0">
      <v-avatar :color="selected_avatar" size="36" class="mr-2">
        <span class="white--text caption">{{ item.government }}</span>
      </v-avatar>
      <span style="text-decoration: none; color:grey !important;">
        <span
          class="d-inline-block text-truncate"
          style="max-width:250px; min-width:150px;"
        >
          <router-link
            text
            style="text-decoration: none; !important;"
            :to="{
              name: 'entity',
              params: { id: item.publisher_id }
            }"
          >
            <span class="body-2 font-weight-normal">{{ item.publisher }}</span>
          </router-link>
        </span></span
      >
      <div class="flex-grow-1 desktop-nav"></div>
      <span class="desktop-nav">
        <span style="text-decoration: none; color:grey !important;">
          <router-link
            text
            style="text-decoration: none; !important;"
            :to="{
              name: 'supplier',
              params: { id: item.supplier_id }
            }"
            >{{ item.supplier }}</router-link
          ></span
        >
      </span>
      <v-divider
        v-if="item.supplier"
        vertical
        class="my-1 mx-3 desktop-nav"
      ></v-divider>
      <span class="desktop-nav" style="color:grey !important;">{{
        formattedCurrency(item.value)
      }}</span>
    </v-card-title>

    <v-row>
      <v-col cols="12">
        <v-card-title class="pt-0 pb-0 mt-0 mb-1 pl-0">
          {{ item.title }}
        </v-card-title>
        <v-card-title class="pl-0 ml-0 py-1 my-1">
          <span class="subtitle-2 font-weight-bold card-data"
            >Public Entity</span
          >
          <span class="body-2 font-weight-normal"
            ><router-link
              text
              style="text-decoration: none; !important;"
              :to="{
                name: 'entity',
                params: { id: item.publisher_id }
              }"
            >
              <span class="body-2 font-weight-normal">{{
                item.publisher
              }}</span>
            </router-link></span
          >
        </v-card-title>

        <v-card-title
          v-if="item.publisher_division"
          class="pl-0 ml-0 py-1 my-1"
        >
          <span class="subtitle-2 font-weight-bold card-data">Division</span>
          <span class="body-2 font-weight-normal">{{
            item.publisher_division
          }}</span>
        </v-card-title>
        <v-card-title v-if="item.value" class="pl-0 ml-0 py-1 my-1">
          <span class="subtitle-2 font-weight-bold card-data"
            >Contract Value</span
          >
          <span class="body-2 font-weight-normal">{{
            formattedCurrency(item.value)
          }}</span>
        </v-card-title>
        <v-card-title v-if="item.supplier" class="pl-0 ml-0 py-1 my-1">
          <span class="subtitle-2 font-weight-bold card-data">Supplier</span>
          <span class="body-2 font-weight-normal"
            ><router-link
              text
              style="text-decoration: none; !important;"
              :to="{
                name: 'supplier',
                params: { id: item.supplier_id }
              }"
              >{{ item.supplier }}</router-link
            ></span
          >
        </v-card-title>
        <v-card-title
          v-if="item.supplier_company_number"
          class="pl-0 ml-0 py-1 my-1"
        >
          <span class="subtitle-2 font-weight-bold card-data"
            >Supplier ABN</span
          >
          <span class="body-2 font-weight-normal">{{
            item.supplier_company_number
          }}</span>
        </v-card-title>
        <!-- <v-card-title v-if="item.published_date" class="pl-0 ml-0 py-1 my-1">
          <span class="subtitle-2 font-weight-bold card-data">Published</span>
          <span class="body-2 font-weight-normal">{{
            formatDateLong(item.published_date)
          }}</span>
        </v-card-title> -->
        <v-card-title v-if="item.contract_start" class="pl-0 ml-0 py-1 my-1">
          <span class="subtitle-2 font-weight-bold card-data"
            >Contract Start</span
          >
          <span class="body-2 font-weight-normal">{{
            formatDateLong(item.contract_start)
          }}</span>
        </v-card-title>

        <v-card-title v-if="item.expiry_date" class="pl-0 ml-0 py-1 my-1">
          <span class="subtitle-2 font-weight-bold card-data">Expiry</span>
          <span class="body-2 font-weight-normal">{{
            formatDateLong(item.expiry_date)
          }}</span>
        </v-card-title>

        <v-card-title class="pl-0 ml-0 py-1 my-1">
          <span class="subtitle-2 font-weight-bold card-data">Identifier</span>
          <span class="body-2 font-weight-normal">{{ item.recid }}</span>
        </v-card-title>
        <v-card-title class="pl-0 ml-0 py-1 my-1">
          <span class="subtitle-2 font-weight-bold card-data">Category</span>
          <span class="body-2 font-weight-normal"
            >{{ item.category }} </span> <span class="body-2 font-weight-normal" v-if="item.category_code"> ({{ item.category_code }})</span
                        >
        </v-card-title>
        <div
          v-if="item.description"
          class="subtitle-2 font-weight-bold card-data pb-2 pt-2"
        >
          Description
        </div>
        <div v-if="item.description" class="body-2 font-weight-normal pb-2">
          {{ item.description }}
        </div>

        <v-divider class="entity-info mr-3 ml-0 mt-3 mb-1"></v-divider>

        <v-card-actions class="pr-3 pl-0">
          <v-card-title v-if="item.contract_end" class="pl-0 ml-0 py-0 my-0">
            <v-icon color="grey" class="ml-0 mr-1 pl-0">
              mdi-clock
            </v-icon>
            <span class="subtitle-2 font-weight-normal" style="color:grey">{{
              formatDate(item.contract_end)
            }}</span>
          </v-card-title>
          <v-card-title
            v-else-if="item.closing_date"
            class="pl-0 ml-0 py-0 my-0"
          >
            <v-icon color="grey" class="ml-0 mr-1">
              mdi-clock
            </v-icon>
            <span class="subtitle-2 font-weight-normal" style="color:grey">{{
              formatDate(item.closing_date)
            }}</span>
          </v-card-title>

          <div class="flex-grow-1"></div>
          <v-tooltip top dark color="#000000" style="opacity: 0.7;">
            <template v-slot:activator="{ on }">
              <v-btn
                small
                color="#4EB888"
                target="_blank"
                light
                icon
                :to="{ name: 'tender', params: { id: item.id } }"
                v-on="on"
              >
                <v-icon left>mdi-open-in-new</v-icon>
              </v-btn>
            </template>
            <span>Open full record</span>
          </v-tooltip>

          <v-tooltip top dark color="#000000" style="opacity: 0.7;">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                large
                icon
                color="#4EB888"
                light
                :href="item.url"
                target="_blank"
                :disabled="disableIcon(item.url)"
              >
                <v-icon>mdi-web</v-icon>
              </v-btn>
            </template>
            <span>Go to Source</span>
          </v-tooltip>

          <v-tooltip top dark color="#000000" style="opacity: 0.7;">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                large
                color="#FF6A9C"
                light
                icon
                @click="hideProfile()"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Close</span>
          </v-tooltip>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { bus } from "../main.js";
import moment from "moment";

export default {
  props: {
    item: Object,
    selected_avatar: String
  },
  data() {
    return {
      show: null
    };
  },
  methods: {
    hideProfile() {
      this.show = false;
      this.$emit("hideProfile", this.show);
    },
    formatDate(date_value) {
      return moment(date_value).format("MMMM YYYY");
    },

    getLists(tender_id) {
      bus.$emit("get-lists", tender_id);
    },

    formatDateLong(date_value) {
      return moment(date_value).format("DD MMMM YYYY");
    },
    formattedCurrency: function(amount) {
      ////////////console.log("AMOUNT: " + amount);
      if (!isNaN(amount)) {
        return (
          "$" +
          Math.round(amount)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        );
      } //else {
      //return null;
      //}
    },
    getStatusColour(item) {
      if (item.current) {
        return "#b3bc4b";
      } else {
        return "#E0E0E0";
      }
    },

    disableIcon(url) {
      if (!url) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style scoped>
::v-deep .v-expansion-panel-content__wrap {
  padding-left: 0px;
}

.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}

.tender {
  border-left: 5px solid #ff4081 !important;
}
.card-data {
  width: 150px !important;
}

table {
  border-collapse: collapse;
}

th {
  border-bottom: 1px solid #535353;
}

/* .tr:hover {
  transition: opacity 0.4s ease-in-out;
} */

tbody tr:hover {
  background: #535353;

  -webkit-transition: background-color 0.4s; /* For Safari 3.1 to 6.0 */
  transition: background-color 0.4s;
}

.v-select--dense .v-input__control {
  height: 20px !important;
}

.state-line {
  width: 100%;
  text-align: right;
  border-bottom: 1px solid #4eb888;
  line-height: 0.1em;
  margin: 10px 0px 20px;
}
.state-line-span {
  padding: 0 10px;
}

.state {
  margin: 20px;
  overflow: hidden;
  color: gray;
  text-align: right;
  line-height: 1.6em;
}

.state:before {
  display: block;
  float: left;
  margin-top: 0.8em; /* half the line-height */
  border-top: 2px solid silver;
  width: 95%;
  content: "";
}
</style>
