<template>
  <div>
    <v-card width="100%" class="mr-5" flat>
      <v-card-title color="#12253a">
        <v-icon color="#12253a" medium left>mdi-bookmark-outline</v-icon>
        <span class="font-weight-bold subtitle-1">Prospect Lists</span>
        <span>
          <v-btn icon @click="loading = true, getLists()">
            <v-icon color="secondary">mdi-refresh</v-icon>
          </v-btn>
        </span>
        <span>
          <v-btn
            icon
            href="https://docs.tendertrace.com/guide/user-documentation/saving-your-work/prospect-lists"
            target="_blank"
          >
            <v-icon class="material-icons" color="secondary">mdi-help-circle-outline</v-icon>
          </v-btn>
        </span>
      </v-card-title>

      <v-divider></v-divider>
      <v-card flat class="px-2 py-2 mx-2 my-2">
        <v-text-field
          append-icon="mdi-magnify"
          rounded
          v-model="new_list_name"
          dense
          solo-inverted
          hide-details
          flat
          append-outer-icon="mdi-plus-circle-outline"
          label="Enter a new list name..."
          @click:append-outer="addList()"
          @keyup.enter="addList()"
        ></v-text-field>
      </v-card>
      <v-card flat class="px-2 py-2 mx-2 my-2">
        <v-text-field
          rounded
          v-model="search"
          dense
          solo-inverted
          hide-details
          flat
          label="Search lists..."
        ></v-text-field>
      </v-card>
      <v-list v-if="loading">
        <v-skeleton-loader
          v-bind="attrs"
          type="list-item-two-line"
        ></v-skeleton-loader>
        <v-skeleton-loader
          v-bind="attrs"
          type="list-item-two-line"
        ></v-skeleton-loader>
      </v-list>
      <v-list v-else>
        <v-list-item-group
          v-if="filteredLists"
          color="rgb(18, 37, 58)"
        >
          <v-list-item v-for="item in filteredLists" :key="item.id">
            <v-tooltip left dark color="#000000" style="opacity: 0.7;">
              <template v-slot:activator="{ on }">
                <v-list-item-subtitle
                  v-on="on"
                  style="color: #12253a !important;"
                  @click="retrieveList(item)"
                >
                  {{ truncateString(item.name, 40) }}
                </v-list-item-subtitle>
              </template>
              <span class="tooltip">{{ item.name }}</span>
            </v-tooltip>
            <span>
              <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                <template v-slot:activator="{ on }">
                  <v-btn right icon @click="selectedItem = item, deleteList = true" v-on="on">
                    <v-icon medium color="primary">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete List</span>
              </v-tooltip>
            </span>
          </v-list-item>
        </v-list-item-group>

        <v-list-item class="ma-0 pa-0">
          <v-card flat class="ma-0 pa-0">
            <v-card-text color="secondary" class="pl-3 pt-0">
              <v-list-group :value="true" no-action sub-group>
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-2 text-decoration-underline font-weight-bold" color="primary">
                      Prospects Explained
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item class="pa-0">
                  <v-card flat tile class="pl-1">
                    <p style="color:#12253a;">
                      You can create prospect lists and then bookmark any tenders or contracts in those lists. This gives you quick access to the lists and notices whenever you need them.
                    </p>
                    <p style="color:#12253a;">
                      To start bookmarking:
                      <ul>
                        <li>Create a list by typing the name in the search box above and pressing enter or the 
                          <v-icon small color="secondary">mdi-plus-circle-outline</v-icon> button.
                        </li>
                        <li>Run a search for contracts of interest and then click the
                          <v-icon small color="secondary">mdi-bookmark-outline</v-icon> button to bookmark that notice in one or more lists.
                        </li>
                      </ul>
                    </p>
                    <p>
                      <router-link :to="{ name: 'search',
                                          query: {
                                            disposition: ['Contract Notice', 'Grant'],
                                            expiry_from: new Date().getFullYear(),
                                            expiry_to: new Date().getFullYear(),
                                            sort_order: 'asc'
                                          }
                                        }"
                      >Click here to start exploring contracts.</router-link>
                    </p>
                  </v-card>
                </v-list-item>
              </v-list-group>
            </v-card-text>
          </v-card>
        </v-list-item>
      </v-list>
    </v-card>
    <v-dialog v-model="deleteList" max-width="500" absolute>
      <v-card>
        <p class="title mx-auto pa-3">
          <span>Delete saved list: </span> <span v-if="selectedItem"> {{selectedItem["name"]}}</span>?
        </p>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteList = false">
            Cancel
          </v-btn>

          <v-btn
            color="#4EB888"
            text
            @click="(deleteList = false), removeList(selectedItem.id)"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { bus } from "../main.js";
import axios from "axios";
import { getInstance } from "../auth/index.js";
import { getListItemsQuery, deleteList, createNewList } from "./APIManager.js";

export default {
  components: {},
  props: { drawer: Boolean },
  data() {
    return {
      loading: true,
      deleteList: false,
      new_list_name: "",
      search: "",
      menu: false,
      my_lists: [],
      empty_list: false,
      deleted_list: {},
      selectedItem: null
    };
  },

  computed: {
    filteredLists() {
      if (this.my_lists.length > 0){
        return this.my_lists.filter(list => 
          list.name.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      else{
        return this.my_lists;
      }
    }
  },

  mounted() {
    this.getLists();

    bus.$on("refresh_lists", refresh_lists => {
      this.getLists();
    });

  },

  methods: {
    truncateString(str, maxLength) {
      if (str.length <= maxLength) {
        return str;
      } else {
        return `${str.slice(0, maxLength)}...`;
      }
    },
    retrieveList(list) {
      var query = {};
      query = JSON.parse(list.search_query);
      if (!query || query.tender_id.length < 1) {
        var dialog = {
          state: true,
          title: "Empty List",
          msg: "The list '" + list.name + "' is empty. Please add a tender or contract to the list to use it."
        };
        bus.$emit("show_dialog", dialog);
      } else {
        query["list_id"] = list.id;
        query["list_name"] = list.name;
        this.$router.push({ path: "/search", query: query });
        bus.$emit(bus.$emit("hide-side-navs", true));
      }
    },
    addList() {
      if (!this.new_list_name) {
        var dialog = {};
        dialog.msg = "Please enter a valid list name";
        dialog.title = "No data provided";
        dialog.state = true;

        bus.$emit("show_dialog", dialog);
      } else {
        createNewList(this.new_list_name)
          .then(data => {
            this.getLists();
            this.new_list_name = "";
            bus.$emit("update-propsect-lists", true);
          })
          .catch(error => {
            var dialog = {};
            dialog.msg = "We encountered an issue saving your new list. Please try again.";
            dialog.title = "Attempted save failed.";
            dialog.state = true;
          });
      }
    },
    removeList(list_id) {
      this.loading = true;
      this.my_lists = [];

      deleteList(list_id)
        .then(data => {
          this.deleted_list = data;
          this.getLists();
        })
        .catch(error => {
          error;
        });
    },
    cancel() {
      this.menu = false;
      bus.$emit("my-lists-close", this.menu);
    },
    async getLists() {
      const instance = getInstance();
      const accessToken = await instance.getTokenSilently();
      axios
        .get(process.env.VUE_APP_API_LISTS, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        .then(response => {
          this.my_lists = response.data.data;
          this.loading = false;
        })
        .catch(error => {
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style scoped>
.v-application--is-ltr .v-list-group--sub-group .v-list-group__header {
  padding-left: 0px !important;
}

.wrap-text {
  -webkit-line-clamp: unset !important;
}

.tooltip {
  display: inline-block;
  max-width: 360px;
}

.border {
  border: 1px solid rgb(255, 20, 99);
}

.hvr:hover {
  text-decoration: underline !important;
}
</style>
