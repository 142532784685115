<template>
  <div class="ma-0 pa-0">
    <v-row>
      <v-col cols="3" class="px-1">
        <v-select
          v-model="disposition"
          :items="disposition_options"
          label="All Notice Types"
          dense
          outlined
        ></v-select>
      </v-col>
      <v-col cols="7" class="px-1">
        <v-text-field
          v-model="keyword"
          outlined
          flat
          hide-details
          label="Search by Keyword"
          prepend-inner-icon="mdi-filter"
          class="ma-0 pa-0 font-regular"
          style="max-height:40px;"
          dense
          @keyup.enter="run_filter()"
        ></v-text-field>
      </v-col>
      <v-col cols="2" class="px-1">
        <span>
          <v-btn
            class="text-capitalize body-2 desktop-nav"
            color="#4EB888"
            small
            icon
            @click="run_filter()"
            ><v-icon>mdi-magnify</v-icon></v-btn
          >
        </span>
        <span>
          <v-btn
            class="text-capitalize body-2 mobile-nav"
            color="#4EB888"
            small
            icon
            @click="showHideMini(), run_filter()"
            ><v-icon>mdi-magnify</v-icon></v-btn
          >
        </span>
        <span>
          <v-btn
            class="caption text-capitalize"
            color="#2c9dd5"
            icon
            @click="reset_filters()"
            ><v-icon>mdi-rotate-left</v-icon></v-btn
          >
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { bus } from "@/main.js";

//import InboxSearch from "../components/InboxSearch";
import { fetchPublishers, fetchProfile } from "../components/APIManager";

export default {
  components: {
    //InboxSearch
  },

  props: {
    source: String,
    mini: Boolean
  },
  data() {
    return {
      item: 0,
      entities: [],
      entities_temp: [],
      page_num: 1,
      num_pages: 5,
      isLoadng: false,
      disposition: "",
      disposition_options: ["Contract Notice", "Request", "Grant", "Grant Opportunity"],
      keyword: ""
    };
  },

  methods: {
    showHideMini() {
      bus.$emit("mini", true);
    },

    run_filter() {
      //this.search_params["government"] = this.selected_territories;

      //var query = JSON.parse(JSON.stringify(this.$route.query));

      var query = {
        path: "/inbox",
        query: {
          keyword: this.keyword,
          disposition: this.disposition
        }
      };
      this.$router.push(query);
    },

    reset_filters() {
      this.disposition = null;
      this.keyword = null;
      this.$router.push({ path: "/inbox" });
    }
  },
  created() {
    //this.loadPublishers(this.page_num);
  },

  mounted() {},
  computed: {},
  watch: {}
};
</script>
<style>
.wrap-text {
  word-wrap: normal !important;
}

.chip-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}
.nav-list {
  background-color: "#303030";
  border-bottom: 2pt white;
}
.list-item {
  text-decoration: none !important;
  color: "#FFFFFF" !important;
}

.bottom-border {
  border-bottom: 2pt white;
}

.v-input__append-outer .v-icon:hover {
  color: rgb(78, 184, 136);
}
</style>
