<template>
<v-row justify="center">
  <v-dialog v-model="show" fullscreen
      hide-overlay
      transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar
          dark
          color="secondary"
        >
          <v-btn
            icon
            dark
            @click="closeDialog()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Notices</v-toolbar-title>
        </v-toolbar>
        <v-skeleton-loader
          v-if="overlay"
          type="table-tbody"
        ></v-skeleton-loader>
        <results-table
          v-else
          class="pb-3 pt-0 pl-1 pr-0"
          :hit_info="hit_info"
          :tenders="tenders"
          :json_query="json_query"
          @change-page="changePage"
          @updateSort="updateSort"
          @typeFilter="typeFilter"
        />
      </v-card>
  </v-dialog>
</v-row>
</template>

<script>

import { executeSearch } from "./APIManager.js";
import { formattedCurrencyFull, formattedCurrency } from "./Utils.js";
import { formatDate, getMonthYear } from "./Utils";
import ResultsTable from "../components/ResultsTable.vue";
//import Donut from "@/components/Donut.vue";

export default {
  components: {
    ResultsTable
    //Donut
  },

  props: {
    args: [],
    show: Boolean,
  },

  data() {
    return {
      //show: false,
      overlay: false,
      hit_info: {},
      tenders: [],
      page_num: 1,
      close: Boolean,
      json_query: {},
      disposition: ["Contract Notice", "Request", "Grant", "Grant Opportunity"]
    };
  },
  watch: {

    args: {
      handler() {
        this.overlay = true;
        this.tenders = [];
        this.page_num = 1;
        ////////////console.log("this.$route.query: ", this.$route.query);
        this.getData(this.$route.query, this.args);
      },
      deep: true
    },

    $route(to, from) {
      //this.refresh();
    },
  },

  methods: {

    formattedCurrency,
    getMonthYear,
    formatDate,

    changePage(number){
      this.page_num = number;
      this.overlay = true;
      this.getData(this.$route.query, this.args);
    },

    updateSort(sortQuery){

      ////////////console.log("ARGS: ", sortQuery);
      this.page_num = 1;
      this.overlay = true;

      var params = JSON.parse(JSON.stringify(this.args));
      //var sort_params = {};

      //last keys in query
      var last = Object.keys(params[params.length - 1]);

      if (last.indexOf("sort_field") >= 0 || last.indexOf("sort_order") >= 0){
        params[params.length - 1] = sortQuery.sort_field;
        params[params.length] = sortQuery.sort_order;
      }
      else{
        params.push(sortQuery.sort_field);
        params.push(sortQuery.sort_order);
      }
      
      //this.args.push(sortQuery)
      ////////////console.log("ARGS: ", params);
      this.getData(this.$route.query, params);
    },

    typeFilter(types){
      ////////////console.log("ARGS: ", sortQuery);
      this.page_num = 1;
      this.overlay = true;

      this.disposition = types.disposition;

      this.getData(this.$route.query, this.args);
    },

    closeDialog(){
      this.overlay = false;
      this.tenders = [];
      this.page_num = 1;
      this.$emit("close", true);
    },

    getData(qry, params) {
      
      var query = JSON.parse(JSON.stringify(qry));
      var args = JSON.parse(JSON.stringify(params));

      ////////////console.log("ARGS: ", args);
      ////////////console.log("OLD QUERY: ", qry);

      for (var i = 0; i < args.length; i++){
        query[args[i].field] = null;
        query[args[i].field] = args[i].value;

        ////////////console.log("UPDATED ARGS: ", args[i])

        if (args[i].field == "search_terms"){
          query["tender"] = [args[i].value]
        }
      }

      query["disposition"] = this.disposition;

      ////////////console.log("NEW QUERY: ", query);

      executeSearch(query, this.page_num)
        .then(data => {
          this.hit_info = data.hits;
          //this.scroll_id = response.data.hits.sscroll_id;
          this.tenders = data.data;
          //this.total_amount = data.aggregations.total_amount.value;
          //this.search_query = data.plain_text_query;
          this.json_query = data.json_query;
          //this.curr_agg_size = data.json_query.agg_size;
          //this.current_search = data.current_search;

          this.overlay = false;
        })
        .catch(error => {
          ////////////console.log(error);
          this.errored = true;
          this.overlay = false;
        });
    },

  }
};
</script>

<style>

</style>
