<template>
  <div>
    <v-row class="mx-auto px-0 py-0" style="background-color:#f5f5f5">
      <v-col cols="12" class="px-0 pb-0 pt-2">

        <v-card tile>
          <v-card-title style="color:#12253a;">
            
            <span
              class="d-inline-block text-truncate"
              style="max-width:600px; min-width:150px;"
            >
            <v-card-subtitle color="secondary" class="pa-0 font-weight-light">Public Entity</v-card-subtitle>
              <span>{{ entity.name }}</span>
            </span>
              <div class="flex-grow-1 desktop-nav"></div>
            <span>
              <v-avatar
                :color="govAvatarColor(entity.gov_type)"
                size="36"
                class="mr-3 pl-0 ml-0"
              >
                <span class="white--text caption">{{ entity.gov_type }}</span>
              </v-avatar>
            </span>
            <span>
              <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                      <cluster-select :large="false" @clearSelections="selected = [], selectAll=false, selectedEntities = []" :disabled="notSelected" :entityType="'publisher'" :entityIDType="'publisher_id'" :entities="selected" />
                  </span>
                </template>
                <span>Add {{ entity.name }} to a cluster</span>
              </v-tooltip>
            </span>
            <span>
              <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    large
                    icon
                    color="#4EB888"
                    light
                    style="text-decoration: none !important;"
                    :to="{ name: 'search', query: { publisher_id: entity.id } }"
                  >
                    <v-icon>mdi-format-list-bulleted</v-icon>
                  </v-btn>
                </template>
                <span>View all {{ entity.name }} notices</span>
              </v-tooltip>
            </span> 
              <span>
              <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    large
                    icon
                    color="secondary"
                    light
                    style="text-decoration: none !important;"
                    target="_blank"
                    :to="{ name: 'report', query: { publisher_id: entity.id, pie_group_value: 'panel', 
                                                    pie_metric_value: 'total_amount', 
                                                    expiry_group_value: 'supplier', 
                                                    expiry_num_groups: 10,
                                                    scatter_group_value: 'procurement_method',
                                                    scatter_num_groups: 5} }"
                  >
                    <v-icon>mdi-chart-box-outline</v-icon>
                  </v-btn>
                </template>
                <span>View this buyer's insights report</span>
              </v-tooltip>
            </span>
            <span>
              <span>
              <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    large
                    icon
                    color="secondary"
                    light
                    style="text-decoration: none !important;"
                    target="_blank"
                    :to="{ name: 'network', query: { publisher_id: entity.id} }"
                  >
                    <v-icon>mdi-graphql</v-icon>
                  </v-btn>
                </template>
                <span>View this buyer's network</span>
              </v-tooltip>
            </span>
              <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    large
                    icon
                    color="#4EB888"
                    light
                    :href="entity.website"
                    target="_blank"
                    style="text-decoration: none !important;"
                    :disabled="disableIcon(entity.website)"
                  >
                    <v-icon>mdi-web</v-icon>
                  </v-btn>
                </template>
                <span>Go to Website</span>
              </v-tooltip>
            </span>
            <span v-if="entity.metadata && entity.metadata.linkedin">
                  <v-btn
                    large
                    icon
                    color="#4EB888"
                    light
                    :href="'https://'+entity.metadata.linkedin"
                    target="_blank"
                    style="text-decoration: none !important;"
                  >
                    <v-icon>mdi-linkedin</v-icon>
                  </v-btn>
            </span>
            <span v-if="entity.metadata && entity.metadata.twitter">
                  <v-btn
                    large
                    icon
                    color="#4EB888"
                    light
                    :href="'https://'+entity.metadata.twitter"
                    target="_blank"
                    style="text-decoration: none !important;"
                  >
                    <v-icon>mdi-twitter</v-icon>
                  </v-btn>
            </span>
            </v-card-title
          >
          <v-card-subtitle>
              <v-card-text v-if="entity['address']" style="color:#12253a;" class="pa-0 font-weight-normal pt-0 pb-1" ><div>{{entity.address}}</div>
                <div
                  v-if="entity.metadata && entity.metadata.numEmployees"
                  style="color:#12253a;"
                  class="font-weight-strong"
                >
                  <b>STAFF</b> {{getSizeText(entity.metadata.numEmployees.max)}}
                </div>
                <div v-if="entity.metadata" class="font-weight-normal subtitle-2 pt-0 pb-0">
                  <a @click="info_dialog = true"
                    >Description<v-icon color="#41a7d9" class="pl-0 ml-0"
                      >mdi-menu-right</v-icon
                    ></a
                  >
                </div>
              </v-card-text>
              
              <div>
               
              <span v-for="e in industry" :key="e">
                <router-link
                            :to="{ name: 'search', query: { industry: [entity['industry']] } }"
                          >
                  <v-chip v-if="entity['industry']"
                      class="my-0 ml-0 mr-2"
                      color="secondary"
                      label
                      text-color="secondary"
                      small
                      outlined
                >
                  <v-icon small left>
                    mdi-label
                  </v-icon>
                  {{e}}
                </v-chip>
                </router-link>
              </span>
              <span v-for="clstr in entity.clusters" :key="clstr.id">
                <router-link
                            :to="{ name: 'cluster', params: { clusterId: clstr.id } }"
                          >
                    <v-chip v-if="clstr"
                      class="my-0 ml-0 mr-2"
                      color="primary"
                      label
                      text-color="primary"
                      small
                      outlined
                    >
                    <v-icon small left>
                      mdi-hexagon-multiple
                    </v-icon>
                    {{clstr.name}}
                    </v-chip>
                </router-link>
              </span>
              </div>
            
          </v-card-subtitle>

          <v-row v-if="loading_stats == true" class="ma-0 pa-0">
            <v-col cols="12" class="ma-0 pa-0">
              <v-card tile style="color:rgb(78, 184, 136);">
                <v-skeleton-loader
                  class="py-2"
                  type="table-thead"
                ></v-skeleton-loader>
              </v-card>
              <v-dialog
                v-model="loading_stats"
                hide-overlay
                persistent
                width="300"
                lazy          
              >
                <v-card
                  color="secondary"
                  dark
                >
                  <v-card-text>
                    Building the profile...
                    <v-progress-linear
                      indeterminate
                      color="white"
                      class="mb-0"
                    ></v-progress-linear>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>

          <v-row v-else-if="loading_stats == false && date_aggs.length > 0" class="ma-0 pa-0">
            <v-divider></v-divider>
            <v-col cols="12" md="6" class="ma-0 pa-0">
              <v-card flat>
                <v-card-text class="pt-2 pb-0 font-weight-normal" >Active Contracts</v-card-text>
                <v-divider class="mx-3"></v-divider>
                <v-row class="ma-0 pa-0">
                  
                  <v-col cols="4" class="ma-0 pa-0">
                    <v-card tile flat class="py-0 my-0">
                      <v-card-title
                        class="font-weight-normal body-2"
                        style="color:#12253a;"
                        >Value</v-card-title
                      >
                      <v-card-subtitle>
                        <div class="font-weight-medium text-h6" style="color:#12253a;">
                          {{ formattedCurrency(entity.active_contracts.stats.sum) }}
                        </div>
                        <div class="font-weight-normal body-2">
                          <router-link
                            :to="{
                                name: 'search',
                                query: {
                                  publisher_id: entity.id,
                                  disposition: 'Contract Notice',
                                  expiry_date_from: new Date().toISOString().split('T')[0],
                                  expiry_date_to: new Date(new Date().getFullYear() + 50, new Date().getMonth(), new Date().getDate()).toISOString().split('T')[0],
                                  sort_order: 'asc'
                                }
                              }"
                          >
                            {{ entity.active_contracts.stats.count}}
                            contracts</router-link
                          >
                        </div>
                      </v-card-subtitle>
                    </v-card>
                  </v-col>
                
                  <v-col cols="4"  class="ma-0 pa-0">
                    <v-card tile flat class="py-0 my-0">
                      <v-card-title
                        class="font-weight-normal body-2"
                        style="color:#12253a;"
                        >Highest Value</v-card-title
                      >
                      <v-card-subtitle>
                        <div class="font-weight-medium text-h6" style="color:#12253a;">
                          {{ formattedCurrency(entity.active_contracts.stats.max) }}
                        </div>
                        <div class="font-weight-normal body-2" style="color:#12253a;">
                          Contract
                        </div>
                      </v-card-subtitle>
                    </v-card>
                  </v-col>

                  <v-col cols="4"  class="ma-0 pa-0">
                    <v-card tile flat class="py-0 my-0">
                      <v-card-title
                        class="font-weight-normal body-2"
                        style="color:#12253a;"
                        >Avg Contract</v-card-title
                      >
                      <v-card-subtitle>
                        <div class="font-weight-medium text-h6" style="color:#12253a;">
                          {{ formattedCurrency(entity.active_contracts.stats.avg) }}
                        </div>
                        <div class="font-weight-normal body-2" style="color:#12253a;">
                          Active
                        </div>
                      </v-card-subtitle>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" md="6" class="ma-0 pa-0">
              <v-card flat>
                <v-card-text class="pt-2 pb-0 font-weight-normal" >Awards - All Time</v-card-text>
                <v-divider class="mx-3"></v-divider>
                <v-row class="ma-0 pa-0">
                  
                  <v-col cols="4" class="ma-0 pa-0">
                    <v-card tile flat class="py-0 my-0">
                      <v-card-title
                        class="font-weight-normal body-2"
                        style="color:#12253a;"
                        >Value</v-card-title
                      >
                      <v-card-subtitle>
                        <div class="font-weight-medium text-h6" style="color:#12253a;">
                          {{ formattedCurrency(entity.award_total) }}
                        </div>
                        <div class="font-weight-normal body-2">
                          <router-link
                            :to="{ name: 'search', query: { publisher_id: entity.id } }"
                          >
                            {{ entity.num_notices
                            }}<span v-if="entity.num_notices == 10000">+</span>
                            awards</router-link
                          >
                        </div>
                      </v-card-subtitle>
                    </v-card>
                  </v-col>
                
                  <v-col cols="4"  class="ma-0 pa-0">
                    <v-card tile flat class="py-0 my-0">
                      <v-card-title
                        class="font-weight-normal body-2"
                        style="color:#12253a;"
                        >Avg Per Year</v-card-title
                      >
                      <v-card-subtitle>
                        <div class="font-weight-medium text-h6" style="color:#12253a;">
                          {{ formattedCurrency(entity.award_total / date_aggs.length) }}
                        </div>
                        <div class="font-weight-normal body-2" style="color:#12253a;">
                          {{date_aggs.length}} years
                        </div>
                      </v-card-subtitle>
                    </v-card>
                  </v-col>

                  <v-col cols="4"  class="ma-0 pa-0">
                    <v-card tile flat class="py-0 my-0">
                      <v-card-title
                        class="font-weight-normal body-2"
                        style="color:#12253a;"
                        >Avg Contract</v-card-title
                      >
                      <v-card-subtitle>
                        <div class="font-weight-medium text-h6" style="color:#12253a;">
                          {{ formattedCurrency(avg_contract) }}
                        </div>
                        <div class="font-weight-normal body-2" style="color:#12253a;">
                          All contracts
                        </div>
                      </v-card-subtitle>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" md="6" class="ma-0 pa-0">
              <v-card flat>
                <v-card-text class="pt-2 pb-0 font-weight-normal">Awards - Last 12 Months</v-card-text>
                <v-divider class="mx-3"></v-divider>
                <v-row class="ma-0 pa-0">
                <v-col cols="4"  class="ma-0 pa-0">
                  <v-card tile flat class="py-0 my-0">
                    <v-card-title
                      class="font-weight-normal body-2"
                      style="color:#12253a;"
                      >Value</v-card-title 
                    >
                    <v-card-subtitle>
                      <div class="font-weight-medium text-h6" style="color:#12253a;">
                        {{ formattedCurrency(entity.growth.current_year_total) }}
                      </div>
                      <div class="font-weight-normal body-2" style="color:#12253a;">
                        <v-icon :color="growth_format(entity.growth.spend_change_percent).color">
                          {{growth_format(entity.growth.spend_change_percent).icontype}}
                        </v-icon>
                        {{ growth_format(entity.growth.spend_change_percent).change }}%
                      </div>
                    </v-card-subtitle>
                  </v-card>
                </v-col>
                <v-col cols="4"  class="ma-0 pa-0">
                  <v-card tile flat class="py-0 my-0">
                    <v-card-title
                      class="font-weight-normal body-2"
                        style="color:#12253a;"
                      >Avg Contract</v-card-title 
                    >
                    <v-card-subtitle>
                      <div class="font-weight-medium text-h6" style="color:#12253a;">
                        {{ formattedCurrency(entity.growth.curr_yr_avg_contract) }}
                      </div>
                      <div class="font-weight-normal body-2" style="color:#12253a;">
                        <v-icon :color="growth_format(entity.growth.avg_change_percent).color">
                          {{growth_format(entity.growth.avg_change_percent).icontype}}
                        </v-icon>
                        {{ growth_format(entity.growth.avg_change_percent).change }}%
                      </div>
                    </v-card-subtitle>
                  </v-card>
                </v-col> 
                <v-col cols="4"  class="ma-0 pa-0">
                  <v-card tile flat class="py-0 my-0">
                    <v-card-title
                      class="font-weight-normal body-2"
                        style="color:#12253a;"
                      >Contracts</v-card-title 
                    >
                    <v-card-subtitle>
                      <div class="font-weight-medium text-h6" style="color:#12253a;">
                        <router-link
                           :to="{
                              name: 'search',
                              query: {
                                publisher_id_id: entity.id,
                                disposition: 'Contract Notice',
                                contract_start_from: new Date().toISOString().split('T')[0],
                                contract_start_to: new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate()).toISOString().split('T')[0],
                                sort_order: 'asc'
                              }
                            }"
                          >
                            {{ numFormatter(entity.growth.current_year_vol) }}
                        </router-link>
                      </div>
                      <div class="font-weight-normal body-2" style="color:#12253a;">
                        <v-icon :color="growth_format(entity.growth.vol_change_percent).color">
                          {{ growth_format(entity.growth.vol_change_percent).icontype }}
                        </v-icon>
                        {{ growth_format(entity.growth.vol_change_percent).change }}%
                      </div>
                    </v-card-subtitle>
                  </v-card>
                </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" md="6" class="ma-0 pa-0">
              <v-card flat>
                <v-card-text class="pt-2 pb-0 font-weight-normal">Expiring - Next 12 Months</v-card-text>
                <v-divider class="mx-3"></v-divider>
                <v-row class="ma-0 pa-0">
                <v-col cols="4"  class="ma-0 pa-0">
                  <v-card tile flat class="py-0 my-0">
                    <v-card-title
                      class="font-weight-normal body-2"
                        style="color:#12253a;"
                      >Value</v-card-title 
                    >
                    <v-card-subtitle>
                      <div class="font-weight-medium text-h6" style="color:#12253a;">
                        {{ formattedCurrency(entity.expiring_soon.next_year_total) }}
                      </div>
                      <div class="font-weight-normal body-2" style="color:#12253a;">
                        <v-icon :color="growth_format(entity.expiring_soon.spend_change_percent).color">
                          {{growth_format(entity.expiring_soon.spend_change_percent).icontype}}
                        </v-icon>
                        {{ growth_format(entity.expiring_soon.spend_change_percent).change }}%
                      </div>
                    </v-card-subtitle>
                  </v-card>
                </v-col>
                <v-col cols="4"  class="ma-0 pa-0">
                  <v-card tile flat class="py-0 my-0">
                    <v-card-title
                      class="font-weight-normal body-2"
                      style="color:#12253a;"
                      >Avg Contract</v-card-title 
                    >
                    <v-card-subtitle>
                      <div class="font-weight-medium text-h6" style="color:#12253a;">
                        {{ formattedCurrency(entity.expiring_soon.next_yr_avg_contract) }}
                      </div>
                      <div class="font-weight-normal body-2" style="color:#12253a;">
                        <v-icon :color="growth_format(entity.expiring_soon.avg_change_percent).color">
                          {{growth_format(entity.expiring_soon.avg_change_percent).icontype}}
                        </v-icon>
                        {{ growth_format(entity.expiring_soon.avg_change_percent).change }}%
                      </div>
                    </v-card-subtitle>
                  </v-card>
                </v-col> 
                <v-col cols="4"  class="ma-0 pa-0">
                  <v-card tile flat class="py-0 my-0">
                    <v-card-title
                      class="font-weight-normal body-2"
                        style="color:#12253a;"
                      >Contracts</v-card-title 
                    >
                    <v-card-subtitle>
                      <div class="font-weight-medium text-h6" style="color:#12253a;">
                        <router-link
                           :to="{
                              name: 'search',
                              query: {
                                publisher_id: entity.id,
                                disposition: 'Contract Notice',
                                contract_end_from: new Date().toISOString().split('T')[0],
                                contract_end_to: new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate()).toISOString().split('T')[0],
                                sort_order: 'asc'
                              }
                            }"
                          >
                            {{ numFormatter(entity.expiring_soon.next_year_vol) }}
                        </router-link>
                        
                      </div>
                      <div class="font-weight-normal body-2" style="color:#12253a;">
                        <v-icon :color="growth_format(entity.expiring_soon.vol_change_percent).color">
                          {{ growth_format(entity.expiring_soon.vol_change_percent).icontype }}
                        </v-icon>
                        {{ growth_format(entity.expiring_soon.vol_change_percent).change }}%
                      </div>
                    </v-card-subtitle>
                  </v-card>
                </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-tabs class="pt-4 pb-1" color="rgb(18, 37, 58)" show-arrows>
      <v-tabs-slider color="rgb(255, 20, 99)"></v-tabs-slider>
      <v-tab v-if="loading_stats == false && date_aggs.length > 0">
        Analysis
      </v-tab>
      <v-tab v-if="loading_stats == false">
        Contacts
      </v-tab>
      <v-tab  v-if="loading_stats == false && (date_aggs.length > 0 || publish_activity.length > 0)">
        Recent Notices
      </v-tab>
      <v-tab  v-if="loading_stats == false && open_tenders">
        Open Tenders
      </v-tab>
      <v-tab  v-if="loading_stats == false && date_aggs.length > 0" @click="updateSupplierList({'publisher_id': [entity.id]}), getSupplierStats(entity.id)">
        Suppliers
      </v-tab>
      <v-tab  v-if="loading_stats == false && date_aggs.length > 0">
        <span v-if="assessment_loaded" class="pr-1"><v-icon small :color="transparencyColor(assessment.transparency.entity.transparency)">mdi-checkbox-blank-circle</v-icon></span>
        <span>Transparency</span>
      </v-tab>
      <v-tab v-if="entity && entity.entity_amendment_rollup">
        Amendments
      </v-tab>
      <v-tab-item v-if="loading_stats == false && date_aggs.length > 0">
        <v-row class="py-3">
          <v-col
            cols="12"
            sm="7"
            class="pl-md-3 pr-md-1 py-md-1 px-xs-0 py-xs-0"
          >
            <v-card tile flat>
              <spending-timeline
                :date_aggs="date_aggs"
                :entity_aggs="supplier_aggs"
                :expiry_aggs="expiry_aggs"
                :contracting_activity="contracting_activity"
                :expiring_activity="expiring_activity"
                :annual_spend="annual_spend"
                :entity_type="'supplier'"
                :metric_name="'total_amount'"
                :units="'Amount'"
                :title="'Awards Over Time - Top Suppliers'"
              />
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="3"
            class="pl-md-1 pr-md-1 py-md-1 px-xs-0 py-xs-0"
          >
            <v-card tile flat>
              <graph-data-view :chartGroup="'supplier'" />
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="2"
            class="pl-md-1 pr-md-3 py-md-1 px-xs-0 py-xs-0"
          >
            <v-card tile flat>
              <spending-donut
                :entity_type="'supplier'"
                :metric_name="'total_amount'"
                :search="search_terms"
                :entity_data="supplier_aggs"
                :title="'Awards By Supplier'"
              />
            </v-card>
          </v-col>

          <v-col
            v-if="entity.gov_type != 'SA'"
            cols="12"
            sm="7"
            class="pl-md-3 pr-md-1 py-md-1 px-xs-0 py-xs-0"
          >
            <v-card tile flat>
              <spending-timeline
                :date_aggs="date_aggs"
                :expiry_aggs="expiry_aggs"
                :contracting_activity="contracting_activity"
                :expiring_activity="expiring_activity"
                :entity_aggs="category_aggs"
                :annual_spend="annual_spend"
                :entity_type="'major_category'"
                :metric_name="'total_amount'"
                :units="'Amount'"
                :title="'Awards Over Time - Top Categories'"
              />
            </v-card>
          </v-col>
          <v-col
            v-if="entity.gov_type != 'SA'"
            cols="12"
            sm="3"
            class="pl-md-1 pr-md-1 py-md-1 px-xs-0 py-xs-0"
          >
            <v-card tile flat>
              <graph-data-view :chartGroup="'parent_category'" />
            </v-card>
          </v-col>
          <v-col
          v-if="entity.gov_type != 'SA'"
            cols="12"
            sm="2"
            class="pl-md-1 pr-md-3 py-md-1 px-xs-0 py-xs-0"
          >
            <v-card tile flat>
              <spending-donut
                :entity_type="'major_category'"
                :metric_name="'total_amount'"
                :search="search_terms"
                :entity_data="category_aggs"
                :title="'Awards By Category'"
              />
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item v-if="loading_stats == false">
        <v-row style="background-color:#f5f5f5">
          <v-col cols="12" class="pt-3">
            <contact-table
              class="pb-3 pt-0 pl-0 pr-0"
              :publisher_id="$route.params.id"
            />
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item v-if="loading_stats == false && (date_aggs.length > 0 || publish_activity.length > 0)">
        <v-row style="background-color:#f5f5f5">
          <v-col cols="12" class="pt-3">
            <v-skeleton-loader
              v-if="overlay"
              type="table-tbody"
            ></v-skeleton-loader>
            <results-table
              v-else
              class="pb-3 pt-0 pl-0 pr-0"
              :hit_info="hit_info"
              :tenders="tenders"
              :hide_pagination="true"
            />
            <v-overlay absolute="true" :value="overlay">
              <v-progress-circular
                indeterminate
                size="64"
              ></v-progress-circular>
            </v-overlay>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item v-if="loading_stats == false && open_tenders">
        <v-row style="background-color:#f5f5f5">
          <v-col cols="12" class="pt-3">
            <v-skeleton-loader
              v-if="overlay"
              type="table-tbody"
            ></v-skeleton-loader>
            <results-table
              v-else
              class="pb-3 pt-0 pl-0 pr-0"
              :hit_info="hit_info"
              :tenders="open_tenders"
              :hide_pagination="true"
            />
            <v-overlay absolute="true" :value="overlay">
              <v-progress-circular
                indeterminate
                size="64"
              ></v-progress-circular>
            </v-overlay>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item v-if="loading_stats == false && date_aggs.length > 0">
          <v-row style="background-color:#f5f5f5">
            <v-col cols="12" md="12" class="mt-1 pt-3 pb-1">
              <v-card v-if="supplier_stats_loaded" flat tile width="100%">
                <v-card flat tile width="100%">
                  <v-row class="ma-0 pa-0">
                    <v-col cols="2"  class="ma-0 pa-0">
                      <v-card tile flat class="py-0 my-0">
                        <v-card-title
                          class="font-weight-normal body-2"
                            style="color:#12253a;"
                          >Total</v-card-title 
                        >
                        <v-card-subtitle>
                          <div class="font-weight-medium text-h6" style="color:#12253a;">
                            <a 
                              :class="filterColour ('all_entities')"
                              @click="selectedFilter = 'all_entities', updateSupplierList({'publisher_id': [entity.id]})">{{ supplier_stats.total_suppliers }}</a>
                          </div>
                          <div class="font-weight-normal body-2" style="color:#12253a;">
                            Suppliers
                          </div>
                        </v-card-subtitle>
                      </v-card>
                    </v-col>
                    <v-col cols="2"  class="ma-0 pa-0">
                      <v-card tile flat class="py-0 my-0">
                        <v-card-title
                          class="font-weight-normal body-2"
                            style="color:#12253a;"
                          >Current</v-card-title 
                        >
                        <v-card-subtitle>
                          <div class="font-weight-medium text-h6" style="color:#12253a;">
                            <a
                              :class="filterColour ('current_entities')"
                              @click="selectedFilter = 'current_entities', updateSupplierList({'publisher_id': [entity.id], 
                                                          expiry_from: new Date().toISOString().split('T')[0],
                                                          expiry_to: new Date(new Date().getFullYear() + 100, new Date().getMonth(), new Date().getDate()).toISOString().split('T')[0]})">
                                                          {{ supplier_stats.current_suppliers }}                           
                            </a>
                          </div>
                          <div class="font-weight-normal body-2" style="color:#12253a;">
                            Suppliers
                          </div>
                        </v-card-subtitle>
                      </v-card>
                    </v-col>
                    <v-col cols="2"  class="ma-0 pa-0">
                      <v-card tile flat class="py-0 my-0">
                        <v-card-title
                          class="font-weight-normal body-2"
                          style="color:#12253a;"
                          >New - 12 Months</v-card-title 
                        >
                        <v-card-subtitle>
                          <div class="font-weight-medium text-h6">
                            <a 
                              :class="filterColour ('new_entities_ytd')"
                              @click="selectedFilter = 'new_entities_ytd', updateSupplierList({'publisher_id': [entity.id],
                                                          'result_filter': 
                                                            { 'filter_name': 'new_entities_ytd', 
                                                              'filter_target_type': 'publisher', 
                                                              'filter_target_id': [entity.id]}})">
                              {{ supplier_stats.current_year }}                          
                            </a>
                            
                          </div>
                          <div class="font-weight-normal body-2" style="color:#12253a;">
                            <v-icon :color="growth_format(supplier_stats.per_change).color">
                              {{growth_format(supplier_stats.per_change).icontype}}
                            </v-icon>
                            {{ growth_format(supplier_stats.per_change).change }}%
                          </div>
                        </v-card-subtitle>
                      </v-card>
                    </v-col>
                    <v-col cols="2"  class="ma-0 pa-0">
                      <v-card tile flat class="py-0 my-0">
                        <v-card-title
                          class="font-weight-normal body-2"
                            style="color:#12253a;"
                          ><span>Repeat Suppliers</span>
                          <v-tooltip left max-width="250">
                            <template v-slot:activator="{ on }">
                              <v-btn x-small v-on="on" icon target="_blank" href="https://docs.tendertrace.com/guide/user-documentation/transparency-integrity-and-risk">
                                <v-icon  small color="primary">mdi-information-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Suppliers that have contracted more than once with this entity.</span
                              >
                              <br />
                              Click the <v-icon small color="white">mdi-information-outline</v-icon> icon for more information.
                          </v-tooltip>
                          </v-card-title 
                        >
                        <v-card-subtitle>
                          <div class="font-weight-medium text-h6" style="color:#12253a;">
                            <a 
                              :class="filterColour ('repeat_entities')"
                              @click="selectedFilter = 'repeat_entities', updateSupplierList({'publisher_id': [entity.id],
                                                          'result_filter': 
                                                            { 'filter_name': 'repeat_entities', 
                                                              'filter_target_type': 'publisher', 
                                                              'filter_target_id': [entity.id]}})">
                              {{ growth_format(Math.round(supplier_stats.num_suppliers_with_multiple_deals / supplier_stats.total_suppliers * 100)).change }}%                          
                            </a>
                          </div>
                          <div class="font-weight-normal body-2" style="color:#12253a;">
                            {{ supplier_stats.num_suppliers_with_multiple_deals }}
                          </div>
                        </v-card-subtitle>
                      </v-card>
                    </v-col>
                    <v-col cols="2"  class="ma-0 pa-0">
                      <v-card tile flat class="py-0 my-0">
                        <v-card-title
                          class="font-weight-normal body-2"
                            style="color:#12253a;"
                          ><span>Exclusive Suppliers</span>
                          <v-tooltip left max-width="250">
                            <template v-slot:activator="{ on }">
                              <v-btn x-small v-on="on" icon target="_blank" href="https://docs.tendertrace.com/guide/user-documentation/transparency-integrity-and-risk">
                                <v-icon  small color="primary">mdi-information-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Suppliers that have only contracted with this entity.</span
                              >
                              <br />
                              Click the <v-icon small color="white">mdi-information-outline</v-icon> icon for more information.
                          </v-tooltip>
                          </v-card-title 
                        >
                        <v-card-subtitle>
                          <div class="font-weight-medium text-h6" style="color:#12253a;">
                            <a 
                              :class="filterColour ('exclusive_entities')"
                              @click="selectedFilter = 'exclusive_entities', updateSupplierList({'publisher_id': [entity.id],
                                                          'result_filter': 
                                                            { 'filter_name': 'exclusive_entities', 
                                                              'filter_target_type': 'publisher', 
                                                              'filter_target_id': [entity.id]}})">
                            {{ growth_format(Math.round(supplier_stats.sole_source_count / supplier_stats.total_suppliers * 100)).change }}%</a>
                            
                          </div>
                          <div class="font-weight-normal body-2" style="color:#12253a;">
                            {{ supplier_stats.sole_source_count }}
                          </div>
                        </v-card-subtitle>
                      </v-card>
                    </v-col>
                    <v-col cols="2"  class="ma-0 pa-0">
                      <v-card tile flat class="py-0 my-0">
                        <v-card-title
                          class="font-weight-normal body-2"
                          style="color:#12253a;"
                          ><span>SMEs</span>
                          <v-tooltip left max-width="250">
                            <template v-slot:activator="{ on }">
                              <v-btn x-small v-on="on" icon target="_blank" href="https://docs.tendertrace.com/guide/user-documentation/transparency-integrity-and-risk">
                                <v-icon  small color="primary">mdi-information-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>The estimate of SME suppliers is an indication of the percentage of Small and Medium Enterprise suppliers to the entity. It is calculated using automatically curated profile data for suppliers in Tendertrace and validated by humans. 
                              The sample used in this calculation represents {{ growth_format(Math.round(supplier_stats.sme_stats.total_matched / supplier_stats.total_suppliers * 100)).change }}% of the entity's supplier base. </span
                              >
                              <br />
                              Click the <v-icon small color="white">mdi-information-outline</v-icon> icon for more information.
                          </v-tooltip>
                          </v-card-title 
                        >
                        <v-card-subtitle>
                          <div class="font-weight-medium text-h6" style="color:#12253a;">
                            {{ growth_format(supplier_stats.sme_stats.sme_percent).change }}%
                          </div>
                          <div class="font-weight-normal body-2" style="color:#12253a;">
                            Estimated
                          </div>
                        </v-card-subtitle>
                      </v-card>
                    </v-col> 
                  </v-row>
                </v-card>
              </v-card>
            </v-col>
          <v-col cols="12" class="pt-0">
              <profile-members 
                  :startIndex="2" 
                  :profile_id="entity.id"
                  :profile_name="entity.name"
                  :profile_type="'publisher'" 
                  :entity_type="'supplier'"
                  :key="supplier_list_change"
                  :query="supplier_list_query.query"></profile-members>
          </v-col>
          </v-row>
      </v-tab-item>
      <v-tab-item v-if="assessment_loaded">
          <v-row class="py-3">
            <v-col cols="12" md="4" class="pr-md-1 px-xs-0 py-xs-0">
              <v-card tile min-height="500">
                <v-card-title class="py-1">
                  <span
                    class="font-weight-normal subtitle-1 py-2"
                    style="color:#12253a;"
                    >Overall Transparency</span>
                          <v-tooltip left max-width="250">
                            <template v-slot:activator="{ on }">
                              <v-btn x-small v-on="on" icon target="_blank" href="https://docs.tendertrace.com/guide/user-documentation/transparency-integrity-and-risk">
                                <v-icon  small color="primary">mdi-information-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>The Transparency Score is calculated and aggregated based on global best practice public sector procurement integrity and transparency factors. In Australia, procurement transparency standards and obligations vary between jurisdictions. Where possible, these rules have been incorporated into the calculations.</span
                              >
                              <br />
                              Click the <v-icon small color="white">mdi-information-outline</v-icon> icon for more information.
                          </v-tooltip>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-subtitle class="text-center">
                  <span class="pr-1"><v-icon small :color="transparencyColor(assessment.transparency.entity.transparency)">mdi-checkbox-blank-circle</v-icon></span>
                  <span>{{ entity.name }}</span>
                  <span class="pl-3 pr-1"><v-icon small :color="transparencyColor(assessment.transparency.jurisdiction_benchmark.transparency)">mdi-checkbox-blank-circle</v-icon></span>
                  <span>{{ entity.gov_type }}</span>
                  <span class="pl-3 pr-1"><v-icon small :color="transparencyColor(assessment.transparency.country_benchmark.transparency)">mdi-checkbox-blank-circle</v-icon></span>
                  <span>National</span>
                </v-card-subtitle>
                <transparency-bar class="pt-0"
                  :transparency="{'name': entity.name, 'value': assessment.transparency.entity.transparency}"
                  :jurisdictionTransparency="{'name': entity.gov_type, 'value': assessment.transparency.jurisdiction_benchmark.transparency}"
                  :nationalTransparency="{'name': 'National', 'value': assessment.transparency.country_benchmark.transparency}"
                />
              </v-card>
            </v-col>
            <v-col cols="12" md="8" class="pl-md-1 px-xs-0 py-xs-0">
              <v-card tile min-height="500">
                <v-card-title class="py-1">
                  <span
                    class="font-weight-normal subtitle-1 py-2"
                    style="color:#12253a;"
                    >Transparency Factors</span>
                          <v-tooltip left max-width="250">
                            <template v-slot:activator="{ on }">
                              <v-btn x-small v-on="on" icon target="_blank" href="https://docs.tendertrace.com/guide/user-documentation/transparency-integrity-and-risk">
                                <v-icon  small color="primary">mdi-information-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>The Transparency Score is calculated and aggregated based on global best practice public sector procurement integrity and transparency factors. In Australia, procurement transparency standards and obligations vary between jurisdictions. Where possible, these rules have been incorporated into the calculations.</span
                              >
                              <br />
                              Click the <v-icon small color="white">mdi-information-outline</v-icon> icon for more information.
                          </v-tooltip>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-subtitle class="text-center">
                  <span class="pr-1"><v-icon small color="primary">mdi-checkbox-blank-circle</v-icon></span>
                  <span>{{ entity.name }}</span>
                  <span class="pl-3 pr-1"><v-icon small color="secondary">mdi-checkbox-blank-circle</v-icon></span>
                  <span>{{ entity.gov_type }}</span>
                  <span class="pl-3 pr-1"><v-icon small color="rgb(255, 231, 10)">mdi-checkbox-blank-circle</v-icon></span>
                  <span>National</span>
                </v-card-subtitle>
                <transparency-spider
                  :data="{ 'payload': assessment.transparency.entity, 'name': entity.name }"
                  :benchmarkData="{ 'payload': assessment.transparency.jurisdiction_benchmark, 'name': entity.gov_type }"
                  :nationalBenchmarkData="{'payload': assessment.transparency.country_benchmark, 'name': 'National'}"
                />
              </v-card>
            </v-col>
          </v-row>
      </v-tab-item>
      <v-tab-item v-if="entity && entity.entity_amendment_rollup">
            <v-row style="background-color:#f5f5f5">
            <v-col cols="12" class="pt-3">
              <v-card flat tile>
                  <v-card-text class="caption">
                    This analysis compares the relative value of the <b>first amendment</b> of the entity's contracts with the relative value of initial contract amendments in the market along with the relative duration between the start of the contract and the date of the first amendment.
                    Understanding these comparisons can provide an indication of the tendency of the entity to enter into the first amendment of a contract earlier than the norm, or for a higher relative value than the norm.
                  </v-card-text>
                </v-card>
                <amendment-analysis
                    :item="entity"
                    :entity_name="entity.name" 
                    ></amendment-analysis>
            </v-col>
            </v-row>
        </v-tab-item>
    </v-tabs>
    <v-dialog
      v-model="info_dialog"
      max-width="500px"
      max-height="400px"
      hide-overlay
    >
      <v-card>
        <v-card-title class="subtitle-1 grey lighten-2">
          About {{ entity.name }}
        </v-card-title>

        <v-card-text class="py-2">
          {{ entity.metadata.description }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="danger" text @click="info_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  entitySearch,
  histogramSearch,
  fetchNews,
  executeSearch, 
  entityAssessment,
  supplierStats
} from "../APIManager.js";
import TransparencySpider from "../TransparencySpider.vue";
import TransparencyBar from "../TransparencyBar.vue";
import NewsLoader from "../NewsLoader.vue";
import { growth_format, numFormatter, formattedCurrency, govAvatarColor, transparencyColor } from "../Utils.js";
import ProfileMembers from "../ProfileMembers.vue";
import GraphDataView from "../GraphDataView.vue";
import SpendingTimeline from "../SpendingTimeline.vue";
import SpendingDonut from "../SpendingDonut.vue";
import ContactTable from "../entities/ContactTable.vue";
import RecentNews from "../RecentNews.vue";
import ResultsTable from "../ResultsTable.vue";
import ClusterSelect from "../ClusterSelect.vue";
import AmendmentAnalysis from "../AmendmentAnalysis.vue";

export default {
  components: {
    //NewsLoader,
    //EntityOverview,
    SpendingDonut,
    SpendingTimeline,
    GraphDataView,
    ResultsTable,
    ContactTable,
    ClusterSelect,
    TransparencySpider,
    TransparencyBar,
    ProfileMembers,
    AmendmentAnalysis
  },
  props: {
    entity: {},
    current_search: {},
    entity_id: String()
    //results_drawer: Boolean
  },
  data() {
    return {

      selectedFilter: 'all_entities',
      supplier_list_query: {"query": ""},
      expiring_activity: [],
      expiry_aggs: [],
      info_dialog: false,
      hit_info: {},
      tenders: [""],
      overlay: false,
      tabs: null,
      absolute: true,
      entity_panel: -9,
      entity_panels: [],
      panel_clicked: false,
      //current_index: 0,
      index: Number, //use the exp panel index as key so that we can cache the data
      profiles: [],
      mounted: false,
      entity_type: "publisher",
      total_amt_metric: "total_amount",
      search: {},
      category_aggs: [],
      date_aggs: [],
      supplier_aggs: [],
      contracting_activity: [],
      publish_activity:[],
      annual_spend: [],
      avg_contract: Number,
      loading_stats: false,
      industry: [],
      assessment_loaded: false,
      supplier_stats_loaded: false,
      assessment: {},
      supplier_stats: {},
      supplier_list_change: Number
    };
  },

  computed: {
    selected(){
      return [this.entity.id];
    }
    
  },
  

  created() {
    
  },

  mounted() {
    //////console.log("PRZOfILE: ", this.entity["transparency"]);
    var ind = this.entity["industry"];
    if (ind && ind.includes(","))
      this.industry = ind.split(',');
    else
      this.industry[0] = ind;
    this.overlay = true;
    this.loadProfile();
  },

  watch: {
    $route(to, from) {
      //this.reset_aggs();
      //this.loadProfile();
    }

  },

  methods: {

    growth_format,
    numFormatter,
    formattedCurrency,
    govAvatarColor,
    transparencyColor,

    filterColour(name) {
      return this.selectedFilter == name ? 'selected-link' : 'not-selected-link';
    },

    isEmpty(obj) {
      for (const prop in obj) {
        if (Object.hasOwn(obj, prop)) {
          return false;
        }
      }

      return true;
    },

    updateSupplierList(query){
      this.supplier_list_query.query = JSON.parse(JSON.stringify(query));
      this.supplier_list_change = Math.random() + Math.random() + Math.random();
    },

    openNetwork(){
        let routeData = this.$router.resolve({name: 'network', query: this.$route.query});
        window.open(routeData.href, '_blank');
    },

    getSizeText(maxSize){
      if (maxSize <= 1000){
        return "< 1000"
      }
      else if (maxSize <= 5000){
        return "< 5000"
      }
      else{
        return "5000+"
      }

    },

    reset_aggs(){
      this.expiry_aggs = [];
      this.expiring_activity = [];
      this.date_aggs = [];
      this.contracting_activity = [];
      this.publish_activity = [];
      this.annual_spend = [];
      this.category_aggs = [];
      this.supplier_aggs = [];
    },

    loadProfile() {
      
      this.search = { publisher_id: this.$route.params.id, size: 1};

      this.loadTenderAggs();

      this.overlay = false;

      this.loadRecentAwards();

      this.loadOpenTenders();

      this.getEntityAssessment(this.$route.params.id);

    },

    async loadRecentAwards() {
      let results = {};

      //let labels = [];
      results = await executeSearch(
        JSON.stringify({
          publisher_id: this.$route.params.id,
          //disposition: "Contract Notice",
          sort_field: "expiry_date",
          sort_order: "desc",
          size: 10
        })
      );

      //////////////////console.log("TABLE RESULTS: ", results)

      this.tenders = results.data;
      this.hit_info = results.hits;
      
    },

    async loadOpenTenders() {
      let results = {};

      //let labels = [];
      results = await executeSearch(
        JSON.stringify({
          publisher_id: this.$route.params.id,
          expiry_date_from: new Date().toISOString().split('T')[0],
          //expiry_date_to: new Date(new Date().getFullYear() + 100, new Date().getMonth(), new Date().getDate()).toISOString().split('T')[0],
          disposition: "Request",
          sort_field: "expiry_date",
          sort_order: "asc",
          size: 1000
        })
      );

      //////////////////console.log("TABLE RESULTS: ", results)

      this.open_tenders = results.data;
      this.hit_info = results.hits;
      this.overlay = false;
    },

    async loadTenderAggs() {
      this.loading_stats = true;

      try {
          const [histogramData, entityData] = await Promise.all([
              histogramSearch(this.search),
              entitySearch(this.search)
          ]);

          this.expiry_aggs = histogramData.aggregations.expiring.buckets;
          this.expiring_activity = histogramData.aggregations.expiring_activity.buckets;
          this.date_aggs = histogramData.aggregations.contracted.buckets;
          this.contracting_activity = histogramData.aggregations.contracting_activity.buckets;
          this.publish_activity = histogramData.aggregations.publish_activity.buckets;
          this.annual_spend = histogramData.aggregations.contracting_spend.buckets;
          this.avg_contract = histogramData.aggregations.avg_contract_value.value;

          this.category_aggs = entityData.aggregations.major_category.buckets;
          this.supplier_aggs = entityData.aggregations.supplier.buckets;
      } catch (error) {
          //console.error('Error fetching data:', error);
      } finally {
          this.loading_stats = false;
      }
    },

    async getEntityAssessment(id) {

      this.assessment_loading = true;

      entityAssessment(id).then(data => {
        this.assessment = data.data;
        this.assessment_loaded = true;
      });

    },

    async getSupplierStats(id) {
      if (this.supplier_stats_loaded == false){
        try {
          const data = await supplierStats(id);
          this.supplier_stats = data.data.supplier_stats;
          this.supplier_stats_loaded = true;
        } catch (error) {
        }
      }
      else{
        return true;
      }
      
    },

    disableIcon(url) {
      if (!url) {
        return true;
      } else {
        return false;
      }
    },

    toggle() {
      this.watching = !this.watching;
      this.is_saved();
    },

    is_saved() {
      //////////////////console.log("NOTIFY STATUS: " + this.current_search.notify)
      return this.watching;
    }
  }
};
</script>

<style>

.selected-link {
    color: #ff4081 !important;
}

.not-selected-link {
    color: #12253a !important;
}

.chart {
  border-color: #424242 !important;
}

.headingUnderline {
  border-color: aqua !important;
}
</style>
