<template>
  <div class="charts-container">
    <div class="chart">
      <div class="riskChart" ref="chart"></div>
    </div>
  </div>
</template>

<script>
import { contains } from "cheerio/lib/static";
import * as echarts from "echarts";

export default {
  name: "Transparency Spider",
  props: {
    data: {
      type: Object,
      required: true
    },
    benchmarkData: {
      type: Object,
      required: false
    },
    nationalBenchmarkData: {
      type: Object,
      required: false
    },
  },
  data() {
    return {
      chart: null,
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
  methods: {
    initChart() {
      const chartDiv = this.$refs.chart;
      const chartInstance = echarts.init(chartDiv);
      
      const keys = Object.keys(this.data.payload.checks);
      const entityData = keys.map(key => this.data.payload.checks[key].toFixed(1));
      
      var benchmarkData = null;
      var nationalBenchmarkData = null;
      var jurisdictionName = null;
      var nationalName = null;
      var entityName = this.data.name;
      var chartData = null;
      var chartSeries = null;

      if (this.benchmarkData && this.nationalBenchmarkData){
        nationalBenchmarkData = keys.map(key => this.nationalBenchmarkData.payload.checks[key].toFixed(1));
        benchmarkData = keys.map(key => this.benchmarkData.payload.checks[key].toFixed(1));
        jurisdictionName = this.benchmarkData.name;
        nationalName = this.nationalBenchmarkData.name;
        chartData = [{
              name: entityName,
              icon: 'circle',
            },
            {
              name: jurisdictionName,
              icon: 'circle',
            },
            {
              name: nationalName,
              icon: 'circle',
            }];

        chartSeries = [
          {
            name: entityName,
            type: 'radar',
            data: [
              {
                value: entityData,
                name: entityName + ' (%)',
                lineStyle: {
                  color: this.$vuetify.theme.currentTheme.primary
                },
                itemStyle: {
                  color: this.$vuetify.theme.currentTheme.primary,
                }/* ,
                areaStyle: {
                  opacity: 0.1
                } */
              },
            ]
          },
          {
            name: jurisdictionName,
            type: 'radar',
            data: [
              {
                value: benchmarkData,
                name: jurisdictionName + ' (%)',
                lineStyle: {
                  color: this.$vuetify.theme.currentTheme.secondary
                },
                itemStyle: {
                  color: this.$vuetify.theme.currentTheme.secondary,
                }/* ,
                areaStyle: {
                  opacity: 0.1
                } */
              }
            ]
          },
          {
            name: nationalName,
            type: 'radar',
            data: [
              {
                value: nationalBenchmarkData,
                name: nationalName+' (%)',
                lineStyle: {
                  color: this.$vuetify.theme.currentTheme.highlight
                },
                itemStyle: {
                  color: this.$vuetify.theme.currentTheme.highlight,
                }/* ,
                areaStyle: {
                  opacity: 0.1
                } */
              }
            ]
          }
        ];
      }
      else{
        //entityName = this.data.name;
        chartSeries = [
          {
            name: entityName,
            type: 'radar',
            data: [
              {
                value: entityData,
                name: entityName + ' (%)',
                lineStyle: {
                  color: this.$vuetify.theme.currentTheme.primary
                },
                itemStyle: {
                  color: this.$vuetify.theme.currentTheme.primary,
                }/* ,
                areaStyle: {
                  opacity: 0.1
                } */
              },
            ]
          }
        ];
        chartData = [{
              name: entityName,
              icon: 'circle',
            },
            {
              name: jurisdictionName,
              icon: 'circle',
            },
            {
              name: nationalName,
              icon: 'circle',
            }];
      }
      
      //const benchmarkData = keys.map(key => this.benchmarkData.payload.checks[key].toFixed(1));
      //const nationalBenchmarkData = keys.map(key => this.nationalBenchmarkData.payload.checks[key].toFixed(1));

      //const entityName = this.data.name;
      //const jurisdictionName = this.benchmarkData.name;
      //const nationalName = this.nationalBenchmarkData.name

      const indicators = keys.map(key => ({ name: key, max: 100 }));

      chartInstance.setOption({
        color: [this.$vuetify.theme.currentTheme.primary, this.$vuetify.theme.currentTheme.secondary, this.$vuetify.theme.currentTheme.highlight],
        tooltip: {
          trigger: 'item',
          textStyle:{
            fontSize: 11
          }
          //formatter: '{b}: {c}%',
          
        },
        legend: {
          show: false,
          data: chartData
        },
        radar: {
          name: {
            textStyle: {
              color: '#000' // Darker text color
            }
          },
          indicator: indicators
        },
        series: chartSeries
      });

      this.chart = chartInstance;
    }
  }
};
</script>

<style scoped>
.charts-container {
  display: flex;
  justify-content: center;
}

.chart {
  width: 100%;
  /*margin: 10px !important;
  padding: 10px !important;*/
}

.riskChart {
  margin-top: 0px !important;
  margin-left: 0px !important;
  padding-top: 0px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  width: 100% !important;
  height: 446px !important;
}

.dark-text {
  color: #000 !important; /* or any dark color you prefer */
}
</style>
