<template>
  <v-card width="100%" class="mr-5" flat>
    <v-card-title color="#12253a">
      <v-icon color="#12253a" medium left>mdi-view-headline</v-icon>
      <span class="font-weight-bold subtitle-1">My Search Profiles</span>
      <span>
        <v-btn icon @click="loading = true, getSavedSearches()">
          <v-icon color="secondary">mdi-refresh</v-icon>
        </v-btn>
      </span>
      <span>
        <v-btn
          icon
          href="https://docs.tendertrace.com/guide/user-documentation/saving-your-work/search-profiles"
          target="_blank"
        >
          <v-icon class="material-icons" color="secondary">mdi-help-circle-outline</v-icon>
        </v-btn>
      </span>
    </v-card-title>

    <v-divider></v-divider>
    <v-card flat class="px-2 py-2 mx-2 my-2">
      <v-text-field
        append-icon="mdi-magnify"
        rounded
        v-model="search"
        dense
        solo-inverted
        hide-details
        flat
        label="Search profiles..."
      ></v-text-field>
    </v-card>
    <v-list v-if="loading">
      <v-skeleton-loader
        v-bind="attrs"
        type="list-item-two-line"
      ></v-skeleton-loader>
      <v-skeleton-loader
        v-bind="attrs"
        type="list-item-two-line"
      ></v-skeleton-loader>
    </v-list>
    <v-list v-else>
      <v-list-item-group
        v-if="filteredSearches.length > 0"
        v-model="selectedItem"
        color="rgb(18, 37, 58)"
      >
        <v-list-item v-for="item in filteredSearches" :key="item.id">
          <v-tooltip left dark color="#000000" style="opacity: 0.7;">
            <template v-slot:activator="{ on }">
              <v-list-item-subtitle
                v-on="on"
                class="wrap-text hvr"
                style="color: #12253a !important;"
                @click="retrieveSearch(item.search_query, item.id)"
              >
                <span v-if="item.name && item.name != 'unnamed search'">
                  {{ item.name }}
                </span>
                <span v-else>
                  {{ item.plain_text_query }}
                </span>
              </v-list-item-subtitle>
            </template>
            <span class="tooltip">{{ item.plain_text_query }}</span>
          </v-tooltip>
        </v-list-item>
      </v-list-item-group>
      <v-list-item class="ma-0 pa-0">
        <v-card flat class="ma-0 pa-0">
          <v-card-text color="secondary" class="pl-3 pt-0">
            <v-list-group :value="true" no-action sub-group>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title
                    class="subtitle-2 text-decoration-underline font-weight-bold"
                    color="primary"
                  >
                    Profiles Explained
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item class="pa-0">
                <v-card flat tile class="pl-1">
                  <p style="color:#12253a;">
                    You can save any searches that you run. This means you can
                    quickly rerun those searches when you log back in.
                    Tendertrace will automatically monitor those searches and
                    notify you of any new matches on that search. All new
                    matches will be available in your Tendertrace Inbox.
                  </p>
                  <p style="color:#12253a;">
                    Saved searches also save the search tab you are on. So for
                    example, if you were on the Leaderboard tab when you saved
                    the search profile, clicking on the saved profile will run
                    the search and also take you to the tab you were on when you
                    saved the profile initially.
                  </p>
                  <p style="color:#12253a;">
                    To start saving searches:
                    <ul>
                      <li>
                        Run a search for contracts or tenders of interest.
                      </li>
                      <li>
                        In the filter description section of the screen, click
                        the button labeled "Save Search" to have Tendetrace
                        start monitoring that search on your behalf.
                      </li>
                    </ul>
                  </p>
                  <p>
                    <router-link
                      :to="{
                        name: 'search',
                        query: {
                          disposition: ['Contract Notice', 'Grant'],
                          expiry_from: new Date().getFullYear(),
                          expiry_to: new Date().getFullYear(),
                          sort_order: 'asc'
                        }
                      }"
                      >Click here to start exploring.</router-link
                    >
                  </p>
                </v-card>
              </v-list-item>
            </v-list-group>
          </v-card-text>
        </v-card>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { bus } from "../main.js";
import axios from "axios";
import { getInstance } from "../auth/index.js";

export default {
  components: {},
  props: { drawer: Boolean },
  data() {
    return {
      loading: true,
      menu: false,
      search: "",
      saved_searches: []
    };
  },

  computed: {
    filteredSearches() {
      if (this.saved_searches.length > 0){
        return this.saved_searches.filter(search =>
          (search.name && search.name !== "unnamed search"
            ? search.name
            : search.plain_text_query
          )
            .toLowerCase()
            .includes(this.search.toLowerCase())
        );
      }
      else{
        return this.saved_searches;
      }
      
    }
  },

  mounted() {
    this.getSavedSearches();

    bus.$on("refresh_lists", refresh_lists => {
      this.getSavedSearches();
    });

    bus.$on("search-complete", search_complete => {
      this.getSavedSearches();
    });
  },

  methods: {
    retrieveSearch(query, search_id) {
      var qry = query;
      qry["search_id"] = search_id;

      this.$router.push({ path: "/search", query: qry });
      bus.$emit(bus.$emit("hide-side-navs", true));
    },

    cancel() {
      this.menu = false;
      bus.$emit("favourites-close", this.menu);
    },

    async getSavedSearches() {
      const instance = getInstance();
      const accessToken = await instance.getTokenSilently();

      axios
        .get(process.env.VUE_APP_API_SEARCHES, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        .then(response => {
          this.saved_searches = response.data.saved_searches;
          this.loading = false;
          return this.saved_searches;
        })
        .catch(error => {
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style scoped>
.v-application--is-ltr .v-list-group--sub-group .v-list-group__header {
  padding-left: 0px !important;
}

.wrap-text {
  -webkit-line-clamp: unset !important;
}

.tooltip {
  display: inline-block;
  max-width: 360px;
}

.border {
  border: 1px solid rgb(255, 20, 99);
}

.hvr:hover {
  text-decoration: underline !important;
}
</style>
