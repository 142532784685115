<template>
      <v-card flat>
        <v-list
      subheader
      two-line
      flat
    >
      <v-subheader>Select which jurisdiction to display in the chart</v-subheader>

    <v-card v-if="loading" flat>
      <v-overlay :value="loading" absolute :opacity="opacity">
          <v-progress-circular
            indeterminate
            size="36"
            color="primary"
          ></v-progress-circular>
        </v-overlay>
    </v-card>
    <v-card v-else flat class="px-2 py-2 mx-2 my-2" height="320" style="flex-grow: 1 !important; overflow:auto !important;">
        <v-list-item-group
        multiple
      >
        <v-list-item v-for="govt in govts" :key="govt.id">
            <v-list-item-action>
              <v-checkbox
                v-model="selectedGovs"
                :value="govt.id"
                color="primary"
              ></v-checkbox>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{govt.name}}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      </v-card>
      
    </v-list>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="$emit('closeGovFilter', true)">
            Cancel
          </v-btn>
          <v-btn color="primary" text @click="$emit('selectedGovs', selectedGovs), $emit('closeGovFilter', true)">
            Apply
          </v-btn>
        </v-card-actions>
      </v-card>
</template>

<script>
import { getClusters, updateClusters, addCluster } from "./APIManager.js"
import { bus } from "../main.js";

export default {
  
  props: {
    current_buyer_flag: Boolean,
    disabled: Boolean,
    entityType: String,
    entityIDType: String,
    entities:[]

  }, 
  data: () => ({
    newClusterName: "",
    clusters: [],
    govts: [{name: "Australian (Federal) Government", id: "AUS"},
            {name: "New South Wales", id: "NSW"}, 
            {name: "Victoria", id: "VIC"}, 
            {name: "South Australia", id: "SA"}, 
            {name: "Western Australia", id: "WA"}, 
            {name: "Tasmania", id: "TAS"}, 
            {name: "Queensland", id: "QLD"}, 
            {name: "Australian Capital Territory", id: "ACT"}, 
            {name: "Northern Territory", id: "NT"}],
    loading:false,
    clusterEntities: [],
    selectedGovs:[],
    fav: true,
    menu: false,
    message: false,
    hints: true,
    active: true,
    years: [2016, 2025],
    year_min: 2016,
    year_max: 2025,
    keyword_chips: [],
    keyword_items: [],
    selected_types: [],
    include_publisher: true,
    expiryRangeMenu: false,
    expiryRange: []
  }),

  mounted(){
    //this.loadClusters();

  },

  computed: {

  },

  methods: {
    emitCloseWindow(){
        this.$emit('closeGovFilter', true);
    },

    async postCluster(){
        this.loading = true;
        
        addCluster(this.newClusterName, this.entityType).then(results => {
            this.clusters = results.data;
            for (var i in this.clusters){
                this.clusters[i].search_query = JSON.parse(this.clusters[i].search_query)
            }
            this.loading = false;
        });
    },

    async saveSettings(){

        this.loading = true;
        var targetClusters = [];

        if (!this.selectedGovs){
            for (var i in this.clusters){
                targetClusters.push(this.clusters[i].id);
            }
        }
        else{
            targetClusters = this.selectedGovs;
        }

        updateClusters(targetClusters, this.entityType, this.entities).then(results => {
            
            this.clusters = results.data;
            for (var i in this.clusters){
                this.clusters[i].search_query = JSON.parse(this.clusters[i].search_query)
            }
            this.loading = false;
        });

    },

    async loadClusters(){

      this.loading = true;

      getClusters(this.entityType).then(results => {
        this.clusters = results.data;
        //////////////console.log("CLUSTER QUERY: ", JSON.parse(this.clusters[0].search_query));
        for (var i in this.clusters){
            this.clusters[i].search_query = JSON.parse(this.clusters[i].search_query)
        }
        
        this.loading = false;
        //////////////console.log("loadPublishers data: ", results);
      });
    },

    exists(cluster_entities){
        
        var exists = cluster_entities.some(item => this.entities.includes(item));
        return exists;

    },
    
    partialExists(cluster_entities){
        let totalEntities = this.entities.length;
        let numMatches = 0;
        let partialMatch = false;

        for (let i = 0; i < this.entities.length; i++){
            if (cluster_entities.indexOf(this.entities[i]) === -1) {
                partialMatch = true;
                break;
            }
            else{
                numMatches++;
            }
        }

        if (numMatches == totalEntities){
            partialMatch = false; //is a fullmatch so indeterminate should be false
        }
      
        return partialMatch;
    },

    year() {
      var d = new Date();
      return d.getFullYear();
    },

    remove_kw(item) {
      this.keyword_chips.splice(this.keyword_chips.indexOf(item), 1);
      this.keyword_chips = [...this.keyword_chips];
    },

    clear_expiryRange() {
      this.expiryRange = [];
    },

    update_search() {
      let additional_filters = {
        keywords: this.keyword_chips,
        expiry_from: this.expiryRange[0],
        expiry_to: this.expiryRange[1],
        type: this.selected_types,
        include_publisher: this.include_publisher
      };

      bus.$emit("update-filter", additional_filters);
    }
  }
};
</script>
