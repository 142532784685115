<template>
    <v-card class="pa-0 ma-0" tile flat>
    <v-card-title class="pt-2 pb-1 pl-1" style="font-weight:normal !important;">
      <span
        style="color:secondary; font-weight:bold;"
      >
      <div class="subtitle-1 font-weight-light">
        {{subtitle}}
      </div>
      </span>
      <div class="flex-grow-1"></div>
      <span>
        <v-btn-toggle v-model="buttons" tile dense class="py-1 pr-0">
            <v-tooltip bottom dark color="#000000" style="opacity: 0.7;">
            <template v-slot:activator="{ on }">
               <v-btn
                    v-on="on"
                    outlined
                    class="text-capitalize"
                    color="secondary"
                    @click="infobox=!infobox, resetButtons()"
                    style="justify-content:center !important;"
                    ><v-icon>mdi-information-outline</v-icon>
                </v-btn>
            </template>
            <span>About this chart</span>
          </v-tooltip>
          <v-tooltip bottom dark color="#000000" style="opacity: 0.7;">
            <template v-slot:activator="{ on }"> 
                <v-btn
                v-on="on"
                :disabled="disable_open_in_new"
                class="text-capitalize"
                outlined
                color="secondary"
                @click="resetButtons(), openWidget()"
                style="justify-content:center !important;"
                active-class="no-toggle"
                ><v-icon>mdi-open-in-new</v-icon>
            </v-btn>
            </template>
            <span>Open full size chart</span>
          </v-tooltip>
          <v-tooltip bottom dark color="#000000" style="opacity: 0.7;">
            <template v-slot:activator="{ on }"> 
                <v-btn
                    v-on="on"
                    class="text-capitalize"
                    outlined
                    color="secondary"
                    @click="getChartData(), sharebox=!sharebox, resetButtons(), updateBoards()"
                    style="justify-content:center !important;"
                    active-class="no-toggle"
                    ><v-icon>mdi-playlist-plus</v-icon>
                </v-btn>
            </template>
            <span>Add chart to a custom report</span>
            </v-tooltip>
            <v-tooltip bottom dark color="#000000" style="opacity: 0.7;">
                <template v-slot:activator="{ on }"> 
                    <v-btn
                        v-on="on"
                        :disabled="disableDataView()"
                        outlined
                        class="text-capitalize"
                        color="secondary"
                        @click="showData=!showData, resetButtons()"
                        style="justify-content:center !important;"
                        ><v-icon>mdi-table</v-icon>
                    </v-btn>
                </template>
                <span>View the aggregated data behind the chart</span>
            </v-tooltip>
            <v-tooltip bottom dark color="#000000" style="opacity: 0.7;">
                <template v-slot:activator="{ on }"> 
                    <v-btn
                        v-on="on"
                        :disabled="disableDataView()"
                        outlined
                        class="text-capitalize"
                        color="secondary"
                        @click="viewData(), resetButtons()"
                        style="justify-content:center !important;"
                        ><v-icon>mdi-view-list</v-icon>
                    </v-btn>
                </template>
                <span>View the individual contracts behind the chart</span>
            </v-tooltip>
            <v-tooltip bottom dark color="#000000" style="opacity: 0.7;">
                <template v-slot:activator="{ on }">
                <v-btn
                    v-on="on"
                    :disabled="disable_download_data"
                    outlined
                    class="text-capitalize"
                    color="secondary"
                    @click="downloadImage(), resetButtons()"
                    style="justify-content:center !important;"
                    ><v-icon>mdi-download</v-icon>
                </v-btn>
            </template>
                <span>Download as image</span>
            </v-tooltip>

        </v-btn-toggle> 
      </span>
    </v-card-title>
   
    <v-dialog v-model="infobox" hide-overlay width="700" absolute>
        <v-card tile>
        <v-card-title>What data is this chart based on?
            <div class="flex-grow-1"></div> <v-btn icon @click="infobox=!infobox"><v-icon style="font-weight:thin;" medium>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4">
            <span>This chart represents data for</span>
                <span class="font-weight-bold" style="color:rgb(255, 20, 99);">
                  {{ total_result }}
                </span>
                notice<span v-if="total_result > 1">s</span
                ><span v-if="total_amount > 0">
                  with a total awarded value of
                  <span class="font-weight-bold" style="color:rgb(255, 20, 99);">
                    {{ totalAmt }}</span
                  >
                </span>
                where
                <span>{{ search_query }}</span>
        </v-card-text>
        </v-card>
    </v-dialog>
    <v-dialog v-model="sharebox" hide-overlay max-width="1200" absolute style="overflow-x:'hidden' !important;">
        <v-card tile>
        <v-card-title>Add chart to a custom report
            <div class="flex-grow-1"></div> <v-btn icon @click="sharebox=!sharebox"><v-icon medium>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card tile flat class="mx-auto pa-5" max-width="1080px">
            <v-row justify="center">
            <v-col
              cols="6"
              class="pb-0 d-flex child-flex"
            >
              <v-combobox
                v-model="selected_board"
                :items="storyboards"
                :loading="isLoading"
                item-value="id"
                :search-input.sync="search"
                item-text="plainTextTitle"
                label="Select a report"
                outlined
                dense
                class="pb-0"
                return-object
              >
              </v-combobox>
            </v-col>
              <v-col
              cols="6"
              class="pb-0"
            >

              <v-btn color="primary" :disabled="!saveButtonActive" @click="saveChart(), updateBoards(), sharebox=!sharebox">Save chart to report</v-btn>
              </v-col>

              <v-col cols="12" class="pt-0 d-flex child-flex">
                <story :changed="embed_obj_changed" :key="embed_obj_changed" :data="embed_object" />
              </v-col>
            </v-row>
        </v-card>
        </v-card>
    </v-dialog>
    <v-dialog v-if="data" v-model="showData" hide-overlay absolute>
      <data-view :data="data.data" :headers="data.headers" @close-view="showData=false" />
    </v-dialog>
     <v-snackbar
            v-model="snackbar"
            top
          >
            Copied to clipboard!
            <v-btn dark text @click="snackbar = false">
              Close
            </v-btn>
          </v-snackbar> 
    </v-card>
</template>

<script>

import { formattedCurrencyFull } from "../components/Utils"
import DataView from "../components/DataView.vue";
import { fetchStoryboards, addStory, addStoryboard} from "../components/APIManager"
import Story from "../components/Story.vue";

export default {

  components: {
    DataView,
    Story
  },

  props: {
    title: String,
    subtitle: String,
    total_result: Number,
    total_amount: Number,
    search_query: String,
    widget_name: String,
    data: Array,
    chart_settings: [],
    embed_object: Object,
    embed_obj_changed: Number,
    disable_open_in_new:{
      type: Boolean,
      defaul: false
    },
    disable_show_data:{
      type: Boolean,
      defaul: false
    },
    disable_download_data:{
      type: Boolean,
      defaul: false
    },

  },
  data() {
    return {
      isLoading: false,
      showData: false,
      buttons: null,
      snackbar: false,
      infobox:false,
      sharebox: false,
      iframe_snippet: String,
      local_obj: null,
      storyboards: [],
      selected_board: null,
      resetChart:Boolean,
      new_board:null,
      search: null,
      saveButtonActive: false
    };
  },

  watch: {
    embed_obj_changed(){
    },

    search (val, prev) {
        if (this.selected_board || this.search != null){
          this.saveButtonActive = true;
        }
        else{
          this.saveButtonActive = false;
        }
      }
  },

  computed: {
    totalAmt(){
      return formattedCurrencyFull(this.total_amount);
    }
  },

  async mounted(){
    //this.updateBoards()
  },

  methods: {

      getChartData(){
        this.$emit('embed', true)
      },

      async updateBoards(){
        this.isLoading = true;
        this.search = null;
        this.saveButtonActive = false;

        var data = await fetchStoryboards();
        this.storyboards = data.storyboards;

        for (var i in this.storyboards){
          this.storyboards[i]["plainTextTitle"] = this.stripHTML(this.storyboards[i]["title"]);
        }
        this.isLoading = false;

      },

      addItem(name) {
        const newItem = {plainTextTitle: name, title: name, description:'This report needs an overview', id: (Math.random()*Math.random())}

        this.storyboards.push(newItem)
        return newItem
      },

      onChange(entry) {
        if (typeof entry === 'string' && entry.trim()) {
          const item = this.storyboards.find(item => item.name === entry)
          if (item) {
            this.item = item
          } else {
            this.item = this.addItem(entry)
          }
        }
      },

      async saveChart(){

        ////////////console.log("SELECTED REPORT: ", this.selected_board);
        ////////////console.log("PAYLOAD TO SAVE: ", this.embed_object);

        let data = {};
        data["title"] = this.embed_object.title;
        data["description"] = this.embed_object.description;
        data["chart_type"] = this.embed_object.chart_type;
        data["payload"] =  this.embed_object.payload;

        if (!this.selected_board || (this.search != this.selected_board["plainTextTitle"])){
          //this.selected_board = this.addItem(this.search);
          await addStoryboard({title: this.search, description:'This report needs an overview', story: data})
        }
        else{
          await addStory(this.selected_board.id, data)
          ////////////console.log("EXISTING REPORT: ", this.selected_board);
        }
      },

      stripHTML(text){
        let strippedText = text.replace(/(<([^>]+)>)/gi, "");
        return strippedText;
      },

      disableDataView(){
        if (this.data || !this.disable_show_data) {
          return false;
        }
        else{
          return true;
        }

      },
    
      formatCurr(amount){
          return formattedCurrencyFull(amount)
      },

      downloadImage(){
          this.$emit("downloadImage", true);
      },

      resetButtons() {
        this.$nextTick(() => {
          this.buttons = null
        })
      },

      viewData(){
        var query = this.$route.query;
        query["viewIndex"] = 0;

        let routeData = this.$router.resolve({name: 'search', query: query});
        window.open(routeData.href, '_blank');
      },

      openWidget(){
        var qry = JSON.parse(JSON.stringify(this.$route.query));
        for (var i in this.chart_settings){
          qry[this.chart_settings[i]["field_name"]] = this.chart_settings[i]["value"]
        }
        let routeData = this.$router.resolve({name: this.widget_name, query: qry});
        window.open(routeData.href, '_blank');
      },

      async copy(type) {
        /* Get the text field */

        ////////////console.log(this.buttons);

        var copyText = String;

        var qry = JSON.parse(JSON.stringify(this.$route.query));
        for (var i in this.chart_settings){
          qry[this.chart_settings[i]["field_name"]] = this.chart_settings[i]["value"]
        }

        //let routeData = this.$router.resolve({name: this.widget_name, query: this.$route.query});
        let routeData = this.$router.resolve({name: this.widget_name, query: qry});

        if (type == 'object'){
          ////////////console.log(this.embed_object);
          copyText = JSON.stringify(this.embed_object);
        }

        if (type == 'iframe'){
            copyText = '<iframe src='+ '"'+window.location.origin +routeData.href+'"' +'frameborder="0" style="height: 600px; width: 1230px"></iframe>';
        }else if (type == 'link'){
            copyText = window.location.origin + routeData.href;
        }

        try {
            await navigator.clipboard.writeText(copyText);
        } catch (e) {
            //console.error(e);
        }

        /* Alert the copied text */
        this.snackbar = true;
    },

    

  }
}
</script>
<style scoped>
.no-toggle {
  background: white !important;
  opacity: 100% !important;
}
</style>
