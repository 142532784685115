<template>
  <div class="pa-0 ma-0 fill-height" style="background-color:#f5f5f5" fluid>
    <v-content>
      <v-container class="fill-height pl-1 pr-0 mx-1 py-2 mt-0" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" class="pt-8">
            <div
              style="max-width:1200px; display: block; margin:auto !important;"
            >
              <p
                style="text-align:center;"
                class="display-1 font-weight-medium"
              >
                Buyer Directory
              </p>
              <p style="text-align:center;" class="body-1 font-weight-normal">
                Buyer profiles offer insights into a specific entity, including
                award trends over time, the top categories the buyer has directed spending towards, and the suppliers to the entity.
              </p>
              <v-row align="center" justify="center">
                <v-col cols="3" style="max-width:400px;" class="pb-0">
                  <v-combobox
                    class="caption font-weight-normal"
                    v-model="selected_territories"
                    :items="territory_options"
                    label="Government"
                    item-text="name"
                    multiple
                    @change="updateFilters"
                    :menu-props="{ maxWidth: '400', maxHeight: '400' }"
                    clearable
                    solo
                    dense
                    outlined
                    :return-object="false"
                    @click:clear="reset_govt()"
                  >
                  <template v-slot:selection="{ attrs, item, select, selected}">
                    <v-chip
                      color="rgb(255, 20, 99)"
                      small
                      label
                      v-bind="attrs"
                      :input-value="selected"
                      close
                      @click="select"
                      @click:close="remove_govt(selected)"
                      ><span class="chip-label">{{ item }}</span></v-chip
                    >
                  </template>
                  </v-combobox>
                </v-col>
                <v-col class="pl-5 pb-0" cols="9" style="max-width:800px;">
                    <entity-profile-search :rounded="false"
                                        :color="'#f5f5f5'"
                                        :solo="true"
                                        :dark="false"
                                        :govts="selected_territories"
                                        @clearFilter="reset_filters"
                                        @filter-entities="filterEntities" />
                  
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" class="pt-0 mb-0 pb-0 pr-0">
            <entity-table />
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </div>
</template>

<script>
import EntityProfileSearch from "../components/EntityProfileSearch";
import EntityTable from "../components/entities/EntityTable";

import { bus } from "../main.js";
import { fetchProfile, fetchGovTypes } from "../components/APIManager";
import { getInstance } from "../auth";

export default {
  components: {
    EntityProfileSearch,
    EntityTable
  },
  props: {
    publisher: {}
  },
  data() {
    return {
      drawer: true,
      mini: true,
      search: { publisher_id: Number },
      current_entity: {},
      global_search: false,
      view_index: null,

      selected: "snapshot",
      current_view: "snapshot",
      watching: false,
      filters: {},
      filters_changed: 0,
      selected_territories: [],
      territory_options: []
      //results_drawer: null
    };
  },

  created() {
  },

  mounted() {
    //check that Auth0 has loaded
    const instance = getInstance();

    //////console.log("loading auth0");

    if (instance.isAuthenticated){
        fetchGovTypes().then(results => {
          
          //this.loadingGovts = true;
          this.territory_options = results.gov_types;
          ////////console.log(this.territory_options);
          var qry = JSON.parse(JSON.stringify(this.$route.query));
          if (qry["government"]){
            this.selected_territories = qry["government"];
          }
          //this.loadingGovts = false;
        });
      }
    
  },

  methods: {

    remove_govt(item) {
      this.selected_territories.splice(this.selected_territories.indexOf(item), 1);
      this.selected_territories = [...this.selected_territories];
      if (this.selected_territories.length == 0){
        this.selected_territories = []
      }

    },

    reset_govt(){
      var qry = JSON.parse(JSON.stringify(this.$route.query));
      //delete qry["term"];
      delete qry["page"];
      delete qry["government"];
      this.selected_territories = [];
      this.$router.push({ name: "entities", query: qry });
    },

    reset_filters(){
      var qry = JSON.parse(JSON.stringify(this.$route.query));
      delete qry["term"];
      delete qry["page"];

      /* if (this.selected_territories.length == 0){
        delete qry["government"];
        this.selected_territories = [];
      } */
      
      this.$router.push({ name: "entities", query: qry });
    },

    updateFilters(){
      //////console.log(this.selected_territories)
      var qry = JSON.parse(JSON.stringify(this.$route.query));
      qry["government"] = this.selected_territories;
      qry["page"] = 1;
      //this.$router.push({ name: "entities", query: qry });
    },

    clearFilter(val){
      delete this.filters["term"];
      this.filters_changed = this.filters_changed + 1;
    },

    filterEntities(val){
      this.filters["term"] = val.key;
      this.filters_changed = this.filters_changed + 1;
    },

    hideFilter() {
      let show = false;
      bus.$emit("hideFilters", show);
    },

    isEmpty(obj) {
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
      }
      return true;
    }
  },

  watch: {

  },

  computed:{
    optionsLoaded(){
      return this.territory_options.length > 0;
    }
  }
}
</script>
