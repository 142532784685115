<template>
    <!-- <div :style="'height:'+chartHeight+'; width:'+chartWidth+';'" :id="entity_id"></div> -->

  <div :id="entity_id" :style="reportSize"></div>
  
</template>
<script>
// Import echarts
import * as echarts from 'echarts';

export default {

components: {
  },

 props:{
    entity_id: Number,
    profile_name: String,
    entity_name: String,
    chartHeight:{
      type: String,
      default: "200px"
    },
    chartWidth: String(),
    cardWidth: String(),
    cardHeight: String(),
    data:[]
 },
  data() {
    return {
        options: Object,
        reportLoading: Boolean,
        datalength: Number
    };
  },


  watch: {
   
    entity_id(){
      if (this.data) {
        this.buildChart(this.data);
        }
    }
  },

  created(){

  },

  async mounted(){
    
    if (this.data) {
      this.buildChart(this.data);
    }
    
  },

  computed: {
    reportSize() {
      return 'margin-top: 0px !important;padding-top: 0px !important;padding-left: 0px !important;padding-bottom: 0px !important;width: 100% !important;height: '+this.chartHeight+' !important;'

    }
  },

  methods:{

    async buildChart(data){
        this.datalength = data.length;
        
        const xAxisData = data.map(item => item['Financial Year']);
        const profileIdCounts = data.map(item => item['Profile ID Counts']);
        const allContractsCounts = data.map(item => item['All Contracts Counts']);
        const profileIdTotals = data.map(item => item['Profile ID Totals']);
        const allContractsTotals = data.map(item => item['All Contracts Totals']);
        const comparison = data.map(item => item['Comparison']);

        // Create echarts instance
        // Create echarts instance
        const myChart = echarts.init(document.getElementById(this.entity_id));

        // Set options for the stacked bar chart
        const option = {
        /* title: {
            text: 'Tender Comparison by Financial Year',
            subtext: 'Based on Totals',
            left: 'center'
        }, */
        color : [
            "#ff1463",
            "#5405FF",
            "#12253a",
            "#BFAB07",
            "#5405FF",
            "#fb8c00",
            "#ff1463",
            "#b71c1c",
            "#ff937b"
        ],
        tooltip: {
            trigger: 'axis',
            formatter: function (params){

                function convert (num){
                    if (num > 999 && num < 1000000) {
                    num = (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
                    } else if (num >= 1000000 && num < 1000000000) {
                    num = (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
                    } else if (num >= 1000000000) {
                    num = (num / 1000000000).toFixed(1) + "B"; // convert to M for number from > 1 million
                    }
                    return num;
                }

                function getColor(number){
                    if (number.includes("-")) return "red";
                    else if (number.includes("+")) return "green";
                    else return "black";
                }

                var infocard = [];

                for (var i in params){
                    let val = params[i].value;

                    if (params[i].seriesType == "bar"){
                        val = "$" + convert(val);
                    }

                    //let iconStyle = '"background-color:'+params[i].data.color + 'flex-shrink: 0; width: 14px;height: 14px;border-radius: 50%;margin-right: 12px;display: inline; align-items: center;justify-content: center;fill: #fff;border: 2px solid #fff;box-shadow: 0 2px 4px rgb(0 0 0 / 25%);"';
                    //iconStyle = iconStyle + 'background-color:'+params[i].data.color;
                    var colorSpan = color => '<span style="display:inline-block;margin-right:5px;border-radius:50%;width:14px;height:14px;border: 2px solid #fff;box-shadow: 0 2px 4px rgb(0 0 0 / 25%);background-color:' + color + '"></span>';
                    infocard[i] = [
                    '<tr><td style="text-align: left;">',
                        //'<div style="color: #12253a; font-size:12; font-family:Roboto; font-weight:normal;">',
                        ''+colorSpan(params[i].color)+'',
                        '<span style="color: #12253a; font-size:11px; font-family:Roboto; font-weight:normal; padding-right:10px;">' + params[i].seriesName + '</span>',
                    '</td>',
                    '<td style="text-align: left;">',
                        '<span style="color: #12253a; font-size:11px; font-family:Roboto; font-weight:bold; padding-right:10px;">' + val + '</span>',
                    '</td>',
                
                        //'<span style="color: #12253a; font-size:12; font-family:Roboto; font-weight:bold; float: right;"> (' + params[i].data.percent + ')</span></div>',
                    ].join('');
                    
                };

                //add header
                infocard.unshift('<table align="left"><thead><tr><th style="text-align: left;padding-right:10px; font-size:11px; font-family:Roboto;">' + params[i].name + '</th><th style="text-align: left;padding-right:10px; font-size:11px; font-family:Roboto;">Total</th></tr></thead>');
                infocard.push('</table>');

                return infocard.join('');
            },
        },
        grid: {
            left: "16px",
            bottom: "0px",
            top: "40px",
            containLabel: true
        },
        xAxis: {
            type: 'category',
            data: xAxisData,
            axisLabel: {
                fontSize: 10,
                },
            axisTick:{
                show: false,
                interval: "auto"
            },

        },
        yAxis: [
            {

            axisTick:{
                show: false,
                interval: "auto"
            },
            splitNumber: 4,
            splitLine: {
                show: false
            },
            type: 'value', // Left y-axis for totals
            axisLabel: {
                formatter : (s) => {
                    function convert (num){
                        if (num > 999 && num < 1000000) {
                            num = (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
                        } else if (num >= 1000000 && num < 1000000000) {
                            num = (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
                        } else if (num >= 1000000000) {
                            num = (num / 1000000000).toFixed(1) + "B"; // convert to M for number from > 1 million
                        }

                        return num;
                    }
                    ////////////console.log(s);

                    return '{valueStyle|' + '$'+ convert(s) + '}';
                    
                },
                rich: {
                    valueStyle: {
                        // Make yearly text more standing out
                        color: '#000',
                        fontWeight: 'normal',
                        fontSize: 10
                    }
                }
            }
            },
            {
                type: 'value', // Right y-axis for counts
                axisLabel: {
                    formatter : (s) => {

                        return '{valueStyle|' + s + '}';
                        
                    },
                    rich: {
                        valueStyle: {
                            // Make yearly text more standing out
                            color: '#000',
                            fontWeight: 'normal',
                            fontSize: 10
                        }
                    }
                }
            }
        ],
        legend: {
            show: true,
            icon: "circle",
            orient: 'horizontal',
            textStyle: {
                fontSize: 9
            },
            padding: [5, 5, 10, 5],
            type: "scroll",
            left: 10,
            //top:'center',
            //bottom: 0
            },
        
        series: [
            {
            name: '(#) '+this.profile_name,
            type: 'line',
            yAxisIndex: 1, // Use right y-axis for counts
            data: profileIdCounts
            },
            {
            name: '(#) Other Contracts',
            type: 'line',
            yAxisIndex: 1, // Use right y-axis for counts
            data: allContractsCounts
            },
            {
            name: '($) '+this.profile_name,
            type: 'bar',
            stack: 'totals',
            data: profileIdTotals
            },
            {
            name: '($) Other Contracts',
            type: 'bar',
            stack: 'totals',
            data: allContractsTotals
            }
        ]
        };

        // Set the options for the chart
        myChart.setOption(option);
        this.reportLoading = false;  
    }

  }

  
};
</script>

<style>
.reportPie {
  margin-top: 0px !important;
  padding-top: 0 !important;
  width: 100% !important;
  height: 500px !important;
  /* padding: 0px, 0px, 0px, 0px !important; */
}
</style>