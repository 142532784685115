/* eslint-disable */
<template>
  <div class="fill-height">
    <v-container class="my-0 pt-0 mx-0 px-2">
        <div class="rtl">
          <v-speed-dial
            v-model="fab"
            fixed
            bottom
            :direction="direction"
            :open-on-hover="hover"
            :transition="transition"
            :close-on-content-click="false"
          >
            <template v-slot:activator>
              <v-btn
                v-model="fab"
                color="primary"
                dark
                fab
              >
                <v-icon v-if="fab">
                  mdi-close
                </v-icon>
                <v-icon v-else>
                  mdi-menu
                </v-icon>
              </v-btn>
            </template>
            <v-tooltip right dark color="#000000" style="opacity: 0.7;">
            <template v-slot:activator="{ on, attrs }"> 
                <v-btn
                fab
                dark
                small
                v-on="on"
                v-bind="attrs"
                color="secondary"
                @click="openWidget()"
                ><v-icon>mdi-open-in-new</v-icon>
            </v-btn>
            </template>
            <span>Open Report in Separate Window</span>
          </v-tooltip>
          <v-tooltip right color="#000000" style="opacity: 0.7;">
            <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              small
              color="secondary"
              v-on="on"
              v-bind="attrs"

              @click="$vuetify.goTo('#expiring', options)"
            >
              <v-icon>mdi-clock</v-icon>
            </v-btn>
            </template>
            
            <span>{{expiring_contracts_title}}</span>
            </v-tooltip>
            
            <v-tooltip right color="#000000" style="opacity: 0.7;">
            <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              small
              color="secondary"
              v-on="on"
              v-bind="attrs"
    
              @click="$vuetify.goTo('#distribution', options)"
            >
              <v-icon>mdi-chart-bubble</v-icon>
            </v-btn>
            </template>
            <span>{{contract_distribution_title}}</span>
            </v-tooltip>
            <v-tooltip right color="#000000" style="opacity: 0.7;">
            <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              small
              color="secondary"
              v-on="on"
              v-bind="attrs"

              @click="$vuetify.goTo('#tree', options)"
            >
              <v-icon>mdi-chart-tree</v-icon>
            </v-btn>
            </template>
            <span>{{gov_spend_title}}</span>
            </v-tooltip>
            <v-tooltip right color="#000000" style="opacity: 0.7;">
              <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                dark
                small
                color="secondary"
                v-on="on"
                v-bind="attrs"

                @click="$vuetify.goTo('#breakdown', options)"
              >
                <v-icon>mdi-chart-donut</v-icon>
              </v-btn>
              </template>
            <span>{{market_share_title}}</span>
            </v-tooltip>
            <v-tooltip right color="#000000" style="opacity: 0.7;">
            <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              small
              color="secondary"
              v-on="on"
              v-bind="attrs"

              @click="$vuetify.goTo('#comparison', options)"
            >
              <v-icon>mdi-compare</v-icon>
            </v-btn>
            </template>
            
            <span>{{spend_comparison_title}}</span>
            </v-tooltip>
            <v-tooltip right color="#000000" style="opacity: 0.7;">
              <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                dark
                small
                color="secondary"
                v-on="on"
                v-bind="attrs"

                @click="$vuetify.goTo('#quarterly-trends', options)"
              >
                <v-icon>mdi-finance</v-icon>
              </v-btn>
              </template>
            <span>{{quarterly_trends_title}}</span>
            </v-tooltip>
            <v-tooltip right color="#000000" style="opacity: 0.7;">
              <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                dark
                small
                color="secondary"
                v-on="on"
                v-bind="attrs"

                @click="$vuetify.goTo('#market-activity', options)"
              >
                <v-icon>mdi-signal</v-icon>
              </v-btn>
              </template>
            <span>Market Activity</span>
            </v-tooltip>
            <!-- <v-tooltip right color="#000000" style="opacity: 0.7;">
              <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                dark
                small
                color="secondary"
                v-on="on"
                v-bind="attrs"

                @click="$vuetify.goTo('#home', options)"
              >
                <v-icon>mdi-home</v-icon>
              </v-btn>
              </template>
            <span>Back to Top</span>
            </v-tooltip> -->
          </v-speed-dial>
          <!-- <v-btn fab fixed bottom color="primary" @click="$vuetify.goTo('#comparison', options)">
            Comparison
         </v-btn> -->
        </div>
      </v-container>
    <v-row justify="center" class="pt-0 pb-1 px-3">
      <v-col cols="12" class="pl-0 pr-0 py-0">
        <v-dialog
          v-model="reportLoading"
          hide-overlay
          persistent
          width="300"
          lazy          
        >
          <v-card
            color="secondary"
            dark
          >
            <v-card-text>
              Analysing the data. Hang tight.
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-card class="py-5" tile>
          
          <v-card v-if="!reportLoading" tile flat max-width="1100" class="mx-auto">
            <v-card-title id="home" color="secondary" class="pl-0">
              <span><v-img
                  src="@/assets/img/tendertrace_logo_0C1A28.png"
                  max-height="20"
                  contain
                  class="pr-0"
                ></v-img></span>
              <span class="pl-0 headline" style="color: #12253a; font-weight:bold;">Insights</span>
              <span class="pl-0" style="float: right;">
                <v-tooltip bottom dark color="#000000" style="opacity: 0.7;">
                    <template v-slot:activator="{ on }"> 
                        <v-btn
                        icon
                        tile
                        small
                        v-on="on"
                        color="secondary"
                        @click="openWidget()"
                        style="justify-content:center !important;"
                        ><v-icon small>mdi-open-in-new</v-icon>
                    </v-btn>
                    </template>
                    <span>Open in separate window</span>
                  </v-tooltip>
              </span>
            </v-card-title>
            <v-card class="justify-center" flat>
              <v-divider class="mb-5"></v-divider>
              <v-row align="center" class="pt-0">
                <v-col cols="12" id="market-activity">
                  <v-card-text class="pt-0">
                    <v-card-title class="pt-0 pb-1 pl-1 d-flex justify-space-between align-center" style="font-weight:normal !important;">
                      <span
                        style="color:secondary; font-weight:bold;"
                      >
                      <div>
                        Market Activity
                      </div>
                      <!-- <div class="subtitle-1">
                        Cumulative growth in contract awards over the past 24 months
                      </div> -->
                      </span>
                      <a href="https://docs.tendertrace.com/guide/user-documentation/analysing-search-results/insights-dashboard#market-activity" target="_blank">
                          <v-icon class="material-icons" color="secondary" right>mdi-help-circle-outline</v-icon>
                      </a>
                    </v-card-title>
                    <div class="body-1 pt-0 pb-0 pl-1">
                    {{market_activity_description}}
                    </div>
                  </v-card-text>
                </v-col>
                <v-col cols="12" class="pt-0 pl-5 mt-0 pb-10">
                    <v-card
                      tile
                      flat
                    >
                    <search-analysis-widget :title="market_activity_title" :description="market_activity_description" :total_amount="total_amount" :total_records="total_records" :plain_text_query="plain_text_query" />
                  </v-card>
               </v-col>
                
              </v-row>
              <v-divider class="mb-5"></v-divider>
              <v-row align="center" class="pt-0">
                <v-col cols="12" id="quarterly-trends">
                  <v-card-text class="pt-0">
                    <v-card-title class="pt-0 pb-1 pl-1 d-flex justify-space-between align-center" style="font-weight:normal !important;">
                      <span
                        style="color:secondary; font-weight:bold;"
                      >
                      <div>
                        {{quarterly_trends_title}}
                      </div>
                      <!-- <div class="subtitle-1">
                        Cumulative growth in contract awards over the past 24 months
                      </div> -->
                      </span>
                      <a href="https://docs.tendertrace.com/guide/user-documentation/analysing-search-results/insights-dashboard#participant-awards-over-time" target="_blank">
                          <v-icon class="material-icons" color="secondary" right>mdi-help-circle-outline</v-icon>
                      </a>
                    </v-card-title>
                    <div class="body-1 pt-0 pb-0 pl-1">
                      {{ quarterly_trends_description }}
                    </div>
                  </v-card-text>
                </v-col>
                <v-col cols="12" class="pt-0 pl-5 mt-0 pb-10">
                    <v-card
                      tile
                      flat
                    >
                    <quarterly-trends @aggs-loaded="loadAggs" @showTenders="showTenders" :title="quarterly_trends_title" :description="quarterly_trends_description" />
                  </v-card>
               </v-col>
              </v-row>
              <v-divider class="mb-5"></v-divider>
              <v-row align="center" class="pt-0">
                <v-col cols="12" id="comparison">
                  <v-card-text class="pt-0">
                    <v-card-title class="pt-0 pb-1 pl-1 d-flex justify-space-between align-center" style="font-weight:normal !important;">
                      <span
                        style="color:secondary; font-weight:bold;"
                      >
                      <div>
                        {{spend_comparison_title}}
                      </div>
                      <!-- <div class="subtitle-1">
                        Cumulative growth in contract awards over the past 24 months
                      </div> -->
                      </span>
                      <a href="https://docs.tendertrace.com/guide/user-documentation/analysing-search-results/insights-dashboard#participant-awards-over-time" target="_blank">
                          <v-icon class="material-icons" color="secondary" right>mdi-help-circle-outline</v-icon>
                      </a>
                    </v-card-title>
                    <div class="body-1 pt-0 pb-0 pl-1">
                    {{spend_comparison_description}}
                    </div>
                  </v-card-text>
                </v-col>
                <v-col cols="12" class="pt-0 pl-5 mt-0 pb-10">
                    <v-card
                      tile
                      flat
                    >
                    <spend-comparison @aggs-loaded="loadAggs" @showTenders="showTenders" :title="spend_comparison_title" :description="spend_comparison_description" />
                  </v-card>
               </v-col>
              </v-row>
              <v-divider class="mb-5"></v-divider>
              <v-row align="center" class="pt-0">
                <v-col cols="12" id="breakdown">
                  <v-card-text v-if="govt_aggs.length > 0" class="pt-0">
                    <v-card-title class="pt-0 pb-1 pl-1 d-flex justify-space-between align-center" style="font-weight:normal !important;">
                      <span
                        style="color:secondary; font-weight:bold;"
                      >
                      <div>
                        {{market_share_title}}
                      </div>
                      <!-- <div class="subtitle-1">
                        Cumulative growth in contract awards over the past 24 months
                      </div> -->
                      </span>
                      <a href="https://docs.tendertrace.com/guide/user-documentation/analysing-search-results/insights-dashboard#market-share" target="_blank">
                          <v-icon class="material-icons" color="secondary" right>mdi-help-circle-outline</v-icon>
                      </a>
                    </v-card-title>
                    <div class="body-1 pt-0 pl-1" v-html="market_share_description">
                    </div>
                  </v-card-text>
                  <v-skeleton-loader v-else
                    v-bind="attrs"
                    type="list-item-three-line"
                  ></v-skeleton-loader>
                </v-col>
                <v-col cols="12" class="pt-0 mt-0 pb-10 pl-5">
                  <spending-pie @showTenders="showTenders" :title="market_share_title" :description="market_share_description"
                  />
                </v-col>
              </v-row>
              <v-divider class="mb-5"></v-divider>
                <v-row align="center" class="pt-0">
                  <v-col cols="12" id="tree" class="pt-0">
                    <v-card-text>
                      <v-card-title class="pt-0 pb-1 pl-1 d-flex justify-space-between align-center" style="font-weight:normal !important;">
                        <span
                          style="color:secondary; font-weight:bold;"
                        >
                        <div>
                          {{gov_spend_title}}
                        </div>
                        <!-- <div class="subtitle-1">
                          Cumulative growth in contract awards over the past 24 months
                        </div> -->
                        </span>
                        <a href="https://docs.tendertrace.com/guide/user-documentation/analysing-search-results/insights-dashboard#government-spending-breakdown" target="_blank">
                          <v-icon class="material-icons" color="secondary" right>mdi-help-circle-outline</v-icon>
                      </a>
                      </v-card-title>
                      <div class="body-1 pt-0 pb-0 pl-1">
                      {{gov_spend_description}}
                      </div>
                    </v-card-text>
                  </v-col>
                
                <v-col cols="12" class="pt-0 mt-0 pl-5 pb-10">
                  <spending-tree @aggs-loaded="loadAggs" @showTenders="showTenders" :title="gov_spend_title" :description="gov_spend_description" />
                </v-col>
              </v-row>
              <v-divider class="mb-5"></v-divider>
              <v-row align="center" class="pt-0">
                <v-col cols="12" id="distribution">
                  <v-card-text v-if="grouped_tenders.length > 0" class="pt-0">
                    <v-card-title class="pt-0 pb-1 pl-1 d-flex justify-space-between align-center" style="font-weight:normal !important;">
                      <span
                        style="color:secondary; font-weight:bold;"
                      >
                      <div>
                        {{contract_distribution_title}}
                      </div>
                      <!-- <div class="subtitle-1">
                        Cumulative growth in contract awards over the past 24 months
                      </div> -->
                      </span>
                      <a href="https://docs.tendertrace.com/guide/user-documentation/analysing-search-results/insights-dashboard#contract-distribution" target="_blank">
                          <v-icon class="material-icons" color="secondary" right>mdi-help-circle-outline</v-icon>
                      </a>
                    </v-card-title>
                    <div class="body-1 pt-0 pl-1" v-html="contract_distribution_description">
                    </div>
                  </v-card-text>
                  <v-skeleton-loader v-else
                    v-bind="attrs"
                    type="list-item-three-line"
                  ></v-skeleton-loader>

                </v-col>
                <v-col cols="12" class="pt-0 pl-5 mt-0 pb-10">
                    <v-card 
                      tile
                      flat
                    >
                    <spending-scatter 
                      @distribution-loaded="loadGroups" :title="contract_distribution_title" :description="contract_distribution_description" /> 
                  </v-card>
               </v-col>
              </v-row>
              <v-divider class="mb-5"></v-divider>
              
              <v-row align="center" class="pt-0">
                <v-col cols="12" id="expiring">
                  <v-card-text class="pt-0">
                    <v-card-title class="pt-0 pb-1 pl-1 d-flex justify-space-between align-center" style="font-weight:normal !important;">
                      <span
                        style="color:secondary; font-weight:bold;"
                      >
                      <div>
                        {{expiring_contracts_title}}
                      </div>
                      <!-- <div class="subtitle-1">
                        Cumulative growth in contract awards over the past 24 months
                      </div> -->
                      </span>
                      <a href="https://docs.tendertrace.com/guide/user-documentation/analysing-search-results/insights-dashboard#expiring-contracts" target="_blank">
                          <v-icon class="material-icons" color="secondary" right>mdi-help-circle-outline</v-icon>
                      </a>
                    </v-card-title>
                    <div class="body-1 pb-0 pl-1" v-html="expiring_contracts_description">
                    </div>
                  </v-card-text>
                </v-col>
                <v-col cols="12" class="pt-0 mt-0 pl-5 pb-10">
                    <results-expiry-cycle @aggs-loaded="loadAggs" @showTenders="showTenders" :title="expiring_contracts_title" :description="expiring_contracts_description" />
               </v-col>
              </v-row>
            </v-card>
          </v-card>
        </v-card>
      </v-col>
    </v-row>

   <dialog-table :show="tenders" :args="tender_filters" @close="hideTenders" />
  </div>
</template>

<script>
import { bus } from "../main.js";
import moment from "moment";

import * as easings from 'vuetify/lib/services/goto/easing-patterns'
import SearchAnalysisWidget from "../components/SearchAnalysisWidget.vue";
import SpendingPie from "../components/SpendingPie.vue";
import { groupBySearch, histogramSearch } from "./APIManager.js";
import SpendingTree from "../components/SpendingTree";
import SpendingScatter from "../components/SpendingScatter";
import SpendComparison from './SpendComparison.vue';
import QuarterlyTrends from './QuarterlyTrends.vue';
import ResultsExpiryCycle from "../components/ResultsExpiryCycle.vue";
import DialogTable from "../components/DialogTable.vue"

export default {
  props: {
    
  },
  components: {
    SpendComparison,
    SpendingPie,
    //ResultsByYear,
    //ReportLineChart,
    SpendingTree,
    SpendingScatter,
    ResultsExpiryCycle,
    DialogTable,
    SearchAnalysisWidget,
    QuarterlyTrends
  },

  data() {
    return {

      total_amount: 0,
      total_records: 0,

      reportLoading: Boolean,
      direction: 'top',
      fab: true,
      fling: false,
      hover: false,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: 'slide-y-reverse-transition',

      grouped_tenders: [],

      show_entity_amount: null,
      show_supplier_amount: null,
      show_category_amount: null,
      item_details: { show: false, record: {} },
      saved: [{ tender_id: "", value: false }],
      total_contracts: "",
      current_contracts: 0,
      expiring_soon: 0,
      avg_contract_value: 0,
      largest_contract: { supplier: "", procurer: "", contract_value: 0 },
      total_contract_value: 0,
      largest_supplier: { supplier: "", total_amount: 0 },
      largest_procurer: { agency: "", total_amount: 0 },
      most_active_govt: { govt: "", total_contracts: 0 },
      updated: 0,
      yoy_data: {},
      grouping: "category",
      group_sort_options: [{text: "Amount", value:"group_total"}, {text: "Volume", value:"group_count"}],
      group_sort_by: {text: "Amount", value:"group_total"},
      tree_metric_options: [{text: "Amount", value:"total_amount"}, {text: "Volume", value:"doc_count"}],
      tree_metric: {text: "Amount", value:"total_amount"},
      tree_group_options: [{text: "Category", value:"category"}, {text: "Buyer", value:"publisher"}, {text: "Supplier", value:"supplier", data: this.suppliers_by_gov}],
      tree_group: {text: "Buyer", value:"publisher", data: this.publishers_by_gov},
      group_options: [{text: "Category", value:"category"}, {text: "Buyer", value:"publisher"}, {text: "Supplier", value:"supplier"}, {text: "Government", value:"government"}],
      group: {text: "Supplier", value:"supplier"},
      bubbleLoading: false,
      date_range: [],
      gov_supplier_tree: true,
      easing: 'easeInOutCubic',
      easings: Object.keys(easings),
      widget_name: "report",

      contracting_activity: [],
      contracting_spend: [],
      contracted_per_month: [],
      govt_aggs: [],
      tenders: false,
      tender_filters: [],
      plain_text_query: "",
      clickOutsideEnabled: false,
      active: false
   };
  },


  watch: {
    $route(to, from) {
      this.getHistogramData(this.$route.query);
      this.show_entity_amount = false;
      this.show_supplier_amount = false;
      this.show_category_amount = false;
    },
    

  },
  created() {
    //////////////console.log("SEARCH TERMS: ", JSON.stringify(this.search_terms))
  },

  async mounted() {

    // Apply a @click.stop to the .v-speed-dial__list that wraps the default slot
    this.$el.querySelector('.v-speed-dial__list').addEventListener('click', (e) => {
        e.stopPropagation()
    })

    this.getHistogramData(this.$route.query);
    
    this.show_entity_amount = false;
    this.show_supplier_amount = false;
    this.show_category_amount = false;
    
  },

  methods: {

    onCloseConditional (e) {
        return this.clickOutsideEnabled;
    },

    onClickOutside () {
        this.active = false;
    },

    showTenders(args){
      this.tender_filters = [];
      this.tender_filters = args;
      this.tenders = true;
    },

    hideTenders(args){
      //this.tender_filters = [];
      this.tenders = false;
    },

    openWidget(){
        let routeData = this.$router.resolve({name: this.widget_name, query: this.$route.query});
        window.open(routeData.href, '_blank');
    },

    loadGroups(groups){
      this.grouped_tenders = groups;
      //////////////console.log("DISTRO GROUPS: ", this.grouped_tenders);
    },

    loadAggs(aggs){
      this.govt_aggs = aggs;
      //////////////console.log("DISTRO GROUPS: ", this.govt_aggs);
    },

    get_govt_name(name){
      switch (name) {
        case "VIC":
          return "Victoria";
        case "NSW":
          return "New South Wales";
        case "SA":
          return "South Australia";
        case "QLD":
          return "Queensland";
        case "NT":
          return "The Northern Territory";
        case "WA":
          return "Western Australia";
        case "TAS":
          return "Tasmania";
        case "AUS":
          return "the Federal Government";
        case "FED":
          return "the Federal Government";
        case "DEF":
          return "Defence";
        case "ACT":
          return "the Australian Capital Territory";
      }
    },

    setDateRange(){

      //////////////console.log("START YEAR " + this.start_year + " END YEAR " + this.end_year);
      for (var i = this.start_year; i <= this.end_year; i++){
        this.date_range.push(i.toString());
        //////////////console.log("DATES " + this.date_range);
      }
    },

    async getHistogramData(query) {

      this.reportLoading = true;

      this.contracting_activity = [];
      this.contracting_spend = [];
      this.contracted_per_month = [];

      histogramSearch(query, 1)
        .then(data => {        
          this.contracting_activity =
            data.aggregations.contracting_activity.buckets;
          this.contracting_spend = data.aggregations.contracting_spend.buckets;
          this.contracted_per_month =
            data.aggregations.filtered_contracted_per_month.contracted_per_month.buckets;
          this.total_amount = data.aggregations.total_amount.value;
          this.total_records = data.hits.value;
          this.plain_text_query = data.plain_text_query;

          //////////////console.log("ACTIVITY: ", this.contracting_activity);

          this.prepYoYData();
          this.setDateRange();
          this.reportLoading = false;

        })
        .catch(error => {
          //////////////console.log(error);
          this.errored = true;
        });
    },

    getDistributionPercent(type){

      var topGroups = 0;
      //////////////console.log("GROUPED TENDERS: ", this.grouped_tenders)
      var other = this.grouped_tenders[this.grouped_tenders.length - 1][type];

      for (var i = 0; i < this.grouped_tenders.length - 1; i++){
        topGroups += this.grouped_tenders[i][type];
      }

      var pc = topGroups / (topGroups + other) * 100;

      return Math.round(pc, 1);

    },

    getGroupedTenders() {
      this.bubbleLoading = true;
      bus.$emit("group-by-search", {grouping: this.group.value, sort_by: this.group_sort_by.value});
    },

    updateTreeMapGrouping() {
      this.gov_supplier_tree = false
      //this.bubbleLoading = true;
      //bus.$emit("group-by-search", {grouping: this.group.value, sort_by: this.group_sort_by.value});
    },

    getPlural(group) {
      switch (group) {
        case "category":
          return "categories";
        case "buyer":
          return "buyers";
        case "supplier":
          return "suppliers";
        case "government":
          return "governments";
      }
    },

    getFullMonthName(shortName) {
      var str = this.splitMonthYearString(shortName);

      switch (str[0]) {
        case "Jan":
          return "January" + " " + str[1];
        case "Feb":
          return "February" + " " + str[1];
        case "Mar":
          return "March" + " " + str[1];
        case "Apr":
          return "April" + " " + str[1];
        case "May":
          return "May" + " " + str[1];
        case "Jun":
          return "June" + " " + str[1];
        case "Jul":
          return "July" + " " + str[1];
        case "Aug":
          return "August" + " " + str[1];
        case "Sep":
          return "September" + " " + str[1];
        case "Oct":
          return "October" + " " + str[1];
        case "Nov":
          return "November" + " " + str[1];
        case "Dec":
          return "December" + " " + str[1];
      }
    },

    splitMonthYearString(str) {
      return str.split("-");
    },

    prepYoYData() {
      var seriesLabels = { prevYear: [], currYear: [] };
      var months = [];
      var data = { prevYear: [], currYear: [] };
      var currYearCumulative = 0;
      var prevYearCumulative = 0;

      //get 12 months before current 12 months

      for (var j = 0; j < 12; j++) {
        //////////////console.log(this.contracted_per_month[j].key_as_string + " " + this.contracted_per_month[j].total_amount.value)
        prevYearCumulative += this.contracted_per_month[j].total_amount.value;
        data.prevYear.push(prevYearCumulative);
        seriesLabels.prevYear.push(this.contracted_per_month[j].key_as_string);
        months.push(this.contracted_per_month[j].key_as_string.split("-")[0]);
      }

      for (var i = 12; i < 24; i++) {
        //////////////console.log(this.contracted_per_month[i].key_as_string + " " + this.contracted_per_month[i].total_amount.value)
        currYearCumulative += this.contracted_per_month[i].total_amount.value;
        data.currYear.push(currYearCumulative);
        seriesLabels.currYear.push(this.contracted_per_month[i].key_as_string);
      }

      this.yoy_data = {
        seriesLabels: seriesLabels,
        headers: months,
        data: data
      };

      //////////////console.log(this.yoy_data);
    },

    getSTLY() {
      var stly = [];

      var currYearAmtTotal = 0;
      var currYearCount = 0;
      var prevYearAmtTotal = 0;
      var prevYearCount = 0;

      //get current 12 months

      for (var i = 12; i < 24; i++) {
        currYearAmtTotal += this.contracted_per_month[i].total_amount.value;
        currYearCount += this.contracted_per_month[i].doc_count;
      }

      //get 12 months before current 12 months

      for (var j = 0; j < 12; j++) {
        prevYearAmtTotal += this.contracted_per_month[j].total_amount.value;
        prevYearCount += this.contracted_per_month[j].doc_count;
      }

      stly[0] = {
        totalValue: prevYearAmtTotal,
        avgValue: prevYearAmtTotal / prevYearCount,
        count: prevYearCount
      };

      stly[1] = {
        totalValue: currYearAmtTotal,
        avgValue: currYearAmtTotal / currYearCount,
        count: currYearCount
      };

      return stly;
    },

    check_gov_name(gov) {
      if (gov === "AUS") {
        return "AUS";
      }
      else if (gov === "FED") {
        return "FED";
      }
      else if (gov === "DEF") {
        return "DEF";
      } 
      else {
        return gov;
      }
    },

    numFormatter(num) {
      if (num > 999 && num < 1000000) {
        return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
      } else if (num >= 1000000 && num < 1000000000) {
        return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
      } else if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1) + "B"; // convert to M for number from > 1 million
      } else if (num < 900) {
        return num; // if value < 1000, nothing to do
      }
    },

    formattedCurrency: function(amount) {
      //////////////console.log("AMOUNT: " + amount);
      if (!isNaN(amount)) {
        return "$" + this.numFormatter(amount);
        //return "$" + Math.round(amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } //else {
      //return null;
      //}
    },

    formattedCurrencyFull: function(amount) {
      //////////////console.log("AMOUNT: " + amount);
      if (!isNaN(amount)) {
        return (
          "$" +
          Math.round(amount)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        );
      } //else {
      //return null;
      //}
    },
    showHideDetails(item) {
      //this.item_details = !this.item_details;
      this.item_details.show = true;
      this.item_details.record = item;
      bus.$emit("show-hide-details", this.item_details);
    },
    formatDate(date_value) {
      return moment(date_value).format("MMMM YYYY");
    },

    getPercentageChange(oldNum, newNum) {
      var change = newNum - oldNum;
      var per = (change / oldNum) * 100;
      return per;
    },

    getTreeData(dataset){
      switch (dataset) {
        case "publisher":
          return this.publishers_by_gov;
        case "supplier":
          return this.suppliers_by_gov;
        case "category":
          return this.categories_by_gov;
      }
    }
  },
  computed: {

    getGroup(){
      if (this.group.value === "publisher"){
        return "buyer";
      }else{
        return this.group.value;
      }
    },

    start_year() {
      return this.contracting_activity[0].key_as_string;
    },

    end_year() {
      return this.contracting_activity[this.contracting_activity.length - 1]
        .key_as_string;
    },

    highest_year() {
      let max_count = this.contracting_activity[0].doc_count;
      let max_year = this.contracting_activity[0].key_as_string;

      for (var i = 1; i < this.contracting_activity.length; i++) {
        if (this.contracting_activity[i].doc_count > max_count) {
          max_count = this.contracting_activity[i].doc_count;
          max_year = this.contracting_activity[i].key_as_string;
        }
      }

      return { year: max_year, num_notices: max_count };
    },

    avg_per_year() {
      let num_years = this.contracting_activity.length;
      let total_contracts = 1;
      let total_spend = 0;

      for (var i in this.contracting_activity) {
        total_contracts += this.contracting_activity[i].doc_count;
        total_spend += this.contracting_spend[i].total_amount.value;
      }

      var avg_contracts_per_year = Math.round(total_contracts / num_years, 0);
      var avg_spend_per_year = Math.round(total_spend / num_years, 0);

      return { count: avg_contracts_per_year, value: avg_spend_per_year };
    },

    avg_monthly_spend() {
      var avg = this.getSTLY()[0].totalValue + this.getSTLY()[1].totalValue;
      avg = avg / 24;
      return avg;
    },

    gov_spend_title(){
      return "Government Spending Breakdown";
    },

    gov_spend_description(){
      return "Overall spending and transaction numbers can be further broken down by the top buyers, suppliers or categories grouped by federal, state, and territory governments."
    },

    market_share_title(){
      return "Market Share";
    },

    market_share_description(){
      //////////////console.log("AGGS: ", this.govt_aggs);
      if (this.govt_aggs.length > 0){
        let text = 'The total value of the\
                      <span style="color:rgb(255, 20, 99); font-weight:normal;">'+ this.total_records+'</span>\
                      contracts awarded by public entities based on the search\
                      criteria was\
                      <span style="color:rgb(255, 20, 99); font-weight:normal;">'+ this.formattedCurrencyFull(this.total_amount) + '</span>.\
                      With the majority of spending commitments being made by '+ this.get_govt_name(this.govt_aggs[0].key)+'\
                      across <span style="color:rgb(255, 20, 99); font-weight:normal;">' + this.govt_aggs[0].doc_count + '</span>\
                      contracts.'
        return text;
      }
      else return ""
      
    },

    contract_distribution_title(){
      return "Contract Distribution";
    },

    contract_distribution_description(){
      if (this.grouped_tenders.length > 0){
        let text = '<span style="color:rgb(255, 20, 99); font-weight:normal;">'+ this.grouped_tenders[0].name + '\
                        </span> is the largest \
                        <span style="color:rgb(255, 20, 99); font-weight:normal;">' + this.getGroup + ' </span> by <span style="color:rgb(255, 20, 99); font-weight:normal;">'+ this.group_sort_by.text.toLowerCase() +' </span>\
                        over the period spanning <span style="color:rgb(255, 20, 99); font-weight:normal;">' + this.start_year +'</span>\
                        to <span style="color:rgb(255, 20, 99); font-weight:normal;">' + this.end_year + '</span>. The top '+ (this.grouped_tenders.length - 1) + ' ' + this.getPlural(this.getGroup) + ' accounted for <span style="color:rgb(255, 20, 99); font-weight:normal;">'
                        + this.getDistributionPercent("group_total") + '% </span>\
                        total contract value, and <span style="color:rgb(255, 20, 99); font-weight:normal;">' + this.getDistributionPercent("group_count") + '%' + '</span> of total contracts awarded. \
                        The largest contract awarded for the period was worth <span style="color:rgb(255, 20, 99); font-weight:normal;">' + this.grouped_tenders[0].data[0].detail.value + '</span>.\
                        This was awarded to <span style="color:rgb(255, 20, 99); font-weight:normal;">' + this.grouped_tenders[0].data[0].detail.supplier + '</span> by \
                        <span style="color:rgb(255, 20, 99); font-weight:normal;">' + this.grouped_tenders[0].data[0].detail.buyer + '</span>.'
        return text;
      }
      else return ""
    },

    spend_comparison_title(){
      return "Participant Comparison Over Time";
    },

    spend_comparison_description(){
      let text = 'Exploring the cumulative growth in the number of contracts awarded or the total value of those contracts can reveal which suppliers, buyers or categories are in the ascendancy. \
                  Viewing the actual spend amounts or awards can also provide insights into the level of spend for each group at the same time over the previous year or quarter.'
      return text;

    },

    quarterly_trends_title(){
      return "Quarterly Trends";
    },

    quarterly_trends_description(){
      let text = 'Monitoring year on year quarterly trends provides visibility into like likelihood of procurement events occuring in a specific period. This enables more accurate forecasting and resource planning.'
      return text;

    },

    market_activity_title(){
      let text = 'Market Activity'
      return text;

    },

    market_activity_description(){
      let text = 'Active contracts provide a view on how many, and what value of contracts are current in the market today. The year on year trending shows the change that has occurred over the past twelve months to date, as well as providing an \
      analysis of expiring contracts over the upcoming 12 months from today.'
      return text;

    },

    expiring_contracts_title(){
      return "Expiring Contracts";
    },

    expiring_contracts_description(){
      let text = 'The heatmap below provides a snapshot of which periods will see the highest amount of activity for the renewal or replacement of contracts within the top participating entities over the next <span style="color:rgb(255, 20, 99); font-weight:normal;">8 quarters</span>.'
      return text;

    }
  }
};
</script>

<style>
.rtl {
  direction: ltr;
}
.small {
  max-height: 300px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.headings {
  font-size: 48px;
  font-weight: 300;
  /*color: #12253a;*/
  /* border-bottom: 3px solid; */
  /*border-color: #12253a;*/
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.body {
  font-size: 21px;
  font-weight: 300;
  color: rgb(18, 37, 58);
}
.divider {
  border-bottom: 2px solid;
  /* border-color: rgb(78, 184, 136); */
  padding-bottom: 30px;
  /* padding-right: 30px;
  padding-left: 30px; */
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 30px;
}

</style>
