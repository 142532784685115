//entity_type = "government", "supplier", "publisher", or "category"
//target = the target govt, publisher, supplier or category. e.g. "NSW Police"
function prepEntityBreakdown(data, metric_name, colour, entity_type) {
  var results = {
    labels: [],
    datasets: [{ label: entity_type, data: [], backgroundColor: [] }]
  };

  //set data
  for (var i = 0; i < data.length; i++) {
    results.labels[i] = data[i].key.toUpperCase();
    //results.labels[i] = data[i].key.substring(0,50).toUpperCase();

    if (metric_name === "doc_count") {
      results.datasets[0].data[i] = data[i].doc_count;
    } else if (metric_name === "total_amount") {
      results.datasets[0].data[i] = Math.round(data[i].total_amount.value);
    }
  }

  //set colours
  //var colours = [data.length];

  //colours [0] = "rgb("+colour.red +","+ colour.green +","+ colour.blue +")";

  ////////////console.log("BASE COLOUR: ", colours[0]);
  var colours = [];

  var palette = [
    "#12253a",
    "#ff1463",
    "#d73e32",
    "#2196f3",
    "#004700",
    "#006000",
    "#00791e",
    "#2d9437",
    "#004d9f",
    "#0064ba",
    "#007cd6",
    "#d73e32",
    "#de004b",
    "#ff457c",
    "#4a575d",
    "#7b898f",
    "#bb5900",
    "#bb5900",
    "#ffa72d",
    "#4caf50",
    "#2196f3",
    "#fb8c00",
    "#ff1463",
    "#b71c1c",
    "#ff937b",
    "#95e8ff",
    "#a2ff9e",
    "#fffb82",
    "#FFE70A",
    "#SFS603",
    "#7F7305",
    "#BFAB07",
    "#FF6905",
    "#5405FF"
  ];

  if (entity_type == "government") {
    for (var t = 0; t < data.length; t++) {
      if (data[t].key == "NSW") {
        colours[t] = "#41a7d9";
      } else if (data[t].key == "VIC") {
        colours[t] = "#0058AC";
      } else if (data[t].key == "WA") {
        colours[t] = "#FFD200";
      } else if (data[t].key == "QLD") {
        colours[t] = "#A6003D";
      } else if (data[t].key == "AUS") { 
        colours[t] = "#D8993B";
      } else if (data[t].key == "FED") { 
        colours[t] = "#D8993B";
      }
      else if (data[t].key == "DEF") { 
        colours[t] = "#d8673b";
      }
        else if (data[t].key == "SA") {
        colours[t] = "#ff4081";
      } else if (data[t].key == "TAS") {
        colours[t] = "#4eb888";
      } else if (data[t].key == "ACT") {
        colours[t] = "#41a7d9";
      } else if (data[t].key == "NT") {
        colours[t] = "#FF3647";
      }

      ////////////console.log("COLOUR FOR: ", data[t].key.toUpperCase(), " IS ", colours[t]);
    }
  } else {
    for (var i = 0; i < data.length; i++) {
      colours[i] = palette.shift();
      ////////////console.log("RANDOM COLOUR FOR: ", data[i].key.toUpperCase(), " IS ", colours[i], " FOR ", entity_type);
    }
  }

  results.datasets[0].backgroundColor = colours;

  ////////////console.log("DATASET: ", JSON.stringify(results.datasets));

  return results;
}

export { prepEntityBreakdown };
