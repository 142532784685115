var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"chart_container pa-2",attrs:{"tile":"","flat":"","min-height":"500px"}},[_c('chart-header',{attrs:{"title":_vm.title,"subtitle":_vm.subtitle,"widget_name":'quarterly-trends',"total_result":_vm.total_records,"total_amount":_vm.total_amount,"search_query":_vm.plain_text_query,"chart_settings":_vm.chart_settings,"disable_show_data":true,"embed_object":_vm.embed_object,"embed_obj_changed":_vm.embed_obj_changed,"data":_vm.dataview},on:{"embed":_vm.embed_code,"downloadImage":_vm.downloadImage}}),_c('chart-loader',{attrs:{"reportLoading":_vm.reportLoading}}),_c('v-row',[_c('div',{staticClass:"d-inline pl-4 mr-5"},[_c('p',{staticClass:"mb-0 pb-0 subtitle-2",staticStyle:{"font-weight":"bold"}},[_vm._v("Metric")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize body-1",staticStyle:{"justify-content":"left !important"},attrs:{"color":"#E0E0E0","outlined":"","width":"130"}},'v-btn',attrs,false),on),[_c('span',{staticStyle:{"color":"#12253a"}},[_vm._v(_vm._s(_vm.metric.text))]),_c('v-icon',{attrs:{"color":"#12253a","right":""}},[_vm._v("mdi-menu-down")])],1)]}}])},[_c('v-list',_vm._l((_vm.metric_options),function(item,value){return _c('v-list-item',{key:value,on:{"click":function($event){_vm.metric = item, _vm.updateChartData(), _vm.updateSettings()}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)}),1)],1)],1),_c('div',{staticClass:"d-inline mr-5"},[_c('p',{staticClass:"mb-0 pb-0 subtitle-2",staticStyle:{"font-weight":"bold"}},[_vm._v("Date Orientation")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize body-1",staticStyle:{"justify-content":"left !important"},attrs:{"color":"#E0E0E0","outlined":"","width":"160"}},'v-btn',attrs,false),on),[_c('span',{staticStyle:{"color":"#12253a"}},[_vm._v(_vm._s(_vm.date_option.text))]),_c('v-icon',{attrs:{"color":"#12253a","right":""}},[_vm._v("mdi-menu-down")])],1)]}}])},[_c('v-list',_vm._l((_vm.date_options),function(item,value){return _c('v-list-item',{key:value,on:{"click":function($event){_vm.date_option = item, _vm.updateChartData(), _vm.updateSettings()}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)}),1)],1)],1),_c('div',{staticClass:"d-inline"},[_c('p',{staticClass:"mb-0 pb-0 subtitle-2",staticStyle:{"font-weight":"bold"}},[_vm._v("# Entities / Categories")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize body-1",staticStyle:{"justify-content":"left !important"},attrs:{"color":"#E0E0E0","outlined":"","width":"130"}},'v-btn',attrs,false),on),[_c('span',{staticStyle:{"color":"#12253a"}},[_vm._v(_vm._s(_vm.num_groups))]),_c('v-icon',{attrs:{"color":"#12253a","right":""}},[_vm._v("mdi-menu-down")])],1)]}}])},[_c('v-list',_vm._l((_vm.top_entities),function(item){return _c('v-list-item',{key:item,on:{"click":function($event){_vm.num_groups = item, _vm.updateSettings(), _vm.refresh()}}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1)],1)]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.contracted_fy_by_qtr_aggs.length > 0)?_c('quarterly-line-chart',{attrs:{"data":_vm.chart_data,"series":_vm.chart_data,"chart_axes":_vm.chart_axes,"x_axis_data":_vm.chart_labels,"changed":_vm.changed,"download":_vm.download,"yAxisMetric":_vm.metric.value,"target_group":_vm.group.value,"embed":_vm.embed,"interval":_vm.interval.value,"id":_vm.id},on:{"chart_object":_vm.chart_object,"showTenders":_vm.showTenders,"dataview":_vm.loadDataView}}):(_vm.contracted_fy_by_qtr_aggs.length == 0 && _vm.reportLoading==false)?_c('v-card',{attrs:{"flat":"","tile":""}},[_c('v-card-title',[_vm._v("No Data")])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }