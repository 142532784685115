var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-0 ma-0 fill-height",staticStyle:{"background-color":"#f5f5f5"},attrs:{"fluid":""}},[_c('v-content',{staticClass:"py-1"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"py-4",staticStyle:{"background-color":"#12253a"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticStyle:{"max-width":"1200px","display":"block","margin":"auto !important","padding-top":"50px"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"pr-0 mr-0",staticStyle:{"max-width":"800px"},attrs:{"cols":"11"}},[_c('tender-search')],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{staticClass:"text-capitalize body-2 ml-0 desktop-nav",attrs:{"color":"white","small":"","text":"","to":{
                    name: 'search',
                    query: {
                      expiry_from: new Date().getFullYear(),
                      expiry_to: new Date().getFullYear(),
                      sort_order: 'asc',
                      showFilter: true
                    }
                  }}},[_c('span',{staticClass:"text-capitalize body-2 desktop-nav"},[_vm._v("Advanced")])])],1)],1)],1)])],1),_c('div',{staticClass:"pt-5 pb-2"},[_c('p',{staticClass:"title pb-0 pl-1"},[_c('span',{staticClass:"pt-2"},[_vm._v("My Search Profiles ")]),_c('span',{staticClass:"pt-0 mt-0"},[_c('v-dialog',{model:{value:(_vm.explainer),callback:function ($$v) {_vm.explainer=$$v},expression:"explainer"}},[_c('v-card',{staticClass:"pa-4",attrs:{"tile":"","flat":""}},[_c('p',{staticClass:"title",staticStyle:{"color":"#12253a"}},[_vm._v(" What are Search Profiles? ")]),_c('p',{staticStyle:{"color":"#12253a"}},[_vm._v(" Search profiles are based on searches that have been executed and then saved. This means you can quickly rerun to those searches when you log back in. Tendertrace will automatically monitor search profiles and notify you of any new notices that match a profile. All new matches will be available in your \"Alerts\" page. ")]),_c('p',[_vm._v(" You will find up to date analysis of the search profiles you create on your home page. ")]),_c('p',{staticStyle:{"color":"#12253a"}},[_vm._v(" Saved searches also save the search tab you are on. So for example, if you were on the Leaderboard tab when you saved the search profile, clicking on the saved profile will run the search and also take you to the tab you were on when you saved the profile initially. ")]),_c('p',{staticStyle:{"color":"#12253a"}},[_vm._v(" To start saving searches: "),_c('ul',[_c('li',[_vm._v("Run a search for contracts or tenders of interest.")]),_c('li',[_vm._v("In the filter description section of the screen, click the button labeled \"Save Search\" to have Tendetrace start monitoring that search on your behalf.")])])]),_c('p',[_c('v-btn',{attrs:{"color":"primary","small":""}},[_c('router-link',{attrs:{"to":{ name: 'search',
                                      query: {
                                        disposition: ['Contract Notice','Grant'],
                                        expiry_from: new Date().getFullYear(),
                                        expiry_to: new Date().getFullYear(),
                                        sort_order: 'asc'
                                      }
                                    }}},[_c('span',{staticStyle:{"color":"white !important"}},[_vm._v("Create profile")])])],1)],1)])],1),_c('v-icon',{attrs:{"small":"","color":"secondary"},on:{"click":function($event){_vm.explainer = true}}},[_vm._v("mdi-information-outline")])],1)])]),_c('v-row',{staticClass:"py-0",staticStyle:{"height":"100%"}},[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","sm":"6"}},[_c('v-combobox',{staticClass:"pb-0",attrs:{"items":_vm.saved_searches,"loading":_vm.loadingSearches,"search-input":_vm.search,"item-text":"name","label":"Select one or more profiles to compare","outlined":"","chips":"","dense":"","multiple":"","return-object":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                                    var attrs = ref.attrs;
                                    var item = ref.item;
                                    var select = ref.select;
                                    var selected = ref.selected;
return [_c('v-chip',_vm._b({staticClass:"accent white--text",attrs:{"color":"rgb(255, 20, 99)","small":"","input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.remove_profile(item)}}},'v-chip',attrs,false),[_c('span',{staticClass:"chip-label"},[_vm._v(_vm._s(item.name))])])]}}]),model:{value:(_vm.selected_profiles),callback:function ($$v) {_vm.selected_profiles=$$v},expression:"selected_profiles"}})],1)],1),(_vm.selected_profiles.length == 0)?_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"pa-4",attrs:{"tile":"","flat":""}},[_c('p',{staticClass:"title",staticStyle:{"color":"#12253a"}},[_vm._v(" What are Search Profiles? ")]),_c('p',{staticStyle:{"color":"#12253a"}},[_vm._v(" Search profiles are based on searches that have been executed and then saved. This means you can quickly rerun to those searches when you log back in. Tendertrace will automatically monitor search profiles and notify you of any new notices that match a profile. All new matches will be available in your \"Alerts\" page. ")]),_c('p',[_vm._v(" You will find up to date analysis of the search profiles you create on your home page. ")]),_c('p',{staticStyle:{"color":"#12253a"}},[_vm._v(" Saved searches also save the search tab you are on. So for example, if you were on the Leaderboard tab when you saved the search profile, clicking on the saved profile will run the search and also take you to the tab you were on when you saved the profile initially. ")]),_c('p',{staticStyle:{"color":"#12253a"}},[_vm._v(" To start saving searches: "),_c('ul',[_c('li',[_vm._v("Run a search for contracts or tenders of interest.")]),_c('li',[_vm._v("In the filter description section of the screen, click the button labeled \"Save Search\" to have Tendetrace start monitoring that search on your behalf.")])])]),_c('p',[_c('v-btn',{attrs:{"color":"primary","small":""}},[_c('router-link',{attrs:{"to":{ name: 'search',
                                      query: {
                                        disposition: ['Contract Notice','Grant'],
                                        expiry_from: new Date().getFullYear(),
                                        expiry_to: new Date().getFullYear(),
                                        sort_order: 'asc'
                                      }
                                    }}},[_c('span',{staticStyle:{"color":"white !important"}},[_vm._v("Create profile")])])],1)],1)])],1)],1):_vm._l((_vm.selected_profiles),function(selected_profile){return _c('v-row',{key:selected_profile.search_id,staticClass:"pb-3"},[(selected_profile['search_query'])?_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12"}},[_c('v-card',{attrs:{"width":"100%","flat":"","outlined":""}},[_c('v-card-title',{staticClass:"pt-3 pb-4 pl-6",staticStyle:{"font-weight":"medium !important","color":"#12253a !important"}},[_c('v-badge',{attrs:{"color":"primary","value":selected_profile.new_matches > 0,"content":selected_profile.new_matches + ' new notices'}},[_c('router-link',{attrs:{"to":{
                              name: 'search',
                              query: selected_profile.search_query
                            }}},[_c('span',[_vm._v(_vm._s(selected_profile.name))])])],1),_c('v-spacer'),_c('span',[_c('v-tooltip',{attrs:{"left":"","max-width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',_vm._g(_vm._b({attrs:{"medium":"","color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("What data is this search profile based on?")]),_c('br'),_c('v-divider',{staticClass:"mt-2 mb-2",staticStyle:{"border-color":"white"}}),_c('span',[_vm._v("This search profile is an aggregate view of the results from the search "),_c('i',[_vm._v(_vm._s(selected_profile.plain_text_query))])]),_c('div')],1)],1),_c('span',[_c('v-tooltip',{attrs:{"left":"","max-width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openProfile(0, selected_profile)}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"medium":"","color":"secondary"}},'v-icon',attrs,false),on),[_vm._v("mdi-format-list-bulleted")])],1)]}}],null,true)},[_c('span',[_vm._v("Go to the data for this profile")])])],1),_c('span',[_c('v-tooltip',{attrs:{"left":"","max-width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openProfile(1, selected_profile)}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"medium":"","color":"secondary"}},'v-icon',attrs,false),on),[_vm._v("mdi-sort-variant")])],1)]}}],null,true)},[_c('span',[_vm._v("Go to the leaderboard for this profile")])])],1),_c('span',[_c('v-tooltip',{attrs:{"left":"","max-width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openProfile(3, selected_profile)}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"medium":"","color":"secondary"}},'v-icon',attrs,false),on),[_vm._v("mdi-chart-box-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Go to the analysis for this profile")])])],1)],1),_c('v-card-text',{staticClass:"px-5 pt-0"},[_c('v-row',{staticClass:"pa-0"},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('search-analysis',{attrs:{"query":selected_profile.search_query}})],1)],1),_c('v-row',{staticClass:"pa-0"},[_c('v-col',{staticClass:"pl-0",attrs:{"cols":"12","sm":"3"}},[_c('spend-comparison-mini',{attrs:{"title":'',"description":'',"query":selected_profile.search_query}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('spending-donut-mini',{attrs:{"title":'Top Sectors',"description":'',"query":selected_profile.search_query,"group":{text: 'Industry', value: 'publisher_industry'}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('spending-donut-mini',{attrs:{"title":'Top Categories',"description":'',"query":selected_profile.search_query,"group":{text: 'Major Category', value: 'major_category'}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('spending-donut-mini',{attrs:{"title":'Top Buyers',"description":'',"query":selected_profile.search_query,"group":{text: 'Buyer', value: 'publisher'}}})],1)],1)],1)],1)],1):_vm._e()],1)})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }