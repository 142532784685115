<template>
    <v-card tile flat style="overflow-x: hidden !important; overflow-y: hidden !important;">
        
        <v-card-title class="pa-0" style="font-weight:bold;">
            <tiptap :editable="editable" :sectionType="'heading'" :value="title" @input="saveUpdate" />
        </v-card-title>
        
        <!-- <v-divider></v-divider> -->
        <div class="body-1 pa-0">
            <tiptap :editable="editable" :value="description" @input="saveUpdate" />
        </div>
        <!-- <p class="title mt-3 font-weight-light">{{description}}</p> -->
        <v-card v-if="isMounted" flat tile class="pb-0 pt-4 px-0">
            <component v-if="isMounted" :is="comp" :story="true" :id="changed" :key="changed" :chart_options="payload.options" :legend="payload['legend']" />
            <div v-if="timestamp" class="caption pt-5 text-right">Data snapshot as at {{ timestamp }} </div>
        </v-card>
    </v-card> 
</template>
<script>
import ELineChart from "./ELineChart.vue";
import QuarterlyLineChart from "./QuarterlyLineChart.vue";
import PieEcharts from "./PieECharts.vue";
import TreeEcharts from "./TreeEcharts.vue";
import Scatter from "./Scatter.vue";
import Heatmap from "./Heatmap.vue";
import NetworkSmall from "./NetworkSmall.vue";
import ResultsLeaderboard from "./ResultsLeaderboard.vue";
import SearchAnalysis from "./SearchAnalysis.vue";
import TraceyResults from "./TraceyResults.vue";
import Tiptap from "./Tiptap.vue";
import { updateStory } from "../components/APIManager.js";

export default {
    components: {
        ELineChart,
        PieEcharts,
        TreeEcharts,
        Scatter,
        Heatmap,
        NetworkSmall,
        Tiptap,
        SearchAnalysis,
        ResultsLeaderboard,
        QuarterlyLineChart,
        TraceyResults
    },
    props:{
        data: Object,
        changed: Number,
        position: Number,
        reset:{
            type: Boolean,
            default: false
        },
        editable: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            comp: Object,
            payload: Object,
            title: "",
            description:"",
            isMounted: false,
            timestamp: ""
        }
    },

    mounted(){
        this.loadStory();
    },

    computed:{

        description_html(){
            let html = '<p style="font-weight:normal; font-size:2.125rem;">'+this.description+'</p>';
            return html;
        },

        title_html(){
            let html = '<p style="font-weight:normal; font-size:2.125rem;">'+this.title+'</p>';
            return html;
        }

    },

    methods:{

        formatTimestamp(timestamp) {
            const date = new Date(timestamp);

            const year = date.getFullYear();
            const month = date.getMonth() + 1; 
            const day = date.getDate();
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const seconds = date.getSeconds();

            const formattedDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day} ${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;

            return formattedDate;
        },

        async saveUpdate(content){

            var story = this.data;
            
            if (content.sectionType === "heading"){
                story.title = content.html;
            }else{
                story.description = content.html;
            }

            await updateStory(story);
        },

        close(args){
            this.$emit("close", args);
        },

        loadStory(){

            this.comp = {};
            this.payload = {};
            this.title="",
            this.description = "";
            this.timestamp = "";

            if (this.data.chart_type == "line")
            {
                this.comp = ELineChart;
            }
            else if (this.data.chart_type == "pie")
            {
                this.comp = PieEcharts;
            }
            else if (this.data.chart_type == "treemap")
            {
                this.comp = TreeEcharts;
            }
            else if (this.data.chart_type == "scatter")
            {
                this.comp = Scatter;
            }
            else if (this.data.chart_type == "network")
            {
                this.comp = NetworkSmall;
            }
            else if (this.data.chart_type == "heatmap")
            {
                this.comp = Heatmap;
            }
            else if (this.data.chart_type == "leaderboard")
            {
                ////////////console.log("LEADERBOARD PAYLOAD: ", this.data.payload);
                this.comp = ResultsLeaderboard;
            }
            else if (this.data.chart_type == "search_analysis")
            {
                //////////////console.log("PAYLOAD: ", this.data.payload)
                this.comp = SearchAnalysis;
            }
            else if (this.data.chart_type == "line-chart")
            {
                //////////////console.log("PAYLOAD: ", this.data.payload)
                this.comp = QuarterlyLineChart;
            }
            else if (this.data.chart_type == "tracey-report" || this.data.chart_type == "copilot-report")
            {
                this.comp = TraceyResults;
            }

            this.payload = this.data.payload;
            this.title = this.data.title;
            this.description = this.data.description;
            this.timestamp = (this.data.created) ? this.formatTimestamp(this.data.created) : this.formatTimestamp(new Date());
            //this.timestamp = this.formatTimestamp(this.data.created);
            this.isMounted = true;
            //this.$emit("isMounted", true);
        }
    },

    watch: {
        changed() {
            this.isMounted = false;
            this.loadStory();
        },

        /* async position(){
            await updateStory(this.data);
        } */
    },
}
</script>