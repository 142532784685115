<template>
    <div>
      <v-dialog
          v-model="dialog"
          max-width="400"
        >
          <v-card>
            <v-card-title class="text-h5">
              Insert link
            </v-card-title>
            <v-text-field dense v-model="url" label="Web address (URL)" outlined prepend-inner-icon="mdi-link"></v-text-field>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="dialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="green darken-1"
                text
                @click="dialog = false, setLink()"
              >
                Ok
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <bubble-menu :editor="editor" :tippy-options="{ duration: 100 }" v-if="editor">
            
            <v-btn-toggle
                v-model="toggle_multiple"
                dense
                background-color="secondary"
                dark
                multiple
            >
                <v-btn small @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
                <v-icon small>mdi-format-bold</v-icon>
                </v-btn>
                <v-btn small @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
                <v-icon small>mdi-format-italic</v-icon>
                </v-btn>
                <v-btn small @click="editor.chain().focus().toggleUnderline().run()" :class="{ 'is-active': editor.isActive('underline') }">
                <v-icon small>mdi-format-underline</v-icon>
                </v-btn>
                <v-btn small color="#ff1463" @click="editor.chain().focus().setColor('#ff1463').run()" :class="{ 'is-active': editor.isActive('textStyle', { color: '#ff1463' })}">
                <v-icon small>mdi-format-color-fill</v-icon>
                </v-btn>
                <v-btn small @click="editor.chain().focus().unsetColor().run()">
                <v-icon small>mdi-format-color-fill</v-icon>
                </v-btn>
                <v-btn small @click="dialog=true" :class="{ 'is-active': editor.isActive('link') }">
                <v-icon small>mdi-link</v-icon>
                </v-btn>
                <v-btn small @click="editor.chain().focus().unsetLink().run()" :disabled="!editor.isActive('link')">
                <v-icon small>mdi-link-off</v-icon>
                </v-btn>
               <!--  <v-btn color="#12253a" @click="editor.chain().focus().setColor('#12253a').run()" :class="{ 'is-active': editor.isActive('textStyle', { color: '#12253a' })}">
                <v-icon>mdi-format-color-fill</v-icon>
                </v-btn> -->
            </v-btn-toggle>
        </bubble-menu>
        <editor-content :editor="editor" />
        <!-- <v-row class="pa-0">
            <v-col cols="12"><editor-content :editor="editor" /></v-col>
            <v-col cols="1"><v-btn icon><v-icon>mdi-pencil</v-icon></v-btn></v-col>
        </v-row> -->
    </div>
</template>

<script>
import { Editor, EditorContent, BubbleMenu } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import TextStyle from '@tiptap/extension-text-style'
import { Color } from '@tiptap/extension-color'
import Link from '@tiptap/extension-link'
import Placeholder from '@tiptap/extension-placeholder'

export default {
  components: {
    EditorContent,
    BubbleMenu,
    //Underline
  },

  props: {
      value: {
        type: String,
        default: '',
    },
    editable: {
        type: Boolean,
        default: true
    },
    sectionType:{
        type: String,
        default: 'body'
    }
  },

  data() {
    return {
      editor: null,
      toggle_multiple: [],
      dialog: false,
      url: ""
    }
  },

  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value

      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      if (isSame) {
        return
      }

      this.editor.commands.setContent(value, false)
    },
  },

  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,
        Underline,
        TextStyle,
        Color,
        Link.configure({
          openOnClick: true,
          HTMLAttributes: {
            class: 'editor-link',
          },
        }),
        Placeholder.configure({
            placeholder: ({ node }) => {
                ////////////console.log("NODE: ", node);
                if (this.sectionType === 'heading') {
                return 'Enter a section heading...'
                }

                return 'Enter a section description...'
            },
        }),
      ],
      editable: this.editable,
      onUpdate: () => {
        // HTML
        this.$emit('input', {"html": this.editor.getHTML(), "sectionType":this.sectionType})

        // JSON
        // this.$emit('input', this.editor.getJSON())
      },
    })
  },

  methods: {

      setLink() {

        //const previousUrl = this.editor.getAttributes('link').href

        if (!this.url.includes("http")){
          this.url = '//'+this.url;
        }
        
        const url = this.url;//window.prompt('URL', previousUrl)

        // cancelled
        if (url === null) {
            return
        }

        // empty
        if (url === '') {
            this.editor
            .chain()
            .focus()
            .extendMarkRange('link')
            .unsetLink()
            .run()

            return
        } 

        // update link
        this.editor
            .chain()
            .focus()
            .extendMarkRange('link')
            .setLink({ href: url })
            .run()

        this.url = "";
    },

  },

  beforeDestroy() {
    this.editor.destroy()
  },
}
</script>
<style lang="scss">

.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}
/* Basic editor styles */
/* .ProseMirror {
  font-size: 20px;
  font-weight: 350;
  padding: 0%;
} */

.editor-link {
    border-color: rgb(255, 190, 10);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    color: inherit !important;
}

a:link { text-decoration: none !important; }


a:visited { text-decoration: none !important; }


a:hover { text-decoration: none !important; }


a:active { text-decoration: none !important; }

[contenteditable]:focus {
    outline: 0px solid transparent;
}
</style>