<template>
    <div>
        <div v-if="!story" :id="id" class="reportScatter"></div>
        <v-row v-else class="pa-0 ma-0">
            <v-col cols="10" class="pa-0 ma-0">
                <div :id="id" class="reportScatter"></div>
            </v-col>
            <v-col cols="2" class="pa-0 ma-0">
                <chart-legend
                :colors="legend['colors']" 
                :legendData="legend['legendData']"
                :groups="legend['groups']"
                :total_results="legend['total_results']"
                :total_amount="legend['total_amount']"
                :metric="legend['metric']" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import * as echarts from "echarts";
import {downloadFile} from "../components/Utils";
import ChartLegend from "../components/ChartLegend.vue";

export default {

components: {
    ChartLegend
    //Donut
  },

 props:{
    series: Array(),
    legend_settings: null,
    changed: Number,
    download: Number,
    story: Boolean,
    embed: Number,
    chart_options: {},
    legend: Object,
    id: Number
 },
  data() {
    return {
      option: {},
      myChart: Object,
      qtr: []
    };
  },

  watch: {
    changed() {
      ////////////console.log("CHANGED-- ", this.chart_data);
      this.loadChart();
    },

    download() {
      ////////////console.log("CHANGED-- ", this.chart_data);
      this.saveImage();
    },

    embed() {
      ////////////console.log("CALL SHARE CHART\n", this.myChart);
      
      this.saveChart();
    },
  },

  mounted() {
    this.$nextTick(()=>{
        this.loadChart();
    });
  },

  methods: {

    formatDataTable() {
        let dataview = {
        headers: [
            { text: 'Title', value: 'title' },
            { text: 'X Value', value: 'x_value' },
            { text: 'Y Value', value: 'y_value' },
            { text: 'Major Category', value: 'major_category' },
            { text: 'Category', value: 'category' },
            { text: 'Start Date', value: 'start_date' },
            { text: 'Expiry Date', value: 'expiry_date' },
            { text: 'Government', value: 'government' },
            { text: 'Buyer', value: 'buyer' },
            { text: 'Supplier', value: 'supplier' },
        ],
        data: []
        };

        // Populate the data rows
        this.series.forEach(series => {
        series.data.forEach(dataPoint => {
            dataview.data.push({
            title: dataPoint.detail.title || 'N/A',
            value: dataPoint.detail.value || 'N/A', 
            quarter:this.getQuarterStart(Math.ceil((dataPoint.value[0].getMonth() + 1) / 3).toString()) || 'N/A', // Y-axis value
            major_category: dataPoint.detail.major_category || 'N/A',
            category: dataPoint.detail.category || 'N/A',
            start_date: dataPoint.detail.contract_start || 'N/A',
            expiry_date: dataPoint.detail.expiry || 'N/A',
            government: dataPoint.detail.government || 'N/A',
            buyer: dataPoint.detail.buyer || 'N/A',
            supplier: dataPoint.detail.supplier || 'N/A',
            });
        });
        });

        this.$emit('dataview', dataview);
    },

    saveChart(){

      var imgUrl = this.myChart.getDataURL();

      ////////////console.log("LEGEND: ", JSON.stringify(this.legend_settings));

      let data = {
        imgData: imgUrl, //this.myChart.getDataURL(),
        options: this.option,
        legend: this.legend_settings
      };

      ////////////console.log("SCATTER CHART PAYLOAD: ", data);

      this.$emit("chart_object", data);

    },

    saveImage(){

      //this.imgPath = this.$refs.echart.getDataURL();

      ////////////console.log("DLOAD: ");//, this.$refs.echart.getDataURL);

      var img = new Image();
      
      img.src = this.myChart.getDataURL({
          pixelRatio: 2,
          backgroundColor: '#fff'
      });

      downloadFile(img.src);
      //this.saveChart();

    },

    getQuarterStart(qtr){
      switch (qtr) {
        case "1":
          return "Jan";
        case "2":
          return "Apr";
        case "3":
          return "Jul";
        case "4":
          return "Oct";
        
      }
    },

    loadChart() {

        /* if (this.myChart != null && this.myChart != "" && this.myChart != undefined) {
          this.myChart.clear();
        } */

        var chartDom = document.getElementById(this.id)
        this.myChart = echarts.init(chartDom);

        if (this.story){

          this.chart_options.tooltip.formatter = function (params){

                var record = params.data.detail;

                return [
                    '<div style="color: #12253a; font-family:Roboto; font-weight:bold; font-size:14;">' + record.title + '</div>',
                    '<div style="color: #12253a; font-size:14; font-family:Roboto; font-weight:bold;">' + record.value + '</div>',
                    '<div style="color: #12253a; font-family:Roboto; font-size:14;"> <b>Major Category:</b> '+ record.major_category + '</div>',
                    '<div style="color: #12253a; font-family:Roboto; font-size:14;"> <b>Category:</b> '+ record.category + '</div>',
                    '<div style="color: #12253a; font-family:Roboto; font-size:14;"> <b>Start Date:</b> '+ record.contract_start + '</div>',
                    '<div style="color: #12253a; font-family:Roboto; font-size:14;"> <b>Expiry:</b> '+ record.expiry + '</div>',
                    '<div style="color: #12253a; font-family:Roboto; font-size:14;"> <b>Government:</b> '+ record.government + '</div>',
                    '<div style="color: #12253a; font-family:Roboto; font-size:14;"> <b>Buyer:</b> '+ record.buyer + '</div>',
                    '<div style="color: #12253a; font-family:Roboto; font-size:14;"> <b>Supplier:</b> '+ record.supplier + '</div>'
                ].join('');
            };
          
          this.chart_options.xAxis[0].axisLabel.formatter = (value, index) => {
                // Formatted to be month/day; display year only in the first labe

                var date = new Date(value);
                var texts = [("Q"+Math.ceil((date.getMonth() + 1) / 3)), date.getFullYear()];

                return texts.join('/');

            };

          this.chart_options.yAxis[0].axisLabel.formatter = function(s) {
                function convert (num){
                    if (num > 999 && num < 1000000) {
                        num = (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
                    } else if (num >= 1000000 && num < 1000000000) {
                        num = (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
                    } else if (num >= 1000000000) {
                        num = (num / 1000000000).toFixed(1) + "B"; // convert to M for number from > 1 million
                    }

                    return num;
                }

                return '{valueStyle|' + '$'+ convert(s) + '}';
            };

          this.myChart.setOption(this.chart_options);
        }
        else{
            //this.myChart = echarts.init(document.querySelector("#canvas"));
            this.myChart.on("click", params => { //use this => for all anonymous fucntions. lets you access methods and 'this'
                ////////////console.log(params.data[2].detail); // do whatever you want with another chart say chartTwo here

                let routeData = this.$router.resolve({ name: 'tender', params: { id: params.data.detail.id } });
                window.open(routeData.href, '_blank');

            });

            var option = {

                grid: {
                    left: "5%",
                    right: "5%",
                    bottom: "5%",
                    top: "20px",
                    containLabel: true
                },
                
                tooltip: {
                    //backgroundColor: "rgb(255, 20, 99)",
                    //backgroundColor: "#F6F8FC",
                    //borderColor: "#12253a",
                    borderWidth: 0,
                    //borderRadius: 4,
                    
                    formatter: function (params){

                        var record = params.data.detail;

                        return [
                            '<div style="color: #12253a; font-family:Roboto; font-weight:bold; font-size:14;">' + record.title + '</div>',
                            '<div style="color: #12253a; font-size:14; font-family:Roboto; font-weight:bold;">' + record.value + '</div>',
                            '<div style="color: #12253a; font-family:Roboto; font-size:14;"> <b>Major Category:</b> '+ record.major_category + '</div>',
                            '<div style="color: #12253a; font-family:Roboto; font-size:14;"> <b>Category:</b> '+ record.category + '</div>',
                            '<div style="color: #12253a; font-family:Roboto; font-size:14;"> <b>Start Date:</b> '+ record.contract_start + '</div>',
                            '<div style="color: #12253a; font-family:Roboto; font-size:14;"> <b>Expiry:</b> '+ record.expiry + '</div>',
                            '<div style="color: #12253a; font-family:Roboto; font-size:14;"> <b>Government:</b> '+ record.government + '</div>',
                            '<div style="color: #12253a; font-family:Roboto; font-size:14;"> <b>Buyer:</b> '+ record.buyer + '</div>',
                            '<div style="color: #12253a; font-family:Roboto; font-size:14;"> <b>Supplier:</b> '+ record.supplier + '</div>'
                        ].join('');
                    },

                    axisPointer: {
                        show: true,
                        type: 'cross',
                        lineStyle: {
                            type: 'dashed',
                            width: 1
                        }
                    }
                },

                xAxis: [
                    {
                        type: 'time',
                        scale: true,
                        
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLabel: {
                            show: true,
                            margin: 10.5,
                            showMinLabel: true,
                            hideOverlap: true,
                            formatter: (value, index) => {
                                // Formatted to be month/day; display year only in the first labe

                                var date = new Date(value);

                                let qtr_num = Math.ceil((date.getMonth() + 1) / 3);

                                let qstart = this.getQuarterStart(qtr_num.toString());
                                //var texts = [("Q"+Math.ceil((date.getMonth() + 1) / 3)), date.getFullYear()];

                                var texts = [qstart, date.getFullYear()];

                                return texts.join('-');

                            },
                    
                            rich: {
                                yearStyle: {
                                    // Make yearly text more standing out
                                    color: '#000',
                                    fontWeight: 'normal',
                                    fontSize: 14
                                },
                                month: {
                                    color: '#999'
                                }
                            }
                        },
                        splitLine: {
                            show: false
                        },

                        name:"",
                        nameLocation: "end",
                        nameGap: 15

                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        axisLabel: {
                            showMinLabel: false,
                            showMaxLabel: true,
                            formatter : function(s) {
                                function convert (num){
                                    if (num > 999 && num < 1000000) {
                                        num = (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
                                    } else if (num >= 1000000 && num < 1000000000) {
                                        num = (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
                                    } else if (num >= 1000000000) {
                                        num = (num / 1000000000).toFixed(1) + "B"; // convert to M for number from > 1 million
                                    }

                                    return num;
                                }

                                return '{valueStyle|' + '$'+ convert(s) + '}';
                            },
                            rich: {
                                valueStyle: {
                                    // Make yearly text more standing out
                                    color: '#000',
                                    fontWeight: 'normal',
                                    fontSize: 14
                                }
                            }
                        },
                        splitLine: {
                            show: false
                        },
                        min: 20000
                        //max: 20000000
                    }
                ],
                series: this.series
                
            };

            this.myChart.setOption(option);
            this.option = option;
            this.formatDataTable();
        
        }
    }
  }
}

</script>
<style>
.reportScatter {
  margin-top: 0px !important;
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  width: 100% !important;
  min-height: 500px !important;
  /* padding: 0px, 0px, 0px, 0px !important; */
}
</style>