<template>
  <v-card flat :color="color" tile min-height="40" class="ma-0 pa-0">
    <v-card-text class="ma-0 pa-0">
      <v-combobox
        v-model="term_chips"
        small-chips
        :rounded="rounded"
        :solo="solo"
        clearable
        outlined
        dense
        label="Enter tab separated keywords"
        multiple
        small
        ref="keywords"
        append-outer-icon="mdi-magnify"
        :dark="dark"
        @click:append-outer="search()"
        @click:clear="clearSearch()"
      >
        <template v-slot:selection="{ item, select, selected }">
          <v-chip
            color="rgb(255, 20, 99)"
            small
            label
            :input-value="selected"
            close
            @click="select"
            @click:close="remove_kw(item)"
            >{{ item }}</v-chip
          >
        </template>
      </v-combobox>
    </v-card-text>
  </v-card>
</template>

<script>
import { bus } from "../main.js";
import { autocomplete, fetchProfile } from "./APIManager.js";
import { getArray } from "./Utils.js";

export default {
  props: {
    rounded: Boolean,
    solo: Boolean,
    dark: Boolean,
    color: String,
    govts: []
  },
  data() {
    return {
      term_chips: [],
      search_terms: "",
      items: [],
      isLoading: false,
      disable_nav: false,
      lookup_limit: 60,
      entity: {}
      //show_login_menu: false
    };
  },

  mounted() {

    var query = this.$route.query;

    if (query.hasOwnProperty("term")) {
      this.term_chips = getArray(query["term"]);
    }

  },

  watch: {
    search_terms(val) {
      val && val !== this.select && this.lookup(val);
    },

    govts: {
      handler() {
        ////////////console.log("IN TENDER RESULTS: ");
        if (govts.length == 0){
          this.clearSearch();
        }
      },
      deep: true
    }
  },

  methods: {
    
    remove_kw(item) {
      this.term_chips.splice(this.term_chips.indexOf(item), 1);
      this.term_chips = [...this.term_chips];
      if (this.term_chips.length == 0){
        this.clearSearch()
      }
    },

    clearSearch() {

      this.term_chips = [];
      this.$emit("clearFilter", true);
    },

    search(){
      //////console.log("IN SEARCH for: ", this.govts);

      if (this.term_chips.length > 0 || this.govts.length > 0) {

         ////////////console.log(this.term_chips);
         //this.$emit("filter-entities", this.entity);
         var qry = JSON.parse(JSON.stringify(this.$route.query));
         delete qry["term"];
         delete qry["page"];
         delete qry["government"];
         qry = {term: this.term_chips, government: this.govts}

         this.$router.push({ name: 'entities', query: qry});

         //this.$router.push({ name: 'entities', query: {...this.$route.query, term: this.term_chips, government: this.govts, page: page_num }});
         
      }
    },

    getPublisher(publisher_id) {
      this.$emit("close-entity-menu");
      this.$router.push({ name: "entity", params: { id: publisher_id } });
    },

    lookup(terms) {
      // Lazily load input items
      this.isLoading = true;
      autocomplete(this.search_terms, "publisher")
        .then(data => {
          data.unshift({ key: terms }); 
          this.items = data;
        })
        .finally(() => (this.isLoading = false));
    },

    disabled() {
      if (localStorage.getItem("isLoggedIn") == "1") {
        this.disable_nav = false;
        return this.disable_nav;
      } else {
        this.disable_nav = true;
        return this.disable_nav;
      }
    }
  }
};
</script>
