<template>
  <div class="bar-chart" ref="chart"></div>
</template>

<script>
import * as echarts from "echarts";
import { transparencyColor } from "./Utils.js";

export default {
  name: "BarChart",
  props: {
    transparency: {
      type: Object,
      required: true
    },
    jurisdictionTransparency: {
      type: Object,
      required: true
    },
    nationalTransparency: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      chart: null,
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
  methods: {
    initChart() {
      const chartDiv = this.$refs.chart;
      const chartInstance = echarts.init(chartDiv);

      // Prepare the data
      const data = [
        { value: this.transparency.value, entity: this.transparency.name, missing: 100 - this.transparency.value },
        { value: this.jurisdictionTransparency.value, entity: this.jurisdictionTransparency.name, missing: 100 - this.jurisdictionTransparency.value },
        { value: this.nationalTransparency.value, entity: this.nationalTransparency.name, missing: 100 - this.nationalTransparency.value }
      ];

      // Map each data point to color using the transparencyColor function
      const colors = data.map(item => transparencyColor(item.value));

      chartInstance.setOption({
        
        tooltip: {
            trigger: 'item',
            formatter: (params) => {
              if (params.seriesName == 'Data present'){
                return `${params.seriesName}: ${params.data.value.toFixed(1)}%`;
              }
              else{
                return `${params.seriesName}: ${params.data.missing.toFixed(1)}%`;
              }
            },
          },
        dataset: {
          source: data
        },
        grid: { 
          containLabel: true
         },
        xAxis: {
          //name:'%',
          axisLine: {
            show: false
          },
          splitLine: {
              show: false
          },
        },
        yAxis: { 
          type: 'category',
          inverse: true,
          axisTick: {
              show: false
          },
          axisLine: {
            show: false
          },
          splitLine: {
              show: false
          },
          axisLabel: {
            color: this.$vuetify.theme.currentTheme.secondary,
            formatter: (value) => {
                return value.length > 20 ? value.substr(0, 20) + '...' : value;
              }
          },
        },
          
        series: [
          {
            name: 'Data present',
            type: 'bar',
            stack: 'total',
            encode: {
              x: 'value',
              y: 'entity'
            },
            itemStyle: {
              color: (params) => colors[params.dataIndex]
            }
          },
          {
            name: 'Data missing',
            type: 'bar',
            stack: 'total',
            encode: {
              x: 'missing',
              y: 'entity'
            },
            itemStyle: {
              color: 'lightgray'
            }
          }
        ]
      });

      this.chart = chartInstance;
    }
  }
};
</script>

<style scoped>
.bar-chart {
  width: 100%;
  padding-top: 0px;
  height: 446px;
}
</style>
