<template>
  <div class="pl-1">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          v-bind="attrs"
          v-on="on"
          class="text-capitalize body-2"
          color="secondary"

        >
          Filters
          <v-icon large right>mdi-menu-down</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-content class="pb-0 mb-0">
              <v-list-item-subtitle>Filter the list</v-list-item-subtitle>
              <v-combobox
                v-model="keyword_chips"
                x-small-chips
                clearable
                label="Enter keywords"
                multiple
                small
                class="pb-0"
                ref="keywords"
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    small
                    color="rgb(255, 20, 99)"
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    @click="select"
                    @click:close="remove_kw(item)"
                    >{{ item }}</v-chip
                  >
                </template>
              </v-combobox>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-menu
              ref="expiryRangeMenu"
              v-model="expiryRangeMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="expiryRangeText"
                  label="Select an expiry date range"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-on="on"
                  class="body-2"
                  clearable
                  @click:clear="clear_contract_end()"
                ></v-text-field>
              </template>
              <v-date-picker v-model="expiryRange" range inp>
                <div class="flex-grow-1"></div>
                <v-btn text color="primary" @click="expiryRangeMenu = false"
                  >Cancel</v-btn
                >
                <v-btn
                  text
                  color="primary"
                  @click="$refs.expiryRangeMenu.save(expiryRangeText)"
                  >OK</v-btn
                >
              </v-date-picker>
            </v-menu>
          </v-list-item>

          <!-- <v-list-item>
            <v-range-slider
              :max="year_max"
              :min="year_min"
              thumb-label="always"
              class="pt-4 mt-4"
              color="#4EB888"
              v-model="years"
              label="Expiry"
            >
            </v-range-slider>
          </v-list-item> -->

          <v-list-item>
            <span v-for="item in type_options" :key="item.value">
              <v-checkbox
                class="pr-3"
                :label="item.value"
                color="#4EB888"
                :value="item.value"
                hide-details
                v-model="selected_types"
              ></v-checkbox>
            </span>
          </v-list-item>

          <v-list-item v-if="current_buyer_flag">
            <v-switch
              v-model="include_publisher"
              flat
              label="Current buyer notices only"
            ></v-switch>
          </v-list-item>
        </v-list>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="menu = false">
            Cancel
          </v-btn>
          <v-btn color="#41a7d9" text @click="update_search(), (menu = false)">
            Apply
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import moment from "moment";
import { bus } from "../main.js";

export default {
  props: {
    current_buyer_flag: Boolean
  },
  data: () => ({
    type_options: [{ value: "Contract Notice" }, { value: "Request" }, { value: "Grant" }, { value: "Grant Opportunity" }],
    fav: true,
    menu: false,
    message: false,
    hints: true,

    years: [2016, 2025],
    year_min: 2016,
    year_max: 2025,
    keyword_chips: [],
    keyword_items: [],
    selected_types: [],
    include_publisher: true,
    expiryRangeMenu: false,
    expiryRange: []
  }),

  computed: {
    expiryRangeText() {
      return this.expiryRange.join(" - ");
    }
  },

  methods: {
    year() {
      var d = new Date();
      return d.getFullYear();
    },

    remove_kw(item) {
      this.keyword_chips.splice(this.keyword_chips.indexOf(item), 1);
      this.keyword_chips = [...this.keyword_chips];
    },

    clear_expiryRange() {
      this.expiryRange = [];
    },

    update_search() {
      let additional_filters = {
        keywords: this.keyword_chips,
        expiry_from: this.expiryRange[0],
        expiry_to: this.expiryRange[1],
        type: this.selected_types,
        include_publisher: this.include_publisher
      };

      bus.$emit("update-filter", additional_filters);
    }
  }
};
</script>
