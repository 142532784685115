<template>
  <div>
    <v-list style="padding-bottom: 20px;">
      <v-list-item class="px-2 ma-0">
        <v-btn icon left href="https://docs.tendertrace.com/guide/user-documentation/searching-the-data/advanced-search" target="_blank">
                <v-icon color="white">mdi-help-circle-outline</v-icon>
            </v-btn>
        <v-list-item-title class="font-weight-bold subtitle-1"
          ><span>Advanced Search</span>
          </v-list-item-title>
        <v-btn icon @click="hideFilter()">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>
    <div
      style="width:100%"
      class="sidebarTopSubmit pt-4 pb-0 my-0"
      align="center"
    >
      <v-btn
        class="text-capitalize body-2 desktop-nav"
        color="white"
        outlined
        small
        width="80%"
        light
        @click="submitSearch()"
        >Run Search</v-btn
      >
      <v-btn
        class="text-capitalize body-2 mobile-nav"
        color="white"
        outlined
        small
        light
        width="80%"
        @click="hideFilter(), submitSearch()"
        >Run Search</v-btn
      >
      <div>
        <v-btn
          text
          class="caption text-capitalize"
          color="#2c9dd5"
          @click="reset_filters()"
          >Reset Filters</v-btn
        >
      </div>
    </div>
    <div class="sidebarExpander v-label">
      <v-expansion-panels multiple>
        <v-expansion-panel style="background-color:#12253a;">
          <v-expansion-panel-header class="font-weight-light subtitle-2 py-0"
            >Keywords</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <v-combobox
              dense
              v-model="keyword_chips"
              x-small-chips
              deletable-chips
              clearable
              label="Enter tab separated keywords"
              :items="supp_smart_tag_items"
              item-text="key"
              :search-input.sync="supp_smart_tags"
              multiple
              x-small
              ref="keywords"
              @keydown.enter.prevent="addKeywordChip({key: supp_smart_tags})"
              @keydown.tab.prevent="addKeywordChip({key: supp_smart_tags})"
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  color="rgb(255, 20, 99)"
                  x-small
                  label
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click="select"
                  @click:close="remove_kw(item)"
                  >{{ item.key }}</v-chip
                >
              </template>
            </v-combobox>
            <v-row class="pa-0 ma-0">
                <v-col class="pa-0 ma-0">
                  <v-subheader class="pl-0 ml-0 py-0 my-0">
                    <v-tooltip max-width="290" right dark color="#000000" style="opacity: 0.7;">
                      <template v-slot:activator="{ on }">
                        <span v-on="on">
                        <v-switch
                          v-model="exclude_panel"
                          label="Exclude panel notices"
                        ></v-switch>
                        </span>
                      </template>
                      <span>This setting will remove all notices awarded via panels or standing offers from the results.</span>
                    </v-tooltip>
                  </v-subheader>
                </v-col>
            </v-row>
            <v-row class="pt-0 pb-0 ma-0">
                <v-col class="pa-0 ma-0">
                  <v-subheader class="pl-0 ml-0 py-0 my-0">
                    <v-tooltip max-width="290" right dark color="#000000" style="opacity: 0.7;">
                      <template v-slot:activator="{ on }">
                        <span v-on="on">
                          <v-switch
                            v-model="isparent"
                            label="Amended notices only"
                          ></v-switch>
                        </span>
                      </template>
                      <span>This setting will only return federal government notices. States and territories at this point in time do not clearly publish amendments.</span>
                    </v-tooltip>
                  </v-subheader>
                </v-col>
              </v-row>
              <v-row class="pa-0 ma-0">
                <v-col class="pa-0 ma-0">
                  <v-subheader class="pl-0 ml-0 py-0 my-0">
                    <v-tooltip max-width="290" right dark color="#000000" style="opacity: 0.7;">
                      <template v-slot:activator="{ on }">
                        <span v-on="on">
                        <v-switch
                          v-model="exclude_grant"
                          label="Exclude grants"
                        ></v-switch>
                        </span>
                      </template>
                      <span>This setting will remove all grant and grant opportunities from the results.</span>
                    </v-tooltip>
                  </v-subheader>
                </v-col>
            </v-row>
              <v-tooltip max-width="290" right dark color="#000000" style="opacity: 0.7;">
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-subheader v-if="keyword_chips.length > 0" class="pl-0 ml-0 py-0 my-0">
                    <v-switch
                      v-model="supplier_description"
                      label="Search supplier description"
                    ></v-switch>
                  </v-subheader>
                </span>
              </template>
              <span>Include Tendertrace's enriched supplier descriptions in the keyword search. This can help reveal contracts that would otherwise not appear due to insufficient data being published by the public entity.</span>
            </v-tooltip>
            <v-tooltip max-width="290" right dark color="#000000" style="opacity: 0.7;">
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-subheader v-if="keyword_chips.length > 0" class="pl-0 ml-0 py-0 my-0">
                    <v-switch
                      v-model="supplier_smart_tags"
                      label="Include supplier smart tags"
                    ></v-switch>
                  </v-subheader>
                </span>
              </template>
              <span>Supplier smart tags are tags extracted from the supplier's web profile (websites, news, blogs, marketing etc). Smart tags can help significantly expand your search for contracts, however they may also return unrelated results.</span>
            </v-tooltip>

            <v-tooltip max-width="290" right dark color="#000000" style="opacity: 0.7;">
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-subheader v-if="keyword_chips.length > 0" class="pl-0 ml-0 py-0 my-0">
                    <v-switch
                      v-model="panel_metadata"
                      label="Include panel metadata in search"
                    ></v-switch>
                  </v-subheader>
                    
                </span>
              </template>
              <span>Include metadata for panels in the search, including panel title, description, and lead agency.</span>
            </v-tooltip>
            
            <v-combobox
              v-model="request_chips"
              x-small-chips
              clearable
              label="Notice Reference ID(s)"
              multiple
              x-small
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  color="rgb(255, 20, 99)"
                  small
                  label
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click="select"
                  @click:close="remove_request(item)"
                  >{{ item }}</v-chip
                >
              </template>
            </v-combobox>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel style="background-color:#12253a;">
          <v-expansion-panel-header class="font-weight-light subtitle-2 py-0"
            >Panels / Standing Offers</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <v-row class="pa-0 ma-0">
                <v-col class="pa-0 ma-0">
                  <v-subheader class="pl-0 ml-0 py-0 my-0">
                    <v-tooltip max-width="290" right dark color="#000000" style="opacity: 0.7;">
                      <template v-slot:activator="{ on }">
                        <span v-on="on">
                          <v-switch
                            v-model="ispanel"
                            label="Panel/Standing Offer results only"
                          ></v-switch>
                        </span>
                      </template>
                      <span>This setting will only return federal government notices. States and territories at this point in time do not clearly publish amendments.</span>
                    </v-tooltip>
                  </v-subheader>
                </v-col>
              </v-row>
            <v-row class="pa-0 ma-0">
              <v-col class="pa-0 ma-0">
                <v-combobox
                  v-model="panel_chips"
                  x-small-chips
                  clearable
                  label="Panel/Standing Offer ID(s)"
                  multiple
                  x-small
                >
                  <template v-slot:selection="{ attrs, item, select, selected }">
                    <v-chip
                      color="rgb(255, 20, 99)"
                      small
                      v-bind="attrs"
                      :input-value="selected"
                      close
                      label
                      @click="select"
                      @click:close="remove_panel(item)"
                      >{{ item }}</v-chip
                    >
                  </template>
                </v-combobox>
              </v-col>
              </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel style="background-color:#12253a;">
          <v-expansion-panel-header class="font-weight-light subtitle-2 py-0"
            >Timeframe</v-expansion-panel-header
          >

          <v-expansion-panel-content>
            <v-card color="#12253a" flat tile class="py-0 my-0">
              <v-row class="pa-0 ma-0">
                <v-col class="pa-0 ma-0">
                  <v-subheader class="pl-0 ml-0 py-0 my-0">
                    <v-switch
                      v-model="contract_start_enabled"
                      label="Include results with start dates only"
                    ></v-switch>
                  </v-subheader>
                </v-col>
              </v-row>
              <v-card-text class="py-2 my-0">
                <v-row class="pb-0 mb-o">
                  <v-col class="text-left pl-0 ml-0 pb-0 mb-o">
                    <span
                      class="title font-weight-normal"
                      v-text="years[0]"
                      style="color: white"
                    ></span>
                  </v-col>
                  <v-col class="text-right pr-0 mr-0 pb-0 mb-o">
                    <span
                      class="title font-weight-normal"
                      v-text="years[1]"
                      style="color: white"
                    ></span>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-range-slider
                :disabled="!contract_start_enabled"
                :max="year_max"
                :min="year_min"
                class="py-0 mt-0"
                color="rgb(255, 20, 99)"
                v-model="years"
                append-icon="mdi-plus"
                prepend-icon="mdi-minus"
                @end="set_contract_month_start(contract_month_start.name), set_contract_month_end(contract_month_end.name)"
              >
              </v-range-slider>
            </v-card>
            <v-row class="py-2">
              <div class="d-inline pl-4 mr-5 pt-0">
                <p class="mb-0 pb-0 subtitle-2 font-weight-normal">From</p>
                <v-menu :disabled="!contract_start_enabled" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    class="text-capitalize body-1 mb-5"
                      color="#E0E0E0"
                      :disabled="!contract_start_enabled"
                      outlined
                      small
                      v-bind="attrs"
                      v-on="on"
                      width="130"
                      style="justify-content:left !important;"
                    >
                      <span>{{contract_month_start.name}}</span>
                      <v-icon right>mdi-menu-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, value) in months"
                      :key="value"
                      @click="set_contract_month_start(item)"
                    >
                      <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <div class="d-inline mr-5 pt-0">
                <p class="mb-0 pb-0 subtitle-2 font-weight-normal">To</p>
                <v-menu :disabled="!contract_start_enabled" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    class="text-capitalize body-1 mb-5"
                      color="#E0E0E0"
                      :disabled="!contract_start_enabled"
                      outlined
                      small
                      v-bind="attrs"
                      v-on="on"
                      width="130"
                      style="justify-content:left !important;"
                    >
                      <span>{{contract_month_end.name}}</span>
                      <v-icon right>mdi-menu-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, value) in months"
                      :key="value"
                      @click="set_contract_month_end(item)"
                    >
                      <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-row>
            <v-divider></v-divider>
            <v-card color="#12253a" flat tile class="py-0 my-0">
              <v-row class="pa-0 ma-0">
                <v-col class="pa-0 ma-0">
                  <v-subheader class="pl-0 ml-0 pt-3 mt-3">
                    <v-switch
                      v-model="contract_end_enabled"
                      label="Include results with expiry dates only"
                    ></v-switch>
                  </v-subheader>
                </v-col>
              </v-row>
              <v-card-text class="py-2 my-0">
                <v-row class="pb-0 mb-o">
                  <v-col class="text-left pl-0 ml-0 pb-0 mb-o">
                    <span
                      class="title font-weight-normal"
                      v-text="end_years[0]"
                      style="color: white"
                    ></span>
                  </v-col>
                  <v-col class="text-right pr-0 mr-0 pb-0 mb-o">
                    <span
                      class="title font-weight-normal"
                      v-text="end_years[1]"
                      style="color: white"
                    ></span>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-range-slider
                :disabled="!contract_end_enabled"
                :max="end_year_max"
                :min="end_year_min"
                class="pt-0 mt-0"
                color="rgb(255, 20, 99)"
                v-model="end_years"
                append-icon="mdi-plus"
                prepend-icon="mdi-minus"
                @end="set_expiry_month_start(expiry_month_start.name), set_expiry_month_end(expiry_month_end.name)"
              >
              </v-range-slider>
            </v-card>
            <v-row class="py-2">
              <div class="d-inline pl-4 mr-5 pt-0">
                <p class="mb-0 pb-0 subtitle-2 font-weight-normal">From</p>
                <v-menu :disabled="!contract_end_enabled" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    class="text-capitalize body-1 mb-5"
                      color="#E0E0E0"
                      :disabled="!contract_end_enabled"
                      outlined
                      small
                      v-bind="attrs"
                      v-on="on"
                      width="130"
                      style="justify-content:left !important;"
                    >
                      <span>{{expiry_month_start.name}}</span>
                      <v-icon right>mdi-menu-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, value) in months"
                      :key="value"
                      @click="set_expiry_month_start(item)"
                    >
                      <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <div class="d-inline mr-5 pt-0">
                <p class="mb-0 pb-0 subtitle-2 font-weight-normal">To</p>
                <v-menu :disabled="!contract_end_enabled" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    class="text-capitalize body-1 mb-5"
                      color="#E0E0E0"
                      :disabled="!contract_end_enabled"
                      outlined
                      small
                      v-bind="attrs"
                      v-on="on"
                      width="130"
                      style="justify-content:left !important;"
                    >
                      <span>{{expiry_month_end.name}}</span>
                      <v-icon right>mdi-menu-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, value) in months"
                      :key="value"
                      @click="set_expiry_month_end(item)"
                    >
                      <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-row>
            <divider></divider>
            <v-card color="#12253a" flat tile class="py-0 my-0">
              <v-row class="pa-0 ma-0">
                <v-col class="pa-0 ma-0">
                  <v-tooltip top max-width="400" class="pa-0 ma-0">
                      <template v-slot:activator="{ on }">
                        <v-subheader v-on="on" class="pl-0 ml-0 py-0 my-0">
                          <span>
                          <v-switch
                            v-model="published_date_enabled"
                            label="Include results with published dates"
                          ></v-switch>
                        </span>
                        
                  </v-subheader>
                </template>
                    <span class="caption">
                    Use this option to filter <b>Federal (FED)</b> government contracts and requests <b>ONLY</b>. State and Territory governments are not consistent in reporting published dates of contracts which will cause discrepancies in your results.
                    </span>
                </v-tooltip>
                </v-col>
              </v-row>
              <v-card-text class="py-2 my-0">
                <v-row class="pb-0 mb-o">
                  <v-col class="text-left pl-0 ml-0 pb-0 mb-o">
                    <span
                      class="title font-weight-normal"
                      v-text="pub_years[0]"
                      style="color: white"
                    ></span>
                  </v-col>
                  <v-col class="text-right pr-0 mr-0 pb-0 mb-o">
                    <span
                      class="title font-weight-normal"
                      v-text="pub_years[1]"
                      style="color: white"
                    ></span>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-range-slider
                :disabled="!published_date_enabled"
                :max="pub_year_max"
                :min="pub_year_min"
                class="py-0 mt-0"
                color="rgb(255, 20, 99)"
                v-model="pub_years"
                append-icon="mdi-plus"
                prepend-icon="mdi-minus"
                @end="set_published_month_start(published_month_start.name), set_published_month_end(published_month_end.name)"
              >
              </v-range-slider>
            </v-card>
            <v-row class="py-2">
              <div class="d-inline pl-4 mr-5 pt-0">
                <p class="mb-0 pb-0 subtitle-2 font-weight-normal">From</p>
                <v-menu :disabled="!published_date_enabled" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    class="text-capitalize body-1 mb-5"
                      color="#E0E0E0"
                      :disabled="!published_date_enabled"
                      outlined
                      small
                      v-bind="attrs"
                      v-on="on"
                      width="130"
                      style="justify-content:left !important;"
                    >
                      <span>{{published_month_start.name}}</span>
                      <v-icon right>mdi-menu-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, value) in months"
                      :key="value"
                      @click="set_published_month_start(item)"
                    >
                      <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <div class="d-inline mr-5 pt-0">
                <p class="mb-0 pb-0 subtitle-2 font-weight-normal">To</p>
                <v-menu :disabled="!published_date_enabled" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    class="text-capitalize body-1 mb-5"
                      color="#E0E0E0"
                      :disabled="!published_date_enabled"
                      outlined
                      small
                      v-bind="attrs"
                      v-on="on"
                      width="130"
                      style="justify-content:left !important;"
                    >
                      <span>{{published_month_end.name}}</span>
                      <v-icon right>mdi-menu-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, value) in months"
                      :key="value"
                      @click="set_published_month_end(item)"
                    >
                      <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel style="background-color:#12253a;">
          <v-expansion-panel-header class="font-weight-light subtitle-2 py-0"
            >Category</v-expansion-panel-header
          >

          <v-expansion-panel-content>
            <v-row class="pt-0 pb-4 ma-0">
                <v-col class="pa-0 ma-0">
                  <v-subheader class="pl-0 ml-0 py-0 my-0">
                    <v-switch
                      v-model="isconsultancy"
                      label="Classified as Consultancy"
                    ></v-switch>
                  </v-subheader>
                </v-col>
              </v-row>
              <v-tooltip top max-width="400" class="pa-0 ma-0">
                <template v-slot:activator="{ on, attrs }">
                  <v-combobox
                    v-model="selected_parent_categories"
                    x-small-chips
                    :items="parent_category_options"
                    item-text="name"
                    clearable
                    dense
                    label="Major category"
                    multiple
                    x-small
                    :menu-props="{ maxWidth: '350', maxHeight: '300' }"
                    ref="parent_categories"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:selection="{ attrs, item, select, selected }">
                      <v-chip
                        color="rgb(255, 20, 99)"
                        x-small
                        v-bind="attrs"
                        :input-value="selected"
                        close
                        label
                        @click="select"
                        @click:close="remove_major_category(item)"
                      ><span class="chip-label">{{ item.name }}</span></v-chip>
                    </template>
                  </v-combobox>
                </template>
                <span>
                  <div class="body-1 font-weight-bold">A note about filtering by major categories</div> 
                  <div class="caption pt-1">The Major Category filter <b>will only work</b> for contracts that have been tagged with the correct major category based on the <b>UNSPC standard</b>. In general, <b>only the Federal government</b> uses the UNSPC standard consistently. This means that using this filter will possibly result in a subset of the actual results being returned. </div>
                  <div class="caption pt-1">While useful, if your search extends beyond only Federal contracts, you may also want to run a separate search using on the category filter below to compare results.</div>
                </span>
              </v-tooltip>

              <v-tooltip top max-width="400" class="pa-0 ma-0">
                <template v-slot:activator="{ on, attrs }">
                  <v-combobox
                      v-model="selected_category_list"
                      :items="category_list"
                      :loading="loadingCategoryLists"
                      :search-input.sync="search_category_lists"
                      label="Category"
                      deletable-chips
                      x-small-chips
                      dense
                      x-small
                      clearable
                      multiple
                      v-bind="attrs"
                      v-on="on"
                      :menu-props="{ maxWidth: '350', maxHeight: '300' }"
                      >
                        <template v-slot:selection="{ attrs, item, select, selected }">
                        <v-chip
                          color="rgb(255, 20, 99)"
                          label
                          small
                          v-bind="attrs"
                          :input-value="selected"
                          close
                          @click="select"
                          @click:close="remove_category_list(item)"
                        >
                          <span class="chip-label">{{ item }}</span></v-chip
                        >
                      </template>
                </v-combobox>
              </template>
                <span>
                  <div class="body-2">Start typing to select a category. Press ENTER to select the keyword you have typed</div> 
                </span>
              </v-tooltip>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel style="background-color:#12253a;">
          <v-expansion-panel-header class="font-weight-light subtitle-2 py-0"
            >Buyer</v-expansion-panel-header
          >
          <v-expansion-panel-content>
            <cluster-list :entityType="'publisher'" :injectedClusters="injectPubClusters" :entityIDType="'publisher_id'" @updateCluster="updatePubCluster" />
            <v-combobox
                v-model="selected_publisher_industry"
                :items="publisher_industry_list"
                :loading="loadingIndustries"
                :search-input.sync="search_industries"
                label="Industry sector"
                deletable-chips
                x-small-chips
                dense
                x-small
                clearable
                multiple
                :menu-props="{ maxWidth: '350', maxHeight: '300' }"
                >
                  <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    color="rgb(255, 20, 99)"
                    small
                    label
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    @click="select"
                    @click:close="remove_buyer_industry(item)"
                  >
                    <span class="chip-label">{{ item }}</span></v-chip
                  >
                </template>
          </v-combobox>

            <v-combobox
              dense
              deletable-chips
              v-model="agency_name_chips"
              x-small-chips
              :items="pub_items"
              item-text="key"
              :search-input.sync="pub_search"
              clearable
              label="Include these entity names"
              multiple
              x-small
              :menu-props="{ maxWidth: '350', maxHeight: '300' }"
              @keydown.enter.prevent="addBuyerChip({key: pub_search})"
              @keydown.tab.prevent="addBuyerChip({key: pub_search})"
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  color="rgb(255, 20, 99)"
                  small
                  label
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click="select"
                  @click:close="remove_agency_name(item)"
                >
                  <span class="chip-label">{{ item.key }}</span></v-chip
                >
              </template>
            </v-combobox>
            <v-combobox
              dense
              v-model="agency_name_chips_exclude"
              x-small-chips
              deletable-chips
              :items="exclude_pub_items"
              item-text="key"
              :search-input.sync="exclude_pub_search"
              clearable
              label="Exclude these entity names"
              multiple
              x-small
              :menu-props="{ maxWidth: '350', maxHeight: '300' }"
              @keydown.enter.prevent="addExcludeBuyerChip({key: exclude_pub_search})"
              @keydown.tab.prevent="addExcludeBuyerChip({key: exclude_pub_search})"
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  color="rgb(255, 20, 99)"
                  small
                  label
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click="select"
                  @click:close="remove_excluded_agency_name(item)"
                >
                  <span class="chip-label">{{ item.key }}</span></v-chip
                >
              </template>
            </v-combobox>
              
            <v-combobox
              dense
              class="pt-1"
              v-model="tendle_pub_id_chips"
              :items="tendle_pub_id_items"
              x-small-chips
              deletable-chips
              clearable
              label="Enter one or more tendertrace IDs"
              multiple
              x-small
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  color="rgb(255, 20, 99)"
                  small
                  label
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click="select"
                  @click:close="remove_tendle_pub_id(item)"
                  >{{ item }}</v-chip
                >
              </template>
            </v-combobox>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel style="background-color:#12253a;">
          <v-expansion-panel-header class="font-weight-light subtitle-2 py-0"
            >Supplier</v-expansion-panel-header
          >

          <v-expansion-panel-content>
            <cluster-list :injectedClusters="injectSuppClusters" :entityType="'supplier'" :entityIDType="'supplier_id'" @updateCluster="updateSuppCluster" />
            <v-combobox
              dense
              v-model="supplier_industry_chips"
              x-small-chips
              deletable-chips
              :items="supp_industry_items"
              item-text="key"
              :search-input.sync="supp_industry_search"
              clearable
              label="Industry"
              multiple
              x-small
              :menu-props="{ maxWidth: '350', maxHeight: '300' }"
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-on="on"
                  color="rgb(255, 20, 99)"
                  small
                  label
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click="select"
                  @click:close="remove_supp_industry(item)"
                  ><span class="chip-label">{{ item.key }}</span></v-chip
                >
              </template>
            </v-combobox>
            <v-combobox
              dense
              v-model="supplier_name_chips"
              x-small-chips
              deletable-chips
              :items="supp_items"
              item-text="key"
              :search-input.sync="supp_search"
              clearable
              label="Include these entity names"
              multiple
              x-small
              :menu-props="{ maxWidth: '350', maxHeight: '300' }"
              @keydown.enter.prevent="addSupplierChip({key: supp_search})"
              @keydown.tab.prevent="addSupplierChip({key: supp_search})"
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-on="on"
                  color="rgb(255, 20, 99)"
                  small
                  v-bind="attrs"
                  label
                  :input-value="selected"
                  close
                  @click="select"
                  @click:close="remove_supp_name(item)"
                  ><span class="chip-label">{{ item.key }}</span></v-chip
                >
              </template>
            </v-combobox>
            <v-combobox
              dense
              v-model="supplier_name_chips_exclude"
              x-small-chips
              deletable-chips
              :items="exclude_supp_items"
              item-text="key"
              :search-input.sync="exclude_supp_search"
              clearable
              label="Exclude these entity names"
              multiple
              x-small
              :menu-props="{ maxWidth: '350', maxHeight: '300' }"
              @keydown.enter.prevent="addSupplierExcludeChip({key: exclude_supp_search})"
              @keydown.tab.prevent="addSupplierExcludeChip({key: exclude_supp_search})"
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  color="rgb(255, 20, 99)"
                  small
                  label
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click="select"
                  @click:close="remove_excluded_supp_name(item)"
                >
                  <span class="chip-label">{{ item.key }}</span></v-chip
                >
              </template>
            </v-combobox>
            <v-combobox
              v-model="supplier_id_chips"
              x-small-chips
              clearable
              label="Enter supplier ABN(s)"
              multiple
              x-small
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  color="rgb(255, 20, 99)"
                  small
                  label
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click="select"
                  @click:close="remove_supp_id(item)"
                  >{{ item }}</v-chip
                >
              </template>
            </v-combobox>
            <v-combobox
              class="pt-1"
              v-model="tendle_supp_id_chips"
              :items="tendle_supp_id_items"
              x-small-chips
              deletable-chips
              clearable
              label="Enter one or more tendertrace IDs"
              multiple
              x-small
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  color="rgb(255, 20, 99)"
                  small
                  label
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click="select"
                  @click:close="remove_tendle_supp_id(item)"
                  >{{ item }}</v-chip
                >
              </template>
            </v-combobox>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel style="background-color:#12253a;">
          <v-expansion-panel-header class="font-weight-light subtitle-2 py-0"
            >Award Amount</v-expansion-panel-header
          >

          <v-expansion-panel-content>
            <div class="sidebarTopSubmit">
              <v-row>
                <v-col class="pt-0">
                  <v-text-field
                    clearable
                    v-model="amount_min"
                    label="Min"
                  ></v-text-field>
                </v-col>
                <v-col class="pt-0">
                  <v-text-field
                    clearable
                    v-model="amount_max"
                    label="Max"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel style="background-color:#12253a;">
          <v-expansion-panel-header class="font-weight-light subtitle-2 py-0"
            >Type of Notice</v-expansion-panel-header
          >

          <v-expansion-panel-content>
            <v-tooltip top max-width="400" class="pa-0 ma-0">
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <div class="v-label">
                      <ul style="list-style: none; padding-left:0;">
                        <li v-for="item in type_options" :key="item.value">
                          <v-checkbox
                            :label="item.name"
                            color="rgb(255, 20, 99)"
                            :value="item.value"
                            hide-details
                            v-model="selected_types"
                          ></v-checkbox>
                        </li>
                      </ul>
                    </div>
                  </span>
                </template>
                <span>
                  <div class="body-1 font-weight-bold">A note about Type filters</div> 
                  <div class="caption pt-1">The <b>Grant</b>, <b>Grant Opportunity</b> and <b>Planned Procurement</b> filters <b>will likely only</b> return federal government data. This is due to the inconsistent nature and availability of state and territory grant and planned procurement data.</div> 
                </span>
              </v-tooltip>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel style="background-color:#12253a;">
          <v-expansion-panel-header class="font-weight-light subtitle-2 py-0"
            >Government</v-expansion-panel-header
          >

          <v-expansion-panel-content>
            <div class="v-label">
              <ul style="list-style: none; padding-left:0;">
                <li v-for="item in territory_options" :key="item.value">
                  <v-checkbox
                    :loading="loadingGovts"
                    :label="item.value"
                    color="rgb(255, 20, 99)"
                    :value="item.value"
                    hide-details
                    v-model="selected_territories"
                  ></v-checkbox>
                </li>
              </ul>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel style="background-color:#12253a;">
          <v-expansion-panel-header class="font-weight-light subtitle-2 py-0"
            >Procurement Method</v-expansion-panel-header
          >

          <v-expansion-panel-content>
            <v-combobox
                v-model="selected_procurement_method"
                :items="procurement_method_list"
                :loading="loadingProcurementMethods"
                :search-input.sync="search_procurement_methods"
                label="Select one or more options"
                deletable-chips
                x-small-chips
                dense
                x-small
                clearable
                multiple
                :menu-props="{ maxWidth: '350', maxHeight: '300' }"
                >
                  <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    color="rgb(255, 20, 99)"
                    small
                    label
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    @click="select"
                    @click:close="remove_procurement_method(item)"
                  >
                    <span class="chip-label">{{ item }}</span></v-chip
                  >
                </template>
          </v-combobox>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <div style="width:100%" class="sidebarTopSubmit py-0 my-0" align="center">
      <v-btn
        class="text-capitalize body-2"
        color="white"
        outlined
        small
        width="80%"
        light
        @click="submitSearch()"
        >Run Search</v-btn
      >
      <div>
        <v-btn
          text
          class="caption text-capitalize"
          color="#2c9dd5"
          @click="reset_filters()"
          >Reset Filters</v-btn
        >
      </div>
    </div>
    <v-dialog v-model="major_category_disclaimer" 
      max-width="500px"
      >
      <div>
        <v-alert
          v-model="major_category_disclaimer"
          :border="'left'"
          close-text="Close Alert"
          color="white accent-4"
          dismissible
          class="mb-0 body-2"
        >
        <v-card flat class="pa-0">
          <v-card-subtitle class="body-1 font-weight-bold">A note about filtering by major categories</v-card-subtitle> 
          <v-card-text class="caption pt-0">The Major Category filter will only work for contracts that have been tagged with the correct major category based on the UNSPC standard. In general, only the Federal government uses the UNSPC standard consistently. This means that using this filter will likely only result in Federal government contracts being returned. </v-card-text>
          <v-card-text class="caption pt-0">While useful, you may also want to run a separate search using on the category keyword filters to compare results.</v-card-text>
        
        </v-card>
        
        </v-alert>
      </div>
      
    </v-dialog>
  </div>
</template>

<script>
import { bus } from "../main.js";
//import TenderSearch from "./TenderSearch.vue";
import { autocomplete, getSubCategories, fetchBuyerIndustries, fetchCategoryList, fetchProcurementMethods, fetchGovTypes } from "./APIManager.js";
import json from './data/categories.json';
import ClusterList from "./ClusterList.vue";
import { getInstance } from "../auth";

export default {
  components: {
    ClusterList
  },
  props: {
    source: String,
    mini: Boolean
  },
  data() {
    return {
      disclaimer_msg_count: 0,
      major_category_disclaimer: false,
      ispanel: false,
      exclude_panel: false,
      exclude_grant: false,
      isconsultancy: false,
      isparent: false,
      supplier_smart_tags: false,
      supplier_description: false,
      panel_metadata: false,
      injectSuppClusters: [],
      injectPubClusters: [],
      search_industries: "",
      publisher_industry_list: [],
      selected_publisher_industry: [],
      selected_procurement_method: [],
      selected_category_list: [],
      procurement_method_list: [],
      category_list: [],
      search_procurement_methods: "",
      search_category_lists: "",
      loadingProcurementMethods: Boolean(),
      loadingCategoryLists: Boolean(),
      loadingIndustries: Boolean(),
      loadingGovts: Boolean(),
      selected_months: [],
      showMenu: false,
      contract_month_start: { name: "July", date: new Date(2005, 6).toISOString().substr(0, 10) },
      contract_month_end: { name: "June", date: new Date(2030, 5, 30).toISOString().substr(0, 10) },
      published_month_start: { name: "July", date: new Date(2005, 6).toISOString().substr(0, 10) },
      published_month_end: { name: "June", date: new Date(2030, 5, 30).toISOString().substr(0, 10) },
      expiry_month_start: { name: "July", date: new Date(2005, 6).toISOString().substr(0, 10) },
      expiry_month_end: { name: "June", date: new Date(2030, 5, 30).toISOString().substr(0, 10) },
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      exclude_publisher: false,
      exclude_supplier: false,
      selected_supp_clusters:[],
      selected_pub_clusters:[],
      contract_start_enabled: false,
      contract_end_enabled: false,
      published_date_enabled: false,
      years: [2005, this.year() + 10],
      year_min: 2005,
      year_max: 2050,
      end_years: [2005, this.year() + 10],
      end_year_min: 2005,
      end_year_max: 2050,
      pub_years: [2005, this.year() + 10],
      pub_year_min: 2005,
      pub_year_max: 2050,
      cat_search: "",
      pub_search: "",
      exclude_pub_search: "",
      supp_search: "",
      exclude_supp_search: "",
      supp_smart_tags: "",
      supp_smart_tag_items: [],
      cat_items: [],
      pub_items: [],
      exclude_pub_items: [],
      supp_items: [],
      supp_industry_items: [],
      supplier_industry_chips: [],
      supp_industry_search: "",
      exclude_supp_items: [],
      isLoading: false,
      search_params: {},
      published_range: [],
      published_to: "",
      published_from: "",
      closing_range: [],
      closing_to: "",
      closing_from: "",
      contract_start_range: [],

      contract_end_range: [],
      contract_start_from: "",
      contract_start_to: "",
      contract_end_from: "",
      contract_end_to: "",
      drawer: "",
      keyword_chips: [],
      keyword_items: [],
      curr_date: new Date().toISOString().substr(0, 10),
      contractStartMenu: false,
      contractEndMenu: false,
      modal: false,
      publishedDateMenu: false,
      closingDateMenu: false,
      amount_index: null,
      amount_min: null,
      amount_max: null,
      custom_amount: false,
      supplier_id_chips: [],
      supplier_id_items: [],
      tendle_cat_id_chips: [],
      tendle_cat_id_items: [],
      tendle_supp_id_chips: [],
      tendle_supp_id_items: [],
      tendle_pub_id_chips: [],
      tendle_pub_id_items: [],
      supplier_name_chips: [],
      supplier_name_items: [],
      agency_name_chips: [],
      agency_name_chips_exclude: [],
      supplier_name_chips_exclude: [],
      agency_name_items: [],
      category_chips: [],
      category_items: [],
      request_chips: [],
      panel_chips: [],
      request_items: [],
      selected_territories: [],
      parent_category_options: json.data,
      selected_parent_categories: [],
      territory_options: [],
      selected_statuses: [],
      status_options: [
        { value: "Current" },
        { value: "Expiring Soon" },
        { value: "Expired" },
        { value: "Unknown" }
      ],
      selected_types: [],
      type_options: [{name: "Contract Notice", value: "Contract Notice" }, { name: "Request / Approach To Market", value: "Request" }, { name: "Planned Procurement", value: "Planned" }, {name: "Grant", value: "Grant" }, { name: "Grant Opportunity", value: "Grant Opportunity" }],
      predefined_amount: [
        { min: 0, max: 1000000 },
        { min: 1000001, max: 5000000 },
        { min: 5000001, max: 20000000 },
        { min: 20000001, max: 100000000 },
        { min: 100000001, max: 1000000000 }
      ]
    };
  },

  methods: {

    addKeywordChip(value) {
      if (value && !this.keyword_chips.includes(value)) {
        this.keyword_chips.push(value);
        this.supp_smart_tags = '';
      }
    },

    addBuyerChip(value) {
      if (value && !this.agency_name_chips.includes(value)) {
        this.agency_name_chips.push(value);
        this.pub_search = '';
      }
    },

    addExcludeBuyerChip(value) {
      if (value && !this.agency_name_chips_exclude.includes(value)) {
        this.agency_name_chips_exclude.push(value);
        this.exclude_pub_search = '';
      }
    },

    addSupplierChip(value) {
      if (value && !this.supplier_name_chips.includes(value)) {
        this.supplier_name_chips.push(value);
        this.supp_search = '';
      }
    },

    addSupplierExcludeChip(value) {
      if (value && !this.supplier_name_chips_exclude.includes(value)) {
        this.supplier_name_chips_exclude.push(value);
        this.exclude_supp_search = '';
      }
    },

    showDisclaimer(){
      
      if (this.disclaimer_msg_count == 0){
        this.major_category_disclaimer = true
      }
      else{
        this.major_category_disclaimer = false
      }

      this.disclaimer_msg_count++;

    },

    updatePubCluster(list){
      this.selected_pub_clusters = list;
      //////////////////console.log("pub cluster: ", this.selected_pub_clusters);
    },

    updateSuppCluster(list){
      this.selected_supp_clusters = list;
      //////////////////console.log("pub cluster: ", this.selected_pub_clusters);
    },

    async update_subcategories(){
      let targets = [];
      for (var i in this.selected_parent_categories){
        targets[i] = this.selected_parent_categories[i].code;
      }
      this.cat_items = await getSubCategories(targets).data;

      //////////////////console.log("SUB CATS: ", this.cat_items);
    },

    set_contract_month_start(month){

      var index = this.months.indexOf(month);

      var dte = new Date(this.years[0], index, 1);

      var converted_date = dte.getFullYear()+"-"+(dte.getMonth()+1).toString().padStart(2, '0')+"-"+dte.getDate().toString().padStart(2, '0');
      
      this.contract_month_start.name = month;
      this.contract_month_start.date = converted_date; //this.years[0]

      //////////////////console.log("start month: ", this.contract_month_start, " YEARS[0]: ", this.years[0]);

    },

    set_published_month_start(month){

      var index = this.months.indexOf(month);

      var dte = new Date(this.pub_years[0], index, 1);

      var converted_date = dte.getFullYear()+"-"+(dte.getMonth()+1).toString().padStart(2, '0')+"-"+dte.getDate().toString().padStart(2, '0');

      this.published_month_start.name = month;
      this.published_month_start.date = converted_date; //this.years[0]

      //////////////////console.log("start month: ", this.contract_month_start, " YEARS[0]: ", this.years[0]);

    },

    set_published_month_end(month){

      var index = this.months.indexOf(month);

      var lastday = function(y,m){
        return  new Date(y, m +1, 0).getDate();
      }

      var dte = new Date(this.pub_years[1], index, lastday(this.pub_years[1], index))

      var converted_date = dte.getFullYear()+"-"+(dte.getMonth()+1).toString().padStart(2, '0')+"-"+dte.getDate().toString().padStart(2, '0');

      this.published_month_end.name = month; //this.years[1]
      this.published_month_end.date = converted_date;

      //////////////////console.log("end month: ", this.contract_month_end, " YEARS[1]: ", this.years[1]);

    },

    set_contract_month_end(month){

      var index = this.months.indexOf(month);

      var lastday = function(y,m){
        return  new Date(y, m +1, 0).getDate();
      }

      var dte = new Date(this.years[1], index, lastday(this.years[1], index))

      var converted_date = dte.getFullYear()+"-"+(dte.getMonth()+1).toString().padStart(2, '0')+"-"+dte.getDate().toString().padStart(2, '0');

      this.contract_month_end.name = month; //this.years[1]
      this.contract_month_end.date = converted_date;

      //////////////////console.log("end month: ", this.contract_month_end, " YEARS[1]: ", this.years[1]);

    },

    set_expiry_month_start(month){

      var index = this.months.indexOf(month);

      var dte = new Date(this.end_years[0], index, 1);

      var converted_date = dte.getFullYear()+"-"+(dte.getMonth()+1).toString().padStart(2, '0')+"-"+dte.getDate().toString().padStart(2, '0');
      
      this.expiry_month_start.name = month;
      this.expiry_month_start.date = converted_date; //this.years[0]

      //////////////////console.log("start month: ", this.expiry_month_start);

    },

    set_expiry_month_end(month){

      var index = this.months.indexOf(month);

      var lastday = function(y,m){
        return  new Date(y, m +1, 0).getDate();
      }

      var dte = new Date(this.end_years[1], index, lastday(this.years[1], index))

      var converted_date = dte.getFullYear()+"-"+(dte.getMonth()+1).toString().padStart(2, '0')+"-"+dte.getDate().toString().padStart(2, '0');

      this.expiry_month_end.name = month; //this.years[1]
      this.expiry_month_end.date = converted_date;

      //////////////////console.log("end month: ", this.expiry_month_end);

    },


    showHideMini() {
      bus.$emit("mini", true);
    },

    remove_blank_chip(chips) {
      var filtered = chips.filter(function(value, index, arr) {
        return value != null;
      });
      return filtered;
    },

    hideFilter() {
      //this.showMenu = false;
      this.$emit("showNav");
    },

    year() {
      var d = new Date();
      return d.getFullYear();
    },

    clear_contract_start() {
      this.contract_start_range = [];
    },
    clear_years() {
      this.years = [2005, 2030];
      this.contract_start_enabled = false;
      this.end_years = [2005, 2030];
      this.pub_years = [2005, 2030];
      this.contract_end_enabled = false;
      this.published_date_enabled = false;
    },
    clear_contract_end() {
      this.contract_end_range = [];
    },
    clear_selected_months() {
      this.selected_months = [];
    },
    clear_published_date() {
      this.published_range = [];
    },
    clear_closing_date() {
      this.closing_range = [];
    },
    remove_kw(item) {
      this.keyword_chips.splice(this.keyword_chips.indexOf(item), 1);
      this.keyword_chips = [...this.keyword_chips];
    },
    remove_category(item) {
      this.category_chips.splice(this.category_chips.indexOf(item), 1);
      this.category_chips = [...this.category_chips];
    },

    remove_major_category(item) {
      this.selected_parent_categories.splice(this.selected_parent_categories.indexOf(item), 1);
      this.selected_parent_categories = [...this.selected_parent_categories];
    },

    remove_supp_name(item) {
      this.supplier_name_chips.splice(
        this.supplier_name_chips.indexOf(item),
        1
      );
      this.supplier_name_chips = [...this.supplier_name_chips];
    },
    remove_supp_industry(item) {
      this.supplier_industry_chips.splice(
        this.supplier_industry_chips.indexOf(item),
        1
      );
      this.supplier_industry_chips = [...this.supplier_industry_chips];
    },
    remove_excluded_supp_name(item) {
      this.supplier_name_chips_exclude.splice(
        this.supplier_name_chips_exclude.indexOf(item),
        1
      );
      this.supplier_name_chips_exclude = [...this.supplier_name_chips_exclude];
    },
    remove_supp_id(item) {
      this.supplier_id_chips.splice(this.supplier_id_chips.indexOf(item), 1);
      this.supplier_id_chips = [...this.supplier_id_chips];
    },
    remove_tendle_cat_id(item) {
      this.tendle_cat_id_chips.splice(
        this.tendle_cat_id_chips.indexOf(item),
        1
      );
      this.tendle_cat_id_chips = [...this.tendle_cat_id_chips];
    },
    remove_tendle_supp_id(item) {
      this.tendle_supp_id_chips.splice(
        this.tendle_supp_id_chips.indexOf(item),
        1
      );
      this.tendle_supp_id_chips = [...this.tendle_supp_id_chips];
    },
    remove_tendle_pub_id(item) {
      this.tendle_pub_id_chips.splice(
        this.tendle_pub_id_chips.indexOf(item),
        1
      );
      this.tendle_pub_id_chips = [...this.tendle_pub_id_chips];
    },
    remove_agency_name(item) {
      this.agency_name_chips.splice(this.agency_name_chips.indexOf(item), 1);
      this.agency_name_chips = [...this.agency_name_chips];
    },
    remove_buyer_industry(item) {
      this.selected_publisher_industry.splice(this.selected_publisher_industry.indexOf(item), 1);
      this.selected_publisher_industry = [...this.selected_publisher_industry];
    },
    remove_procurement_method(item) {
      this.selected_procurement_method.splice(this.selected_procurement_method.indexOf(item), 1);
      this.selected_procurement_method = [...this.selected_procurement_method];
    },
    remove_category_list(item) {
      this.selected_category_list.splice(this.selected_category_list.indexOf(item), 1);
      this.selected_category_list = [...this.selected_category_list];
    },
    remove_excluded_agency_name(item) {
      this.agency_name_chips_exclude.splice(
        this.agency_name_chips_exclude.indexOf(item),
        1
      );
      this.agency_name_chips_exclude = [...this.agency_name_chips_exclude];
    },

    remove_request(item) {
      this.request_chips.splice(this.request_chips.indexOf(item), 1);
      this.request_chips = [...this.request_chips];
    },
    remove_panel(item) {
      this.panel_chips.splice(this.panel_chips.indexOf(item), 1);
      this.panel_chips = [...this.panel_chips];
    },
    is_panel_off(){
      this.ispanel = false;
    },
    exclude_panel_off(){
      this.exclude_panel = false;
    },
    exclude_grant_off(){
      this.exclude_grant = false;
    },
    is_consultancy_off(){
      this.isconsultancy = false;
    },
    is_parent_off(){
      this.isparent = false;
    },
    setAmountRange() {
      this.amount_min = null;
      this.amount_max = null;
    },

    customAmount(flag) {
      this.custom_amount = flag;
    },

    submitSearch() {
      
      //this.search_params["category"] = this.category_chips;

      this.search_params["category"] = this.remove_blank_chip(this.selected_category_list);

      var kw_chips = [];
      if (this.keyword_chips.length > 0) {
        
        for (var i = 0; i < this.keyword_chips.length; i++) {
          kw_chips[i] = this.keyword_chips[i].key;
        }
      }

      this.search_params["tender"] = this.remove_blank_chip(kw_chips);

      if (this.supplier_smart_tags){
        this.search_params["supplier_smart_tags"] = this.supplier_smart_tags;
      }
      else{
        delete this.search_params["supplier_smart_tags"]
      }

      if (this.supplier_description){
        this.search_params["supplier_description"] = this.supplier_description;
      }
      else{
        delete this.search_params["supplier_description"]
      }

      if (this.panel_metadata){
        this.search_params["panel_metadata"] = this.panel_metadata;
      }
      else{
        delete this.search_params["panel_metadata"]
      }

      if (this.selected_parent_categories) {
        var parent_cats = [];
        for (var i = 0; i < this.selected_parent_categories.length; i++) {
          
          parent_cats[i] = this.selected_parent_categories[i].id;
        }
        this.search_params["major_category_id"] = parent_cats;
      }

      if (this.selected_pub_clusters) {
        var pub_clusters = [];
        for (var i = 0; i < this.selected_pub_clusters.length; i++) {
          pub_clusters[i] = Number(this.selected_pub_clusters[i].id);
        }
        this.search_params["buyer_cluster"] = pub_clusters;
      }

      if (this.selected_supp_clusters) {
        var supp_clusters = [];
        for (var i = 0; i < this.selected_supp_clusters.length; i++) {
          supp_clusters[i] = Number(this.selected_supp_clusters[i].id);
        }
        this.search_params["supplier_cluster"] = supp_clusters;
      }

      /* if (this.category_chips) {
          this.search_params["category"] = this.category_chips;
        } */

      if (this.amount_min > 0){
        this.search_params["amount_from"] = this.amount_min;
      }
      else{
        delete this.search_params["amount_from"];
      }

      if (this.amount_max > 0){
        this.search_params["amount_to"] = this.amount_max;
      }
      else{
        delete this.search_params["amount_to"];
      }
      
      this.search_params["status"] = this.selected_statuses;
      this.search_params["disposition"] = this.selected_types;

      if (this.contract_start_enabled) {
        this.set_contract_month_start(this.contract_month_start.name);
        this.set_contract_month_end(this.contract_month_end.name);

        this.search_params["contract_start_from"] = this.contract_month_start.date;
        this.search_params["contract_start_to"] = this.contract_month_end.date;
      } 
      else {
        delete this.search_params["contract_start_from"];
        delete this.search_params["contract_start_to"];
        delete this.search_params["contract_year_from"];
        delete this.search_params["contract_year_to"];

      }

      if (this.published_date_enabled) {
        this.set_published_month_start(this.published_month_start.name);
        this.set_published_month_end(this.published_month_end.name);

        this.search_params["published_from"] = this.published_month_start.date;
        this.search_params["published_to"] = this.published_month_end.date;
      } 
      else {
        delete this.search_params["published_from"];
        delete this.search_params["published_to"];
        delete this.search_params["published_year_from"];
        delete this.search_params["published_year_to"];

      }

      if (this.contract_end_enabled) {
        this.set_expiry_month_start(this.expiry_month_start.name);
        this.set_expiry_month_end(this.expiry_month_end.name);

        this.search_params["expiry_date_from"] = this.expiry_month_start.date;
        this.search_params["expiry_date_to"] = this.expiry_month_end.date;
      } 
      else {
        delete this.search_params["expiry_date_from"];
        delete this.search_params["expiry_date_to"];
      }

      this.search_params["publisher_industry"] = this.remove_blank_chip(this.selected_publisher_industry);

      this.search_params["procurement_method"] = this.remove_blank_chip(this.selected_procurement_method);
      

      var pub_chips = [];
      if (this.agency_name_chips) {
        for (var i = 0; i < this.agency_name_chips.length; i++) {
          pub_chips[i] = this.agency_name_chips[i].key;
        }
      }

      this.search_params["publisher"] = this.remove_blank_chip(pub_chips);

      var exclude_pub_chips = [];
      if (this.agency_name_chips_exclude) {
        for (var i = 0; i < this.agency_name_chips_exclude.length; i++) {
          exclude_pub_chips[i] = this.agency_name_chips_exclude[i].key;
        }
      }

      this.search_params["exclude_publisher"] = this.remove_blank_chip(
        exclude_pub_chips
      );

      var supp_ind_chips = [];
      if (this.supplier_industry_chips) {
        for (var i = 0; i < this.supplier_industry_chips.length; i++) {
          supp_ind_chips[i] = this.supplier_industry_chips[i].key;
        }
      }

      this.search_params["supplier_industry"] = this.remove_blank_chip(supp_ind_chips);

      var supp_chips = [];
      if (this.supplier_name_chips) {
        for (var i = 0; i < this.supplier_name_chips.length; i++) {
          supp_chips[i] = this.supplier_name_chips[i].key;
        }
      }

      this.search_params["supplier"] = this.remove_blank_chip(supp_chips);
      this.search_params["supplier_company_number"] = this.supplier_id_chips;
      this.search_params["supplier_id"] = this.tendle_supp_id_chips;
      this.search_params["category_id"] = this.tendle_cat_id_chips;
      this.search_params["publisher_id"] = this.tendle_pub_id_chips;
      //remove white space from abn

      var exclude_supp_chips = [];
      if (this.supplier_name_chips_exclude) {
        for (var i = 0; i < this.supplier_name_chips_exclude.length; i++) {
          exclude_supp_chips[i] = this.supplier_name_chips_exclude[i].key;
        }
      }

      this.search_params["exclude_supplier"] = this.remove_blank_chip(
        exclude_supp_chips
      );

      for (
        var i = 0;
        i < this.search_params["supplier_company_number"].length;
        i++
      ) {
        var s = this.search_params["supplier_company_number"][i];
        this.search_params["supplier_company_number"][
          i
        ] = this.remove_white_space(s);
      }
      this.search_params["supplier_company_number"] = this.supplier_id_chips;
      this.search_params["government"] = this.selected_territories;
      this.search_params["recid"] = this.request_chips;
      this.search_params["son_id"] = this.panel_chips;

      if (!this.ispanel){
        delete this.search_params.ispanel;
      }
      else{
        this.search_params["ispanel"] = this.ispanel;
      }

      if (!this.exclude_panel){
        delete this.search_params.exclude_panel;
      }
      else{
        this.search_params["exclude_panel"] = this.exclude_panel;
      }

      if (!this.exclude_grant){
        delete this.search_params.exclude_grant;
      }
      else{
        this.search_params["exclude_grant"] = this.exclude_grant;
      }

      if (!this.isconsultancy){
        delete this.search_params.isconsultancy;
      }
      else{
        this.search_params["isconsultancy"] = this.isconsultancy;
      }

      if (!this.isparent){
        delete this.search_params.isparent;
      }
      else{
        this.search_params["isparent"] = this.isparent;
      }
      
      

      
      //bus.$emit("submit-search", this.search_params);
      //////////////////console.log(this.$route);
      if (this.$route.query.viewIndex){
        this.search_params["viewIndex"] = this.$route.query.viewIndex;
      }

      this.search_params["newSearch"] = true;

      var qry = JSON.parse(JSON.stringify(this.$route.query));

      if (qry.size){
        this.search_params["size"] = qry.size;
      }

      this.$router.push({ path: "/search", query: this.search_params });
    },

    async populateFilters(query) {

      this.reset_filters();

      if (!query.hasOwnProperty("tender")) {
        //////////////////console.log('!query.hasOwnProperty("tender")');
        //check if this is a filter on supplier or publisher only
        if (query.hasOwnProperty("supplier_company_number")) {
          this.supplier_id_chips = this.getArray(
            query["supplier_company_number"]
          );
        } if (query.hasOwnProperty("supplier")) {
          query["supplier"] = this.getArray(query["supplier"]);
          for (var i = 0; i < query["supplier"].length; i++) {
            this.supplier_name_chips[i] = { key: query["supplier"][i] }; // convert to dict as that is what is created in reverse
          }
        } 
        if (query.hasOwnProperty("supplier_industry")) {
          query["supplier_industry"] = this.getArray(query["supplier_industry"]);

          for (var i = 0; i < query["supplier_industry"].length; i++) {
            this.supplier_industry_chips[i] = { key: query["supplier_industry"][i] }; // convert to dict as that is what is created in reverse
          }
        }
        if (query.hasOwnProperty("publisher")) {
          query["publisher"] = this.getArray(query["publisher"]);
          for (var i = 0; i < query["publisher"].length; i++) {
            this.agency_name_chips[i] = { key: query["publisher"][i] }; // convert to dict as that is what is created in reverse
          }
        } if (query.hasOwnProperty("exclude_publisher")) {
          query["exclude_publisher"] = this.getArray(
            query["exclude_publisher"]
          );
          for (var i = 0; i < query["exclude_publisher"].length; i++) {
            this.agency_name_chips_exclude[i] = {
              key: query["exclude_publisher"][i]
            }; // convert to dict as that is what is created in reverse
          }
        } if (query.hasOwnProperty("exclude_supplier")) {
          query["exclude_supplier"] = this.getArray(query["exclude_supplier"]);
          for (var i = 0; i < query["exclude_supplier"].length; i++) {
            this.supplier_name_chips_exclude[i] = {
              key: query["exclude_supplier"][i]
            }; // convert to dict as that is what is created in reverse
          }
        } 
        if (query.hasOwnProperty("category_id")) {
          query["category_id"] = this.getArray(query["category_id"]);
          this.tendle_cat_id_chips = this.getArray(query["category_id"]);
        }
        if (query.hasOwnProperty("major_category_id")) {
          query["major_category_id"] = this.getArray(query["major_category_id"]);
          //////////////////console.log("PARENT HEIRARCHY: ", query["major_category_id"]);
          for (var i = 0; i < query["major_category_id"].length; i++) {
            //this.selected_parent_categories[i] = { key: query["parent_category"][i] };
            this.selected_parent_categories[i]  = this.parent_category_options.find(x => x.id === parseInt(query["major_category_id"][i])); // convert to dict as that is what is created in reverse
          }
        }
        if (query.hasOwnProperty("supplier_id")) {
          query["supplier_id"] = this.getArray(query["supplier_id"]);
          this.tendle_supp_id_chips = this.getArray(query["supplier_id"]);
        } 
        if (query.hasOwnProperty("publisher_id")) {
          query["publisher_id"] = this.getArray(query["publisher_id"]);
          this.tendle_pub_id_chips = this.getArray(query["publisher_id"]);
        }

        if (query.hasOwnProperty("amount_from")) {
          this.amount_min = query["amount_from"];
        }

        if (query.hasOwnProperty("amount_to")) {
          this.amount_max = query["amount_to"];
        }

        if (query["contract_start_from"] || query["contract_start_to"]) {
          //////////////////console.log('query["expiry_from"] || query["expiry_to"]');
          this.contract_start_enabled = true;
          let from = new Date(query["contract_start_from"]);
          let to = new Date(query["contract_start_to"]);

          this.years[0] = from.getFullYear();
          this.years[1] = to.getFullYear();

          let start_month = this.months[from.getMonth()];
          let end_month = this.months[to.getMonth()];
          
          this.set_contract_month_start(start_month);
          this.set_contract_month_end(end_month);

        }

        if (query["published_from"] || query["published_to"]) {
          //////////////////console.log('query["expiry_from"] || query["expiry_to"]');
          this.published_date_enabled = true;
          let from = new Date(query["published_from"]);
          let to = new Date(query["published_to"]);

          this.pub_years[0] = from.getFullYear();
          this.pub_years[1] = to.getFullYear();

          let start_month = this.months[from.getMonth()];
          let end_month = this.months[to.getMonth()];
          
          this.set_published_month_start(start_month);
          this.set_published_month_end(end_month);

        }

        if (query["expiry_date_from"] || query["expiry_date_to"]) {
          //////////////////console.log('query["expiry_from"] || query["expiry_to"]');
          this.contract_end_enabled = true;
          let from = new Date(query["expiry_date_from"]);
          let to = new Date(query["expiry_date_to"]);

          this.end_years[0] = from.getFullYear();
          this.end_years[1] = to.getFullYear();

          let start_month = this.months[from.getMonth()];
          let end_month = this.months[to.getMonth()];
          
          this.set_expiry_month_start(start_month);
          this.set_expiry_month_end(end_month);

        }

        /* if (query.hasOwnProperty("category")) {
          query["category"] = this.getArray(query["category"]);
          for (var i = 0; i < query["category"].length; i++) {
            this.category_chips[i] = query["category"][i]; ; // convert to dict as that is what is created in reverse
          }
        } */

        if (query.hasOwnProperty("category")) {
          query["category"] = this.getArray(query["category"]);
          for (var i = 0; i < query["category"].length; i++) {
            this.selected_category_list[i] = query["category"][i]; // convert to dict as that is what is created in reverse
          }
        }

        if (query.hasOwnProperty("publisher_industry")) {
          this.selected_publisher_industry = this.getArray(query["publisher_industry"]);
        }

        if (query.hasOwnProperty("procurement_method")) {
          this.selected_procurement_method = this.getArray(query["procurement_method"]);
        }


        if (query.hasOwnProperty("supplier_cluster")) {
          this.injectSuppClusters = this.getArray(query["supplier_cluster"]);
        }

        if (query.hasOwnProperty("buyer_cluster")) {
          this.injectPubClusters = this.getArray(query["buyer_cluster"]);
        }

        if (this.getArray(query["government"]) && this.getArray(query["government"]).length > 0)
          this.selected_territories = this.getArray(query["government"]);

        this.request_chips = this.getArray(query["recid"]);
        this.panel_chips = this.getArray(query["son_id"]);
        this.ispanel = query["ispanel"];
        this.exclude_panel = query["exclude_panel"];
        this.exclude_grant = query["exclude_grant"];
        this.isconsultancy = query["isconsultancy"];
        this.isparent = query["isparent"];

      } else {
        //this.keyword_chips = this.getArray(query.tender);

        if (query.hasOwnProperty("tender")) {
          query["tender"] = this.getArray(query["tender"]);

          for (var i = 0; i < query["tender"].length; i++) {
            this.keyword_chips[i] = { key: query["tender"][i] }; // convert to dict as that is what is created in reverse
          }
        }

        if (query.hasOwnProperty("supplier_smart_tags")){
          this.supplier_smart_tags = query["supplier_smart_tags"]
        }

        if (query.hasOwnProperty("supplier_description")){
          this.supplier_description = query["supplier_description"]
        }

        if (query.hasOwnProperty("panel_metadata")){
          this.panel_metadata = query["panel_metadata"]
        }

        if (query.hasOwnProperty("supplier_cluster")) {
          
          this.injectSuppClusters = this.getArray(query["supplier_cluster"]);
          ////////////////console.log(this.injectSuppClusters);
        }

        if (query.hasOwnProperty("buyer_cluster")) {
          this.injectPubClusters = this.getArray(query["buyer_cluster"]);
        }

        /* if (query.hasOwnProperty("category")) {
          query["category"] = this.getArray(query["category"]);
          for (var i = 0; i < query["category"].length; i++) {
            this.category_chips[i] = query["category"][i]; // convert to dict as that is what is created in reverse
          }
        } */

        if (query.hasOwnProperty("category")) {
          query["category"] = this.getArray(query["category"]);
          for (var i = 0; i < query["category"].length; i++) {
            this.selected_category_list[i] = query["category"][i]; // convert to dict as that is what is created in reverse
          }
        }

        if (query.hasOwnProperty("major_category_id")) {
          
          query["major_category_id"] = this.getArray(query["major_category_id"]);
          //////////////////console.log("PARENT HEIRARCHY: ", query["major_category_id"]);
          for (var i = 0; i < query["major_category_id"].length; i++) {
            //this.selected_parent_categories[i] = { key: query["parent_category"][i] };
            this.selected_parent_categories[i]  = this.parent_category_options.find(x => x.id === parseInt(query["major_category_id"][i])); // convert to dict as that is what is created in reverse
          }
        }

        if (query.hasOwnProperty("amount_from")) {
          this.amount_min = query["amount_from"];
        }

        if (query.hasOwnProperty("amount_to")) {
          this.amount_max = query["amount_to"];
        }

        //////////////////console.log("QUERY: " + JSON.stringify(query));

        if (query.hasOwnProperty("supplier")) {
          query["supplier"] = this.getArray(query["supplier"]);

          for (var i = 0; i < query["supplier"].length; i++) {
            this.supplier_name_chips[i] = { key: query["supplier"][i] }; // convert to dict as that is what is created in reverse
          }
        }

        if (query.hasOwnProperty("supplier_industry")) {
          query["supplier_industry"] = this.getArray(query["supplier_industry"]);

          for (var i = 0; i < query["supplier_industry"].length; i++) {
            this.supplier_industry_chips[i] = { key: query["supplier_industry"][i] }; // convert to dict as that is what is created in reverse
          }
        }

        if (query.hasOwnProperty("exclude_supplier")) {
          query["exclude_supplier"] = this.getArray(query["exclude_supplier"]);
          for (var i = 0; i < query["exclude_supplier"].length; i++) {
            this.supplier_name_chips_exclude[i] = {
              key: query["exclude_supplier"][i]
            }; // convert to dict as that is what is created in reverse
          }
        }

        if (query.hasOwnProperty("publisher_industry")) {
          this.selected_publisher_industry = this.getArray(query["publisher_industry"]);
        }

        if (query.hasOwnProperty("procurement_method")) {
          this.selected_procurement_method = this.getArray(query["procurement_method"]);
        }

        if (query.hasOwnProperty("category_list")) {
          this.selected_category_list = this.getArray(query["category_list"]);
        }

        //query["publisher"] = this.getArray(query["publisher"]);
        if (query.hasOwnProperty("publisher")) {
          query["publisher"] = this.getArray(query["publisher"]);
          for (var i = 0; i < query["publisher"].length; i++) {
            this.agency_name_chips[i] = { key: query["publisher"][i] }; // convert to dict as that is what is created in reverse
          }
        }

        if (query.hasOwnProperty("exclude_publisher")) {
          query["exclude_publisher"] = this.getArray(
            query["exclude_publisher"]
          );
          for (var i = 0; i < query["exclude_publisher"].length; i++) {
            this.agency_name_chips_exclude[i] = {
              key: query["exclude_publisher"][i]
            }; // convert to dict as that is what is created in reverse
          }
        }

        if (this.getArray(query["status"]) && this.getArray(query["status"]).length > 0)
          this.selected_statuses = this.getArray(query["status"]);

        if (this.getArray(query["disposition"]) && this.getArray(query["disposition"]).length > 0)
          this.selected_types = this.getArray(query["disposition"]);

        this.amount_min = query["amount_from"];
        this.amount_max = query["amount_to"];

        if (query["contract_year_from"] || query["contract_year_to"]) {
          this.contract_start_enabled = true;
          this.years[0] = query["contract_year_from"];
          this.years[1] = query["contract_year_to"];
        }

        if (query["contract_start_from"] || query["contract_start_to"]) {
          //////////////////console.log('query["expiry_from"] || query["expiry_to"]');
          this.contract_start_enabled = true;
          let from = new Date(query["contract_start_from"]);
          let to = new Date(query["contract_start_to"]);

          this.years[0] = from.getFullYear();
          this.years[1] = to.getFullYear();

          let start_month = this.months[from.getMonth()];
          let end_month = this.months[to.getMonth()];
          
          this.set_contract_month_start(start_month);
          this.set_contract_month_end(end_month);

        }

        if (query["published_from"] || query["published_to"]) {
          //////////////////console.log('query["expiry_from"] || query["expiry_to"]');
          this.published_date_enabled = true;
          let from = new Date(query["published_from"]);
          let to = new Date(query["published_to"]);

          this.pub_years[0] = from.getFullYear();
          this.pub_years[1] = to.getFullYear();

          let start_month = this.months[from.getMonth()];
          let end_month = this.months[to.getMonth()];
          
          this.set_published_month_start(start_month);
          this.set_published_month_end(end_month);

        }

        if (query["expiry_date_from"] || query["expiry_date_to"]) {
          //////////////////console.log('query["expiry_from"] || query["expiry_to"]');
          this.contract_end_enabled = true;
          let from = new Date(query["expiry_date_from"]);
          let to = new Date(query["expiry_date_to"]);

          this.end_years[0] = from.getFullYear();
          this.end_years[1] = to.getFullYear();

          let start_month = this.months[from.getMonth()];
          let end_month = this.months[to.getMonth()];
          
          this.set_expiry_month_start(start_month);
          this.set_expiry_month_end(end_month);

        }

        if (query.hasOwnProperty("supplier_company_number")) {
          query["supplier_company_number"] = this.getArray(
            query["supplier_company_number"]
          );
          this.supplier_id_chips = this.getArray(
            query["supplier_company_number"]
          );
        }

        if (query.hasOwnProperty("supplier_id")) {
          query["supplier_id"] = this.getArray(query["supplier_id"]);
          this.tendle_supp_id_chips = this.getArray(query["supplier_id"]);
        }

        if (query.hasOwnProperty("category_id")) {
          query["category_id"] = this.getArray(query["category_id"]);
          this.tendle_cat_id_chips = this.getArray(query["category_id"]);
        }

        if (query.hasOwnProperty("publisher_id")) {
          query["publisher_id"] = this.getArray(query["publisher_id"]);
          this.tendle_pub_id_chips = this.getArray(query["publisher_id"]);
        }

        if (this.getArray(query["government"]) && this.getArray(query["government"]).length > 0)
          this.selected_territories = this.getArray(query["government"]);

        this.request_chips = this.getArray(query["recid"]);
        this.panel_chips = this.getArray(query["son_id"]);
        this.ispanel = query["ispanel"];
        this.exclude_panel = query["exclude_panel"];
        this.exclude_grant = query["exclude_grant"];
        this.isconsultancy = query["isconsultancy"];
        this.isparent = query["isparent"];
      }
    },

    getArray(string) {
      if (Array.isArray(string)) {
        return string;
      } else {
        if (string > "") {
          let arr = new Array();
          arr[0] = string;
          return arr;
        } else {
          return string;
        }
      }
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${year}/${month}/${day}`;
    },

    remove_white_space(str) {
      return str.replace(/\s/g, "");
    },
    reset_filters() {
      //this.amount_index = false;
      this.keyword_chips = [];
      this.category_chips = [];
      this.tendle_cat_id_chips = [];
      this.agency_name_chips = [];
      this.agency_name_chips_exclude = [];
      this.supp_smart_tags = false;
      this.supplier_name_chips = [];
      this.supplier_smart_tags = false;
      this.supplier_description = false;
      this.supplier_industry_chips = [];
      this.supplier_name_chips_exclude = [];
      this.supplier_id_chips = [];
      this.tendle_supp_id_chips = [];
      this.tendle_pub_id_chips = [];
      this.selected_statuses = [];
      this.selected_types = [];
      this.selected_publisher_industry = [];
      this.selected_procurement_method = [];
      this.selected_category_list = [];
      this.selected_months = [];
      this.selected_parent_categories = [];
      this.request_chips = [];
      this.panel_chips = [];
      this.is_panel_off();
      this.published_range = [];
      this.closing_range = [];
      this.contract_start_range = [];
      this.contract_end_range = [];
      this.injectSuppClusters = [];
      this.injectPubClusters = [];
      this.selected_territories = [];
      //this.injectSuppClusters = [];
      //this.injectPubClusters = [];
      this.setAmountRange();
      this.clear_years();
    },

    get_acronym(str) {
      var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
      var acronym = matches.join(""); // JSON
      return acronym;
    },

    lookup(terms, field) {
      // Lazily load input items
      this.isLoading = true;
      var result;
      autocomplete(terms, field)
        .then(data => {
          //////////////////console.log("DATA: ", data);
          data.unshift({ key: terms }); 
          if (field === "category") this.cat_items = data;
          if (field === "unique_publisher_name") this.pub_items = data;
          //if (field === "exclude_publisher") this.exclude_pub_items = data;
          if (field === "supplier") this.supp_items = data;
          if (field === "supplier_industry") this.supp_industry_items = data;
          if (field === "keyword") this.supp_smart_tag_items = data;
        })
        .finally(() => (this.isLoading = false));
    },

    lookup_exclude(terms, field) {
      // Lazily load input items
      this.isLoading = true;
      var result;
      autocomplete(terms, field)
        .then(data => {
          //if (field === "category") this.cat_items = data;
          //if (field === "publisher") this.pub_items = data;
          data.unshift({ key: terms }); 
          if (field === "supplier") this.exclude_supp_items = data;
          if (field === "unique_publisher_name") this.exclude_pub_items = data;
          //if (field === "supplier") this.supp_items = data;
        })
        .finally(() => (this.isLoading = false));
    },

  },
  mounted() { 
     
    //check that Auth0 has loaded
    const instance = getInstance();

    instance.$watch("loading", loading => {
      if (!loading) {
        //const token = await instance.loginWithRedirect({});
        fetchGovTypes().then(results => {
          
          this.loadingGovts = true;
          this.territory_options = results.gov_types;
          this.loadingGovts = false;
        });
        
        fetchBuyerIndustries().then(data => {
          this.loadingIndustries = true;
          this.publisher_industry_list = data;
          this.loadingIndustries = false;
        });

        fetchProcurementMethods().then(data => {
          this.loadingProcurementMethods = true;
          this.procurement_method_list = data;
          this.loadingProcurementMethods = false;
        });

        fetchCategoryList().then(data => {
          this.loadingCategoryLists = true;
          this.category_list = data;
          this.loadingCategoryLists = false;
        });

        this.populateFilters(this.$route.query);
      }
    });
  },
  computed: {
    publishedDateRangeText() {
      return this.published_range.join(" ~ ");
    },
    closingDateRangeText() {
      return this.closing_range.join(" ~ ");
    },
    contractStartRangeText() {
      return this.contract_start_range.join(" ~ ");
    },
    contractEndRangeText() {
      return this.contract_end_range.join(" ~ ");
    }
  },
  watch: {

    $route(to, from) {
      //////////////////console.log("ROUTE CHANGEDS: ");
      this.populateFilters(this.$route.query);
    },

    $govtsLoaded(val) {
      //////console.log("GOVT CHANGEDS: ");
      this.territory_options = val;
    },

    $buyer_industries: {
      handler() {
        //////console.log("INDUSRTY CHANGEDS: ");
        this.getIndustries();
      },
      deep: true
    },

    cat_search(val) {
      val && val !== this.select && this.lookup(val, "category");
    },
    pub_search(val) {
      if (val && val !== this.select) {
        this.pub_items = this.lookup(val, "unique_publisher_name");
      }
    },
    exclude_pub_search(val) {
      if (val && val !== this.select) {
        this.exclude_pub_items = this.lookup_exclude(val, "unique_publisher_name");
      }
    },
    supp_search(val) {
      val && val !== this.select && this.lookup(val, "supplier");
    },
    supp_industry_search(val) {
      val && val !== this.select && this.lookup(val, "supplier_industry");
    },
    supp_smart_tags(val) {
      val && val !== this.select && this.lookup(val, "keyword");
    },
    exclude_supp_search(val) {
      if (val && val !== this.select) {
        this.exclude_supp_items = this.lookup_exclude(val, "supplier");
      }
    }
  }
};
</script>
<style scoped>
.chip-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

</style>