<template>
<div>
    <div v-if="!isLoaded" tile flat>
        <news-loader/>
    </div>
    <div v-else>
        <v-card
            v-for="(article, j) in news"
            :key="j"
            outlined
            color="#2C2C2C"
            flat
            tile

            class="mb-0"
            >

            <div class="pl-4 pr-5">
            <v-divider></v-divider>
            </div>
            <v-row class="mb-0 pb-0">
            <v-col cols="4" class="mb-0 pb-0">
            <span class="caption mb-0 mt-0 pl-4"> 
            {{convertDate(article.datePublished)}}
            </span>
            </v-col>
            <v-col cols="8" class="text-right">
                <span class="caption mb-0 mt-0 pr-5" ><a class="font-weight-bold" >{{article.provider[0].name}}</a></span>
            </v-col>
            </v-row>
            <v-card-text class="subtitle-2 mb-0 mt-0 pt-0"> {{article.name}}</v-card-text>            
            </v-card> 
    </div>
</div>
</template>

<script>
import { bus } from "@/main.js";
import { fetchNews } from "./APIManager.js";
import NewsLoader from "./NewsLoader.vue";
import moment from 'moment';
//import Donut from "@/components/Donut.vue";

export default {
  components: {
    NewsLoader
    //PublisherTimeline
    //Heatmap
    //Donut
  },
  props: {
    entity: String()
    //results_drawer: Boolean
  },
  data() {
    return {
        news: [],
        isLoading: false,
        isLoaded: false  
    }
  },

  computed:{

  },

  mounted() {
    this.getNews(this.entity);
  },

  methods: {

    convertDate(thedate){
      //return moment(String(thedate)).format('DD/MM/YYYY hh:mm');
      return moment(String(thedate)).format('DD-MM-YYYY');
    },

    getNews(entity){
        ////////////console.log("ENTITY PANEL INDEX: ", this.entity_panel);
        ////////////console.log("ENTITY DATA INDEX: ", this.entities[this.entity_panel].profile.entity);
      // Lazily load input items
        this.isLoading = true
        fetchNews(entity)
          .then(data => {
            this.news = data.news.value
            ////////////console.log("DATA VALUE: ", data.news.value)
          })
          .then(() => {
            this.isLoading = false
            ////////////console.log("RECENT NEWS: ", this.news)
          })
          .finally(() => (
            this.loaded()
            ))
    },

    loaded(){
      this.isLoaded = true
    }


  }
  
};
</script>