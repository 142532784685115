var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-0 ma-0 fill-height",staticStyle:{"background-color":"#f5f5f5"},attrs:{"fluid":""}},[_c('v-content',{staticClass:"py-1"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"py-6",staticStyle:{"background-color":"#12253a"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticStyle:{"max-width":"1200px","display":"block","margin":"auto !important","padding-top":"50px"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"pr-0 mr-0",staticStyle:{"max-width":"800px"},attrs:{"cols":"11"}},[_c('tender-search')],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{staticClass:"text-capitalize body-2 ml-0 desktop-nav",attrs:{"color":"white","small":"","text":"","to":{
                    name: 'search',
                    query: {
                      expiry_from: new Date().getFullYear(),
                      expiry_to: new Date().getFullYear(),
                      sort_order: 'asc',
                      showFilter: true
                    }
                  }}},[_c('span',{staticClass:"text-capitalize body-2 desktop-nav"},[_vm._v("Advanced")])])],1)],1)],1)])],1),_c('v-dialog',{model:{value:(_vm.explainer),callback:function ($$v) {_vm.explainer=$$v},expression:"explainer"}},[_c('v-card',{staticClass:"pa-4",attrs:{"tile":"","flat":""}},[_c('p',{staticClass:"title",staticStyle:{"color":"#12253a"}},[_vm._v(" What are Search Profiles? ")]),_c('p',{staticStyle:{"color":"#12253a"}},[_vm._v(" Search profiles are based on searches that have been executed and then saved. This means you can quickly rerun those searches when you log back in. Tendertrace will automatically monitor search profiles and notify you of any new notices that match a profile. All new matches will be available in your \"Alerts\" page. ")]),_c('p',[_vm._v(" You will find up to date analysis of the search profiles you create on your home page. ")]),_c('p',{staticStyle:{"color":"#12253a"}},[_vm._v(" Saved searches also save the search tab you are on. So for example, if you were on the Leaderboard tab when you saved the search profile, clicking on the saved profile will run the search and also take you to the tab you were on when you saved the profile initially. ")]),_c('p',{staticStyle:{"color":"#12253a"}},[_vm._v(" To start saving searches: "),_c('ul',[_c('li',[_vm._v("Run a search for contracts or tenders of interest.")]),_c('li',[_vm._v("In the filter description section of the screen, click the button labeled \"Save Search\" to have Tendetrace start monitoring that search on your behalf.")])])]),_c('p',[_c('v-btn',{attrs:{"color":"primary","small":""}},[_c('router-link',{attrs:{"to":{
                  name: 'search',
                  query: {
                    disposition: ['Contract Notice', 'Grant'],
                    expiry_from: new Date().getFullYear(),
                    expiry_to: new Date().getFullYear(),
                    sort_order: 'asc'
                  }
                }}},[_c('span',{staticStyle:{"color":"white !important"}},[_vm._v("Create profile")])])],1)],1)])],1),_c('v-row',{staticClass:"pt-5 px-0 pb-0",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"3","sm":"6"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"width":"420px"}},[_c('v-card-title',{staticClass:"font-weight-medium py-1 px-4 subtitle-1 d-flex justify-space-between"},[_c('span',[_vm._v("Search Profiles")]),_c('span',[_c('v-btn',{attrs:{"icon":"","href":"https://docs.tendertrace.com/guide/user-documentation/saving-your-work/search-profiles","target":"_blank"}},[_c('v-icon',{staticClass:"material-icons",attrs:{"color":"secondary"}},[_vm._v("mdi-help-circle-outline")])],1)],1)]),_c('v-img',{attrs:{"height":"200","src":require("@/assets/img/Search-Profiles.jpg")}}),_c('v-card',{staticClass:"px-2 py-2 mx-2 my-2",attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","dense":"","solo-inverted":"","hide-details":"","flat":"","append-inner-icon":"mdi-magnify","label":"Search profiles..."},model:{value:(_vm.searchProfiles),callback:function ($$v) {_vm.searchProfiles=$$v},expression:"searchProfiles"}})],1),_c('v-list',{staticStyle:{"flex-grow":"1 !important","overflow":"auto !important"},attrs:{"nav":"","dense":"","height":"400px"}},[(_vm.loadingSearches)?_c('v-list-item',[_c('chart-loader',{attrs:{"reportLoading":_vm.loadingSearches}})],1):_vm._l((_vm.filteredSearchProfiles),function(profile){return _c('v-list-item',{key:profile.id},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-view-headline")])],1),_c('v-list-item-content',[_c('v-tooltip',{attrs:{"bottom":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-list-item-title',_vm._g({staticClass:"pl-0"},on),[_c('router-link',{staticClass:"list-link",attrs:{"to":{
                            name: 'search',
                            query: profile.search_query
                          }}},[_c('span',[_vm._v(_vm._s(profile.name))])])],1)]}}],null,true)},[_c('span',{staticClass:"tooltip"},[_vm._v(_vm._s(profile.plain_text_query))])]),_c('v-list-item-title',{staticClass:"pl-0"},[_c('span',[_c('v-tooltip',{attrs:{"top":"","max-width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.openProfile(0, profile)}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","medium":"","color":"secondary"}},'v-icon',attrs,false),on),[_vm._v("mdi-format-list-bulleted")])],1)]}}],null,true)},[_c('span',[_vm._v("Go to the data for this profile")])])],1),_c('span',[_c('v-tooltip',{attrs:{"top":"","max-width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.openProfile(2, profile)}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","medium":"","color":"secondary"}},'v-icon',attrs,false),on),[_vm._v("mdi-graphql")])],1)]}}],null,true)},[_c('span',[_vm._v("Go to the network analysis for this profile")])])],1),_c('span',[_c('v-tooltip',{attrs:{"top":"","max-width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.openProfile(1, profile)}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","medium":"","color":"secondary"}},'v-icon',attrs,false),on),[_vm._v("mdi-sort-variant")])],1)]}}],null,true)},[_c('span',[_vm._v("Go to the leaderboard for this profile")])])],1),_c('span',[_c('v-tooltip',{attrs:{"top":"","max-width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.openProfile(3, profile)}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","medium":"","color":"secondary"}},'v-icon',attrs,false),on),[_vm._v("mdi-chart-box-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Go to the insights report for this profile")])])],1)])],1)],1)})],2)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"6"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"width":"420px"}},[_c('v-card-title',{staticClass:"font-weight-medium py-1 px-4 subtitle-1 d-flex justify-space-between"},[_c('span',[_vm._v("Recent Searches")]),_c('span',[_c('v-btn',{attrs:{"icon":"","href":"https://docs.tendertrace.com/guide/user-documentation/saving-your-work/search-history","target":"_blank"}},[_c('v-icon',{staticClass:"material-icons",attrs:{"color":"secondary"}},[_vm._v("mdi-help-circle-outline")])],1)],1)]),_c('v-img',{attrs:{"height":"200","src":require("@/assets/img/Recent-Searches.jpg")}}),_c('v-card',{staticClass:"px-2 py-2 mx-2 my-2",attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","dense":"","solo-inverted":"","hide-details":"","flat":"","append-inner-icon":"mdi-magnify","label":"Search recent searches..."},model:{value:(_vm.searchRecent),callback:function ($$v) {_vm.searchRecent=$$v},expression:"searchRecent"}})],1),_c('v-list',{staticStyle:{"flex-grow":"1 !important","overflow":"auto !important"},attrs:{"nav":"","dense":"","height":"400px"}},[(_vm.loadingHistory)?_c('v-list-item',[_c('chart-loader',{attrs:{"reportLoading":_vm.loadingHistory}})],1):_vm._l((_vm.filteredRecentSearches),function(item){return _c('v-list-item',{key:item.id},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-history")])],1),_c('v-list-item-content',[_c('v-tooltip',{attrs:{"bottom":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
return [_c('v-list-item-title',_vm._g({staticClass:"pl-0"},on),[_c('router-link',{staticClass:"list-link",attrs:{"to":{
                            name: 'search',
                            query: JSON.parse(item.search_query)
                          }}},[_c('span',[_vm._v(_vm._s(item.plain_text_query))])])],1)]}}],null,true)},[_c('span',{staticClass:"tooltip"},[_vm._v(_vm._s(item.plain_text_query))])]),_c('v-list-item-title',{staticClass:"pl-0"},[_c('span',[_c('v-tooltip',{attrs:{"top":"","max-width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.openItem(0, item)}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","medium":"","color":"secondary"}},'v-icon',attrs,false),on),[_vm._v("mdi-format-list-bulleted")])],1)]}}],null,true)},[_c('span',[_vm._v("Go to the data for this search")])])],1),_c('span',[_c('v-tooltip',{attrs:{"top":"","max-width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.openItem(2, item)}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","medium":"","color":"secondary"}},'v-icon',attrs,false),on),[_vm._v("mdi-graphql")])],1)]}}],null,true)},[_c('span',[_vm._v("Go to the network analysis for this search")])])],1),_c('span',[_c('v-tooltip',{attrs:{"top":"","max-width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.openItem(1, item)}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","medium":"","color":"secondary"}},'v-icon',attrs,false),on),[_vm._v("mdi-sort-variant")])],1)]}}],null,true)},[_c('span',[_vm._v("Go to the leaderboard for this search")])])],1),_c('span',[_c('v-tooltip',{attrs:{"top":"","max-width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.openItem(3, item)}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","medium":"","color":"secondary"}},'v-icon',attrs,false),on),[_vm._v("mdi-chart-box-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Go to the analysis report for this search")])])],1)])],1)],1)})],2)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"6"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"width":"420px"}},[_c('v-card-title',{staticClass:"font-weight-medium py-1 px-4 subtitle-1 d-flex justify-space-between"},[_c('span',[_vm._v("Prospect Lists")]),_c('span',[_c('v-btn',{attrs:{"icon":"","href":"https://docs.tendertrace.com/guide/user-documentation/saving-your-work/prospect-lists","target":"_blank"}},[_c('v-icon',{staticClass:"material-icons",attrs:{"color":"secondary"}},[_vm._v("mdi-help-circle-outline")])],1)],1)]),_c('v-img',{attrs:{"height":"200","src":require("@/assets/img/bookmarks.jpg")}}),_c('v-card',{staticClass:"px-2 py-2 mx-2 my-2",attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","dense":"","solo-inverted":"","hide-details":"","flat":"","append-inner-icon":"mdi-magnify","label":"Search prospect lists..."},model:{value:(_vm.searchProspect),callback:function ($$v) {_vm.searchProspect=$$v},expression:"searchProspect"}})],1),_c('v-list',{staticStyle:{"flex-grow":"1 !important","overflow":"auto !important"},attrs:{"nav":"","dense":"","height":"400px"}},[(_vm.loadingBookmarks)?_c('v-list-item',[_c('chart-loader',{staticClass:"pt-6",attrs:{"reportLoading":_vm.loadingBookmarks}})],1):_vm._l((_vm.filteredProspectLists),function(list){return _c('v-list-item',{key:list.id},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-bookmark")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"pl-0"},[_c('router-link',{staticClass:"list-link",attrs:{"to":{
                        name: 'search',
                        query: _vm.getQuery(list)
                      }}},[_c('span',[_vm._v(_vm._s(list.name))])])],1)],1)],1)})],2)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"6"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"width":"420px"}},[_c('v-card-title',{staticClass:"font-weight-medium py-1 px-4 subtitle-1 d-flex justify-space-between"},[_c('span',[_vm._v("Custom Reports")]),_c('span',[_c('v-btn',{attrs:{"icon":"","href":"https://docs.tendertrace.com/guide/user-documentation/custom-reports","target":"_blank"}},[_c('v-icon',{staticClass:"material-icons",attrs:{"color":"secondary"}},[_vm._v("mdi-help-circle-outline")])],1)],1)]),_c('v-img',{attrs:{"height":"200","src":require("@/assets/img/Custom-Reports.jpg")}}),_c('v-card',{staticClass:"px-2 py-2 mx-2 my-2",attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","dense":"","solo-inverted":"","hide-details":"","flat":"","append-inner-icon":"mdi-magnify","label":"Search custom reports..."},model:{value:(_vm.searchReports),callback:function ($$v) {_vm.searchReports=$$v},expression:"searchReports"}})],1),_c('v-list',{staticStyle:{"flex-grow":"1 !important","overflow":"auto !important"},attrs:{"nav":"","dense":"","height":"400px"}},[(_vm.loadingReports)?_c('v-list-item',[_c('chart-loader',{attrs:{"reportLoading":_vm.loadingReports}})],1):_vm._l((_vm.filteredCustomReports),function(report){return _c('v-list-item',{key:report.id},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-chart-bubble")])],1),_c('v-list-item-content',[_c('v-tooltip',{attrs:{"bottom":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-list-item-title',_vm._g({staticClass:"pl-0"},on),[_c('router-link',{staticClass:"list-link",attrs:{"to":'/report/' + report.id}},[_c('span',[_vm._v(_vm._s(_vm.stripHTML(report.title)))])])],1)]}}],null,true)},[_c('span',{staticClass:"tooltip"},[_c('div',[_vm._v(_vm._s(_vm.stripHTML(report.description)))]),_c('div',[_vm._v(_vm._s(_vm.stripHTML(report.created)))])])])],1)],1)})],2)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }