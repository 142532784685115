<template>
    <div
        class="pa-0 ma-0 fill-height"
        style="background-color:#F4F4F4;"
        fluid
    >
    <v-snackbar
            v-model="copied"
            top
            color="green"
            dense
            small
            max-height="10"
            max-width="50"
            timeout="1000"
          >
            Copied to clipboard!
            <!-- <v-btn dark text @click="copied = false">
              Close
            </v-btn> -->
    </v-snackbar> 
    <v-dialog
      v-model="removeDialog"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-text class="title py-4">
          Delete <b><i>{{ stripHTML(toRemove.title) }}</i></b>?
        </v-card-text>
        <v-card-text>This action will remove the widget from your report <i><b>{{ stripHTML(storyboard.title) }}</b></i>.</v-card-text>
        <v-card-text>This action cannot be undone.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="removeDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="removeDialog = false, removeStory(toRemove.id)"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
          v-model="overlay"
          hide-overlay
          persistent
          width="300"
          lazy          
        >
          <v-card
            color="secondary"
            dark
          >
            <v-card-text>
              Generating report...
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
    </v-dialog>
    <v-content v-if="!overlay">
        <v-card tile max-width="1200px" class="mx-auto pt-2 pb-0">
            <v-row justify="center" class="px-0 pt-3 pb-5">
                <v-col class="pt-0" cols="12">
                <v-card class="ma-0 pa-0" tile flat color="secondary">
                    <v-row justify="center" class="px-0 py-3">
                        <v-col cols="12">
                                <v-card dark color="secondary" tile flat max-width="1200px" class="mx-auto pa-9">
                                        <h2 class="display-3"><tiptap :sectionType="'heading'" :value="title" @input="saveUpdate" /></h2>
                                        <p class="title mt-3 font-weight-light"><tiptap :value="description" @input="saveUpdate" /></p>
                                        <p class="title mt-3 font-weight-light">{{getMonthYear(created)}}</p>
                                        <p class="mt-3 subtitle-2 font-weight-light"><i>by {{author}}</i></p>
                                        <p class="pl-0 ml-0" >
                                            <v-btn-toggle
                                                v-model="published"
                                                dense
                                                tile
                                                color="rgb(255, 231, 10)"
                                                small
                                                >
                                                <v-btn @click="publish(false)" small text>
                                                    <span class="subtitle-2">
                                                    Draft
                                                    </span>
                                                </v-btn>
                                                <v-btn @click="publish(true)" small text>
                                                    <span class="subtitle-2">
                                                Published
                                                    </span>
                                                </v-btn>
                                            </v-btn-toggle>
                                        </p>
                                        <p v-if="published==1">
                                            <span v-if="published==1 && !publishing">
                                                <span class="subtitle-2">URL: <router-link style="color:white !important;" :to="{ name: 'openboard', params: { openboardId: storyboard.public_id }}">
                                                    {{publicURL}}
                                                    </router-link>
                                                </span>
                                                <span>
                                                    <v-btn icon @click="copy(publicURL)"><v-icon medium>mdi-content-copy</v-icon></v-btn>
                                                </span>
                                            </span>
                                            <span v-else-if="published == 1 && publishing">
                                                <v-progress-linear
                                                    indeterminate
                                                    color="primary"
                                                    ></v-progress-linear>
                                            </span>
                                        </p>
                                </v-card>
                        </v-col>
                    </v-row>
                    </v-card>
                    <v-card flat max-width="1400" class="mx-auto">
                        <v-row justify="center" class="mt-3 px-0 pb-0 pt-9">
                            <v-col class="d-flex child-flex mt-0 py-0" cols="12">
                                <draggable v-model="stories" handle=".drag-handle" @end="dragEnd">
                                    <div v-for="story in stories" :key="story.id">
                                        <v-hover v-slot:default="{ hover }">
                                            <v-card tile :flat="true" :outlined="false" max-width="1200px" class="mx-auto px-9 pt-0 pb-0">
                                                <!-- Story component here -->
                                                <v-expand-transition>
                                                    <v-card v-if="hover" tile flat>
                                                        <v-row class="pa-0">
                                                            <v-col class="text-left pt-1 pb-5"> 
                                                                <span>
                                                                    <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-btn
                                                                            v-on="on" 
                                                                            large 
                                                                            icon color="secondary" @click="toRemove=story, removeDialog=true">
                                                                            <v-icon>mdi-playlist-remove</v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                        <span>Remove widget from report</span>
                                                                    </v-tooltip>
                                                                </span>
                                                            </v-col>
                                                            <v-col class="text-right pt-1 pb-5"> 
                                                                <span>
                                                                    <v-tooltip top dark color="#000000" style="opacity: 0.7;">
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-btn
                                                                        v-on="on" 
                                                                        large icon 
                                                                        color="secondary" class="drag-handle">
                                                                        <v-icon large>mdi-drag</v-icon>
                                                                        </v-btn>
                                                                </template>
                                                                    <span>Drag up or down to reposition this widget</span>
                                                                </v-tooltip>
                                                                </span>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card>
                                                </v-expand-transition>
                                                    <story :changed="story.id" :position="story.position" :data="story" @close="storyDialog = false" />
                                                    <div class="flex-grow-1 desktop-nav"></div>
                                                    <v-divider v-if="showDivider(story)" class="pt-6 mt-6 pb-0 mb-0"></v-divider>
                                            </v-card>
                                        </v-hover>
                                    </div>
                                </draggable>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <v-footer
                dark
                padless
               
            >
                <v-card
                flat
                tile
                class="secondary white--text text-center"
                width="100%"
                >
                <v-card-text class="white--text pt-5">
                    This report was automatically generated using the Tendertrace market intelligence platform.
                </v-card-text>

                <v-card-text class="white--text">
                    This report, including all supporting materials, is proprietary to Tendertrace, and/or its affiliates and is for the sole internal use of the intended recipients. Because this report may contain information that is confidential, proprietary or otherwise legally protected, it may not be further copied, distributed or publicly displayed without the express written permission of Tendertrace, and/or its affiliates.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-text class="white--text">
                    <span> <a href="https://tendertrace.com" target="_blank">
                                                <v-img
                                                src="@/assets/img/tendertrace_logo_outline_white_final_552x66.png"
                                                max-height="20"
                                                contain
                                                class="pl-0 ml-0"
                                                >
                                                </v-img>
                                                </a></span>
                    <span>&copy; {{ new Date().getFullYear() }}</span>
                </v-card-text>
                </v-card>
            </v-footer>
        </v-card>
    </v-content>
    </div>
</template>
<script>
import draggable from "vuedraggable";
import Story from "../components/Story.vue";
//import json from '../components/data/imgdata.json';
import Tiptap from "../components/Tiptap.vue";
import { fetchStoryboard, updateStoryboard, deleteStory, updateStory } from "../components/APIManager.js";
import { getMonthYear } from "../components/Utils.js";

export default {
    components: {
        Story,
        Tiptap,
        draggable
    },

    props:{
        storyboardId:{
            required: true
        }
    },

    data() {
        return {
            removeDialog: false,
            toRemove: "",
            grid: false,
            model: 0,
            title: "",
            createdDate: "",
            description: '',
            created:'',
            show: false,
            storyDialog: false,
            selectedStory: {},
            stories: {}, //json,
            changed: Number,
            num: 0,
            boardUpdate: Number,
            storyboard: {},
            overlay: true,
            author: "",
            published: undefined,
            publishing: Boolean,
            copied: false,
        }
    },

    async mounted(){
        this.$nextTick(() => {
            this.loadBoard();
        });
    },


    methods:{

        getMonthYear,

        showDivider(story){
            let index = this.stories.findIndex(item => item.id === story.id);
            if (index+1 == this.stories.length) return false;
            else return true;
        },

        publish(status){
            this.publishing = true;
            this.storyboard.public = status;
            updateStoryboard(this.storyboard)
                .then(response => {
                    //////////////////console.log("UPDATED STORY SUCCESSFULLY\n", response.data);
                    this.storyboard.public_id = response.data.public_id;
                    this.publishing = false;
                })
                .catch(error => {
                    //////////////////console.log("ERROR IN GETUSERLISTS.. ERROR: ", error );
                    return error;
                });
        },

        async loadBoard(){
            this.overlay = true;
            var data = await fetchStoryboard(this.storyboardId);

            if (data.storyboard.public){
                this.published = 1;
            }
            else{
                this.published = 0;
            }

            //this.published = data.storyboard.public;
            this.author = data.author;
            this.stories = data.stories;
            this.storyboard = data.storyboard;
            ////////console.log("----STORIES----\n: ", this.stories);
            this.title = this.storyboard.title;
            this.description = this.storyboard.description;
            this.created = this.storyboard.created;
            this.sortBoard();
            this.publishing = false;
            this.overlay = false;
        },

        async saveUpdate(content){

            var storyboard = this.storyboard;
            if (content.sectionType === "heading"){
                storyboard.title = content.html;
            }else{
                storyboard.description = content.html;
            }

            await updateStoryboard(storyboard);
        },

        currentStoryIndex(id){

            var index = this.stories.findIndex((story, index) => {
            if (story.id === id) {
                return true
            }
            })

            return index;

        },

        async dragEnd(event) {
            ////console.log(event);
            this.stories.forEach((story, index) => {
                story.position = index;
                //update each story's position in database
                updateStory(story)
                ////console.log(story);
            });
        },

        async removeStory(id){
            this.overlay = true;
            deleteStory(id)
            .then(response => {
                ////////console.log(response);
                this.loadBoard();
            })
            .catch(error => {
                return error;
            });
            
        },

        stripHTML(text){
            if (!text){
                return "";
            }
            else{
                let strippedText = text.replace(/(<([^>]+)>)/gi, "");
                return strippedText;
            }
            
        },

        arraystart(index){

            let isStart = false;

            if (index == 0){
                isStart = true;
            }

            return isStart;

        },

        arrayend(index){

            let isEnd = false;

            if (index == (this.stories.length - 1)){
                isEnd = true;
            }

            return isEnd;

        },

        sortBoard(){
            this.stories.sort(function(a, b) {
                return parseInt(a.position) - parseFloat(b.position);
            });
        },

        moveUp(currentIndex){

            this.stories[currentIndex-1].position = currentIndex;

            this.stories[currentIndex].position = currentIndex - 1;

            this.sortBoard();

        },

        moveDown(currentIndex){

            if (currentIndex < 0){
                currentIndex = 0;
            }

            this.stories[currentIndex+1].position = currentIndex;

            this.stories[currentIndex].position = currentIndex + 1;

            this.sortBoard();

        },

    
        showHideStory(story) {
            this.graphChanged(story);
            this.storyDialog = true;
            
        },

        async copy(txt){
            try {
                await navigator.clipboard.writeText(txt);
                this.copied = true;
            } catch (e) {
                //console.error(e);
            }
        },

        graphChanged(story){
            this.selectedStory = null;
            this.selectedStory = story;
            this.changed = (Math.random() + this.stories.length) * Math.random();
            ////////console.log("MODEL: ", this.model);
            ////////console.log("STORY: ", this.selectedStory);
        },

        changeGraph(){
            return (Math.random() + this.stories.length) * Math.random();
        },

         shortDescription(description){
            let text = description;
            let arr = text.split(" ");
            let limit = arr.length;
            let newText = []
            ////////console.log(arr);
            if (limit > 20){
                limit = 20;

                for (let i=0; i < limit; i++){
                    newText[i]= arr[i];
                }
                text = newText.join(" ");
                text = text + "..."
            }

            return text;
        }
    },

    computed:{
        publicURL(){
            const resolved = this.$router.resolve({ name: 'openboard', params: { openboardId: this.storyboard.public_id }})
            ////////console.log("LINK:", resolved)
            return window.location.origin+resolved.href;
        },
    },

    watch: {
        model() {
            this.graphChanged(this.stories[this.model])
        },
    },
}
</script>
<style scoped>

.drag-handle {
	cursor: move;
	cursor: -webkit-grabbing;
}

.truncate {
    font-family:sans-serif;
    font-size: 1em;
    font-weight: bold;
    line-height: 1.2em;
    transition: var(--transition);
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden ;
    text-overflow: ellipsis;
}

.txt {
    
    font-weight: normal;
    line-height: 1.2;
    letter-spacing: -0.02em;
    color: var(--dark-grey);
}

.truncate-title {
    line-height: 2em;
    transition: var(--transition);
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden ;
    text-overflow: ellipsis;

}

#no-background-hover::before {
   background-color: transparent !important;
}
</style>

