var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height pa-0 ma-0",staticStyle:{"background-color":"#f5f5f5"},attrs:{"fluid":""}},[_c('v-content',[_c('v-row',{staticClass:"px-0 py-3",attrs:{"justify":"center"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"color":"#f5f5f5","flat":"","width":"95%"}},[_c('p',{staticClass:"pt-6 display-1 font-weight-medium",staticStyle:{"text-align":"center"}},[_vm._v(" Panels and Standing Offers ")]),_c('p',{staticClass:"body-1 font-weight-normal",staticStyle:{"text-align":"center"}},[_vm._v(" Find and explore panels and standing offers of interest. Only the first 100 results are displayed, so use the keyword search to narrow down your list. Search using keywords for title, description, category, supplier or lead agency names. ")]),_c('v-row',{staticClass:"px-0 py-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"ma-0 pa-0",attrs:{"flat":"","color":"#f5f5f5","tile":"","min-height":"40"}},[_c('v-card-text',{staticClass:"ma-0 pa-0"},[_c('v-combobox',{ref:"keywords",attrs:{"small-chips":"","rounded":false,"solo":true,"clearable":"","outlined":"","dense":"","label":"Enter tab separated keywords e.g. Antenna, Deloitte, Defence","multiple":"","small":"","append-outer-icon":"mdi-magnify","dark":false},on:{"click:append-outer":function($event){return _vm.loadData()},"click:clear":function($event){return _vm.clearSearch()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',{attrs:{"color":"rgb(255, 20, 99)","small":"","label":"","input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.remove_kw(item)}}},[_vm._v(_vm._s(item))])]}}]),model:{value:(_vm.keywords),callback:function ($$v) {_vm.keywords=$$v},expression:"keywords"}})],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1 py-2",attrs:{"headers":_vm.headers,"items":_vm.filteredItems,"footer-props":{
                        'items-per-page-options': [100]
                    },"items-per-page":100,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.gov_type",fn:function(ref){
                    var item = ref.item;
return [_c('v-avatar',{staticClass:"mr-2",attrs:{"color":_vm.govAvatarColor(item.gov_type),"size":"36"}},[_c('span',{staticClass:"white--text caption"},[_vm._v(_vm._s(item.gov_type))])])]}},{key:"item.son_id",fn:function(ref){
                    var item = ref.item;
return [_c('router-link',{staticClass:"editor-link",staticStyle:{"font-size":"14px !important","font-weight":"500 !important"},attrs:{"to":'/panel?son_id='+item.son_id+'&government='+item.gov_type}},[_vm._v(_vm._s(_vm.stripHTML(item.son_id)))])]}},{key:"item.son_start",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.son_start))+" ")]}},{key:"item.son_end",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.son_end))+" ")]}},{key:"item.total_spend",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formattedCurrencyFull(item.total_spend))+" ")]}},{key:"item.son_url",fn:function(ref){
                    var item = ref.item;
return [_c('span',{staticClass:"text-center"},[(item.son_url)?_c('v-btn',{staticStyle:{"text-decoration":"none !important"},attrs:{"small":"","icon":"","color":"#4EB888","light":"","disabled":_vm.isLink(item.son_url),"href":item.son_url,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-web")])],1):_vm._e()],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }