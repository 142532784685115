<template>
  <div class="pa-0 ma-0 fill-height" style="background-color:#f5f5f5" fluid>
    <v-content>
      <v-container class="fill-height pl-1 pr-0 mx-1 py-2 mt-0" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" class="pt-8">
            <div
              style="max-width:1200px; display: block; margin:auto !important;"
            >
              <p
                style="text-align:center;"
                class="display-1 font-weight-medium"
              >
                Category Directory
              </p>
              <p style="text-align:center;" class="body-1 font-weight-normal">
                Category profiles offer insights into a specific product or service category,
                including award trends over time along with the buyers and suppliers that participate in the category.
              </p>
              <v-row align="center" justify="center">
                <v-col cols="12" style="max-width:800px;" class="pa-0 ma-0">
                  <category-search :rounded="false" :color="'#f5f5f5'" :dark="false" :solo="true" @clearFilter="reset_filters" />
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" class="pt-0 mb-0 pb-0 pr-0">
            <category-table />
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </div>
</template>

<script>
import CategorySearch from "../components/CategorySearch";
import CategoryTable from "../components/entities/CategoryTable";
//import EntityProfile from "../components/entities/EntityProfile";

import { bus } from "../main.js";

export default {
  components: {
    CategorySearch,
    CategoryTable
  },
  props: {
    publisher: {}
  },
  data() {
    return {
      drawer: true,
      mini: true,
      search: { category_id: Number },
      current_entity: {},
      global_search: false,
      view_index: null,

      selected: "snapshot",
      current_view: "snapshot",
      watching: false,
      filters: {}
      //results_drawer: null
    };
  },

  created() {

  },

  mounted() {},

  methods: {
    process_route() {
      this.filters = this.$route.query;
    },

    reset_filters(){
      var qry = JSON.parse(JSON.stringify(this.$route.query));
      delete qry["term"];
      delete qry["page"];
      this.$router.push({ name: "categories", query: qry });
    },

    hideFilter() {
      let show = false;
      bus.$emit("hideFilters", show);
    },

    isEmpty(obj) {
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
      }
      return true;
    }
  },

  watch: {
  }
};
</script>
