
import moment from "moment";
import Router from '../router'

export function govAvatarColor(gov) {
  var color = "grey";
  switch (gov) {
    case "NSW":
      color = "#41a7d9";
      break;
    case "VIC":
      color = "#0058AC";
      break;
    case "WA":
      color = "#FFD200";
      break;
    case "QLD":
      color = "#A6003D";
      break;
    case "AUS":
      color = "#D8993B";
      break;
    case "FED":
      color = "#D8993B";
      break;
    case "DEF":
      color = "#d8673b";
      break;
    case "SA":
      color = "#ff4081";
      break;
    case "TAS":
      color = "#4eb888";
      break;
    case "ACT":
      color = "#41a7d9";
      break;
    case "NT":
      color = "#FF3647";
      break;
  }

  return color;
}

export function benchmarkTransparencyColor(score) {
  // Calculate intensity based on the score (0 for highest scores, 0.7 for lowest scores)
  var intensity = 0.7 - (score - 1) * 0.007;

  // Ensure intensity is within the range [0, 0.7]
  intensity = Math.max(0, Math.min(0.7, intensity));

  // Calculate red, green, and blue values based on the intensity
  var red = Math.round(255 * intensity);
  var green = Math.round(20 * intensity);
  var blue = Math.round(99 * intensity);

  // Create the hexadecimal color using the calculated red, green, and blue values
  var color = "#" + red.toString(16).padStart(2, "0") + green.toString(16).padStart(2, "0") + blue.toString(16).padStart(2, "0");

  return color;
}

export function checkTransparencyColor(score) {
  // Calculate alpha based on the score (1 for highest scores, 0.3 for lowest scores)
  var alpha = 1 - (score - 1) * 0.007;

  // Ensure alpha is within the range [0.3, 1]
  alpha = Math.max(0.3, Math.min(1, alpha));

  // Create the rgba color using the #ff1463 color with the calculated alpha
  var color = `rgba(18, 37, 58, ${alpha})`;

  return color;
}


export function transparencyColor(score) {
  var color = "#143522"; // Default color from the palette (darkest shade)

  switch (true) {
    case score <= 30:
      color = "#d42613"; // Red - lowest scores
      break;
    case score <= 50:
      color = "#d48a13"; // Orange-red
      break;
    case score <= 60:
      color = "#8a9917"; // Orange-red
      break;
    case score <= 70:
      color = "#729917"; // Gold
      break;
    case score <= 80:
      color = "#1c9c1e"; // Lime Green
      break;
    case score <= 100:
      color = "#1d5e39"; // Green - highest scores
      break;
  }

  return color;
}


export function getKey(obj, index) {
  let i = 0;
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (i === index) {
        return key;
      }
      i++;
    }
  }
  return null;
}

export function getMatchedFields(obj) {
  const result = new Set();

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const arr = obj[key];
      arr.forEach((value) => {
        const matches = value.match(/<em>([^<]*)<\/em>/gi);
        if (matches) {
          const words = matches.map((match) =>
            match.replace(/<\/?em>/gi, '').trim().toLowerCase()
          );

          const uniqueArr = [...new Set(words)];
          const concatenatedWords = uniqueArr.join(' ');

          if (!result.has(concatenatedWords)) {
            result.add(concatenatedWords);
          }
        }
      });
    }
  }

  const queryString = window.location.search;

  return filterMatchesByQuery(queryString, Array.from(result));
}

function filterMatchesByQuery(queryString, matches) {
  const queryTerms = new URLSearchParams(queryString);
  const filteredMatches = matches.filter((match) => {
    const lowerMatch = match.toLowerCase();
    return Array.from(queryTerms.values()).some(
      (value) => value.toLowerCase().includes(lowerMatch)
    );
  });
  return filteredMatches;
}

export function getArray(string) {
  if (Array.isArray(string)) {
    return string;
  } else {
    if (string > "") {
      let arr = new Array();
      arr[0] = string;
      return arr;
    } else {
      return string;
    }
  }
}

export function growth_format(per_change){
  var icontype = String;
  var color = String;

  if (per_change == 0){
    icontype = "mdi-minus";
    color = "black";
    per_change = per_change * -1;
  }
  else if (per_change > 0){
    icontype = "mdi-menu-up";
    color = "green";
  }
  else if (per_change < 0){
    icontype = "mdi-menu-down";
    color = "red";
    per_change = per_change * -1;
  }

  return {icontype: icontype, color: color, change: per_change};
}

export function silentURLUpdate(settings){

  //quietly update the URL without triggering a route changed event
  const url = new URL(window.location);

  for (var i in settings){
    url.searchParams.set(settings[i]["field_name"], settings[i]["value"]); 
  }

  window.history.pushState({}, '', url);
}

export function getMonthYear(date_value) {
  if (!date_value) {
    return null;
  } else {
    return moment(date_value).format("MMMM YYYY");
  }
}


export function formatDate(date_value) {
  if (!date_value) {
    return null;
  } else {
    return moment(date_value).format("YYYY/M/D");
  }
}


export function getPercent(value, total) {
  return (value/total * 100).toFixed(1) + '%'
}


export function govColor(gov){
  var color = "grey";
  switch (gov) {
    case "NSW":
      color = "#41a7d9";
      break;
    case "VIC":
      color = "#0058AC";
      break;
    case "WA":
      color = "#FFD200";
      break;
    case "QLD":
      color = "#A6003D";
      break;
    case "AUS":
      color = "#D8993B";
      break;
    case "FED":
      color = "#D8993B";
      break;
    case "DEF":
      color = "#d8673b";
      break;
    case "SA":
      color = "#ff4081";
      break;
    case "TAS":
      color = "#4eb888";
      break;
    case "ACT":
      color = "#41a7d9";
      break;
    case "NT":
      color = "#FF3647";
      break;
  }

  return color;
}

export function downloadFile(src){
      
  //this trick will generate a temp <a /> tag
  var link = document.createElement("a");    
  link.href = src;
  link.target = "_blank";
  
  //set the visibility hidden so it will not effect on your web-layout
  link.style = "visibility:hidden";
  link.download = "tendertrace_chart" + ".png";
  
  //this part will append the anchor tag and remove it after automatic click
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function numFormatter(num) {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
  } else if (num >= 1000000 && num < 1000000000) {
    return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
  } else if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1) + "B"; // convert to M for number from > 1 million
  } else if (num < 1000) {
    return num; // if value < 1000, nothing to do
  }
}

export function formattedCurrency (amount) {
  //////////////////console.log("AMOUNT: " + amount);
  if (amount > 0) {
    return "$" + numFormatter(amount);
  }
  else return "$0"
} 


export function formattedCurrencyFull (amount) {
  //////////////////console.log("AMOUNT: " + amount);
  if (!isNaN(amount)) {
    return (
      "$" +
      Math.round(amount)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    );
  } //else {
  //return null;
  //}
}

export function convertToCSV(objArray) {
  var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  var str = "";

  for (var i = 0; i < array.length; i++) {
    var line = "";
    for (var index in array[i]) {
      if (line != "") line += ",";

      line += array[i][index];
    }

    str += line + "\r\n";
  }

  return str;
}

export function exportCSVFile(headers, items, fileTitle) {
  if (headers) {
    items.unshift(headers);
  }

  // Convert Object to JSON
  var jsonObject = JSON.stringify(items);

  var csv = convertToCSV(jsonObject);

  var exportedFilenmae = fileTitle + ".csv" || "export.csv";

  var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    var link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilenmae);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export function download(data, page) {
  //////////////////console.log(JSON.stringify(data));
  var headers = {
    title: "title",
    description: "description",
    recid: "reference",
    url: "source",
    contract_start: "startDate",
    disposition: "disposition",
    contact_person: "contact",
    publisher: "buyer",
    government: "government",
    value: "value",
    supplier: "supplier",
    supplier_company_number: "abn",
    expiry_date: "expiryDate",
    category: "category",
    category_code: "categoryCode",
    id: "id",
    is_consultancy_services: "consultancy",
    published_date: "publishedDate",
  };

  var itemsNotFormatted = data;

  var itemsFormatted = [];

  // format the data
  for (var item = 0; item < itemsNotFormatted.length; item++) {
    try {
      if (!itemsNotFormatted[item].description) {
        itemsNotFormatted[item].description = "";
      }
      if (!itemsNotFormatted[item].value) {
        itemsNotFormatted[item].value = "";
      }
      if (!itemsNotFormatted[item].supplier) {
        itemsNotFormatted[item].supplier = "";
      }
      if (!itemsNotFormatted[item].contract_start) {
        itemsNotFormatted[item].contract_start = "";
      }
      if (!itemsNotFormatted[item].expiry_date) {
        itemsNotFormatted[item].expiry_date = "";
      }
      if (!itemsNotFormatted[item].contact_person) {
        itemsNotFormatted[item].contact_person = "";
      }
      if (!itemsNotFormatted[item].supplier_company_number) {
        itemsNotFormatted[item].supplier_company_number = "";
      }
      if (!itemsNotFormatted[item].url) {
        itemsNotFormatted[item].url = "";
      }
      if (!itemsNotFormatted[item].category_code) {
        itemsNotFormatted[item].category_code = "";
      }
      if (!itemsNotFormatted[item].is_consultancy_services) {
        itemsNotFormatted[item].is_consultancy_services = "";
      }
      if (!itemsNotFormatted[item].published_date) {
        itemsNotFormatted[item].published_date = "";
      }

      itemsFormatted.push({
        title: itemsNotFormatted[item].title.replace(/[,\n]+/g, ""),
        description: itemsNotFormatted[item].description.replace(/[,\n]+/g, ""),
        recid: itemsNotFormatted[item].recid,
        url: itemsNotFormatted[item].url,
        contract_start: itemsNotFormatted[item].contract_start,
        disposition: itemsNotFormatted[item].disposition,
        contact_person: itemsNotFormatted[item].contact_person.replace(
          /[,\n]+/g,
          ""
        ),
        publisher: itemsNotFormatted[item].publisher.replace(/[,\n]+/g, ""),
        government: itemsNotFormatted[item].government,
        value: itemsNotFormatted[item].value,
        supplier: itemsNotFormatted[item].supplier.replace(/[,\n]+/g, ""),
        supplier_company_number:
          itemsNotFormatted[item].supplier_company_number,
        expiry_date: itemsNotFormatted[item].expiry_date,
        category: itemsNotFormatted[item].category.replace(/[,\n]+/g, ""),
        category_code: itemsNotFormatted[item].category_code,
        id: itemsNotFormatted[item].id,
        is_consultancy_services: itemsNotFormatted[item].is_consultancy_services.replace(/[,\n]+/g, ""),
        published_date: itemsNotFormatted[item].published_date
      });
    } catch (err) {
      continue;
    }
  }

  var dte = new Date();

  var fileTitle = "tendertrace_page" + page + "_" + dte.toISOString(); // or 'my-unique-title'

  exportCSVFile(headers, itemsFormatted, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
}
