<template>
  <div :id="id" :class="canvasCSS"></div>
</template>
<script>
import * as echarts from "echarts";
import {downloadFile} from "./Utils";

export default {
  props: {
    chart_headers: [],
    chart_data: {},
    chart_axes: {},
    target_entity: String(),
    changed: Number,
    download: Number,
    metric_name: String(),
    entity_type: String(),
    target_group: String(),
    embed: Number,
    story: Boolean,
    chart_options: {},
    id: Number,
    chart_size: {
      type: String(),
      default: "large"
    }
  },
  data() {
    return {
      myChart: null,
      labels: [],
      options: {},
      dataView: [],
      canvasCSS: String(),
      chartHeight: Number(),
      chartWidth: Number(),
      legend_padding: Number()
    };
  },

  computed: {
    
  },

  watch: {
    embed() {
      ////////////console.log("CALL SHARE CHART\n", this.myChart);
      
      this.saveChart();
    },

    changed() {
      //this.myChart.clear();
      this.loadChart();
    },

    download() {
      this.saveImage();
    }
  },

  created() {
    this.setStyle();
  },

  mounted() {
    this.loadChart();
  },

  methods: {

    setStyle () {
      //this.chartHeight= 60;
      //this.chartWidth=90;
      //this.legend_padding = -30;
      this.canvasCSS = "reportPieSmall";

            
    },

    showTenders(params){
      ////////////console.log(params.name);
    },

    saveChart(){

      var imgUrl = this.myChart.getDataURL();

      let data = {
        imgData: imgUrl, //this.myChart.getDataURL(),
        options: this.options
      };

      this.$emit("chart_object", data);

      ////////////console.log("PIE CHART PAYLOAD: ", data);

    },

    saveImage(){

      var img = new Image();
      
      img.src = this.myChart.getDataURL({
          pixelRatio: 2,
          backgroundColor: '#fff'
      });

      downloadFile(img.src);
      //this.saveChart();
    },

    numFormatter(num) {
      if (num > 999 && num < 1000000) {
        return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
      } else if (num >= 1000000 && num < 1000000000) {
        return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
      } else if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1) + "B"; // convert to M for number from > 1 million
      } else if (num < 900) {
        return num; // if value < 1000, nothing to do
      }
    },

    loadChart() {

      var chartDom = document.getElementById(this.id)
      this.myChart = echarts.init(chartDom);

      if (this.story){
          this.chart_options.tooltip.formatter = (params) => {
              var num = params.value;
              var name = params.name;

              if (this.chart_options.metric_name == "total_amount")
                num = name + ": $" + this.convert(num) + " (" + params.percent + "%)";
              else
                num = name + ": " + num + " (" + params.percent + "%)";

              return num;
            };
          
          this.chart_options.series[0].label.formatter = (params) => {
                  var num = params.value;
                  //var name = params.name;
                  var percent = params.percent;

                  if (this.chart_options.metric_name == "total_amount")
                    num = "$" + this.convert(num);

                  return '{b|'+num + '} '+'{per|'+percent+'%}';
                };

          this.myChart.setOption(this.chart_options);
      }
      else{

        var preppedData = [];
        var dataview = {headers: [], data: []}
        var colours = [];
        var total = 0;

        ////////////console.log("CHART DATA: ", this.chart_data.datasets[0]);

        for (var i in this.chart_data.datasets[0].data) total += this.chart_data.datasets[0].data[i];

        dataview.headers[0] = {text: 'Group', value: 'name'}
        if (this.metric_name == "total_amount"){
          dataview.headers[1] = {text: 'Amount ($)', value: 'value'}
        }
        else{
          dataview.headers[1] = {text: 'Volume', value: 'value'}
        }
          
        dataview.headers[2] = {text: 'Share (%)', value: 'share'}

        for (var i in this.chart_data.labels) {
          colours[i] = this.chart_data.datasets[0].backgroundColor[i];
          preppedData[i] = {
            value: this.chart_data.datasets[0].data[i],
            name: this.chart_data.labels[i],
            share: (this.chart_data.datasets[0].data[i] / total * 100).toFixed(2)
          };
        }

        dataview.data = preppedData;

        this.options = { 
          color: [],
          legend: {
            show: false,
            icon: "circle",
            orient: 'horizontal',
            //type: "scroll",
            //right: 50,
            bottom: this.legend_padding,
            data: this.chart_data.labels,
          },

          tooltip: {
            trigger: "item",
            textStyle: {
              fontSize: 10
            },
            formatter: (params) => {
              var num = params.value;
              var name = params.name;

              if (this.metric_name == "total_amount")
                num = name + ": $" + this.convert(num) + " (" + params.percent + "%)";
              else
                num = name + ": " + num + " (" + params.percent + "%)";

              return num;
            }
          },

          series: [
            {
              //top: "middle",
              name: "Value of published awards",
              type: "pie",
              radius: ["30%","70%"],
              //radius: "30%",
              //center: ["50%", "50%"],
              data: [],
              center: ['50%', '40%'],
              roseType: "radius",
  
              labelLine: {
                lineStyle: {
                  color: "#12253a"
                },
                smooth: 0.2,
                length: 5,
                length2: 5
              },

              label: {
                show: true,
                //width: "50%",
                overflow: "break",
                padding: [0,5,0,5],
                //formatter: '{hr|}\n  {b|{b}：}{c}  {per|{d}%}  ',

                formatter: (params) => {
                  ////////////console.log(params);
                  var num = params.value;
                  //var name = params.name;
                  var percent = params.percent;
                  var name = this.truncateString(params.name, 15);

                  if (this.metric_name == "total_amount")
                    num = "$" + this.convert(num);

                  return '{b|'+name + '}';
                  //return '{b|'+num + '}';
                },

                rich: {
                  a: {
                    color: "#6E7079",
                    lineHeight: 22,
                    align: "center"
                  },
                  b: {
                    color: "#12253a",
                    fontSize: 8,
                    fontWeight: "normal",
                    lineHeight: 33
                  }
                }
              },

              animationType: "scale",
              animationEasing: "elasticOut",
              animationDelay: function(idx) {
                return Math.random() * 200;
              }
            }
        ]}

        this.options.series[0].data = preppedData;

        this.options.color = colours;

        this.options.metric_name = this.metric_name;

        this.myChart.setOption(this.options);

        this.$emit('dataview', dataview);

        this.myChart.on('click', param => {
          let field = this.target_group;
          let value = param.name;
          let args = new Object;
          args = {field: field, value: value};
          this.$emit("showTenders", [args]);
          ////////////console.log("PARAM: ", param.name, " ", args);
        });
      }
    },

    isEmpty(obj) {
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
      }
      return true;
    },

    truncateString(str, num) {
      if (str.length > num) {
        return str.slice(0, num) + "...";
      } else {
        return str;
      }
    },

    convert(num){
      if (num > 999 && num < 1000000) {
        num = (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
      } else if (num >= 1000000 && num < 1000000000) {
        num = (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
      } else if (num >= 1000000000) {
        num = (num / 1000000000).toFixed(1) + "B"; // convert to M for number from > 1 million
      }
      return num;
    }
  }
};
</script>

<style>
.reportPieLarge {
  margin-top: 0px !important;
  padding-top: 0px !important;
  padding-left: 0px !important;
  width: 100% !important;
  height: 500px !important;
  /* padding: 0px, 0px, 0px, 0px !important; */
}

.reportPieSmall {
  margin-top: 0px !important;
  padding-top: 0px !important;
  padding-left: 0px !important;
  width: 100% !important;
  height: 200px !important;
  /* padding: 0px, 0px, 0px, 0px !important; */
}
</style>
