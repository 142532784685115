<template>
  <v-card min-height="300" tile>
    <v-card-title class="py-3">
      <span
        class="font-weight-normal subtitle-1"
        style="color:#12253a;"
        >{{ title }}</span
      >
    </v-card-title>
    <v-divider></v-divider>
    <div class="pa-3"></div>
    <v-container fluid>
      <v-row>
        <v-col v-if="entity_data.length == 0"  cols="12" md="12">
          <div class="text-center" style="height:305px;">
            <v-progress-circular
              :size="70"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
        </v-col>
        <v-col v-else cols="12">
          <donut
            :chart_data="chart_data"
            :metric_type="metric_type"
            :metric_name="metric_name"
            :target_entity="target_entity"
            :changed="changed"
            :entity_type="entity_type"
            :show_labels="show_labels"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { prepEntityBreakdown } from "../components/PrepDonut.js";
import { executeSearch } from "../components/APIManager.js";
import { bus } from "../main.js";
import Donut from "../components/Donut";
//import Donut from "@/components/Donut.vue";

export default {
  components: {
    Donut
    //Donut
  },
  props: {
    entity_type: String(),
    metric_name: String(),
    search: {},
    entity_data: [],
    title: String(),
    show_labels: Boolean

    //:width="300"
    //:height="300"
    //results_drawer: Boolean
  },
  data() {
    return {
      item_details: false,
      aggregated_data: [],
      //publisher_line_chart_data: [],
      chart_data: {},
      //chart_axes: { x: "Est Total", y: "Categories" },
      //chart_axes: { x: "Transactions", y: "Year" },
      group_by_amount: true,
      target_entity: String,
      changed: Number,
    
    };
  },
  watch: {
    entity_data: {
      handler() {
        this.chart_data = this.chartConfig();
        ////////////console.log("SEARCH: ", this.chart_data);
        ////////////console.log("DATA WHEN CREATED: ", data)
        this.changed =
          (Math.random() + this.chart_data.datasets[0].data.length) * 1100;
      },
      deep: true
    }
  },

  mounted() {
    this.chart_data = this.chartConfig();
    ////////////console.log("SEARCH: ", this.chart_data);
    ////////////console.log("DATA WHEN CREATED: ", data)
    this.changed =
      (Math.random() + this.chart_data.datasets[0].data.length) * 1100;
  },

  methods: {
    chartConfig() {
      let data = {};

      data = prepEntityBreakdown(
        this.entity_data,
        this.metric_name,
        this.entity_type
      );

      return data;
    }
  }
};
</script>

<style>
.pie-chart-container {
  max-height: 360px !important;
  max-width: 600px !important;
}
</style>
